import { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { initialFilter } from 'views/Home/Feed'

const defaultViewType = {
  marketplace: { view: 'CARD' },
  registry: { view: 'CARD' }
}

const defaultSavedPosition = {
  marketplace: {
    domains: [],
    clickedDomain: '',
    offset: 0
  },
  registry: {
    domains: [],
    clickedDomain: '',
    offset: 0
  }
}

export const FilterContext = createContext({
  opened: true,
  openedRegistry: true,
  openedProfile: false,
  toggleOpen: async () => {},
  toggleOpenRegistry: async () => {},
  toggleOpenProfile: async () => {},
  filter: {},
  registryFilter: {},
  profileFilter: {},
  feedFilter: initialFilter,
  profileFilter: {},
  setFilter: () => {},
  activeCurrency: 'USDC',
  setActiveCurrency: () => {},
  darkMode: false,
  setDarkMode: () => {},
  setRegistryFilter: () => {},
  setFeedFilter: () => {},
  setProfileFilter: () => {},
  viewType: defaultViewType,
  setViewType: () => {},
  profileTabs: [],
  setProfileTabs: () => {},
  savedDomainPosition: defaultSavedPosition,
  setSavedDomainPosition: () => {}
})

const FilterProvider = ({ children }) => {
  const [opened, setOpened] = useState(window.innerWidth > 640)
  const [openedRegistry, setOpenedRegistry] = useState(window.innerWidth > 640)
  const [openedProfile, setOpenedProfile] = useState(false)
  const [filter, setFilter] = useState({})
  const [registryFilter, setRegistryFilter] = useState({})
  const [feedFilter, setFeedFilter] = useState(initialFilter)
  const [profileFilter, setProfileFilter] = useState({})
  const [activeCurrency, setActiveCurrency] = useState('USDC')
  const [viewType, setViewType] = useState(defaultViewType)
  const [darkMode, setDarkMode] = useState(false)
  const [profileTabs, setProfileTabs] = useState([])

  const [savedDomainPosition, setSavedDomainPosition] = useState(
    defaultSavedPosition
  )

  // const location = useLocation()

  const toggleOpen = () => {
    setOpened(state => !state)
  }

  const toggleOpenRegistry = () => {
    setOpenedRegistry(openedRegistry => !openedRegistry)
  }

  const toggleOpenProfile = () => {
    setOpenedProfile(openedProfile => !openedProfile)
  }

  useEffect(() => {
    const clickedDomain = savedDomainPosition.marketplace.clickedDomain
    const savedDomains = savedDomainPosition.marketplace.domains

    if (savedDomains.length > 0 && clickedDomain.length > 0) {
      const index = savedDomains.findIndex(
        domain => domain && domain.name === clickedDomain
      )
      const newSavedPositions = Object.assign(savedDomainPosition, {
        marketplace: {
          ...savedDomainPosition.marketplace,
          offset: savedDomainPosition.marketplace.offset + index
        }
      })
      setSavedDomainPosition(newSavedPositions)
    }
  }, [savedDomainPosition.marketplace.clickedDomain])

  useEffect(() => {
    const clickedDomain = savedDomainPosition.registry.clickedDomain
    const savedDomains = savedDomainPosition.registry.domains

    if (savedDomains.length > 0 && clickedDomain.length > 0) {
      const index = savedDomains.findIndex(
        domain => domain && domain.name === clickedDomain
      )
      const newSavedPositions = Object.assign(savedDomainPosition, {
        registry: {
          ...savedDomainPosition.registry,
          offset: savedDomainPosition.registry.offset + index
        }
      })
      setSavedDomainPosition(newSavedPositions)
    }
  }, [savedDomainPosition.registry.clickedDomain])

  // useEffect(() => {
  //   if (window.innerWidth < 740) return
  //   // setOpened(false)
  // }, [location])

  // useEffect(() => {
  //   if (location.pathname === '/marketplace') {
  //     setFilter({ limit: 20, date_status: 'recently_listed' })
  //     return
  //   }
  // }, [location.pathname])

  return (
    <FilterContext.Provider
      value={{
        profileTabs,
        setProfileTabs,
        opened,
        openedRegistry,
        openedProfile,
        setOpened,
        toggleOpen,
        toggleOpenRegistry,
        toggleOpenProfile,
        filter,
        feedFilter,
        profileFilter,
        setProfileFilter,
        registryFilter,
        setRegistryFilter,
        savedDomainPosition,
        setSavedDomainPosition,
        setFeedFilter,
        viewType,
        setViewType,
        setFilter,
        activeCurrency,
        setActiveCurrency,
        darkMode,
        setDarkMode
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export default FilterProvider
