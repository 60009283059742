import styled from '@emotion/styled/macro'
import useDomainResolver from 'hooks/useDomainResolver'
import useCellDescriptor from '../hooks/useCellDescriptor'

const ListItem = props => {
  const { children, data: dataProp, ...rest } = props

  const data = useDomainResolver(dataProp)

  const cells = useCellDescriptor({
    children,
    data,
    Component: StyledCell
  })

  return <StyledListItem {...rest}>{cells}</StyledListItem>
}

export default ListItem

export const ListHeader = props => {
  const { children, ...rest } = props

  const cells = useCellDescriptor({
    children,
    Component: StyledCell
  })

  return <StyledListHeader {...rest}>{cells}</StyledListHeader>
}

const StyledListItem = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  padding: 5px 0;
  background: white;
  font-family: 'Satoshi', sans-serif;
  padding: 5px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #212121;
`

const StyledCell = styled.div`
  display: flex;
  flex: 1 0 30px;
  align-items: center;
  overflow: hidden;
  background: white;
`

export const StyledListHeader = styled(StyledListItem)`
  height: 60px;
  font-size: 13px;
  color: #bcbccc;
`
