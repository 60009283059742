import { useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { getSigner } from '@ensdomains/ui'
import elevator_ding from 'assets/elevator_ding.mp3'
import elevator_music from 'assets/elevator_music.mp3'
import FractalBg from 'assets/fractal-completed-modal.png'
import PhotoPlaceholder from 'assets/search-no-results.png'
import { ReactComponent as SoundOff } from 'assets/sound-off.svg'
import { ReactComponent as SoundOn } from 'assets/sound-on.svg'
import { ReactComponent as Trash } from 'assets/trash.svg'
import { useWindowListener } from 'common/useWindowListener'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import LoadingSpinner from 'components/LoadingSpinner'
import { useAccount } from 'components/QueryAccount'
import Space from 'components/Space'
import View from 'components/View'
import { CartStoreContext } from 'contexts/CartStoreProvider'
import { ModalContext } from 'contexts/ModalProvider'
import { BigNumber, ethers } from 'ethers'
import { useEthPrice } from 'hooks/useEthPrice'
import moment from 'moment'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { GET_SEARCH_DOMAINS } from 'service/graphql/kodex-api'
import { BULK_COMMIT, BULK_REGISTER } from 'service/graphql/mutations'
import {
  GET_BALANCE,
  GET_MINIMUM_COMMITMENT_AGE
} from 'service/graphql/queries'
import { getListing } from 'service/rest/getListing'
import { randomSecret } from 'utils/register'
import { actions } from 'utils/reservoir'
import { ENS_CONTRACT_ADDRESS } from 'utils/utils'
import CartItem from './CartItem'
import CartTabButton from './CartTabButton'
import RegistrationLoadingAnimation from './RegistrationLoadingAnimation'

const YEAR_IN_SECONDS = 31556952
const CATEGORIES = ['purchase', 'registration']

const CartItems = ({
  closeCart,
  openRegistration,
  cartOpen,
  purchased,
  setPurchased,
  registered,
  setRegistered,
  setOpenOfferDialog,
  setOpenBulkOfferDialog,
  setSelectedDomain
}) => {
  const history = useHistory()
  const cartCtx = useContext(CartStoreContext)
  const modalCtx = useContext(ModalContext)
  const location = useLocation()

  const [activeCategory, setActiveCategory] = useState(
    cartCtx.state.checkoutType || CATEGORIES[0]
  )
  useEffect(() => {
    if (location.search.includes('marketplace'))
      setActiveCategory([CATEGORIES[0]])
    if (location.search.includes('registration'))
      setActiveCategory([CATEGORIES[1]])
  }, [location])

  const [purchasePrices, setPurchasePrices] = useState([0])
  const [registrationPrices, setRegistrationPrices] = useState([0])
  const [buyableDomains, setBuyableDomains] = useState([])
  const [isBuying, setBuying] = useState(false)
  const [purchaseTx, setPurchaseTx] = useState('')
  const [pricesInETH, setPricesInETH] = useState(
    activeCategory === CATEGORIES[0]
  )
  const [isCommiting, setCommiting] = useState(false)
  const [isRegistering, setRegistering] = useState(false)
  const [registrationStep, setRegistrationStep] = useState(0)
  const [txHash, setTxHash] = useState(0)
  const [purchaseTotal, setPurchaseTotal] = useState(0)
  const [pricesLoading, setPricesLoading] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const [
    registrationCommitCountdown,
    setRegistrationCommitCountdown
  ] = useState(60)

  const [secret, setSecret] = useState(randomSecret())
  const [elevatorMusicMuted, setElevatorMusicMuted] = useState(false)
  const [registrationTotal, setRegistrationTotal] = useState(0)
  const [itemsLoading, setItemsLoading] = useState({
    marketplace: [],
    registry: []
  })

  const elevatorMusic = useRef(null)
  const elevatorDing = new Audio(elevator_ding)

  const account = useAccount()
  const { data: { getBalance } = {} } = useQuery(GET_BALANCE, {
    variables: { address: account },
    fetchPolicy: 'no-cache'
  })

  const ethBalance = getBalance
    ? BigNumber.from(getBalance)
        .mul(BigNumber.from(10).pow(5))
        .div(BigNumber.from(10).pow(18))
        .toNumber() /
      10 ** 5
    : 0

  const purchaseDomainsUnsorted = useMemo(
    () =>
      cartCtx.state.domains.filter(
        domain =>
          domain.expire_time &&
          moment()
            .subtract(90, 'days')
            .isBefore(parseInt(domain.expire_time || domain.expires) * 1000)
      ),
    [cartCtx.state.domains]
  )

  const purchaseDomains = useMemo(
    () =>
      purchaseDomainsUnsorted.sort(
        (a, b) => b?.listing_start_price - a?.listing_start_price
      ),
    [purchaseDomainsUnsorted]
  )

  const registerableDomains = useMemo(
    () =>
      cartCtx.state.domains.filter(domain => !purchaseDomains.includes(domain)),
    [purchaseDomains]
  )

  useEffect(() => {
    if (
      activeCategory === CATEGORIES[0] &&
      itemsLoading.marketplace.includes(false)
    ) {
      setPricesLoading(false)
      return
    }
    if (
      activeCategory === CATEGORIES[1] &&
      itemsLoading.registry.includes(false)
    ) {
      setPricesLoading(false)
      return
    }
    setPricesLoading(true)
  }, [itemsLoading, activeCategory])

  useEffect(() => {
    if (purchaseDomains.length === 0 && registerableDomains.length > 0)
      setActiveCategory(CATEGORIES[1])

    if (purchaseDomains.length > 0 && registerableDomains.length === 0)
      setActiveCategory(CATEGORIES[0])
  }, [cartOpen])

  useEffect(() => {
    setPurchased(false)
    setPricesLoading(true)
    setPricesInETH(activeCategory === CATEGORIES[0])
  }, [activeCategory])

  const fetchListings = async (ids = []) => {
    ids.forEach(async id => {
      const fetchedListing = await getListing({
        address: ENS_CONTRACT_ADDRESS,
        token_id: id
      })

      if (fetchedListing.length === 0) return

      const buyableDomainsCopy = buyableDomains

      setBuyableDomains([...buyableDomainsCopy, fetchedListing[0]])
    })
  }

  useEffect(() => {
    const fetchOrders = async domains => {
      if (!domains) return

      const domainNamesArr = domains.map(domain =>
        domain.name.replace('.eth', '')
      )

      try {
        const tokenIds = domainNamesArr.map(name =>
          ethers.BigNumber.from(
            ethers.utils.keccak256(ethers.utils.toUtf8Bytes(name))
          ).toString()
        )

        if (tokenIds.length === 0) return

        fetchListings(tokenIds)
      } catch (err) {
        throw new Error(err)
      }
    }

    fetchOrders(purchaseDomains)
  }, [purchaseDomains])

  const removeDomain = (name, i, price) => {
    cartCtx.removeDomain(
      name,
      activeCategory === CATEGORIES[0] ? 'PURCHASE' : 'REGISTER'
    )
    if (activeCategory === CATEGORIES[0]) {
      const purchasePricesCopy = purchasePrices
      purchasePricesCopy.splice(i, 1)
      setPurchasePrices(purchasePricesCopy)
    }
    if (activeCategory === CATEGORIES[1]) {
      const registrationPriceCopy = registrationPrices
      registrationPriceCopy.splice(i, 1)
      setRegistrationPrices(registrationPriceCopy)
    }
  }

  const removeMultipleDomains = () => {
    if (activeCategory === CATEGORIES[0]) {
      const domainNamesArr = purchaseDomains.map(domain => domain.name)
      cartCtx.removeMultipleDomains(domainNamesArr, 'PURCHASE')
      setPurchasePrices([])
    }
    if (activeCategory === CATEGORIES[1]) {
      const domainNamesArr = registerableDomains.map(domain => domain.name)
      cartCtx.removeMultipleDomains(domainNamesArr, 'REGISTER')
      setRegistrationPrices([])
    }
  }

  const ethPrice = useEthPrice()

  const addToTotal = (price, i) => {
    if (activeCategory === CATEGORIES[0]) {
      setPricesLoading(false)
      const purchasePricesCopy = purchasePrices
      purchasePricesCopy[i] = price
      setPurchasePrices(purchasePricesCopy)
      setPurchaseTotal(purchasePrices.reduce((acc, price) => acc + price))
    }
    if (activeCategory === CATEGORIES[1]) {
      const registrationPriceCopy = registrationPrices
      registrationPriceCopy[i] = price
      setRegistrationPrices(registrationPriceCopy)
      setPricesLoading(false)
      setRegistrationTotal(
        registrationPrices.reduce((acc, price) => acc + price) * 1.02
      )
    }
  }

  useEffect(() => {
    setPurchaseTotal(purchasePrices.reduce((acc, price) => acc + price))
  }, [purchasePrices.length])

  useEffect(() => {
    setRegistrationTotal(registrationPrices.reduce((acc, price) => acc + price))
  }, [registrationPrices.length])

  const usdTotal =
    activeCategory === CATEGORIES[0]
      ? purchaseTotal > 0 && ethPrice
        ? (Number(purchaseTotal) * Number(ethPrice)).toLocaleString('default', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })
        : '0'
      : registrationTotal > 0 && ethPrice
      ? `${(Number(registrationTotal) * Number(ethPrice)).toLocaleString(
          'default',
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )}`
      : '0'

  const ethTotal =
    activeCategory === CATEGORIES[0]
      ? purchaseTotal.toLocaleString('default', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 3
        })
      : registrationTotal.toLocaleString('default', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 3
        })

  const cartIsEmpty =
    (registerableDomains.length === 0 && activeCategory === CATEGORIES[1]) ||
    (purchaseDomains.length === 0 && activeCategory === CATEGORIES[0])

  const changeCartTab = index => {
    setPricesLoading(true)
    setActiveCategory(CATEGORIES[index])
    cartCtx.changeCheckoutType(CATEGORIES[index])
  }

  const handleCartEmptyButtonClick = () => {
    closeCart()
    history.push(
      `/${activeCategory === CATEGORIES[0] ? 'marketplace' : 'registry'}`,
      activeCategory === CATEGORIES[0] ? 60 : 0
    )
  }

  const handlePurchaseDomain = async () => {
    if (buyableDomains.length === purchaseDomains.length) {
      try {
        const signer = await getSigner()

        const tokens = purchaseDomains.map(d => {
          const tokenId = ethers.BigNumber.from(
            ethers.utils.keccak256(
              ethers.utils.toUtf8Bytes(d.name.replace('.eth', ''))
            )
          ).toString()

          return {
            token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
            quantity: 1,
            preferredOrderSource: 'kodex.io'
          }
        })

        setBuying(true)

        await actions.buyToken({
          items: tokens,
          signer,
          onProgress: step => {
            if (step[0].items[0].status === 'complete') {
              setPurchased(true)
              setBuying(false)
              setPurchaseTx(ordersFulfilled.hash)
              cartCtx.removeMultipleDomains(
                purchaseDomains.map(domain => domain.name)
              )
            }
          }
        })
      } catch (e) {
        setBuying(false)
        throw new Error(e)
      }

      return
    }

    if (purchaseDomains.length > 1) {
      setSelectedDomain(purchaseDomains)
      setOpenBulkOfferDialog(true)
      return
    }

    setSelectedDomain(purchaseDomains[0])
    setOpenOfferDialog(true)
  }

  const { data: { getMinimumCommitmentAge } = {} } = useQuery(
    GET_MINIMUM_COMMITMENT_AGE,
    {}
  )

  useEffect(() => {
    setRegistrationCommitCountdown(getMinimumCommitmentAge)
  }, [getMinimumCommitmentAge])

  const labels = registerableDomains.map(domain =>
    domain.name.replace('.eth', '')
  )
  const durations = registerableDomains.map(() => 1 * YEAR_IN_SECONDS)

  const [handleBulkRegister] = useMutation(BULK_REGISTER, {
    onCompleted: data => {
      setRegistering(false)
      setRegistrationStep(4)
      setRegistered(true)
      modalCtx.setClosable(true)
      removeMultipleDomains()
    },
    onError: err => {
      console.log(err)
      setRegistering(false)
      setRegistrationStep(3)
      modalCtx.setClosable(true)
    },
    variables: {
      labels,
      account,
      durations,
      secret
    },
    refetchQueries: [{ query: GET_SEARCH_DOMAINS }, 'getDomainsAdvanced']
  })

  const [handleBulkRequestRegister] = useMutation(BULK_COMMIT, {
    onCompleted: data => {
      setCommiting(false)
      setRegistrationStep(2)

      setTxHash(Object.values(data)[0])
      localStorage.setItem(labels, secret)

      if (getMinimumCommitmentAge) {
        elevatorMusic.current.play()
        setTimeout(() => {
          elevatorDing.play()
          elevatorMusic.current.pause()
        }, 59500)

        const commitmentInterval = setInterval(() => {
          setRegistrationCommitCountdown(state => state - 1)
        }, 1000)

        setTimeout(() => {
          clearInterval(commitmentInterval)
          setTxHash(undefined)
          setRegistrationStep(3)
          modalCtx.setClosable(false)
          setRegistering(true)
          handleBulkRegister()
        }, getMinimumCommitmentAge * 1000 + 1000)
      }
    },
    onError: e => {
      console.log(e)
      setCommiting(false)
      setRegistrationStep(0)
      modalCtx.setClosable(true)
    },
    variables: {
      secret,
      owner: account,
      names: labels
    }
  })

  const handleMakeBulkCommit = async () => {
    modalCtx.setClosable(false)
    setRegistrationStep(1)
    handleBulkRequestRegister()
  }

  useWindowListener(
    'resize',
    () => {
      setWindowWidth(window.innerWidth)
    },
    []
  )

  return (
    <View
      direction="column"
      width={windowWidth < 1500 ? '45vw' : '33vw'}
      background="transparent"
      height={cartIsEmpty ? '99vh' : '96vh'}
      maxHeight={cartIsEmpty ? '99vh' : '96vh'}
    >
      <View direction="row" width="100%" height="60px" margin="0 0 0.5rem 0">
        <CartTabButton
          active={activeCategory === CATEGORIES[0]}
          label="Marketplace"
          count={purchaseDomains.length}
          onClick={() => changeCartTab(0)}
        />
        <Space size={10} />
        <CartTabButton
          active={activeCategory === CATEGORIES[1]}
          label="Registry"
          count={registerableDomains.length}
          onClick={() => changeCartTab(1)}
        />
      </View>
      <Space size={10} />
      <View
        direction="column"
        background="#fff"
        width="100%"
        borderRadius="10px"
        margin={cartIsEmpty ? '0 0 1rem 0' : '0'}
        height="100%"
        padding={
          purchased || registered ? '0' : cartIsEmpty ? '2.5rem' : '0.65rem'
        }
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.4) 2px -1px 10px'
        }}
      >
        {((registrationStep > 0 && registrationStep < 4) ||
          (registrationStep === 3 && isRegistering)) && (
          <RegistrationLoadingAnimation step={registrationStep} />
        )}
        {activeCategory === CATEGORIES[0] && purchased ? (
          <View
            width="100%"
            height="100%"
            direction="column"
            borderRadius="10px"
            background="#0698A8"
            justify="space-between"
            alignItems="center"
            padding="2rem"
          >
            <Label size={40}>
              {purchaseDomains.map(
                (d, i) => d.name + (i + 1 === purchaseDomains.length && ', ')
              )}
            </Label>
            <Space size={15} />
            <Label size={40} color="#fff8">
              {purchaseDomains.length > 1 ? 'Were' : 'Was'} successfully
              purchased for
            </Label>
            <Space size={15} />
            <Label size={40}>{ethTotal}</Label>
            <img src={FractalBg} />
            <View width="350px" direction="column">
              <a href={`https://etherscan.io/tx/${purchaseTx}`} target="_blank">
                <RoundedButton
                  width="350px"
                  height="60px"
                  background="transparent"
                  border="1px solid #fff"
                >
                  <Label size={16}>View transaction</Label>
                </RoundedButton>
              </a>
              <Space size={10} />
              <RoundedButton
                width="100%"
                height="60px"
                background="#fff"
                border="1px solid #fff"
                onClick={() => {
                  setPurchased(false)
                }}
              >
                <Label size={16} color="#020202">
                  Back to cart
                </Label>
              </RoundedButton>
            </View>
          </View>
        ) : activeCategory === CATEGORIES[1] && registrationStep === 4 ? (
          <View
            width="100%"
            height="100%"
            direction="column"
            borderRadius="10px"
            background="#0698A8"
            justify="space-between"
            alignItems="center"
            padding="2rem"
          >
            <Label size={40} color="#fff8" align="center">
              Domains registered successfully
            </Label>
            <Space size={15} />
            <img src={FractalBg} />
            <View width="350px" direction="column">
              <RoundedButton
                width="350px"
                height="60px"
                background="transparent"
                border="1px solid #fff"
                onClick={() => {
                  history.push('/profile/my-profile?unlisted')
                  setRegistered(false)
                }}
              >
                <Label size={16}>View in profile</Label>
              </RoundedButton>
              <Space size={10} />
              <RoundedButton
                width="100%"
                height="60px"
                background="#fff"
                border="1px solid #fff"
                onClick={() => {
                  history.push('/registry')
                  closeCart()
                  setRegistered(false)
                }}
              >
                <Label size={16} color="#020202">
                  Back to registry
                </Label>
              </RoundedButton>
            </View>
          </View>
        ) : cartIsEmpty ? (
          <View
            width="100%"
            height="100%"
            direction="column"
            justify="space-between"
            alignItems="center"
          >
            <Label size={40} color="#BCBCCC" key="">
              Cart is empty
            </Label>
            <img
              src={PhotoPlaceholder}
              width={300}
              height={300}
              alt="No items"
            />
            <CartEmptyButton onClick={handleCartEmptyButtonClick}>
              <Label color="#020202" size={16}>
                Go to{' '}
                {activeCategory === CATEGORIES[0] ? 'Marketplace' : 'Registry'}
              </Label>
            </CartEmptyButton>
          </View>
        ) : (
          <>
            <View
              padding="44px 90px 20px 30px"
              alignItems="center"
              justify="space-between"
              width="100%"
            >
              <View width="50%">
                <Label size={14} color="#BCBCCC">
                  Domain
                </Label>
              </View>
              <View width="47.5%" gap="25px">
                <Label size={14} color="#BCBCCC">
                  Price
                </Label>
                <Label
                  size={14}
                  color="#0698A8"
                  hoverColor="#26a8c8"
                  cursor="pointer"
                  onClick={() => {
                    setPricesInETH(!pricesInETH)
                  }}
                >
                  {pricesInETH ? 'ETH' : 'USD'}
                </Label>
              </View>
            </View>
            <View
              width="100%"
              height="100%"
              direction="column"
              justify="space-between"
            >
              <View
                width="100%"
                direction="column"
                gap="10px"
                padding="0 30px"
                height="calc(100vh - 335px)"
                overflow="scroll"
                scrollBar={`&::-webkit-scrollbar {
                  display: none; /* for Chrome, Safari, and Opera */
                }
                -ms-overflow-style: none; /* for Internet Explorer, Edge */
                scrollbar-width: none; /* for Firefox */`}
              >
                {activeCategory === CATEGORIES[0]
                  ? purchaseDomains.map((domain, i) => (
                      <CartItem
                        domain={domain}
                        itemsLoading={itemsLoading}
                        setItemsLoading={setItemsLoading}
                        key={domain.name}
                        removeDomain={removeDomain}
                        category={CATEGORIES[0]}
                        addToTotal={addToTotal}
                        purchasePrices={purchasePrices}
                        setPurchasePrices={setPurchasePrices}
                        index={i}
                        closeCart={closeCart}
                        priceInETH={pricesInETH}
                      />
                    ))
                  : registerableDomains.map((domain, i) => (
                      <CartItem
                        itemsLoading={itemsLoading}
                        setItemsLoading={setItemsLoading}
                        domain={domain}
                        key={domain.name}
                        removeDomain={removeDomain}
                        purchasePrices={purchasePrices}
                        setPurchasePrices={setPurchasePrices}
                        category={CATEGORIES[1]}
                        addToTotal={addToTotal}
                        index={i}
                        closeCart={closeCart}
                        priceInETH={pricesInETH}
                      />
                    ))}
              </View>
              {!cartIsEmpty && (
                <View
                  direction="row"
                  width="100%"
                  alignItems="center"
                  justify="space-between"
                  borderRadius="6px"
                  padding="10px 30px"
                  background="#F3F3FA"
                >
                  <View
                    direction="row"
                    width="100%"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Label color="black" size={16}>
                      Total
                    </Label>
                    <View direction="row" alignItems="center" width="50%">
                      <Space size={20} />
                      <View direction="column" alignItems="flex-start">
                        <Label color="black" size={16} letterSpacing="1px">
                          {pricesLoading ? (
                            <LoadingSpinner margin="0 0 0 1rem" />
                          ) : pricesInETH ? (
                            ethTotal + ' ETH'
                          ) : (
                            usdTotal + ' USD'
                          )}
                        </Label>
                      </View>
                    </View>
                  </View>
                  <Space size={20} />
                  <View
                    justify="center"
                    alignItems="center"
                    cursor="pointer"
                    onClick={removeMultipleDomains}
                    minWidth="40px"
                    minHeight="40px"
                  >
                    <Trash />
                  </View>
                </View>
              )}
            </View>
          </>
        )}
      </View>
      {!cartIsEmpty && !purchased && !registered && <Space size={10} />}
      {!cartIsEmpty && !purchased && !registered && (
        <View direction="row" width="100%" height="60px" margin="0.5rem 0 0 0">
          <RoundedButton
            background="#020202"
            width="50%"
            color="#888"
            height="50px"
            padding="2rem"
            borderRadius="10px"
            letterSpacing="0.1rem"
            hoverBackground="#222"
            style={{
              boxShadow: 'rgba(48, 48, 48, 0.8) 2px -1px 8px'
            }}
            // onClick={() => {
            //   history.replace('/checkout')
            //   closeCart()
            // }}
          >
            Advanced
          </RoundedButton>
          <Space size={10} />
          {registrationStep === 2 ? (
            <RoundedButton
              background="#FFFFFF"
              width="50%"
              height="62.5px"
              color="black"
              padding="0rem 0.3rem 0rem 1rem"
              borderRadius="10px"
              letterSpacing="0.1rem"
              hoverBackground="#FFFFFF"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.4) 2px -1px 8px'
              }}
            >
              <View width="100%" justify="space-between" alignItems="center">
                <Label size={16} color="#000">
                  {registrationCommitCountdown <= 0
                    ? '0:00'
                    : `${Math.floor(
                        registrationCommitCountdown / 60
                      )}:${Math.floor(
                        registrationCommitCountdown % 60
                      ).toLocaleString('default', {
                        minimumIntegerDigits: 2
                      })}`}
                </Label>
                <RoundedButton
                  borderRadius="10px"
                  padding="15px"
                  background="white"
                  hoverBackground="#F3F3FA"
                  onClick={() => {
                    setElevatorMusicMuted(!elevatorMusicMuted)
                    elevatorMusic.current.volume = !elevatorMusicMuted ? 0 : 1
                  }}
                >
                  {elevatorMusicMuted ? (
                    <SoundOff height="20px" />
                  ) : (
                    <SoundOn height="20px" />
                  )}
                </RoundedButton>
              </View>
            </RoundedButton>
          ) : (
            <RoundedButton
              background="#0698A8"
              width="50%"
              height="50px"
              color="white"
              padding="2rem"
              borderRadius="10px"
              letterSpacing="0.1rem"
              hoverBackground="#16a8b8"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.4) 2px -1px 8px'
              }}
              disabled={
                isBuying ||
                (activeCategory === CATEGORIES[0] &&
                  purchaseDomains.length === buyableDomains.length &&
                  ethBalance < purchaseTotal) ||
                (activeCategory === CATEGORIES[1] &&
                  ethBalance <
                    registrationTotal + registrationPrices.length * 0.00035) ||
                (activeCategory === CATEGORIES[1] &&
                  registerableDomains.length > 100) ||
                registrationStep === 1 ||
                isRegistering
              }
              onClick={() => {
                if (activeCategory === CATEGORIES[0]) {
                  handlePurchaseDomain()
                  return
                }
                if (activeCategory === CATEGORIES[1])
                  if (registerableDomains.length > 1) {
                    if (registrationStep === 0) {
                      handleMakeBulkCommit()
                      return
                    }
                    if (registrationStep === 3) {
                      handleBulkRegister()
                      return
                    }
                  }
                openRegistration(registerableDomains[0])
              }}
            >
              {activeCategory === CATEGORIES[0]
                ? purchaseDomains.length === buyableDomains.length
                  ? ethBalance >= purchaseTotal
                    ? isBuying
                      ? 'Buying...'
                      : 'Buy now'
                    : 'Add funds'
                  : purchaseDomains.length > 1
                  ? 'Bulk offer'
                  : 'Offer now'
                : registerableDomains.length > 100
                ? 'Max domains'
                : ethBalance >= registrationTotal
                ? registerableDomains.length > 1
                  ? isRegistering
                    ? 'Registering...'
                    : 'Bulk register'
                  : 'Register'
                : 'Add funds'}
            </RoundedButton>
          )}
        </View>
      )}
      <audio
        ref={elevatorMusic}
        volume={elevatorMusicMuted ? 0 : 1}
        src={elevator_music}
        controls={false}
      />
    </View>
  )
}

const CartEmptyButton = styled.button`
  width: 100%;
  padding: 22px 0;
  border: 1px solid #e1e1e8;
  border-radius: 6px;
  cursor: pointer;
`

export default CartItems
