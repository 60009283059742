import View from 'components/View'
import { useImagePreloader } from 'contexts/ImagePreloaderProvider'
import { useEffect } from 'react'
import InViewAnimation from 'utils/inViewAnimation'
import Categories from './Categories'

const Home = () => {
  const { imageLoading, canStartAnimatePage } = useImagePreloader()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })

    document.title = 'Kodex - ENS discovery'
  }, [])

  useEffect(() => {
    // inview animation handler
    if (imageLoading) {
      return
    }
    let animation
    if (canStartAnimatePage) {
      animation = new InViewAnimation()
    }
    if (animation != null) {
      return () => {
        animation.destroy()
      }
    }
  }, [imageLoading, canStartAnimatePage])

  return (
    <View direction="column" width="100%" alignItems="center">
      <Categories />
    </View>
  )
}

export default Home
