import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import View from 'components/View'

const CartTabButton = ({ active, label, count, onClick }) => {
  return (
    <RoundedButton
      background={active ? '#fff' : '#555'}
      width="50%"
      height="60px"
      borderRadius="10px"
      padding="0"
      hoverBackground={active ? '#fff' : '#666'}
      onClick={onClick}
      style={{
        boxShadow: active
          ? 'rgba(0, 0, 0, 0.3) 1px -1px 7px'
          : 'rgba(0, 0, 0, 0.7) 1px -1px 6px'
      }}
    >
      <View
        direction="row"
        width="100%"
        alignItems="center"
        justify="space-between"
        padding="15px 15px 15px 40px"
      >
        <Label size={16} color={active ? '#000' : '#B6b6b6'} weight={600}>
          {label}
        </Label>
        <View
          alignItems="center"
          justify="center"
          borderRadius="4px"
          background={active ? '#F3F3FA' : 'transparent'}
          padding="7px 10px"
          transition="all 0.2s ease"
          minWidth="30px"
          minHeight="30px"
        >
          <Label size={16} color={active ? '#000' : '#B6b6b6'}>
            {count}
          </Label>
        </View>
      </View>
    </RoundedButton>
  )
}

export default CartTabButton
