import styled from '@emotion/styled/macro'
import { ReactComponent as ArrowDown } from 'assets/arrow-down-white.svg'
import { ReactComponent as ArrowDownGray } from 'assets/arrow-down-gray.svg'
import { ReactComponent as CategorySelected } from 'assets/category-selected.svg'
import { ReactComponent as Tick } from 'assets/tick-white.svg'
import { countBy, every, uniq } from 'lodash'
import { useEffect, useState } from 'react'
import { formatNumber } from 'utils/format'
import { CATEGORIES } from 'constants/category'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import useGetCategoryCount from 'hooks/useGetCategoryCount'

const CategoryFilter = ({
  darkMode,
  page,
  innerSubCategories,
  setInnerSubcategories
}) => {
  const [categories, setCategories] = useState([])

  // ------------------------------------
  // Handlers ---------------------------
  // ------------------------------------

  const handleSelectAllCategories = () => {
    if (Object.keys(CATEGORIES).length === categories.length) {
      setCategories([])
      setInnerSubcategories([])
      return
    }
    setCategories(Object.keys(CATEGORIES))
    setInnerSubcategories(Object.values(CATEGORIES).flat())
  }

  const handleChangeCategory = params => {
    let newCategories = [],
      newSubcategories = []
    if (categories.includes(params)) {
      newCategories = categories.filter(cat => cat !== params)
      newSubcategories = innerSubCategories.filter(
        cat => !CATEGORIES[params].includes(cat)
      )
    } else {
      newCategories = [...categories, params]
      newSubcategories = uniq([...innerSubCategories, ...CATEGORIES[params]])
    }
    setCategories(newCategories)
    setInnerSubcategories(newSubcategories)
  }

  const handleChangeSubcategory = (label, params) => {
    let newCategories = categories,
      newSubcategories = []

    if (innerSubCategories.includes(params)) {
      newSubcategories = innerSubCategories.filter(cat => cat !== params)
      newCategories = categories.filter(cat => cat !== label)
    } else {
      newSubcategories = uniq([...innerSubCategories, params])
      if (every(CATEGORIES[label], cat => newSubcategories.includes(cat))) {
        newCategories = uniq([...newCategories, label])
      }
    }
    setCategories(newCategories)
    setInnerSubcategories(newSubcategories)
  }

  const getCategoryCount = param => {
    return (
      countBy(CATEGORIES[param], cat => innerSubCategories.includes(cat))[
        'true'
      ] || 0
    )
  }

  return (
    <>
      <CategoryRow
        darkMode={darkMode}
        onSelect={handleSelectAllCategories}
        color={darkMode ? 'rgba(255,255,255,0.6)' : '#47596B'}
        label="All Categories"
        active={Object.keys(CATEGORIES).length === categories.length}
      />
      {Object.keys(CATEGORIES).map((cat, index) => (
        <CategoryRow
          darkMode={darkMode}
          key={index}
          onSelect={count => handleChangeCategory(cat, count)}
          color={MAIN_CATEGORY_COLORS[index % 11]}
          label={cat}
          selectedCount={getCategoryCount(cat)}
          active={categories.includes(cat)}
          subCategories={CATEGORIES[cat]}
          handleChangeSubcategory={handleChangeSubcategory}
          innerSubCategories={innerSubCategories}
          page={page}
        />
      ))}
    </>
  )
}

export default CategoryFilter

const CategoryRow = ({
  onSelect,
  color,
  label,
  selectedCount,
  active,
  subCategories,
  handleChangeSubcategory,
  innerSubCategories,
  page,
  darkMode
}) => {
  const domainCount =
    selectedCount || selectedCount === 0
      ? useGetCategoryCount(label)
      : undefined

  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpanded = () =>
    setIsExpanded(prev => Boolean(subCategories?.length) && !prev)

  useEffect(() => {
    setIsExpanded(false)
  }, [page])

  return (
    <Container
      isExpanded={isExpanded}
      expansionCount={subCategories?.length ?? 0}
    >
      <Row onClick={toggleExpanded}>
        <CheckboxContainer
          isSelected={active}
          darkMode={darkMode}
          onClick={e => {
            e.stopPropagation()
            onSelect(domainCount)
          }}
        >
          {active && <Tick />}
        </CheckboxContainer>
        <Label color={color} darkMode={darkMode}>
          {label}
        </Label>
        <DomainCount darkMode={darkMode}>
          {formatNumber(domainCount)}
        </DomainCount>
        <Spacer />
        <SelectedCount darkMode={darkMode} active={Boolean(selectedCount)}>
          {selectedCount}
        </SelectedCount>
        {subCategories?.length && (
          <ArrowContainer invert={isExpanded}>
            {darkMode ? <ArrowDown /> : <ArrowDownGray />}
          </ArrowContainer>
        )}
      </Row>
      {subCategories &&
        subCategories.map((cat, index) => (
          <SubCategoryRow
            darkMode={darkMode}
            key={index}
            label={cat}
            onSelect={() => handleChangeSubcategory(label, cat)}
            isSelected={innerSubCategories.includes(cat)}
          />
        ))}
    </Container>
  )
}

const SubCategoryRow = ({ label, onSelect, isSelected, darkMode }) => {
  const domainCount = useGetCategoryCount(label, true)

  return (
    <Row>
      <Indent />
      <CheckboxContainer
        darkMode={darkMode}
        isSelected={isSelected}
        onClick={e => {
          e.stopPropagation()
          onSelect()
        }}
      >
        {isSelected && <CategorySelected />}
      </CheckboxContainer>
      <Label darkMode={darkMode}>{label}</Label>
      <DomainCount darkMode={darkMode}>{formatNumber(domainCount)}</DomainCount>
    </Row>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 400;
  height: ${({ isExpanded, expansionCount }) =>
    isExpanded ? (expansionCount + 1) * 46 + 'px' : '30px'};
  max-height: ${({ isExpanded, expansionCount }) =>
    isExpanded ? (expansionCount + 1) * 46 + 'px' : '30px'};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
`

const Row = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  min-height: 30px;
`

const CheckboxContainer = styled.div`
  align-items: center;
  border: 1px solid ${({ darkMode }) => (darkMode ? '#4a4a4d' : '#919CA6')};
  background: ${({ darkMode, isSelected }) =>
    !darkMode && isSelected ? '#47596B' : ''};
  border-radius: 4px;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
`

const Label = styled.p`
  color: ${({ color, darkMode }) =>
    color ? color : darkMode ? 'white' : '#47596B'};
  margin: 0;
`

const DomainCount = styled.p`
  color: rgba(256, 256, 256, 0.6);
  margin: 0;
`

const Spacer = styled.div`
  flex: 1;
`

const SelectedCount = styled.div`
  align-items: center;
  background: ${({ active, darkMode }) =>
    active ? (darkMode ? '#333333' : '#EAEAF1') : 'transparent'};
  border-radius: 4px;
  color: ${({ active, darkMode }) =>
    darkMode ? (active ? 'white' : '#333333') : '#919CA6'};
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
`

const ArrowContainer = styled.div`
  svg {
    transform: ${({ invert }) => (invert ? 'rotate(180deg)' : '')};
    transition: all 0.1s ease-in;
  }
`

const Indent = styled.div`
  width: 20px;
`
