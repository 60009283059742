import React from 'react'
import styled from '@emotion/styled/macro'
import View from '../View'
import Label from '../Label'

const ToolBarCategoriesButton = ({
  title,
  categoriesCount,
  onClick,
  darkMode
}) => {
  return (
    <ToolBarButtonComponent
      borderColor={darkMode ? '#4A4A4D' : '#ebebf2'}
      onClick={onClick}
    >
      <View
        width="100%"
        alignItems="center"
        justify="space-between"
        padding="22px 30px"
      >
        <Label size={16} weight={600} color={darkMode ? '#ffffff' : '#020202'}>
          {title}
        </Label>
        {categoriesCount === '' || categoriesCount === 55 ? (
          <Label
            size={16}
            weight={600}
            color={darkMode ? '#ffffff' : '#020202'}
          >
            {categoriesCount === 55 ? 'ALL' : categoriesCount}
          </Label>
        ) : (
          <CategoriesCount>{categoriesCount}</CategoriesCount>
        )}
      </View>
    </ToolBarButtonComponent>
  )
}

const ToolBarButtonComponent = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${props => props.borderColor};
  border-radius: 10px;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  width: 100%;
  cursor: pointer;
`

const CategoriesCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3fa;
  border-radius: 4px;
  height: 30px;
  width: 30px;
  color: #020202;
  font-size: 16px;
`

export default ToolBarCategoriesButton
