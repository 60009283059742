import React, { useEffect, useRef, useState } from 'react'
import Searchbar from 'components/Search'
import View from 'components/View'

const StickySearchbar = ({
  onInputChange,
  onInputClear,
  searchDomain,
  searchOnEnter,
  searchDomains
}) => {
  const [scrollBarOffset, setScrollBarOffset] = useState(window.scrollY)
  const searchBarRef = useRef(null)

  useEffect(() => {
    const onScroll = () => {
      setScrollBarOffset(window.scrollY)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <View
      position="sticky"
      zIndex={scrollBarOffset > 140 ? -1 : 1}
      opacity={scrollBarOffset > 140 ? 0 : 1}
      top={
        scrollBarOffset > 140
          ? '-80px'
          : scrollBarOffset > 80
          ? `${140 - scrollBarOffset}px`
          : '70px'
      }
      left="0"
      ref={searchBarRef}
    >
      <View width="100vw" padding={`80px 70px 0px 70px`} background={'#000'}>
        <Searchbar
          placeholder="Explore all unregistered domains"
          onChange={onInputChange}
          onClear={onInputClear}
          value={searchDomain || ''}
          searchOnEnter
          searchDomains={searchDomains}
        />
      </View>
    </View>
  )
}

export default StickySearchbar
