import CategoryFilterModal from 'components/CategoryFilterModal'
import { OfferDialog } from 'components/Dialogs'
import HeaderBar from 'components/HeaderBar'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import ResultList from 'components/ResultList'
import KodexSidebar from 'components/Sidebar'
import Space from 'components/Space'
import StickyToolbar from 'components/StickyToolBar'
import View from 'components/View'
import { TAGS } from 'constants/category'
import { FilterContext } from 'contexts/FilterProvider'
import { useImagePreloader } from 'contexts/ImagePreloaderProvider'
import useSimilarDomains from 'hooks/useSimilarDomains'
import queryString from 'query-string'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import InViewAnimation from 'utils/inViewAnimation'

const SORT_BY_OPTIONS = [
  { value: '', label: 'Default' },
  { value: 'price_high_to_low', label: 'Price high to low' },
  { value: 'price_low_to_high', label: 'Price low to high' },
  { value: 'alphabetical', label: 'Alphabetical' },
  { value: 'highest_last_sale', label: 'Last Sale' },
  { value: 'most_favorited', label: 'Likes' }
]

let loading = true

const SearchResult = () => {
  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant'
    })
  }, [])

  const location = useLocation()
  const history = useHistory()

  const domainName = location.search
    ? queryString.parse(location.search)?.query.replace('.eth', '')
    : null

  const [searchedDomain, setSearchedDomain] = useState(domainName)

  const isSearchingForSimilarDomains = location.search
    ? queryString.parse(location.search)?.searchType
    : true

  const [categoriesModalOpen, setCategoriesModalOpen] = useState(false)
  const [filter, setFilter] = useState({
    limit: isSearchingForSimilarDomains ? 100 : 20
  })

  const { opened: filtersOpened, toggleOpen } = useContext(FilterContext)

  const pageRef = useRef(null)

  const { canStartAnimatePage } = useImagePreloader()

  // const { domains, fetchMore, getDomains, loading } = useGetDomains()

  const [selectedCategories, setSelectedCategories] = useState(TAGS)

  const [offerDialogOpen, setOfferDialogOpen] = useState(false)
  const [offeredDomainName, setOfferedDomainName] = useState('')
  const [offeredDomainlastPrice, setOfferedDomainLastPrice] = useState('')
  const [activeCurrency, setActiveCurrency] = useState('ETH')
  const [orderBy, setOrderBy] = useState(SORT_BY_OPTIONS[0])
  const [viewType, setViewType] = useState('Card')

  const [shownSimilarDomains, setShownSimilarDomains] = useState([])

  const handleOpenModal = useCallback(() => {
    setCategoriesModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(categories => {
    if (categories != null) {
      setSelectedCategories(categories)
    }
    setCategoriesModalOpen(false)
  }, [])

  useEffect(() => {
    let animation
    if (canStartAnimatePage) {
      animation = new InViewAnimation()
    }
    if (animation != null) {
      return () => {
        animation.destroy()
      }
    }
  }, [canStartAnimatePage])

  // useEffect(() => {
  //   if (domains == null) {
  //     return
  //   }
  //   const cards = new InViewCard()

  //   return () => cards.destroy()
  // }, [domains])

  const handleOpenFilters = useCallback(() => {
    toggleOpen()
  }, [])

  const handleHideFilters = useCallback(() => {
    toggleOpen()
  }, [])

  // const categoriesCount = useMemo(() => {
  //   if (selectedCategories.length === TAGS.length) {
  //     return 'All'
  //   } else {
  //     return selectedCategories.length
  //   }
  // }, [selectedCategories])

  const handleLengthChange = params => {
    setFilter(filter => ({
      ...filter,
      minLength: params[0],
      maxLength: params[1]
    }))
  }
  const handleSortByChange = sort => {
    setFilter(filter => ({
      ...filter,
      sort
    }))
  }

  const handleChangeCategories = subCategories => {
    setFilter(filter => ({
      ...filter,
      subCategories
    }))
  }

  // useEffect(() => {
  //   if (isSearchingForSimilarDomains) {
  //     return
  //   }
  //   getDomains(filter)
  // }, [filter])

  useEffect(() => {
    if (!location.search) {
      return
    }

    setFilter({
      ...filter,
      search: searchedDomain
    })
  }, [location.search])

  const similarDomains = useSimilarDomains(searchedDomain, 100, orderBy)

  useEffect(() => {
    if (!similarDomains) return

    const similarDomainsCopy = similarDomains

    setShownSimilarDomains(
      similarDomainsCopy?.slice(
        0,
        similarDomains?.length >= 20 ? 20 : similarDomains?.length
      )
    )
  }, [similarDomains])

  const loadMoreSimilarDomains = () => {
    if (!similarDomains) return
    if (shownSimilarDomains?.length >= similarDomains?.length) return

    const similarDomainsCopy = similarDomains

    const moreSimilarDomains = similarDomainsCopy?.slice(
      shownSimilarDomains.length,
      shownSimilarDomains.length + 20
    )

    if (!moreSimilarDomains?.length) return

    loading = false
    setShownSimilarDomains([...shownSimilarDomains, ...moreSimilarDomains])
  }

  const handleResetFilter = () => {
    setFilter({})
  }

  const handleOpenOfferDialog = (name, lastPrice) => {
    setOfferedDomainName(name)
    setOfferedDomainLastPrice(lastPrice)
    setOfferDialogOpen(true)
  }

  return (
    <>
      <KodexSidebar
        positionDialogCenter={true}
        opened={offerDialogOpen}
        onClose={() => setOfferDialogOpen(false)}
      >
        <OfferDialog
          onClose={() => setOfferDialogOpen(false)}
          domain={{
            name: offeredDomainName,
            lastPrice: offeredDomainlastPrice
          }}
          backLabel="marketplace"
        />
      </KodexSidebar>
      <Container ref={pageRef} maxWidth="calc(100% - 140px)" background="#fff">
        <Space size={160} />
        <View gap="2rem">
          {isSearchingForSimilarDomains && (
            <View direction="column">
              <Label size={80} color="#BCBCCC">
                Similar to:
              </Label>
              <Space size={10} />
            </View>
          )}
          <Label
            size={80}
            color="#020202"
            data-in="stagger-chars"
            hoverColor="#000a"
            cursor="pointer"
            onClick={() => {
              history.push(`/domain/${searchedDomain}`)
            }}
          >
            {searchedDomain}.eth
          </Label>
        </View>
        <Space size={80} />
        <View width="100%">
          <StickyToolbar
            ref={pageRef}
            filtersOpened={filtersOpened}
            onHideFilters={handleHideFilters}
            onOpenFilters={handleOpenFilters}
            subCategories={filter.subCategories}
            onOpenCategoriesModal={handleOpenModal}
            onLengthChange={handleLengthChange}
            onSortByChange={handleSortByChange}
            onResetFilter={handleResetFilter}
            setActiveCurrency={setActiveCurrency}
            domainCount={100}
            onChangeResult={() => {}}
            onChangeType={() => {}}
            modalButtonTitle="Categories"
            page="marketplace"
          />
          <View direction="column" flex={1}>
            {window.innerWidth > 740 && (
              <HeaderBar
                darkMode={false}
                domainCount={100}
                refreshData={() => {}}
                viewType={'Card'}
                onViewTypeChange={setViewType}
                onSortByChange={setOrderBy}
                onSearchChange={setSearchedDomain}
                searchDomain={searchedDomain}
                isSimilar={true}
              />
            )}
            <ResultList
              domains={shownSimilarDomains}
              filtersOpened={filtersOpened}
              fetchMore={loadMoreSimilarDomains}
              setFetchMore={loadMoreSimilarDomains}
              loading={loading}
              openOfferDialog={handleOpenOfferDialog}
              activeCurrency={activeCurrency}
              isSimilar={true}
              viewType={viewType}
            />
          </View>
        </View>
        <Space size={80} />
      </Container>
      {categoriesModalOpen && (
        <CategoryFilterModal
          subCategories={filter.subCategories}
          open={categoriesModalOpen}
          onClose={handleCloseModal}
          onApply={handleChangeCategories}
        />
      )}
    </>
  )
}

export default SearchResult
