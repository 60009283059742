import styled from '@emotion/styled/macro'
import Space from 'components/Space'
import Label from 'components/Label'
import View from 'components/View'
import React, { useContext, useEffect, useState } from 'react'
import RoundedButton from 'components/Forms/RoundedButton'
import Avatar from 'components/Avatar'
import MessageItem from './MessageItem'
import AccountAvatarImg from 'assets/icon-pfp-default.png'
import { ReactComponent as ArrowForward } from 'assets/arrow-forward.svg'
import { ReactComponent as TrashCan } from 'assets/trash-can.svg'
import { AccountContext } from 'contexts/AccountProvider'
import useUserProfile from 'hooks/useUserProfile'
import { useAccount } from 'components/QueryAccount'

const groupMessagesByDay = messages => {
  const getSimplifiedDateString = date => {
    const simplifiedDate = new Date(date)
    simplifiedDate.setHours(0)
    simplifiedDate.setMinutes(0)
    simplifiedDate.setSeconds(0)
    simplifiedDate.setMilliseconds(0)
    return simplifiedDate.toString()
  }

  const messagesMap = messages.reduce((acc, message) => {
    const dateKey = getSimplifiedDateString(message.createdAt)

    return {
      ...acc,
      [dateKey]: acc[dateKey] ? [...acc[dateKey], message] : [message]
    }
  }, {})

  return Object.entries(messagesMap)
    .map(([dateKey, value]) => [new Date(dateKey), value])
    .sort(([dateA], [dateB]) => dateA - dateB)
}

/**
 * If the date is current day, instead of writing the date like "12 June"
 * we can just have it say "Today"
 *
 * @param {Date} date - date to be formatted
 * @returns {string} - formatted date
 */
const formatDate = date => {
  if (!date) {
    return undefined
  }

  const isToday = date => {
    const today = new Date()
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    )
  }

  if (isToday(date)) {
    return 'Today'
  }

  return `${date.toLocaleString('en-us', {
    month: 'long'
  })} ${date.getDate()}`
}

const MessageView = ({ chat }) => {
  const { userData } = useContext(AccountContext)
  const address = useAccount()
  const { profile } = useUserProfile(address)

  const [message, setMessage] = useState('')
  const [newMessages, setNewMessages] = useState([])

  const { picture } = profile.userInfo || {}

  const accountAvatar = userData?.picture ? userData.picture : AccountAvatarImg

  const messagesGroupedByDay = groupMessagesByDay([
    ...chat.messages.map(message => {
      if (message.user.id === '4') {
        return {
          ...message,
          user: {
            ...message.user,
            avatar: picture
          }
        }
      }
      return message
    }),
    ...newMessages
  ])

  const handleChangeTxt = e => {
    setMessage(e.target.value)
  }

  const sendNewMessage = () => {
    setNewMessages(newMessages => [
      ...newMessages,
      {
        content: message,
        createdAt: new Date(),
        user: {
          name: 'me',
          id: '4',
          avatar: accountAvatar
        }
      }
    ])
    setMessage('')
  }

  const handleEnterKeyDown = e => {
    if (e.key === 'Enter' && message) {
      sendNewMessage()
    }
  }

  useEffect(() => {
    setNewMessages([])
  }, [chat])

  return (
    <View
      flex={1.5}
      background={'#F3F3FA'}
      height="calc(100vh - 140px)"
      borderRadius="10px"
      direction="column"
    >
      <View padding="20px 85px 20px 86px" width="100%">
        <View
          borderRadius="6px"
          height="70px"
          background="#fff"
          alignItems="center"
          justify="center"
          padding="15px 20px"
          width="100%"
        >
          <Avatar
            background="transparent"
            src={chat.summary.users[0].avatar}
            borderRadius="6px"
          />
          <Space size={17} />
          <Label size={14} color="#212121">
            {chat.summary.users[0].name}
          </Label>

          <TrashCanButton margin="0 0 0 auto">
            <TrashCan />
          </TrashCanButton>
        </View>
      </View>
      <View direction="column" flex="1" width="100%">
        {messagesGroupedByDay.map(([date, messages]) => (
          <React.Fragment key={date.toString()}>
            <View alignItems="center" justify="center" width="100%">
              <Label size={13} color="#BCBCCC">
                {formatDate(date)}
              </Label>
            </View>
            <MessageViewMessages>
              {Boolean(messages?.length) &&
                messages.map((message, index) => (
                  <React.Fragment key={index}>
                    <MessageItem message={message} />
                    <Space size={10} />
                  </React.Fragment>
                ))}
            </MessageViewMessages>
          </React.Fragment>
        ))}
      </View>
      <View
        border="1px solid #F3F3FA"
        height="70px"
        width="100%"
        padding="10px"
        background="#fff"
        borderRadius="0 0 10px 10px"
      >
        <StyledInput
          value={message}
          disabled={true}
          placeholder="Coming soon"
          onChange={handleChangeTxt}
          onKeyDown={handleEnterKeyDown}
        />

        <SendButton
          tabIndex={message ? 0 : -1}
          isActive={message}
          onClick={sendNewMessage}
        >
          <ArrowForward />
        </SendButton>
      </View>
    </View>
  )
}

const MessageViewMessages = styled(View)`
  padding: 20px 40px 40px;
  flex-direction: column;
  width: 100%;
`

const TrashCanButton = styled(RoundedButton)`
  color: #020202;
  display: flex;
  width: 40px;
  height: 40px;
  margin-left: auto;

  // # TODO => add hover state
  &:hover {
  }
`

const SendButton = styled(RoundedButton)`
  color: #0698a8;
  background: #e8f5f7;
  flex: 0 0 80px;
  height: 52px;

  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: opacity 200ms;
`

const StyledInput = styled.input`
  border: none;
  height: 50px;
  width: 100%;
  outline: none;
  font-size: 16px;
  padding-left: 20px;
  caret-color: #a14f9d;
  font-family: 'Satoshi', sans-serif;

  ::placeholder {
    color: #bcbccc;
  }
`

export default MessageView
