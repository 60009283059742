import React from 'react'
import styled from '@emotion/styled/macro'
import View from 'components/View'

export default function Card({
  title,
  children,
  HeaderTrailingElement,
  width
}) {
  return (
    <Container width={width}>
      <View width="100%" justify="space-between" alignItems="center">
        <Header>{title}</Header>
        {HeaderTrailingElement}
      </View>
      {children}
    </Container>
  )
}

const Container = styled.div`
  display: inline-block;
  height: 95%;
  margin: 5px;
  border-radius: 10px;
  position: relative;
  padding: 30px;
  border: 1px solid #e1e1e8;
  width: ${props => (props.width ? props.width : '50%')};
  overflow: hidden;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const Header = styled.div`
  height: 50px;
  line-height: 50px;
  font-size: 16px;
`
