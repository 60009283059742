import styled from '@emotion/styled/macro'
import PlaceholderBanner from 'assets/icon-banner-default.png'
import Placeholder from 'assets/icon-pfp-default.png'
import { ReactComponent as Instagram } from 'assets/instagram.svg'
import { ReactComponent as Twitter } from 'assets/twitter.svg'
import { ReactComponent as Weblink } from 'assets/weblink.svg'
import Label from 'components/Label'
import Space from 'components/Space'
import Uploader from 'components/Uploader'
import View from 'components/View'
import { AccountContext } from 'contexts/AccountProvider'
import { useContext, useMemo, useState } from 'react'
import { updateUser } from 'service/rest/updateUser'
import SaveButton from './components/SaveButton'
import SettingsInput from './components/SettingsInput'
import SettingTextArea from './components/SettingTextArea'
import SocialLinkInput from './components/SocialLinkInput'

const FORM_STATUS = {
  NOUN: 0,
  DURTY: 1,
  SAVED: 2
}

const ProfileSettingTab = ({ selected, primaryName }) => {
  const { userData, handleSIWE, isConnected, setUserData } = useContext(
    AccountContext
  )

  const [formStatus, setFormStatus] = useState(FORM_STATUS.NOUN)
  const [updatedName, setUpdatedName] = useState(userData?.username || '')
  const [updatedBiography, setUpdateBiography] = useState(userData?.bio || '')
  const [updatedEmail, setUpdatedEmail] = useState(userData?.email || '')
  const [updatedTwitter, setUpdatedTwitter] = useState(userData?.twitter || '')
  const [updatedInstagram, setUpdatedInstagram] = useState(
    userData?.instagram || ''
  )
  const [updatedWeblink, setUpdatedWeblink] = useState(userData?.weblink || '')
  const [updatedAvatar, setUpdatedAvatar] = useState(userData?.picture || '')
  const [updatedBg, setUpdatedBg] = useState(userData?.banner || '')
  const [loading, setLoading] = useState(false)
  const [usernameError, setUsernameError] = useState('')

  const handleNameChange = e => {
    const enteredString = e.target.value
    if (enteredString.length > 25 || enteredString.includes('/')) {
      return
    }
    setUsernameError('')
    setFormStatus(FORM_STATUS.DURTY)
    setUpdatedName(enteredString)
  }

  const handleBiographyChange = e => {
    setFormStatus(FORM_STATUS.DURTY)
    setUpdateBiography(e.target.value)
  }

  const handleEmailChange = e => {
    setFormStatus(FORM_STATUS.DURTY)
    setUpdatedEmail(e.target.value)
  }

  const handleInstagramChange = e => {
    setFormStatus(FORM_STATUS.DURTY)
    setUpdatedInstagram(e.target.value)
  }

  const handleTwitterChange = e => {
    setFormStatus(FORM_STATUS.DURTY)
    setUpdatedTwitter(e.target.value)
  }

  const handleWeblinkChange = e => {
    setFormStatus(FORM_STATUS.DURTY)
    setUpdatedWeblink(e.target.value)
  }

  const dataDidChange = useMemo(
    () =>
      userData?.username !== updatedName ||
      userData?.bio !== updatedBiography ||
      userData?.email !== updatedEmail ||
      userData?.instagram !== updatedInstagram ||
      userData?.weblink !== updatedWeblink ||
      updatedAvatar ||
      updatedBg,
    [
      userData,
      updatedName,
      updatedBiography,
      updatedEmail,
      updatedInstagram,
      updatedWeblink,
      updatedAvatar,
      updatedBg
    ]
  )

  const handleUploadAvatar = file => {
    setFormStatus(FORM_STATUS.DURTY)
    const reader = new FileReader()
    reader.onloadend = function() {
      setUpdatedAvatar(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const handleUploadBg = file => {
    setFormStatus(FORM_STATUS.DURTY)
    const reader = new FileReader()
    reader.onloadend = function() {
      setUpdatedBg(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const saveChanges = async () => {
    if (!isConnected) {
      return () => {}
    }

    try {
      await handleSIWE()
    } catch (e) {
      throw new Error(e)
    }

    if (loading) return
    setLoading(true)

    try {
      let bannerUrl = '',
        pictureUrl = ''
      if (updatedBg) {
        const formData = new FormData()
        formData.append('file', updatedBg)
        formData.append('upload_preset', 'kodex-banner-upload')
        const response = await fetch(
          'https://api.cloudinary.com/v1_1/kodex-solutions/image/upload',
          {
            method: 'POST',
            body: formData
          }
        )
        const banner = await response.json()
        bannerUrl = banner.url
      }
      if (updatedAvatar) {
        const formData = new FormData()
        formData.append('file', updatedAvatar)
        formData.append('upload_preset', 'kodex-picture-upload')
        const response = await fetch(
          'https://api.cloudinary.com/v1_1/kodex-solutions/image/upload',
          {
            method: 'POST',
            body: formData
          }
        )
        const picture = await response.json()
        pictureUrl = picture.url
      }

      const updateUserDataRes = await updateUser({
        username: updatedName.length > 0 ? updatedName : null,
        picture: pictureUrl,
        banner: bannerUrl,
        bio: updatedBiography,
        instagram: updatedInstagram,
        twitter: updatedTwitter,
        weblink: updatedWeblink,
        email: updatedEmail
      })

      if (
        updateUserDataRes.status === 200 ||
        updateUserDataRes.status === 204
      ) {
        setFormStatus(FORM_STATUS.SAVED)
        setUserData({
          ...userData,
          username: updatedName,
          picture: pictureUrl,
          banner: bannerUrl,
          bio: updatedBiography,
          instagram: updatedInstagram,
          twitter: updatedTwitter,
          weblink: updatedWeblink,
          email: updatedEmail
        })
      }

      if (updateUserDataRes.status >= 500) {
        setUsernameError('Username already taken')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  if (!selected) {
    return <></>
  }

  return (
    <View
      flex={1}
      direction="column"
      overflow="scroll"
      height="calc(100vh - 140px)"
      gap="20px"
      scrollBar={`
      &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
      }
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
    `}
    >
      <View gap="10px" width="100%">
        <View
          width="160px"
          minWidth="160px"
          direction="column"
          alignItems="center"
          gap="10px"
        >
          <Uploader
            name="Profile pic"
            image={updatedAvatar}
            onUpload={handleUploadAvatar}
            alt="profile pic"
            placeholder={
              <img
                src={Placeholder}
                style={{ width: 80 }}
                alt="profile pic placeholder"
              />
            }
          />
        </View>
        <View width="436px" direction="column" alignItems="center" gap="10px">
          <Uploader
            name="Profile banner"
            image={updatedBg}
            onUpload={handleUploadBg}
            alt="profile banner"
            placeholder={
              <img src={PlaceholderBanner} alt="profile banner placeholder" />
            }
          />
        </View>
      </View>
      <Space size={60} />
      {usernameError && (
        <View>
          <Label size={16} color="#ff0000">
            {usernameError}
          </Label>
        </View>
      )}
      <SettingsInput
        border={`1px solid ${usernameError ? '#ff0000' : '#e1e1e8'}`}
        padding="22px 0 22px 30px"
        placeholder={primaryName ? primaryName : 'username'}
        value={updatedName}
        onChange={handleNameChange}
      />
      <Space size={60} />
      <TextAreaWrapper>
        <SettingTextArea
          placeholder="biography"
          value={updatedBiography}
          maxLength={140}
          onChange={handleBiographyChange}
          height="122px"
          width="554px"
          position="absolute"
          top="-1px"
          left="-1px"
        />
        {140 - updatedBiography?.length}
      </TextAreaWrapper>
      <Space size={60} />
      <SettingsInput
        border="1px solid #e1e1e8"
        padding="22px 0 22px 30px"
        placeholder="email address"
        disabled={true}
        value={updatedEmail}
        onChange={handleEmailChange}
      />
      <Space size={60} />
      <View direction="column" width="100%">
        <View width="100%">
          <SocialLinkInput
            placeholder="username"
            value={updatedTwitter}
            onChange={handleTwitterChange}
            icon={<Twitter />}
          />
          <Space size={10} />
          <SocialLinkInput
            placeholder="username"
            value={updatedInstagram}
            onChange={handleInstagramChange}
            icon={<Instagram />}
          />
        </View>
        <Space size={10} />
        <SocialLinkInput
          placeholder="web link"
          value={updatedWeblink}
          onChange={handleWeblinkChange}
          icon={<Weblink />}
        />
      </View>
      <Space size={60} />
      <SaveButton
        disabled={!dataDidChange || loading || formStatus === FORM_STATUS.SAVED}
        onClick={saveChanges}
      >
        {loading
          ? 'Saving...'
          : formStatus === FORM_STATUS.SAVED
          ? 'Changes saved!'
          : 'Save changes'}
      </SaveButton>
      <Space size={70} />
    </View>
  )
}

const TextAreaWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  padding: 52px 23px 52px 0;
  background: #f3f3fa;
  border: 1px solid #e1e1e8;
  border-radius: 10px;
  font-size: 16px;
  color: #bcbccc;
  width: 100%;
  font-family: 'Satoshi', sans-serif;
  line-height: 1;
`

export default ProfileSettingTab
