import React, { useEffect, useRef } from 'react'
import Container from 'components/Layout/Container'
import View from 'components/View'
import Label from 'components/Label'
import Space from 'components/Space'
import Section from './Section'
import styled from '@emotion/styled'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Avatar from 'assets/about-page/team/chosen-ph-colored.webp'

gsap.registerPlugin(ScrollTrigger)

export const AboutUs = () => {
  const titleRef = useRef(null)
  const contentRef = useRef(null)

  useEffect(() => {
    ScrollTrigger.create({
      trigger: titleRef.current,
      start: 'top top',
      endTrigger: contentRef.current,
      end: 'bottom bottom',
      pin: true,
      scrub: true,
      pinSpacing: false
    })
  }, [])

  return (
    <Section background="transparent" className="about-us">
      <Container maxWidth="1080px" ref={contentRef}>
        <View padding="250px 0 0">
          <View ref={titleRef} width="50%">
            <Label size={60} color="#020202" fontFamily="'Sora', sans-serif">
              Solving ENS Discovery
            </Label>
          </View>
          <View width="50%" direction="column">
            <View alignItems="center">
              <AvatarWrapper>
                <img
                  src={Avatar}
                  alt="chosen.eth avatar"
                  width={80}
                  height={80}
                />
              </AvatarWrapper>
              <View direction="column">
                <Label
                  size={16}
                  color="#020202"
                  fontFamily="'Sora', sans-serif"
                >
                  chosen.eth
                </Label>
                <Space size={10} />
                <Label
                  size={16}
                  color="#BCBCCC"
                  fontFamily="'Sora', sans-serif"
                >
                  Head of Operations
                </Label>
              </View>
            </View>
            <Space size={40} />
            <Label
              size={30}
              lineHeight={1.2}
              color="#020202"
              fontFamily="'Sora', sans-serif"
            >
              We want to build the tools that will help great domains be
              discovered
            </Label>
            <Space size={100} />
            <Label
              size={16}
              lineHeight={2}
              color="#020202"
              fontFamily="'Sora', sans-serif"
            >
              Everyone deserves to have an epic Web3 username. Yet how can a new
              user navigate the saturated, eclectic landscape that is the ENS
              market? This was the driving question that prompted us to the
              build tools to solve it. Using a turbocharged database,
              cutting-edge natural language tools and on-chain discovery
              mechanisms, we are committed to building the best, fastest way to
              discover and trade ENS domains, as well as providing market
              analytics that boost accurate price discovery. To read more into
              the technical implementations, check out our{' '}
              <TextLink
                href="https://docs.kodex.io"
                target="_blank"
                referrerPolicy="no-referrer"
              >
                Docs
              </TextLink>
              .
            </Label>
            <Space size={150} />
          </View>
        </View>
      </Container>
    </Section>
  )
}

const AvatarWrapper = styled.div`
  border-radius: 4px;
  overflow: hidden;
  height: 80px;
  width: 80px;
  min-height: 80px;
  min-width: 80px;
  margin-right: 20px;
`

const TextLink = styled.a`
  color: #0698a8;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 0.25s ease;

  &:visited {
    color: #0698a8;
  }

  &:hover {
    color: #0698a8;
    text-decoration-color: #0698a8;
  }
`

export default AboutUs
