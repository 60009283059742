import { ReactComponent as EthPriceDark } from 'assets/eth-price-dark.svg'
import { ReactComponent as EthPriceLight } from 'assets/eth-price-light.svg'
import { ReactComponent as Minus } from 'assets/minus.svg'
import { ReactComponent as Plus } from 'assets/plus.svg'
import Avatar from 'components/Avatar'
import Card from 'components/Card'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import RoundedButton from '../Forms/RoundedButton'

const TrendingTokenCard = ({
  margin,
  headerBg,
  headerColor,
  bodyBg,
  registeredBg,
  bodyColor,
  footerBg,
  starStrokeColor,
  cardBorder,
  domain,
  addedToCart,
  name,
  category,
  eth,
  likes,
  avatar
}) => {
  return (
    <Card
      width="310px"
      height="430px"
      margin={margin}
      background="#212121"
      borderRadius="12px"
    >
      <View
        width="100%"
        height="100%"
        direction="column"
        background={headerBg}
        overflow="visible"
        borderRadius="10px"
      >
        <View height="60px" padding="0px 30px" alignItems="center">
          <Label size={16} color={headerColor ? headerColor : '#fff'}>
            {category}
          </Label>
        </View>
        <View
          width="100%"
          flex={1}
          direction="column"
          background={addedToCart ? '#262628' : footerBg ? footerBg : '#4A4A4D'}
          borderRadius="8px"
        >
          <View
            width="100%"
            flex={1}
            position="relative"
            zIndex="10"
            direction="column"
            justify="space-between"
            background={bodyBg}
            borderRadius="6px"
            padding="40px 30px 30px 30px"
          >
            <View
              width="100%"
              justify="space-between"
              alignItems="center"
              padding="0 0 65px"
            >
              <View>
                <Label size={16} color={addedToCart ? '#fff' : bodyColor}>
                  {likes}
                </Label>
                <Space size={5} />
                <Label size={16} color="#BCBCCC">
                  Likes
                </Label>
              </View>
            </View>
            <View width="100%" direction="column-reverse">
              <Label size={16} color="#BCBCCC" />
              <Label size={16} color="#BCBCCC" />
              <Space size={20} />
              <Label
                size={30}
                color={addedToCart ? '#fff' : bodyColor || '#fff'}
                width="100%"
                whiteSpace="nowrap"
              >
                {name}
              </Label>
              <Space size={20} />
              <Avatar src={avatar} borderRadius="6px" background={'#fff'} />
            </View>
          </View>
          <View
            position="relative"
            width="100%"
            height="90px"
            alignItems="center"
            justify="space-between"
            padding="30px 30px 20px 30px"
            margin="-10px 0 0 0"
            border={cardBorder}
            borderRadius="10px"
          >
            <View alignItems="center">
              {addedToCart ? <EthPriceDark /> : <EthPriceLight />}
              <Space size={10} />
              <Label size={30} color="#FFFFFF">
                {eth}
              </Label>
            </View>
            <RoundedButton>{addedToCart ? <Minus /> : <Plus />}</RoundedButton>
          </View>
        </View>
      </View>
    </Card>
  )
}

export default TrendingTokenCard
