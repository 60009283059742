import React from 'react'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { ethers } from 'ethers'
import Avatar from 'components/Avatar'
import WalletAddress from 'components/Label/WalletAddress'
import DefaultAvatarImg from 'assets/icon-pfp-default.png'
import { isAddress } from 'utils/address'

const SummarySecondColumn = ({
  eventType,
  loading,
  label,
  avatar,
  onClick
}) => {
  const isSaleOrOffer = eventType === 'sale' || eventType === 'offer'

  const getColumnTitle = () => {
    if (isSaleOrOffer) {
      return 'To'
    }
    return 'Platform'
  }

  const handleClick = e => {
    if (isSaleOrOffer) {
      onClick(e, label)
    }
  }

  return (
    <View flex={1} direction="column" padding="0 8px 0 0">
      <Label size={13} opacity="0.5">
        {getColumnTitle()}
      </Label>
      <Space size={16} />
      {loading ? (
        <View
          width="100px"
          height="16px"
          background="#2C2C2C"
          borderRadius="6px"
        />
      ) : (
        <View
          alignItems="center"
          cursor={
            isSaleOrOffer && label !== ethers.constants.AddressZero
              ? 'pointer'
              : ''
          }
          onClick={handleClick}
        >
          <Avatar
            src={avatar ?? DefaultAvatarImg}
            borderRadius="6px"
            hoverOpacity={isSaleOrOffer ? 0.7 : 1}
            cursor={isSaleOrOffer ? 'pointer' : 'auto'}
          />
          <Space size={16} />
          <Label size={16}>
            {isSaleOrOffer ? (
              isAddress(label) ? (
                <WalletAddress>{label}</WalletAddress>
              ) : (
                label
              )
            ) : (
              label
            )}
          </Label>
        </View>
      )}
    </View>
  )
}

export default SummarySecondColumn
