import React, { useEffect, useState } from 'react'
import Link from 'components/Forms/Link'
import View from 'components/View'
import Space from 'components/Space'
import Logo from 'assets/logo.svg'
import RoundedButton from 'components/Forms/RoundedButton'
import WhiteLogo from 'assets/while-logo.png'
import { useMediaMax } from 'mediaQuery'
import styled from '@emotion/styled/macro'
import Label from 'components/Label'
import { useHistory } from 'react-router'
import FirstPageLink from './FirstPageLink'
import SecondPageLink from './SecondPageLink'
import ThirdPageLink from './ThirdPageLink'
import { ReactComponent as Twitter } from 'assets/twitter.svg'
import { ReactComponent as Mirror } from 'assets/mirror.svg'
import { ReactComponent as Github } from 'assets/github.svg'
import { ReactComponent as Discord } from 'assets/discord.svg'

const Footer = () => {
  const smallBP = useMediaMax('small')
  const [topContentDirection, setTopContentDirection] = useState('row')
  const [topContentPadding, setTopContentPadding] = useState('80px 0 0')
  const [sectionHeight, setSectionHeight] = useState('450px')
  const [sectionPadding, setSectionPadding] = useState('100px 70px')
  const [displayBottomLinks, setDisplayBottomLinks] = useState('flex')
  const [bottomRowJustify, setBottomRowJustify] = useState('space-between')
  const history = useHistory()

  useEffect(() => {
    if (smallBP) {
      setTopContentDirection('column')
      setTopContentPadding('80px 0 0')
      setSectionHeight('675px')
      setSectionPadding('80px 0 250px')
      setDisplayBottomLinks('none')
      setBottomRowJustify('center')
    } else {
      setTopContentDirection('row')
      setTopContentPadding('0')
      setSectionHeight('450px')
      setSectionPadding('100px 70px')
      setDisplayBottomLinks('flex')
    }
  }, [smallBP])

  const openBlog = () => {
    window.open('https://docs.kodex.io/blog')
  }

  const openHiring = () => {
    window.open(
      'https://kodexlabs.notion.site/b38e37628d854dc0abad712efcd891b3?v=05c8d20017cb4b4cae0511f00a44e553',
      '_blank'
    )
  }

  const openDiscord = () => {
    window.open('https://docs.kodex.io', '_blank')
  }

  return (
    <View
      position="relative"
      width="100vw"
      overflow="hidden"
      height={sectionHeight}
      direction="column"
      justify="space-between"
      background="#020202"
      padding={sectionPadding}
      className="js-footer"
    >
      <img
        src={WhiteLogo}
        alt="Footer image logo"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 'calc(50% - 360px)',
          overflow: 'hidden'
        }}
        data-in="fade-up"
        className="js-footer__logo"
      />
      <View
        width="100%"
        justify="space-between"
        alignItems="center"
        direction={topContentDirection}
        className="js-footer__top"
      >
        <Link hoverOpacity={0.6}>
          <img src={Logo} alt="Footer text logo" />
        </Link>
        <View
          alignItems="center"
          direction={topContentDirection}
          padding={topContentPadding}
        >
          <FirstPageLink />
          <Space size={40} />
          <SecondPageLink />
          <Space size={40} />
          <ThirdPageLink />
        </View>
      </View>
      <View
        width="100%"
        justify={bottomRowJustify}
        alignItems="center"
        className="js-footer__bottom"
      >
        <View>
          <a target="_blank" href="https://twitter.com/kodex_io">
            <RoundedButton
              width="50px"
              height="50px"
              border="1px solid #4A4A4D"
              hoverOpacity={0.6}
              transition="opacity 0.2s ease"
            >
              <IconWrapper>
                <Twitter />
              </IconWrapper>
            </RoundedButton>
          </a>

          <Space size={8} />
          <a target="_blank" href="https://mirror.xyz/kodex.eth">
            <RoundedButton
              width="50px"
              height="50px"
              border="1px solid #4A4A4D"
              hoverOpacity={0.6}
              transition="opacity 0.2s ease"
            >
              <IconWrapper>
                <Mirror />
              </IconWrapper>
            </RoundedButton>
          </a>

          <Space size={8} />
          <a target="_blank" href="https://github.com/kodexlabs">
            <RoundedButton
              width="50px"
              height="50px"
              border="1px solid #4A4A4D"
              hoverOpacity={0.6}
              transition="opacity 0.2s ease"
            >
              <IconWrapper>
                <Github />
              </IconWrapper>
            </RoundedButton>
          </a>

          <Space size={8} />
          <a target="_blank" href="https://discord.gg/kodex">
            <RoundedButton
              width="50px"
              height="50px"
              border="1px solid #4A4A4D"
              hoverOpacity={0.6}
              transition="opacity 0.2s ease"
            >
              <IconWrapper>
                <Discord />
              </IconWrapper>
            </RoundedButton>
          </a>
        </View>
        <BottomLinksWrapper display={displayBottomLinks}>
          <View>
            <Link onClick={openBlog}>
              <Label
                color="rgba(255, 255, 255, 0.3)"
                size={16}
                weight={600}
                hoverColor="rgba(255, 255, 255, 0.2)"
              >
                Blog
              </Label>
            </Link>
            <Space size={40} />
            <Link onClick={openDiscord}>
              <Label
                color="rgba(255, 255, 255, 0.3)"
                size={16}
                weight={600}
                hoverColor="rgba(255, 255, 255, 0.2)"
              >
                Docs
              </Label>
            </Link>
            <Space size={40} />
            <Link onClick={openHiring}>
              <Label
                color="rgba(255, 255, 255, 0.3)"
                size={16}
                weight={600}
                hoverColor="rgba(255, 255, 255, 0.2)"
              >
                Hiring
              </Label>
            </Link>
          </View>
        </BottomLinksWrapper>
      </View>
    </View>
  )
}

const BottomLinksWrapper = styled.div`
  display: ${props => (props.display ? props.display : 'flex')};
`

const IconWrapper = styled.div`
  fill: white !important;
  line-height: 1;
  width: 20px;
  height: 20px;
`

export default Footer
