import { getNetworkId, getSigner } from '@ensdomains/ui'
import { Contract, ethers } from 'ethers'
import {
  emptyAddress as _emptyAddress,
  validateName as _validateName,
  parseSearchTerm as _parseSearchTerm,
  getEnsStartBlock as _ensStartBlock,
  isLabelValid as _isLabelValid,
  isEncodedLabelhash
} from '@ensdomains/ui/src/utils/index'
import { validate } from '@ensdomains/ens-validation'
import { normalize } from '@ensdomains/eth-ens-namehash'
import getENS from '../apollo/mutations/ens'
import * as jsSHA3 from 'js-sha3'
import { saveName } from '../api/labels'
import { useEffect, useRef } from 'react'
import { EMPTY_ADDRESS } from './records'
import ExchangeAbi from 'contracts/abi/KodexExchange.json'
import ERC20TokenAbi from 'contracts/abi/ERC20.json'
import ENSContractAbi from 'contracts/abi/ENS.json'
import KodexBulkRegistrarAbi from 'contracts/abi/KodexBulkRegistrar.json'
import moment from 'moment'

// From https://github.com/0xProject/0x-monorepo/blob/development/packages/utils/src/address_utils.ts

const BASIC_ADDRESS_REGEX = /^(0x)?[0-9a-f]{40}$/i
const SAME_CASE_ADDRESS_REGEX = /^(0x)?([0-9a-f]{40}|[0-9A-F]{40})$/
const ADDRESS_LENGTH = 40
export const MAINNET_DNSREGISTRAR_ADDRESS =
  '0x58774Bb8acD458A640aF0B88238369A167546ef2'
export const ROPSTEN_DNSREGISTRAR_ADDRESS =
  '0xdB328BA5FEcb432AF325Ca59E3778441eF5aa14F'

// Seaport address ⬇⬇⬇
export const EXCHANGE_CONTRACT_ADDRESS =
  '0x00000000006c3852cbef3e08e8df289169ede581'
export const WETH_TOKEN_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
export const ENS_CONTRACT_ADDRESS = '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85'
export const ENS_PRICING_ORACLE_CONTRACT_ADDRESS =
  '0x0B7CbeE19E219050e38B419273229fd24590555a'
export const ENS_BULK_REGISTRAR_ADDRESS =
  '0x946373124c353F25866D09fED05523b6A517239B'

export const COMMIT_GAS_WEI = 42000
export const REGISTER_GAS_WEI = 240000
export const TOGAL_GAS_WEI = COMMIT_GAS_WEI + REGISTER_GAS_WEI

export const networkName = {
  main: 'mainnet',
  goerli: 'goerli',
  rinkeby: 'rinkeby',
  ropsten: 'ropsten',
  local: 'local'
}

export const supportedAvatarProtocols = [
  'http://',
  'https://',
  'ipfs://',
  'eip155'
]

export const addressUtils = {
  isChecksumAddress(address) {
    // Check each case
    const unprefixedAddress = address.replace('0x', '')
    const addressHash = jsSHA3.keccak256(unprefixedAddress.toLowerCase())

    for (let i = 0; i < ADDRESS_LENGTH; i++) {
      // The nth letter should be uppercase if the nth digit of casemap is 1
      const hexBase = 16
      const lowercaseRange = 7
      if (
        (parseInt(addressHash[i], hexBase) > lowercaseRange &&
          unprefixedAddress[i].toUpperCase() !== unprefixedAddress[i]) ||
        (parseInt(addressHash[i], hexBase) <= lowercaseRange &&
          unprefixedAddress[i].toLowerCase() !== unprefixedAddress[i])
      ) {
        return false
      }
    }
    return true
  },
  isAddress(address) {
    if (!BASIC_ADDRESS_REGEX.test(address)) {
      // Check if it has the basic requirements of an address
      return false
    } else if (SAME_CASE_ADDRESS_REGEX.test(address)) {
      // If it's all small caps or all all caps, return true
      return true
    } else {
      // Otherwise check each case
      const isValidChecksummedAddress = addressUtils.isChecksumAddress(address)
      return isValidChecksummedAddress
    }
  }
}

export const uniq = (a, param) =>
  a.filter(
    (item, pos) => a.map(mapItem => mapItem[param]).indexOf(item[param]) === pos
  )

export async function getEtherScanAddr() {
  const networkId = await getNetworkId()
  switch (networkId) {
    case 1:
    case '1':
      return 'https://etherscan.io/'
    case 3:
    case '3':
      return 'https://ropsten.etherscan.io/'
    case 4:
    case '4':
      return 'https://rinkeby.etherscan.io/'
    default:
      return 'https://etherscan.io/'
  }
}

export async function ensStartBlock() {
  return _ensStartBlock()
}

export const checkLabels = (...labelHashes) => labelHashes.map(hash => null)

// export const checkLabels = (...labelHashes) =>
//   labelHashes.map(labelHash => checkLabelHash(labelHash) || null)

export const mergeLabels = (labels1, labels2) =>
  labels1.map((label, index) => (label ? label : labels2[index]))

export function validateName(name) {
  const normalisedName = _validateName(name)
  saveName(normalisedName)
  return normalisedName
}

export function isLabelValid(name) {
  return _isLabelValid(name)
}

export const parseSearchTerm = async term => {
  const ens = getENS()
  const domains = term.split('.')
  const tld = domains[domains.length - 1]
  try {
    _validateName(tld)
  } catch (e) {
    return 'invalid'
  }
  console.log('** parseSearchTerm', { ens })
  const address = await ens.getOwner(tld)
  return _parseSearchTerm(term, true)
}

export function humaniseName(name) {
  return name
    .split('.')
    .map(label => {
      return isEncodedLabelhash(label) ? `[unknown${label.slice(1, 8)}]` : label
    })
    .join('.')
}

export function modulate(value, rangeA, rangeB, limit) {
  let fromHigh, fromLow, result, toHigh, toLow
  if (limit === null) {
    limit = false
  }
  fromLow = rangeA[0]
  fromHigh = rangeA[1]
  toLow = rangeB[0]
  toHigh = rangeB[1]
  result = toLow + ((value - fromLow) / (fromHigh - fromLow)) * (toHigh - toLow)
  if (limit === true) {
    if (toLow < toHigh) {
      if (result < toLow) {
        return toLow
      }
      if (result > toHigh) {
        return toHigh
      }
    } else {
      if (result > toLow) {
        return toLow
      }
      if (result < toHigh) {
        return toHigh
      }
    }
  }
  return result
}

export function isElementInViewport(el) {
  var rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /*or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /*or $(window).width() */
  )
}

export const emptyAddress = _emptyAddress

export function isShortName(term) {
  return [...term].length < 3
}

export const aboutPageURL = () => {
  const lang = window.localStorage.getItem('language') || ''

  return `https://ens.domains/${lang === 'en' ? '' : lang}`
}

export function isRecordEmpty(value) {
  return value === emptyAddress || value === ''
}

export const hasValidReverseRecord = getReverseRecord =>
  getReverseRecord?.name && getReverseRecord.name !== emptyAddress

export const hasNonAscii = () => {
  const strs = window.location.pathname.split('/')
  const rslt = strs.reduce((accum, next) => {
    if (accum) return true
    if (!validate(next)) return true
    return accum
  }, false)
  return rslt
}

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()
  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export function isOwnerOfParentDomain(domain, account) {
  if (!account) return false
  if (domain.parentOwner !== EMPTY_ADDRESS) {
    return domain.parentOwner?.toLowerCase() === account.toLowerCase()
  }
  return false
}

export function filterNormalised(data, name, nested = false) {
  return data?.filter(data => {
    const domain = nested ? data.domain : data
    return domain[name] === normalize(domain[name])
  })
}

export function prependUrl(url) {
  if (url && !url.match(/http[s]?:\/\//)) {
    return 'https://' + url
  } else {
    return url
  }
}

export function imageUrl(url, name, network) {
  const _network = networkName[network?.toLowerCase()]
  const _protocol = supportedAvatarProtocols.find(proto =>
    url.startsWith(proto)
  )
  // check if given uri is supported
  // provided network name is valid,
  // domain name is available
  if (_protocol && _network && name) {
    return `https://metadata.ens.domains/${_network}/avatar/${name}`
  }
  console.warn('Unsupported avatar', network, name, url)
}

export const getExchangeContract = async () => {
  try {
    const signer = await getSigner()
    const contract = new Contract(
      EXCHANGE_CONTRACT_ADDRESS,
      ExchangeAbi,
      signer
    )
    return contract
  } catch (err) {
    console.log('get exchange contract error: ', err)
    return null
  }
}

export const getERC20TokenContract = async address => {
  try {
    const signer = await getSigner()
    const tokenContract = new Contract(address, ERC20TokenAbi, signer)
    return tokenContract
  } catch (err) {
    console.log('erc20 token error: ', err)
    return null
  }
}

export const getENSContract = async () => {
  try {
    const signer = await getSigner()
    const contract = new Contract(ENS_CONTRACT_ADDRESS, ENSContractAbi, signer)
    return contract
  } catch (err) {
    console.log('ens contract create error: ', err)
    return null
  }
}

export const getBulkRegistrarContract = async () => {
  try {
    const signer = await getSigner()
    const contract = new Contract(
      ENS_BULK_REGISTRAR_ADDRESS,
      KodexBulkRegistrarAbi,
      signer
    )
    return contract
  } catch (err) {
    console.log('bulk registrar contract create error: ', err)
    return null
  }
}

export const weiToEthNum = balance => {
  return Number(ethers.utils.formatEther(balance))
}

export const changeDateFormat = timestamp => {
  return moment(timestamp).format('MM/DD')
}

export const getCloudinaryUrl = path => {
  return `https://res.cloudinary.com/kodex-solutions/image/upload/v1658396195/${path}.jpg`
}

const minuteRage = 60
const hourRange = 60 * 60
const dayRange = 60 * 60 * 24
const monthRange = 60 * 60 * 24 * 30
const yearRange = 60 * 60 * 24 * 365

export const getTimeFromDate = date => {
  if (!date) return ''

  const time = new Date().getTime() / 1000 - Number(date)
  if (time > yearRange) {
    return `${Math.floor(time / yearRange)} years ago`
  } else if (time > monthRange) {
    return `${Math.floor(time / monthRange)} months ago`
  } else if (time > dayRange) {
    return `${Math.floor(time / dayRange)} days ago`
  } else if (time > hourRange) {
    return `${Math.floor(time / hourRange)} hours ago`
  } else {
    return `${Math.floor(time / minuteRage)} mins ago`
  }
}

export const makeHttpLink = url => {
  if (!url) return ''

  if (url.includes('http')) return url

  return `https://${url}`
}

export const parseCookie = str =>
  str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
      return acc
    }, {})
