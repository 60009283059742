import React, { useEffect } from 'react'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import RoundedButton from 'components/Forms/RoundedButton'
import { useEthPrice } from 'hooks/useEthPrice'
import { utils } from 'ethers'
import { useLazyQuery } from '@apollo/client'
import {
  GET_CATEGORY_FLOOR_PRICE,
  kodexApiContext
} from 'service/graphql/kodex-api'

const SummaryThirdColumn = ({ eventType, loading, data, category }) => {
  const [fetchFloorPrice, { data: floorPriceData }] = useLazyQuery(
    GET_CATEGORY_FLOOR_PRICE,
    {
      ...kodexApiContext,
      fetchPolicy: 'no-cache'
    }
  )

  const getColumnTitle = () => {
    if (eventType === 'registration') {
      return 'Type'
    }
    if (eventType === 'listing') {
      return 'Service'
    }
    return category ? 'Floor difference' : ''
  }

  const ethPrice = useEthPrice()

  const getLabel = () => {
    if (eventType === 'registration') {
      const ethFromWei = utils.formatEther(BigInt(data))
      return Number(ethPrice) * Number(ethFromWei) > 640
        ? 'Premium'
        : 'Standard'
    }
    return data
  }

  const getFloorDifference = () => {
    const floorPriceRaw =
      floorPriceData?.get_feed_aggregate?.aggregate?.min.price
    if (!floorPriceRaw) {
      return ''
    }
    const floorPrice = Number(utils.formatEther(BigInt(floorPriceRaw)))
    return `+${Number(
      (
        (1 - floorPrice / Number(utils.formatEther(BigInt(data)))) *
        100
      ).toFixed(2)
    )}%`
  }

  useEffect(() => {
    if (eventType !== 'offer' || category == null) {
      return
    }
    fetchFloorPrice({
      variables: {
        search_taxa: `{${category}}`
      }
    })
  }, [])

  return (
    <View flex={1} direction="column" padding="0 8px 0 0">
      <Label size={13} opacity="0.5">
        {getColumnTitle()}
      </Label>
      <Space size={16} />
      <View alignItems="center" height="40px">
        {loading ? (
          <View
            width="100px"
            height="16px"
            background="#2C2C2C"
            borderRadius="6px"
          />
        ) : eventType === 'offer' ? (
          category ? (
            <RoundedButton
              padding="12px 10px"
              borderRadius="6px"
              background="#E8F5F6"
              cursor="auto"
            >
              <Label size={16} color="#0698A8">
                {getFloorDifference()}
              </Label>
            </RoundedButton>
          ) : (
            ''
          )
        ) : (
          <Label size={16}>{getLabel()}</Label>
        )}
      </View>
    </View>
  )
}

export default SummaryThirdColumn
