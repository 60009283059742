import React, { useCallback, useEffect, useState } from 'react'
import omit from 'lodash/omit'
import getTableColumns from './getTableColumns'

const useCellDescriptor = props => {
  const { children, headerHeight, rowHeight } = props

  const [cacheData, setCacheData] = useState()

  const clearCache = useCallback(() => {
    setCacheData(null)
  })

  useEffect(() => {
    clearCache()
  }, [children])

  if (cacheData) {
    return cacheData
  }

  const headerCells = []
  const bodyCells = []

  if (!children) {
    const cacheCell = {
      columns: [],
      headerCells,
      bodyCells
    }

    setCacheData(cacheCell)
    return cacheCell
  }

  const columns = getTableColumns(children)
  React.Children.forEach(columns, (column, index) => {
    if (React.isValidElement(column)) {
      const columnChildren = column.props.children

      const headerCell = columnChildren[0]
      const cell = columnChildren[1]

      const cellProps = {
        ...omit(column.props, ['children', 'width']),
        headerHeight,
        key: index,
        width: column.props.width,
        height: rowHeight
      }
      const headerCellProps = {
        index,
        dataKey: cell.props.dataKey,
        isHeaderCell: true,
        width: column.props.width
      }

      headerCells.push(
        React.cloneElement(headerCell, { ...cellProps, ...headerCellProps })
      )
      bodyCells.push(React.cloneElement(cell, cellProps))
    }
  })

  const cacheCell = {
    columns,
    headerCells,
    bodyCells
  }

  setCacheData(cacheCell)
  return cacheCell
}

export default useCellDescriptor
