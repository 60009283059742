import styled from '@emotion/styled'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import Space from 'components/Space'
import View from 'components/View'
import React, { useRef, useState } from 'react'
import Section from './Section'
import AlcibiadesColored from 'assets/about-page/team/alcibiades-colored.webp'
import Alcibiades from 'assets/about-page/team/alcibiades.webp'
import RoninColored from 'assets/about-page/team/ronin-colored.webp'
import Ronin from 'assets/about-page/team/ronin.webp'
import ChosenColored from 'assets/about-page/team/chosen-ph-colored.webp'
import Chosen from 'assets/about-page/team/chosen-ph.webp'
import EncryptedColored from 'assets/about-page/team/encrypted-ph-colored.webp'
import Encrypted from 'assets/about-page/team/encrypted-ph.webp'
import JtrileyColored from 'assets/about-page/team/jtriley-colored.webp'
import Jtriley from 'assets/about-page/team/jtriley.webp'
import KostyamblColored from 'assets/about-page/team/kostyambl-ph-colored.webp'
import Kostyambl from 'assets/about-page/team/kostyambl-ph.webp'
import MangoColored from 'assets/about-page/team/mango-colored.webp'
import Mango from 'assets/about-page/team/mango.webp'
import PerpetuumColored from 'assets/about-page/team/perpetuum-ph-colored.webp'
import Perpetuum from 'assets/about-page/team/perpetuum-ph.webp'
import RelayColored from 'assets/about-page/team/relay-colored.webp'
import Relay from 'assets/about-page/team/relay.webp'
import Singularity2045Colored from 'assets/about-page/team/singularity2045-colored.webp'
import Singularity2045 from 'assets/about-page/team/singularity2045.webp'
import VirtualJpegColored from 'assets/about-page/team/virtualjpeg-colored.webp'
import VirtualJpeg from 'assets/about-page/team/virtualjpeg.webp'
import ZvetColored from 'assets/about-page/team/zvet-colored.webp'
import Zvet from 'assets/about-page/team/zvet.webp'

const TEAM = [
  {
    image: Ronin,
    coloredImage: RoninColored,
    name: 'ronin.eth',
    href: 'https://twitter.com/roninfrog'
  },
  {
    image: Chosen,
    coloredImage: ChosenColored,
    name: 'chosen.eth',
    href: 'https://twitter.com/0xchosen'
  },
  {
    image: Perpetuum,
    coloredImage: PerpetuumColored,
    name: 'perpetuum7.eth',
    href: 'https://twitter.com/_perpetuum7'
  },
  {
    image: Encrypted,
    coloredImage: EncryptedColored,
    name: 'encryptedd.eth',
    href: 'https://github.com/encryptedDegen'
  },
  {
    image: Kostyambl,
    coloredImage: KostyamblColored,
    name: 'コスチャ.eth',
    href: 'https://twitter.com/newcryptosheesh'
  },
  {
    image: Alcibiades,
    coloredImage: AlcibiadesColored,
    name: 'alcibiades.eth',
    href: 'https://twitter.com/0xAlcibiades'
  },
  {
    image: Jtriley,
    coloredImage: JtrileyColored,
    name: 'jtriley.eth',
    href: 'https://twitter.com/jtriley_eth'
  },
  {
    image: Singularity2045,
    coloredImage: Singularity2045Colored,
    name: 'msx.eth',
    href: 'https://github.com/MarcusWentz'
  },
  {
    image: Zvet,
    coloredImage: ZvetColored,
    name: 'zvet.eth',
    href: 'https://github.com/Tevzi2'
  },
  {
    image: VirtualJpeg,
    coloredImage: VirtualJpegColored,
    name: 'virtualjpeg.eth',
    href: 'https://twitter.com/9dd4134'
  },
  {
    image: Relay,
    coloredImage: RelayColored,
    name: 'r3lay.eth',
    href: 'https://github.com/re1ay'
  },
  {
    image: Mango,
    coloredImage: MangoColored,
    name: 'mangooo.eth',
    href: 'https://github.com/mango-rasa'
  }
]

export const Team = () => {
  const sectionRef = useRef(null)

  // useEffect(() => {
  //   if (sectionRef.current == null) {
  //     return
  //   }
  // }, [])

  return (
    <Section ref={sectionRef} background="#F3F3FA" borderRadius="20px">
      <Container maxWidth="1080px">
        <View direction="column" padding="250px 0">
          <Label size={60} color="#020202">
            Builders
          </Label>
          <Space size={100} />
          <TeamGrid>
            {TEAM.map(teamMember => (
              <TeamMember key={teamMember.name} {...teamMember} />
            ))}
          </TeamGrid>
        </View>
      </Container>
    </Section>
  )
}

const TeamMember = ({ image, coloredImage, name, href }) => {
  const [hovered, setHovered] = useState(false)

  const onMouseEnter = () => {
    setHovered(true)
  }

  const onMouseLeave = () => {
    setHovered(false)
  }

  return (
    <View direction="column" justify="center">
      <ImageWrapper
        target="_blank"
        href={href}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Image src={image} hovered={hovered} width={255} height={255} />
        <ColoredImage src={coloredImage} hovered={hovered} />
      </ImageWrapper>
      <Space size={20} />
      <Label size={16} color="#020202">
        {name}
      </Label>
    </View>
  )
}

const TeamGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 60px;
  align-self: center;
  justify-self: center;
`

const ImageWrapper = styled.a`
  width: 255px;
  height: 255px;
  min-height: 255px;
  min-width: 255px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
`

const Image = styled.img`
  width: 255px;
  height: 255px;
  position: absolute;
  z-index: 2;
  opacity: ${props => (props.hovered ? 0 : 1)};
  transition: all 0.5s ease;
`

const ColoredImage = styled.img`
  width: 255px;
  height: 255px;
  position: relative;
  transition: all 0.25s ease;
`

export default Team
