import React from 'react'
import View from 'components/View'
import Space from 'components/Space'
import styled from '@emotion/styled/macro'
import { ReactComponent as EthPriceSelected } from 'assets/eth-price-white-small.svg'
import { ReactComponent as EthPrice } from 'assets/eth-price-disabled.svg'
import { ReactComponent as UsdcPriceSelected } from 'assets/usdc-price.svg'
import { ReactComponent as UsdcPrice } from 'assets/usdc-price-disabled.svg'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Card from './Card'

const PriceRangeSelector = ({
  currency,
  from,
  to,
  onCurrencyChange,
  onToChanged,
  onFromChanged
}) => {
  return (
    <Card
      title="Price range"
      HeaderTrailingElement={
        <CurrencySelector value={currency} onChange={onCurrencyChange} />
      }
    >
      <View
        width="100%"
        direction="column"
        justify="center"
        background="#fff"
        borderRadius="10px"
        overflow="visible"
      >
        <Space size={20} />
        <PriceInput label={currency} value={from} onChange={onFromChanged} />
        <Divider />
        <PriceInput label={currency} value={to} onChange={onToChanged} />
      </View>
    </Card>
  )
}

const CurrencySelector = ({ value, onChange }) => {
  return (
    <CurrencySelectorContainer>
      <CurrencySelectorButton
        icon={value === 'ETH' ? <EthPriceSelected /> : <EthPrice />}
        iconColor={value === 'ETH' ? '#020202' : '#BCBCCC'}
        onClick={() => onChange('ETH')}
        background={value === 'ETH' ? '#fff' : 'transparent'}
      />
      <CurrencySelectorButton
        icon={value === 'USDC' ? <UsdcPriceSelected /> : <UsdcPrice />}
        iconColor={value === 'USDC' ? '#020202' : '#BCBCCC'}
        onClick={() => onChange('USDC')}
        background={value === 'USDC' ? '#fff' : 'transparent'}
      />
      {/*<CurrencySelectorButton*/}
      {/*  icon={value === 'USD' ? <UsdPriceSelected /> : <UsdPrice />}*/}
      {/*  iconColor={value === 'USD' ? '#020202' : '#BCBCCC'}*/}
      {/*  onClick={() => onChange('USD')}*/}
      {/*  background={value === 'USD' ? '#fff' : 'transparent'}*/}
      {/*/>*/}
    </CurrencySelectorContainer>
  )
}

const CurrencySelectorButton = ({ icon, onClick, background }) => {
  return (
    <View flex={1}>
      <RoundedButton
        width="40px"
        height="40px"
        background={background}
        onClick={onClick}
        borderRadius="6px"
        margin="auto"
      >
        <IconWrapper>{icon}</IconWrapper>
      </RoundedButton>
    </View>
  )
}

const PriceInput = ({ value, onChange, label }) => {
  const onInputChange = e => {
    const reg = /^[+-]?(\d*\.\d+|\d+\.\d*|\d+)/

    const match = e.target.value.match(reg)
    if (e.target.value.length === 0 || (match && e.target.value === match[0])) {
      onChange(e.target.value)
    }
  }

  return (
    <View width="100%">
      <PriceInputComponent
        value={value}
        onChange={onInputChange}
        placeholder="0.00"
        padding={`20px ${label === 'ETH' ? '40px' : '50px'} 20px 0`}
      />
      <CurrencyLabel>
        <Label size={16} color="#020202">
          {label}
        </Label>
      </CurrencyLabel>
    </View>
  )
}

export default PriceRangeSelector

const PriceInputComponent = styled.input`
  color: #020202;
  padding: ${props => props.padding};
  border: none;
  width: 100%;
  font-size: 16px;
  caret-color: #a24f9d;
  line-height: 1;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: #bcbccc;
    opacity: 1;
    line-height: 1;
  }

  &:focus-visible {
    outline: none;
  }
`

const CurrencyLabel = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

const IconWrapper = styled.div`
  transform: ${props => props.rotate};
  transition: transform 0.3s ease;
  height: 20px;
`

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #e1e1e8;
  height: 1px;
  width: 100%;
  margin: 0;
`

const CurrencySelectorContainer = styled.div`
  z-index: 1;
  display: flex;
  background: #f3f3fa;
  border-radius: 10px;
  padding: 5px;
`
