import React from 'react'
import styled from '@emotion/styled/macro'
import Label from '../Label'

const ClearButton = ({ filtersCount, onClick }) => {
  return (
    <ToolBarButtonComponent onClick={onClick}>
      <Label size={16} weight={600}>
        Clear options
      </Label>
      <FiltersCount>{filtersCount}</FiltersCount>
    </ToolBarButtonComponent>
  )
}

const ToolBarButtonComponent = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: #0698a8;
  border-radius: 10px;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  width: 100%;
  cursor: pointer;
  padding: 15px 15px 15px 30px;
`

const FiltersCount = styled.div`
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 16px;
  line-height: 1;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default ClearButton
