import { useQuery } from '@apollo/client'
import styled from '@emotion/styled/macro'
import { getSigner } from '@ensdomains/ui'
import { ReactComponent as ListWhite } from 'assets/list-white.svg'
import { useWindowListener } from 'common/useWindowListener'
import AcceptSuccessModal from 'components/AcceptModal/AcceptSuccessModal'
import BottomModal from 'components/BottomModal'
import NetTokenCard from 'components/Card/NetTokenCard'
import ListingDialog from 'components/Dialogs/ListingDialog'
import { DomainView, useDomainViewContext } from 'components/Domain'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import { useAccount } from 'components/QueryAccount'
import { useColumnCount } from 'components/ResultList/hooks/useColumnCount'
import KodexSidebar from 'components/Sidebar'
import View from 'components/View'
import { DOMAIN_TABLE_COLUMNS_BREAKPOINTS } from 'constants/breakpoints'
import { AccountContext } from 'contexts/AccountProvider'
import { CartStoreContext } from 'contexts/CartStoreProvider'
import { FilterContext } from 'contexts/FilterProvider'
import { BigNumber, ethers } from 'ethers'
import { getAddress, isAddress } from 'ethers/lib/utils'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useLocation, useParams } from 'react-router-dom'
import {
  GET_DOMAINS_BY_TOKENID,
  GET_LABEL_NAME_FROM_HASH,
  GET_LIKES_BY_USER,
  GET_USER_ADDRESS_FROM_USERNAME,
  GET_USER_LIKES,
  kodexApiContext
} from 'service/graphql/kodex-api'
import { getListing } from 'service/rest/getListing'
import { getOffers } from 'service/rest/getOffers'
import { actions } from 'utils/reservoir'
import { ENS_CONTRACT_ADDRESS } from 'utils/utils'
import SettingDialog from '../Topbar/SettingDialog'
import ProfileHeader from './Header'
import NoResult from './NoResult'
import Activities from './Tables/Activities'
import ProfileOffers from './Tables/ProfileOffers'
import ActivityDropdown from './components/ActivityDropdown'
import ProfileOffersDropdown from './components/ProfileOffersDropdown'
import useDomainsData from './useDomainsData'
import HeaderBar from 'components/HeaderBar'
import NetTokenRow from 'components/Row/NetTokenRow'
import { OfferDialog } from 'components/Dialogs'
import { getActivitiyData } from 'service/rest/getActivityData'

const NAV_ITEMS = [
  'listed',
  'unlisted',
  'activity',
  'likes',
  'trophies',
  'offers'
]

export const OFFERS_DROPDOWN_ITEMS = {
  SENT: 'Sent',
  RECEIVED: 'Received'
}

const ACTIVITY_DROPDOWN_ITEMS = [
  'All',
  'Sale',
  'Purchase',
  'Transfer',
  'Registration'
]

let dataIsLoading = true

const Profile = () => {
  const history = useHistory()
  const location = useLocation()

  const { id } = useParams()
  // const defaultNavItem = location.search.slice(1)
  const defaultNavItem = location.search.slice(1)
  const [navItem, setNavItem] = useState(
    NAV_ITEMS.includes(defaultNavItem) ? defaultNavItem : NAV_ITEMS[0]
  )
  const { account, refetchUserData } = useContext(AccountContext)
  const accountAddress = useAccount()

  const cartCtx = useContext(CartStoreContext)
  const domainsInCart = cartCtx.state.domains

  const {
    profileFilter: filter,
    openedProfile: filtersOpened,
    toggleOpenProfile,
    setProfileFilter: setFilter,
    setActiveCurrency,
    activeCurrency,
    viewType,
    setViewType
  } = useContext(FilterContext)

  const viewTypeValue = viewType.marketplace.view

  const handleOpenFilters = () => {
    toggleOpenProfile()
  }

  const handleHideFilters = () => {
    toggleOpenProfile()
  }

  // const pageRef = useRef(null)

  const [categoriesModalOpen, setCategoriesModalOpen] = useState(false)
  const [refetchDomains, setRefetchDomains] = useState(false)

  const [listedDomainsIDs, setListedDomainsIDs] = useState([''])
  const [offerDomainIds, setOfferDomainIds] = useState([])

  const [cancelingIndex, setCancelingIndex] = useState(undefined)
  const [acceptingIndex, setAcceptingIndex] = useState(undefined)
  const [successModalOpen, setSuccessModalOpen] = useState(false)

  const [listingDialogOpen, setListingDialogOpen] = useState(false)
  const [ListCounterOpacity, setListCounterOpacity] = useState(1)

  const [offerDialogOpen, setOfferDialogOpen] = useState(false)
  const [offeredDomainName, setOfferedDomainName] = useState('')
  const [offeredDomainlastPrice, setOfferedDomainLastPrice] = useState('')

  const [shownUserActivity, setShownUserActivity] = useState([])
  const [shownListedDomains, setShownListedDomains] = useState([])
  const [shownUnlistedDomains, setShownUnlistedDomains] = useState([])
  const [shownLikedDomains, setShownLikedDomains] = useState([])
  const [domainsToList, setDomainsToList] = useState([])

  const [resolvedENSName, setResolvedENSName] = useState('')
  const [primaryName, setPrimaryName] = useState('')

  const [userActivity, setActivity] = useState([])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const { profileTabs, setProfileTabs } = useContext(FilterContext)

  const provider = new ethers.providers.StaticJsonRpcProvider(
    process.env.REACT_APP_LLAMA_NODES_URL,
    'homestead'
  )

  const filterFetchedDomains = (domains = []) => {
    if (filter.search) {
      const filteredDomains = domains.filter(
        domain =>
          domain?.name?.includes(filter?.search) ||
          domain?.domain?.includes(filter?.search)
      )

      if (filter.sort && filter.sort.length > 0) {
        filteredDomains?.sort((a, b) => {
          if (filter.sort === 'alphabetical') {
            if (navItem === NAV_ITEMS[3]) {
              if (a.domain < b.domain) {
                return -1
              }
              if (a.domain > b.domain) {
                return 1
              }
            }

            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
          }

          if (filter.sort === 'most_favorited') {
            if (navItem === NAV_ITEMS[1]) return b.likes - a.likes

            if (navItem === NAV_ITEMS[0])
              return (
                b.likes_aggregate?.aggregate.count -
                a.likes_aggregate?.aggregate.count
              )

            if (navItem === NAV_ITEMS[3])
              return b.domainDetails?.likes - a.domainDetails?.likes
          }

          if (filter.sort === 'highest_last_sale') {
            if (navItem === NAV_ITEMS[3]) {
              return b.domainDetails?.last_price - a.domainDetails?.last_price
            }

            if (navItem === NAV_ITEMS[0]) {
              return (
                (b.ens_sales?.[0]?.saleassetamount || 0) -
                (a.ens_sales?.[0]?.saleassetamount || 0)
              )
            }

            return BigNumber.from(b.amount || 0)
              .sub(BigNumber.from(a.amount || 0))
              .toNumber()
          }

          if (filter.sort === 'expires_soonest') {
            if (navItem === NAV_ITEMS[1]) {
              return BigNumber.from(a.expire_time || 0)
                .sub(BigNumber.from(b.expire_time || 0))
                .toNumber()
            }

            if (navItem === NAV_ITEMS[3]) {
              return BigNumber.from(a.domainDetails?.expire_time || 0)
                .sub(BigNumber.from(b.domainDetails?.expire_time || 0))
                .toNumber()
            }
          }

          if (filter.sort === 'expires_latest') {
            if (navItem === NAV_ITEMS[1])
              return BigNumber.from(b.expire_time || 0)
                .sub(BigNumber.from(a.expire_time || 0))
                .toNumber()

            if (navItem === NAV_ITEMS[3])
              return BigNumber.from(b.domainDetails?.expire_time || 0)
                .sub(BigNumber.from(a.domainDetails?.expire_time || 0))
                .toNumber()
          }

          if (filter.sort === 'price_high_to_low' && navItem === NAV_ITEMS[0]) {
            return a
          }

          if (filter.sort === 'price_low_to_high' && navItem === NAV_ITEMS[0]) {
            return a
          }

          return a
        })
      }

      return filteredDomains
    }

    if (filter.sort && filter.sort.length > 0) {
      const sortedDomains = [...domains]
      sortedDomains?.sort((a, b) => {
        if (filter.sort === 'alphabetical') {
          if (navItem === NAV_ITEMS[3]) {
            if (a.domain < b.domain) {
              return -1
            }
            if (a.domain > b.domain) {
              return 1
            }
          }

          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
        }

        if (filter.sort === 'most_favorited') {
          if (navItem === NAV_ITEMS[1]) return b.likes - a.likes

          if (navItem === NAV_ITEMS[0])
            return (
              b.likes_aggregate?.aggregate.count -
              a.likes_aggregate?.aggregate.count
            )

          if (navItem === NAV_ITEMS[3])
            return b.domainDetails?.likes - a.domainDetails?.likes
        }

        if (filter.sort === 'highest_last_sale') {
          if (navItem === NAV_ITEMS[3]) {
            return b.domainDetails?.last_price - a.domainDetails?.last_price
          }

          if (navItem === NAV_ITEMS[0]) {
            return (
              (b.ens_sales?.[0]?.saleassetamount || 0) -
              (a.ens_sales?.[0]?.saleassetamount || 0)
            )
          }

          return BigNumber.from(b.amount || 0)
            .sub(BigNumber.from(a.amount || 0))
            .toNumber()
        }

        if (filter.sort === 'expires_soonest') {
          if (navItem === NAV_ITEMS[1]) {
            return BigNumber.from(a.expire_time || 0)
              .sub(BigNumber.from(b.expire_time || 0))
              .toNumber()
          }

          if (navItem === NAV_ITEMS[3]) {
            return BigNumber.from(a.domainDetails?.expire_time || 0)
              .sub(BigNumber.from(b.domainDetails?.expire_time || 0))
              .toNumber()
          }
        }

        if (filter.sort === 'expires_latest') {
          if (navItem === NAV_ITEMS[1])
            return BigNumber.from(b.expire_time || 0)
              .sub(BigNumber.from(a.expire_time || 0))
              .toNumber()

          if (navItem === NAV_ITEMS[3])
            return BigNumber.from(b.domainDetails?.expire_time || 0)
              .sub(BigNumber.from(a.domainDetails?.expire_time || 0))
              .toNumber()
        }

        if (filter.sort === 'price_high_to_low' && navItem === NAV_ITEMS[0]) {
          return a
        }

        if (filter.sort === 'price_low_to_high' && navItem === NAV_ITEMS[0]) {
          return a
        }

        return a
      })

      if (sortedDomains.length > 0) return sortedDomains
    }

    return domains
  }

  useEffect(() => {
    setFilter({})

    if (window.innerWidth <= 740) {
      history.push('/marketplace')
      return
    }
  }, [])

  useEffect(() => {
    setFilter({})

    if (location.search.slice(1)) {
      setNavItem(location.search.slice(1))
    }
  }, [location])

  useEffect(() => {
    if (!defaultNavItem) return
    window.scrollTo({
      left: 0,
      top: 400,
      behavior: 'smooth'
    })
  }, [defaultNavItem])

  const { data: resolvedUserAddressData } = useQuery(
    GET_USER_ADDRESS_FROM_USERNAME,
    {
      variables: {
        username: id
      },
      ...kodexApiContext,
      fetchPolicy: 'no-cache'
    }
  )

  const resolveENSName = async ensName => {
    const resolvedAttempt = await provider
      .resolveName(ensName || '')
      .catch(() => null)

    const lookup = await provider
      .lookupAddress(resolvedAttempt)
      .catch(() => null)

    if (!lookup) return

    setPrimaryName(lookup)
    setResolvedENSName(resolvedAttempt)
  }

  useEffect(() => {
    setFilter({})
    resolveENSName(id)
  }, [id])

  const address = useMemo(() => {
    const zeroExPrepended = `0x${id}`

    if (
      id === 'my-profile' ||
      zeroExPrepended?.toLowerCase() === accountAddress?.toLowerCase()
    ) {
      return account
    } else if (isAddress(id?.toLowerCase())) {
      return getAddress(id?.toLowerCase())
    } else if (isAddress(zeroExPrepended)) {
      return getAddress(zeroExPrepended)
    } else if (resolvedUserAddressData?.users[0]?.user) {
      return resolvedUserAddressData.users[0].user
    }
    if (isAddress(resolvedENSName)) {
      return resolvedENSName
    }

    return '0x'
  }, [id, account, resolvedUserAddressData, resolvedENSName])

  const isMyProfile = address?.toLowerCase() === accountAddress?.toLowerCase()

  const isOwnProfile =
    id === 'my-profile' ||
    address?.toLowerCase() === accountAddress.toLowerCase()

  // const checkSummedAddress = isAddress(address)
  //   ? ethers.utils.getAddress(address)
  //   : ''

  const fetchActivityData = async () => {
    if (!address || !isAddress(address)) {
      setActivity([])
      return
    }
    const res = await getActivitiyData({
      limit: 250,
      offset: 0,
      user: address
    })

    setActivity(res)
  }

  const loadMoreUserActivity = () => {
    if (!userActivity && userActivity.length === 0) return
    if (shownUserActivity?.length >= userActivity?.length) return

    const userActivityCopy = userActivity

    const moreUserActivity = userActivityCopy.slice(
      shownUserActivity.length,
      shownUserActivity.length + 30
    )

    setShownUserActivity([...shownUserActivity, ...moreUserActivity])
  }

  useEffect(() => {
    if (!userActivity) return

    const userActivityCopy = userActivity

    setShownUserActivity(
      userActivityCopy?.slice(
        0,
        userActivity?.length >= 30 ? 30 : userActivity?.length
      )
    )
  }, [userActivity, navItem])

  const domains = useDomainsData(address)

  const fetchListings = async () => {
    const fetchedListedDomains = isAddress(address)
      ? await getListing({
          address: ENS_CONTRACT_ADDRESS,
          maker: id === 'my-profile' ? accountAddress : address
        })
      : []

    const loadedFromLocalStorage = domains.filter(
      d =>
        localStorage.getItem(`${d?.name.replace('.eth', '')}-listing`) &&
        moment().isBefore(
          JSON.parse(
            localStorage.getItem(`${d?.name.replace('.eth', '')}-listing`)
          )?.expires
        )
    )

    loadedFromLocalStorage.map(d => fetchedListedDomains.push(d))

    const transformedDomains = new Set()

    fetchedListedDomains?.map(listDom =>
      transformedDomains.add(
        listDom.criteria
          ? BigNumber.from(listDom.criteria?.data?.token?.tokenId).toHexString()
          : listDom.id
      )
    )

    const tokenIds = []
    transformedDomains.forEach(id => tokenIds.push(id))

    setListedDomainsIDs(tokenIds)
  }

  useEffect(() => {
    if (!address || !accountAddress) return
    dataIsLoading = true
    fetchActivityData()
    refetchUserData()
    fetchListings()
  }, [accountAddress, address])

  const { data: listedDomains, refetch: refetchListedDomains } = useQuery(
    GET_DOMAINS_BY_TOKENID,
    {
      variables: { id: listedDomainsIDs },
      ...kodexApiContext,
      onCompleted: () => {
        dataIsLoading = false
      }
    }
  )

  const filteredListedDomains = useMemo(() => {
    const filteredListedDomains = filterFetchedDomains(listedDomains?.domains)
    return { domains: filteredListedDomains }
  }, [listedDomains, filter])

  useEffect(() => {
    refetchListedDomains({
      id: listedDomainsIDs
    })
  }, [listedDomainsIDs])

  const listedDomainsNames = useMemo(
    () => listedDomains?.domains?.map(d => d.name),
    [listedDomains]
  )

  useEffect(() => {
    if (location.search.includes('offers')) setNavItem(NAV_ITEMS[5])
  }, [location.search])

  const unlistedDomains = useMemo(
    () =>
      domains?.filter(
        d =>
          !listedDomainsNames?.includes(d.name) &&
          moment()
            .subtract(90, 'days')
            .isBefore(Number(d.expire_time) * 1000)
      ),
    [domains, listedDomainsNames]
  )

  const filteredUnlistedDomains = useMemo(() => {
    return filterFetchedDomains(unlistedDomains)
  }, [unlistedDomains, filter])

  const loadMoreListedDomains = () => {
    if (!filteredListedDomains || filteredListedDomains.length === 0) return
    if (shownListedDomains?.length >= filteredListedDomains?.domains?.length)
      return

    const listedDomainsCopy = filteredListedDomains?.domains

    const moreListedDomains = listedDomainsCopy?.slice(
      shownListedDomains.length,
      shownListedDomains.length + 20
    )

    setShownListedDomains([...shownListedDomains, ...moreListedDomains])
  }

  useEffect(() => {
    if (!listedDomains?.domains || !filteredListedDomains?.domains) return

    const listedDomainsCopy = filteredListedDomains?.domains

    setShownListedDomains(
      listedDomainsCopy?.slice(
        0,
        filteredListedDomains?.domains?.length >= 20
          ? 20
          : filteredListedDomains?.domains?.length
      )
    )
  }, [listedDomains, filteredListedDomains])

  const loadMoreUnlistedDomains = () => {
    if (!filteredUnlistedDomains || filteredUnlistedDomains.length === 0)
      returnuseEffect
    if (shownUnlistedDomains?.length >= filteredUnlistedDomains?.length) return

    const unlistedDomainsCopy = filteredUnlistedDomains

    const moreUnlistedDomains = unlistedDomainsCopy?.slice(
      shownUnlistedDomains.length,
      shownUnlistedDomains.length + 20
    )

    if (!moreUnlistedDomains?.length) return

    setShownUnlistedDomains([...shownUnlistedDomains, ...moreUnlistedDomains])
  }

  useEffect(() => {
    if (!unlistedDomains || !filteredUnlistedDomains) return

    const unlistedDomainsCopy = filteredUnlistedDomains

    setShownUnlistedDomains(
      unlistedDomainsCopy?.slice(
        0,
        filteredUnlistedDomains?.length >= 20
          ? 20
          : filteredUnlistedDomains?.length
      )
    )
  }, [unlistedDomains, filteredUnlistedDomains])

  const [openSettings, setOpenSettings] = useState(false)

  const [offersDropdownOpen, setOffersDropdownOpen] = useState(false)
  const [offersDropdownItem, setOffersDropdownItem] = useState(
    OFFERS_DROPDOWN_ITEMS.SENT
  )
  const [refetchDomainOffers, setRefetchDomainOffers] = useState(false)

  const [activityDropdownOpen, setActivityDropdownOpen] = useState(false)
  const [activityDropdownItem, setActivityDropdownItem] = useState(
    ACTIVITY_DROPDOWN_ITEMS[0]
  )
  // const [activityLimit, setActivityLimit] = useState(50)

  const [cardWidth, setCardWidth] = useState('310px')

  const scrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: 450,
      behavior: 'smooth'
    })
  }

  const {
    data: { likes: likesRes = [] } = {},
    refetch: refetchLikedDomains
  } = useQuery(isOwnProfile ? GET_USER_LIKES : GET_LIKES_BY_USER, {
    variables: {
      user: address?.toLowerCase()
    },
    skip: !isAddress(address) || !isAddress(accountAddress),
    fetchPolicy: isOwnProfile ? 'cache-only' : 'no-cache',
    ...kodexApiContext
  })

  const likeDomains = useMemo(
    () =>
      likesRes.length > 0
        ? isOwnProfile
          ? likesRes?.[0]?.userInfo?.list ?? []
          : likesRes.map(r => r.domainDetails)
        : [],
    [likesRes]
  )

  const filteredLikedDomains = useMemo(() => {
    return filterFetchedDomains(likeDomains)
  }, [likeDomains, filter])

  const loadMoreLikedDomains = () => {
    if (!filteredLikedDomains || filteredLikedDomains.length === 0) return
    if (shownLikedDomains?.length >= filteredLikedDomains?.length) return

    const likedDomainsCopy = filteredLikedDomains

    const moreLikeDomains = likedDomainsCopy?.slice(
      shownLikedDomains.length,
      shownLikedDomains.length + 20
    )

    setShownLikedDomains([...shownLikedDomains, ...moreLikeDomains])
  }

  useEffect(() => {
    if (!filteredLikedDomains) return

    const likedDomainsCopy = filteredLikedDomains

    setShownLikedDomains(
      likedDomainsCopy.slice(
        0,
        filteredLikedDomains?.length >= 20 ? 20 : filteredLikedDomains?.length
      )
    )
  }, [filteredLikedDomains])

  const domainViewContext = useDomainViewContext()

  useEffect(() => {
    domainViewContext.reset()
  }, [navItem])

  // const handleGoMyDomain = tokenData => {
  //   history.push(`/mydomains/${tokenData.id}`)
  // }

  const handleGoList = tokenData => {
    history.push(`/listing/${tokenData.id}`)
  }

  const handleCancelOffer = async (offer, i) => {
    console.log(offer, i)
    try {
      const signer = await getSigner()

      setCancelingIndex(i)

      await actions.cancelOrder({
        id: offer.id,
        signer,
        onProgress: step => {
          if (
            step[0]?.items[0]?.status &&
            step[0]?.items[0]?.status === 'complete'
          ) {
            setTimeout(() => {
              setCancelingIndex(undefined)
              setRefetchDomainOffers(true)
            }, 1000)
          }
        }
      })
    } catch (err) {
      setCancelingIndex(undefined)
      throw new Error(err)
    }
  }

  useEffect(() => {
    if (
      offersDropdownItem.toLowerCase() ===
      OFFERS_DROPDOWN_ITEMS.RECEIVED.toLowerCase()
    )
      return

    const getOffersSentTokenIds = async () => {
      try {
        const fetchedOrdersForOfferer = await getOffers({
          address: ENS_CONTRACT_ADDRESS,
          maker: account
        })

        if (fetchedOrdersForOfferer?.length === 0) return

        const extractedTokenIds = fetchedOrdersForOfferer.map(
          order => order.tokenSetId.split(':')[2]
        )

        setOfferDomainIds(extractedTokenIds)
      } catch (err) {
        throw new Error(err)
      }
    }

    getOffersSentTokenIds()
  }, [offersDropdownItem])

  const { data: offerDomains } = useQuery(GET_LABEL_NAME_FROM_HASH, {
    variables: { hashes: offerDomainIds }
  })

  const offerDomainNames = offerDomains?.domains?.map(
    domain => domain.labelName
  )

  const handleAcceptOffer = async (offer, i) => {
    if (offer) {
      try {
        const signer = await getSigner()

        // console.log(offer.tokenSetId.split(':')[2])

        const tokenId = BigNumber.from(
          offer.tokenSetId.split(':')[2]
        ).toString()

        await actions.acceptOffer({
          items: [
            {
              token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
              quantity: 1
            }
          ],
          signer,
          onProgress: step => {
            if (step[0].items[0].status === 'complete') {
              setAcceptingIndex(i)
              setSuccessModalOpen(false)
              setTimeout(() => {
                setAcceptingIndex(undefined)
                setRefetchDomainOffers(true)
              }, 2000)
            }
          }
        })
      } catch (err) {
        setAcceptingIndex(undefined)
      }
      return
    }
  }

  const handleOpenOfferDialog = (name, lastPrice) => {
    setOfferedDomainName(name)
    setOfferedDomainLastPrice(lastPrice)
    setOfferDialogOpen(true)
  }

  const domainsInCartNames = useMemo(
    () => domainsInCart.map(domain => domain.name),
    [domainsInCart]
  )

  const { columnCount } = useColumnCount({
    sizeMap: DOMAIN_TABLE_COLUMNS_BREAKPOINTS
  })

  const handleRefetchListedData = () => {
    setRefetchDomains(true)
  }

  const handleViewChange = type => {
    setViewType({
      ...viewType,
      marketplace: { view: type }
    })
    scrollToTop()
  }

  const handleLengthChange = params => {
    setFilter(filter => ({
      ...filter,
      minLength: params[0],
      maxLength: params[1] === 10 ? null : params[1]
    }))
    scrollToTop()
  }

  const handlePriceChange = prices => {
    setFilter(filter => ({
      ...filter,
      minPrice: prices[0] > 0 ? Number(prices[0]) : null,
      maxPrice: prices[1] > 0 ? Number(prices[1]) : null
    }))
    scrollToTop()
  }

  const handleStatusChange = status => {
    if (!hasStatusFilterChanged) hasStatusFilterChanged = true
    setFilter(filter => ({
      ...filter,
      status
    }))
    scrollToTop()
  }

  const handleSortByChange = sort => {
    setFilter(filter => ({
      ...filter,
      sort
    }))
    scrollToTop()
  }

  const handleDateChange = date => {
    setFilter(filter => ({
      ...filter,
      date
    }))
    scrollToTop()
  }

  const handleChangeCategories = subCategories => {
    setFilter(filter => ({
      ...filter,
      subCategories
    }))
    scrollToTop()
  }

  const handleChangeResult = result => {
    setFilter(filter => ({
      ...filter,
      result
    }))
    scrollToTop()
  }

  const handleChangeType = type => {
    setFilter(filter => ({
      ...filter,
      type: type.length > 0 ? type : undefined
    }))
    scrollToTop()
  }

  const handleChangeSearch = name => {
    setFilter(filter => ({
      ...filter,
      search: name || undefined
    }))
    scrollToTop()
  }

  const handleOrderByChange = value => {
    // setFilter({
    //   ...filter,
    //   rawOrderType: value
    // })
  }

  useEffect(() => {
    let calculatedCardWith
    switch (columnCount) {
      case 3:
        calculatedCardWith = '32.6%'
        break
      case 4:
        calculatedCardWith = '24.25%'
        break
      case 5:
        calculatedCardWith = '19.2%'
        break
      case 6:
        calculatedCardWith = '15.833%'
        break
      case 7:
        calculatedCardWith = '13.425%'
        break
      case 8:
        calculatedCardWith = '11.625%'
        break
      case 9:
        calculatedCardWith = '9%'
        break
      case 10:
        calculatedCardWith = '3%'
        break
      default:
        calculatedCardWith = '310px'
        break
    }

    setCardWidth(calculatedCardWith)
  }, [columnCount])

  useWindowListener(
    'resize',
    () => {
      setWindowWidth(window.innerWidth)
    },
    []
  )

  useEffect(() => {
    const index = profileTabs.findIndex(profile => profile.address === address)
    if (index !== -1) {
      setNavItem(profileTabs[index].tab)
    }
  }, [])

  useEffect(() => {
    const profileTabsCopy = [...profileTabs]
    const index = profileTabs.findIndex(profile => profile.address === address)
    if (index !== -1) {
      profileTabsCopy[index] = {
        address: profileTabsCopy[index].address,
        tab: navItem
      }
      setProfileTabs(profileTabsCopy)
      return
    }

    setProfileTabs([...profileTabs, { address, tab: navItem }])
  }, [navItem])

  useEffect(() => {
    dataIsLoading = true
    setRefetchDomains(false)
    fetchListings()
    refetchLikedDomains()
  }, [refetchDomains])

  console.log(likeDomains)

  return (
    <>
      {navItem === NAV_ITEMS[1] &&
        unlistedDomains.length > 0 &&
        domainsToList.length > 0 && (
          <View
            position="sticky"
            top="calc(100vh - 100px)"
            left="0"
            zIndex={2}
            onClick={() => setListingDialogOpen(true)}
          >
            <View width="100vw" justify="center" alignItems="center">
              <View
                width="354px"
                background="#0698A8"
                borderRadius="6px"
                border="2px solid #0698A8"
                direction="row"
                alignItems="center"
                gap="24px"
                opacity={ListCounterOpacity}
                cursor="pointer"
                onMouseEnter={() => setListCounterOpacity(0.98)}
                onMouseLeave={() => setListCounterOpacity(1)}
                onClick={() => setListingDialogOpen(true)}
                zIndex="10000"
              >
                <View
                  width="284px"
                  background="#fff"
                  borderRadius="5px"
                  padding="20px"
                  justify="space-between"
                  alignItems="center"
                >
                  <Label size={16} color="#000">
                    Listing
                  </Label>
                  <View
                    background="#F3F3FA"
                    padding="6px 10px"
                    borderRadius="3px"
                  >
                    <Label size={16} color="#000">
                      {domainsToList.length}
                    </Label>
                  </View>
                </View>
                <ListWhite />
              </View>
            </View>
          </View>
        )}
      <KodexSidebar
        opened={offerDialogOpen}
        positionDialogCenter={true}
        onClose={() => setOfferDialogOpen(false)}
      >
        <OfferDialog
          onClose={() => setOfferDialogOpen(false)}
          domain={{
            name: offeredDomainName,
            lastPrice: offeredDomainlastPrice
          }}
          backLabel="account"
        />
      </KodexSidebar>
      <KodexSidebar
        opened={listingDialogOpen}
        onClose={() => {
          handleRefetchListedData()
          setListingDialogOpen(false)
        }}
      >
        <ListingDialog
          onClose={() => {
            handleRefetchListedData()
            setListingDialogOpen(false)
          }}
          domains={domainsToList}
          setDomains={setDomainsToList}
          backLabel="account"
          resetState={handleRefetchListedData}
          setNavItem={setNavItem}
        />
      </KodexSidebar>
      <View direction="column" width="100%" overflow="auto hidden">
        <BottomModal
          open={successModalOpen}
          onClose={() => setSuccessModalOpen(false)}
          contentBackground="#0698A8"
        >
          <AcceptSuccessModal onClose={() => setSuccessModalOpen(false)} />
        </BottomModal>
        <Container
          maxWidth="calc(100% - 140px)"
          minHeight="100vh"
          background="#fff"
          height="100%"
          position="relative"
        >
          <ProfileHeader
            address={address || '0x'}
            primaryName={primaryName}
            setOpenSettings={setOpenSettings}
          />

          <View margin="80px 0 64px 0" alignItems="center">
            <NavBar>
              <NavItem
                className={navItem === NAV_ITEMS[0] ? 'active' : ''}
                onClick={() => setNavItem(NAV_ITEMS[0])}
                key={NAV_ITEMS[0]}
              >
                Listed
                <Badge>{listedDomains?.domains?.length || '0'}</Badge>
              </NavItem>
              <NavItem
                className={navItem === NAV_ITEMS[1] ? 'active' : ''}
                onClick={() => setNavItem(NAV_ITEMS[1])}
                key={NAV_ITEMS[1]}
              >
                Unlisted
                <Badge>{unlistedDomains?.length || '0'}</Badge>
              </NavItem>
              {NAV_ITEMS.slice(2).map(item => (
                <NavItem
                  className={navItem === item ? 'active' : ''}
                  onClick={() => setNavItem(item)}
                  key={item}
                >
                  {item[0].toUpperCase() + item.slice(1, item.length)}
                </NavItem>
              ))}
            </NavBar>

            <View style={{ marginLeft: 'auto' }}>
              {navItem === NAV_ITEMS[5] && (
                <ProfileOffersDropdown
                  open={offersDropdownOpen}
                  selectedItem={offersDropdownItem}
                  setItem={setOffersDropdownItem}
                  setOpen={setOffersDropdownOpen}
                />
              )}
              {navItem === NAV_ITEMS[2] && (
                <ActivityDropdown
                  open={activityDropdownOpen}
                  selectedItem={activityDropdownItem}
                  setItem={setActivityDropdownItem}
                  setOpen={setActivityDropdownOpen}
                />
              )}
              {(navItem === NAV_ITEMS[0] || navItem === NAV_ITEMS[1]) && (
                <>
                  <Label size={16} color="#BCBCCC">
                    Trading volume:
                  </Label>
                  &nbsp;
                  <Label number size={16} color="#020202">
                    0 ETH
                  </Label>
                </>
              )}
            </View>
          </View>

          {(navItem === NAV_ITEMS[0] ||
            navItem === NAV_ITEMS[1] ||
            navItem === NAV_ITEMS[3]) && (
            // <View width="100%" direction="row" height="fit" position="relative">
            //   <StickyToolbar
            //     ref={pageRef}
            //     filtersOpened={filtersOpened}
            //     onHideFilters={handleHideFilters}
            //     onOpenFilters={handleOpenFilters}
            //     subCategories={filter.subCategories}
            //     onOpenCategoriesModal={() => setCategoriesModalOpen(true)}
            //     onLengthChange={handleLengthChange}
            //     onPriceChange={handlePriceChange}
            //     onStatusChange={handleStatusChange}
            //     onSortByChange={handleSortByChange}
            //     onDateChange={handleDateChange}
            //     onResetFilter={() => {
            //       setFilter({})
            //     }}
            //     setActiveCurrency={setActiveCurrency}
            //     activeCurrency={activeCurrency}
            //     onViewTypeChange={handleViewChange}
            //     onChangeType={handleChangeType}
            //     onChangeResult={handleChangeResult}
            //     domainCount={
            //       NavItem === NAV_ITEMS[0]
            //         ? listedDomains?.domains?.length || '0'
            //         : NavItem === NAV_ITEMS[1]
            //         ? unlistedDomains?.length || '0'
            //         : NavItem === NAV_ITEMS[3]
            //         ? likeDomains?.length || '0'
            //         : 0
            //     }
            //     refreshData={() => setRefetchDomains()}
            //     viewType={viewTypeValue}
            //     modalButtonTitle="Categories"
            //     page="marketplace"
            //   />
            <View
              width={'100%'}
              padding="0 0 140px 0"
              flex={1}
              margin={
                domainViewContext.contextData.layout === 'CARD' &&
                unlistedDomains.length > 0
                  ? '0 -3px 0 0'
                  : undefined
              }
              direction="column"
            >
              <HeaderBar
                onOrderByChange={handleOrderByChange}
                renderedDomainCount={
                  NavItem === NAV_ITEMS[0]
                    ? filteredListedDomains?.domains.length
                    : NavItem === NAV_ITEMS[1]
                    ? unlistedDomains.length
                    : NavItem === NAV_ITEMS[3]
                    ? likeDomains?.length
                    : 0
                }
                from="profile"
                filtersOpened={filtersOpened}
                darkMode={false}
                domainCount={
                  NavItem === NAV_ITEMS[0]
                    ? filteredListedDomains?.length
                    : NavItem === NAV_ITEMS[1]
                    ? unlistedDomains.length
                    : NavItem === NAV_ITEMS[3]
                    ? likeDomains?.length
                    : 0
                }
                refreshData={() => refreshData()}
                viewType={domainViewContext.contextData.layout}
                onViewTypeChange={domainViewContext.setLayout}
                onSortByChange={handleSortByChange}
                onSearchChange={handleChangeSearch}
                searchDomains={handleChangeSearch}
                searchDomain={filter?.search}
                selectedCategories={filter?.subCategories}
                changeSelectedCategories={handleChangeCategories}
                profile={true}
                navItem={navItem}
              />
              {navItem === NAV_ITEMS[0] && (
                <>
                  <DomainView
                    domains={shownListedDomains}
                    context={domainViewContext}
                    loadMore={() => loadMoreListedDomains()}
                    renderers={{
                      card: (data, index) => {
                        return (
                          <NetTokenCard
                            key={index}
                            domain={data}
                            width={cardWidth}
                            windowWidth={windowWidth}
                            margin="0"
                            headerBg="#EBEBF2"
                            headerColor="#000"
                            footerBg="#020202"
                            bodyBg="#fff"
                            registeredBg="#ffffff"
                            bodyColor="#020202"
                            starStrokeColor="#020202"
                            profileTab="listed"
                            hasAnimation={true}
                            from="PROFILE"
                            fromMyProfile={isOwnProfile}
                            addedToCart={domainsInCartNames.includes(
                              data?.name || ''
                            )}
                            fieldGetters={{
                              name: prop => prop?.name || '',
                              expires: prop =>
                                prop?.expires
                                  ? parseInt(prop.expires) * 1000
                                  : 0,
                              terms: prop => prop?.domains_terms || [],
                              taxonomies: prop =>
                                prop?.domains_taxonomies?.map(
                                  tax => tax.taxonomy
                                ) || [],
                              lastPrice: prop =>
                                prop?.ens_sales
                                  ? prop?.ens_sales[0]?.saleassetamount || 0
                                  : 0,
                              likesCount: prop =>
                                prop?.likes_aggregate?.aggregate?.count || 0
                            }}
                            onClick={() => {
                              history.push(`/domain/${data?.name || ''}`)
                            }}
                            onSelect={() => {
                              if (!isOwnProfile) {
                                if (
                                  domainsInCartNames.includes(data?.name || '')
                                ) {
                                  cartCtx.removeDomain(
                                    data?.name || '',
                                    'PURCHASE'
                                  )
                                } else {
                                  const dataTransformed = {
                                    ...data,
                                    expire_time: data.expires
                                  }
                                  cartCtx.addNewDomain(
                                    dataTransformed,
                                    isOwnProfile ? 'LIST' : 'PURCHASE'
                                  )
                                }
                              } else {
                                const dataTransformed = {
                                  ...data,
                                  id: data.tokenid,
                                  expire_time: data.expires
                                }
                                handleGoList(dataTransformed)
                              }
                            }}
                          />
                        )
                      },
                      listItem: (data, index) => {
                        return (
                          <NetTokenRow
                            key={index}
                            domain={data}
                            width={'100%'}
                            windowWidth={windowWidth}
                            margin="0"
                            headerBg="#EBEBF2"
                            headerColor="#000"
                            footerBg="#020202"
                            bodyBg="#fff"
                            registeredBg="#ffffff"
                            bodyColor="#020202"
                            starStrokeColor="#020202"
                            profileTab="listed"
                            hasAnimation={true}
                            from="PROFILE"
                            fromMyProfile={isOwnProfile}
                            addedToCart={domainsInCartNames.includes(
                              data?.name || ''
                            )}
                            fieldGetters={{
                              name: prop => prop?.name || '',
                              expires: prop =>
                                prop?.expires
                                  ? parseInt(prop.expires) * 1000
                                  : 0,
                              terms: prop => prop?.domains_terms || [],
                              taxonomies: prop =>
                                prop?.domains_taxonomies?.map(
                                  tax => tax.taxonomy
                                ) || [],
                              lastPrice: prop =>
                                prop?.ens_sales
                                  ? prop?.ens_sales[0]?.saleassetamount || 0
                                  : 0,
                              likesCount: prop =>
                                prop?.likes_aggregate?.aggregate?.count || 0
                            }}
                            onClick={() => {
                              history.push(`/domain/${data?.name || ''}`)
                            }}
                            onSelect={() => {
                              if (!isOwnProfile) {
                                if (
                                  domainsInCartNames.includes(data?.name || '')
                                ) {
                                  cartCtx.removeDomain(
                                    data?.name || '',
                                    'PURCHASE'
                                  )
                                } else {
                                  const dataTransformed = {
                                    ...data,
                                    expire_time: data.expires
                                  }
                                  cartCtx.addNewDomain(
                                    dataTransformed,
                                    isOwnProfile ? 'LIST' : 'PURCHASE'
                                  )
                                }
                              } else {
                                const dataTransformed = {
                                  ...data,
                                  id: data.tokenid,
                                  expire_time: data.expires
                                }
                                handleGoList(dataTransformed)
                              }
                            }}
                          />
                        )
                      },
                      noItems: () => <NoResult message="No domains listed" />
                    }}
                  />
                </>
              )}
              {navItem === NAV_ITEMS[1] && (
                <>
                  <DomainView
                    domains={shownUnlistedDomains}
                    context={domainViewContext}
                    loadMore={loadMoreUnlistedDomains}
                    renderers={{
                      card: (data, index) => (
                        <NetTokenCard
                          key={index}
                          domain={data}
                          width={cardWidth}
                          windowWidth={windowWidth}
                          margin="0"
                          headerBg="#EBEBF2"
                          headerColor="#000"
                          footerBg="#020202"
                          bodyBg="#fff"
                          registeredBg="#ffffff"
                          bodyColor="#020202"
                          starStrokeColor="#020202"
                          hasAnimation={true}
                          profileTab="unlisted"
                          from="PROFILE"
                          fromMyProfile={isOwnProfile}
                          addedToCart={
                            (!isOwnProfile &&
                              domainsInCartNames.includes(data?.name || '')) ||
                            (isOwnProfile && domainsToList.includes(data))
                          }
                          onClick={() => {
                            history.push(`/domain/${data?.name || ''}`)
                          }}
                          onSelect={() => {
                            if (!isOwnProfile) {
                              domainsInCartNames.includes(data?.name || '')
                                ? cartCtx.removeDomain(
                                    data?.name || '',
                                    isOwnProfile ? 'LIST' : 'PURCHASE'
                                  )
                                : cartCtx.addNewDomain(
                                    data,
                                    isOwnProfile ? 'LIST' : 'PURCHASE'
                                  )
                            } else {
                              const domainsToListCopy = domainsToList
                              if (domainsToList.indexOf(data) > -1) {
                                const filteredDomainsToList = domainsToListCopy.filter(
                                  domain => domain.name !== (data?.name || '')
                                )
                                setDomainsToList([...filteredDomainsToList])
                              } else {
                                domainsToListCopy.push(data)
                                setDomainsToList([...domainsToListCopy])
                              }
                            }
                          }}
                        />
                      ),
                      listItem: (data, index) => {
                        return (
                          <NetTokenRow
                            key={index}
                            openOfferDialog={handleOpenOfferDialog}
                            domain={data}
                            width={'100%'}
                            windowWidth={windowWidth}
                            margin="0"
                            headerBg="#EBEBF2"
                            headerColor="#000"
                            footerBg="#020202"
                            bodyBg="#fff"
                            registeredBg="#ffffff"
                            bodyColor="#020202"
                            starStrokeColor="#020202"
                            profileTab="unlisted"
                            hasAnimation={true}
                            from="PROFILE"
                            fromMyProfile={isOwnProfile}
                            addedToCart={
                              (!isOwnProfile &&
                                domainsInCartNames.includes(
                                  data?.name || ''
                                )) ||
                              (isOwnProfile && domainsToList.includes(data))
                            }
                            onClick={() => {
                              history.push(`/domain/${data?.name || ''}`)
                            }}
                            onSelect={() => {
                              if (!isOwnProfile) {
                                domainsInCartNames.includes(data?.name || '')
                                  ? cartCtx.removeDomain(
                                      data?.name || '',
                                      isOwnProfile ? 'LIST' : 'PURCHASE'
                                    )
                                  : cartCtx.addNewDomain(
                                      data,
                                      isOwnProfile ? 'LIST' : 'PURCHASE'
                                    )
                              } else {
                                const domainsToListCopy = domainsToList
                                if (domainsToList.indexOf(data) > -1) {
                                  const filteredDomainsToList = domainsToListCopy.filter(
                                    domain => domain.name !== (data?.name || '')
                                  )
                                  setDomainsToList([...filteredDomainsToList])
                                } else {
                                  domainsToListCopy.push(data)
                                  setDomainsToList([...domainsToListCopy])
                                }
                              }
                            }}
                          />
                        )
                      },
                      noItems: () => <NoResult message="No Items" />
                    }}
                  />
                </>
              )}

              {navItem === NAV_ITEMS[3] && (
                <>
                  <DomainView
                    domains={shownLikedDomains}
                    context={domainViewContext}
                    loadMore={loadMoreLikedDomains}
                    renderers={{
                      card: (data, index) => {
                        const registered = moment()
                          .subtract(90, 'days')
                          .isBefore(
                            parseInt(
                              data?.domainDetails?.expire_time ||
                                data?.domainDetails?.expires
                            ) * 1000
                          )
                        return (
                          <NetTokenCard
                            key={index}
                            domain={data?.domainDetails}
                            width={cardWidth}
                            windowWidth={windowWidth}
                            margin="0"
                            headerBg="#EBEBF2"
                            headerColor="#000"
                            footerBg="#020202"
                            bodyBg="#fff"
                            registeredBg="#ffffff"
                            bodyColor="#020202"
                            starStrokeColor="#020202"
                            shouldFetch
                            from="PROFILE"
                            onClick={() => {
                              history.push(`/domain/${data?.domain}`)
                            }}
                            addedToCart={domainsInCartNames.includes(
                              data?.domain
                            )}
                            onSelect={() => {
                              // if (!registered) {
                              //   history.push(
                              //     `/registry?search=${data.name.replace(
                              //       '.eth',
                              //       ''
                              //     )}`
                              //   )
                              //   return
                              // }
                              domainsInCartNames.includes(data?.domain)
                                ? cartCtx.removeDomain(
                                    data?.domain,
                                    registered ? 'PURCHASE' : 'REGISTER'
                                  )
                                : cartCtx.addNewDomain(
                                    data?.domainDetails,
                                    registered ? 'PURCHASE' : 'REGISTER'
                                  )
                            }}
                          />
                        )
                      },
                      listItem: (data, index) => {
                        const registered = moment()
                          .subtract(90, 'days')
                          .isBefore(
                            parseInt(
                              data?.domainDetails.expire_time ||
                                data?.domainDetails.expires
                            ) * 1000
                          )
                        return (
                          <NetTokenRow
                            key={index}
                            domain={data?.domainDetails}
                            width={'100%'}
                            windowWidth={windowWidth}
                            margin="0"
                            headerBg="#EBEBF2"
                            headerColor="#000"
                            footerBg="#020202"
                            bodyBg="#fff"
                            registeredBg="#ffffff"
                            bodyColor="#020202"
                            starStrokeColor="#020202"
                            profileTab="listed"
                            hasAnimation={true}
                            shouldFetch
                            from="PROFILE"
                            onClick={() => {
                              history.push(`/domain/${data?.domain}`)
                            }}
                            addedToCart={domainsInCartNames.includes(
                              data?.domain
                            )}
                            onSelect={() => {
                              // if (!registered) {
                              //   history.push(
                              //     `/registry?search=${data.name.replace(
                              //       '.eth',
                              //       ''
                              //     )}`
                              //   )
                              //   return
                              // }
                              domainsInCartNames.includes(data?.domain)
                                ? cartCtx.removeDomain(
                                    data?.domain,
                                    registered ? 'PURCHASE' : 'REGISTER'
                                  )
                                : cartCtx.addNewDomain(
                                    data?.domainDetails,
                                    registered ? 'PURCHASE' : 'REGISTER'
                                  )
                            }}
                          />
                        )
                      },
                      noItems: () => <NoResult message="No Likes" />
                    }}
                  />
                </>
              )}
            </View>
            // </View>
          )}

          {navItem === NAV_ITEMS[0] && listedDomains?.length > 0 && (
            <View margin="0 0 140px" width="100%">
              <NoResult message="No Items" />
            </View>
          )}

          {navItem === NAV_ITEMS[2] && (
            <View margin="0 0 140px 0" width="100%">
              <Activities
                data={shownUserActivity}
                allData={userActivity}
                filter={activityDropdownItem}
                loadMore={() => loadMoreUserActivity()}
                noItems={() => <NoResult message="No activity" />}
              />
            </View>
          )}

          {navItem === NAV_ITEMS[4] && (
            <View margin="0 0 140px" width="100%">
              <NoResult message="Coming soon" />
            </View>
          )}

          {navItem === NAV_ITEMS[5] && (
            <View margin="0 0 140px" width="100%">
              <ProfileOffers
                domains={
                  offersDropdownItem === OFFERS_DROPDOWN_ITEMS.RECEIVED
                    ? domains
                    : offerDomainNames
                }
                account={address}
                cancelOffer={handleCancelOffer}
                navItem={offersDropdownItem}
                canceling={cancelingIndex}
                acceptOffer={handleAcceptOffer}
                accepting={acceptingIndex}
                ownedProfile={isMyProfile}
                refetchDomainsOffers={refetchDomainOffers}
                setRefetchDomainsOffers={setRefetchDomainOffers}
              />
            </View>
          )}

          {/** TODO: Should have a global one instance */}
          {isOwnProfile && openSettings && (
            <SettingDialog
              open={openSettings}
              onClose={() => setOpenSettings(false)}
            />
          )}
        </Container>
        {/* {((navItem === NAV_ITEMS[0] &&
          (shownListedDomains?.length === listedDomains?.domains?.length ||
            !listedDomains ||
            listedDomains?.domains?.length === 0)) ||
          (navItem === NAV_ITEMS[1] &&
            (shownUnlistedDomains?.length === unlistedDomains?.length ||
              !unlistedDomains ||
              unlistedDomains?.length === 0)) ||
          (navItem === NAV_ITEMS[3] &&
            (shownLikedDomains?.length === likeDomains?.length ||
              !likeDomains ||
              likeDomains?.length === 0)) ||
          navItem === NAV_ITEMS[2] ||
          navItem === NAV_ITEMS[4] ||
          navItem === NAV_ITEMS[5]) && <Footer />} */}
      </View>
      {categoriesModalOpen && window.innerWidth > 740 && (
        <CategoryFilterModal
          subCategories={filter.subCategories}
          open={categoriesModalOpen}
          onClose={() => setCategoriesModalOpen(false)}
          onApply={handleChangeCategories}
        />
      )}
    </>
  )
}

export default Profile

export const NavBar = styled.div`
  display: flex;
`

export const NavItem = styled.div`
  font-size: 16px;
  background: #fff;
  color: #bcbccc;
  transition: all 0.1s ease-out;
  padding: 18px;
  border-radius: 0;
  flex: 1;
  display: flex;
  font-family: 'Satoshi', sans-serif;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:hover,
  &:visited {
    cursor: pointer;
  }

  &.active {
    background: #f3f3fa;
    color: #000;
    border-radius: 6px;
  }
`

const Badge = styled.span`
  background: #f3f3fa;
  border-radius: 4px;
  padding: 10px 7px;
  margin-left: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #bcbccc;

  .active & {
    background: #fff;
    color: #020202;
  }
`
