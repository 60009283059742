import { JETTY_URL } from 'service/siwe/constants'
import { siweAuthedFetch } from 'service/siwe/siweFetch'

export const getRegistrySearchDomains = async (
  filter,
  isInitialLoad = false
) => {
  const {
    offset = 0,
    sort,
    search,
    minLength,
    maxLength,
    minPrice,
    maxPrice,
    subCategories,
    status,
    rawOrderType,
    result,
    type,
    clickedOffset = 0
  } = filter

  let params = [
    `&name=${search || ''}`,
    `${minLength ? `&min_domain_length=${minLength || ''}` : ''}`,
    `${maxLength ? `&max_domain_length=${maxLength || ''}` : ''}`,
    `${minPrice ? `&min_listing_price=${minPrice || ''}` : ''}`,
    `${maxPrice ? `&max_listing_price=${maxPrice || ''}` : ''}`,
    `${
      type && type.length > 0
        ? `&name_symbols_type=${
            type && type.length > 0 ? `${type.join(',')}` : ''
          }`
        : ''
    }`,
    `${result ? `&name_result=${result || ''}` : ''}`,
    `${status ? `&status_type=${status || ''}` : ''}`,
    `&search_type=registry`,
    `&${rawOrderType || 'order_type=default'}`,
    `${
      subCategories
        ? `&search_terms=${
            subCategories && subCategories.length > 0
              ? `${subCategories.join(',')}`
              : ''
          }`
        : ''
    }`
  ].join('')

  try {
    const plainReq = fetch(
      `${JETTY_URL}/search/plain?limit=27&offset=${offset -
        (offset > 0 ? (offset / 40) * 27 : 0)}${params}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )

    const similarReq = siweAuthedFetch(
      `${JETTY_URL}/search/similar?limit=13&offset=${
        offset > 0 ? (offset / 40) * 13 : 0
      }${params}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )

    const plainRes = await plainReq.then(res => res).then(data => data.json())

    if (plainRes.domains.length < 27) {
      const similarReq = fetch(
        `${JETTY_URL}/search/similar?limit=13&offset=${
          offset > 0 ? (offset / 40) * 13 : 0
        }${params}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )

      const similarRes = await similarReq
        .then(data => data.json())
        .then(data => data)

      const mixedDomainsArr = [
        plainRes.domains.length === 0 && isInitialLoad
          ? {
              expire_time: null,
              has_offers: false,
              last_price: null,
              last_sale_asset: null,
              likes: 0,
              listing_end_price: null,
              listing_start_price: null,
              listing_time: null,
              name: `${search}.eth`,
              name_ens: search,
              taxonomies: null,
              terms: null
            }
          : null,
        ...(await plainRes.domains),
        ...(await similarRes.domains)
      ].filter(d => d !== null)

      return mixedDomainsArr
    }

    const similarRes = await similarReq
      .then(res => res)
      .then(data => data.json())

    const mixedDomainsArrPlaceholder = [
      ...(await plainRes.domains),
      ...(await similarRes.domains)
    ]

    const mixedDomainsArr = mixedDomainsArrPlaceholder
      .map((_, i) => {
        if ((i + 1) % 3 === 0) return similarRes.domains.shift()
        return plainRes.domains.shift()
      })
      .filter(Boolean)

    // return plainRes?.domains
    return mixedDomainsArr
  } catch (err) {
    throw new Error(err)
  }
}

export const getRegistryDefaultDomains = async filter => {
  const {
    offset = 0,
    sort,
    search,
    minLength,
    maxLength,
    minPrice,
    maxPrice,
    subCategories,
    rawOrderType,
    clickedOffset = 0,
    status,
    result,
    type
  } = filter

  let params = [
    `offset=${offset + clickedOffset}`,
    `&name=${search || ''}`,
    `${minLength ? `&min_domain_length=${minLength || ''}` : ''}`,
    `${maxLength ? `&max_domain_length=${maxLength || ''}` : ''}`,
    `${minPrice ? `&min_listing_price=${minPrice || ''}` : ''}`,
    `${maxPrice ? `&max_listing_price=${maxPrice || ''}` : ''}`,
    `${
      type && type.length > 0
        ? `&name_symbols_type=${
            type && type.length > 0 ? `${type.join(',')}` : ''
          }`
        : ''
    }`,
    `${result ? `&name_result=${result || ''}` : ''}`,
    `${status ? `&status_type=${status || ''}` : ''}`,
    `&search_type=registry`,
    `&${rawOrderType || 'order_type=default'}`,
    `${
      subCategories
        ? `&search_terms=${
            subCategories && subCategories.length > 0
              ? `${subCategories.join(',')}`
              : ''
          }`
        : ''
    }`
  ].join('')

  try {
    const req = fetch(
      `${JETTY_URL}/search/plain?limit=40&search_taxa=&${params}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )

    const res = await req.then(res => res).then(data => data.json())

    return await res
  } catch (err) {
    throw new Error(err)
  }
}

// export const getRegistrySearchDomains = async filter => {
//   const {
//     offset = 0,
//     sort,
//     search,
//     minLength,
//     maxLength,
//     minPrice,
//     maxPrice,
//     subCategories,
//     status,
//     result,
//     type
//   } = filter

//   let params = [
//     `offset=${offset}`,
//     `name=${search || ''}`,
//     `min_domain_length=${minLength || ''}`,
//     `max_domain_length=${maxLength || ''}`,
//     `min_listing_price=${minPrice || ''}`,
//     `max_listing_price=${maxPrice || ''}`,
//     `name_symbols_type=${type && type.length > 0 ? `${type.join(',')}` : ''}`,
//     `name_result=${result || ''}`,
//     `status_type=${status || ''}`,
//     `search_type=Registry`,
//     `order_type=${sort || ''}`,
//     `terms=${
//       subCategories && subCategories.length > 0
//         ? `${subCategories.join(',')}`
//         : ''
//     }`
//   ].join('&')

//   try {
//     const plainReq = siweAuthedFetch(
//       `${JETTY_URL}/search/plain?limit=14&${params}`,
//       {
//         method: 'GET',
//         mode: 'cors',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//         }
//       }
//     )

//     const similarReq = siweAuthedFetch(
//       `${JETTY_URL}/search/similar?limit=6&${params}`,
//       {
//         method: 'GET',
//         mode: 'cors',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//         }
//       }
//     )

//     const plainRes = await plainReq.then(res => res).then(data => data.json())

//     const similarRes = await similarReq
//       .then(res => res)
//       .then(data => data.json())

//     const mixedDomainsArrPlaceholder = new Array(20)

//     const mixedDomainsArr = mixedDomainsArrPlaceholder.map((_, i) => {
//       if ((i + 1) % 3 === 0) return similarRes[(i + 1) / 3 - 1]
//       return plainRes[i - (i + 1) / 3]
//     })

//     console.log(mixedDomainsArr)

//     return mixedDomainsArr
//   } catch (err) {
//     throw new Error(err)
//   }
// }

// export const getRegistryDefaultDomains = async filter => {
//   const {
//     offset = 0,
//     sort,
//     search,
//     minLength,
//     maxLength,
//     minPrice,
//     maxPrice,
//     subCategories,
//     status,
//     result,
//     type
//   } = filter

//   let params = [
//     `offset=${offset}`,
//     `name=${search || ''}`,
//     `min_domain_length=${minLength || ''}`,
//     `max_domain_length=${maxLength || ''}`,
//     `min_listing_price=${minPrice || ''}`,
//     `max_listing_price=${maxPrice || ''}`,
//     `name_symbols_type=${type && type.length > 0 ? `${type.join(',')}` : ''}`,
//     `name_result=${result || ''}`,
//     `status_type=${status || ''}`,
//     `search_type=Registry`,
//     `order_type=${sort || ''}`,
//     `terms=${
//       subCategories && subCategories.length > 0
//         ? `${subCategories.join(',')}`
//         : ''
//     }`
//   ].join('&')

//   try {
//     const req = siweAuthedFetch(
//       `${JETTY_URL}/search/plain?limit=20&${params}`,
//       {
//         method: 'GET',
//         mode: 'cors',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//         }
//       }
//     )

//     const res = await req.then(res => res).then(data => data.json())

//     return await res
//   } catch (err) {
//     throw new Error(err)
//   }
// }
