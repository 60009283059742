import styled from '@emotion/styled'
import View from 'components/View'
import { useTopBarStyle } from 'contexts/TopBarStyleProvider'
import { useCallback, useState } from 'react'
import Divider from './Divider'
import Logo from './Logo'
import QuickSearch from './QuickSearch'
import RequestModal from './RequestModal'
import TopbarContainer from './TopbarContainer'

const HelpCenterTopbar = () => {
  const { style } = useTopBarStyle()
  const [requestModalOpen, setRequestModalOpen] = useState(false)

  const openRequestModal = useCallback(() => {
    setRequestModalOpen(true)
  }, [])

  const closeRequestModal = useCallback(() => {
    setRequestModalOpen(false)
  }, [])

  const handleSearchIconClick = () => {
    // console.log('Open search')
  }

  return (
    <TopbarContainer
      background={style.topBarBgColor}
      boxShadow={style.topBarBoxShadow}
    >
      <View alignItems="center" flex={1}>
        <Logo src={style.logo} />
      </View>

      <View alignItems="center">
        <QuickSearch
          color={style.quickSearchButtonColor}
          onClick={handleSearchIconClick}
        />
        <Divider color={style.dividerColor} />
        <RequestButton onClick={openRequestModal}>
          SUBMIT A REQUEST
        </RequestButton>
      </View>
      {requestModalOpen && (
        <RequestModal
          open={requestModalOpen}
          onClose={closeRequestModal}
          cancelListingsModal={closeRequestModal}
          successExitButtonLabel="BACK TO HELP CENTER"
        />
      )}
    </TopbarContainer>
  )
}

const RequestButton = styled.button`
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  padding: 22px 20px;
  line-height: 1;
  background-color: #f3f3fa;
  border-radius: 6px;
  opacity: 1;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

export default HelpCenterTopbar
