import { createContext, useCallback, useContext, useState } from 'react'

const HasLoadedCardContext = createContext({})
export const useHasLoadedCard = () => useContext(HasLoadedCardContext)

export const HasLoadedCard = ({ queryKey, children }) => {
  const [hasLoadedCardMap, setHasLoadedCardMap] = useState({})

  const onCardLoaded = useCallback(index => {
    setHasLoadedCardMap(hasLoadedCardMap => ({
      ...hasLoadedCardMap,
      [index]: true
    }))
  }, [])

  const hasLoadedCard = useCallback(
    index => {
      return hasLoadedCardMap[index]
    },
    [hasLoadedCardMap]
  )

  // useEffect(() => {
  //   setHasLoadedCardMap({})
  // }, [queryKey])

  return (
    <HasLoadedCardContext.Provider value={{ onCardLoaded, hasLoadedCard }}>
      {children}
    </HasLoadedCardContext.Provider>
  )
}
