import { useQuery } from '@apollo/client'
import RoundedButton from 'components/Forms/RoundedButton'
import { useAccount } from 'components/QueryAccount'
import { AccountContext } from 'contexts/AccountProvider'
import { useContext } from 'react'
import {
  GET_USER,
  GET_USER_FOLLOWINGS_REACTIVE,
  kodexApiContext
} from 'service/graphql/kodex-api'
import { follow, unfollow } from 'service/rest/followMutation'

export const SetFollowerBtn = ({
  id,
  isFollowing,
  refetchFollowers,
  refetchFollowings,
  refetchIsFollowing
}) => {
  const { handleSIWE, isConnected } = useContext(AccountContext)
  const account = useAccount()
  const { data: { users: usersRes } = {} } = useQuery(GET_USER, {
    variables: {
      user: id.toLowerCase()
    },
    ...kodexApiContext
  })
  const isKodexUser = usersRes?.length

  // const [toggleFollowerMutation] = useMutation(
  //   isFollowing === true ? DELETE_FOLLOWER : ADD_FOLLOWER,
  //   {
  //     variables: {
  //       followerId: account.toLowerCase(),
  //       user: id.toLowerCase()
  //     },
  //     update(cache, { data }) {
  //       if (isFollowing) {
  //         cache.modify({
  //           id: cache.identify({
  //             __typename: 'profile_view',
  //             user: account.toLowerCase()
  //           }),
  //           fields: {
  //             followings: value => (value > 0 ? value - 1 : 0),
  //             followingsList: (list = [], { readField }) =>
  //               list.filter(item => readField('user', item) !== id)
  //           }
  //         })
  //       } else {
  //         const { user, userInfo } = data?.['set_follower']?.[0] || {}

  //         cache.modify({
  //           id: cache.identify({
  //             __typename: 'profile_view',
  //             user: account.toLowerCase()
  //           }),
  //           fields: {
  //             followings: value => value + 1,
  //             followingsList: list => {
  //               const userRef = cache.writeFragment({
  //                 data: userInfo,
  //                 fragment: gql`
  //                   fragment User on users {
  //                     user
  //                     username
  //                     bio
  //                     twitter
  //                   }
  //                 `
  //               })
  //               return [
  //                 {
  //                   __typename: 'followers',
  //                   user,
  //                   userByUser: userRef
  //                 },
  //                 ...list
  //               ]
  //             }
  //           }
  //         })
  //       }
  //     },
  //     refetchQueries: ['GetUserFollowingsReactive', 'GetUserFollowersReactive'],
  //     skip: !account || !id,
  //     ...kodexApiContext
  //   }
  // )

  // const toggleFollower = async (...args) => {
  //   try {
  //     await handleSIWE()
  //   } catch (e) {
  //     throw e
  //   }

  //   return toggleFollowerMutation(...args)
  // }

  const toggleFollower = async () => {
    if (!isConnected) {
      return () => {}
    }

    try {
      await handleSIWE()
    } catch (e) {
      throw new Error(e)
    }

    if (isFollowing) {
      const unfollowUser = await unfollow({
        user: id.toLowerCase()
      })

      if (unfollowUser.ok) {
        refetchFollowings()
        refetchFollowers()
        refetchIsFollowing()
      }

      return
    }

    const followUser = await follow({
      user: id.toLowerCase()
    })

    if (followUser.ok) {
      refetchFollowings()
      refetchFollowers()
      refetchIsFollowing()
    }
  }

  if (account.toLowerCase() === id.toLowerCase() || !isKodexUser) {
    return <></>
  }

  if (isFollowing === true) {
    return (
      <RoundedButton
        width="120px"
        height="60px"
        background="#F3F3FA"
        color="#020202"
        onClick={() => toggleFollower()}
      >
        Unfollow
      </RoundedButton>
    )
  }

  return (
    <RoundedButton
      width="120px"
      height="60px"
      background="#020202"
      color="#fff"
      onClick={() => toggleFollower()}
    >
      Follow
    </RoundedButton>
  )
}

export const FollowerBtn = ({ id, refetchFollowers, refetchFollowings }) => {
  const account = useAccount()
  const { data, refetch: refetchIsFollowing } = useQuery(
    GET_USER_FOLLOWINGS_REACTIVE,
    {
      variables: {
        user: account.toLowerCase()
      },
      fetchPolicy: 'cache-only',
      ...kodexApiContext
    }
  )

  const isFollowing =
    data?.followers?.find(f => f.user.toLowerCase() === id.toLowerCase()) !==
    undefined

  return (
    <SetFollowerBtn
      id={id}
      isFollowing={isFollowing}
      refetchFollowers={refetchFollowers}
      refetchFollowings={refetchFollowings}
      refetchIsFollowing={refetchIsFollowing}
    />
  )
}
