import styled from '@emotion/styled/macro'
import { ReactComponent as Bin } from 'assets/bin.svg'
import { ReactComponent as BinGray } from 'assets/bin-gray.svg'

const BottomBar = ({ onApply, darkMode, filtersCounter, resetFilters }) => {
  return (
    <Container darkMode={darkMode}>
      {filtersCounter > 0 && (
        <BinContainer darkMode={darkMode} onClick={() => resetFilters()}>
          {darkMode ? <Bin /> : <BinGray />}
          <BinCounter darkMode={darkMode}>{filtersCounter}</BinCounter>
        </BinContainer>
      )}
      <Button onClick={onApply}>Show Results</Button>
    </Container>
  )
}

export default BottomBar

const Container = styled.div`
  background: ${({ darkMode }) => (darkMode ? '#020202' : 'white')};
  box-shadow: ${({ darkMode }) =>
    !darkMode
      ? `0px -2px 8px rgba(0, 0, 0, 0.04),
  0px -4px 16px rgba(0, 0, 0, 0.05)`
      : ''};
  border-radius: ${({ darkMode }) => (!darkMode ? '12px 12px 0px 0px' : '')};
  display: flex;
  gap: 8px;
  height: 92px;
  padding: 16px;
  width: 100$;
`
const BinContainer = styled.div`
  align-items: center;
  background: ${({ darkMode }) => (darkMode ? '#1b1b1b' : '#F6F6F9')};
  border-radius: 6px;
  display: flex;
  gap: 15px;
  justify-content: center;
  padding: 16px;
  position: relative;
  width: 106px;

  svg {
    stroke: white;
  }
`
const BinCounter = styled.div`
  align-items: center;
  background: ${({ darkMode }) => (darkMode ? '#333333' : '#EAEAF1')};
  border-radius: 4px;
  color: ${({ darkMode }) => (darkMode ? '' : '#47596B')};
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
`

const Button = styled.button`
  align-items: center;
  background: #0698a8;
  border-radius: 6px;
  display: flex;
  color: white;
  flex: 1;
  height: 60px;
  justify-content: center;
`
