import { getBulkRegistrarContract } from '../../utils/utils'
import { getRegistrar } from 'apollo/mutations/ens'
import { sendHelperBlocking } from '../resolverUtils'
import { BigNumber } from 'ethers'

const resolvers = {
  Mutation: {
    async bulkCommit(_, { secret, owner, names }) {
      const bulkRegistrar = await getBulkRegistrarContract()
      const domains = names?.map(name => ({
        secret,
        owner,
        name
      }))

      const commitments = await bulkRegistrar.bulkMakeCommitment(domains)
      const tx = await bulkRegistrar.bulkCommit(commitments)
      return sendHelperBlocking(tx)
    },
    async bulkRegister(_, { labels, account, durations, secret }) {
      const bulkRegistrar = await getBulkRegistrarContract()
      const domains = labels.map((label, i) => ({
        name: label,
        owner: account,
        duration: durations[i],
        secret
      }))

      const registrar = getRegistrar()
      const rentPrices = await domains.reduce(async (acc, domain) => {
        const result = await registrar.getRentPrice(
          domain.name,
          domain.duration
        )
        // console.log(result, await acc)
        if (!result) {
          return acc
        }
        return BigNumber.from(await acc)
          .add(result)
          .toString()
      }, 0)

      const price = BigNumber.from(rentPrices)
        .mul(BigNumber.from(1150))
        .div(BigNumber.from(1000))
        .toString()

      const tx = await bulkRegistrar.bulkRegister(domains, {
        value: price
      })

      return sendHelperBlocking(tx)
    }
  }
}

export default resolvers
