import { JETTY_URL } from 'service/siwe/constants'
import { siweAuthedFetch } from 'service/siwe/siweFetch'

export const getMarketplaceDefaultDomains = async filter => {
  const {
    offset = 0,
    sort,
    search = '',
    minLength,
    maxLength,
    minPrice,
    rawOrderType,
    maxPrice,
    subCategories,
    status,
    result,
    type
  } = filter

  let params = [
    `offset=${
      offset > 0 ? (search.length > 0 ? (offset / 20) * 16 : offset) : 0
    }`,
    `&name=${search || ''}`,
    `${minLength ? `&min_domain_length=${minLength || ''}` : ''}`,
    `${maxLength ? `&max_domain_length=${maxLength || ''}` : ''}`,
    `${minPrice ? `&min_listing_price=${minPrice || ''}` : ''}`,
    `${maxPrice ? `&max_listing_price=${maxPrice || ''}` : ''}`,
    `${
      type && type.length > 0
        ? `&name_symbols_type=${
            type && type.length > 0 ? `${type.join(',')}` : ''
          }`
        : ''
    }`,
    `${result ? `&name_result=${result || ''}` : ''}`,
    `${status ? `&has_offers_selector=${status || ''}` : ''}`,
    `&search_type=marketplace`,
    `&${rawOrderType || 'order_type=default'}`,
    `${
      subCategories
        ? `&search_terms=${
            subCategories && subCategories.length > 0
              ? `${subCategories.join(',')}`
              : ''
          }`
        : ''
    }`
  ].join('')

  try {
    const req = siweAuthedFetch(
      `${JETTY_URL}/search/plain?limit=${
        search.length > 0 ? 16 : 20
      }&search_taxa=&${params}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )

    const res = await req.then(res => res).then(data => data.json())

    if (search.length > 0) {
      let similarParams = [
        `&name=${search || ''}`,
        `${minLength ? `&min_domain_length=${minLength || ''}` : ''}`,
        `${maxLength ? `&max_domain_length=${maxLength || ''}` : ''}`,
        `${minPrice ? `&min_listing_price=${minPrice || ''}` : ''}`,
        `${maxPrice ? `&max_listing_price=${maxPrice || ''}` : ''}`,
        `${
          type && type.length > 0
            ? `&name_symbols_type=${
                type && type.length > 0 ? `${type.join(',')}` : ''
              }`
            : ''
        }`,
        `${result ? `&name_result=${result || ''}` : ''}`,
        `${status ? `&has_offers_selector=${status || ''}` : ''}`,
        `&search_type=marketplace`,
        `&order_type=${sort || 'default'}`,
        `${
          subCategories
            ? `&search_terms=${
                subCategories && subCategories.length > 0
                  ? `${subCategories.join(',')}`
                  : ''
              }`
            : ''
        }`
      ].join('')

      // fetch 100domains through jetty like here and calculate the offset. Then add the 100results to the end of the arr
      if (res.domains.length < 16) {
        const similarReq = siweAuthedFetch(
          `${JETTY_URL}/search/similar?limit=4&offset=${
            offset > 0 ? (offset / 20) * 4 : 0
          }${similarParams}`,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )

        const similarRes = await similarReq
          .then(data => data.json())
          .then(data => data)

        const mixedDomainsArr = [
          ...(await res.domains),
          ...(await similarRes.domains)
        ]

        return { domains: mixedDomainsArr }
      }

      const similarReq = siweAuthedFetch(
        `${JETTY_URL}/search/similar?limit=4&offset=${
          offset > 0 ? (offset / 20) * 4 : 0
        }${similarParams}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )

      const similarRes = await similarReq
        .then(data => data.json())
        .then(data => data)

      const mixedDomainsArrPlaceholder = [
        ...(await res.domains),
        ...(await similarRes.domains)
      ]

      // if (res.domains.length < 16) {
      //   const addedSimilarDomains = res.domains
      //   const similarDomains = useSimilarDomains(search, 100)

      //   addedSimilarDomains.concat(similarDomains)

      //   console.log(addedSimilarDomains)
      // }

      const mixedDomainsArr = mixedDomainsArrPlaceholder
        .map((_, i) => {
          if ((i + 1) % 5 === 0) return similarRes.domains[(i + 1) / 5 - 1]
          return res.domains[i - Math.floor((i + 1) / 5)]
        })
        .filter(d => d !== undefined)

      // console.log(mixedDomainsArr)
      return { domains: mixedDomainsArr }
    }

    return await res
  } catch (err) {
    throw new Error(err)
  }
}
