import gql from 'graphql-tag'

export const APP_DATA = gql`
  query getAppData @client {
    globalError
  }
`

const ApolloContainer = ({ children }) => {
  return children
}

export default ApolloContainer
