import DeafultAvatarImg from 'assets/icon-pfp-default.png'
import Avatar from 'components/Avatar'
import Label from 'components/Label'
import View from 'components/View'
import useUserProfile from 'hooks/useUserProfile'
import { useHistory } from 'react-router'

const ActivityAddress = ({
  address,
  showPfp = true,
  link = true,
  color = '#BCBCCC',
  hoverColor = '#DEDEEE',
  onlyENS
}) => {
  const history = useHistory()

  const { profile: ownerData } = useUserProfile(address)

  return (
    <View direction="row" gap="20px" alignItems="center">
      {onlyENS ? (
        address === '0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5' ? (
          <View
            cursor="pointer"
            onClick={e => {
              e.stopPropagation()
              history.push(`/${address?.slice(2)}`)
            }}
          >
            <Avatar
              src={ownerData?.userInfo?.picture || DeafultAvatarImg}
              borderRadius="5px"
              background="#fff0"
            />
          </View>
        ) : null
      ) : (
        showPfp &&
        ownerData?.userInfo?.picture && (
          <View
            cursor="pointer"
            onClick={e => {
              e.stopPropagation()
              history.push(`/profile/${address?.slice(2)}`)
            }}
          >
            <Avatar
              src={ownerData?.userInfo?.picture || DeafultAvatarImg}
              borderRadius="5px"
              background="#fff0"
            />
          </View>
        )
      )}
      <Label
        color={color}
        hoverColor={hoverColor}
        cursor="pointer"
        onClick={e => {
          e.stopPropagation()
          if (!link) return
          history.push(`/profile/${address?.slice(2)}`)
        }}
      >
        {ownerData?.userInfo?.username?.length > 0
          ? ownerData?.userInfo?.username
          : address?.slice(2, 7)}
      </Label>
    </View>
  )
}

export default ActivityAddress
