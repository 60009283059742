import React from 'react'
import Ranger from 'components/Ranger'
import Space from 'components/Space'
import Card from './Card'
import Label from 'components/Label'
import View from 'components/View'

const Length = ({ values, onChange }) => {
  return (
    <Card
      title="Length"
      HeaderTrailingElement={
        <Label color="#020202" size={16}>
          {values[0] === values[1]
            ? `${values[0]}`
            : `${values[0]} - ${values[1]}${values[1] === 10 ? '+' : ''}`}
        </Label>
      }
    >
      <Space size={20} />
      <View direction="column" padding="6px 0 5px" width="100%">
        <Ranger
          step={1}
          min={3}
          max={10}
          values={values}
          onChange={onChange}
          thumbWidth="40px"
        />
        <View width="100%" justify="space-between">
          <Label color="#BCBCCC" size={13}>
            3
          </Label>
          <Label color="#BCBCCC" size={13}>
            10+
          </Label>
        </View>
      </View>
    </Card>
  )
}

export default Length
