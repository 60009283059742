import styled from '@emotion/styled'
import LoaderAnimation from 'assets/lotties/loader.json'
import View from 'components/View'
import Lottie from 'lottie-react'

const SearchLoader = () => {
  return (
    <ForcedSizeView width="100%" alignItems="center" justify="center">
      <LottieWrapper>
        <Lottie animationData={LoaderAnimation} loop autoplay />
      </LottieWrapper>
    </ForcedSizeView>
  )
}

const LottieWrapper = styled.div`
  height: 20px;
  width: 20px;
`

const ForcedSizeView = styled(View)`
  min-height: 500px;
`

export default SearchLoader
