'use strict'

import gsap, { Expo } from 'gsap/all'
import 'splitting/dist/splitting.css'
import 'splitting/dist/splitting-cells.css'
import Splitting from 'splitting'
class InViewAnimation {
  constructor(el) {
    this.el = el || document.body
    this.elems = [...this.el.querySelectorAll('[data-in]')]
    this.cache = null
    this.options = null
    this.observer = null

    this.init()
  }

  createObserver() {
    this.options = {
      root: null,
      rootMargin: '0px 0px -10% 0px',
      threshold: [0, 0]
    }
    this.observer = new IntersectionObserver(
      this.handler.bind(this),
      this.options
    )
  }

  getCache() {
    this.cache = []
    this.elems.forEach(el => {
      this.cache.push({
        el: el,
        animation: el.dataset.in,
        isIntersected: false,
        elems: null,
        split: null,
        tl: null
      })
    })
  }

  setAnimation(elem) {
    elem.tl = gsap.timeline({ paused: true })

    if (elem.animation === 'stagger-item') {
      const staggerItems = elem.el.querySelectorAll('div')
      elem.tl.staggerFromTo(
        staggerItems,
        0.75,
        {
          y: 30,
          alpha: 0
        },
        {
          y: 0,
          alpha: 1,
          ease: Expo.easeOut
        },
        0.025,
        0
      )
    } else if (elem.animation === 'stagger-slider') {
      const staggerItems = elem.el.querySelectorAll('.slick-slide')
      elem.tl.staggerFromTo(
        staggerItems,
        0.75,
        {
          y: 30,
          alpha: 0
        },
        {
          y: 0,
          alpha: 1,
          ease: Expo.easeOut
        },
        0.1,
        0
      )
    } else if (elem.animation === 'stagger-words') {
      Splitting({
        /* target: String selector, Element, Array of Elements, or NodeList */
        target: elem.el,
        /* by: String of the plugin name */
        by: 'words',
        /* key: Optional String to prefix the CSS variables */
        key: null
      })

      const staggerItems = elem.el.querySelectorAll('.word')
      elem.tl.staggerFromTo(
        staggerItems,
        0.75,
        {
          y: 30,
          alpha: 0
        },
        {
          y: 0,
          alpha: 1,
          ease: Expo.easeOut
        },
        0.025,
        0
      )
    } else if (elem.animation === 'stagger-chars') {
      Splitting({
        /* target: String selector, Element, Array of Elements, or NodeList */
        target: elem.el,
        /* by: String of the plugin name */
        by: 'chars',
        /* key: Optional String to prefix the CSS variables */
        key: null
      })

      const staggerItems = elem.el.querySelectorAll('.char')
      elem.tl.set(staggerItems, {
        alpha: 0,
        onComplete: function() {
          elem.el.style.opacity = 1
        }
      })

      elem.tl.staggerFromTo(
        staggerItems,
        0.75,
        {
          y: 30,
          alpha: 0
        },
        {
          y: 0,
          alpha: 1,
          ease: Expo.easeOut
        },
        0.025,
        0
      )
    } else if (elem.animation === 'view') {
      elem.el.classList.add('in-view')
    } else if (elem.animation === 'fade-up') {
      elem.tl.fromTo(
        elem.el,
        0.75,
        {
          y: 30,
          alpha: 0
        },
        {
          y: 0,
          alpha: 1,
          ease: Expo.easeOut
        },
        0.025,
        0
      )
    }

    elem.tl.progress(1).progress(0)
  }

  handler(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        let i = this.elems.indexOf(entry.target)
        let elem = this.cache[i]

        elem.isIntersected = true

        elem.tl.play()

        this.stillObserving()
          ? this.observer.unobserve(entry.target)
          : this.observer.disconnect()
      } else {
        return
      }
    })
  }

  stillObserving() {
    return this.cache.some(e => !e.isIntersected)
  }

  destroy() {
    this.observer.disconnect()
    this.observer = null
    this.elems = null
    this.cache = null
  }

  run() {
    this.cache.forEach(elem => {
      this.setAnimation(elem)

      this.observer.observe(elem.el)
    })
  }

  init() {
    this.getCache()

    this.createObserver()

    this.run()
  }
}

export default InViewAnimation
