import styled from '@emotion/styled'
import Fractal from 'assets/fractal-completed-modal.png'
import BottomModal from 'components/BottomModal'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import Space from 'components/Space'
import View from 'components/View'

const CompleteModal = ({
  open,
  onClose,
  exitBugRequest,
  exitButtonLabel,
  parentIsInner
}) => {
  return (
    <BottomModal
      open={open}
      onClose={onClose}
      contentPadding="0"
      contentBackground="#0698A8"
      modalId={parentIsInner ? 'inner-modal-root' : 'second-inner-modal-root'}
      height="100%"
    >
      <Container maxWidth="500px">
        <View direction="column" alignItems="center" padding="120px 0 0">
          <img src={Fractal} alt="fractal" width={200} height={200} />
          <Space size={60} />
          <Label size={60}>COMPLETE</Label>
          <Space size={20} />
          <Label
            align="center"
            size={16}
            lineHeight={1.6}
            color="rgba(255, 255, 255, 0.5)"
          >
            your request has been sent successfully. We will send a response to
            the email address you provided.
          </Label>
          <Space size={80} />
          <BackButton onClick={exitBugRequest}>{exitButtonLabel}</BackButton>
        </View>
      </Container>
    </BottomModal>
  )
}

const BackButton = styled.button`
  width: 100%;
  background-color: #fff;
  transition: all 0.3s ease;
  opacity: 1;
  padding: 22px 0;
  border-radius: 10px;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;

  &:hover {
    opacity: 0.6;
  }
`

export default CompleteModal
