import { useEffect, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'

export const useVirtualizedListLoadMore = ({ loadMore }) => {
  const bottomPaddingRef = useRef(null)
  const enterCountRef = useRef(0)
  const { enterCount, inViewport } = useInViewport(bottomPaddingRef)

  useEffect(() => {
    if (inViewport && enterCount === enterCountRef.current + 1) {
      enterCountRef.current = enterCountRef.current + 1
      loadMore?.()
    }
  }, [enterCount, inViewport, loadMore])

  return { bottomPaddingRef }
}
