import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_RENT_PRICE } from 'service/graphql/queries'

import { ReactComponent as EthPriceLight } from 'assets/eth-price-light.svg'
import Avatar from 'components/Avatar'
import Card from 'components/Card'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'

const FeaturedNetTokenCard = ({
  margin,
  headerBg,
  bodyBg,
  bodyColor,
  footerBg,
  searchTerm,
  cardBorder,
  onClick
}) => {
  const [price, setPrice] = useState(false)

  const { data } = useQuery(GET_RENT_PRICE, {
    variables: {
      label: searchTerm,
      duration: 365
    }
  })

  useEffect(() => {
    if (!data) return
    let rentPrice = data.getRentPrice
    setPrice(rentPrice ? (rentPrice.toString() / 10000000000000).toFixed(3) : 0)
  }, [data, setPrice])

  const handleClickCard = () => {
    if (onClick) {
      return onClick()
    }
  }

  return (
    <Card
      width="310px"
      height="430px"
      margin={margin}
      background="#212121"
      borderRadius="12px"
      onClick={handleClickCard}
      hasShadowOnHover
    >
      <View
        width="100%"
        height="100%"
        direction="column"
        background={headerBg}
        overflow="visible"
        borderRadius="10px"
      >
        <View height="60px" padding="0px 30px" alignItems="center" />
        <View
          width="100%"
          flex={1}
          direction="column"
          background={footerBg ? footerBg : '#4A4A4D'}
          borderRadius="8px"
        >
          <View
            width="100%"
            flex={1}
            position="relative"
            zIndex="10"
            direction="column"
            justify="space-between"
            background={bodyBg}
            borderRadius="6px"
            padding="40px 30px 30px 30px"
          >
            <View width="100%" justify="space-between">
              {/* <Label size={16} color={bodyColor}>
              </Label> */}
            </View>
            <View width="100%" direction="column-reverse">
              <Label size={16} color="#BCBCCC" />
              <Space size={20} />
              <Label size={30} color={bodyColor || '#fff'}>
                {searchTerm}
              </Label>
              <Space size={20} />
              <Avatar borderRadius="6px" background={'#fff'} />
            </View>
          </View>
          <View
            position="relative"
            width="100%"
            height="90px"
            alignItems="center"
            justify="space-between"
            padding="30px 30px 20px 30px"
            margin="-10px 0 0 0"
            border={cardBorder}
            borderRadius="10px"
          >
            <View alignItems="center">
              <EthPriceLight />
              <Space size={10} />
              <Label size={30} color="#FFFFFF">
                {price}
              </Label>
            </View>
          </View>
        </View>
      </View>
    </Card>
  )
}

export default FeaturedNetTokenCard
