import { Chart } from 'chart.js'
import { externalTooltipHandler } from './tooltip'

export default function({ labels, data: lineData }) {
  const canvas = document.getElementById('categoryChart')
  const ctx = canvas.getContext('2d')
  Chart.defaults.font.family = 'Satoshi'
  Chart.defaults.font.weight = '300'

  const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height)
  gradient.addColorStop(0, 'rgba(6, 152, 168, 0.5)')
  // gradient.addColorStop(0.2, 'rgba(6, 152, 168, 0.1)')
  gradient.addColorStop(1, 'rgba(6, 152, 168, 0)')

  const chart = new Chart(ctx, {
    data: {
      labels: labels,
      datasets: [
        {
          data: lineData,
          borderColor: '#0698A8',
          backgroundColor: gradient,
          pointBackgroundColor: 'white',
          pointBorderColor: '#0698A8',
          pointBorderWidth: 0,
          hoverBorderWidth: 2,
          hoverBorderColor: '#0698A8',
          hoverBackgroundColor: '#fff',
          pointRadius: 0,
          hoverRadius: 6,
          tension: 0.1,
          type: 'line',
          fill: true,
          order: 0,
          yAxisID: 'price'
        }
      ]
    },
    options: {
      legend: {
        display: false
      },
      interaction: {
        mode: 'index',
        intersect: false
      },
      transitions: {
        show: {
          animations: {
            y: {
              duration: 1200,
              from: 500,
              easing: 'easeOutExpo'
            }
          }
        },
        hide: {
          animations: {
            y: {
              duration: 0
            },
            visible: {
              type: 'boolean',
              duration: 0
            }
          }
        }
      },
      plugins: {
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: tooltip => externalTooltipHandler(tooltip)
        },
        filler: {
          propagate: true
        }
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        volume: {
          type: 'linear',
          display: true,
          grid: {
            display: false,
            drawTicks: false,
            drawBorder: false
          },
          ticks: {
            display: false,
            stepSize: 500
          }
        },
        price: {
          type: 'linear',
          position: 'left',
          display: true,
          beginAtZero: true,

          ticks: {
            display: false,
            color: 'rgba(202, 202, 219, 0.5)',
            maxTicksLimit: 3
          },
          grid: {
            drawTicks: false,
            drawBorder: false,
            color: 'rgba(202, 202, 219, 0.5)',
            borderDash: [4, 8]
          }
        },
        y: {
          display: false
        },
        x: {
          offset: false,
          beginAtZero: true,
          align: 'start',
          display: false,
          grid: {
            display: false,
            drawBorder: false,
            color: 'black'
          }
        }
      }
    }
  })
  chart.options.transitions.show.colors = false

  return chart
}
