import { ens_normalize } from '@adraffy/ens-normalize'
import styled from '@emotion/styled/macro'
import { ReactComponent as Cross } from 'assets/cross-gray.svg'
import { ReactComponent as CrossWhite } from 'assets/cross-small-white.svg'
import { ReactComponent as RefreshBlack } from 'assets/refresh-black-flat.svg'
import { ReactComponent as RefreshWhite } from 'assets/refresh-white-flat.svg'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'

import { ReactComponent as ArrowWhite } from 'assets/arrow-down-white.svg'
import { ReactComponent as ArrowBlack } from 'assets/arrow-down.svg'
import LoadingSpinner from 'components/LoadingSpinner'
import ViewTypeSelector from 'components/StickyToolBar/ViewTypeSelector'
import View from 'components/View'
import { CATEGORIES, TAGS } from 'constants/category'
import { FilterContext } from 'contexts/FilterProvider'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import { DurationAccordion } from 'views/TokenProfile/DurationAccordion'
import SearchIcon from 'views/Topbar/SearchIcon'
import { formatBigNumber } from 'views/Subcategory/CategoryHeader'

const SORT_BY_OPTIONS = [
  { value: '', label: 'Default' },
  { value: 'price_high_to_low', label: 'Price high to low' },
  { value: 'price_low_to_high', label: 'Price low to high' },
  { value: 'alphabetical', label: 'Alphabetical' },
  { value: 'highest_last_sale', label: 'Last Sale' },
  { value: 'most_favorited', label: 'Likes' }
]

const SORT_BY_OPTIONS_REGISTRY = [
  { value: '', label: 'Default' },
  { value: 'alphabetical', label: 'Alphabetical' },
  { value: 'highest_last_sale', label: 'Last Sale' },
  { value: 'most_favorited', label: 'Likes' }
]

// const SORT_BY_OPTIONS_PROFILE_LISTED_LIKED = [
//   { value: '', label: 'Default' },
//   { value: 'price_high_to_low', label: 'Price high to low' },
//   { value: 'price_low_to_high', label: 'Price low to high' },
//   { value: 'alphabetical', label: 'Alphabetical' },
//   { value: 'highest_last_sale', label: 'Last Sale' },
//   { value: 'most_favorited', label: 'Likes' }
// ]

const SORT_BY_OPTIONS_PROFILE_UNLISTED = [
  { value: '', label: 'Default' },
  { value: 'alphabetical', label: 'Alphabetical' },
  { value: 'expires_soonest', label: 'Expires soonest' },
  { value: 'expires_latest', label: 'Expires latest' },
  { value: 'highest_last_sale', label: 'Last Sale' },
  { value: 'most_favorited', label: 'Likes' }
]

const ORDER_TYPE_OPTIONS = {
  ALPHABETICAL: {
    prefix: 'order_type',
    orderType: 'both',
    ASC: 'alphabetical',
    DESC: 'alphabetical'
  },
  LAST_SALE: {
    prefix: 'order_type',
    orderType: 'both',
    ASC: 'highest_last_sale',
    DESC: 'highest_last_sale'
  },
  FAVORITES: {
    prefix: 'order_type',
    orderType: 'both',
    ASC: 'most_favorited',
    DESC: 'most_favorited'
  },
  PRICE: {
    prefix: 'order_type',
    orderType: 'both',
    ASC: 'price_low_to_high',
    DESC: 'price_high_to_low'
  },
  LISTED: {
    prefix: 'date_status',
    orderType: 'marketplace',
    ASC: 'recently_listed',
    DESC: 'oldest_listed'
  },
  REGISTERED: {
    prefix: 'date_status',
    orderType: 'registry',
    ASC: 'recently_registered',
    DESC: 'oldest_registered'
  },
  EXPIRES: {
    prefix: 'date_status',
    orderType: 'registry',
    ASC: 'expiring_soonest',
    DESC: 'expiring_soonest'
  }
}

const HeaderBar = ({
  darkMode,
  domainCount,
  renderedDomainCount,
  refreshData,
  viewType,
  onViewTypeChange = () => {},
  onSortByChange = () => {},
  onOrderByChange = () => {},
  onSearchChange,
  searchDomain = '',
  isSimilar = false,
  selectedCategories = [],
  onOpenFilters,
  onHideFilters,
  changeSelectedCategories = () => {},
  profile = false,
  navItem = undefined
}) => {
  const [sortBy, setSortBy] = useState(SORT_BY_OPTIONS[0])
  const [orderType, setOrderType] = useState(ORDER_TYPE_OPTIONS.LAST_SALE)
  const [ascending, setAscending] = useState(false)
  const [searchInputTxt, setSearchInputTxt] = useState(searchDomain)
  const [scrollBarOffset, setScrollBarOffset] = useState(0)
  const [durationExpanded, setDurationExpanded] = useState(false)
  const [domainCountLoading, setDomainCountLoading] = useState(false)

  const inputRef = useRef(null)

  const { opened: filtersOpened, openedRegistry } = useContext(FilterContext)

  const handleSortByChange = option => {
    setSortBy(option)
    onSortByChange(option.value)
  }

  const handleOrderTypeChange = option => {
    if (orderType === option) {
      setOrderType(option)
      setAscending(prev => !prev)
      return
    }
    setOrderType(option)
    setAscending(!(option === ORDER_TYPE_OPTIONS.LAST_SALE))
  }

  useEffect(() => {
    onOrderByChange(
      orderType.prefix === 'order_type'
        ? `order_type=${ascending ? orderType.ASC : orderType.DESC}`
        : `${orderType.prefix}=${
            ascending ? orderType.ASC : orderType.DESC
          }&order_type=default`
    )
  }, [orderType, ascending])

  const handleChangeSearchTxt = e => {
    const inputText = e.target.value.trim().toLowerCase()
    if (inputText === '') {
      setSearchInputTxt('')
      searchDomains('')
      return
    }

    const inputNormalized = ens_normalize(inputText)

    if (!inputNormalized) return

    setSearchInputTxt(inputNormalized)
  }

  useEffect(() => {
    const onScroll = () => {
      const scrollbarTop = window.scrollY
      setScrollBarOffset(scrollbarTop)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.addEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    if (searchDomain === searchInputTxt) return

    setSearchInputTxt(searchDomain)
  }, [searchDomain])

  useEffect(() => {
    if (!profile) return
    setSortBy(SORT_BY_OPTIONS[0])
  }, [navItem])

  const selectedCategoriesColor = useMemo(() => {
    const colors = selectedCategories.map(cat => {
      const categoryKey = cat
        .toLowerCase()
        .split(' ')
        .join('-')

      TAGS.forEach(tag => {
        if (tag.key.toLowerCase() === categoryKey.toLowerCase()) {
          const color =
            MAIN_CATEGORY_COLORS[Object.keys(CATEGORIES).indexOf(tag.key)]

          return color
        } else if (tag.subcategories.length > 0) {
          tag.subcategories.forEach(subCategory => {
            if (subCategory.key.toLowerCase() === categoryKey.toLowerCase()) {
              const color =
                MAIN_CATEGORY_COLORS[Object.keys(CATEGORIES).indexOf(tag.key)]

              return color
            }
          })
        }
      })
    })

    return colors
  }, [selectedCategories])

  const finishDomainCountLoading = () => {
    setDomainCountLoading(false)
  }

  const showClearButton =
    searchInputTxt === searchDomain && searchDomain.length > 0

  return (
    <View
      position="sticky"
      top="80px"
      left="0"
      width="100%"
      zIndex={100000000000}
      transition={'all 0.4s ease'}
    >
      <View
        // width={
        //   (!darkMode && filtersOpened) || (darkMode && openedRegistry)
        //     ? 'calc(100vw - 450px)'
        //     : 'calc(100vw - 140px)'
        // }
        width="100%"
        transition={'all 0.4s ease'}
        background={`${darkMode ? '#000' : '#fff'}`}
        padding={'10px 2px 10px 0px'}
        margin="0 0 10px 0 "
        direction="column"
        left="-1px"
        gap="20px"
        zIndex={100000}
        style={{
          boxShadow: darkMode
            ? '0 11px 9px -11px #000e'
            : scrollBarOffset > 430
            ? '0 9px 9px -11px #0006'
            : 'none',
          transition: 'width 0.4s ease, box-shadow 200ms ease'
        }}
      >
        <View
          width="100%"
          direction="row"
          gap="10px"
          justify="space-between"
          zIndex={10000}
        >
          <RoundedButton
            onClick={() => (filtersOpened ? onHideFilters() : onOpenFilters())}
            padding="22px 20px"
            borderRadius="10px"
            background={darkMode ? '#262628' : '#F3F3FA'}
            hoverBackground={darkMode ? '#363638' : '#ededf7'}
          >
            <Label color={darkMode ? '#fff' : '#020202'} weight={700}>
              Filters
            </Label>
          </RoundedButton>
          <View gap="20px" padding="0 23px 0 0" alignItems="center">
            <RoundedButton
              onClick={() => {
                refreshData()
                setDomainCountLoading(true)
                return setTimeout(() => {
                  finishDomainCountLoading()
                }, 1000)
              }}
              borderRadius="10px"
              background={darkMode ? '#262628' : '#F3F3FA'}
              hoverBackground={darkMode ? '#363638' : '#ededf7'}
              padding="20px"
            >
              {darkMode ? <RefreshWhite /> : <RefreshBlack />}
            </RoundedButton>
            <View alignItems="center" width="100px" justify="center">
              {/* {domainCount != null && !domainCountLoading ? (
                <>
                  <Label
                    color={darkMode ? '#fff' : '#020202'}
                    whiteSpace="nowrap"
                  >
                    {formatBigNumber(domainCount)}&nbsp;
                  </Label>
                  <Label color="#BCBCCC">items</Label>
                </>
              ) : (
                <LoadingSpinner />
              )} */}
              <>
                <Label // PLACEHOLDER FOR WHEN DOMAIN COUNT IS FIXED
                  color={darkMode ? '#fff' : '#020202'}
                  whiteSpace="nowrap"
                />
                <Label color="#BCBCCC" />
              </>
            </View>
          </View>

          {darkMode && scrollBarOffset < 140 ? (
            <View
              width="50%"
              direction="row"
              justify="space-between"
              alignItems="center"
              padding="0 10px 0 0"
            />
          ) : (
            <View
              width="50%"
              height="60px"
              border={`1px solid ${darkMode ? '#4a4a4d' : '#ebebf2'}`}
              borderRadius="10px"
              justify="start"
              alignItems="center"
            >
              <SearchIcon
                size="14px"
                color={darkMode ? 'white' : 'black'}
                onClick={() => onSearchChange(searchInputTxt)}
              />
              <SearchNameInput
                ref={inputRef}
                width={
                  searchInputTxt.length === 0 && selectedCategories.length > 0
                    ? '120px'
                    : '90%'
                }
                darkMode={darkMode}
                placeholder={
                  darkMode ? 'Search unregistered domains' : 'Search domains'
                }
                value={searchInputTxt || ''}
                onChange={handleChangeSearchTxt}
                subcategories={selectedCategories.length > 0}
                onKeyDown={e => {
                  // if (e.which == 37 || e.which == 39) {
                  //   e.preventDefault()
                  //   return
                  // }
                  if (e.key === 'Enter') {
                    if (searchInputTxt.trim().length === 0) return
                    searchDomains(searchInputTxt)
                    return
                  }
                }}
              />
              <View
                gap="10px"
                justify="space-between"
                alignItems="center"
                // overflow="scroll"
                width="auto"
                // scrollBar={`&::-webkit-scrollbar {
                //     height: 5px;
                //     background: white;
                //   }
                //   &::-webkit-scrollbar-thumb {
                //     height: 5px;
                //     background: #555;
                //     border-radius: 20px;
                //     cursor: pointer;
                //   }`}
              >
                {searchInputTxt.length === 0 &&
                  selectedCategories.map(cat => (
                    <RoundedButton
                      key={cat}
                      padding="8px"
                      background="#f3f3f3"
                      transition="0.4s ease-out"
                      style={{
                        gap: '4px'
                      }}
                      onClick={e => {
                        e.currentTarget.style.opacity = '0'
                        setTimeout(() => {
                          const removedCategoryArr = selectedCategories.filter(
                            c => c !== cat
                          )
                          changeSelectedCategories(removedCategoryArr)
                        }, 400)
                      }}
                    >
                      <Label size={14} color="#777" weight="600">
                        Category:
                      </Label>
                      <Label
                        size={14}
                        color="black"
                        weight="600"
                        overflowWrap="nowrap"
                        width="fit"
                      >
                        {cat}
                      </Label>
                      <Cross />
                    </RoundedButton>
                  ))}
              </View>

              <View
                margin="0 10px 0 0"
                borderRadius="4px"
                gap="15px"
                alignItems="center"
                padding="3px"
              >
                {searchInputTxt.length > 0 && searchInputTxt !== searchDomain && (
                  <Label color="#888" whiteSpace="nowrap">
                    Press Enter
                  </Label>
                )}
                {showClearButton && (
                  <button
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '5px',
                      background: darkMode
                        ? 'rgba(255,255,255,0.1)'
                        : 'rgba(0,0,0,0.1)',
                      cursor: showClearButton ? 'pointer' : 'default',
                      borderRadius: '10px'
                    }}
                    onClick={() => {
                      if (!showClearButton) return
                      setSearchInputTxt('')
                      onSearchChange('')
                    }}
                  >
                    {darkMode ? (
                      <CrossWhite width={20} height={20} />
                    ) : (
                      <Cross width={20} height={20} />
                    )}
                  </button>
                )}
              </View>
            </View>
          )}
          <DurationAccordion
            key={navItem || 'woogieboogie'}
            selectedItem={sortBy}
            onChange={handleSortByChange}
            content={
              darkMode
                ? SORT_BY_OPTIONS_REGISTRY
                : profile && (navItem === 'unlisted' || navItem === 'likes')
                ? SORT_BY_OPTIONS_PROFILE_UNLISTED
                : SORT_BY_OPTIONS
            }
            contentExpanded={durationExpanded}
            setContentExpanded={setDurationExpanded}
            width="27.5%"
            itemPadding="20px 10px 20px 20px"
            itemHeight="60px"
            isRegistered={!darkMode}
          />
          <View width="20%">
            <ViewTypeSelector
              value={viewType}
              onChange={onViewTypeChange}
              darkMode={darkMode}
            />
          </View>
        </View>
        {viewType.toLowerCase() === 'list' ? (
          darkMode ? (
            <>
              <View width="100%" padding="10px 20px" alignItems="center">
                <ColumnOption
                  darkMode
                  label="DOMAINS"
                  flex="20%"
                  handleOrderTypeChange={handleOrderTypeChange}
                  ascending={ascending}
                  orderType={orderType}
                  orderOption={ORDER_TYPE_OPTIONS.ALPHABETICAL}
                />
                <ColumnOption
                  darkMode
                  label="CURRENT PRICE"
                  flex="20%"
                  handleOrderTypeChange={handleOrderTypeChange}
                  ascending={ascending}
                  orderType={orderType}
                  orderOption={ORDER_TYPE_OPTIONS.PRICE}
                />
                <ColumnOption
                  darkMode
                  label="LAST SALE"
                  flex="20%"
                  handleOrderTypeChange={handleOrderTypeChange}
                  ascending={ascending}
                  orderType={orderType}
                  orderOption={ORDER_TYPE_OPTIONS.LAST_SALE}
                />
                <ColumnOption
                  darkMode
                  label="REGISTERED"
                  flex="20%"
                  handleOrderTypeChange={handleOrderTypeChange}
                  ascending={ascending}
                  orderType={orderType}
                  orderOption={ORDER_TYPE_OPTIONS.REGISTERED}
                />
                <ColumnOption
                  darkMode
                  label="EXPIRED"
                  flex="18%"
                  handleOrderTypeChange={handleOrderTypeChange}
                  ascending={ascending}
                  orderType={orderType}
                  orderOption={ORDER_TYPE_OPTIONS.EXPIRES}
                />
                <View flex="2%" />
              </View>
            </>
          ) : (
            <>
              <View width="100%" padding="10px 20px" alignItems="center">
                <ColumnOption
                  label={`${renderedDomainCount} DOMAINS`}
                  flex="20%"
                  handleOrderTypeChange={handleOrderTypeChange}
                  ascending={ascending}
                  orderType={orderType}
                  orderOption={ORDER_TYPE_OPTIONS.ALPHABETICAL}
                />
                <ColumnOption
                  label="CURRENT PRICE"
                  flex="20%"
                  handleOrderTypeChange={handleOrderTypeChange}
                  ascending={ascending}
                  orderType={orderType}
                  orderOption={ORDER_TYPE_OPTIONS.PRICE}
                />
                <ColumnOption
                  label="LAST SALE"
                  flex="20%"
                  handleOrderTypeChange={handleOrderTypeChange}
                  ascending={ascending}
                  orderType={orderType}
                  orderOption={ORDER_TYPE_OPTIONS.LAST_SALE}
                />

                <View flex="20%">
                  <Label size={13} color="#BCBCCC">
                    OWNER
                  </Label>
                </View>
                <ColumnOption
                  label="LISTED"
                  flex="18%"
                  handleOrderTypeChange={handleOrderTypeChange}
                  ascending={ascending}
                  orderType={orderType}
                  orderOption={ORDER_TYPE_OPTIONS.EXPIRES}
                />
                <View flex="2%" />
              </View>
            </>
          )
        ) : (
          ''
        )}
      </View>
    </View>
  )
}

const ColumnOption = ({
  ascending,
  orderType,
  handleOrderTypeChange,
  orderOption,
  darkMode,
  flex,
  label
}) => {
  return (
    <View flex={flex}>
      <RoundedButton
        onClick={() => handleOrderTypeChange(orderOption)}
        style={{ gap: '8px' }}
      >
        <Label size={13} color="#BCBCCC">
          {label}
        </Label>
        <View
          opacity={orderType === orderOption ? '1' : '0'}
          transition="opacity 500ms ease, transform 500ms ease"
          style={{
            transform: ascending ? 'rotate(180deg)' : 'rotate(0deg)'
          }}
        >
          {darkMode ? <ArrowWhite /> : <ArrowBlack />}
        </View>
      </RoundedButton>
    </View>
  )
}

export default HeaderBar

const SearchNameInput = styled.input`
  height: 53px;
  width: ${props => props.width};
  font-size: 1rem;
  padding: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  color: ${props => (props.darkMode ? 'white' : 'black')};
`

const RegistryTableHeader = styled.div`
  align-items: center;
  color: #bcbccc;
  display: flex;
  font-size: 13px;
  margin: 4px 0 20px;
  height: 20px;
  padding: 0 30px 0 20px;
  width: 100%;

  div {
    width: 24%;
  }

  .shorter-header-el {
    width: 24%;
  }

  .first-header-el {
    width: 22.25%;
  }
`
const TableHeader = styled.div`
  align-items: center;
  color: #bcbccc;
  display: flex;
  font-size: 13px;
  margin: 3px 0 0px;
  height: 20px;
  padding: 0 30px 0 20px;
  width: 100%;

  div {
    width: 20%;
  }

  .shorter-header-el {
    width: 17.75%;
  }

  .first-header-el {
    width: 22.25%;
  }
`
