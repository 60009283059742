import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import App from 'App'
import ReactDOM from 'react-dom'
import reportWebVitals from 'reportWebVitals'
import { sendToVercelAnalytics } from 'vitals'
import './i18n'

import 'globalStyles'
import './index.css'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  replaysSessionSampleRate: 0.2,
  tracesSampleRate: 1.0,
  integrations: [new BrowserTracing(), new Sentry.Replay()]
})

ReactDOM.render(<App />, document.getElementById('root'))

reportWebVitals(sendToVercelAnalytics)
