import React from 'react'
import styled from '@emotion/styled/macro'

const Link = styled.div`
  background: transparent;
  color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s ease;
  padding: ${props => props.padding};
  overflow: ${props => props.overflow};

  :hover {
    opacity: ${props => (props.hoverOpacity ? props.hoverOpacity : 1)};
  }
`

export default Link
