import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import KodexIcon from './Icon/KodexIcon'
import { ReactComponent as ArrowTop } from 'assets/arrow-down.svg'
import View from './View'

const ScrollUpButton = ({ dark, scrollToPosition, feed }) => {
  const [isVisible, setIsVisible] = useState(false)
  const timeoutRef = useRef(null)

  const throttleScrollEventHandler = useCallback(() => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      if (window.scrollY > scrollToPosition) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }, 250)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', throttleScrollEventHandler)

    return () => {
      window.removeEventListener('scroll', throttleScrollEventHandler)
    }
  }, [throttleScrollEventHandler])

  const buttonClickHandler = () => {
    window.scrollTo({
      left: 0,
      top: scrollToPosition,
      behavior: 'smooth'
    })
  }

  return (
    <View
      style={{
        position: 'sticky',
        top: 'calc(100vh - 65px)',
        left: 0,
        transform: `translateX(calc(100vw - ${
          dark ? (feed ? '53vw' : '65px') : '135px'
        }))`
      }}
      zIndex={100000}
    >
      <ScrollUpButtonComponent
        visible={isVisible}
        dark={dark}
        onClick={buttonClickHandler}
      >
        <KodexIcon
          padding={0}
          size={20}
          icon={<ArrowTop />}
          stroke={dark ? '#FFFFFF' : '#020202'}
        />
      </ScrollUpButtonComponent>
    </View>
  )
}

const ScrollUpButtonComponent = styled.button`
  border-radius: 6px;
  background-color: ${props => (props.dark ? '#262628' : '#F3F3FA')};
  padding: 20px;
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  cursor: ${props => (props.visible ? 'pointer' : 'auto')};
  transition: all 0.25s ease;
  transform: rotateX(180deg);
`

export default ScrollUpButton
