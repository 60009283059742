import styled from '@emotion/styled/macro'
import userDefaultAvatar from 'assets/icon-pfp-default.png'
import Link from 'components/Forms/Link'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { ethers } from 'ethers'
import gsap from 'gsap'
import useGetDomainOwner from 'hooks/useGetDomainOwner'
import useUserProfile from 'hooks/useUserProfile'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import SearchResultItem from './SearchResultItem'
import SearchResultTitle from './SearchResultTitle'

const SearchResult = ({ data, onClose, searchTerm }) => {
  const history = useHistory()
  const rootRef = useRef(null)
  const [filteredUsers, setFilteredUsers] = useState([])

  const provider = new ethers.providers.StaticJsonRpcProvider(
    process.env.REACT_APP_LLAMA_NODES_URL,
    'homestead'
  )

  useEffect(() => {
    const elems = rootRef.current.querySelectorAll('.inview')
    gsap.fromTo(
      elems,
      {
        y: 30,
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        stagger: {
          each: 0.065
        }
      }
    )
  }, [])

  const onItemPressed = redirect => {
    onClose()
    redirect()
  }

  const { marketDomains, registryDomains, users, categories = [] } = data

  useEffect(() => {
    const seenUsers = []
    const seenPrimaries = []

    const validUserDomain = async user => {
      const resolvedAttempt = await provider
        .resolveName(user?.id || '')
        .catch(() => null)
      const lookup = await provider
        .lookupAddress(resolvedAttempt)
        .catch(() => null)

      if (
        user?.item_type === 'user' &&
        lookup !== null &&
        resolvedAttempt?.toLowerCase() === user?.id.toLowerCase()
      ) {
        seenPrimaries.push(lookup)
      }

      if (lookup === null && user?.item_type === 'user') {
        seenUsers.push(user)
        return true
      } else if (
        (resolvedAttempt === null || lookup === null) &&
        lookup?.toLowerCase() !== user?.id.toLowerCase()
      ) {
        return false
      } else if (user?.item_type === 'user' && seenPrimaries.includes(lookup)) {
        return true
      } else if (
        user?.item_type === 'domain' &&
        seenPrimaries.includes(lookup)
      ) {
        return false
      } else if (!seenUsers.includes(user)) {
        seenUsers.push(user)
        return true
      }
      return false
    }

    if (users) {
      const usersReduces = users?.reduce(async (acc, u) => {
        const result = await validUserDomain(u)
        if (!result) {
          return acc
        }
        return (await acc).concat(u)
      }, [])

      Boolean(usersReduces)
        ? Boolean(usersReduces?.then !== undefined)
          ? usersReduces.then(fu => setFilteredUsers(fu))
          : setFilteredUsers(usersReduces)
        : null
    }
  }, [users])

  const totalCount = registryDomains.length + marketDomains.length

  const switchMarketRegistry = marketDomains
    .map(({ name }) => name.replace('.eth', ''))
    .includes(searchTerm.replace('.eth', ''))

  const domains = [
    registryDomains.length > 0 && (
      <View direction="column" width="100%" key={1}>
        <Space size={20} />
        <SearchResultTitle
          text="Registry"
          button={
            <Link
              hoverOpacity={0.6}
              onClick={() =>
                onItemPressed(() =>
                  history.push(`/registry?search=${searchTerm}`)
                )
              }
              overflow="hidden"
            >
              <Label size={13} color="#020202">
                See all
              </Label>
            </Link>
          }
        />

        <View wrappable>
          {registryDomains.slice(0, 6).map(domain => (
            <SearchResultItem key={domain.name} className="inview">
              <Link
                hoverOpacity={0.6}
                onClick={() =>
                  onItemPressed(() =>
                    history.push(`/registry?search=${domain.name}`)
                  )
                }
                overflow="hidden"
              >
                <Label size={16} color="#020202">
                  {domain.name}
                </Label>
              </Link>
            </SearchResultItem>
          ))}
        </View>
      </View>
    ),
    marketDomains.length > 0 && (
      <View direction="column" width="100%" key={2}>
        <Space size={20} />
        <SearchResultTitle
          text="Marketplace"
          button={
            <Link
              hoverOpacity={0.6}
              onClick={() => history.push(`/marketplace?search=${searchTerm}`)}
              overflow="hidden"
            >
              <Label size={13} color="#020202">
                See all
              </Label>
            </Link>
          }
        />

        <View wrappable>
          {marketDomains.slice(0, 6).map(domain => (
            <SearchResultItem
              className="inview"
              key={domain.name}
              onClick={() =>
                onItemPressed(() => history.push(`/domain/${domain.name}`))
              }
            >
              <Link hoverOpacity={0.6} overflow="hidden">
                <Label size={16} color="#020202">
                  {domain.name}
                </Label>
              </Link>
            </SearchResultItem>
          ))}
        </View>
      </View>
    )
  ]

  return (
    <View ref={rootRef} direction="column" width="100%">
      <SearchResultTitle
        text={`${totalCount >= 99 ? '99+' : totalCount} Items`}
      />
      {switchMarketRegistry ? domains.reverse() : domains}
      {filteredUsers.length > 0 && (
        <View direction="column" width="100%">
          <Space size={20} />
          <SearchResultTitle text="Users" />

          <View wrappable>
            {filteredUsers.slice(0, 3).map(user => (
              <UserSearchItem
                key={user.item_name}
                id={user.id}
                name={user.item_name}
                type={user.item_type}
                width={
                  filteredUsers.length === 1
                    ? '100%'
                    : filteredUsers.length === 2
                    ? '220px'
                    : '160px'
                }
                onClick={address => {
                  history.push(`/profile/${address?.slice(2, address?.length)}`)
                  onClose()
                }}
              />
            ))}
          </View>
        </View>
      )}

      {categories.length > 0 && (
        <View direction="column" width="100%">
          <Space size={20} />
          <SearchResultTitle text="Categories" />

          <View wrappable>
            {categories.slice(0, 6).map((category, index) => (
              <SearchResultItem key={index} className="inview">
                <Link
                  hoverOpacity={0.6}
                  onClick={() => {
                    onItemPressed(() => {
                      history.push(
                        `/marketplace?category=${category.name.toLowerCase()}`
                      )
                    })
                  }}
                  overflow="hidden"
                >
                  <Label size={16} color={category.color}>
                    #{category.name}
                  </Label>
                </Link>
              </SearchResultItem>
            ))}
          </View>
        </View>
      )}
    </View>
  )
}

const UserSearchItem = ({ id, name, type, width, onClick }) => {
  const labelName = id.replace('.eth', '')
  const { ownerAddress = id } = useGetDomainOwner(labelName)

  const { profile: profile, refetch: refetchProfileData } = useUserProfile(
    ownerAddress
  )
  const picture = profile?.userInfo?.picture

  useEffect(() => {
    refetchProfileData(ownerAddress)
  }, [ownerAddress])

  return (
    <Link onClick={() => onClick(ownerAddress)} hoverOpacity={0.6}>
      <View
        padding="10px 30px 10px 0"
        alignItems="center"
        width={width}
        className="inview"
        cursor="pointer"
      >
        <UserImage
          src={picture ? picture : userDefaultAvatar}
          alt={name + ' avatar'}
        />
        <Space size={16} />
        <Label size={16} color="#020202" whiteSpace="nowrap">
          {type === 'domain' ? name : `${name}`}
        </Label>
      </View>
    </Link>
  )
}

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #e6eaf4;
`

export default SearchResult
