import styled from 'styled-components'

const Label = styled.span`
  z-index: ${props => (props.zIndex ? props.zIndex : 0)};
  color: ${props => (props.color ? props.color : '#fff')};
  font-size: ${props => (props.size ? props.size / 16 : 1)}rem;
  font-weight: ${props => (props.weight ? props.weight : '400')};
  opacity: ${props => (props.opacity !== undefined ? props.opacity : 1)};
  text-align: ${props => (props.align ? props.align : 'left')};
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  line-height: ${props => props.lineHeight};
  letter-spacing: ${props =>
    props.letterSpacing ? props.letterSpacing : '0.03em'};
  transition: ${props =>
    props.transition
      ? props.transition
      : 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)'};
  text-overflow: ellipsis !important;

  ${props =>
    props.overflow &&
    !props.overflowY &&
    `overflow-x:${props.overflow} !important;`}
  overflow-y:${props => (props.overflowY ? props.overflowY : 'visible')};
  ${props => (props.inlineSize ? `inline-size: ${props.inlineSize};` : '')};

  overflow-wrap: ${props => props.overflowWrap};
  white-space: ${props => props.whiteSpace};
  ${props => (props.cursor ? `cursor: ${props.cursor};` : '')}

  ${props =>
    props.animation
      ? `animation: ${props.animation};`
      : `-webkit-animation: none;
        animation: none;
      `}

  &:hover {
    color: ${props => props.hoverColor || props.color};
  }

  &[data-in='view'] {
    transform: translateY(20px);
    opacity: 0;
  }

  &.in-view {
    transform: translateY(0);
    opacity: ${props => (props.opacity !== undefined ? props.opacity : 1)};
  }

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  @keyframes fadeFromBottom {
    0% {
      transform: translateY(25%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

export default Label
