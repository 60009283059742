import React from 'react'
import styled from '@emotion/styled/macro'
import Container from 'components/Layout/Container'
import View from 'components/View'
import Space from 'components/Space'
import Label from 'components/Label'
import BottomModal from 'components/BottomModal'
import RegistrationSuccessfullImage from 'assets/registration-successfull.png'
import Fractal from 'assets/cancel-listings-fractal.png'
import RoundedButton from 'components/Forms/RoundedButton'

const CancelListingsModal = ({
  open,
  onClose,
  cancelListingsModal,
  canceling,
  cancelled
}) => {
  return (
    <BottomModal
      open={open}
      onClose={onClose}
      contentPadding="0"
      contentBackground="#fff"
      modalId="inner-modal-root"
    >
      {cancelled ? (
        <View
          direction="column"
          height="95.5vh"
          width="100%"
          padding="8vh 0 2rem 0"
          background="#0698A8"
          overflow="auto"
          justify="center"
          alignItems="center"
          gap="5vh"
        >
          <img src={RegistrationSuccessfullImage} />
          <View
            direction="column"
            gap="3vh"
            justify="center"
            alignItems="center"
          >
            <Label size={40} color="#fff">
              Complete
            </Label>
            <Label size={20} color="#fff8">
              All listigns and offers canceled successfully.
            </Label>
          </View>
          <RoundedButton
            background="#fff"
            hoverBackground="#ddd"
            padding="1.5rem 1rem"
            width="33vw"
            onClick={onClose}
          >
            <Label size={20} color="#000">
              Go back
            </Label>
          </RoundedButton>
        </View>
      ) : (
        <Container maxWidth="500px">
          <View
            padding="120px 0"
            justify="center"
            alignItems="center"
            direction="column"
          >
            <img src={Fractal} alt="fractal" width={200} height={200} />
            <Space size={60} />
            <Label size={60} color="#020202">
              ARE YOU SURE?
            </Label>
            <Space size={20} />
            <Label size={16} color="#BCBCCC" lineHeight={1.6} align="center">
              Cancelling your listing will unpublish this sale from Kodex and
              requires a transaction to make sure it will never be fulfillable.
            </Label>
            <Space size={80} />
            <View>
              <CancelListingsButton onClick={onClose}>
                NO, GO BACK
              </CancelListingsButton>
              <Space size={10} />
              <CancelListingsButton
                onClick={cancelListingsModal}
                disabled={canceling}
                primary
              >
                {canceling ? 'CANCELING...' : 'YES, CANCEL LISTINGS'}
              </CancelListingsButton>
            </View>
          </View>
        </Container>
      )}
    </BottomModal>
  )
}

const CancelListingsButton = styled.button`
  width: 245px;
  padding: 22px 0;
  color: ${props => (props.primary ? '#fff' : '#020202')};
  background-color: ${props =>
    props.disabled ? '#BCBCCC' : props.primary ? '#AF61AB' : '#fff'};
  border-radius: 10px;
  border: ${props => (props.primary ? 'none' : '1px solid #E1E1E8')};
  box-sizing: ${props => (props.primary ? 'content-box' : 'border-box')};
  display: flex;
  justify-content: center;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  line-height: 1;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.25s ease;
  opacity: 1;

  &:hover {
    opacity: 0.75;
  }
`

export default CancelListingsModal
