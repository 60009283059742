import { SiweMessage } from 'siwe'

export async function createSiweMessage(
  address,
  statement,
  nonce,
  chainId = '1'
) {
  const domain = window.location.host
  const origin = window.location.origin

  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: '1',
    chainId: chainId,
    nonce
  })

  return message.prepareMessage()
}
