import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import TableContext from './TableContext'
import Row from './Row'
import useCellDescriptor from './utils/useCellDescriptor'

import './styles/table.css'
import styled from '@emotion/styled'

const Table = React.forwardRef((props, ref) => {
  const {
    className,
    style,
    children,
    data: dataProp,
    contextData,
    virtualized,
    width: widthProp,
    height,
    maxHeight,
    headerHeight = 60,
    rowHeight = 60,
    paddingRight = 10,
    rowKey,
    rowClassName,
    renderRow: renderRowProp,
    ...rest
  } = props

  const headerRef = useRef(null)
  const contentContainerRef = useRef(null)

  const { headerCells, bodyCells } = useCellDescriptor({
    children,
    headerHeight,
    rowHeight
  })

  const [data, setData] = useState(() => dataProp)
  const [hideScrollbar, setHideScrollbar] = useState(true)

  useEffect(() => {
    setData(dataProp)
  }, [dataProp, rowKey])

  const classes = classNames('kodex-table', className)

  const styles = {
    width: widthProp,
    height,
    position: 'relative',
    ...style
  }

  useEffect(() => {
    let timeoutId

    const handleScroll = () => {
      setHideScrollbar(false)
      clearTimeout(timeoutId)

      timeoutId = setTimeout(() => {
        setHideScrollbar(true)
      }, 2000)
    }

    const containerElement = contentContainerRef.current

    containerElement.addEventListener('scroll', handleScroll)

    return () => {
      containerElement.removeEventListener('scroll', handleScroll)
      clearTimeout(timeoutId)
    }
  }, [])

  const renderTableHeader = headerCells => {
    const rowProps = {
      headerHeight,
      isHeaderRow: true
    }

    return renderRow(rowProps, headerCells)
  }

  const renderTableBody = bodyCells => {
    if (data) {
      const rows = data.slice().map((row, index) => {
        const rowProps = {
          key: index,
          height: rowHeight,
          rowData: row,
          rowIndex: index,
          hasChildren: row.children && Array.isArray(row.children),
          background: style?.background
        }
        return renderRow(rowProps, bodyCells)
      })

      return <>{rows}</>
    }

    return null
  }

  const renderRow = (props, cells) => {
    if (typeof renderRowProp === 'function') {
      return renderRowProp(props, cells)
    }

    return <Row {...props}>{cells}</Row>
  }

  const contextValue = React.useMemo(() => contextData || {}, [contextData])

  return (
    <TableContext.Provider value={contextValue}>
      <div ref={ref} {...rest} className={classes} style={styles}>
        {renderTableHeader(headerCells)}
        {/* <View
          position="absolute"
          top="0"
          left="0"
          height="50px"
          width="100%"
          backgorund="linear-gradient(to bottom, #fff, #fff0)"
        /> */}
        <StyledInnerTable
          hideScrollbar={hideScrollbar}
          style={{
            height: height,
            maxHeight: maxHeight,
            overflowY: 'scroll'
          }}
          ref={contentContainerRef}
        >
          {children && renderTableBody(bodyCells)}
        </StyledInnerTable>
      </div>
    </TableContext.Provider>
  )
})

const StyledInnerTable = styled.div`
  color: ${props =>
    props.hideScrollbar ? 'transparent' : 'rgba(0, 0, 0, 0.1)'};
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  transition: color 0.3s ease;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border: 10px solid;
  }

  &::-webkit-scrollbar-thumb {
    border: 5px solid ${props => !props.hideScrollbar && 'rgba(0,0,0,0.3)'};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${props => !props.hideScrollbar && 'rgba(0,0,0,0.2)'};
  }
`

export default Table
