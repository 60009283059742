import styled from '@emotion/styled/macro'
import { ReactComponent as SelectedEllipse } from 'assets/selected-ellipse.svg'
import { ReactComponent as TickWhite } from 'assets/tick-white.svg'
import Label from 'components/Label'

const FilterList = ({ items, onSelect, useTick, darkMode }) => {
  return (
    <Container>
      {items.map(({ label, value, selected }, index) => (
        <Row key={index}>
          <CheckboxContainer
            isSelected={selected}
            darkMode={darkMode}
            onClick={() => onSelect(value)}
          >
            {selected && (useTick ? <TickWhite /> : <SelectedEllipse />)}
          </CheckboxContainer>
          <Label color="#919ca6">{label}</Label>
          <Spacer />
        </Row>
      ))}
    </Container>
  )
}

export default FilterList

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

const Row = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  min-height: 30px;
`

const CheckboxContainer = styled.div`
  align-items: center;
  background: ${({ darkMode, isSelected }) =>
    !darkMode && isSelected ? '#47596B' : ''};
  border: 1px solid ${({ darkMode }) => (darkMode ? '#4a4a4d' : '#919CA6')};
  border-radius: 4px;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
`

const Spacer = styled.div`
  flex: 1;
`
