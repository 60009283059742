import styled from '@emotion/styled/macro'
import View from 'components/View'
import { useState } from 'react'
import MobileActivities from './components/MobileActivities'
import MobileActivityDropdown from './components/MobileActivityDropdown'
import MobileOffers from './components/MobileOffers'

const TABS = ['Offers', 'Activity']

const MobileActivityAndOffers = ({
  activityData,
  offers,
  cancelOffer,
  setHighestOffer,
  canceling,
  acceptOffer,
  accepting,
  ownedDomain,
  isRegistered,
  bodyColor,
  bodyBg,
  bodyBorder
}) => {
  const [tab, setTab] = useState(TABS[1])
  const [activityDropdownIsOpen, setActivityDropdownIsOpen] = useState(false)
  const [activityDropdownItem, setActivityDropdownItem] = useState('All events')

  return (
    <div
      style={{
        width: '100%',
        borderRadius: '10px',
        border: `1px solid ${bodyBorder}`,
        direction: 'column'
      }}
    >
      <View
        width="100%"
        padding={tab === TABS[0] ? '16px' : '9px 16px 14px 16px'}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <TabsContainer>
          {isRegistered
            ? TABS.map(t => (
                <Tab
                  active={tab === t}
                  onClick={() => setTab(t)}
                  key={t}
                  background="#fff"
                  color="#020202"
                >
                  {t}
                </Tab>
              ))
            : [TABS[1]].map(t => (
                <Tab
                  active={tab === t}
                  onClick={() => setTab(t)}
                  key={t}
                  background="#020202"
                  color="#fff"
                >
                  {t}
                </Tab>
              ))}
        </TabsContainer>
        {tab === TABS[1] && (
          <MobileActivityDropdown
            open={activityDropdownIsOpen}
            setOpen={setActivityDropdownIsOpen}
            selectedItem={activityDropdownItem}
            setItem={setActivityDropdownItem}
            isRegistered={isRegistered}
          />
        )}
      </View>
      <View width="100%">
        {tab === TABS[0] && isRegistered && (
          <MobileOffers
            parsedOffers={offers}
            cancelOffer={cancelOffer}
            navItem={tab}
            setHighestOffer={setHighestOffer}
            canceling={canceling}
            acceptOffer={acceptOffer}
            accepting={accepting}
            ownedDomain={ownedDomain}
            isRegistered={isRegistered}
          />
        )}
        {tab === TABS[1] && (
          <MobileActivities
            activityData={activityData}
            isRegistered={isRegistered}
            filter={activityDropdownItem}
          />
        )}
      </View>
    </div>
  )
}

export default MobileActivityAndOffers

export const Tab = styled('div')`
  font-size: 16px;
  color: ${({ active, color }) => (active ? color : '#BCBCCC')};
  transition: all 0.1s ease-out;
  border-radius: ${({ active }) => (active ? '6px' : '0')};
  flex: 1;
  font-weight: 600;
  display: flex;
  font-family: 'Satoshi', sans-serif;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:hover,
  &:visited {
    cursor: pointer;
  }
`

export const TabsContainer = styled('div')`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  justify-content: start;
  overflow: hidden;
`
