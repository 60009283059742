import styled from '@emotion/styled/macro'
import Label from 'components/Label'
import useGetCategoryCount from 'hooks/useGetCategoryCount'
import { useEffect } from 'react'
import { Checkbox } from './Checkbox'

export const Category = ({
  color,
  name,
  isSelected,
  isActive,
  count,
  isSubcategory,
  onSelect = () => {},
  onClick = () => {},
  onUpdateDomainCount = () => {},
  darkMode
}) => {
  const totalCount = useGetCategoryCount(name, isSubcategory)
  useEffect(() => {
    onUpdateDomainCount(name, totalCount || 0)
    return () => {}
  }, [totalCount])

  return (
    <Container
      onClick={onClick}
      className={isActive ? 'active' : ''}
      darkMode={darkMode}
    >
      <Label size={16} color={color || (darkMode ? '#fff' : '#020202')}>
        {name}
      </Label>
      <Price>
        {Number(totalCount)
          .toLocaleString('default', {
            maximumFractionDigits: 0
          })
          .replaceAll(',', ' ')}
      </Price>
      {!isSubcategory && <Count darkMode={darkMode}>{count}</Count>}
      <Checkbox
        onCheck={() => onSelect(totalCount)}
        isChecked={isSelected}
        darkMode={darkMode}
      />
    </Container>
  )
}

const Container = styled.div`
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: 16px;
  padding: 0 20px;
  margin: 5px 0;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover,
  &.active {
    background: ${props => (props.darkMode ? '#333333' : '#f3f3fa')};
  }
`
const Name = styled.div`
  color: ${props => props.color};
`
const Price = styled.div`
  color: #bcbccc;
  margin-left: auto;
  margin-right: 20px;
  font-family: 'Satoshi', sans-serif;
`
const Count = styled.div`
  background: ${props => (props.darkMode ? '#212121' : '#f3f3fa')};
  border-radius: 4px;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  font-family: 'Satoshi', sans-serif;
  color: ${props => (props.darkMode ? '#fff' : '#020202')};
  text-align: center;
  line-height: 30px;
  transition: all 0.3s ease;
  ${Container}:hover & {
    background: ${props => (props.darkMode ? '#212121' : '#fff')};
  }
  ${Container}.active & {
    background: ${props => (props.darkMode ? '#212121' : '#fff')};
  }
`
