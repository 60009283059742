import React from 'react'
import styled from '@emotion/styled'

const PriceInfoCard = () => {
  return (
    <InfoContainer>
      Registration of the domain will be attempted the very moment it reaches
      the desired price.
    </InfoContainer>
  )
}

export default PriceInfoCard

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff1ea;
  padding: 1.5rem;
  border-radius: 15px;
  color: #888;
`
