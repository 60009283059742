import { useLazyQuery } from '@apollo/client'
import { useState, useMemo, useCallback } from 'react'
import {
  kodexApiContext,
  GET_SEARCH_DOMAINS,
  GET_SEARCH_DOMAINS_COUNT
} from 'service/graphql/kodex-api'

export default () => {
  const [vars, setVars] = useState({})
  const [fetchDomainsCount, { data: countData }] = useLazyQuery(
    GET_SEARCH_DOMAINS_COUNT,
    {
      variables: {},
      ...kodexApiContext
    }
  )
  const [fetchDomains, { data, loading, error, fetchMore }] = useLazyQuery(
    GET_SEARCH_DOMAINS,
    {
      variables: {},
      ...kodexApiContext
    }
  )

  const domains = useMemo(() => data?.domains ?? [], [data])

  const totalResults = useMemo(
    () => countData?.domains?.aggregate?.count ?? 0,
    [countData]
  )

  const getDomains = useCallback(
    filter => {
      const {
        limit = 20,
        sort,
        search,
        minLength,
        maxLength,
        minPrice,
        maxPrice,
        subCategories,
        status,
        result,
        type
      } = filter

      let variables = {
        limit,
        offset: 0,
        name_pattern_search: search,
        min_domain_length: minLength,
        max_domain_length: maxLength,
        min_listing_price: minPrice,
        max_listing_price: maxPrice,
        name_symbols_type:
          type && type.length > 0 ? `{${type.join(',')}}` : undefined,
        name_result: result,
        status_type: status,
        search_type: 'registry',
        order_type: sort || 'default',
        terms:
          subCategories && subCategories.length > 0
            ? `{${subCategories.join(',')}}`
            : undefined
      }

      setVars(variables)

      fetchDomainsCount({
        variables: { ...variables, limit: undefined }
      })

      fetchDomains({ variables })
    },
    [fetchDomainsCount, fetchDomains]
  )

  const fetchMoreCallback = useCallback(async () => {
    if (totalResults > 0 && totalResults === domains.length) {
      return
    }
    const variables = {
      ...vars,
      offset: (data?.domains ?? []).length
    }
    await fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newResults = fetchMoreResult.domains
        return {
          domains: [...(previousResult?.domains ?? []), ...newResults]
        }
      }
    })
  }, [data, domains, totalResults])

  // TODO: add new filters here
  return {
    getDomains,
    domains,
    loading,
    error,
    fetchMore: fetchMoreCallback,
    refetchDomains: getDomains,
    domainsCount: countData
  }
}
