import React from 'react'
import { Link as RouteLink } from 'react-router-dom'
import Link from 'components/Forms/Link'
import styled from '@emotion/styled/macro'

const Logo = ({ src }) => {
  return (
    <LogoWrapper>
      <RouteLink to={'/'}>
        <Link hoverOpacity={0.6}>
          <img src={src} alt="logo" />
        </Link>
      </RouteLink>
    </LogoWrapper>
  )
}

const LogoWrapper = styled.div`
  padding-right: 60px;
`

export default Logo
