import { ReactComponent as SmallEthPrice } from 'assets/eth-price-grey-small.svg'
import Label from 'components/Label'
import View from 'components/View'
import { ethers } from 'ethers'
import 'moment-timezone'
import { useMemo } from 'react'
import { useHistory } from 'react-router'
import { COLORS_BY_CATEGORY } from 'utils/constants'

const MOCKUP_DATA = [
  {
    category: 'Company',
    sale_volume: 0.0013,
    offer_volume: 0.0013,
    highest_sale: 0.0013,
    highest_offer: 0.0013,
    floor_price: 0.0013,
    sale_volume_difference: 32,
    offer_volume_difference: -15
  },
  {
    category: 'Misc',
    sale_volume: 0.0013,
    offer_volume: 0.0013,
    highest_sale: 0.0013,
    highest_offer: 0.0013,
    floor_price: 0.0013,
    sale_volume_difference: -40,
    offer_volume_difference: 26
  }
]

const columnHeaders = [
  'Category',
  'Floor price',
  '1D Change',
  '7D Change',
  '1D Volume',
  '7D Volume',
  'Owners',
  'Supply'
]

const TrendingCategoriesTable = ({ trendingCategories }) => {
  const sortedTrendingCategories = useMemo(() => {
    const data = trendingCategories.map(category => {
      const oneDayChangePercentage =
        ((parseFloat(category.last_sale_volume_day) * 100) /
          parseFloat(category.sale_volume_day)) *
          100 || 0
      const sevenDayChangePercentage =
        ((parseFloat(category.last_sale_volume_week) * 100) /
          parseFloat(category.sale_volume_week)) *
          100 || 0

      return {
        ...category,
        one_day_change:
          oneDayChangePercentage === 0
            ? null
            : (oneDayChangePercentage < 0 ? '-' : '+') +
              oneDayChangePercentage +
              '%',
        seven_day_change:
          sevenDayChangePercentage === 0
            ? null
            : (sevenDayChangePercentage < 0 ? '-' : '+') +
              sevenDayChangePercentage +
              '%'
      }
    })
    return data
  }, [trendingCategories])

  const getCategoryColor = event => {
    let iconBg = '#f3f3f3'
    switch (event) {
      case 'sale':
        iconBg = '#EFF0FF'
        break
      case 'purchase':
        iconBg = '#E6F6FF'
        break
      case 'transfer':
        iconBg = '#E8F5F7'
        break
      case 'registration':
        iconBg = '#F1E5FA'
        break
      default:
        iconBg = '#f3f3f3'
        break
    }
    return iconBg
  }

  return (
    <View width="100%" direction="column" justify="space-evenly" padding="">
      <View width="100%" margin="0 0 50px 0">
        {columnHeaders.map((headerLabel, i) => {
          return (
            <View
              key={i}
              flex="12.5%"
              justify={i === 0 ? 'flex-start' : 'flex-end'}
            >
              <Label size={18} color="#A8A8A9">
                {headerLabel}
              </Label>
            </View>
          )
        })}
      </View>

      {sortedTrendingCategories.map((category, i) => {
        return (
          <TrendingCategoriesItem
            key={i}
            category={category.taxonomy}
            floorPrice={category.floor_price_day}
            oneDayChange={category.one_day_change}
            sevenDayChange={category.one_day_change}
            oneDayVolume={category.sale_volume_day}
            sevenDayVolume={category.sale_volume_week}
            ownersCount={category.owners}
            supply={category.supply}
          />
        )
      })}
    </View>
  )
}

const TrendingCategoriesItem = props => {
  const history = useHistory()
  const valuesArray = Object.values(props)
  const keysArray = Object.keys(props)

  const isPriceNumber = value => {
    return (
      value === 'floorPrice' ||
      value === 'oneDayVolume' ||
      value === 'sevenDayVolume'
    )
  }

  return (
    <View width="100%">
      {valuesArray.map((value, i) => {
        return (
          <>
            {i === 0 ? (
              <View
                key={i}
                flex="12.5%"
                onClick={() =>
                  history.push(`/marketplace/${value.toLowerCase()}`)
                }
              >
                <Label
                  size={20}
                  color={
                    COLORS_BY_CATEGORY.filter(c => c.category === value)[0]
                      .color || '#000'
                  }
                  hoverColor={
                    COLORS_BY_CATEGORY.filter(c => c.category === value)[0]
                      .color + 'AA' || '#BCBCCC'
                  }
                  cursor="pointer"
                >
                  {value}
                </Label>
              </View>
            ) : (
              <View
                key={i}
                flex="12.5%"
                padding="27px 0"
                justify="flex-end"
                gap="15px"
                alignItems="center"
              >
                <Label
                  size={20}
                  color={
                    value === null
                      ? '#fff'
                      : value.toString()[0] === '+'
                      ? '#0698A8'
                      : value.toString()[0] === '-'
                      ? '#B56F7C'
                      : '#fff'
                  }
                >
                  {isPriceNumber(keysArray[i])
                    ? Number(ethers.utils.formatEther(Number(value))).toFixed(2)
                    : value}
                </Label>
                {isPriceNumber(keysArray[i]) && (
                  <View padding="0 5px 0 0">
                    <SmallEthPrice />
                  </View>
                )}
              </View>
            )}
          </>
        )
      })}
    </View>
  )
}

export default TrendingCategoriesTable
