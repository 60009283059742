import React from 'react'
import Cell from './Cell'

const HeaderCell = React.forwardRef((props, ref) => {
  const { children, style, ...rest } = props

  return (
    <Cell ref={ref} style={style} {...rest}>
      {children}
    </Cell>
  )
})

export default HeaderCell
