import styled from '@emotion/styled/macro'
import Space from 'components/Space'
import View from 'components/View'

const LoadingTrendingUserCard = ({
  headerBg,
  contentBorder,
  width,
  windowWidth
}) => {
  return (
    <StyledCard
      width={width}
      height={'25vw'}
      maxHeight={'430px'}
      background={headerBg}
    >
      <View width="100%" height="100%" direction="column">
        <View
          height="13.5%"
          width="100%"
          padding="0px 20px"
          alignItems="center"
        />
        <StyledCardContent border={contentBorder}>
          <ContentContainer>
            <View
              width="100px"
              height="100px"
              animation="loading 1s infinite"
              background="linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0.25), rgba(0,0,0,0.2), rgba(0, 0,0,0.25), rgba(0,0,0,0.2))"
              borderRadius="6px"
            />
            <Space size={20} />
            <View
              width={windowWidth < 1350 ? '90%' : '85%'}
              margin="0 auto"
              alignItems="center"
              justify="center"
            >
              <View
                width="70%"
                height="20px"
                animation="loading 1s infinite"
                background="linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0.25), rgba(0,0,0,0.2), rgba(0, 0,0,0.25), rgba(0,0,0,0.2))"
                borderRadius="6px"
              />
            </View>
          </ContentContainer>
          <SubtitleContainer
            direction={windowWidth < 1400 ? 'column' : 'row'}
            justify={windowWidth < 1400 ? 'center' : 'space-between'}
            gap="0.3rem"
          >
            <View
              width="70px"
              height="20px"
              animation="loading 1s infinite"
              background="linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0.25), rgba(0,0,0,0.2), rgba(0, 0,0,0.25), rgba(0,0,0,0.2))"
              borderRadius="6px"
            />
            <View
              width="70px"
              height="20px"
              animation="loading 1s infinite"
              background="linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0.25), rgba(0,0,0,0.2), rgba(0, 0,0,0.25), rgba(0,0,0,0.2))"
              borderRadius="6px"
            />
          </SubtitleContainer>
        </StyledCardContent>
      </View>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  width: ${props => props.width || '310px'};
  height: ${props => props.height || '430px'};
  max-height: ${props => props.maxHeight};
  // overflow: hidden;
  border-radius: 10px;
  background-color: ${props =>
    props.background ? props.background : '#212121'};
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  flex-direction: column;
  overflow: visible;
  border-radius: 10px;
  border-radius-bottom-left: 15px;
  border-radius-bottom-right: 15px;
  height: 80%;
`

const StyledCardContent = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 20% 0 0 0;
  width: 100%;
  height: 82.5%;
  background-color: #fff;
  flex: 1;
  border-radius: 10px;
  border: ${props => props.border};
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transform: ${props =>
    props.isActive ? 'translateY(-60px)' : 'translateY(0)'};
`

const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  gap: ${props => props.gap || '0'};
  padding: 14% 12.5%;
  justify-content: ${props => props.justify};
  align-items: center;
  width: 100%;
  opacity: ${props => (props.isActive ? '0' : '1')};
  height: ${props => (props.isActive ? '0%' : '100%')}
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
`

export default LoadingTrendingUserCard
