import { useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    // return () => {
    //   // Unbind the event listener on clean up
    //   document.removeEventListener('mousedown', handleClickOutside)
    // }
  }, [ref])
}

export default useOutsideAlerter
