import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled/macro'
import View from '../View'
import KodexIcon from '../Icon/KodexIcon'
import { ReactComponent as ArrowBottom } from 'assets/arrow-down.svg'
import Space from '../Space'
import { ReactComponent as Done } from 'assets/done.svg'

export const AccordionRadio = ({
  darkMode,
  title,
  content,
  onChange,
  nameTypeProps,
  value
}) => {
  const [contentExpanded, setContentExpanded] = useState(false)

  const contentContainerRef = useRef()
  const contentWrapperRef = useRef()
  const accordionRef = useRef()

  useEffect(() => {
    if (contentExpanded)
      setTimeout(
        () =>
          accordionRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'center'
          }),
        200
      )
  }, [contentExpanded])

  const onTitlePressed = useCallback(() => {
    if (contentExpanded) {
      contentContainerRef.current.style.maxHeight = 0
    } else {
      contentContainerRef.current.style.maxHeight = `${
        contentWrapperRef.current.clientHeight
      }px`
    }

    setContentExpanded(!contentExpanded)
  }, [setContentExpanded, contentExpanded])

  // console.log(nameTypeProps)

  return (
    <AccordionComponent
      borderColor={darkMode ? '#4A4A4D' : '#E1E1E8'}
      ref={accordionRef}
    >
      <div style={{ cursor: 'pointer', width: '100%' }}>
        <View
          alignItems="center"
          justify="space-between"
          width="100%"
          padding="22px 20px 22px 30px"
          onClick={onTitlePressed}
        >
          <Title style={{ color: darkMode ? '#fff' : '#020202' }}>
            {title}
          </Title>
          <IconWrapper rotate={contentExpanded ? 'rotate(180deg)' : ''}>
            <KodexIcon
              icon={<ArrowBottom />}
              stroke={darkMode ? '#fff' : '#020202'}
              size={20}
              padding={0}
            />
          </IconWrapper>
        </View>
      </div>
      <ContentContainer ref={contentContainerRef}>
        <div ref={contentWrapperRef}>
          <Space size={10} />
          {nameTypeProps?.content &&
            nameTypeProps.content.map((item, idx) => (
              <ContentItem
                key={`second_${idx}`}
                onClick={() => nameTypeProps.onSecondSectionChanged(item.value)}
                label={item.label}
                selected={nameTypeProps.secondSectionValue?.includes(
                  item.value
                )}
                darkMode={darkMode}
                bottomLine={idx === nameTypeProps.content.length - 1}
                multiple
              />
            ))}
          {content.map((item, idx) => (
            <ContentItem
              key={idx}
              onClick={() => onChange(item.value)}
              label={item.label}
              selected={item.value === value}
              darkMode={darkMode}
            />
          ))}
        </div>
      </ContentContainer>
    </AccordionComponent>
  )
}

export const AccordionMultipleSelect = ({
  title,
  secondSection,
  onSecondSectionChanged,
  secondSectionValue,
  darkMode
}) => {
  const [contentExpanded, setContentExpanded] = useState(false)

  const contentContainerRef = useRef()
  const contentWrapperRef = useRef()
  const accordionRef = useRef()

  useEffect(() => {
    if (contentExpanded)
      setTimeout(
        () =>
          accordionRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'center'
          }),
        200
      )
  }, [contentExpanded])

  const onTitlePressed = useCallback(() => {
    if (contentExpanded) {
      contentContainerRef.current.style.maxHeight = 0
    } else {
      contentContainerRef.current.style.maxHeight = `${
        contentWrapperRef.current.clientHeight
      }px`
    }

    setContentExpanded(!contentExpanded)
  }, [setContentExpanded, contentExpanded])

  return (
    <AccordionComponent
      ref={accordionRef}
      borderColor={darkMode ? '#4A4A4D' : '#E1E1E8'}
    >
      <div style={{ cursor: 'pointer', width: '100%' }}>
        <View
          alignItems="center"
          justify="space-between"
          width="100%"
          padding="22px 20px 22px 30px"
          onClick={onTitlePressed}
        >
          <Title style={{ color: darkMode ? '#fff' : '#020202' }}>
            {title}
          </Title>
          <IconWrapper rotate={contentExpanded ? 'rotate(180deg)' : ''}>
            <KodexIcon
              icon={<ArrowBottom />}
              size={20}
              padding={0}
              stroke={darkMode ? '#fff' : '#020202'}
            />
          </IconWrapper>
        </View>
      </div>
      <ContentContainer ref={contentContainerRef}>
        <div ref={contentWrapperRef}>
          <Space size={10} />
          {secondSection.map((item, i) => (
            <ContentItem
              key={`second_${i}`}
              onClick={() => onSecondSectionChanged(item.value)}
              label={item.label}
              selected={secondSectionValue?.includes(item.value)}
              darkMode={darkMode}
              multiple
            />
          ))}
        </div>
      </ContentContainer>
    </AccordionComponent>
  )
}

const ContentItem = ({
  label,
  selected,
  multiple,
  onClick,
  darkMode,
  bottomLine
}) => {
  return (
    <div
      style={{
        cursor: 'pointer',
        padding: '22px 0 22px 0',
        margin: '0 20px 0 30px',
        borderBottom: bottomLine
          ? darkMode
            ? '1px solid #4A4A4D'
            : '1px solid #E1E1E8'
          : ''
      }}
      onClick={onClick}
    >
      <View alignItems="center" justify="space-between" width="100%">
        <div style={{ color: darkMode ? '#fff' : '#020202' }}>{label}</div>

        <div
          style={{
            padding: multiple ? '5px' : '7px',
            border: `1px solid ${darkMode ? '#4A4A4D' : '#F3F3FA'}`,
            borderRadius: '4px'
          }}
        >
          {multiple ? (
            <KodexIcon
              icon={selected ? <Done /> : <div />}
              size={10}
              padding={0}
              stroke={darkMode ? '#fff' : '#020202'}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '6px',
                height: '6px',
                borderRadius: '6px',
                background: selected
                  ? darkMode
                    ? '#fff'
                    : 'black'
                  : 'transparent'
              }}
            />
          )}
        </div>
      </View>
    </div>
  )
}

const AccordionComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid
    ${props => (props.borderColor ? props.borderColor : '#ebebf2')};
  border-radius: 10px;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  width: 100%;
`

const Title = styled.div`
  font-weight: 600;
`

const ContentContainer = styled.div`
  max-height: 0;
  transition: all 0.3s ease;
  width: 100%;
  overflow: hidden;
`

const IconWrapper = styled.div`
  transform: ${props => props.rotate};
  transition: transform 0.3s ease;
`
