import DeafultAvatarImg from 'assets/icon-pfp-default.png'
import { ReactComponent as InstagramImg } from 'assets/instagram.svg'
import { ReactComponent as Twitter } from 'assets/twitter.svg'
import { ReactComponent as WebUrlImg } from 'assets/weblink.svg'
import Avatar from 'components/Avatar'
import Label from 'components/Label'
import View from 'components/View'
import { useHistory } from 'react-router'

const LikeAccountsList = ({ likeAccounts }) => {
  const history = useHistory()

  const goToProfileHandler = account => {
    const accountAddress = account.user.slice(2, account.user.length)
    history.push(`/profile/${accountAddress}`)
  }

  return (
    <View
      direction="column"
      background="#fff"
      width="49vw"
      height="99vh"
      maxHeight="99vh"
      overflow="hidden scroll"
      borderRadius="10px"
      margin="0 0 1rem 0"
      padding="5rem 3rem 2rem 3rem"
      gap="5rem"
    >
      <View>
        <Label size={45} color="#000">
          {(likeAccounts?.length === 1
            ? '1 Like'
            : likeAccounts?.length + ' Likes') || '0 Likes'}
        </Label>
      </View>
      <View direction="column" gap="2rem">
        {likeAccounts?.map(account => (
          <View
            key={account?.user}
            direction="row"
            alignItems="center"
            justify="space-between"
            width="100%"
          >
            <View direction="row" alignItems="center" gap="2vw">
              <View onClick={() => goToProfileHandler(account)}>
                <Avatar
                  src={account?.picture || DeafultAvatarImg}
                  borderRadius="6px"
                  width={70}
                  maxWidth="70px"
                  background={'#fff'}
                />
              </View>
              <View
                cursor="pointer"
                width={
                  account.twitter || account.instagram || account.weblink
                    ? '25vw'
                    : '35vw'
                }
                onClick={() => goToProfileHandler(account)}
              >
                <Label size={30} color="#000" hoverColor="#666">
                  {account.username || account.user}
                </Label>
              </View>
            </View>
            <View direction="row" gap="1rem" alignItems="end">
              {account.twitter?.length > 0 && (
                <View>
                  <a
                    href={`https://twitter.com/${account.twitter}`}
                    target="_blank"
                  >
                    <Twitter fontSize="2rem" />
                  </a>
                </View>
              )}
              {account.instagram && (
                <View>
                  <a
                    href={`https://instagram.com/${account.instagram}`}
                    target="_blank"
                  >
                    <InstagramImg color="#000" size="20" />
                  </a>
                </View>
              )}
              {account.weblink && (
                <View>
                  <a href={`https://${account.weblink}`} target="blank">
                    <WebUrlImg color="#000" size="20" />
                  </a>
                </View>
              )}
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

export default LikeAccountsList
