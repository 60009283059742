import React, { useEffect, useMemo, useRef, useState } from 'react'
import View from 'components/View'
import KodexIcon from 'components/Icon/KodexIcon'
import { ReactComponent as ArrowBottom } from 'assets/arrow-down.svg'
import styled from '@emotion/styled/macro'

const RequestSelect = ({ data, selectedItem, onChange }) => {
  const [contentExpanded, setContentExpanded] = useState(false)

  const contentContainerRef = useRef()
  const contentWrapperRef = useRef()

  const sortedContent = useMemo(() => {
    const contentCopy = [...data]
    return contentCopy.sort((a, _) => (a.value === selectedItem.value ? -1 : 1))
  }, [selectedItem])

  const onItemPressed = item => {
    if (contentExpanded) {
      onChange(item)
    }
    setContentExpanded(!contentExpanded)
  }

  useEffect(() => {
    if (contentExpanded) {
      contentContainerRef.current.style.maxHeight = `${
        contentWrapperRef.current.clientHeight
      }px`
    } else {
      contentContainerRef.current.style.maxHeight = '60px'
    }
  }, [contentExpanded])

  return (
    <AccordionComponent>
      <ContentContainer ref={contentContainerRef}>
        <ContentWrapper ref={contentWrapperRef}>
          {sortedContent.map((item, idx) => (
            <ContentItem
              key={idx}
              onClick={_ => onItemPressed(item)}
              label={item.label}
              selected={item.value === selectedItem.value}
              contentExpanded={contentExpanded}
            />
          ))}
        </ContentWrapper>
      </ContentContainer>
    </AccordionComponent>
  )
}

const ContentItem = ({ label, selected, contentExpanded, onClick }) => {
  return (
    <ContentItemComponent onClick={onClick} contentExpanded={contentExpanded}>
      <View
        alignItems="center"
        justify="space-between"
        width="100%"
        padding="20px 20px 20px 30px"
      >
        <div>{label}</div>
        <IconWrapper open={!contentExpanded && selected}>
          <KodexIcon icon={<ArrowBottom />} size={20} padding={0} />
        </IconWrapper>
      </View>
    </ContentItemComponent>
  )
}

const AccordionComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  line-height: 1;
  position: relative;
  min-height: 60px;
`

const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  max-height: 60px;
  border: 1px solid #e1e1e8;
  border-radius: 10px;
  transition: all 0.3s ease;
  width: 100%;
  overflow: hidden;
`

const ContentWrapper = styled.div`
  width: 100%;
`

const IconWrapper = styled.div`
  opacity: ${props => (props.open ? 1 : 0)};
  transform: ${props => (props.open ? 'rotateZ(0)' : 'rotateZ(180deg)')};
  transition: all 0.3s ease;
`

const ContentItemComponent = styled.div`
  cursor: pointer;
  background-color: #fff;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => (props.contentExpanded ? '#f3f3fa' : '#fff')};
  }
`

export default RequestSelect
