import { useLazyQuery } from '@apollo/client'
import {
  GET_FEED_AGGREGATION,
  kodexApiContext
} from 'service/graphql/kodex-api'
import { useCallback, useMemo } from 'react'
import { utils } from 'ethers'

export default (timestampRange, duration) => {
  const [fetchChartData, { data }] = useLazyQuery(GET_FEED_AGGREGATION, {
    pollInterval: 10000,
    ...kodexApiContext
  })

  const fetchChartDataCallback = useCallback(
    vars => {
      fetchChartData({
        variables: {
          ...vars,
          min_timestamp: timestampRange.min_timestampISO,
          max_timestamp: timestampRange.max_timestampISO,
          search_asset: vars.asset
        }
      })
    },
    [timestampRange]
  )

  const feedChartData = useMemo(() => {
    const fetchedData = data?.get_feed_agg
    if (fetchedData == null || fetchedData.length === 0) {
      return []
    }

    const createTimestamp = index => {
      const toDate = new Date(timestampRange.max_timestamp)
      toDate.setHours(
        toDate.getHours() -
          (duration.max_epoch_part - 1 - index) * duration.labelRawHours
      )
      const fromDate = new Date(toDate)
      fromDate.setHours(fromDate.getHours() - duration.labelRawHours)
      return {
        to: toDate,
        from: fromDate,
        durationLabel: duration.label
      }
    }

    const convertFromWei = number => {
      return Number(utils.formatEther(BigInt(number)))
    }

    const mappedFetchedData = [...Array(duration.max_epoch_part).keys()].map(
      (value, index) => {
        return {
          timestamp: createTimestamp(value),
          avgPrice: 0,
          volumePrice: 0,
          count: 0,
          epoch_part: value
        }
      }
    )

    fetchedData.forEach(value => {
      // TODO: temporary fix backend, has wrong date constraint
      // Backend returns epoch part for the future. Is needed to cut it off.
      if (value.epoch_part >= duration.max_epoch_part) {
        return
      }
      mappedFetchedData[value.epoch_part] = {
        ...mappedFetchedData[value.epoch_part],
        avgPrice: convertFromWei(value.price_avg),
        volumePrice: convertFromWei(value.price_sum),
        count: value.price_cnt,
        epoch_part: value.epoch_part
      }
    })

    return mappedFetchedData
  }, [data])

  return {
    fetchChartData: fetchChartDataCallback,
    chartData: feedChartData
  }
}
