import styled from '@emotion/styled/macro'

const Overlay = styled.div`
  background-color: ${props =>
    props.isInner ? 'transparent' : 'rgba(1, 1, 1, 0.8)'};
  transition: opacity 0.45s ease;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  opacity: ${props => (props.open ? 1 : 0)};
  z-index: ${props => props.zIndex};
`

export default Overlay
