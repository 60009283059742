import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash/function'
import gsap from 'gsap'
import moment from 'moment'
import View from 'components/View'
import Label from 'components/Label'
import Space from 'components/Space'
import SearchBar from './Searchbar'
import ImagesSequence from './ImagesSequence'
import { GET_RANKED_DOMAINS, kodexApiContext } from 'service/graphql/kodex-api'
import { useMediaMax } from 'mediaQuery'
import { useHistory } from 'react-router'
import { MAX_INT } from '@opensea/seaport-js/lib/constants'

const HeaderSection = () => {
  const history = useHistory()
  const browserHistory = useHistory()
  const smallBP = useMediaMax('small')

  const [searchTxt, setSearchTxt] = useState('')
  const [labelSize, setLabelSize] = useState(18)
  const [labelWidth, setLabelWidth] = useState('600px')
  const [wrapperStyle, setWrapperStyle] = useState({
    justify: 'flex-start',
    direction: 'column-reverse'
  })
  const [spaceSize, setSpaceSize] = useState(30)

  useEffect(() => {
    gsap.set('.js-header-section__bg', { scale: 0.9 })
    gsap.to('.js-header-section__bg', {
      scrollTrigger: {
        scrub: true,
        trigger: '.js-header-section__bg',
        start: '-=700',
        end: '-=500'
      },
      scale: 1,
      ease: 'none'
    })

    gsap.set('.js-header-section__separator', { height: 0 })
    gsap.to('.js-header-section__separator', {
      scrollTrigger: {
        scrub: true,
        trigger: '.js-header-section__separator',
        start: '-=1300',
        end: '-=400'
      },
      height: '100%',
      ease: 'none'
    })

    gsap.set('.js-header-section__canvas-container canvas', { y: 0 })
    gsap.to('.js-header-section__canvas-container canvas', {
      scrollTrigger: {
        scrub: true,
        trigger: '.js-header-section__canvas-container canvas',
        start: 'top center',
        end: '+=1000'
      },
      y: '600px',
      ease: 'none'
    })
  }, [])

  useEffect(() => {
    if (smallBP) {
      setLabelSize(16)
      setLabelWidth('325px')
      setWrapperStyle({
        justify: 'center',
        direction: 'column',
        padding: '0 24px'
      })
      setSpaceSize(40)
    } else {
      setLabelSize(18)
      setLabelWidth('600px')
      setWrapperStyle({
        justify: 'flex-start',
        direction: 'column-reverse',
        padding: '0px 70px'
      })
      setSpaceSize(30)
    }
  }, [smallBP])

  const [searchDomain] = useLazyQuery(GET_RANKED_DOMAINS, {
    variables: {
      limit: 1
    },
    ...kodexApiContext,
    fetchPolicy: 'no-cache'
  })

  const debounceSearch = useMemo(() => {
    return debounce(text => {
      if (text.length > 2) {
        const domainName = `${text}.eth`

        searchDomain({
          variables: {
            where: {
              domain: {
                _eq: domainName
              }
            }
          },
          onCompleted: data => {
            const result = data?.domains?.[0]
            if (!result) {
              browserHistory.push(`/registry?search=${text}`)
            }

            const expires = (parseInt(result.expire_time) || 0) * 1000
            const registered =
              expires &&
              moment()
                .subtract(90, 'days')
                .isBefore(expires)

            if (registered) {
              browserHistory.push(`/marketplace?search=${text}`)
            } else {
              browserHistory.push(`/registry?search=${text}`)
            }
          }
        })
      }
    }, MAX_INT)
  }, [])

  useEffect(() => {
    return () => {
      debounceSearch.cancel()
    }
  }, [])

  const handleKeyDownSearchTxt = useCallback(
    e => {
      if ((e.key !== 'Enter' && e !== 'search') || searchTxt.length < 3) {
        return
      }
      const domainName = `${searchTxt}.eth`
      searchDomain({
        variables: {
          where: {
            domain: {
              _eq: domainName
            }
          }
        },
        onCompleted: data => {
          const result = data?.domains?.[0]
          if (!result) {
            history.push(`/registry`, 300, { search: searchTxt })
          }

          const expires = (parseInt(result?.expire_time) || 0) * 1000
          const registered =
            expires &&
            moment()
              .subtract(90, 'days')
              .isBefore(expires)

          if (registered) {
            history.push(`/marketplace?search=${searchTxt}`)
          } else {
            history.push(`/registry?search=${searchTxt}`, 300, {
              search: searchTxt
            })
          }
        }
      })
    },
    [searchTxt]
  )

  return (
    <View background="#0698A8" width="100%">
      <View direction="column" width="100%">
        <View width="100%" height="460px" {...wrapperStyle}>
          <SearchBar
            value={searchTxt}
            onChange={setSearchTxt}
            searchCallback={debounceSearch}
            onKeyDown={handleKeyDownSearchTxt}
          />
          <Space size={spaceSize} />
          <Label
            size={labelSize}
            color="#fff"
            weight="400"
            opacity={0.5}
            width={labelWidth}
            lineHeight="25.2px"
            data-in="stagger-words"
          >
            Start by typing something
          </Label>
        </View>
        <View
          width="100%"
          flex={1}
          id="hero"
          direction="column"
          padding="70px 0px 0px 0px"
          overflow="hidden"
        >
          <View
            flex={1}
            background="#fff"
            width="100%"
            justify="center"
            alignItems="flex-start"
            willChange="transform"
            overflow="hidden"
            className="js-header-section__bg"
            borderTopLeftRadius="6px"
            borderTopRightRadius="6px"
          >
            <View
              direction="column"
              justify="flex-start"
              alignItems="center"
              width="100%"
              height="1000px"
              className="js-header-section__canvas-container"
            >
              <ImagesSequence />

              {/* separator color */}
              <View
                background="#AF61AB"
                position="absolute"
                bottom="0"
                height="100%"
                width="100%"
                borderTopRightRadius="6px"
                borderTopLeftRadius="6px"
                willChange="height"
                className="js-header-section__separator"
                zIndex="-1"
                transform="translate3d(0,0,0)"
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default HeaderSection
