import styled from '@emotion/styled'

const SocialLinkIcon = styled.div`
  position: absolute;
  height: 60px;
  width: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.borderColor};
  border-radius: 10px;
  top: ${props => props.top};
  left: ${props => props.left};
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

export default SocialLinkIcon
