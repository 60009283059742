import { useQuery } from '@apollo/client'
import {
  GET_ENS_DOMAIN_OWNER,
  kodexApiContext
} from 'service/graphql/kodex-api'
import { useState } from 'react'

export default domainLabel => {
  const [ownerAddress, setOwnerAddress] = useState('')

  useQuery(GET_ENS_DOMAIN_OWNER, {
    variables: { label: domainLabel },
    ...kodexApiContext,
    onCompleted: data => {
      setOwnerAddress(data.ens_.registrations[0]?.registrant.id)
    },
    skip: !domainLabel
  })

  return { ownerAddress }
}
