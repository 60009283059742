import View from 'components/View'
import {
  topBarMarketplaceStyle,
  topBarRegistryStyle,
  useTopBarStyle
} from 'contexts/TopBarStyleProvider'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useEffect } from 'react'
import {
  DomainCategories,
  HeaderSection,
  News,
  NotableAccounts,
  NotableListings
} from './components'

const Categories = () => {
  const { setStyle } = useTopBarStyle()

  useEffect(() => {
    let navBarChangeColorTrigger
    // timeout to let slider init.
    setTimeout(() => {
      // get pinned boxes and box width
      const pinBoxes = document.querySelectorAll('.pin-box')
      let pinWrapWidth = 0

      pinBoxes.forEach(element => {
        pinWrapWidth += element.offsetWidth
      })

      // screen width 100vw equivalent
      let windowWidth = window.innerWidth

      // get pin box wrapper and calc width based on window width X number of boxes
      const pinWrap = document.querySelector('.pin-wrap')

      let horizontalScrollLength = pinWrapWidth - windowWidth

      const firstHorizontalSectionWidth = pinBoxes[0].offsetWidth
      const secondHorizontalSectionWidth = pinBoxes[1].offsetWidth

      // give pin wrap a set width
      pinWrap.style.width = pinWrapWidth + 'px'

      gsap.set('.pin-wrap', { x: 0 })
      const horizontalPin = gsap.to('.pin-wrap', {
        scrollTrigger: {
          scrub: true,
          trigger: '#sectionPin',
          pin: true,
          start: 'top top',
          end: () => '+=' + pinWrapWidth,
          invalidateOnRefresh: true
        },
        x: () => -horizontalScrollLength,
        ease: 'none'
      })

      navBarChangeColorTrigger = ScrollTrigger.create({
        trigger: '#sectionPin',
        start: 'top top+=93',
        scrub: true,
        onEnter: () => {
          // console.log('white')
          setStyle({ ...topBarMarketplaceStyle, topBarBgColor: '#fff' })
        },
        onLeaveBack: () => {
          // console.log('black')
          setStyle({ ...topBarRegistryStyle, topBarBgColor: '#020202' })
        }
      })

      gsap.to('.js-accounts__title', {
        scrollTrigger: {
          scrub: true,
          trigger: '.js-notable-accounts',
          start: 'left left',
          end: 'right right',
          invalidateOnRefresh: true,
          containerAnimation: horizontalPin
        },
        x: () => firstHorizontalSectionWidth - windowWidth,
        ease: 'none'
      })

      gsap.to('.js-news__title', {
        scrollTrigger: {
          scrub: true,
          trigger: '.js-news',
          start: 'left left',
          end: 'right right',
          invalidateOnRefresh: true,
          containerAnimation: horizontalPin
        },
        x: () => secondHorizontalSectionWidth - windowWidth,
        ease: 'none'
      })

      window.addEventListener('resize', function() {
        pinWrapWidth = 0
        pinBoxes.forEach(element => {
          pinWrapWidth += element.offsetWidth
        })
        windowWidth = window.innerWidth
        horizontalScrollLength = pinWrapWidth - windowWidth
      })
    }, 1000)
    return () => {
      if (navBarChangeColorTrigger) {
        navBarChangeColorTrigger.kill()
      }
    }
  }, [])
  return (
    <div style={{ width: '100%' }}>
      <HeaderSection />
      <DomainCategories />
      <NotableListings />

      <div
        style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}
        id="sectionPin"
      >
        <View
          alignItems="center"
          width="100%"
          height="100vh"
          className="pin-wrap"
        >
          <NotableAccounts />
          <News />
        </View>
      </div>
    </div>
  )
}

export default Categories
