import React, { useEffect, useState } from 'react'
import Label from '../../../components/Label'
import { useMediaMax } from '../../../mediaQuery'

const SectionHeader = ({ text, color }) => {
  const smallBP = useMediaMax('small')
  const [labelSize, setLabelSize] = useState(110)

  useEffect(() => {
    if (smallBP) {
      setLabelSize(40)
    } else {
      setLabelSize(110)
    }
  })
  return (
    <Label size={labelSize} color={color} data-in="stagger-chars">
      {text}
    </Label>
  )
}

export default SectionHeader
