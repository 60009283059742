import PfpRed from 'assets/pfp-red.svg'
import PfpPurple from 'assets/pfp-purple.svg'
import PfpGreen from 'assets/pfp-green.svg'
import PfpGray from 'assets/pfp-gray.svg'
import PfpYellow from 'assets/pfp-yellow.svg'
import PfpPink from 'assets/pfp-pink.svg'

export const defaultPfps = [
  PfpRed,
  PfpGray,
  PfpGreen,
  PfpPink,
  PfpPurple,
  PfpYellow
]
