import { useState, useRef, useEffect } from 'react'
import imageCompression from 'browser-image-compression'
import RoundedButton from 'components/Forms/RoundedButton'
import styled from '@emotion/styled/macro'
import Label from 'components/Label'

const Uploader = ({ name, placeholder, image, height, onUpload }) => {
  const fileInputField = useRef(null)
  const [currentImg, setCurrentImg] = useState()

  useEffect(() => {
    if (image) {
      setCurrentImg(image)
    }
  }, [image])

  const handleNewFileUpload = async e => {
    if (e.target.files.length) {
      const imageFile = e.target.files[0]

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(imageFile, options)
        onUpload(compressedFile)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleImageClick = () => {
    if (fileInputField.current) {
      fileInputField.current.click()
    }
  }

  return (
    <>
      <RoundedButton
        onClick={handleImageClick}
        width="100%"
        height={height ? height : '160px'}
        background="#F3F3FA"
        style={{ overflow: 'hidden' }}
      >
        {currentImg ? (
          <StyledImage src={currentImg} alt="img" />
        ) : (
          <>{placeholder}</>
        )}
        <input
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          style={{ display: 'none' }}
        />
      </RoundedButton>
      <Label size={13} color="#BCBCCC" align="center">
        {name}
      </Label>
    </>
  )
}

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export default Uploader
