import React, { useEffect, useState } from 'react'
import View from 'components/View'
import styled from '@emotion/styled/macro'
import { useMediaMax } from 'mediaQuery'
import { ens_normalize } from '@adraffy/ens-normalize'
import useCaretPosition from 'hooks/useCaretPosition'
import Caret from 'components/Caret'

const Searchbar = ({ value, onChange, searchCallback, onKeyDown }) => {
  const [searchTxtWidth, setSearchTxtWidth] = useState(0)
  const [placeholder, setPlaceholder] = useState('')
  const [placeholderSize, setPlaceholderSize] = useState('110px')
  const [isFocused, setIsFocused] = useState(true)
  const smallBP = useMediaMax('small')
  let searchTimeout

  const {
    inputRef,
    inputStringLengthRef,
    caretLeftPosition
  } = useCaretPosition(value, 0, 10)

  const handleChangeSearchTxt = e => {
    const inputText = e.target.value.trim().toLowerCase()
    if (inputText === '') {
      onChange('')
      return
    }

    clearTimeout(searchTimeout)

    const inputNormalized = ens_normalize(inputText)

    if (!inputNormalized) return

    onChange(inputNormalized)

    if (inputText.length >= 3) {
      searchCallback(inputNormalized)
    }

    const TestEl = document.getElementById('test')
    TestEl.innerText = inputText
    const windowsWidth = window.innerWidth
    const maxWidth = windowsWidth - 290
    setSearchTxtWidth(
      TestEl.clientWidth < maxWidth ? TestEl.clientWidth + 13 : maxWidth + 13
    )
  }

  useEffect(() => {
    if (smallBP) {
      setPlaceholder('Search')
      setPlaceholderSize('40px')
    } else {
      setPlaceholder('Find a unique ENS domain')
      setPlaceholderSize('105px')
    }
  }, [smallBP])

  return (
    <View width="100%">
      <SearchBarComponent
        ref={inputRef}
        autoFocus
        placeholder={placeholder}
        fontSize={placeholderSize}
        onChange={handleChangeSearchTxt}
        onKeyDown={e => {
          if (e.which == 37 || e.which == 39) {
            e.preventDefault()
            return
          }
          clearTimeout(searchTimeout)
          if (e.key === 'Enter') {
            onKeyDown(e)
            return
          }
        }}
        onKeyUp={() => {
          clearTimeout(searchTimeout)
          searchTimeout = setTimeout(() => {
            onKeyDown('search')
          }, 1200)
        }}
        value={value}
        spellCheck="false"
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        onClick={e => {
          e.target.setSelectionRange(value.length, value.length)
        }}
        // TODO: Check and impl InViewAnimcation if neccessary
        // data-in="view"
      />
      <Caret
        focused={isFocused}
        left={caretLeftPosition}
        width="6px"
        height="77px"
        top="54%"
        transform="translate(-50%, -50%)"
      />
      <HiddenParagraph ref={inputStringLengthRef} id="test" />
      {value && <Suffix positionLeft={searchTxtWidth}>.eth</Suffix>}
    </View>
  )
}

const SearchBarComponent = styled.input`
  font-size: ${props => (props.fontSize ? props.fontSize : '110px')};
  font-family: 'Satoshi', sans-serif;
  color: #fff;
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  caret-color: transparent;
  resize: both;

  &[data-in] {
    transform: translateY(60%) rotate(5deg);
    opacity: 0;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &.in-view {
    transform: translateY(0) rotate(0);
    opacity: 1;
  }

  ::placeholder {
    color: #fff;
    opacity: 0.5; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
    opacity: 0.5;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
    opacity: 0.5;
  }
`

const HiddenParagraph = styled.p`
  position: absolute;
  z-index: -1;
  font-size: 105px;
  font-family: 'Satoshi', sans-serif;
  top: 0;
  left: 0;
`

const Suffix = styled.span`
  position: absolute;
  opacity: 0.5;
  color: #fff;
  font-size: 105px;
  font-family: 'Satoshi', sans-serif;
  top: 10px;
  left: ${props => props.positionLeft || 0}px;
  pointer-events: none;
`

export default Searchbar
