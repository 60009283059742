import React, { forwardRef, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import Label from 'components/Label'
import View from 'components/View'
import gsap from 'gsap'
import { useWindowListener } from 'common/useWindowListener'

const SimilarSection = forwardRef((props, pageRef) => {
  const {
    isRegistered = true,
    data,
    LastItemComponent,
    itemsRenderer,
    title,
    gap,
    firstItemPositionCoefficient
  } = props

  const cardsSliderRef = useRef(null)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useWindowListener(
    'resize',
    () => {
      setWindowHeight(window.innerHeight)
    },
    []
  )

  return (
    <SimilarSectionComponent isRegistered={isRegistered}>
      <View
        ref={cardsSliderRef}
        gap={gap}
        justify="flex-start"
        transition="none"
      >
        {data.map((dataItem, index) => itemsRenderer(dataItem, index))}
        {LastItemComponent}
      </View>
      <LabelWrapper>
        <Label
          style={{
            fontSize:
              windowHeight < 600 ? '10vh' : windowHeight < 630 ? '14vh' : '16vh'
          }}
          color={isRegistered ? '#020202' : '#fff'}
        >
          {title}
        </Label>
      </LabelWrapper>
    </SimilarSectionComponent>
  )
})

const SimilarSectionComponent = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props => (props.isRegistered ? '#f3f3fa' : '#262628')};
  padding-bottom: 60px;
  position: relative;
`

const LabelWrapper = styled.div`
  position: absolute;
  bottom: 7vh;
  padding-left: 70px;
  left: 0;
`

export default SimilarSection
