import React, { useEffect, useState } from 'react'
import View from 'components/View'
import NotableAccountCard from 'components/Card/NotableAccountCard'
import styled from '@emotion/styled/macro'
import { useMediaMax } from 'mediaQuery'
import SectionHeader from '../../components/SectionHeader'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import TopUser1 from 'assets/top-users/1.png'
import TopUser2 from 'assets/top-users/2.png'
import TopUser3 from 'assets/top-users/3.jpeg'
import TopUser4 from 'assets/top-users/4.jpg'
import { useHistory } from 'react-router'

const NotableAccounts = () => {
  const smallBP = useMediaMax('small')
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const [sectionDirection, setSectionDirection] = useState('column')
  const [headerPadding, setHeaderPadding] = useState('35px 70px 0')
  const [headerPosition, setHeaderPosition] = useState({
    bottom: '5em',
    left: '70px'
  })
  const [itemAlignItems, setItemAlignItems] = useState('end')
  const history = useHistory()

  useEffect(() => {
    gsap.set('.js-notable-accounts__slider', {
      x: window.innerWidth / 2,
      yPercent: 50
    })
    gsap.to('.js-notable-accounts__slider', {
      scrollTrigger: {
        scrub: true,
        trigger: '.js-notable-accounts',
        start: 'top bottom',
        end: 'top top',
        invalidateOnRefresh: true
      },
      yPercent: 0,
      ease: 'none'
    })
    gsap.to('.js-notable-accounts__slider', {
      scrollTrigger: {
        scrub: true,
        trigger: '.js-notable-accounts',
        start: 'top top',
        end: 'top top-=2000px',
        invalidateOnRefresh: true
      },
      xPercent: 0,
      ease: 'none'
    })

    ScrollTrigger.create({
      trigger: '.js-notable-accounts',
      start: 'top top',
      onEnter: () => {
        setActiveItemIndex(1)
      },
      onLeaveBack: () => {
        setActiveItemIndex(0)
      }
    })

    ScrollTrigger.create({
      trigger: '.js-notable-accounts',
      start: 'top top-=400px',
      onEnter: () => {
        setActiveItemIndex(2)
      },
      onLeaveBack: () => {
        setActiveItemIndex(1)
      }
    })

    ScrollTrigger.create({
      trigger: '.js-notable-accounts',
      start: 'top top-=900px',
      onEnter: () => {
        setActiveItemIndex(3)
      },
      onLeaveBack: () => {
        setActiveItemIndex(2)
      }
    })

    ScrollTrigger.create({
      trigger: '.js-notable-accounts',
      start: 'top top-=1500px',
      onEnter: () => {
        setActiveItemIndex(4)
      },
      onLeaveBack: () => {
        setActiveItemIndex(3)
      }
    })
  }, [])

  useEffect(() => {
    if (smallBP) {
      setSectionDirection('column-reverse')
      setHeaderPadding('0 24px 60px')
      setItemAlignItems('start')
    } else {
      setSectionDirection('column')
      setHeaderPadding('35px 70px 0')
      setItemAlignItems('end')
    }
  }, [smallBP])

  useEffect(() => {
    if (smallBP) {
      // setCardWrapperWidth('365px')
      // setSliderWrapperPadding('20vh 0')
      setHeaderPosition({ top: '140px', left: '24px' })
    } else {
      // setCardWrapperWidth('460px')
      // setSliderWrapperPadding('30vh 0')
      setHeaderPosition({ bottom: '5em', left: '70px' })
    }
  }, [smallBP])

  const seeAllUsers = () => {
    history.push('/marketplace', 60)
  }

  return (
    <View
      minWidth="2500px"
      width="2500px"
      height="100vh"
      direction={sectionDirection}
      justify="center"
      data-in="stagger-slider"
      className="js-notable-accounts pin-box"
      gap="3rem"
    >
      <SliderWrapper className="js-notable-accounts__slider">
        <CardWrapper alignItems={itemAlignItems}>
          <NotableAccountCard
            name="faun"
            title="1k club"
            domains="330"
            followers="22"
            avatar={TopUser1}
            isActive={activeItemIndex === 1}
          />
        </CardWrapper>
        <CardWrapper alignItems={itemAlignItems}>
          <NotableAccountCard
            name="mia.eth"
            title="name lover"
            domains="175"
            followers="16"
            avatar={TopUser2}
            isActive={activeItemIndex === 2}
          />
        </CardWrapper>
        <CardWrapper alignItems={itemAlignItems}>
          <NotableAccountCard
            name="libevm"
            title="ens og"
            domains="52"
            followers="9"
            avatar={TopUser3}
            isActive={activeItemIndex === 3}
          />
        </CardWrapper>
        <CardWrapper alignItems={itemAlignItems}>
          <NotableAccountCard
            name="flow.eth"
            title="dealmaker"
            domains="211"
            followers="45"
            avatar={TopUser4}
            isActive={activeItemIndex === 4}
          />
        </CardWrapper>
      </SliderWrapper>
      <TitleWrapper
        className="js-accounts__title"
        // padding={headerPadding}
        {...headerPosition}
        // width="99vw"
        // justify="space-between"
        // alignItems="end"
        // height="30vh"
        bottom={window.innerHeight < 900 ? '1rem' : undefined}
      >
        <SectionHeader text="Top users" color="#fff" />
        <View
          onClick={() => {
            history.push('/trending?users')
          }}
        >
          <SectionHeader text="All" color="rgba(255, 255, 255, 0.5)" />
        </View>
      </TitleWrapper>
    </View>
  )
}

const CardWrapper = styled.div`
  width: 350px !important;
  height: 500px;
  display: flex !important;
  justify-content: center;
  align-items: ${props => (props.alignItems ? props.alignItems : 'end')};
`

const SliderWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
`

const TitleWrapper = styled.div`
  position: absolute;
  bottom: ${props => (props.bottom ? `${props.bottom}` : '3em')};
  padding-left: ${props => (props.left ? `${props.left}` : '70px')};
  padding-right: ${props => (props.left ? `${props.left}` : '70px')};
  top: ${props => props.top};
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 99vw;
`

export default NotableAccounts
