import { ens_normalize } from '@adraffy/ens-normalize'
import { useLazyQuery } from '@apollo/client'
import Label from 'components/Label'
import Searchbar from 'components/Search'
import View from 'components/View'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { GET_RANKED_DOMAINS, kodexApiContext } from 'service/graphql/kodex-api'

const navItems = [
  { label: 'Marketplace', path: '/marketplace' },
  { label: 'Registry', path: '/registry' },
  { label: 'Docs', path: 'https://docs.kodex.io' }
]

let isSearching = false

const MobileNavContent = ({
  primaryColor,
  secondaryColor,
  background,
  closeNavbar
}) => {
  const [input, setInput] = useState('')
  const [displayedNavItems, setDisplayedNavitems] = useState(navItems)
  const history = useHistory()

  let searchTimeout

  const handleClearSearchBar = () => {
    setDisplayedNavitems(navItems)
    setInput('')
  }

  const [searchDomain] = useLazyQuery(GET_RANKED_DOMAINS, {
    variables: {
      limit: 1
    },
    ...kodexApiContext,
    fetchPolicy: 'no-cache'
  })

  // const debounceSearch = useMemo(() => {
  //   return debounce(text => {
  //     if (text.length > 2) {
  //       const domainName = `${text}.eth`

  //       searchDomain({
  //         variables: {
  //           where: {
  //             domain: {
  //               _eq: domainName
  //             }
  //           }
  //         },
  //         onCompleted: data => {
  //           const result = data?.domains?.[0]
  //           if (!result) {
  //             browserHistory.push(`/registry?search=${text}`)
  //           }

  //           const expires = (parseInt(result.expire_time) || 0) * 1000
  //           const registered =
  //             expires &&
  //             moment()
  //               .subtract(90, 'days')
  //               .isBefore(expires)

  //           if (registered) {
  //             closeNavbar()
  //             history.push(`/marketplace?search=${text}`)
  //           } else {
  //             closeNavbar()
  //             history.push(`/registry?search=${text}`)
  //           }
  //         }
  //       })
  //     }
  //   }, MAX_INT)
  // }, [])

  // useEffect(() => {
  //   return () => {
  //     debounceSearch.cancel()
  //   }
  // }, [])

  const handleKeyDownSearchTxt = useCallback(
    e => {
      if ((e.key !== 'Enter' && e !== 'search') || input.length < 3) {
        return
      }
      clearTimeout(searchTimeout)
      const domainName = `${input}.eth`
      searchDomain({
        variables: {
          where: {
            domain: {
              _eq: domainName
            }
          }
        },
        onCompleted: data => {
          clearTimeout(searchTimeout)
          const result = data?.domains?.[0]
          if (!result) {
            history.push(`/registry`, 300, { search: input })
          }

          const expires = (parseInt(result?.expire_time) || 0) * 1000
          const registered =
            expires &&
            moment()
              .subtract(90, 'days')
              .isBefore(expires)

          if (registered) {
            closeNavbar()
            setInput('')
            history.push(`/marketplace?search=${input}`)
          } else {
            closeNavbar()
            setInput('')
            history.push(`/registry`, 300, { search: input })
          }
        }
      })
    },
    [input]
  )

  const handleKeyDown = e => {
    if (e.which == 37 || e.which == 39) {
      e.preventDefault()
      return
    }
    clearTimeout(searchTimeout)
    if (e.key === 'Enter') {
      isSearching = true
      clearTimeout(searchTimeout)
      handleKeyDownSearchTxt(e)
      return
    }
  }

  const handleKeyUp = () => {
    clearTimeout(searchTimeout)
    if (!isSearching) {
      searchTimeout = setTimeout(() => {
        handleKeyDownSearchTxt('search')
      }, 1500)
    } else {
      isSearching = false
    }
  }

  const onInputChange = value => {
    const inputText = value.trim().toLowerCase()
    if (inputText === '') {
      setInput('')
      return
    }

    clearTimeout(searchTimeout)

    const inputNormalized = ens_normalize(inputText)

    if (!inputNormalized) return

    setInput(inputNormalized)
  }

  return (
    <View padding="0 16px" width="100%" direction="column" gap="10px">
      <Searchbar
        placeholder="Search"
        onChange={onInputChange}
        value={input || ''}
        background={background}
        placeholderColor={secondaryColor}
        color={primaryColor}
        onClear={handleClearSearchBar}
        top="10px"
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
      />
      <View
        direction="column"
        width="100%"
        gap="32px"
        maxHeight="calc(100vh - 200px)"
        overflow="scroll"
        scrollBar={`&::-webkit-scrollbar {
          display: none; /* for Chrome, Safari, and Opera */
        }
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */`}
      >
        {navItems.map(item => (
          <div
            key={item.label}
            onClick={() => {
              closeNavbar()
              setInput('')
              setDisplayedNavitems(navItems)

              if (item.path.includes('http')) {
                window.open(item.path, 'blank', 'noopener,noreferrer')
                return
              }
              history.push(item.path)
            }}
          >
            <Label size={40} color={primaryColor}>
              {item.label}
            </Label>
          </div>
        ))}
      </View>
    </View>
  )
}

export default MobileNavContent
