import React, { createContext, useCallback, useContext, useState } from 'react'

export const FeedAnimationContext = createContext({
  animationAlreadyShown: false,
  setAnimationShowed: () => {}
})

export const FeedAnimationProvider = ({ children }) => {
  const [animationAlreadyShown, setAnimationAlreadyShown] = useState()

  const setAnimationShowed = useCallback(() => {
    setAnimationAlreadyShown(true)
  }, [])

  return (
    <FeedAnimationContext.Provider
      value={{ animationAlreadyShown, setAnimationShowed }}
    >
      {children}
    </FeedAnimationContext.Provider>
  )
}

export function useFeedAnimation() {
  const context = useContext(FeedAnimationContext)
  if (context === undefined) {
    throw new Error(
      'useFeedAnimation must be used within a FeedAnimationProvider'
    )
  }
  return context
}
