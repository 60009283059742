import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg'
import { ReactComponent as Check } from 'assets/check-icon.svg'
import Label from 'components/Label'
import View from 'components/View'

const ACTIVITY_DROPDOWN_ITEMS = [
  'all',
  'sale',
  'purchase',
  'transfer',
  'registration'
]

const ActivityDropdown = ({ open, selectedItem, setItem, setOpen }) => {
  return (
    <View direction="column" zIndex="100">
      <View
        padding="0.5rem"
        cursor="pointer"
        alignItems="center"
        gap="0.5rem"
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
      >
        <Label color="black">{selectedItem}</Label>
        <ArrowDown />
      </View>
      {open && (
        <View
          width={
            selectedItem === ACTIVITY_DROPDOWN_ITEMS[0]
              ? '9rem'
              : selectedItem === ACTIVITY_DROPDOWN_ITEMS[1]
              ? '8rem'
              : 'fit'
          }
          position="absolute"
          top="2.5rem"
          direction="column"
          left="-1.2rem"
          border="1px solid #E1E1E8"
          borderRadius="6px"
          background="#fff"
        >
          {ACTIVITY_DROPDOWN_ITEMS.map(item => (
            <View
              key={item}
              direction="row"
              wrappable={false}
              alignItems="center"
              cursor="pointer"
              gap="1rem"
              width={
                selectedItem === ACTIVITY_DROPDOWN_ITEMS[0] ? '9rem' : '100%'
              }
              padding="1rem"
              hoverBackground="#f3f3fa"
              transition="0.1s ease-in-out"
              onClick={() => {
                setItem(item)
                setOpen(false)
              }}
            >
              {item}
              {selectedItem === item && <Check />}
            </View>
          ))}
        </View>
      )}
    </View>
  )
}

export default ActivityDropdown
