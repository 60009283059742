import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import styled from '@emotion/styled'
import React, { useContext, useState } from 'react'
import RoundedButton from 'components/Forms/RoundedButton'
import { isAddress } from 'utils/address'
import { useMutation } from '@apollo/client'
import { SET_REGISTRANT } from 'service/graphql/mutations'
import FractalBg from 'assets/fractal-completed-modal.png'
import { ModalContext } from 'contexts/ModalProvider'
import { ethers } from 'ethers'
import { useHistory } from 'react-router'

const TransferDialog = ({ domainName, onClose, onReset = () => {} }) => {
  const [addressInput, setAddressInput] = useState('')
  const [addressError, setAddressError] = useState('')
  const [isTransfering, setTransfering] = useState(false)
  const [isTransfered, setIsTransfered] = useState(false)
  const provider = new ethers.providers.StaticJsonRpcProvider(
    process.env.REACT_APP_LLAMA_NODES_URL,
    'homestead'
  )

  const history = useHistory()

  const modalCtx = useContext(ModalContext)

  const [transferOwnership] = useMutation(SET_REGISTRANT, {
    onCompleted: data => {
      setIsTransfered(true)
      setTransfering(false)
      modalCtx.setClosable(true)
      onReset()
    },
    onError: () => {
      setTransfering(false)
      modalCtx.setClosable(true)
    },
    variables: {
      name: domainName,
      address: addressInput
    }
  })

  const resolveENSName = async () => {
    const resolvedAttempt = await provider
      .resolveName(addressInput || '')
      .catch(() => null)

    return resolvedAttempt
  }

  const handleTransfer = async () => {
    if (!isAddress(addressInput)) {
      const resolvedAddress = await resolveENSName()
      if (!isAddress(resolvedAddress)) {
        setAddressError('Invalid address')
        return
      }
      modalCtx.setClosable(false)
      setTransfering(true)
      transferOwnership({
        name: domainName,
        address: resolvedAddress
      })
      return
    }

    modalCtx.setClosable(false)
    setTransfering(true)
    transferOwnership()
  }

  if (isTransfered)
    return (
      <View
        width="720px"
        height="100%"
        margin="0 0 1rem 0"
        direction="column"
        borderRadius="10px"
        background="#0698A8"
        justify="space-between"
        alignItems="center"
        padding="40px"
      >
        <Label size={40}>Transfer successful</Label>
        <img src={FractalBg} />
        <View width="350px" direction="column">
          <RoundedButton
            width="100%"
            height="60px"
            border="1px solid #fff"
            onClick={() => {
              onClose()
              history.push(`/profile/my-profile?activity`)
            }}
          >
            <Label size={16}>View in activity</Label>
          </RoundedButton>
          <Space size={10} />
          <RoundedButton
            width="100%"
            height="60px"
            background="#fff"
            border="1px solid #fff"
            onClick={() => onClose()}
          >
            <Label size={16} color="#020202">
              Go back
            </Label>
          </RoundedButton>
        </View>
      </View>
    )

  return (
    <View width="720px" height="100%" direction="column" padding="0 0 1rem 0">
      <View
        flex={1}
        width="100%"
        direction="column"
        style={{ overflow: 'hidden' }}
        borderRadius="10px"
      >
        <View
          flex={1}
          direction="column"
          background="#fff"
          padding="40px"
          width="100%"
        >
          <Space size={30} />
          <Label size={35} color="#000">
            Transfer
          </Label>
          <Space size={10} />
          <Label size={35} color="#BCBCCC">
            {domainName}
          </Label>
          <Space size={60} />
          <Label size={16} color="#BCBCCC">
            Wallet address
          </Label>
          <Space size={20} />
          <View
            height="70px"
            width="100%"
            borderRadius="10px"
            background="#F3F3FA"
            justify="space-between"
            alignItems="center"
          >
            <StyledInput
              value={addressInput}
              placeholder="Type wallet address"
              onChange={e => {
                setAddressInput(e.target.value)
                setAddressError('')
              }}
            />
          </View>
          <Space size={40} />
          {addressError && (
            <Label size={20} color="#BCBCCC">
              {addressError}
            </Label>
          )}

          <Space size={40} />
          <View
            position="absolute"
            width="100%"
            padding="40px"
            bottom="0px"
            left="0px"
          >
            <View flex={1}>
              <RoundedButton
                width="100%"
                height="60px"
                border="1px solid #E1E1E8"
                hoverBackground="#E1E1E8"
                disabled={addressInput.length === 0 || isTransfering}
                onClick={() => handleTransfer()}
              >
                <Label
                  size={16}
                  color={
                    addressInput.length === 0 || isTransfering
                      ? '#888'
                      : '#020202'
                  }
                >
                  {isTransfering ? 'Transfering...' : 'Transfer'}
                </Label>
              </RoundedButton>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const StyledInput = styled.input`
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0 1rem;
  color: #212121;
  background: transparent;
  width: 100%;
`

export default TransferDialog
