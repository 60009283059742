import React from 'react'
import classNames from 'classnames'

const Row = React.forwardRef((props, ref) => {
  const {
    className,
    style,
    children,
    headerHeight,
    height,
    isHeaderRow,
    hasChildren,
    rowData,
    rowIndex,
    rowKey,
    background = 'transparent',
    ...rest
  } = props

  const classes = classNames(
    'table-row',
    { 'table-row-header': isHeaderRow },
    className
  )

  const styles = {
    height: isHeaderRow ? headerHeight : height,
    padding: 0,
    border: 'none',
    // margin: '5px 0',
    padding: '5px 0',
    background,
    ...style
  }

  if (isHeaderRow) {
    return (
      <div ref={ref} role="row" {...rest} className={classes} style={styles}>
        {children}
      </div>
    )
  }

  const childrenContent = children.map(cell =>
    React.cloneElement(cell, {
      hasChildren,
      rowData,
      height,
      rowIndex,
      rowKey
    })
  )

  return (
    <div ref={ref} role="row" {...rest} className={classes} style={styles}>
      {childrenContent}
    </div>
  )
})

export default Row
