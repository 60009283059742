import { useQuery } from '@apollo/client'
import { ethers } from 'ethers'
import {
  GET_USER_FOLLOWERS_REACTIVE,
  GET_USER_FOLLOWINGS_REACTIVE,
  GET_USER_PROFILE,
  kodexApiContext
} from 'service/graphql/kodex-api'

export default (address = '', options) => {
  const { data, refetch: refetchUser, ...rest } = useQuery(GET_USER_PROFILE, {
    variables: {
      user: address.toLowerCase()
    },
    skip: !address || address === ethers.constants.AddressZero,
    ...kodexApiContext,
    ...options
  })

  const { data: followersData, refetch: refetchFollowers } = useQuery(
    GET_USER_FOLLOWERS_REACTIVE,
    {
      variables: {
        user: address.toLowerCase()
      },
      skip: !address || address === ethers.constants.AddressZero,
      ...kodexApiContext,
      ...options
    }
  )

  const { data: followingsData, refetch: refetchFollowings } = useQuery(
    GET_USER_FOLLOWINGS_REACTIVE,
    {
      variables: {
        user: address.toLowerCase()
      },
      skip: !address || address === ethers.constants.AddressZero,
      ...kodexApiContext,
      ...options
    }
  )

  const refetchAll = () => {
    void refetchUser()
  }

  return {
    profile: data?.profile?.[0] ?? {},
    followers: followersData?.followers?.length || 0,
    followings: followingsData?.followers?.length || 0,
    refetchAll,
    refetch: refetchUser,
    refetchFollowers,
    refetchFollowings,
    ...rest
  }
}
