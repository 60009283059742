import styled from '@emotion/styled'

const Section = styled.section`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background-color: ${props => props.background};
  border-radius: ${props => props.borderRadius};
  display: flex;
  padding: ${props => props.padding};
`

export default Section
