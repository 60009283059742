import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_DOMAINS_LIKES, kodexApiContext } from 'service/graphql/kodex-api'
import { GET_REGISTRATIONS_SUBGRAPH } from 'service/graphql/queries'

export default address => {
  const [data, setData] = useState([])

  const { data: subgraphData } = useQuery(GET_REGISTRATIONS_SUBGRAPH, {
    variables: {
      id: address?.toLowerCase(),
      orderBy: 'registrationDate',
      first: 1000
    },
    skip: !address,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  const { data: likesData } = useQuery(GET_DOMAINS_LIKES, {
    variables: {
      domains: (subgraphData?.account?.registrations || []).map(
        r => `${r.labelName}.eth`
      )
    },
    ...kodexApiContext,
    skip: !subgraphData?.account?.registrations,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache'
  })

  useEffect(() => {
    const registrations = subgraphData?.account?.registrations || []
    const likes = likesData?.likes || []

    setData(
      registrations.map(r => ({
        id: r.id,
        name: `${r.labelName}.eth`,
        amount: r.cost,
        expire_time: r.expiryDate,
        likes: likes.filter(l => l.domain === `${r.labelName}.eth` && l.liked)
          .length
      }))
    )
  }, [subgraphData, likesData])

  return data
}
