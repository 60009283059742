import { ens_normalize } from '@adraffy/ens-normalize'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled/macro'
import { getSigner } from '@ensdomains/ui'
import { ReactComponent as EthPriceGrey } from 'assets/eth-icon-dark.svg'
import { ReactComponent as EthPriceLight } from 'assets/eth-icon.svg'
import { ReactComponent as NameWarning } from 'assets/warning-name.svg'
import { useWindowListener } from 'common/useWindowListener'
import MobileTokenCard from 'components/Card/MobileNetTokenCard'
import { getRegistrationStatus } from 'components/Card/NetTokenCard'
import Date from 'components/DateTime/Date'
import Link from 'components/Forms/Link'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import LoadingSpinner from 'components/LoadingSpinner'
import { useAccount } from 'components/QueryAccount'
import MobileSimilarSection from 'components/SimilarSection/mobile'
import Space from 'components/Space'
import View from 'components/View'
import { TAGS } from 'constants/category'
import { CartStoreContext } from 'contexts/CartStoreProvider'
import {
  TopBarStyleContext,
  topBarMarketplaceStyle,
  topBarRegistryStyle
} from 'contexts/TopBarStyleProvider'
import { BigNumber, ethers } from 'ethers'
import useDomainResolver from 'hooks/useDomainResolver'
import { useEthPrice } from 'hooks/useEthPrice'
import useGetDomainOwner from 'hooks/useGetDomainOwner'
import useSimilarDomains from 'hooks/useSimilarDomains'
import useUserProfile from 'hooks/useUserProfile'
import moment from 'moment'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useLocation } from 'react-router'
import { useHistory, useParams } from 'react-router-dom'
import {
  GET_DOMAIN_ACTIVITY_QUERY,
  GET_LIKES_BY_DOMAIN,
  kodexApiContext
} from 'service/graphql/kodex-api'
import {
  GET_BALANCE,
  GET_LAST_SALE,
  GET_REGISTRATIONS_BY_LABELNAME
} from 'service/graphql/queries'
import { getListing } from 'service/rest/getListing'
import { getOffers } from 'service/rest/getOffers'
import { getPremiumPrice } from 'service/rest/getPremiumPrice'
import { JETTY_URL } from 'service/siwe/constants'
import { siweAuthedFetch } from 'service/siwe/siweFetch'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import { formatETHPrice } from 'utils/format'
import { actions } from 'utils/reservoir'
import {
  ENS_CONTRACT_ADDRESS,
  EXCHANGE_CONTRACT_ADDRESS,
  WETH_TOKEN_ADDRESS,
  getERC20TokenContract
} from 'utils/utils'
import { calculateIfPremium } from 'views/Checkout/components/calculateIfPremium'
import PremiumChart from '../../components/PremiumRegistry/Chart'
import MobileDetails from './MobileDetails'
import MobileActivityAndOffers from './MobleActivityAndOffers'
import MobilePriceHistory from './PriceHistory/mobile'

const MobileRegisteredDomain = () => {
  const { name } = useParams()
  const location = useLocation()
  // const [active, setActive] = useState(false)
  const [listed, setListed] = useState(false)
  const [listedModalOpen, setListedModalOpen] = useState(
    location.search?.replace('?', '')?.includes('listed')
  )
  const [isBuying, setBuying] = useState(false)
  const [domainInCart, setDomainInCart] = useState(false)
  const [offers, setOffers] = useState([])
  const [highestOffer, setHighestOffer] = useState(0)
  const [openOfferDialog, setOpenOfferDialog] = useState(false)
  const [openTransferDialog, setOpenTransferDialog] = useState(false)
  const [domainOrder, setDomainOrder] = useState([])
  const [domainsIsInCartArr, setDomainsIsInCartArr] = useState([])
  const [cancelingListing, setCancelingListing] = useState(undefined)
  const [registrationModalOpen, setRegistrationModalOpen] = useState(false)
  const [domainIsPremium, setDomainIsPremium] = useState(false)
  const [listingCanceled, setListingCanceled] = useState(false)
  const [listingPurchased, setListingPurchased] = useState(false)
  const [cancelingIndex, setCancelingIndex] = useState(undefined)
  const [acceptingIndex, setAcceptingIndex] = useState(undefined)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [registrationPrice, setRegistrationPrice] = useState(0)
  const [premiumRegistrationPrice, setPremiumRegistrationPrice] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const [usesSpecialCharacters, setUsesSpecialCharacters] = useState(false)
  const [displayWarningMessage, setDisplayWarningMessage] = useState(false)
  const [listingPurchasedPrice, setListingPurchasedPrice] = useState(0)
  const [listingPurchasedTxHash, setListingPurchasedTxHash] = useState('')
  const [isNotEnoughETH, setIsNotEnoughETH] = useState(false)

  const [isOwned, setIsOwned] = useState(false)

  const history = useHistory()
  const noAnimateHistory = useHistory()

  if (name.length <= 6) noAnimateHistory.push('/marketplace')

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant'
    })
  }, [])

  useEffect(() => {
    setListedModalOpen(location.search?.replace('?', '')?.includes('listed'))
  }, [location.search])

  useEffect(() => {
    if (
      !name ||
      name.split('.')?.[1]?.length < 3 ||
      name.split('.')?.[0]?.length < 3 ||
      !name.includes('.')
    ) {
      // history.push(`/registry?search=${name.split('.')[0]}`)
      history.push(`/registry`)
      return
    }

    try {
      const normalizedName = ens_normalize(name)
      if (normalizedName) setUsesSpecialCharacters(false)
    } catch (e) {
      setUsesSpecialCharacters(true)
    }

    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
  }, [name])

  // Seeds for manual updating of certain components
  const [offersSeed, setOffersSeed] = useState(1)
  const [headerSeed, setHeaderSeed] = useState(2)
  const [pageSeed, setPageSeed] = useState(3)
  const resetHeader = () => {
    setHeaderSeed(Math.random())
  }
  const resetOffers = () => {
    setOffersSeed(Math.random())
    setHeaderSeed(Math.random())
  }

  const resetPage = () => {
    setPageSeed(Math.random())
  }

  const {
    state: { domains: selectedDomains },
    addNewDomain,
    removeDomain
  } = useContext(CartStoreContext)

  const contentRef = useRef(null)

  const cartCtx = useContext(CartStoreContext)

  const account = useAccount()

  const ethPrice = useEthPrice()

  const { data: { registrations } = {} } = useQuery(
    GET_REGISTRATIONS_BY_LABELNAME,
    {
      variables: { labelName: name.replace('.eth', '') }
    }
  )

  const { data: saleData, refetch: refetchLastSale } = useQuery(GET_LAST_SALE, {
    variables: { domain: name },
    ...kodexApiContext
  })

  const lastPrice = saleData?.ens_last_sales[0]?.price || undefined
  const lastPriceAsset = saleData?.ens_last_sales[0]?.saleasset || undefined

  const tokenId = useMemo(() => {
    if (name) {
      const nameLabel = name.replace('.eth', '')
      return ethers.BigNumber.from(
        ethers.utils.keccak256(ethers.utils.toUtf8Bytes(nameLabel))
      ).toString()
    }

    return ''
  }, [name])

  const tokenIdHex = useMemo(() => {
    if (name) {
      const nameLabel = name.replace('.eth', '')
      return ethers.BigNumber.from(
        ethers.utils.keccak256(ethers.utils.toUtf8Bytes(nameLabel))
      ).toHexString()
    }

    return ''
  }, [name])

  useEffect(() => {
    const fetchViews = async () => {
      const reqBody = { token_id: tokenIdHex }

      try {
        const req = siweAuthedFetch(`${JETTY_URL}/user/viewed`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(reqBody)
        })

        const res = await req.then(res => res)
      } catch (e) {
        throw new Error(e)
      }
    }

    fetchViews()
  }, [account])

  useEffect(() => {
    // Checking if the domain is in cart to update the state
    const [domainIsInCart] = cartCtx.state.domains.filter(domain =>
      domain.name.includes(name)
    )
    if (domainIsInCart) {
      setDomainInCart(true)
    } else {
      setDomainInCart(false)
    }
  }, [name])

  useEffect(() => {
    document.title = name ? `${name} | Kodex` : 'Domain | Kodex'
  }, [name])

  const fetchAllowance = useCallback(async () => {
    if (account) {
      const wethContract = await getERC20TokenContract(WETH_TOKEN_ADDRESS)
      const allowance = await wethContract.allowance(
        account,
        EXCHANGE_CONTRACT_ADDRESS
      )
    }
  }, [account])

  useEffect(() => {
    fetchAllowance()
  }, [fetchAllowance])

  // const handleApproveAllowance = async () => {
  //   if (isApproving) return

  //   try {
  //     setApproving(true)
  //     const wethContract = await getERC20TokenContract(WETH_TOKEN_ADDRESS)
  //     const tx = await wethContract.approve(
  //       EXCHANGE_CONTRACT_ADDRESS,
  //       ethers.constants.MaxUint256
  //     )
  //     await tx.wait()
  //     await fetchAllowance()
  //     setApproving(false)
  //   } catch (err) {
  //     setApproving(false)
  //     throw new Error(err)
  //   }
  // }

  const handleCancelListing = async () => {
    try {
      const [listingId] = domainOrder.map(listing => listing.id)
      const signer = await getSigner()

      // const tokenId = ethers.BigNumber.from(
      //   ethers.utils.keccak256(ethers.utils.toUtf8Bytes(domain.name))
      // ).toHexString()

      setCancelingListing(true)

      await actions.cancelOrder({
        id: listingId,
        signer,
        onProgress: step => {
          if (step[0].items[0].status === 'complete') {
            setListed(false)
            setCancelingListing(false)
            setListingCanceled(true)
            resetHeader()
            resetPage()
          }
        }
      })
    } catch (err) {
      setCancelingListing(false)
      throw new Error(err)
    }
  }

  useEffect(() => {
    if (!registrations) return

    const [domainIsInCart] = cartCtx.state.domains.filter(domain =>
      domain.name.includes(name)
    )

    if (domainIsInCart) {
      setDomainInCart(true)
    } else {
      setDomainInCart(false)
    }
  }, [cartCtx.state.domains])

  const handleSeeMoreClick = () => {
    // since we changed the output to 100 im not sure if we should change from 60 to 100 here, it works without but could be affecting something else.
    history.push(`/search?query=${name}&searchType=similar`, 60)
  }

  const [likedProfiles, setLikedProfiles] = useState([])
  const [likedModalIsOpen, setLikedModalIsOpen] = useState(false)
  const [domainIsMine, setDomainIsMine] = useState(false)
  const [isCopied, setCopied] = useState(false)

  const labelName = name.replace('.eth', '')

  const { ownerAddress } = useGetDomainOwner(labelName)

  const { profile } = useUserProfile(ownerAddress)
  const { picture: profilePicture } = profile.userInfo || {}
  const { likes } = profile

  const { data: likeAddresses, refetch: refetchLikeAddresses } = useQuery(
    GET_LIKES_BY_DOMAIN,
    {
      variables: {
        domain: name,
        offset: 0,
        limit: likes || 1000
      },
      ...kodexApiContext,
      fetchPolicy: 'no-cache'
    }
  )

  const {
    isLikedByUser,
    isListed,
    listingPrice,
    listingExpireTime,
    toggleLike,
    likesCount,
    isRegistered,
    refetchDataFn,
    views,
    price,
    likesAmount,
    categories,
    expires,
    listedOn,
    expirationStatus
  } = useDomainResolver(
    {
      name: name,
      expire_time: registrations?.length > 0 ? registrations[0]?.expiryDate : 0,
      price: registrations?.length > 0 ? Number(registrations[0]?.cost) : 0
    },
    undefined,
    listingPurchased
  )

  const handleCart = () => {
    if (!account) return

    const domainName = registrations[0]?.labelName
      ? registrations[0]?.labelName + '.eth'
      : name

    const [domainIsInCart] = cartCtx.state.domains.filter(domain =>
      domain.name.includes(domainName)
    )

    if (domainIsInCart) {
      setDomainInCart(false)
      cartCtx.removeDomain(domainName, isRegistered ? 'PURCHASE' : 'REGISTER')
      return
    }

    const domainObjectTransformed = {
      name: domainName,
      expire_time: registrations[0]?.expiryDate || null,
      price: Number(registrations[0]?.cost) || null,
      __typename: registrations[0]?.__typename || null
    }

    setDomainInCart(true)
    cartCtx.addNewDomain(
      domainObjectTransformed,
      isRegistered ? 'PURCHASE' : 'REGISTER'
    )
  }

  const handleGoSubCategory = useCallback(
    (e, category) => {
      e.stopPropagation()

      if (category) {
        const categoryKey = category
          .toLowerCase()
          .split(' ')
          .join('-')

        TAGS.forEach(tag => {
          if (tag.key.toLowerCase() === categoryKey.toLowerCase()) {
            history.push(`/marketplace/${tag.key.toLowerCase()}`)
          } else if (tag.subcategories.length > 0) {
            tag.subcategories.forEach(subCategory => {
              if (subCategory.key.toLowerCase() === categoryKey.toLowerCase()) {
                history.push(`/marketplace/${tag.key.toLowerCase()}`)
              }
            })
          }
        })
      }
    },
    [categories]
  )

  useEffect(() => {
    if (!isRegistered) {
      setIsOwned(false)
      return
    }
    if (moment().isBefore(registrations[0]?.expiryDate * 1000)) {
      setIsOwned(true)
      return
    }
    setIsOwned(false)
  }, [isRegistered, registrations])

  useEffect(() => {
    setListed(isListed)
  }, [isListed])

  useEffect(() => {
    refetchLikeAddresses()
  }, [likesAmount, likesCount])

  useEffect(() => {
    const likedProfilePicturesArray = likeAddresses?.likes
      ?.filter(d => d.liked)
      ?.map(likedUser => ({
        user: likedUser.user,
        username: likedUser.userByUser.username,
        picture: likedUser.userByUser.picture,
        twitter: likedUser.userByUser.twitter,
        instagram: likedUser.userByUser.instagram,
        weblink: likedUser.userByUser.weblink
      }))

    setLikedProfiles(likedProfilePicturesArray)
  }, [likeAddresses, isLikedByUser])

  const myProfile = useAccount()

  useEffect(() => {
    if (myProfile?.toLowerCase() === ownerAddress?.toLowerCase()) {
      setDomainIsMine(true)
    } else {
      setDomainIsMine(false)
    }
  }, [myProfile, ownerAddress])

  const fetchOrder = useCallback(async domainName => {
    if (domainName?.length === 0) return

    try {
      const tokenId = ethers.BigNumber.from(
        ethers.utils.keccak256(
          ethers.utils.toUtf8Bytes(domainName.replace('.eth', ''))
        )
      ).toString()

      const fetchedOrders = await getListing({
        address: ENS_CONTRACT_ADDRESS,
        token_id: tokenId
      })

      if (!fetchedOrders) {
        setDomainOrder([])
        return
      }

      setDomainOrder([...fetchedOrders])
    } catch (err) {
      throw new Error(err)
    }
  }, [])

  useEffect(() => {
    fetchOrder(name)
  }, [name, isListed])

  const handlePurchaseDomain = async () => {
    if (domainOrder.length > 0) {
      try {
        const signer = await getSigner()

        setBuying(true)

        await actions.buyToken({
          signer,
          items: [
            {
              token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
              quantity: 1,
              preferredOrderSource: 'kodex.io'
            }
          ],
          onProgress: step => {
            if (step[0].items[0].status === 'complete') {
              removeDomain(name, 'PURCHASE')
              setListingPurchasedPrice(listingPrice)
              setBuying(false)
              setListed(false)
              setListingPurchasedTxHash(receipt.transactionHash)
              setListingPurchased(true)
              resetHeader()
              resetPage()
            }
          }
        })
      } catch (err) {
        setBuying(false)
        setListingPurchasedPrice(0)
        throw new Error(err)
      }
    }
  }

  const fetchOffers = async () => {
    if (!ownerAddress) return

    try {
      const fetchedOffers = await getOffers({
        address: ENS_CONTRACT_ADDRESS,
        token_id: tokenId
      })

      if (!fetchedOffers) {
        setOffers([])
        return
      }

      const filteredOffers = fetchedOffers.filter(
        off => off.maker.toLowerCase() !== ownerAddress.toLowerCase()
      )

      setOffers([...filteredOffers])
    } catch (err) {
      throw new Error(err)
    }
  }

  useEffect(() => {
    fetchOffers()
  }, [tokenId, ownerAddress])

  const handleCancelOffer = async (offer, i) => {
    try {
      setCancelingIndex(i)

      const signer = await getSigner()

      await actions.cancelOrder({
        id: offer.id,
        signer,
        onProgress: step => {
          if (
            step[0]?.items[0]?.status &&
            step[0]?.items[0]?.status === 'complete'
          ) {
            setCancelingIndex(undefined)
            resetHeader()
            setOffers(currOffers => currOffers.filter(o => o.id !== offer.id))
          }
        }
      })
    } catch (err) {
      setCancelingIndex(undefined)
      throw new Error(err)
    }
  }
  const handleAcceptOffer = async (offer, i) => {
    if (offer) {
      try {
        const signer = await getSigner()

        setAcceptingIndex(i)

        await actions.acceptOffer({
          items: [
            {
              token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
              quantity: 1
            }
          ],
          signer,
          onProgress: step => {
            if (step[0].items[0].status === 'complete') {
              setSuccessModalOpen(true)
              setAcceptingIndex(undefined)
              setOffers([])
              resetPage()
            }
          }
        })
      } catch (err) {
        setAcceptingIndex(undefined)
        throw new Error(err)
      }
    }
  }

  const { data: domainActivity, refetch: refetchDomainActivity } = useQuery(
    GET_DOMAIN_ACTIVITY_QUERY,
    {
      variables: {
        domain: name,
        limit: 20,
        offset: 0
      },
      ...kodexApiContext
    }
  )

  // const allRegistrations = registrations || []
  const allActivity = domainActivity?.get_domain_activity || []

  const activity = [...allActivity]

  const similarDomains = useSimilarDomains(name)

  useEffect(() => {
    const selectedDomainsNames = selectedDomains.map(domain => domain.name)
    const areInCartArr = similarDomains.map(domain =>
      selectedDomainsNames.includes(domain.name)
    )
    setDomainsIsInCartArr([...areInCartArr])
  }, [cartCtx, similarDomains, selectedDomains])

  // const handleOpenSimilarOfferDialog = (name, lastPrice) => {
  //   setSimilarOfferedDomainName(name)
  //   setSimilarOfferedDomainLastPrice(lastPrice)
  //   setOpenSimilarOfferDialog(true)
  // }

  useEffect(() => {
    if (!registrations) return
    const getIfDomainPremium = calculateIfPremium(registrations[0]?.expiryDate)

    if (getIfDomainPremium) {
      setDomainIsPremium(true)
    } else {
      setDomainIsPremium(false)
    }
  }, [registrations, name])

  useEffect(() => {
    const registrationPriceDollars = (price * ethPrice).toLocaleString(
      'default',
      {
        maximumFractionDigits: 0
      }
    )

    setRegistrationPrice(registrationPriceDollars || 0)
  }, [price, ethPrice])

  useEffect(() => {
    if (!registrations) return
    const premiumPrice = getPremiumPrice(
      registrations[0]?.expiryDate,
      moment().unix() * 1000
    )
    setPremiumRegistrationPrice(premiumPrice)
  }, [registrations])

  const topBarCtx = useContext(TopBarStyleContext)

  useEffect(() => {
    if (
      !isOwned &&
      expirationStatus !== 'grace period' &&
      !topBarCtx.scrolled
    ) {
      topBarCtx.setStyle(topBarRegistryStyle)
      return
    }
    if (
      (isOwned || expirationStatus === 'grace period') &&
      !topBarCtx.scrolled
    ) {
      topBarCtx.setStyle(topBarMarketplaceStyle)
    }
  }, [isOwned, topBarCtx, expirationStatus])

  useEffect(() => {
    setTimeout(() => {
      refetchDataFn()
      refetchLastSale()
      refetchDomainActivity()
    }, 7500)
  }, [listingPurchased])

  const { data: { getBalance } = {} } = useQuery(GET_BALANCE, {
    variables: { address: account },
    fetchPolicy: 'no-cache'
  })

  const ethBalance = getBalance
    ? Number(BigNumber.from(getBalance).toString()) / 10 ** 18
    : 0

  const handleCopy = useCallback(() => {
    if (!isCopied) {
      setCopied(true)

      const el = document.createElement('textarea')
      el.value = name
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    }
  }, [isCopied, name])

  useEffect(() => {
    if (!isCopied) {
      return
    }

    const timeout = setTimeout(() => setCopied(false), 5000)

    return () => clearTimeout(timeout)
  }, [isCopied])

  const formatAddress = address => {
    return address ? address.substr(2, 7) : ''
  }

  const bodyColor = useMemo(
    () =>
      isRegistered || expirationStatus === 'grace period' ? '#47596B' : '#fff',
    [isRegistered, expirationStatus]
  )
  const bodyBg = useMemo(
    () =>
      isRegistered || expirationStatus === 'grace period'
        ? '#F6F6F9'
        : '#020202',
    [isRegistered, expirationStatus]
  )
  const bodyBorder = useMemo(
    () =>
      isRegistered || expirationStatus === 'grace period'
        ? '#DADAE7'
        : '#333333',
    [isRegistered, expirationStatus]
  )

  const componentHeight = '500px'

  const addedToCart = selectedDomains.some(domain => domain.name === name)
  useWindowListener(
    'resize',
    () => {
      setWindowWidth(window.innerWidth)
    },
    []
  )

  const hasPriceContent = useMemo(
    () => listingPrice || domainIsPremium || lastPrice !== undefined,
    [listingPrice, domainIsPremium, lastPrice]
  )

  const whiteMode = useMemo(
    () => isRegistered || expirationStatus === 'grace period',
    [isRegistered, expirationStatus]
  )

  return (
    <>
      <View
        ref={contentRef}
        direction="column"
        width="100%"
        overflow="hidden"
        background={bodyBg}
      >
        {/* <KodexSidebar
          opened={listingCanceled}
          onClose={() => setListingCanceled(false)}
        >
          <View
            direction="column"
            alignItems="center"
            justify="space-between"
            padding="10vh 1rem"
            background="#0698A8"
            height="98vh"
            margin="0 0 1rem 0"
            width="50vw"
            borderRadius="10px"
          >
            <View direction="column" alignItems="center">
              <Label size={40}>{name}</Label>
              <Space size={15} />
              <Label size={40} color="rgb(255 255 255 / 0.5)" align="center">
                Unlisted successfully
              </Label>
            </View>
            <Space size={80} />
            <img src={ConfirmationImg} />
            <Space size={80} />
            <RoundedButton
              width="300px"
              background="#ffffff"
              color="#000000"
              borderRadius="10px"
              height="60px"
              border="1px solid #EBEBF2"
              fontSize="16px"
              onClick={() => {
                setListingCanceled(false)
                noAnimateHistory.push(`/domain/${name}?unlisted`)
              }}
            >
              Go back
            </RoundedButton>
          </View>
        </KodexSidebar>
        <KodexSidebar
          opened={listingPurchased}
          onClose={() => {
            setListingPurchased(false)
            noAnimateHistory.push(`/domain/${name}?unlisted`)
          }}
        >
          <View
            direction="column"
            alignItems="center"
            justify="space-between"
            padding="10vh 1rem"
            background="#0698A8"
            height="98vh"
            margin="0 0 1rem 0"
            width="50vw"
            borderRadius="10px"
          >
            <View direction="column" alignItems="center">
              <Label size={40}>{name}</Label>
              <Space size={15} />
              <Label size={40} color="rgb(255 255 255 / 0.5)" align="center">
                Purchase successful for
              </Label>
              <Space size={15} />
              <Label size={40}>
                {Number(listingPurchasedPrice).toLocaleString(
                  navigator.language,
                  {
                    maximumFractionDigits: 3
                  }
                )}{' '}
                ETH
              </Label>
            </View>
            <Space size={80} />
            <img src={ConfirmationImg} />
            <Space size={80} />
            <RoundedButton
              width="350px"
              height="60px"
              background="#2898A8"
              color="#ffffff"
              borderRadius="10px"
              border="1px solid #EBEBF2"
              fontSize="16px"
              onClick={() =>
                window.open(
                  `https://etherscan.io/tx/${listingPurchasedTxHash}`,
                  '_blank'
                )
              }
            >
              View transaction
            </RoundedButton>
            <Space size={10} />
            <RoundedButton
              width="350px"
              height="60px"
              background="#ffffff"
              color="#000000"
              borderRadius="10px"
              border="1px solid #EBEBF2"
              fontSize="16px"
              onClick={() => {
                setListingPurchased(false)
                noAnimateHistory.push(`/domain/${name}?unlisted`)
              }}
            >
              Go back
            </RoundedButton>
          </View>
        </KodexSidebar>
        <BottomModal
          open={successModalOpen}
          onClose={() => setSuccessModalOpen(false)}
        >
          <AcceptSuccessModal onClose={() => setSuccessModalOpen(false)} />
        </BottomModal>
        <BottomModal
          open={listedModalOpen}
          onClose={() => setListedModalOpen(false)}
          transparent
        >
          <ListedModal
            open={listedModalOpen}
            onClose={() => setListedModalOpen(false)}
            domain={name}
          />
        </BottomModal>
        <BottomModal
          open={registrationModalOpen}
          onClose={() => setRegistrationModalOpen(false)}
          closeButtonLabel="Back to registry"
          contentPadding="0"
        >
          {domainIsPremium
            ? registrationModalOpen && (
                <PremiumRegistry
                  domain={{
                    name,
                    expire_time: registrations[0]?.expiryDate
                  }}
                  closeLabel="Back to domain page"
                  closeRegistration={() => setRegistrationModalOpen(false)}
                  refetchState={() => {
                    noAnimateHistory.push(`/domain/${name}?registered`)
                  }}
                />
              )
            : registrationModalOpen && (
                <SingleRegistration
                  domain={{
                    name
                  }}
                  closeLabel="Back to domain page"
                  closeRegistration={() => setRegistrationModalOpen(false)}
                  refetchState={() => {
                    noAnimateHistory.push(`/domain/${name}?registered`)
                  }}
                />
              )}
        </BottomModal>
        <KodexSidebar
          opened={likedModalIsOpen}
          onClose={() => setLikedModalIsOpen(false)}
        >
          <LikeAccountsList likeAccounts={likedProfiles} />
        </KodexSidebar>
        <KodexSidebar
          opened={openOfferDialog}
          onClose={() => setOpenOfferDialog(false)}
        >
          <OfferDialog
            domain={{
              name,
              lastPrice: highestOffer
            }}
            onClose={() => setOpenOfferDialog(false)}
            backLabel="domain page"
            resetState={() => {
              fetchOffers()
              resetOffers()
              noAnimateHistory.push(`/domain/${name}?unlisted`)
            }}
          />
        </KodexSidebar>
        {/* This code below was used for when you could open offer dialog when clicking on the card footer */}
        {/* <KodexSidebar
          opened={openSimilarOfferDialog}
          onClose={() => setOpenSimilarOfferDialog(false)}
        >
          <OfferDialog
            domain={{
              name: similarOfferedDomainName,
              lastPrice: similarOfferedDomainlastPrice
            }}
            backLabel="account"
            onClose={() => setOpenSimilarOfferDialog(false)}
          />
        </KodexSidebar> 
        <KodexSidebar
          opened={openTransferDialog}
          onClose={() => setOpenTransferDialog(false)}
        >
          <TransferDialog
            domainName={name}
            onClose={() => setOpenTransferDialog(false)}
            onReset={() => {
              resetPage()
              resetHeader()
            }}
          />
        </KodexSidebar> */}
        <Container
          maxWidth="calc(100% - 32px)"
          background={bodyBg}
          key={pageSeed}
        >
          <Space size={104} />
          <View>
            <Label
              color={isRegistered ? '#47596B' : 'white'}
              size={30}
              weight={500}
            >
              {name}
            </Label>
            {usesSpecialCharacters && (
              <View
                height="25px"
                padding="1px 0 0 0"
                justify="items-start"
                position="relative"
                alignItems="start"
                cursor="pointer"
                onMouseEnter={() => setDisplayWarningMessage(true)}
                onMouseLeave={() => setDisplayWarningMessage(false)}
              >
                {displayWarningMessage && (
                  <>
                    <div
                      style={{
                        width: '360px',
                        position: 'absolute',
                        top: '-12px',
                        left: '35px',
                        border: `1px solid ${
                          isRegistered ? '#E1E1E8' : '#4A4A4D'
                        }`,
                        padding: '15px',
                        background: isRegistered ? 'white' : '#262628',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '8px',
                        zIndex: 100
                      }}
                    >
                      <Label
                        color={isRegistered ? '#222' : 'white'}
                        size={15}
                        align="center"
                      >
                        This domain uses non ASCII special characters
                      </Label>
                    </div>
                  </>
                )}
                <NameWarning height="25px" width="25px" />
              </View>
            )}
          </View>
          <Space size={32} />
          <View width="100%" gap="16px" direction="column">
            <View
              background={isRegistered ? 'white' : '#1B1B1B'}
              width="100%"
              borderRadius="8px"
              direction="column"
              justify="space-between"
              padding="24px"
              gap="16px"
            >
              {hasPriceContent && (
                <View direction="row" justify="space-between" width="100%">
                  <Label
                    color={whiteMode ? '#47596B99' : '#A8A8A9'}
                    weight={500}
                  >
                    {isListed || domainIsPremium ? 'Price' : 'Last sale'}
                  </Label>

                  <View alignItems="center" gap="0.5rem">
                    {isRegistered ? (
                      whiteMode ? (
                        <EthPriceGrey width={20} height={20} />
                      ) : (
                        <EthPriceLight width={20} height={20} />
                      )
                    ) : (
                      <Label
                        color={isRegistered ? '#47596B' : '#fffb'}
                        size={16}
                      >
                        $
                      </Label>
                    )}
                    <Label color={bodyColor} number size={16}>
                      {!!listingPrice || !!price || !!lastPrice ? (
                        <>
                          {isRegistered
                            ? isListed
                              ? formatETHPrice(listingPrice)
                              : domainIsPremium
                              ? formatETHPrice(price)
                              : formatETHPrice(lastPrice)
                            : registrationPrice}
                        </>
                      ) : (
                        <LoadingSpinner
                          margin="0 0 0 10px"
                          color={whiteMode ? '#000' : '#fff'}
                        />
                      )}
                    </Label>
                  </View>
                </View>
              )}

              <View
                direction="row"
                justify="space-between"
                alignItems="center"
                width="100%"
              >
                <Label color={whiteMode ? '#47596B99' : '#A8A8A9'} weight={500}>
                  {listingExpireTime || expires ? 'Listing expires' : null}
                </Label>

                <View alignItems="center">
                  <Label color={whiteMode ? '#47596B' : '#A8A8A9'} size={16}>
                    {listed && (listingExpireTime || expires) ? (
                      <Date dateString={listingExpireTime * 1000} />
                    ) : !!registrations ? (
                      registrations?.length > 0 && <Date dateString={expires} />
                    ) : (
                      '-'
                    )}
                  </Label>
                </View>
              </View>
              <View
                direction="row"
                justify="space-between"
                alignItems="center"
                width="100%"
              >
                <Label color={whiteMode ? '#47596B99' : '#A8A8A9'} weight={500}>
                  Views
                </Label>
                <View alignItems="center">
                  <Label number size={16} color={bodyColor}>
                    {views >= 1_000_000
                      ? Math.floor(views / 1_000_000) + 'M'
                      : views >= 1_000
                      ? Math.floor(views / 1_000) + 'K'
                      : views}
                  </Label>
                </View>
              </View>
              <View
                width="100%"
                alignItems="center"
                margin="16px 0 0 0"
                flex="33%"
                gap="12px"
              >
                {domainIsMine ? (
                  <>
                    {listed ? (
                      <>
                        <RoundedButton
                          borderRadius="8px"
                          height="48px"
                          width={
                            isListed || isRegistered === false ? '50%' : '100%'
                          }
                          background="#47596B"
                          onClick={() => history.push(`/listing/${tokenIdHex}`)}
                        >
                          <Label color="#fff" size={16} weight={500}>
                            Edit
                          </Label>
                        </RoundedButton>
                        <RoundedButton
                          borderRadius="8px"
                          height="48px"
                          width={
                            isListed || isRegistered === false ? '50%' : '100%'
                          }
                          background={
                            isListed || !domainIsPremium ? '#F3F3FA' : '#262628'
                          }
                          onClick={handleCancelListing}
                        >
                          <Label
                            color={
                              isListed || !domainIsPremium ? '#000' : '#fff'
                            }
                            size={16}
                            weight={500}
                          >
                            {cancelingListing
                              ? 'Canceling...'
                              : 'Cancel listing'}
                          </Label>
                        </RoundedButton>
                      </>
                    ) : (
                      <>
                        <RoundedButton
                          borderRadius="8px"
                          height="48px"
                          width={
                            isListed || isRegistered === false ? '50%' : '100%'
                          }
                          background={
                            isListed || !domainIsPremium ? '#F3F3FA' : '#262628'
                          }
                          onClick={() => history.push(`/listing/${tokenIdHex}`)}
                        >
                          <Label
                            color={
                              isListed || !domainIsPremium ? '#000' : '#fff'
                            }
                            size={16}
                            weight={500}
                          >
                            Sell
                          </Label>
                        </RoundedButton>
                        <RoundedButton
                          borderRadius="8px"
                          height="48px"
                          width={
                            isListed || isRegistered === false ? '50%' : '100%'
                          }
                          background="#47596B"
                          onClick={() => setOpenTransferDialog(true)}
                        >
                          <Label color="#fff" size={16} weight={500}>
                            Transfer
                          </Label>
                        </RoundedButton>
                      </>
                    )}
                  </>
                ) : (
                  isListed &&
                  !domainIsPremium && (
                    <RoundedButton
                      onClick={() => {
                        if (Number(ethBalance) <= Number(listingPrice))
                          return setIsNotEnoughETH(true)
                        handlePurchaseDomain()
                      }}
                      disabled={isBuying || isNotEnoughETH}
                      borderRadius="8px"
                      height="48px"
                      background="#47596B"
                      hoverBackground="#47596B"
                      width="50%"
                    >
                      <ButtonMouseEventSensor
                        inFront={isNotEnoughETH}
                        onMouseLeave={() => setIsNotEnoughETH(false)}
                      />
                      <Label size={16} weight={500}>
                        {isNotEnoughETH
                          ? 'Not enough ETH'
                          : isBuying
                          ? 'Pending...'
                          : 'Buy now'}
                      </Label>
                    </RoundedButton>
                  )
                )}
                {!domainIsMine && (
                  <RoundedButton
                    onClick={() => {
                      isRegistered
                        ? setOpenOfferDialog(true)
                        : setRegistrationModalOpen(true)
                    }}
                    borderRadius="8px"
                    height="48px"
                    width={isListed || isRegistered === false ? '50%' : '100%'}
                    background={
                      (isRegistered && !isListed) || !isRegistered
                        ? '#47596B'
                        : '#F3F3FA'
                    }
                  >
                    <Label
                      color={
                        (isRegistered && !isListed) || !isRegistered
                          ? '#fff'
                          : '#000'
                      }
                      size={16}
                      weight={500}
                    >
                      {isRegistered ? 'Make offer' : 'Register'}
                    </Label>
                  </RoundedButton>
                )}
                {isRegistered === false && (
                  <RoundedButton
                    onClick={handleCart}
                    borderRadius="8px"
                    height="48px"
                    background={addedToCart ? '#47596B' : '#262628'}
                    width="100%"
                  >
                    <Label color="#fff" size={16} weight={500}>
                      {!addedToCart ? 'Add to cart' : 'Remove from cart'}
                    </Label>
                  </RoundedButton>
                )}
              </View>
            </View>
            <MobileActivityAndOffers
              bodyColor={bodyColor}
              bodyBg={bodyBg}
              bodyBorder={bodyBorder}
              key={offersSeed}
              activityData={activity}
              offers={offers}
              cancelOffer={handleCancelOffer}
              setHighestOffer={setHighestOffer}
              canceling={cancelingIndex}
              acceptOffer={handleAcceptOffer}
              accepting={acceptingIndex}
              ownedDomain={domainIsMine}
              isRegistered={isOwned || expirationStatus === 'grace period'}
            />
            {domainIsPremium && (
              <div style={{ gridColumnStart: 'span 2' }}>
                <PremiumChart
                  domain={name}
                  expiryDate={registrations[0]?.expiryDate}
                  darkMode
                  id="premiumDomainModalChart"
                />
              </div>
            )}
            <MobileDetails
              bodyColor={bodyColor}
              bodyBg={bodyBg}
              bodyBorder={bodyBorder}
              tokenId={tokenId}
              registrationDate={
                registrations && registrations[0]?.registrationDate
              }
              expires={registrations && registrations[0]?.expiryDate}
              domainName={name}
              isRegistered={isOwned}
              whiteMode={whiteMode}
            />
            <View borderRadius="8px" height="252px" width="100%">
              <MobilePriceHistory
                height="252px"
                domainName={name}
                whiteMode={whiteMode}
                isRegistered={isOwned}
              />
            </View>
            <View
              height="100%"
              borderRadius="8px"
              border={`1px solid ${bodyBorder}`}
              direction="column"
              justify="space-between"
              width="100%"
              padding="1rem"
              gap="24px"
            >
              <Label weight={600} size={16} color={bodyColor}>
                Categories
              </Label>
              <View width="100%" gap="24px" wrappable>
                {categories?.terms?.map((category, index) => {
                  return (
                    <Link
                      key={index}
                      hoverOpacity={0.6}
                      onClick={e => {
                        handleGoSubCategory(e, category)
                      }}
                    >
                      <Label
                        color={MAIN_CATEGORY_COLORS[index % 11]}
                        size={16}
                        weight={500}
                      >
                        {category}
                      </Label>
                    </Link>
                  )
                })}
                {!categories?.terms && <Label>Uncategorized.</Label>}
              </View>
            </View>
          </View>
          <Space size={20} />
        </Container>
        <MobileSimilarSection
          ref={contentRef}
          isRegistered={whiteMode}
          data={similarDomains}
          gap="20px"
          // LastItemComponent={
          //   <SeeMoreCard onClick={handleSeeMoreClick}>
          //     <Label size={30} zIndex={1}>
          //       See more
          //     </Label>
          //     <Image src={SeeMore} width={200} height={230} alt="see more" />
          //   </SeeMoreCard>
          // }
          itemsRenderer={(dataItem, index) => {
            const registered =
              dataItem &&
              moment()
                .subtract(90, 'days')
                .isBefore(Number(dataItem.expire_time) * 1000)
            return (
              <MobileTokenCard
                // height="370px"
                // margin="10px"
                footerBg="#020202"
                // registeredBg="#ffffff"
                hasAnimation={true}
                domain={dataItem}
                onClick={() => {
                  window.scrollTo({
                    left: 0,
                    top: 0,
                    behavior: 'smooth'
                  })
                  history.push(`/domain/${dataItem.name}`)
                }}
                onSelect={() => {
                  selectedDomains.includes(dataItem) ||
                  domainsIsInCartArr[index]
                    ? removeDomain(
                        dataItem.name,
                        registered ? 'Purchase' : 'Register'
                      )
                    : addNewDomain(
                        dataItem,
                        registered ? 'Purchase' : 'Register'
                      )
                }}
                // headerBg={isRegistered ? '#EBEBF2' : '#262628'}
                headerColor={isRegistered ? '#000' : '#fff'}
                bodyBg={
                  isRegistered ? '#fff' : registered ? '#020202' : '#212121'
                }
                bodyColor={isRegistered ? '#020202' : '#fff'}
                addedToCart={
                  selectedDomains.includes(dataItem) ||
                  domainsIsInCartArr[index]
                }
                from={isRegistered ? 'Marketplace' : 'Registry'}
                inSimilarSection={true}
                key={`${dataItem.name}--${index}`}
                // openOfferDialog={handleOpenSimilarOfferDialog}
              />
            )
          }}
        />
      </View>
    </>
  )
}

const ButtonMouseEventSensor = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: ${({ inFront }) => (inFront ? 1 : -1)};
`

export default MobileRegisteredDomain
