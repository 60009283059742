import mockAvatar0 from 'assets/mock/chat/user--0.png'
import mockAvatar1 from 'assets/mock/chat/user--1.jpeg'
import mockAvatar2 from 'assets/mock/chat/user--2.png'
import mockAvatar3 from 'assets/mock/chat/user--3.jpg'
import accountAvatar from 'assets/icon-pfp-default.png'

function getPreviousDay(date) {
  const previous = new Date(date.getTime())
  previous.setDate(date.getDate() - 1)

  return previous
}

const mockDate = (date, hours, minutes) => {
  date.setHours(hours)
  date.setMinutes(minutes)
  return date
}

const MOCK_USER_DATA = [
  {
    name: 'wen.eth',
    id: '0',
    avatar: mockAvatar3
  },
  {
    name: 'Peter',
    id: '1',
    avatar: mockAvatar0
  },
  {
    name: 'lisa.eth',
    id: '2',
    avatar: mockAvatar1
  },
  {
    name: 'Jenkins',
    id: '3',
    avatar: mockAvatar2
  },
  {
    name: 'me',
    id: '4',
    avatar: accountAvatar
  }
]

export const MOCK_CHATS = [
  {
    messages: [
      {
        content: 'Wen chatrooms?',
        createdAt: mockDate(new Date(), 4, 20),
        user: MOCK_USER_DATA[4]
      },
      {
        content: 'Soon fren, very soon.',
        createdAt: mockDate(new Date(), 19, 0),
        user: MOCK_USER_DATA[0]
      }
    ],
    summary: {
      users: [MOCK_USER_DATA[0]],
      lastMessageReceived: {
        content: 'Soon fren, very soon.',
        createdAt: mockDate(new Date(), 19, 0),
        user: MOCK_USER_DATA[0]
      },
      lastMessageSent: {
        // # TODO => use this for sorting unresponded messages
      }
    },
    id: '0'
  },
  {
    messages: [
      {
        content: 'Hello there!',
        createdAt: mockDate(getPreviousDay(new Date()), 11, 3),
        user: MOCK_USER_DATA[1]
      },
      {
        content: 'Hey!',
        createdAt: mockDate(getPreviousDay(new Date()), 11, 26),
        user: MOCK_USER_DATA[4]
      },
      {
        content: "I've got an offer for you that I think you'll like.",
        createdAt: mockDate(new Date(), 16, 40),
        user: MOCK_USER_DATA[1]
      }
    ],
    summary: {
      users: [MOCK_USER_DATA[1]],
      lastMessageReceived: {
        content: "I've got an offer for you that I think you'll like.",
        createdAt: mockDate(new Date(), 16, 40),
        user: MOCK_USER_DATA[1]
      },
      lastMessageSent: {
        // # TODO => use this for sorting unresponded messages
      }
    },
    id: '1'
  },
  {
    messages: [
      {
        content:
          'Hey there, are you interested in trades? I own a lot of first name domains! ',
        createdAt: mockDate(getPreviousDay(new Date()), 12, 9),
        user: MOCK_USER_DATA[3]
      }
    ],
    summary: {
      users: [MOCK_USER_DATA[3]],
      lastMessageReceived: {
        content:
          'Hey there, are you interested in trades? I own a lot of first name domains! ',
        createdAt: mockDate(getPreviousDay(new Date()), 12, 9),
        user: MOCK_USER_DATA[3]
      },
      lastMessageSent: {
        // # TODO => use this for sorting unresponded messages
      }
    },
    id: '3'
  },
  {
    messages: [
      {
        content:
          "How did you get your first name ENS handle? I'm trying to get mine!",
        createdAt: mockDate(new Date(), 7, 30),
        user: MOCK_USER_DATA[4]
      }
    ],
    summary: {
      users: [MOCK_USER_DATA[2]],
      lastMessageReceived: null,
      lastMessageSent: {
        // # TODO => use this for sorting unresponded messages
      }
    },
    id: '2'
  }
]
