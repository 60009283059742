import ANIMALS from 'assets/lotties/animals.json'
import Characters from 'assets/lotties/characters.json'
import COMPANY from 'assets/lotties/company.json'
import CREATURES from 'assets/lotties/creatures.json'
import Crypto from 'assets/lotties/crypto.json'
import DICTIONARY from 'assets/lotties/dictionary.json'
import KCLUB from 'assets/lotties/k-club.json'
import MISCELLANEOUS from 'assets/lotties/miscellaneous.json'
import Names from 'assets/lotties/names.json'
import Places from 'assets/lotties/places.json'
import WEB from 'assets/lotties/web.json'
import TrendingCategoryCard from 'components/Card/TrendingCategoryCard'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import Space from 'components/Space'
import View from 'components/View'

const Categories = () => {
  return (
    <View background="#fff" direction="column" width="100%">
      <Space size={160} />
      <Container maxWidth="1300px">
        <View width="100%" justify="space-between" alignItems="center">
          <Label size={110} color="#000">
            Categories
          </Label>
        </View>
        <Space size={100} />
        <View width="100%">
          <View flex={1}>
            <TrendingCategoryCard
              subject="Crypto"
              icon={Crypto}
              hoverBackground={'#E3888F'}
              category="crypto"
            />
          </View>
          <Space size={20} />
          <View flex={1}>
            <TrendingCategoryCard
              subject="Names"
              icon={Names}
              hoverBackground={'#0698A8'}
              category="names"
            />
          </View>
          {/* <Space size={20} />
          <View flex={1}>
            <TrendingCategoryCard
              subject="ART"
              icon={ART}
              hoverBackground={'#E59F8A'}
              category="art"
            />
          </View> */}
          <Space size={20} />
          <View flex={1}>
            <TrendingCategoryCard
              subject="Places"
              icon={Places}
              hoverBackground={'#E3CF88'}
              category="places"
            />
          </View>
          <Space size={20} />
          <View flex={1}>
            <TrendingCategoryCard
              subject="Characters"
              icon={Characters}
              hoverBackground={'#AF61AB'}
              category="characters"
            />
          </View>
        </View>
        <Space size={20} />
        <View width="100%">
          <View flex={1}>
            <TrendingCategoryCard
              subject="Web"
              icon={WEB}
              hoverBackground={'#ABA3E3'}
              category="web"
            />
          </View>
          <Space size={20} />
          <View flex={1}>
            <TrendingCategoryCard
              subject="Miscellaneous"
              icon={MISCELLANEOUS}
              hoverBackground={'#82CED9'}
              category="misc"
            />
          </View>
          {/* <Space size={20} />
          <View flex={1}>
            <TrendingCategoryCard
              subject="PEOPLE"
              icon={PEOPLE}
              hoverBackground={'#80A2D6'}
              category="people"
            />
          </View> */}
          <Space size={20} />
          <View flex={1}>
            <TrendingCategoryCard
              subject="Dictionary"
              icon={DICTIONARY}
              hoverBackground={'#E3BA88'}
              category="dictionary"
            />
          </View>
          <Space size={20} />
          <View flex={1}>
            <TrendingCategoryCard
              subject="Company"
              icon={COMPANY}
              hoverBackground={'#7ACCBD'}
              category="company"
            />
          </View>
        </View>
        <View width="100%">
          <View flex={1}>
            <TrendingCategoryCard
              subject="Clubs"
              icon={KCLUB}
              hoverBackground={'#9398B2'}
              category="clubs"
            />
          </View>
          <Space size={20} />
          <View flex={1}>
            <TrendingCategoryCard
              subject="Creatures"
              icon={CREATURES}
              hoverBackground={'#BD8FCC'}
              category="creatures"
            />
          </View>
          <Space size={20} />
          <View flex={1}>
            <TrendingCategoryCard
              subject="Animals"
              icon={ANIMALS}
              hoverBackground={'#82BC84'}
              category="animals"
            />
          </View>
          <Space size={20} />
          <View flex={1} />
        </View>
      </Container>
      <Space size={100} />
    </View>
  )
}

export default Categories
