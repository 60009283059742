import { useImagePreloader } from 'contexts/ImagePreloaderProvider'
import { useRef, useState } from 'react'

const ImagesSequence = () => {
  const { imagesSequence } = useImagePreloader()

  const canvasRef = useRef(null)
  const [size, setSize] = useState({
    width: 1440,
    height: 770
  })
  const [airpods, setAirpods] = useState({
    frame: 0
  })

  const getCanvas = () => canvasRef.current

  const getContext = () => getCanvas()?.getContext?.('2d')

  const renderItem = () => {
    const ctx = getContext()
    if (!ctx) return

    const left = 0.5
    const top = 0.5

    const cWidth = ctx.canvas.width
    const cHeight = ctx.canvas.height
    const width = imagesSequence.images[airpods.frame].width
    const height = imagesSequence.images[airpods.frame].height
    const ratio = width / height
    const cRatio = cWidth / cHeight
    let resultHeight, resultWidth

    if (ratio > cRatio) {
      resultHeight = cHeight
      resultWidth = cHeight * ratio
    } else {
      resultWidth = cWidth
      resultHeight = cWidth / ratio
    }

    ctx.clearRect(0, 0, getCanvas().width, getCanvas().height)
    ctx.drawImage(
      imagesSequence.images[airpods.frame],
      (cWidth - resultWidth) * left,
      (cHeight - resultHeight) * top,
      resultWidth,
      resultHeight
    )
  }

  // const init = useCallback(() => {
  //   gsap.to(airpods, {
  //     frame: imagesSequence.frameCount - 1,
  //     snap: 'frame',
  //     roundProps: 'frame', // round, only int
  //     ease: 'none', // ease provided by smooth-scroll momentum
  //     immediateRender: true, // render first frame immediately
  //     scrollTrigger: {
  //       trigger: canvasRef.current,
  //       scrub: true,
  //       start: '-=700',
  //       end: '+=1500'
  //     },
  //     onUpdate: renderItem // use animation onUpdate instead of scrollTrigger's onUpdate
  //   })

  //   if (imagesSequence?.images[0]) {
  //     imagesSequence.images[0].onload = renderItem
  //   }
  // }, [imagesSequence])

  // useEffect(() => {
  //   init()
  // }, [])

  return (
    <canvas {...size} ref={canvasRef} style={{ filter: 'saturate(2.2)' }} />
  )
}

export default ImagesSequence
