import React, { useCallback } from 'react'
import View from 'components/View'
import Space from 'components/Space'
import Card from './Card'
import OptionSelect from './OptionSelect'

const events = [
  {
    label: 'Sale',
    value: 'sale'
  },
  {
    label: 'Offer',
    value: 'offer'
  },
  {
    label: 'Registration',
    value: 'registration'
  },
  {
    label: 'Listing',
    value: 'listing'
  }
]
const EventsSelector = ({ value, onChange }) => {
  const handleChange = useCallback(option => {
    onChange(option.value)
  }, [])

  return (
    <Card title="Events">
      <Space size={20} />
      <View width="450px" margin="0 -20px" direction="column">
        <View width="100%">
          <OptionSelect
            label={events[0].label}
            onClick={() => handleChange(events[0])}
            isChecked={value === events[0].value}
            isRadio
          />
          <OptionSelect
            label={events[1].label}
            onClick={() => handleChange(events[1])}
            isChecked={value === events[1].value}
            isRadio
          />
        </View>
        <View width="100%">
          <OptionSelect
            label={events[2].label}
            onClick={() => handleChange(events[2])}
            isChecked={value === events[2].value}
            isRadio
          />
          <OptionSelect
            label={events[3].label}
            onClick={() => handleChange(events[3])}
            isChecked={value === events[3].value}
            isRadio
          />
        </View>
      </View>
    </Card>
  )
}

export default EventsSelector
