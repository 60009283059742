import styled from '@emotion/styled/macro'
import Card from 'components/Card'
import Label from 'components/Label'
import { useHistory } from 'react-router'
import View from 'components/View'

const SimpleCard = ({ imgUrl, title, height, link }) => {
  const history = useHistory()
  return (
    <SimpleCardWrapper
      onClick={() => {
        if (!link) return
        history.push(link)
      }}
    >
      <View width="100%" style={{ opacity: 0 }} data-in="fade-up">
        <Card
          width="100%"
          height={height}
          background="#020202"
          borderRadius="7px"
        >
          <CardContent>
            <View
              flex={1}
              width="100%"
              borderRadius="6px"
              background="#EBEBF2"
              overflow="hidden"
            >
              <img
                src={imgUrl}
                alt={title}
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: '-8px',
                  minWidth: '100%',
                  minHeight: 'calc(100% + 8px)'
                }}
              />
            </View>
            <View
              height="80px"
              width="100%"
              padding="0px 30px"
              alignItems="center"
            >
              <Label size={30}>{title}</Label>
            </View>
          </CardContent>
        </Card>
      </View>
    </SimpleCardWrapper>
  )
}

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const SimpleCardWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100%;
`

export default SimpleCard
