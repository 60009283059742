import styled from '@emotion/styled/macro'
import { Checkbox } from './Checkbox'

export const SelectAllCategories = ({
  name,
  isSelected,
  onSelect = () => {},
  darkMode
}) => {
  return (
    <Container isSelected={isSelected}>
      <Name darkMode={darkMode}>{name}</Name>
      <Checkbox darkMode={darkMode} onCheck={onSelect} isChecked={isSelected} />
    </Container>
  )
}

const Container = styled.div`
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: 16px;
  padding: 0 20px;
  &:hover {
    background-color: '#F3F3FA';
  }
`
const Name = styled.div`
  color: ${props => (props.darkMode ? '#ffffff90' : '#bcbccc')};
  margin-right: auto;
  opacity: 0.6;
`
