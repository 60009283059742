import styled from '@emotion/styled/macro'

const View = styled.div`
  color: ${props => (props.color ? props.color : '')};
  display: flex;
  position: ${props => (props.position ? props.position : 'relative')};
  box-sizing: border-box;
  bottom: ${props => props.bottom};
  opacity: ${props => props.opacity};
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  flex-direction: ${props => props.direction};
  flex-wrap: ${props => (props.wrappable ? 'wrap' : 'no-wrap')};
  flex: ${props => props.flex};
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => props.padding};
  outline: ${props => props.outline};
  margin: ${props => props.margin};
  width: ${props => props.width};
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
  height: ${props => props.height};
  min-height: ${props => props.minHeight};
  max-height: ${props => props.maxHeight};
  background: ${props => props.background};
  border-radius: ${props => props.borderRadius};
  border-top-left-radius: ${props => props.borderTopLeftRadius};
  border-top-right-radius: ${props => props.borderTopRightRadius};
  border: ${props => props.border};
  will-change: ${props => props.willChange};
  overflow: ${props => props.overflow};
  transform: ${props => props.transform};
  transition: ${props => props.transition};
  z-index: ${props => props.zIndex};
  gap: ${props => props.gap};
  cursor: ${props => props.cursor};
  transition: ${props => props.transition};
  background-size: ${props =>
    props.backgroundSize ? props.backgroundSize : '200% 200%'};
  ${props =>
    props.animation
      ? `animation: ${props.animation};`
      : `-webkit-animation: none;
        animation: none;
      `}

  &:hover {
    color: ${props => props.hoverColor};
    background: ${props => props.hoverBackground};
    transform: ${props => props.hoverTransform};
    opacity: ${props => props.hoverOpacity};
  }

  ${props => props.scrollBar}

  @keyframes fadeInBottom {
    from {
      opacity: 0;
      transform: translateY(5%);
    }
    to {
      opacity: 1;
    }
  }

  @keyframes loading {
    0% {
      background-position: 160% 0%;
    }
    100% {
      background-position: -40% 0%;
    }
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(10%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-10%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeFromBottom {
    0% {
      transform: translateY(10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

export default View
