import { useLazyQuery, useQuery } from '@apollo/client'
import styled from '@emotion/styled/macro'
import Link from 'components/Forms/Link'
import Label from 'components/Label'
import View from 'components/View'
import useGetDomains from 'hooks/useGetDomains'
import useGetUnRegisteredDomains from 'hooks/useGetUnRegisteredDomains'
import useOutsideAlerter from 'hooks/useOutsideAlerter'
import { differenceBy, uniq } from 'lodash'
import { debounce } from 'lodash/function'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  GET_CATEGORIES,
  SEARCH_USERS,
  kodexApiContext
} from 'service/graphql/kodex-api'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import SearchIcon from '../SearchIcon'
import SearchResultModal from './SearchResultModal'
import { getMarketplaceDefaultDomains } from 'service/rest/getMarketplaceDomains'
import { JETTY_URL } from 'service/siwe/constants'

// TODO: temporary solution. it might fetch from backend
const CATEGORY_COLORS = ['#E3BA88', '#E3888F', '#0698A8']

let isLoading = true

const QuickSearch = ({ color }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [registryDomains, setRegistryDomains] = useState([])
  const [marketDomains, setMarketDomains] = useState([])

  const searchBarContainerRef = useRef(null)
  const searchBarWrapperRef = useRef(null)
  const inputRef = useRef(null)

  // TODO: Move this into individual search component
  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus()
      searchBarContainerRef.current.style.maxWidth = `${searchBarWrapperRef
        .current.clientWidth + 60}px`
    } else {
      searchBarContainerRef.current.style.maxWidth = '60px'
    }
  }, [isFocused])

  const { data: categoryListData } = useQuery(GET_CATEGORIES, {
    variables: {
      offset: 0,
      limit: 10
    },
    ...kodexApiContext
  })

  const categoryList = useMemo(() => {
    const domainsTaxonomies =
      categoryListData && categoryListData['domains_taxonomies']

    const categoryMap = {}
    ;(domainsTaxonomies || []).forEach((tx, id) => {
      const ckey = tx.taxonomy.toLowerCase()
      if (!categoryMap.hasOwnProperty(ckey)) {
        categoryMap[ckey] = {
          key: ckey,
          name: tx.taxonomy,
          color: CATEGORY_COLORS[id % 3]
        }
      }
    })

    return Object.values(categoryMap)
  }, [categoryListData])

  const getDomains = async type => {
    if (searchTerm.length < 3) return []

    const params = [
      `offset=0`,
      `&name=${searchTerm || ''}`,
      '&order_type=default'
    ].join('')

    try {
      const marketReq = fetch(
        `${JETTY_URL}/search/plain?limit=100&search_taxa=&search_type=marketplace&${params}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )

      const registryReq = fetch(
        `${JETTY_URL}/search/plain?limit=100&search_taxa=&search_type=registry&${params}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )

      const marketRes = await marketReq
        .then(res => res)
        .then(data => data.json())

      const registryRes = await registryReq
        .then(res => res)
        .then(data => data.json())

      setMarketDomains(marketRes.domains)

      const filteredregistryDomains = registryRes.domains.filter(
        d => !marketRes.domains.includes(d)
      )

      setRegistryDomains(filteredregistryDomains)

      isLoading = false
    } catch (e) {
      throw new Error(e)
    }
  }

  const [getUsers, getUsersResponse] = useLazyQuery(SEARCH_USERS, {
    notifyOnNetworkStatusChange: true,
    ...kodexApiContext
  })

  useEffect(() => {
    getDomains()
    getUsers({
      variables: { name: searchTerm.replace('.eth', '') }
    })
  }, [searchTerm])

  const searchResult = useMemo(() => {
    const { data: usersData } = getUsersResponse

    if (isLoading) {
      return {
        loading: true
      }
    }

    const results = {
      loading: false,
      marketDomains: [],
      registryDomains: [],
      users: [],
      categories: []
    }
    if (marketDomains.length > 0) {
      results['marketDomains'] = marketDomains
    }
    if (registryDomains.length > 0) {
      results['registryDomains'] = differenceBy(
        registryDomains,
        marketDomains,
        domain => domain.name
      )
    }

    let cats = []
    marketDomains.forEach(domain => {
      cats = [...cats, ...(domain.taxonomies || [])]
    })
    registryDomains.forEach(domain => {
      cats = [...cats, ...(domain.taxonomies || [])]
    })
    results['categories'] = uniq(cats).map((cat, index) => ({
      name: cat,
      color: MAIN_CATEGORY_COLORS[index % 9]
    }))

    if (usersData) {
      const _users = {}
      ;(usersData?.users || []).forEach(ui => {
        if (ui.id && !_users.hasOwnProperty(ui.id.toLowerCase())) {
          _users[ui.id.toLowerCase()] = {
            id: ui.id.toLowerCase(),
            item_name: ui.item_name.toLowerCase(),
            item_type: ui.item_type
          }
        }
      })
      results['users'] = Object.values(_users)
    }

    isLoading = false
    return results
  }, [marketDomains, registryDomains, getUsersResponse])

  const cancelSearch = () => {
    setIsFocused(false)
  }

  const closeModal = () => {
    setSearchTerm('')
    setIsFocused(false)
  }

  const handleSearchIconClick = () => {
    setIsFocused(true)
  }

  const handleSearchBarChange = e => {
    isLoading = true
    const text = e.target.value
    setSearchTerm(text)
  }

  const handleSearchBarKeyDown = e => {
    if (e.key === 'Escape') {
      if (searchTerm.length > 2) {
        setSearchTerm('')
      } else if (!searchTerm.length) {
        cancelSearch()
      }
    }
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, closeModal)

  return (
    <div ref={wrapperRef}>
      {/* TODO: Create an interactive search bar component */}
      <View
        ref={searchBarContainerRef}
        zIndex={isFocused ? 2 : 0}
        maxWidth="60px"
        transition="all 0.45s ease"
        alignItems="center"
      >
        <SearchIcon color={color} onClick={handleSearchIconClick} />

        <div
          ref={searchBarWrapperRef}
          style={{
            padding: '0 20px 0 0',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <SearchbarComponent
            color={color}
            ref={inputRef}
            value={searchTerm}
            disabled={!isFocused}
            onChange={handleSearchBarChange}
            onKeyDown={handleSearchBarKeyDown}
          />
          {searchTerm.length > 2 && (
            <Link onClick={cancelSearch}>
              <Label
                size={16}
                color="#BCBCCC"
                transition="0.2s ease"
                opacity={1}
              >
                Esc
              </Label>
            </Link>
          )}
        </div>
      </View>

      {isFocused && searchTerm.length > 2 && (
        <SearchResultModal
          data={searchResult}
          searchTerm={searchTerm}
          categoryList={categoryList}
          closeModal={closeModal}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

export default QuickSearch

const SearchbarComponent = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  color: ${({ color }) => color};
  font-family: 'Satoshi', sans-serif;
  background: transparent;
  height: 40px;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  width: 151px;
  caret-color: #a14f9d;
  margin-right: 3px;
  visibility: ${props => (props.disabled ? 'hidden' : 'initial')};
`

const classifyDomains = domains => {
  const now = new Date().getTime() / 1000

  const domainsWithCategories = []
  const domainNamesWithCategories = []
  const categories = []

  for (let i = 0; i < domains.length; i++) {
    let dcs = (domains[i]['domains_taxonomies'] || []).map(tx => tx.taxonomy)
    dcs = dcs.filter(tx => !categories.includes(tx))

    if (dcs.length) {
      categories.push(...dcs)
      domainsWithCategories.push(domains[i])
      domainNamesWithCategories.push(domains[i].name)

      if (categories.length >= 2) {
        break
      }
    }
  }

  const domainsNotSelected = domains.filter(
    domain => !domainNamesWithCategories.includes(domain.name)
  )
  const sortedDomains = [...domainsWithCategories, ...domainsNotSelected]

  const registered = sortedDomains.filter(d => Number(d.expires) > now)
  const unregistered = sortedDomains.filter(
    d => !d.expires || Number(d.expires) < now
  )

  return {
    registered,
    unregistered,
    categories: categories.slice(0, 2).map((c, id) => ({
      key: c,
      name: c,
      color: CATEGORY_COLORS[id]
    }))
  }
}
