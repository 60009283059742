import { useMediaMax } from '../../../../mediaQuery'
import React, { useEffect, useState } from 'react'
import Card from 'components/Card'
import View from 'components/View'
import Label from 'components/Label'
import styled from '@emotion/styled/macro'
import { useHistory } from 'react-router'

const SeeMoreCard = ({ subject, icon }) => {
  const history = useHistory()
  const smallBP = useMediaMax('medium')
  const [seeMoreStyle, setSeeMoreStyle] = useState({
    width: 310,
    height: 330,
    style: { position: 'absolute', right: 0, bottom: 0 }
  })
  const [cardMargin, setCardMargin] = useState('10px')

  useEffect(() => {
    if (smallBP) {
      setCardMargin('8px 0')
      setSeeMoreStyle({
        width: 248,
        height: 242,
        style: { position: 'absolute', right: 0, top: 0 }
      })
    } else {
      setCardMargin('10px')
      setSeeMoreStyle({
        width: 310,
        height: 330,
        style: { position: 'absolute', right: 0, bottom: 0 }
      })
    }
  }, [smallBP])

  const handleGoCategoryPage = () => {
    history.push('/categories')
  }

  return (
    <Card
      width="100%"
      onClick={handleGoCategoryPage}
      height="360px"
      margin={cardMargin}
      hasShadowOnHover
      cursor="pointer"
    >
      <CardContent background="#0698A8">
        <img src={icon} alt={subject} {...seeMoreStyle} />
        <View flex={1} />
        <View width="100%" padding="0px 40px">
          <Label size={18} color="#CAD0D2">
            {subject}
          </Label>
        </View>
      </CardContent>
    </Card>
  )
}

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  background: ${props => (props.background ? props.background : '#fff')};
  border: 1px solid #ebebf2;
  border-radius: 6px;
  transition: background 0.2s ease-out;

  :hover {
    background: ${props =>
      props.hoverBackground ? props.hoverBackground : '#AF61AB'};

    svg {
      transition: stroke 0.5s ease-out;
      stroke: #fff;
    }
  }
`

export default SeeMoreCard
