import styled from '@emotion/styled'
import BottomModal from 'components/BottomModal'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import Space from 'components/Space'
import View from 'components/View'
import { useCallback, useState } from 'react'
import CompleteModal from './CompleteModal'
import FileDropzone from './SettingDialog/components/FileDropzone'
import RequestSelect from './SettingDialog/components/RequestSelect'
import SaveButton from './SettingDialog/components/SaveButton'
import SettingTextArea from './SettingDialog/components/SettingTextArea'
import SettingsInput from './SettingDialog/components/SettingsInput'

const emailRegExp = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const REQUEST_REASONS = [
  {
    value: 'transaction_issue',
    label: 'Transaction issue'
  },
  {
    value: 'site_bug',
    label: 'Site bug'
  },
  {
    value: 'other',
    label: 'Other'
  }
]

const RequestModal = ({ open, onClose, successExitButtonLabel, isInner }) => {
  const [emailAddress, setEmailAddress] = useState('')
  const [requestReason, setRequestReason] = useState(REQUEST_REASONS[0])
  const [subject, setSubject] = useState('')
  const [description, setDescription] = useState('')
  const [images, setImages] = useState([])
  const [submitPressed, setSubmitPressed] = useState(false)
  const [emailError, setEmailError] = useState(null)

  const [completeModalOpened, setCompleteModalOpened] = useState(false)

  const onDrop = acceptedFiles => {
    setImages([...images, ...acceptedFiles].slice(0, 3))
  }

  const removeImage = file => {
    const newImages = [...images]
    newImages.splice(newImages.indexOf(file), 1)
    setImages(newImages)
  }

  const removeAll = () => {
    setImages([])
  }

  const handleEmailAddressChange = e => {
    setEmailAddress(e.target.value)
    if (submitPressed) {
      validateEmail(e.target.value)
    }
  }

  const handleRequestReasonChange = reason => {
    setRequestReason(reason)
  }

  const handleSubjectChange = e => {
    setSubject(e.target.value)
  }

  const handleDescriptionChange = e => {
    setDescription(e.target.value)
  }

  const dataIsValid =
    emailAddress.trim().length !== 0 &&
    subject.trim().length !== 0 &&
    description.trim().length !== 0 &&
    emailError == null

  const submitRequest = () => {
    const valid = validateEmail(emailAddress)
    setSubmitPressed(true)
    if (valid) {
      openCompleteModal()
      // console.log('Submit a request')
    }
  }

  const validateEmail = email => {
    if (email.match(emailRegExp)) {
      setEmailError(null)
      return true
    } else {
      setEmailError('WRONG ADDRESS')
      return false
    }
  }

  const openCompleteModal = () => {
    setCompleteModalOpened(true)
  }

  const closeCompleteModal = useCallback(() => {
    setCompleteModalOpened(false)
  }, [onClose])

  return (
    <>
      <BottomModal
        open={open}
        onClose={onClose}
        contentPadding="0"
        modalId={isInner ? 'inner-modal-root' : 'modal-root'}
        contentBackground="#fff"
      >
        <Container maxWidth="940px">
          <View
            padding="120px 0 0"
            justify="center"
            direction="column"
            alignItems="flex-start"
            width="100%"
            height="100%"
          >
            <Label size={60} color="#020202">
              REQUEST
            </Label>
            <Space size={60} />
            <View width="100%">
              <ContentContainer>
                <View>
                  <View direction="column" width="100%">
                    <EmailInputWrapper>
                      <SettingsInput
                        placeholder="your email address"
                        value={emailAddress}
                        onChange={handleEmailAddressChange}
                        padding={`22px ${
                          emailError == null ? '0' : '130px'
                        } 22px 30px`}
                        border={`1px solid ${
                          emailError == null ? '#E1E1E8' : '#E5CACF'
                        }`}
                        background={emailError == null ? '#fff' : '#FAF2F4'}
                      />
                      {emailError && (
                        <ErrorLabel>
                          <Label color="#B56F7C" size={13}>
                            {emailError}
                          </Label>
                        </ErrorLabel>
                      )}
                    </EmailInputWrapper>
                    <Space size={20} />
                    <RequestSelect
                      data={REQUEST_REASONS}
                      onChange={handleRequestReasonChange}
                      selectedItem={requestReason}
                    />
                    <Space size={20} />
                    <SettingsInput
                      placeholder="subject"
                      value={subject}
                      onChange={handleSubjectChange}
                      padding="22px 0 22px 30px"
                      border="1px solid #E1E1E8"
                    />
                    <Space size={10} />
                    <SettingTextArea
                      placeholder="description"
                      value={description}
                      onChange={handleDescriptionChange}
                      width="100%"
                      height="210px"
                    />
                    <Space size={40} />
                    <SaveButton onClick={submitRequest} disabled={!dataIsValid}>
                      SUBMIT A REQUEST
                    </SaveButton>
                    <Space size={110} />
                  </View>
                  <Space size={20} />
                </View>
              </ContentContainer>
              <FileDropzone
                images={images}
                onDrop={onDrop}
                removeAllImages={removeAll}
                removeImage={removeImage}
              />
            </View>
          </View>
        </Container>
      </BottomModal>
      {completeModalOpened && (
        <CompleteModal
          open={completeModalOpened}
          onClose={closeCompleteModal}
          exitBugRequest={onClose}
          exitButtonLabel={successExitButtonLabel}
          parentIsInner={isInner}
        />
      )}
    </>
  )
}

const EmailInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const ErrorLabel = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
`

const ContentContainer = styled.div`
  width: 100%;
  height: calc(100vh - 300px);
  overflow: auto;
`

export default RequestModal
