import React from 'react'
import styled from '@emotion/styled/macro'
import { ReactComponent as Done } from 'assets/done.svg'

export const Checkbox = ({ isChecked, onCheck, className, isRadio }) => {
  return (
    <Container onClick={onCheck} className={className}>
      {isChecked &&
        (isRadio ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '6px',
              height: '6px',
              borderRadius: '6px',
              background: 'black'
            }}
          />
        ) : (
          <Done />
        ))}
    </Container>
  )
}

const Container = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #e1e1e8;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`
