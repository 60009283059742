export const REGISTRATION_STATUS = {
  GRACE_PERIOD: 'grace period',
  RECENTLY_EXPIRED: 'recently expired',
  NEW: 'new'
}

export const getRegistrationStatus = expiration => {
  // check for undefined on 0 expirations
  if (!expiration) {
    return undefined
  }

  const expirationDate = new Date(parseInt(expiration) * 1000)
  const now = new Date()

  const getFutureDate = days => {
    const futureDate = new Date(expirationDate)
    futureDate.setDate(futureDate.getDate() + days)
    return futureDate
  }

  if (now > getFutureDate(149)) {
    return undefined
  }

  if (now > getFutureDate(119)) {
    return REGISTRATION_STATUS.NEW
  }

  if (now > getFutureDate(90)) {
    return REGISTRATION_STATUS.RECENTLY_EXPIRED
  }

  if (now > expirationDate) {
    return REGISTRATION_STATUS.GRACE_PERIOD
  }

  return undefined
}
