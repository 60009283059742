import { JETTY_URL } from './constants'

export function verifySiwe(message, signature) {
  return fetch(`${JETTY_URL}/verify`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ message, signature })
  })
}
