import React from 'react'
import styled from '@emotion/styled/macro'
import KodexIcon from '../KodexIcon'

const TableIcon = ({
  icon,
  background,
  stroke,
  fill,
  size,
  borderRadius,
  padding
}) => {
  return (
    <TableIconComponent>
      <KodexIcon
        icon={icon}
        background={background}
        stroke={stroke}
        fill={fill}
        size={size}
        borderRadius={borderRadius}
        padding={padding}
      />
    </TableIconComponent>
  )
}

const TableIconComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default TableIcon
