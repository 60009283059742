import styled from '@emotion/styled'
import PhotoPlaceholder from 'assets/no-result-transparent.png'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import useDomainPriceHistory from 'hooks/useDomainPriceHistory'
import { useEffect, useRef, useState } from 'react'
import { DurationAccordion } from '../DurationAccordion'
import getPriceHistoryChart from './getPriceHistoryChart'

const DURATION_OPTIONS = [
  { label: 'All time', value: 'all' },
  { label: 'Year', value: 'year', duration: 365 },
  { label: 'Month', value: 'month', duration: 30 },
  { label: 'Week', value: 'week', duration: 7 }
]

const PriceHistory = ({
  domainName,
  isRegistered = true,
  height,
  whiteMode
}) => {
  const chartRef = useRef()
  const [chart, setChart] = useState(false)
  const [duration, setDuration] = useState(DURATION_OPTIONS[0])
  const [initChartAnimationFinished, seInitChartAnimationFinished] = useState(
    false
  )
  const [durationAccordionExpanded, setDurationAccordionExpanded] = useState(
    false
  )

  const { priceHistory } = useDomainPriceHistory(domainName, duration.duration)

  useEffect(() => {
    if (initChartAnimationFinished || priceHistory.length === 0) {
      return
    }
    const callback = entries => {
      const [entry] = entries
      if (entry.isIntersecting && !initChartAnimationFinished) {
        seInitChartAnimationFinished(true)
        chart.update('show')
      }
    }

    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: '0px 0px -200px 0px',
      threshold: [0, 0]
    })

    if (chartRef.current != null && !initChartAnimationFinished) {
      observer.observe(chartRef.current)
    }
    return () => {
      if (chartRef.current != null) {
        observer.unobserve(chartRef.current)
      }
    }
  }, [initChartAnimationFinished, chart, priceHistory])

  useEffect(() => {
    if (priceHistory.length === 0) {
      setChart(null)
      return
    }

    const data = priceHistory.map((event, index, array) => {
      const previousPrice = index === 0 ? 0 : array[index - 1].price
      return [Number(previousPrice), Number(event.price)]
    })

    const labels = priceHistory.map(event => new Date(event.timestamp))

    const getBgColor = context => {
      const i = context.index
      const previousPrice = context.raw[0]
      const currentPrice = context.raw[1]
      if (i === 0 || currentPrice > previousPrice) {
        return '#0698A8'
      }
      return '#AF61AB'
    }
    if (chart) {
      chart.data.labels = labels
      chart.data.datasets[0].data = data
      chart.data.datasets[0].backgroundColor = getBgColor

      if (initChartAnimationFinished) {
        chart.update()
      } else {
        chart.update('hide')
      }
    } else {
      const _chart = getPriceHistoryChart(
        data,
        labels,
        getBgColor,
        !initChartAnimationFinished,
        isRegistered
      )
      setChart(_chart)
    }
  }, [initChartAnimationFinished, priceHistory])

  return (
    <View
      height={height}
      flex={1}
      borderRadius="10px"
      border={`1px solid ${whiteMode ? '#CACADB' : '#4A4A4D'}`}
      direction="column"
      overflow="hidden"
    >
      <View
        width="100%"
        justify="space-between"
        alignItems="center"
        padding="40px 40px 0 40px"
      >
        <Label
          color={whiteMode ? '#020202' : 'white'}
          size={16}
          zIndex={0}
          weight={600}
        >
          Price history
        </Label>
        <View width="145px" zIndex={1}>
          <DurationAccordion
            selectedItem={duration}
            onChange={setDuration}
            content={DURATION_OPTIONS}
            isRegistered={isRegistered}
            whiteMode={whiteMode}
            contentExpanded={durationAccordionExpanded}
            setContentExpanded={setDurationAccordionExpanded}
          />
        </View>
      </View>
      {priceHistory.length === 0 ? (
        <View
          width="100%"
          height="100%"
          direction="column"
          alignItems="center"
          justify="center"
          gap="1rem"
        >
          <img
            src={PhotoPlaceholder}
            height={150}
            width={150}
            alt="No result image"
          />
          <Label size={16} color="#BCBCCC">
            No data yet
          </Label>
        </View>
      ) : (
        <CanvasWrapper
          ref={chartRef}
          style={{
            width: '100%',
            height: '100%'
            // reason for using setting via style https://github.com/chartjs/Chart.js/issues/5805#issuecomment-1111618891
          }}
        >
          <Space size={20} />
          <View height="305px" width="100%" margin="0 0 0 23px">
            <canvas id="myChart" />
          </View>
          <Space size={15} />
        </CanvasWrapper>
      )}
    </View>
  )
}

const CanvasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 60px 0 0;
`

export default PriceHistory
