import Avatar from 'components/Avatar'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import React from 'react'
import styled from '@emotion/styled/macro'
import DeafultAvatarImg from 'assets/icon-pfp-default.png'

const MessageItemAvatar = styled(Avatar)`
  min-width: 40px;
`

/**
 * We only need the time next to the message.
 * the date will be mentioned above each group of messages.
 *
 * @param {Date} date - date to be formatted
 * @returns {string} - formatted date
 */
const formatDate = date => {
  if (!date) {
    return undefined
  }

  return `${date.toLocaleString('en-us', {
    hour: 'numeric',
    minute: '2-digit'
  })}`
}

const MessageItem = ({ message }) => {
  return (
    <View width="100%" justify="space-between" alignItems="center">
      <View alignItems="end">
        <MessageItemAvatar
          background="#E6EAF4"
          src={message.user.avatar || DeafultAvatarImg}
          borderRadius="6px"
        />
        <Space size={10} />
        <View
          borderRadius="6px"
          height="70px"
          background="#fff"
          alignItems="center"
          justify="center"
          padding="20px"
          style={{ maxWidth: 400, marginRight: 20 }}
        >
          <Label size={14} color="#020202">
            {message.content}
          </Label>
        </View>
      </View>
      <Label
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        size={13}
        color="#BCBCCC"
      >
        {formatDate(message.createdAt)}
      </Label>
    </View>
  )
}

export default MessageItem
