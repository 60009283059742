import React from 'react'
import styled from '@emotion/styled/macro'

const IconWrapper = ({ children, stroke, fill, size }) => {
  return (
    <IconWrapperComponent stroke={stroke} fill={fill} size={size}>
      {children}
    </IconWrapperComponent>
  )
}

const IconWrapperComponent = styled.div`
  display: flex;
  width: ${props => (props.size ? `${props.size}px` : '20px')};
  height: ${props => (props.size ? `${props.size}px` : '20px')};
  transition: 0.2s linear;

  svg {
    width: ${props => (props.size ? `${props.size}px` : '20px')};
    height: ${props => (props.size ? `${props.size}px` : '20px')};
    fill: ${props => props.fill};
    stroke: ${props => props.stroke};
    transition: 0.2s linear;

    path {
      stroke: ${props => props.stroke};
    }
  }
`

export default IconWrapper
