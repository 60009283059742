import { ens_normalize } from '@adraffy/ens-normalize'
import { useLazyQuery, useQuery } from '@apollo/client'
import { ReactComponent as FiltersIcon } from 'assets/filters-mobile.svg'
import BottomModal from 'components/BottomModal'
import CategoryFilterModal from 'components/CategoryFilterModal'
import { OfferDialog } from 'components/Dialogs'
import HeaderBar from 'components/HeaderBar'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import PremiumRegistry from 'components/PremiumRegistry'
import ScrollUpButton from 'components/ScrollUpButton'
import Searchbar from 'components/Search'
import KodexSidebar from 'components/Sidebar'
import SingleRegistration from 'components/SingleRegistration'
import Space from 'components/Space'
import StickyToolbar from 'components/StickyToolBar'
import View from 'components/View'
import { FilterContext } from 'contexts/FilterProvider'
import useDebounce from 'hooks/useDebounce'
import useGetUnRegisteredDomains from 'hooks/useGetUnRegisteredDomains'
import queryString from 'query-string'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  GET_DOMAINS_AMOUNT,
  GET_DOMAINS_BY_TOKENID,
  kodexApiContext
} from 'service/graphql/kodex-api'
import {
  getRegistryDefaultDomains,
  getRegistrySearchDomains
} from 'service/rest/getRegistryDomains'
import moment from 'moment'
import { Footer } from '../Home/components'
import DomainTable from './DomainTable'
import StickySearchbar from './SearchBar/StickySearchbar'

let initialFiltersChanged = false

const Registry = () => {
  const { search: searchParam } = useParams()

  const location = useLocation()

  const pageRef = useRef(null)

  const { domainsCount } = useGetUnRegisteredDomains()

  const history = useHistory()

  const [searchDomain, setSearchDomain] = useState(location.state?.search)
  // const [viewType, setViewType] = useState('CARD')
  const [categoriesModalOpen, setCategoriesModalOpen] = useState(false)
  const debouncedSearchDomain = useDebounce(searchDomain, 500)
  const [allDomainsFetched, setAllDomainsFetched] = useState(false)
  const [offerDialogOpen, setOfferDialogOpen] = useState(false)
  const [offeredDomainName, setOfferedDomainName] = useState('')
  const [offeredDomainlastPrice, setOfferedDomainLastPrice] = useState('')
  const [domains, setDomains] = useState([])
  const [registrationDomainName, setRegistrationDomainName] = useState('')
  const [domainExpiresTime, setDomainExpiresTime] = useState(0)
  const [registrationDialogOpen, setRegistrationDialogOpen] = useState(false)
  const [domainIsPremium, setDomainIsPremium] = useState(false)
  const [loading, setLoading] = useState(true)
  const [filtersClosing, setFiltersClosing] = useState(false)
  const [filtersOpening, setFiltersOpening] = useState(false)

  const {
    registryFilter: filter,
    setRegistryFilter: setFilter,
    openedRegistry: opened,
    toggleOpenRegistry: toggleOpen,
    activeCurrency,
    setActiveCurrency,
    setDarkMode,
    viewType,
    setViewType,
    savedDomainPosition,
    setSavedDomainPosition
  } = useContext(FilterContext)

  const [noSearchFilter, setNoSearchFilter] = useState(
    filter.search
      ? [...Object.values(filter)].filter(item => item !== undefined)
      : [filter.search, ...Object.values(filter)]
  )

  const sanitizedSearchParam = searchParam
    ? searchParam
        .trim()
        .toLowerCase()
        .replace('.eth', '')
    : null

  const [committedDomains, setCommittedDomains] = useState([])

  useEffect(() => {
    let savedCommittedDomains = JSON.parse(
      localStorage.getItem('committed_domains')
    )
    if (!savedCommittedDomains) return
    savedCommittedDomains = savedCommittedDomains.filter(obj => {
      return !moment(obj.expire_time).isBefore(moment())
    })
    localStorage.setItem(
      'committed_domains',
      JSON.stringify(savedCommittedDomains)
    )
  }, [committedDomains])

  useEffect(() => {
    let savedCommittedDomains = JSON.parse(
      localStorage.getItem('committed_domains')
    )
    if (!savedCommittedDomains) return
    savedCommittedDomains = savedCommittedDomains.filter(obj => {
      return !moment(obj.expire_time).isBefore(moment())
    })
    localStorage.setItem(
      'committed_domains',
      JSON.stringify(savedCommittedDomains)
    )
  }, [committedDomains])

  useEffect(() => {
    const savedCommittedDomains = JSON.parse(
      localStorage.getItem('committed_domains')
    )

    if (savedCommittedDomains) {
      setCommittedDomains(
        savedCommittedDomains.filter(
          obj => !moment(obj.expire_time).isBefore(moment())
        )
      )
    }
    if (filter.search) history.push(`/registry/${filter.search}`)
    var filtersObj
    if (Object.keys(filter).length === 0) {
      filtersObj = {
        limit: 40,
        status: 'new'
      }
    } else {
      filtersObj = filter
    }
    if (location.search) {
      const parsed = queryString.parse(location.search)
      if (parsed.search) {
        filtersObj = Object.assign(filtersObj, {
          limit: 40,
          status: 'new',
          search: parsed.search
        })
        setSearchDomain(parsed.search)
        return
      }
    }
    setFilter(filtersObj)
  }, [])

  const [getDomain] = useLazyQuery(GET_DOMAINS_BY_TOKENID, {
    ...kodexApiContext
  })

  const fetchDomains = useCallback(async () => {
    setLoading(true)
    if (filter?.search?.length > 0 || sanitizedSearchParam) {
      const fetchedDomains = await getRegistrySearchDomains({
        ...filter,
        search: sanitizedSearchParam,
        clickedOffset: savedDomainPosition.registry.offset
      })
      setDomains([...fetchedDomains])
      setLoading(false)
      return
    }

    const fetchedDomains = await getRegistryDefaultDomains({
      ...filter,
      clickedOffset: savedDomainPosition.registry.offset
    })
    setDomains(fetchedDomains?.domains)
    setLoading(false)
  }, [filter, searchDomain, searchParam])

  useEffect(() => {
    // setDomains([])
    fetchDomains()
  }, [noSearchFilter])

  useEffect(() => {
    fetchDomains()
  }, [
    savedDomainPosition.registry.offset,
    savedDomainPosition.registry.clickedDomain
  ])

  const loadMoreDomains = async () => {
    if (!domains || domains?.length % 40 > 0) return

    if (filter?.search?.length > 0) {
      const fetchedDomains = await getRegistrySearchDomains({
        ...filter,
        offset: domains.length
      })

      const moreDomains = [...domains, ...fetchedDomains]

      setDomains(moreDomains)
      return
    }

    const fetchedDomains = await getRegistryDefaultDomains({
      ...filter,
      offset: domains.length
    })

    const moreDomains = [...domains, ...fetchedDomains.domains]

    setDomains(moreDomains)
  }

  useEffect(() => {
    const newSavedPositions = Object.assign(savedDomainPosition, {
      registry: { ...savedDomainPosition.registry, domains }
    })
    setSavedDomainPosition(newSavedPositions)
  }, [domains])

  const refetchDomains = async () => {
    scrollToTop()
    fetchDomains()
  }

  const { data: numOfDomains } = useQuery(GET_DOMAINS_AMOUNT, {
    ...kodexApiContext
  })

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant'
    })

    document.title = 'Registry | Kodex'
  }, [])

  useEffect(() => {
    if (domains.length >= numOfDomains?.domains_aggregate?.aggregate.count)
      setAllDomainsFetched(true)
  }, [domains])

  const scrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: window.innerWidth < 740 ? 50 : 175,
      behavior: 'smooth'
    })
  }

  const handleOpenModal = useCallback(() => {
    setCategoriesModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(categories => {
    setCategoriesModalOpen(false)
  }, [])

  useEffect(() => {
    // setActiveCurrency('USDC')
    setDarkMode(true)
  }, [])

  useEffect(() => {
    if (debouncedSearchDomain) {
      setFilter(filters => ({
        ...filters,
        search: debouncedSearchDomain
      }))
    }
  }, [debouncedSearchDomain])

  const handleOpenFilters = useCallback(() => {
    setDarkMode(true)
    toggleOpen()
  }, [])

  const handleHideFilters = useCallback(() => {
    setDarkMode(false)
    toggleOpen()
  }, [])

  const handleStatusChange = status => {
    if (!initialFiltersChanged) {
      initialFiltersChanged = true
    }

    setFilter(filter => ({
      ...filter,
      status
    }))

    scrollToTop()
  }

  const handleLengthChange = params => {
    if (!initialFiltersChanged) {
      initialFiltersChanged = true
      handleStatusChange(undefined)
    }
    setFilter(filter => ({
      ...filter,
      minLength: params[0],
      maxLength: params[1]
    }))
    scrollToTop()
  }

  const handlePriceChange = prices => {
    if (!initialFiltersChanged) {
      initialFiltersChanged = true
      handleStatusChange(undefined)
    }
    setFilter(filter => ({
      ...filter,
      minPrice: prices[0] > 0 ? Number(prices[0]) : null,
      maxPrice: prices[1] > 0 ? Number(prices[1]) : null
    }))
    scrollToTop()
  }

  const handleSortByChange = sort => {
    if (!initialFiltersChanged) {
      initialFiltersChanged = true
      handleStatusChange(undefined)
    }
    setFilter(filter => ({
      ...filter,
      sort
    }))
    scrollToTop()
  }

  const handleChangeCategories = subCategories => {
    if (!initialFiltersChanged) {
      initialFiltersChanged = true
      handleStatusChange(undefined)
    }
    setFilter(filter => ({
      ...filter,
      subCategories
    }))
    scrollToTop()
  }

  const handleChangeResult = result => {
    if (!initialFiltersChanged) {
      initialFiltersChanged = true
      handleStatusChange(undefined)
    }
    setFilter(filter => ({
      ...filter,
      result
    }))
    scrollToTop()
  }

  const handleChangeType = type => {
    if (!initialFiltersChanged) {
      initialFiltersChanged = true
      handleStatusChange(undefined)
    }
    setFilter(filter => ({
      ...filter,
      type: type.length > 0 ? type : undefined
    }))
    scrollToTop()
  }

  const handleResetFilter = () => {
    setFilter({
      limit: 40,
      status: 'new'
    })
    setSavedDomainPosition({
      ...savedDomainPosition,
      registry: { domains: [], clickedDomain: '', offset: 0 }
    })
    scrollToTop()
  }

  const handleViewChange = type => {
    setDomains(domains.slice(0, 40))

    setViewType({
      ...viewType,
      registry: { view: type }
    })
    scrollToTop()
  }

  useEffect(() => {
    if (location.search) {
      const parsed = queryString.parse(location.search)
      if (parsed.search) {
        setFilter(filters => ({
          ...filters,
          search: parsed.search
        }))
        setSearchDomain(parsed.search)
      }
    }
  }, [location.search])

  useEffect(() => {
    if (searchParam) {
      setLoading(true)
      onInputChange(sanitizedSearchParam)
      fetchDomains()
    }
  }, [searchParam])

  const searchDomains = () => {
    if (!searchDomain || searchDomain.trim().length === 0) {
      history.push('/registry')
      return
    }
    setLoading(true)
    history.push(
      `/registry/${searchDomain
        .trim()
        .toLowerCase()
        .replace('.eth', '')}`
    )
  }

  const searchHeaderBarDomains = name => {
    if (name.trim().length === 0) {
      history.push('/registry')
      return
    }
    setLoading(true)
    history.push(
      `/registry/${name
        .trim()
        .toLowerCase()
        .replace('.eth', '')}`
    )
  }

  const onInputChange = useCallback(val => {
    const inputText = val?.trim().toLowerCase()
    if (inputText === '' || !inputText) {
      setFilter(filters => ({
        ...filters,
        search: ''
      }))
      setSearchDomain('')
      return
    }

    setSavedDomainPosition({
      ...savedDomainPosition,
      registry: { ...savedDomainPosition.registry, offset: 0 }
    })

    if (!initialFiltersChanged) {
      initialFiltersChanged = true
      // setFilter({
      //   ...filter,
      //   status: undefined
      // })
    }

    const inputNormalized = ens_normalize(inputText)
    if (!inputNormalized) return
    setSearchDomain(inputNormalized)

    if (window.scrollY > 170) {
      window.scrollTo({
        left: 0,
        top: 170,
        behavior: 'smooth'
      })
    }
  }, [])

  const handleOrderByChange = value => {
    setFilter({
      ...filter,
      rawOrderType: value
    })
  }

  const onInputClear = useCallback(() => {
    setSearchDomain('')
    setFilter(filters => ({
      ...filters,
      search: ''
    }))
  }, [])

  const handleOpenRegistrationDialog = (domainName, isPremium, expires) => {
    setRegistrationDomainName(domainName)
    setDomainExpiresTime(expires)
    setDomainIsPremium(isPremium)
    setRegistrationDialogOpen(true)
  }

  const handleOpenOfferDialog = (name, lastPrice) => {
    setOfferedDomainName(name)
    setOfferedDomainLastPrice(lastPrice)
    setOfferDialogOpen(true)
  }

  const hideFiltersHandler = () => {
    if (filtersOpening || filtersClosing) return
    setFiltersClosing(true)
    handleOpenFilters()
    setTimeout(() => {
      setFiltersClosing(false)
    }, 400)
  }

  const openFiltersHandler = () => {
    if (filtersOpening || filtersClosing) return
    setFiltersOpening(true)
    handleOpenFilters()
    setTimeout(() => {
      setFiltersOpening(false)
    }, 400)
  }

  const viewTypeValue = viewType.registry.view

  return (
    <>
      <KodexSidebar
        positionDialogCenter={true}
        opened={offerDialogOpen}
        onClose={() => setOfferDialogOpen(false)}
      >
        <OfferDialog
          onClose={() => setOfferDialogOpen(false)}
          domain={{
            name: offeredDomainName,
            lastPrice: offeredDomainlastPrice
          }}
          backLabel="registry"
        />
      </KodexSidebar>
      <BottomModal
        open={registrationDialogOpen}
        onClose={() => setRegistrationDialogOpen(false)}
        closeButtonLabel="Back to registry"
        contentPadding="0"
      >
        {registrationDialogOpen && domainIsPremium ? (
          <PremiumRegistry
            domain={{
              name: registrationDomainName,
              expires: domainExpiresTime
            }}
            closeLabel="Back to registry"
            closeRegistration={() => setRegistrationDialogOpen(false)}
            refetchState={() => {
              history.push(`/registry`)
            }}
          />
        ) : (
          registrationDialogOpen && (
            <SingleRegistration
              committedDomains={committedDomains}
              setCommittedDomains={setCommittedDomains}
              domain={{
                name: registrationDomainName
              }}
              closeRegistration={() => {
                setRegistrationDialogOpen(false)
              }}
              closeLabel="Back to Registry"
              refetchState={() => {
                history.push(`/registry`)
              }}
            />
          )
        )}
      </BottomModal>
      <View
        direction="column"
        width="100%"
        padding={window.innerWidth < 740 ? '0 16px' : ''}
        background="#000"
        minHeight="100vh"
      >
        {window.innerWidth < 740 ? (
          <View padding="100px 0 0 0" width="100%">
            <Searchbar
              placeholder="Search"
              searchOnEnter
              searchDomains={searchDomains}
              onChange={onInputChange}
              onClear={onInputClear}
              value={searchDomain || ''}
            />
          </View>
        ) : (
          <StickySearchbar
            searchOnEnter
            searchDomains={searchDomains}
            onInputChange={onInputChange}
            onInputClear={onInputClear}
            searchDomain={searchDomain}
          />
        )}
        {window.innerWidth > 740 && (
          <ScrollUpButton scrollToPosition={250} dark />
        )}
        <Container
          ref={pageRef}
          maxWidth={window.innerWidth < 740 ? '100%' : 'calc(100% - 140px)'}
          background="#000"
        >
          {window.innerWidth < 740 ? <Space size={0} /> : <Space size={103} />}
          {/* <View height="170px">
            <View wrappable>
              <AllCategoriesButton onClick={handleOpenModal}>
                {selectedCategories.length === TAGS.length
                  ? 'ALL CATEGORIES'
                  : 'SELECTED:'}
              </AllCategoriesButton>
              {selectedCategories.length === TAGS.length ? (
                <div />
              ) : (
                selectedCategories.map(selectedCategory => (
                  <Tag color={selectedCategory.color}>
                    {selectedCategory.name}
                  </Tag>
                ))
              )}
            </View>
          </View> */}

          <View width="100%" direction="column">
            {window.innerWidth < 740 ? (
              <View
                height="30px"
                width="100%"
                justify="space-between"
                alignItems="center"
                margin="30px 0 10px 0"
              >
                <View
                  alignItems="center"
                  gap="8px"
                  cursor="pointer"
                  onClick={handleOpenFilters}
                >
                  <FiltersIcon />
                  <Label size={16} color="white">
                    Filters
                  </Label>
                </View>
                <View alignItems="center" gap="8px">
                  <Label size={16} color="#A6A6A6">
                    {domainsCount?.domains?.aggregate?.count} domains
                  </Label>
                </View>
              </View>
            ) : (
              <HeaderBar
                from="registry"
                filtersOpened={opened}
                darkMode={true}
                searchOnEnter
                onSearchChange={searchHeaderBarDomains}
                viewType={viewTypeValue}
                onOrderByChange={handleOrderByChange}
                onViewTypeChange={handleViewChange}
                onSortByChange={handleSortByChange}
                domainCount={numOfDomains?.domains_aggregate?.aggregate?.count}
                refreshData={() => refetchDomains(filter)}
                searchDomain={searchDomain}
                onHideFilters={hideFiltersHandler}
                onOpenFilters={openFiltersHandler}
              />
            )}
            <View flex={1} width="100%">
              <StickyToolbar
                ref={pageRef}
                filtersOpened={opened}
                domainCount={domainsCount?.domains?.aggregate?.count}
                onHideFilters={handleHideFilters}
                onOpenFilters={handleOpenFilters}
                subCategories={filter.subCategories}
                onOpenCategoriesModal={handleOpenModal}
                onLengthChange={handleLengthChange}
                onPriceChange={handlePriceChange}
                statusType={filter.status}
                onStatusChange={handleStatusChange}
                onSortByChange={handleSortByChange}
                refreshData={() => refetchDomains()}
                onChangeType={handleChangeType}
                onChangeResult={handleChangeResult}
                onResetFilter={handleResetFilter}
                modalButtonTitle="Categories"
                page="registry"
                viewType={viewTypeValue}
                activeCurrency={activeCurrency}
                setActiveCurrency={setActiveCurrency}
                darkMode
              />
              <DomainTable
                committedDomains={committedDomains}
                searchTerm={searchDomain}
                parsedDomains={domains}
                fetchMore={loadMoreDomains}
                viewType={viewTypeValue}
                searchTxt={searchDomain || undefined}
                loading={loading}
                setIsLoading={setLoading}
                filtersOpened={opened}
                onChangeViewType={handleViewChange}
                activeCurrency={activeCurrency}
                openOfferDialog={handleOpenOfferDialog}
                openRegistrationDialog={handleOpenRegistrationDialog}
              />
            </View>
          </View>
          <Space size={120} />
        </Container>
        {allDomainsFetched && <Footer />}
        {categoriesModalOpen && (
          <CategoryFilterModal
            subCategories={filter.subCategories}
            open={categoriesModalOpen}
            onClose={handleCloseModal}
            onApply={handleChangeCategories}
            darkMode
          />
        )}
      </View>
    </>
  )
}

export default Registry
