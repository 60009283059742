import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import styled from '@emotion/styled/macro'
import KodexIcon from '../Icon/KodexIcon'
import Label from '../Label'
import RoundedButton from '../Forms/RoundedButton'
import Space from '../Space'
import View from '../View'
import { ReactComponent as ArrowBottom } from 'assets/arrow-down.svg'
import { ReactComponent as EthPrice } from 'assets/eth-price-disabled.svg'
import { ReactComponent as UsdcPrice } from 'assets/usdc-price-disabled.svg'

const PriceRangeSelector = ({
  currency,
  from,
  to,
  onCurrencyChange,
  onToChanged,
  onFromChanged,
  onSave,
  defaultOpen = false,
  darkMode,
  setActiveCurrency
}) => {
  const [contentExpanded, setContentExpanded] = useState(defaultOpen)

  const contentContainerRef = useRef()
  const contentWrapperRef = useRef()
  const accordionRef = useRef()

  useEffect(() => {
    if (contentExpanded)
      accordionRef.current.scrollIntoView(
        {
          behavior: 'auto',
          block: 'center'
        },
        200
      )
  }, [contentExpanded])

  const onTitlePressed = useCallback(() => {
    if (contentExpanded) {
      contentContainerRef.current.style.maxHeight = 0
      accordionRef.current.style.minHeight = '60px'
    } else {
      contentContainerRef.current.style.maxHeight = `${
        contentWrapperRef.current.clientHeight
      }px`
      accordionRef.current.style.minHeight = `${contentWrapperRef.current
        .clientHeight + 60}px`
    }

    setContentExpanded(!contentExpanded)
  }, [setContentExpanded, contentExpanded])

  const applyPriceRange = () => {
    setActiveCurrency(currency)

    onSave()
  }

  useEffect(() => {
    setActiveCurrency(currency)
  }, [currency])

  if (defaultOpen) {
    return (
      <View
        direction="column"
        width="100%"
        padding="22px 20px 22px 30px"
        zIndex={2}
        background={darkMode ? '#000000' : '#fff'}
        borderRadius="8px"
        border={`1px solid ${darkMode ? '#4A4A4D' : '#E1E1E8'}`}
      >
        <View
          width="100%"
          alignItems="center"
          justify="space-between"
          background="transparent"
        >
          <Title style={{ color: darkMode ? '#fff' : '#020202' }}>Price</Title>
          <View width="170px">
            <CurrencySelector
              darkMode={darkMode}
              value={currency}
              onChange={onCurrencyChange}
            />
          </View>
        </View>
        <Space size={20} />
        <PriceInput label={currency} value={from} onChange={onFromChanged} />
        <Divider />
        <PriceInput label={currency} value={to} onChange={onToChanged} />
      </View>
    )
  }

  return (
    <AccordionComponent
      ref={accordionRef}
      borderColor={darkMode ? '#4A4A4D' : '#E1E1E8'}
      background={Number(from) > Number(to) ? '#666' : '#0698a8'}
    >
      <View
        alignItems="center"
        justify="space-between"
        width="100%"
        padding="22px 20px 22px 30px"
        onClick={onTitlePressed}
        zIndex={2}
        background={darkMode ? '#000000' : '#fff'}
        borderRadius="8px"
        cursor="pointer"
      >
        <Title style={{ color: darkMode ? '#fff' : '#020202' }}>Price</Title>
        <IconWrapper rotate={contentExpanded ? 'rotate(180deg)' : ''}>
          <KodexIcon
            icon={<ArrowBottom />}
            stroke={darkMode ? '#fff' : '#020202'}
            size={20}
            padding={0}
          />
        </IconWrapper>
      </View>
      <ContentContainer ref={contentContainerRef}>
        <View
          ref={contentWrapperRef}
          width="100%"
          padding="0 30px"
          direction="column"
          justify="center"
          background={darkMode ? '#000' : '#fff'}
          borderRadius="10px"
          overflow="visible"
        >
          <Space size={60} />
          <Space size={10} />
          <CurrencySelector
            darkMode={darkMode}
            value={currency}
            onChange={onCurrencyChange}
          />
          <Space size={20} />
          <PriceInput
            darkMode={darkMode}
            label={currency}
            value={from}
            onChange={onFromChanged}
          />
          <Divider />
          <PriceInput
            darkMode={darkMode}
            label={currency}
            value={to}
            onChange={onToChanged}
          />
          <Space size={30} />
        </View>
      </ContentContainer>
      <ApplyButton
        disabled={Number(from) > Number(to)}
        onClick={applyPriceRange}
        style={{}}
      >
        Apply
      </ApplyButton>
    </AccordionComponent>
  )
}

const CurrencySelector = ({ darkMode, value, onChange }) => {
  return (
    <View
      width="100%"
      alignItems="center"
      background={darkMode ? '#262628' : '#F3F3FA'}
      borderRadius="10px"
      padding="5px"
    >
      <CurrencySelectorButton
        icon={<EthPrice />}
        iconColor={
          value === 'ETH' ? (darkMode ? '#ffffff' : '#020202') : '#BCBCCC'
        }
        onClick={() => onChange('ETH')}
        background={
          value === 'ETH' ? (darkMode ? '#020202' : '#fff') : 'transparent'
        }
      />
      <CurrencySelectorButton
        icon={<UsdcPrice />}
        iconColor={
          value === 'USDC' ? (darkMode ? '#ffffff' : '#020202') : '#BCBCCC'
        }
        onClick={() => onChange('USDC')}
        background={
          value === 'USDC' ? (darkMode ? '#020202' : '#fff') : 'transparent'
        }
      />
    </View>
  )
}

const CurrencySelectorButton = ({ icon, onClick, background }) => {
  return (
    <View flex={1}>
      <RoundedButton
        width="100%"
        height="60px"
        padding="20px 30px"
        background={background}
        onClick={onClick}
      >
        <View width="100%" alignItems="center" justify="center">
          <IconWrapper>{icon}</IconWrapper>
        </View>
      </RoundedButton>
    </View>
  )
}

const PriceInput = ({ darkMode, value, onChange, label }) => {
  const onInputChange = e => {
    const reg = /^[+-]?(\d*\.\d+|\d+\.\d*|\d+)/

    const match = e.target.value.match(reg)
    if (e.target.value.length === 0 || (match && e.target.value === match[0])) {
      onChange(e.target.value)
    }
  }

  return (
    <View width="100%">
      <PriceInputComponent
        value={value || ''}
        onChange={onInputChange}
        placeholder="0.00"
        style={{
          backgroundColor: darkMode ? 'transparent' : '#fff',
          color: darkMode ? '#fff' : '#020202'
        }}
        padding={`19px ${label === 'ETH' ? '40px' : '50px'} 19px 0`}
      />
      <CurrencyLabel>
        <Label size={16} color={darkMode ? '#fff' : '#020202'}>
          {label}
        </Label>
      </CurrencyLabel>
    </View>
  )
}

export default PriceRangeSelector

const ApplyButton = styled.button`
  width: 100%;
  position: absolute;
  font-size: 16px;
  padding: 22px 0;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  line-height: 1;
  color: ${props => (props.disabled ? '#aaa' : '#fff')};
  cursor: pointer;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`

const PriceInputComponent = styled.input`
  color: #020202;
  padding: ${props => props.padding};
  border: none;
  width: 100%;
  font-size: 16px;
  caret-color: #a24f9d;
  line-height: 1;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: #bcbccc;
    opacity: 1;
    line-height: 1;
  }

  &:focus-visible {
    outline: none;
  }
`

const CurrencyLabel = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

const AccordionComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.borderColor};
  border-radius: 10px;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  width: 100%;
  min-height: 60px;
  transition: all 0.3s ease;
  background: ${props => props.background};
`

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
`

const ContentContainer = styled.div`
  max-height: 0;
  transition: all 0.3s ease;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

const IconWrapper = styled.div`
  transform: ${props => props.rotate};
  transition: transform 0.3s ease;
`

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #e1e1e8;
  height: 1px;
  width: 100%;
  margin: 0;
`
