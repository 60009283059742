import React from 'react'
import styled from '@emotion/styled'
import Label from 'components/Label'

const InfoCard = ({}) => {
  return (
    <InfoContainer>
      This domain is on a temporary descending premium because it has recently
      expired. Learn more about domain expiry and premiums{' '}
      <a
        href="https://docs.ens.domains/frequently-asked-questions#what-happens-if-i-forget-to-extend-the-registration-of-a-name"
        target="_blank"
      >
        <Label
          color="#0698a8"
          size={14}
          hoverColor="#0698a8aa"
          cursor="pointer"
        >
          here
        </Label>
      </a>
      .
    </InfoContainer>
  )
}

export default InfoCard

const InfoContainer = styled.div`
  font-size: 14px;
  padding: 20px;
  border: 1px solid #e1e1e8;
  border-radius: 10px;
  line-height: 1.6;
  font-weight: 300;
`
