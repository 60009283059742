import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg'
import { ReactComponent as Check } from 'assets/check-icon.svg'
import Label from 'components/Label'
import View from 'components/View'

const ACTIVITY_DROPDOWN_ITEMS = [
  'All events',
  'sale',
  'purchase',
  'transfer',
  'registration'
]

const MobileActivityDropdown = ({
  open,
  selectedItem,
  setItem,
  setOpen,
  isRegistered
}) => {
  return (
    <View direction="column" zIndex={1000}>
      <View
        padding="0.5rem 0 0.5rem 0.5rem"
        cursor="pointer"
        alignItems="center"
        gap="0.5rem"
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
      >
        <Label color={isRegistered ? '#919CA6' : 'white'}>{selectedItem}</Label>
        <ArrowDown stroke={isRegistered ? '#919CA6' : 'white'} />
      </View>
      {open && (
        <View
          width={selectedItem === ACTIVITY_DROPDOWN_ITEMS[1] ? '8rem' : 'fit'}
          position="absolute"
          top="2.5rem"
          direction="column"
          left={
            selectedItem === ACTIVITY_DROPDOWN_ITEMS[1] ? '-3rem' : '-1.5rem'
          }
          border="1px solid #E1E1E8"
          borderRadius="6px"
          background="#fff"
        >
          {ACTIVITY_DROPDOWN_ITEMS.map(item => (
            <View
              key={item}
              direction="row"
              wrappable={false}
              alignItems="center"
              cursor="pointer"
              gap="1rem"
              width={
                selectedItem === ACTIVITY_DROPDOWN_ITEMS[0] ? '9rem' : '100%'
              }
              padding="1rem"
              hoverBackground="#f3f3fa"
              transition="0.1s ease-in-out"
              onClick={() => {
                setItem(item)
                setOpen(false)
              }}
            >
              {item}
              {selectedItem === item && <Check />}
            </View>
          ))}
        </View>
      )}
    </View>
  )
}

export default MobileActivityDropdown
