import styled from '@emotion/styled/macro'

const Divider = styled.hr`
  border: none;
  background-color: ${props => props.color} #e1e1e8;
  width: 1px;
  height: 40px;
  margin: 0 20px;
`

export default Divider
