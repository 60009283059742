import Link from 'components/Forms/Link'
import React from 'react'

const SearchIcon = ({ color, onClick, size = '20px' }) => {
  return (
    <Link padding="20px" onClick={onClick} hoverOpacity="0.6">
      <svg
        width={size}
        height={size}
        viewBox="0 0 27 27"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Designs"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="V2---No-Accounts-Open"
            transform="translate(-314.000000, -29.000000)"
            fill={color}
            fillRule="nonzero"
          >
            <g id="Top-Nav" transform="translate(-33.000000, 0.000000)">
              <path
                d="M358.307675,51.6152853 C352.075555,51.6152853 347,46.5397448 347,40.3076427 C347,34.0755407 352.075555,29 358.307675,29 C364.539794,29 369.615349,34.0755406 369.615349,40.3076427 C369.615349,42.8267345 368.780938,45.2233464 367.273165,47.1832027 L373.545573,53.4557019 C373.931653,53.8263755 374.087177,54.3768079 373.952163,54.8947148 C373.81715,55.4126217 373.412696,55.817074 372.894788,55.9520872 C372.376879,56.0871005 371.826445,55.9315774 371.461265,55.5511058 L365.183252,49.2731095 C363.22339,50.7808769 360.826772,51.6152853 358.307675,51.6152853 Z M358.307675,31.955812 C353.682136,31.955812 349.95582,35.6821174 349.95582,40.3076427 C349.95582,44.9331682 353.682136,48.6594733 358.307675,48.6594733 C362.933213,48.6594733 366.659529,44.9331684 366.659529,40.3076427 C366.659529,35.6821172 362.933213,31.955812 358.307675,31.955812 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    </Link>
  )
}

export default SearchIcon
