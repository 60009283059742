import styled from '@emotion/styled/macro'
import Avatar from 'components/Avatar'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import moment from 'moment'
import React from 'react'

const ContactListItemAvatar = styled(Avatar)`
  min-width: 40px;
`

/**
 * Lastly, the time next to each user on the left hand column should be the date
 * (month/day only, no year) that the other user (not you) last responded,
 * unless they responded today, in which case it should just show the time they last responded.
 *
 * @param {Date} date - date to be formatted
 * @returns {string} - formatted date
 */
const formatDate = date => {
  if (!date) {
    return undefined
  }

  const isToday = date => {
    const today = new Date()
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    )
  }

  if (isToday(date)) {
    return `${date.toLocaleString('en-us', {
      hour: 'numeric',
      minute: '2-digit'
    })}`
  }

  if (moment(date).fromNow() === 'a day ago') {
    return 'yesterday'
  }

  return moment(date).fromNow()
}

const ContactListItem = ({ selected, contact, onClick }) => {
  return (
    <RoundedButton
      width="100%"
      height="80px"
      padding="20px"
      background={selected ? '#F3F3FA' : '#fff'}
      hoverBackground={'#F3F3FA'}
      onClick={onClick}
    >
      <View width="100%">
        <ContactListItemAvatar
          background="transparent"
          src={contact.summary.users[0].avatar}
          borderRadius="6px"
        />
        <Space size={20} />
        <View
          flex={1}
          direction="column"
          height="40px"
          width="calc(100% - 56px)"
          justify="space-between"
        >
          <View width="100%" justify="space-between" alignItems="center">
            <Label size={14} color="#212121">
              {contact.summary.users[0].name}
            </Label>
            {contact.summary.lastMessageReceived && (
              <Label size={13} color="#BCBCCC">
                {formatDate(contact.summary.lastMessageReceived.createdAt)}
              </Label>
            )}
          </View>
          {contact.summary.lastMessageReceived && (
            <Label
              style={{
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
              size={14}
              color="#BCBCCC"
            >
              {contact.summary.lastMessageReceived.content}
            </Label>
          )}
        </View>
      </View>
    </RoundedButton>
  )
}

export default ContactListItem
