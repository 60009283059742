import styled from '@emotion/styled/macro'
import { useEffect, useState, useRef, useCallback } from 'react'

import './styles.css'

const MinMaxSlider = ({ onChange, darkMode }) => {
  const min = 3
  const max = 10

  const [minVal, setMinVal] = useState(min)
  const [maxVal, setMaxVal] = useState(max)
  const minValRef = useRef(min)
  const maxValRef = useRef(max)
  const range = useRef(null)

  // Convert to percentage
  const getPercent = useCallback(
    value => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  // Get min and max values when their state changes
  useEffect(() => {
    onChange([minVal, maxVal === max ? undefined : maxVal])
  }, [minVal, maxVal])

  return (
    <Container>
      <Bar id="bar">
        <Label darkMode={darkMode} left={((minVal - min) / (max - min)) * 94}>
          {minVal}
        </Label>
        <Label darkMode={darkMode} left={((maxVal - min) / (max - min)) * 94}>
          {maxVal + (maxVal === max ? '+' : '')}
        </Label>
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={event => {
            const value = Math.min(Number(event.target.value), maxVal)
            setMinVal(value)
            minValRef.current = value
          }}
          style={{ zIndex: minVal > max - 100 && '5' }}
          className="thumb thumb--left"
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={event => {
            const value = Math.max(Number(event.target.value), minVal)
            setMaxVal(value)
            maxValRef.current = value
          }}
          className="thumb thumb--right"
        />
      </Bar>
    </Container>
  )
}

export default MinMaxSlider

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 52px;
  position: relative;
`

const Bar = styled.div`
  background: #0698a8;
  border-radius: 4px;
  height: 8px;
  margin-top: 30px;
  position: relative;
  width: calc(100% - 35px);
`

const Label = styled.p`
  color: ${({ darkMode }) => (darkMode ? '#e5e5e5' : '#47596B')};
  left: ${({ left }) => left + '%'};
  font-weight: 400;
  position: absolute;
  top: -45px;
  text-align: center;
  width: 20px;
`
