const { createClient } = require('@reservoir0x/reservoir-sdk')

export const reservoirAPIURL = 'https://reservoir.kodex.io'

export const client = createClient({
  chains: [
    {
      id: 1,
      baseApiUrl: reservoirAPIURL,
      default: true
    }
  ],
  source: 'kodex.io',
  automatedRoyalties: false,
  marketplaceFee: 100,
  marketplaceFeeRecipient: '0x171940bFcBB287744D644E07333D6738fCC53DbF',
  normalizeRoyalties: false
})

export const actions = client.actions
