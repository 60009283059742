import { ReactComponent as EtherIcon } from 'assets/ether-grey.svg'
import Time from 'components/DateTime/Time'
import TableIcon from 'components/Icon/TableIcon'
import Label from 'components/Label'
import { Cell, Column, HeaderCell, Table } from 'components/Table'
import View from 'components/View'
// import { ReactComponent as EthPriceLight } from 'assets/eth-price-light.svg'
import RedSmallEth from 'assets/eth-red-small.svg'
import { ReactComponent as PurchaseIcon } from 'assets/purchase-icon.svg'
import { ReactComponent as RegisteredIcon } from 'assets/registered.svg'
import { ReactComponent as SaleIcon } from 'assets/sale.svg'
import { ReactComponent as TransferIcon } from 'assets/transfer.svg'
import Avatar from 'components/Avatar'
import { BigNumber } from 'ethers'
import moment from 'moment'
import 'moment-timezone'
import { useEffect, useMemo, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'
import { useHistory } from 'react-router'
import { EMPTY_ADDRESS } from 'utils/records'
import NoResult from '../NoResult'
import ActivityAddress from '../components/ActivityAddress'

const ICONS = {
  transfer: <TransferIcon />,
  sale: <SaleIcon />,
  purchase: <PurchaseIcon />,
  registration: <RegisteredIcon />,
  expiration: <RegisteredIcon />
}

const Activities = ({ data = [], allData = [], filter, loadMore, noItems }) => {
  if (data?.length === 0) {
    return noItems()
  }

  const history = useHistory()

  const bottomPaddingRef = useRef(null)

  const { inViewport } = useInViewport(bottomPaddingRef)

  useEffect(() => {
    if (data.length === 0 || !inViewport) return
    loadMore()
  }, [inViewport])

  const filteredData = useMemo(
    () =>
      data.filter(activity => {
        // Wanna exclude racists here
        if (activity?.domain?.includes('nigga')) return false
        if (activity?.domain?.includes('nigger')) return false
        if (filter.toLowerCase() === 'all') return activity
        return (
          (activity.event_type || activity.__typename.toLowerCase()) ===
          filter.toLowerCase()
        )
      }),
    [filter]
  )

  const sortedData = filteredData.sort(
    (a, b) =>
      (b.timestamp || Number(b.registrationDate) * 1000) -
      (a.timestamp || Number(a.registrationDate) * 1000)
  )

  if (filteredData.length === 0) {
    return <NoResult message="No Activity Yet" />
  }

  const filteredActivityData = useMemo(
    () =>
      sortedData
        ?.filter(
          activity =>
            activity.fromaddr !== EMPTY_ADDRESS &&
            activity.fromaddr !== '0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5'
        )
        .map(activity => {
          if (activity.toaddr === EMPTY_ADDRESS) {
            return {
              ...activity,
              event_type: 'expiration'
            }
          }

          if (activity.toaddr === null) {
            return {
              ...activity,
              event_type: 'registration'
            }
          }

          return activity
        }),
    [sortedData]
  )

  const getIconBg = event => {
    let iconBg = '#f3f3f3'
    switch (event) {
      case 'sale':
        iconBg = '#EFF0FF'
        break
      case 'purchase':
        iconBg = '#E6F6FF'
        break
      case 'transfer':
        iconBg = '#E8F5F7'
        break
      case 'registration':
        iconBg = '#F1E5FA'
        break
      default:
        iconBg = '#f3f3f3'
        break
    }
    return iconBg
  }

  return (
    <View direction="column" width="100%" height="50vh">
      <Table data={filteredActivityData} height="auto">
        <Column width="16vw" style={{ padding: '10px 0' }}>
          <HeaderCell>Event</HeaderCell>
          <Cell>
            {row => (
              <View alignItems="center" gap="20px">
                <TableIcon
                  icon={ICONS[row.event_type || row?.__typename.toLowerCase()]}
                  background={getIconBg(
                    row?.event_type || row?.__typename.toLowerCase()
                  )}
                />
                <Label
                  size={16}
                  style={{ textTransform: 'capitalize' }}
                  color="#212121"
                >
                  {row.event_type || row?.__typename.toLowerCase()}
                </Label>
              </View>
            )}
          </Cell>
        </Column>
        <Column width="17.5vw">
          <HeaderCell>Domain</HeaderCell>
          <Cell>
            {row => (
              <Label
                size={16}
                color="#000"
                hoverColor="#BCBCCC"
                cursor="pointer"
                onClick={() =>
                  history.push(
                    `/domain/${row.domain || row.labelName + '.eth'}`
                  )
                }
              >
                {row.domain || row.labelName + '.eth'}
              </Label>
            )}
          </Cell>
        </Column>
        <Column>
          <HeaderCell>Price</HeaderCell>

          <Cell>
            {row =>
              (row?.cost || row?.price) && (
                <View alignItems="center" gap="20px">
                  {row.asset === 'USDC' || row.asset === 'DAI' ? (
                    <View
                      padding="10px 15px"
                      background={'#F3F3FA'}
                      borderRadius="4px"
                      justify="center"
                      alignItems="center"
                    >
                      <Label size={20} color={'#000'}>
                        $
                      </Label>
                    </View>
                  ) : row.asset === 'WETH' ? (
                    <Avatar
                      borderRadius="5px"
                      width={40}
                      background={'#F3F3FA'}
                      src={RedSmallEth}
                      padding="9px"
                    />
                  ) : (
                    <TableIcon icon={<EtherIcon />} background="#f3f3fa" />
                  )}
                  <Label color="#212121">
                    {row.asset === 'USDC' || row.asset === 'DAI'
                      ? Number(
                          BigNumber.from(
                            row?.cost || String(Number(row?.price)) || 1
                          )
                            .div(BigNumber.from(10).pow(6))
                            .toNumber()
                        )
                      : Number(
                          BigNumber.from(
                            row?.cost || String(Number(row?.price)) || 1
                          )
                            .div(BigNumber.from(10).pow(15))
                            .toNumber() /
                            10 ** 3 || 0
                        )
                          .toLocaleString('default', {
                            maximumFractionDigits:
                              row.asset === 'USDC' || row.asset === 'DAI'
                                ? 0
                                : Number(
                                    BigNumber.from(
                                      row?.cost ||
                                        String(Number(row?.price)) ||
                                        1
                                    )
                                      .div(BigNumber.from(10).pow(15))
                                      .toNumber() /
                                      10 ** 3 || 0
                                  ) >= 1000
                                ? 0
                                : 3
                          })
                          .replaceAll(',', ' ') || '0.00'}
                  </Label>
                </View>
              )
            }
          </Cell>
        </Column>
        <Column width="16.25vw">
          <HeaderCell>From</HeaderCell>
          <Cell>
            {row => (
              <ActivityAddress
                onlyENS
                address={
                  (row?.event_type === 'registration' &&
                    '0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5') ||
                  row.fromaddr
                }
              />
            )}
          </Cell>
        </Column>
        <Column width="16.25vw">
          <HeaderCell>To</HeaderCell>
          <Cell>
            {row => (
              <ActivityAddress
                onlyENS
                address={
                  row.event_type === 'registration'
                    ? row.fromaddr
                    : row.toaddr || row?.registrant?.id
                }
              />
            )}
          </Cell>
        </Column>
        <Column width="120px">
          <HeaderCell style={{ textAlign: 'right' }}>Date</HeaderCell>
          <Cell>
            {row => (
              <View
                width="100%"
                alignItems="end"
                justify="end"
                padding="0 5px 0 0 "
              >
                <Time
                  timeString={moment
                    .tz(
                      row.timestamp || Number(row.registrationDate) * 1000,
                      'UTC'
                    )
                    .format()}
                  fromProfile={true}
                  style={{ textAlign: 'right' }}
                />
              </View>
            )}
          </Cell>
        </Column>
      </Table>
      {data.length > 0 && data.length < allData.length && (
        <div ref={bottomPaddingRef} style={{ height: '1px', width: '100%' }} />
      )}
    </View>
  )
}

export default Activities
