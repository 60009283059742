import styled from '@emotion/styled/macro'
import RedSmallEth from 'assets/eth-red-small.svg'
import { ReactComponent as DarkSmallEth } from 'assets/eth-price-dark-small.svg'
import FractalBg from 'assets/fractal-completed-modal.png'
import Avatar from 'components/Avatar'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { WETH_ABI } from 'constants/WETH'
import { AccountContext } from 'contexts/AccountProvider'
import { BigNumber, ethers } from 'ethers'
import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { addOffer } from 'service/rest/addOffer'
import {
  ENS_CONTRACT_ADDRESS,
  EXCHANGE_CONTRACT_ADDRESS,
  WETH_TOKEN_ADDRESS,
  getENSContract
} from 'utils/utils'
import { DurationAccordion } from 'views/TokenProfile/DurationAccordion'
import * as Sentry from '@sentry/browser'
import useGetDomainOwner from 'hooks/useGetDomainOwner'
import { actions } from 'utils/reservoir'
import { getSigner } from '@ensdomains/ui'
import LoadingSpinner from 'components/LoadingSpinner'
import { formatETHPrice } from 'utils/format'
import { utils } from 'ethers'
import {
  GET_CATEGORY_FLOOR_PRICE,
  kodexApiContext
} from 'service/graphql/kodex-api'
import useGetDomainCategories from 'hooks/useGetDomainCategories'
import { useLazyQuery } from '@apollo/client'
import { getOffers } from 'service/rest/getOffers'

const DURATION_OPTIONS = [
  { label: '12 Hours', value: 0.5 },
  { label: '3 Days', value: 3 },
  { label: '7 Days', value: 7 },
  { label: '1 Month', value: 30 }
]

const OfferDialog = ({ domain, onClose, backLabel, resetState, minWidth }) => {
  const { account, handleSIWE } = useContext(AccountContext)
  const history = useHistory()

  const [allowance, setAllowance] = useState(0)
  const [offerAmount, setOfferAmount] = useState(0)
  const [offerDuration, setOfferDuration] = useState()
  const [isApproving, setApproving] = useState(false)
  const [isOffering, setOffering] = useState(false)
  const [offerCreated, setOfferCreated] = useState(false)
  const [duration, setDuration] = useState(DURATION_OPTIONS[0])
  const [WETHBalance, setWETHBalance] = useState(0)
  const [categoryFloor, setCategoryFloor] = useState(null)
  const [highestOffer, setHighestOffer] = useState(null)
  const [durationAccordionExpanded, setDurationAccordionExpanded] = useState(
    false
  )

  const { name, lastPrice, lastPriceAsset } = domain

  const data = useGetDomainCategories(name)

  const category = data[0]?.name || null

  const { ownerAddress: domainOwner } = useGetDomainOwner(
    name.replace('.eth', '')
  )

  const [fetchFloorPrice, { data: floorPriceData }] = useLazyQuery(
    GET_CATEGORY_FLOOR_PRICE,
    {
      ...kodexApiContext,
      fetchPolicy: 'no-cache'
    }
  )

  const WETH = new ethers.Contract(
    WETH_TOKEN_ADDRESS,
    WETH_ABI,
    new ethers.providers.StaticJsonRpcProvider(
      process.env.REACT_APP_LLAMA_NODES_URL,
      'homestead'
    )
  )

  const disabledResetButton =
    (offerAmount === 0 || offerAmount.length === 0) && duration.value === 0.5

  useEffect(() => {
    if (!category) return
    fetchFloorPrice({
      variables: {
        search_taxa: `{${category}}`
      }
    })
  }, [category])

  const fetchOffers = async () => {
    const tokenId = ethers.BigNumber.from(
      ethers.utils.keccak256(ethers.utils.toUtf8Bytes(name.replace('.eth', '')))
    ).toString()
    try {
      const fetchedOffers = await getOffers({
        address: ENS_CONTRACT_ADDRESS,
        token_id: tokenId
      })

      if (!fetchedOffers) {
        return
      }

      const highestOfferAmount = fetchedOffers?.reduce((acc, curr) => {
        const price = curr.price.amount.decimal

        if (price > acc) {
          return price
        } else {
          return acc
        }
      }, 0)

      setHighestOffer(highestOfferAmount)
    } catch (err) {
      throw new Error(err)
    }
  }

  useEffect(() => {
    fetchOffers()
  }, [])

  useEffect(() => {
    const floorPriceRaw =
      floorPriceData?.get_feed_aggregate?.aggregate?.min.price
    if (!floorPriceRaw) return
    const floorPrice = Number(utils.formatEther(BigInt(floorPriceRaw)))
    setCategoryFloor(floorPrice)
  }, [floorPriceData])

  useEffect(() => {
    const fetchWETHBalance = async () => {
      if (!account) return
      const wethBalance = await WETH.balanceOf(account)

      const bigNumETHConversion = BigNumber.from(10).pow(18)

      const bigNumWETHBalance = BigNumber.from(wethBalance).mul(
        BigNumber.from(10).pow(6)
      )
      const WETHBalanceConverted =
        bigNumWETHBalance.div(bigNumETHConversion).toNumber() / 10 ** 6

      setWETHBalance(WETHBalanceConverted)
    }
    fetchWETHBalance()
  }, [WETH])

  const fetchAllowance = useCallback(async () => {
    if (account) {
      try {
        const ensContract = await getENSContract()
        const allowance = await ensContract.isApprovedForAll(
          account,
          EXCHANGE_CONTRACT_ADDRESS
        )
        setAllowance(allowance)
      } catch (err) {
        console.log('ens fetch error for isApprovedForAll: ', err)
      }
    }
  }, [account])

  useEffect(() => {
    fetchAllowance()
  }, [fetchAllowance])

  const authedOffer = async args => {
    try {
      await handleSIWE()
    } catch (e) {
      throw e
    }

    return actions.placeBid(args)
  }

  const handleApproveAllowance = async () => {
    if (isApproving) return

    try {
      setApproving(true)
      const ensContract = await getENSContract()
      const tx = await ensContract.setApprovalForAll(
        EXCHANGE_CONTRACT_ADDRESS,
        true
      )
      await tx.wait()
      await fetchAllowance()
      setApproving(false)
    } catch (err) {
      setApproving(false)
      console.log('Approve allowace error: ', err)
    }
  }

  const handleReset = () => {
    if (disabledResetButton) return
    setOfferAmount(0)
    setOfferDuration(DURATION_OPTIONS[0])
    setDuration(DURATION_OPTIONS[0])
  }

  const handleAddOffer = async data => {
    const response = await addOffer(data)
    return response
  }

  const handleMakeOffer = async () => {
    if (
      isApproving ||
      isOffering ||
      !offerAmount ||
      !duration ||
      Number(offerAmount) > Number(WETHBalance) ||
      Number(offerAmount) === 0
    )
      return
    const durationTransformed = moment()
      .add(
        duration.value < 1 ? duration.value * 24 : duration.value,
        duration.value < 1 ? 'hours' : 'days'
      )
      .toISOString()
    const durationInSeconds = Math.floor(
      new Date(durationTransformed).getTime() / 1000
    ).toString()
    const weiOfferPrice = ethers.utils.parseEther(offerAmount).toString()

    try {
      if (!domainOwner) throw new Error('Domain owner address invalid.')

      const signer = await getSigner()

      const tokenId = ethers.BigNumber.from(
        ethers.utils.keccak256(
          ethers.utils.toUtf8Bytes(domain.name.replace('.eth', ''))
        )
      ).toString()

      setOffering(true)
      await actions.placeBid({
        signer,
        bids: [
          {
            weiPrice: weiOfferPrice,
            token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
            expirationTime: durationInSeconds,
            orderbook: 'reservoir',
            orderKind: 'seaport-v1.4',
            excludeFlaggedTokens: false,
            currency: WETH_TOKEN_ADDRESS,
            quantity: 1
          }
        ],
        onProgress: step => {
          if (
            step[2]?.items[0]?.status &&
            step[2]?.items[0]?.status === 'complete'
          ) {
            if (resetState) resetState()

            setOffering(false)
            setOfferCreated(true)
          }
        }
      })

      // const order = await createdOffer.executeAllActions()
      // setOfferCreated(true)
      // const setOfferOnJetty = handleAddOffer(createdOffer)
    } catch (err) {
      Sentry.captureException(err)
      setOffering(false)
    }
  }

  useEffect(() => {
    if (offerAmount === '') setOfferAmount(0)
  }, [offerAmount])

  const insufficientBalance = WETHBalance < offerAmount
  const disabledOfferButton =
    insufficientBalance ||
    parseFloat(offerAmount) === 0 ||
    isApproving ||
    isOffering

  const balance = !account ? null : WETHBalance

  return offerCreated && !isOffering ? (
    <View
      width="20vw"
      minWidth="600px"
      height="40vh"
      margin="0 0 1rem 0"
      direction="column"
      borderRadius="10px"
      justify="space-between"
      background="#0698A8"
      alignItems="center"
      padding="40px"
    >
      <Label size={40}>Offer placed!</Label>
      <View width="100%" justify="space-between" alignItems="center">
        <RoundedButton
          onClick={() => {
            onClose()
            history.push(`/domain/${name}`)
          }}
        >
          <Label size={16}>{name}</Label>
        </RoundedButton>
        <View gap="20px" alignItems="center">
          {/* <View
            justify="center"
            gap="20px"
            alignItems="center"
            padding="10px"
            background="#fff"
            width="40px"
            height="40px"
            borderRadius="6px"
          >
            <DarkSmallEth />
          </View> */}
          <Label>{offerAmount} WETH</Label>
        </View>
      </View>
      <View width="100%" gap="10px">
        <RoundedButton
          flex="50%"
          height="60px"
          background="transparent"
          border="1px solid #fff"
          borderRadius="6px"
          onClick={() => {
            onClose()
            history.push(`/my-profile?offers`)
          }}
        >
          <Label size={16} color="#fff">
            View in offers
          </Label>
        </RoundedButton>
        <RoundedButton
          flex="50%"
          height="60px"
          background="#fff"
          border="1px solid #fff"
          borderRadius="6px"
          onClick={() => onClose()}
        >
          <Label size={16} color="#020202">
            Back to {backLabel || ''}
          </Label>
        </RoundedButton>
      </View>
    </View>
  ) : (
    <View
      width="33vw"
      minWidth="720px"
      minHeight="640px"
      maxHeight="750px"
      height="100%"
      direction="column"
      margin="0 0 1rem 0"
    >
      <View
        flex={1}
        width="100%"
        direction="column"
        style={{ overflow: 'hidden' }}
        borderRadius="10px"
      >
        <View
          width="100%"
          background="#fff"
          padding="8vh 0 0 2.5rem"
          direction="column"
          gap="0.5rem"
        >
          <View direction="column">
            <Label color="#BCBCCC" size={13}>
              Make an offer
            </Label>
            <Space size={10} />
            <Label
              style={{ overflow: 'visible', zIndex: 10 }}
              size={40}
              color="#020202"
            >
              {name}
            </Label>
          </View>
        </View>
        <View
          flex={1}
          direction="column"
          background="#fff"
          padding="3.5vh 2.5rem 2vh 2.5rem"
          width="100%"
          gap="2vh"
        >
          <View width="100%">
            <View flex="50%" direction="column" gap="2vh">
              <Label color="#BCBCCC" size={13}>
                Your offer
              </Label>
              <View
                height="64px"
                padding="10px"
                alignItems="center"
                width="80%"
                gap="1rem"
                background="#F3F3FA"
                borderRadius="10px"
                border="1px solid #CACADB"
              >
                <Avatar
                  borderRadius="6px"
                  width={40}
                  background={'#fff'}
                  src={RedSmallEth}
                  padding="10px"
                />
                <StyledInput
                  fontSize={16}
                  value={offerAmount || ''}
                  placeholder={'0.00'}
                  type="number"
                  onChange={e => setOfferAmount(e.target.value)}
                  min={0}
                />
              </View>
            </View>

            <View
              justify="space-between"
              flex="50%"
              direction="column"
              alignItems="flex-start"
              gap="1.2vh"
            >
              {balance !== null && (
                <>
                  <Label color="#BCBCCC" size={13}>
                    Balance
                  </Label>
                  <View height="64px" gap="20px" alignItems="center">
                    <Avatar
                      borderRadius="6px"
                      width={40}
                      background={'#f3f3fa'}
                      src={RedSmallEth}
                      padding="10px"
                    />
                    <Label size={30} color="#aaa" lineHeight="42px">
                      {balance === 0
                        ? balance.toFixed(2)
                        : formatETHPrice(balance)}
                    </Label>
                  </View>
                </>
              )}
            </View>
          </View>
          <View width="100%" alignItems="center">
            <View gap="2vh" flex={1} direction="column">
              <Label size={13} color="#BCBCCC">
                Duration
              </Label>
              <DurationAccordion
                borderColor="#CACADB"
                itemPadding="22px 20px"
                itemHeight="60px"
                fontWeight={500}
                selectedItem={duration}
                onChange={setDuration}
                content={DURATION_OPTIONS}
                width="80%"
              />
            </View>
            <View flex={1} gap="2vh" direction="column">
              <Label size={13} color="#BCBCCC">
                Ends
              </Label>
              <View height="64px" alignItems="center">
                <Label size={16} color="#BCBCCC">
                  {moment()
                    .add(
                      duration.value < 1 ? duration.value * 24 : duration.value,
                      duration.value < 1 ? 'hours' : 'days'
                    )
                    .format('DD MMM, hh:mm a')}
                </Label>
              </View>
            </View>
          </View>
          <View
            direction="column"
            width="100%"
            padding="2vh 0 0 0"
            style={{
              borderTop: '1px solid #CACADB'
            }}
          >
            {!!highestOffer && (
              <View
                alignItems="center"
                justify="space-between"
                width="100%"
                padding="10px 0"
              >
                <Label color="#020202">Highest offer</Label>
                <View gap="20px" width="110px" alignItems="center">
                  <Avatar
                    borderRadius="6px"
                    width={40}
                    background={'#f3f3fa'}
                    src={RedSmallEth}
                    padding="10px"
                  />
                  <Label color="#020202">{formatETHPrice(highestOffer)}</Label>
                </View>
              </View>
            )}
            {lastPrice > 0 && (
              <View
                alignItems="center"
                justify="space-between"
                width="100%"
                padding="10px 0"
              >
                <Label color="#020202">Last sale</Label>
                <View gap="20px" width="110px" alignItems="center">
                  <View
                    justify="center"
                    alignItems="center"
                    padding="10px"
                    background="#f3f3fa"
                    width="40px"
                    height="40px"
                    borderRadius="6px"
                  >
                    {lastPriceAsset === 'USDC' || lastPriceAsset === 'DAI' ? (
                      <Label weight={700} color="#000">
                        $
                      </Label>
                    ) : (
                      <DarkSmallEth />
                    )}
                  </View>
                  <Label color="#020202">{formatETHPrice(lastPrice)}</Label>
                </View>
              </View>
            )}
            <View
              opacity={categoryFloor ? '1' : '0'}
              transition="opacity 500ms ease"
              alignItems="center"
              justify="space-between"
              width="100%"
              padding="10px 0"
            >
              <View>
                <Label color="#020202">Category floor</Label>
                <Space size={4} />
                <Label color="#BCBCCC">({category})</Label>
              </View>

              <View gap="20px" width="110px" alignItems="center">
                <View
                  justify="center"
                  alignItems="center"
                  padding="10px"
                  background="#f3f3fa"
                  width="40px"
                  height="40px"
                  borderRadius="6px"
                >
                  <DarkSmallEth />
                </View>
                <Label color="#020202">{formatETHPrice(categoryFloor)}</Label>
              </View>
            </View>
          </View>
          <View
            position="absolute"
            width="100%"
            padding="40px"
            bottom="0px"
            left="0px"
          >
            <View flex={1}>
              <RoundedButton
                width="100%"
                height="60px"
                background="#fff"
                style={{ cursor: disabledResetButton ? 'default' : 'pointer' }}
                border="1px solid #CACADB"
                onClick={handleReset}
              >
                <Label
                  size={16}
                  color={disabledResetButton ? '#BCBCCC' : '#020202'}
                >
                  Reset
                </Label>
              </RoundedButton>
            </View>
            <Space size={10} />
            <View flex={1}>
              {!allowance ? (
                <RoundedButton
                  width="100%"
                  height="60px"
                  style={{
                    cursor: disabledOfferButton ? 'default' : 'pointer'
                  }}
                  border={
                    disabledOfferButton
                      ? '1px solid #CACADB'
                      : '1px solid #0698A8'
                  }
                  onClick={handleApproveAllowance}
                  background={
                    !disabledOfferButton || isApproving || isOffering
                      ? '#0698A8'
                      : 'transparent'
                  }
                >
                  <View alignItems="center">
                    <Label
                      color={
                        !disabledOfferButton || isApproving || isOffering
                          ? '#fff'
                          : '#BCBCCC'
                      }
                    >
                      {insufficientBalance
                        ? 'Insufficient balance'
                        : isApproving
                        ? 'Approving'
                        : disabledOfferButton
                        ? 'Make offer'
                        : 'Approve WETH'}
                    </Label>
                    {isApproving && (
                      <LoadingSpinner color="white" margin="0 0 0 20px" />
                    )}
                  </View>
                </RoundedButton>
              ) : (
                <RoundedButton
                  width="100%"
                  height="60px"
                  style={{
                    cursor: disabledOfferButton ? 'default' : 'pointer'
                  }}
                  border={
                    disabledOfferButton
                      ? '1px solid #CACADB'
                      : '1px solid #0698A8'
                  }
                  onClick={handleMakeOffer}
                  background={
                    !disabledOfferButton || isApproving || isOffering
                      ? '#0698A8'
                      : 'transparent'
                  }
                >
                  <View alignItems="center">
                    <Label
                      color={
                        !disabledOfferButton || isApproving || isOffering
                          ? '#fff'
                          : '#BCBCCC'
                      }
                    >
                      {insufficientBalance
                        ? 'Insufficient balance'
                        : isOffering
                        ? 'Making offer'
                        : 'Make offer'}
                    </Label>
                    {isOffering && (
                      <LoadingSpinner color="white" margin="0 0 0 20px" />
                    )}
                  </View>
                </RoundedButton>
              )}

              {/* {!allowance ? (
                <RoundedButton
                  width="100%"
                  height="60px"
                  background="#0698A8"
                  disabled={isApproving}
                  border="1px solid #EBEBF2"
                  onClick={handleApproveAllowance}
                  hoverBackground="#26a8c8"
                >
                  <Label color="#020202" size={16} letterSpacing="1px">
                    {isApproving ? 'Pending' : 'Approve WETH'}
                  </Label>
                  {isApproving && <LoadingSpinner margin="0 0 0 20px" />}
                </RoundedButton>
              ) : (
                <RoundedButton
                  width="100%"
                  height="60px"
                  border="1px solid #E1E1E8"
                  disabled={
                    isApproving ||
                    isOffering ||
                    !offerAmount ||
                    !duration ||
                    Number(offerAmount) > Number(WETHBalance) ||
                    Number(offerAmount) === 0
                  }
                  background="#0698A8"
                  hoverBackground="#16a8b8"
                  onClick={handleMakeOffer}
                >
                  <Label
                    size={16}
                    color={
                      !offerAmount ||
                      Number(offerAmount) === 0 ||
                      !duration ||
                      Number(offerAmount) > Number(WETHBalance)
                        ? '#ffffff'
                        : isOffering
                        ? '#000'
                        : '#ffffff'
                    }
                  >
                    {offerAmount > WETHBalance
                      ? 'Not enough WETH'
                      : isOffering
                      ? 'Pending'
                      : 'Make offer'}
                  </Label>
                  {isOffering && <LoadingSpinner margin="0 0 0 20px" />}
                </RoundedButton>
              )} */}
            </View>
          </View>
          {/* <View
            width="100%"
            borderRadius="10px"
            border="1px solid #EBEBF2"
            padding="40px"
            direction="column"
          >
            <Label color="#020202" size={16}>
              LAST OFFERS
            </Label>
            <Space size={20} />
            <StyledTable>
              <thead>
                <tr>
                  <th>EVENT</th>
                  <th>PRICE</th>
                  <th>FROM</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>OFFER</td>
                  <td>0.105</td>
                  <td>USERNAME</td>
                  <td>
                    <RoundedButton
                      padding="10px 20px"
                      border="1px solid #EBEBF2"
                    >
                      CANCEL
                    </RoundedButton>
                  </td>
                </tr>
              </tbody>
            </StyledTable>
          </View> */}
        </View>
      </View>
    </View>
  )
}

const StyledInput = styled.input`
  border: none;
  outline: none;
  font-size: ${props => (props.fontSize ? props.fontSize + 'px' : '30px')};
  line-height: 40px;
  color: '#020202';
  background: transparent;
  font-family: 'Satoshi', sans-serif;
  letter-spacing: 1px;
  width: 200px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #bcbccc;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

const StyledTable = styled.table`
  text-align: left;
  width: 100%;

  th {
    font-size: 12px;
    font-weight: 500;
    color: #bcbccc;
    height: 70px;
    font-family: 'Satoshi', sans-serif;
  }

  tbody {
    td {
      height: 70px;
      width: 40%;
      font-family: 'Satoshi', sans-serif;
    }
  }
`

export default OfferDialog
