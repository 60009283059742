import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { useTopBarStyle } from '../../contexts/TopBarStyleProvider'
import DefaultTopBar from './DefaultTopBar'
import HelpCenterTopbar from './HelpCenterTopbar'
import { isAddress } from 'utils/address'

const Topbar = () => {
  const { setScrolled, setScrolledMarketplace } = useTopBarStyle()
  const location = useLocation()

  const isProfile =
    isAddress(`0x${location.pathname.slice(1, location.pathname.length)}`) ||
    location.pathname === '/my-profile'

  useEffect(() => {
    const handleScroll = e => {
      // sessionStorage.setItem('SCROLL', `${window.scrollY}`)

      if (isProfile || window.scrollY > (window.innerWidth < 640 ? 40 : 80)) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }

      if (
        window.innerWidth > 740 &&
        location.pathname.includes('marketplace') &&
        window.scrollY > 430
      ) {
        setScrolledMarketplace(true)
      } else {
        setScrolledMarketplace(false)
      }
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [location])

  return location.pathname.includes('/help') ? (
    <HelpCenterTopbar />
  ) : (
    <DefaultTopBar />
  )
}

export default Topbar
