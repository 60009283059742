import { useQuery } from '@apollo/client'
import styled from '@emotion/styled/macro'
import { ethers, getSigner } from '@ensdomains/ui'
import ConfirmationImg from 'assets/abstract-orb.png'
import AccountBtnBg from 'assets/account-btn-bg.png'
import { ReactComponent as ArrowBack } from 'assets/arrow-back.svg'
import { ReactComponent as EthPriceWhite } from 'assets/eth-price-white.svg'
import Card from 'components/Card'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import LoadingSpinner from 'components/LoadingSpinner'
import { useAccount } from 'components/QueryAccount'
import Space from 'components/Space'
import View from 'components/View'
import { AccountContext } from 'contexts/AccountProvider'
import { useEthPrice } from 'hooks/useEthPrice'
import useGetDomainOwner from 'hooks/useGetDomainOwner'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { createPortal } from 'react-dom'
import { useHistory, useParams } from 'react-router-dom'
import { GET_CATEGORIES, kodexApiContext } from 'service/graphql/kodex-api'
import { GET_REGISTRANT_FROM_SUBGRAPH } from 'service/graphql/queries'
import { getListing } from 'service/rest/getListing'
import { actions } from 'utils/reservoir'
import {
  ENS_CONTRACT_ADDRESS,
  EXCHANGE_CONTRACT_ADDRESS,
  getENSContract
} from 'utils/utils'
import { DurationAccordion } from 'views/TokenProfile/DurationAccordion'

const DURATION_OPTIONS = [
  { label: '1 Day', value: 1 },
  { label: '1 Week', value: 7 },
  { label: '1 Month', value: 30 },
  { label: '3 Months', value: 90 },
  { label: '6 Months', value: 180 }
]

// const COMMIT_GAS_WEI = 42000
// const REGISTER_GAS_WEI = 240000
// const TOGAL_GAS_WEI = COMMIT_GAS_WEI + REGISTER_GAS_WEI

const Sale = () => {
  const params = useParams()
  const history = useHistory()
  const account = useAccount()
  const { handleSIWE } = useContext(AccountContext)

  const [cost, setCost] = useState('0.00')
  const [listPrice, setListPrice] = useState('')
  const [listed, setListed] = useState(false)
  const [edited, setEdited] = useState(false)
  const [allowance, setAllowance] = useState(0)
  const [isApproving, setApproving] = useState(false)
  const [isCanceling, setCanceling] = useState(false)
  const [canceled, setCanceled] = useState(false)
  const [isListing, setListing] = useState(false)
  const [published, setPublished] = useState(false)
  const [description, setDescription] = useState('')
  const [duration, setDuration] = useState(DURATION_OPTIONS[0])
  const [domainOrder, setDomainOrder] = useState([])
  const [durationAccordionExpanded, setDurationAccordionExpanded] = useState(
    false
  )

  const ethPrice = useEthPrice()

  const { data: { registration: domain } = {} } = useQuery(
    GET_REGISTRANT_FROM_SUBGRAPH,
    {
      variables: {
        id: params.id
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  )

  const { data: { domains_taxonomies: taxonomies } = {} } = useQuery(
    GET_CATEGORIES,
    {
      variables: {
        where: {
          domain: { _eq: `${domain?.labelName}.eth` }
        }
      },
      ...kodexApiContext,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  )

  const handleOutsideConfirmationClick = useCallback(e => {
    if (e.target.id === 'modal-container') {
      history.push('/profile/my-profile')
    }
  }, [])

  const handleViewListing = useCallback(() => {
    history.push(`/domain/${domain?.labelName}.eth?listed`)
  }, [domain, history])

  const listingPriceInUsd = useMemo(() => {
    // console.log('(Number(ethPrice)', Number(ethPrice))
    // console.log('(Number(ethPrice)', Number(listPrice))
    return ethPrice === '0.00' || listPrice === '0' || listPrice === ''
      ? null
      : (Number(ethPrice) * Number(listPrice)).toFixed(2)
  }, [ethPrice, listPrice])

  // const handleAddListing = async data => {
  //   await addListing(data)
  // }

  const fetchAllowance = useCallback(async () => {
    if (account) {
      try {
        const ensContract = await getENSContract()
        const allowance = await ensContract.isApprovedForAll(
          account,
          EXCHANGE_CONTRACT_ADDRESS
        )
        setAllowance(allowance)
      } catch (err) {
        throw new Error('ens fetch error for isApprovedForAll: ', err)
      }
    }
  }, [account])

  useEffect(() => {
    fetchAllowance()
  }, [fetchAllowance])

  const authedList = async args => {
    try {
      await handleSIWE()
    } catch (e) {
      throw e
    }

    return actions.listToken(args)
  }

  const handleApproveAllowance = async () => {
    if (isApproving) return

    try {
      setApproving(true)
      const ensContract = await getENSContract()
      const tx = await ensContract.setApprovalForAll(
        EXCHANGE_CONTRACT_ADDRESS,
        true
      )
      await tx.wait()
      await fetchAllowance()
      setApproving(false)
    } catch (err) {
      setApproving(false)
      console.log('Approve allowace error: ', err)
    }
  }

  const handleConfirmListing = async () => {
    if (!domain) return

    try {
      setListing(true)
      const weiListedPrice = ethers.utils.parseEther(listPrice).toString()

      // const { executeAllActions } = await authedList(
      //   domain.labelName,
      //   weiListedPrice,
      //   moment()
      //     .add(duration.value, 'days')
      //     .unix()
      // )

      const signer = await getSigner()

      const tokenId = ethers.BigNumber.from(domain.id).toString()

      const expirationTime = moment()
        .add('days', duration.value)
        .format('X')

      // return {
      //   // token: `0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85:${tokenId}`,
      //   token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
      //   weiPrice: weiListedPrice,
      //   expirationTime
      // }

      await authedList({
        signer,
        listings: [
          {
            token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
            weiPrice: weiListedPrice,
            expirationTime
          }
        ],
        onProgress: step => {
          if (step[1].items[0].status === 'complete') {
            setListing(false)
            setPublished(true)
            localStorage.removeItem(`${domain?.labelName}-listing`)
            localStorage.setItem(
              `${domain?.labelName}-listing`,
              JSON.stringify({
                name: domain?.labelName,
                price: listPrice,
                expires: expirationTime
              })
            )
            history.push(`/domain/${domain?.labelName}.eth?listed`)
          }
        }
      })

      // const order = await executeAllActions()

      // await handleAddListing(order)

      // setPublished(true)
      // history.replace('/profile/my-profile')
    } catch (e) {
      throw new Error('error: ', e)
    }

    setListing(false)
  }

  const { ownerAddress } = useGetDomainOwner(domain?.labelName)

  const currentUser = useAccount()

  useEffect(() => {
    if (ownerAddress === '') return
    if (currentUser.toLowerCase() !== ownerAddress.toLowerCase())
      history.push('/')
  }, [currentUser, ownerAddress])

  const fetchOrder = useCallback(async domainName => {
    if (!domainName) return

    try {
      const tokenId = ethers.BigNumber.from(
        ethers.utils.keccak256(ethers.utils.toUtf8Bytes(domainName))
      ).toString()

      const fetchedOrders = await getListing({
        address: ENS_CONTRACT_ADDRESS,
        token_id: tokenId
      })

      if (!fetchedOrders) {
        setCost('0.00')
        setDomainOrder([])
        return
      }

      const currentListingPrice = fetchedOrders[0].price.amount.decimal

      setListPrice(currentListingPrice.toString())
      setDomainOrder([...fetchedOrders])
    } catch (err) {
      throw new Error(err)
    }
  }, [])

  useEffect(() => {
    fetchOrder(domain?.labelName)
  }, [domain])

  return (
    <Container maxWidth="900px">
      <Space size={80} />
      <Space size={80} />
      <View width="100%" justify="center">
        <Label size={60} color="#212121">
          List{' '}
          <Label size={60} color="#BCBCCC">
            {domain?.labelName || ''}.eth
          </Label>
        </Label>
        <AbsoluteView>
          <RoundedButton
            onClick={() => history.goBack()}
            width="60px"
            height="60px"
            border="1px solid #EBEBF2"
          >
            <ArrowBack />
          </RoundedButton>
        </AbsoluteView>
      </View>
      <Space size={60} />
      <View
        width="100%"
        height="180px"
        borderRadius="10px"
        background="#8B9BB9"
        alignItems="flex-end"
        padding="40px"
        overflow="hidden"
      >
        <StyledImage src={AccountBtnBg} />
        <View direction="column">
          <Label size={16} color="#fff" opacity={0.6}>
            Expiration date
          </Label>
          <Space size={30} />
          <Label size={40} color="#fff">
            {domain
              ? new Date(Number(domain.expiryDate) * 1000).toDateString()
              : ''}
          </Label>
        </View>
      </View>
      <Space size={20} />
      <View
        direction="column"
        padding="56px 40px 40px"
        borderRadius="10px"
        border="1px solid #EBEBF2"
      >
        <View
          direction="row"
          align="center"
          width="100%"
          padding="0 1rem"
          justify="space-between"
        >
          <Label size={16} color="#020202" weight={600}>
            Price
          </Label>
          <Label size={16} number color="#BCBCBC" weight={300}>
            ${listingPriceInUsd || '0.00'}
          </Label>
        </View>

        <Space size={37} />
        <View width="100%">
          <StyledInput
            placeholder={'0.00'}
            value={listPrice}
            onChange={e => setListPrice(e.target.value)}
            type="number"
            min={0}
          />
          <View
            alignItems="center"
            style={{ position: 'absolute', left: 20, top: 20 }}
          >
            <EthPriceWhite />
          </View>
        </View>
      </View>
      <Space size={20} />
      <View
        zIndex={1}
        padding="56px 40px 40px"
        borderRadius="10px"
        border="1px solid #EBEBF2"
      >
        <View flex={1} direction="column">
          <Label size={16} color="#020202" weight={600}>
            Duration
          </Label>
          <Space size={37} />
          <DurationAccordion
            selectedItem={duration}
            onChange={setDuration}
            content={DURATION_OPTIONS}
            width="100%"
            itemPadding="20px"
            itemHeight="60px"
            contentExpanded={durationAccordionExpanded}
            setContentExpanded={setDurationAccordionExpanded}
          />
        </View>
        <Space size={40} />
        <View flex={1} direction="column">
          <Label size={16} color="#020202" weight={600}>
            Ends
          </Label>
          <Space size={52} />
          <Label size={30} color="#BCBCCC">
            {moment()
              .add(duration.value, 'days')
              .format('DD MMM, h:mm a')}
          </Label>
        </View>
      </View>
      <Space size={20} />
      <View
        direction="column"
        padding="45px 40px 40px"
        borderRadius="10px"
        border="1px solid #EBEBF2"
      >
        <View width="100%" justify="space-between" alignItems="center">
          <Label size={16} color="#020202" weight={600}>
            Description
          </Label>
          <View
            background="#F3F3FA"
            height="40px"
            width="60px"
            borderRadius="6px"
            alignItems="center"
            justify="center"
          >
            <Label color="#BCBCCC" size={16} weight={300}>
              {280 - description.length}
            </Label>
          </View>
        </View>
        <Space size={20} />
        <DomainInput
          value={description}
          onChange={e => {
            if (e.target.value.length > 280) return
            setDescription(e.target.value)
          }}
          onBlur={() => {
            if (description.length > 280)
              setDescription(description.slice(0, 280))
          }}
          placeholder="Describe your domain!"
        />
      </View>
      <Space size={20} />
      <View
        direction="column"
        padding="56px 40px 40px"
        borderRadius="10px"
        border="1px solid #EBEBF2"
      >
        <Label size={16} color="#020202" weight={600}>
          Categories
        </Label>
        <Space size={37} />
        <View alignItems="center">
          {(taxonomies || []).length ? (
            <>
              {taxonomies.map(tax => (
                <React.Fragment key={tax.taxonomy}>
                  <Label color="#BCBCCC" size={30}>
                    #{tax.taxonomy}
                  </Label>
                  <Space size={10} />
                </React.Fragment>
              ))}
            </>
          ) : (
            <Label color="#BCBCCC" size={30}>
              None
            </Label>
          )}
        </View>
      </View>
      <Space size={20} />
      <View padding="49px 40px" borderRadius="10px" border="1px solid #EBEBF2">
        <View justify="space-between" alignItems="center" width="100%">
          <View direction="column">
            <Label size={16} color="#020202" weight={600}>
              Fees
            </Label>
            <Space size={10} />
            <Label size={16} color="#BCBCCC">
              Listing is free. Once sold, a fee will be deducted.
            </Label>
          </View>
          <View
            background="#F3F3FA"
            height="40px"
            width="60px"
            borderRadius="6px"
            alignItems="center"
            justify="center"
          >
            <Label size={16} color="#020202">
              1 %
            </Label>
          </View>
        </View>
      </View>
      <Space size={60} />
      {domainOrder.length > 0 ? (
        <RoundedButton
          width="100%"
          disabled={isCanceling || isListing}
          borderRadius="10px"
          height="60px"
          border="1px solid #EBEBF2"
          onClick={handleConfirmListing}
        >
          <Label color="#020202" size={16} letterSpacing="1px" weight={600}>
            {isCanceling || isListing ? 'Publishing' : 'Edit listing'}
            {(isCanceling || isListing) && (
              <LoadingSpinner margin="0 0 0 20px" />
            )}
          </Label>
        </RoundedButton>
      ) : !allowance ? (
        <RoundedButton
          width="100%"
          borderRadius="10px"
          height="60px"
          disabled={isApproving}
          border="1px solid #EBEBF2"
          onClick={handleApproveAllowance}
        >
          <Label color="#020202" size={16} letterSpacing="1px" weight={600}>
            {isApproving ? 'Pending' : 'Approve all'}
          </Label>
          {isApproving && <LoadingSpinner margin="0 0 0 20px" />}
        </RoundedButton>
      ) : (
        <RoundedButton
          width="100%"
          disabled={listed || isListing}
          borderRadius="10px"
          height="60px"
          border="1px solid #EBEBF2"
          onClick={handleConfirmListing}
        >
          <Label
            color={!listed ? '#020202' : '#BCBCCC'}
            size={16}
            letterSpacing="1px"
            weight={600}
          >
            {isListing ? 'Publishing' : 'Publish'}
            {isListing && <LoadingSpinner margin="0 0 0 20px" />}
          </Label>
        </RoundedButton>
      )}
      <Space size={80} />

      {published
        ? createPortal(
            <FixedView onClick={handleOutsideConfirmationClick}>
              <View
                direction="column"
                flex="1"
                alignItems="end"
                id="modal-container"
                padding="1rem 1rem"
              >
                <Card
                  width="720px"
                  height="calc(100vh - 2rem)"
                  background="#0698A8"
                  borderRadius="10px"
                >
                  <Space size={40} />
                  <View
                    height="calc(100vh - 7.5rem)"
                    direction="column"
                    justify="space-between"
                    aling="center"
                    alignItems="center"
                  >
                    <View direction="column" alignItems="center">
                      {edited && (
                        <Label size={40} color="rgb(255 255 255 / 0.5)">
                          Listed for
                        </Label>
                      )}
                      <Space size={15} />
                      <Label size={40}>{domain?.labelName}.ETH</Label>
                      <Space size={15} />
                      <Label size={40} color="rgb(255 255 255 / 0.5)">
                        Has been {edited ? 'Edited' : 'Listed for'}
                      </Label>
                      <Space size={15} />
                      {!edited && <Label size={40}>{listPrice} ETH</Label>}
                    </View>
                    <Space size={50} />
                    <ConfirmationImage src={ConfirmationImg} />
                    <Space size={50} />
                    <RoundedButton
                      width="300px"
                      background="#ffffff"
                      color="#000000"
                      borderRadius="10px"
                      height="60px"
                      border="1px solid #EBEBF2"
                      fontSize="16px"
                      onClick={handleViewListing}
                    >
                      View listing
                    </RoundedButton>
                  </View>
                </Card>
              </View>
            </FixedView>,
            document.querySelector('body')
          )
        : null}
    </Container>
  )
}

const FixedView = styled.div`
  background: rgb(0 0 0 / 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`

const AbsoluteView = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
`

const ConfirmationImage = styled.img`
  display: block;
  height: auto;
  width: 100%;
  max-width: 300px;
`

const StyledImage = styled.img`
  position: absolute;
  top: -130px;
  left: 0px;
  width: 100%;
`

const StyledInput = styled.input`
  background: #f3f3fa;
  border: 1px solid #e1e1e8;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  outline: none;
  height: 80px;
  font-size: 30px;
  padding-left: 80px;
  caret-color: #a14f9d;
  color: #020202;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  ::placeholder {
    color: #bcbccc;
  }
`

const DomainInput = styled.input`
  background: transparent;
  border: none;
  font-family: 'Satoshi', sans-serif;
  box-sizing: border-box;
  border-radius: 18px;
  width: 100%;
  outline: none;
  font-size: 16px;
  caret-color: #a14f9d;
  color: #020202;

  ::placeholder {
    color: #bcbccc;
  }
`

const StyledTable = styled.table`
  text-align: left;
  width: 100%;

  th {
    font-size: 12px;
    font-weight: 500;
    color: #bcbccc;
    height: 70px;
  }

  tbody {
    td {
      height: 70px;
      width: 40%;
    }
  }
`

export default Sale
