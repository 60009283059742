const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.background = 'rgba(255, 255, 255)'
    tooltipEl.style.borderRadius = '10px'
    tooltipEl.style.color = '#020202'
    tooltipEl.style.opacity = '1'
    tooltipEl.style.padding = '20px'
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, -110%)'
    tooltipEl.style.transition =
      'all .8s cubic-bezier( 0.190,  1.000,  0.220,  1.000 ), opacity .2s ease'
    tooltipEl.style.fontFamily = 'Satoshi'
    tooltipEl.style.width = '390px'

    const table = document.createElement('table')
    table.style.margin = '0px'

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

export const externalTooltipHandler = (context, eventType) => {
  // Tooltip Element
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  // Set Text
  if (tooltip.body) {
    const header = createHeader(tooltip)
    const data = createData(tooltip, eventType.current + 's')

    const tableRoot = tooltipEl.querySelector('table')

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove()
    }

    // Add new children
    // tableRoot.appendChild(tableHead)
    tableRoot.appendChild(header)
    tableRoot.appendChild(data)
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1'
  tooltipEl.style.left = positionX + tooltip.caretX + 'px'
  tooltipEl.style.top = positionY + tooltip.caretY + 'px'
  tooltipEl.style.font = tooltip.options.bodyFont.string
}

function createHeader(tooltip) {
  const header = document.createElement('div')
  header.style.display = 'flex'
  header.style.alignItems = 'center'
  header.style.marginBottom = '20px'
  header.style.fontFamily = 'Satoshi'
  const color = document.createElement('div')
  color.style.width = '6px'
  color.style.height = '6px'
  color.style.borderRadius = '3px'
  color.style.marginRight = '10px'
  color.style.backgroundColor =
    tooltip.labelColors[0].backgroundColor === 'rgba(255,255,255, 0.5)'
      ? '#0698A8'
      : tooltip.labelColors[0].backgroundColor
  const text = document.createElement('span')
  text.innerHTML = tooltip.title
  text.style.fontSize = '13px'
  text.style.color = '#BCBCCC'
  text.style.fontWeight = '300'
  header.appendChild(color)
  header.appendChild(text)

  return header
}

function createData(tooltip, operationTypeLabel) {
  const table = document.createElement('div')
  table.style.display = 'flex'
  table.style.width = '340px'
  table.style.justifyContent = 'space-between'
  table.style.fontFamily = 'Satoshi'

  const firstCell = createCell(
    'Avg.price (ETH)',
    Number(tooltip.dataPoints[0].raw?.toFixed(2))
  )
  const secondCell = createCell(
    'Volume (ETH)',
    Number(tooltip.dataPoints[1].raw?.toFixed(2))
  )
  const thirdCell = createCell(
    `Num.${operationTypeLabel}`,
    tooltip.dataPoints[2].raw
  )

  table.appendChild(firstCell)
  table.appendChild(secondCell)
  table.appendChild(thirdCell)

  return table
}

function createCell(header, data) {
  const cell = document.createElement('div')
  cell.style.display = 'flex'
  cell.style.flexDirection = 'column'
  cell.style.flex = '1'
  const headCell = document.createElement('span')
  headCell.innerHTML = header
  headCell.style.marginBottom = '10px'
  headCell.style.color = '#BCBCCC'
  headCell.style.fontSize = '13px'
  headCell.style.fontWeight = '300'
  headCell.style.fontFamily = 'Satoshi'
  const dataCell = document.createElement('span')
  dataCell.innerHTML = data
  dataCell.style.fontSize = '16px'
  dataCell.style.color = '#020202'
  dataCell.style.fontWeight = '600'
  dataCell.style.fontFamily = 'Satoshi'

  cell.appendChild(headCell)
  cell.appendChild(dataCell)

  return cell
}
