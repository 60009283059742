import gql from 'graphql-tag'
import { LikesFields, UserFields } from './fragments'

export const GET_USER = gql`
  query getUser($user: String) {
    users(where: { user: { _eq: $user } }) {
      ...UserFields
    }
  }

  ${UserFields}
`

export const SEARCH_USERS = gql`
  query usersQuickSearch($name: String) {
    users: quick_search_users(args: { name: $name }) {
      id
      item_name
      item_type
    }
  }
`

export const GET_ALL_LIKES = gql`
  query getLikes($offset: Int, $limit: Int) {
    likes(offset: $offset, limit: $limit) {
      ...LikesFields
    }
  }

  ${LikesFields}
`

export const GET_LIKES_BY_USER = gql`
  query getLikes($user: String!, $offset: Int, $limit: Int) {
    likes(
      where: { user: { _eq: $user }, liked: { _eq: true } }
      offset: $offset
      limit: $limit
    ) {
      ...LikesFields
      domainDetails: domainViewByDomain {
        name: domain
        expire_time
        taxonomies
        terms
        last_price
        likes
      }
    }
  }

  ${LikesFields}
`

export const GET_LIKES_BY_DOMAIN = gql`
  query GetLikesByDomain($domain: String!, $offset: Int, $limit: Int) {
    likes(where: { domain: { _eq: $domain } }, offset: $offset, limit: $limit) {
      domain
      liked
      user
      userByUser {
        user
        picture
        username
        instagram
        twitter
        weblink
      }
    }
  }
`

export const GET_LIKE_AMOUNT_BY_DOMAIN = gql`
  query GetLikeAmountByDomain($domain: String!, $offset: Int, $limit: Int) {
    likes(where: { domain: { _eq: $domain } }, offset: $offset, limit: $limit) {
      domain
      user
      liked
    }
  }
`

export const GET_USER_ADDRESS_FROM_USERNAME = gql`
  query GetUserAddressFromUsername($username: citext!) {
    users(where: { username: { _eq: $username } }) {
      user
      username
    }
  }
`

export const GET_USER_PROFILE = gql`
  query getProfile($user: String!) {
    profile: profile_view(where: { user: { _eq: $user } }) {
      user
      followers
      followings
      likes
      userInfo {
        ...UserFields
      }
    }
  }

  ${UserFields}
`

export const GET_USER_FOLLOWERS = gql`
  query getFollowers($user: String!, $offset: Int, $limit: Int) {
    followers: profile_view(where: { user: { _eq: $user } }) {
      user
      length: followers
      list: followersList(offset: $offset, limit: $limit) {
        userId: follower_id
        info: userByFollowerId {
          user
          username
          bio
          twitter
          picture
          address {
            address
          }
        }
      }
    }
  }
`

export const GET_USER_FOLLOWINGS = gql`
  query getFollowings($user: String!, $offset: Int, $limit: Int) {
    followings: profile_view(where: { user: { _eq: $user } }) {
      user
      length: followings
      list: followingsList(offset: $offset, limit: $limit) {
        userId: user
        info: userByUser {
          user
          username
          bio
          twitter
          picture
          address {
            address
          }
        }
      }
    }
  }
`

export const GET_USER_FOLLOWINGS_REACTIVE = gql`
  query GetUserFollowingsReactive($user: String!) {
    followers: followers(where: { follower_id: { _eq: $user } }) {
      follower_id
      user
    }
  }
`

export const GET_USER_FOLLOWERS_REACTIVE = gql`
  query GetUserFollowersReactive($user: String!) {
    followers: followers(where: { user: { _eq: $user } }) {
      follower_id
      user
    }
  }
`

export const GET_USER_LIKES = gql`
  query getLikes($user: String!, $offset: Int, $limit: Int) {
    likes: profile_view(where: { user: { _eq: $user } }) {
      user
      length: likes
      userInfo {
        user
        list: likes(
          offset: $offset
          limit: $limit
          where: { liked: { _eq: true } }
        ) {
          user
          domain
          liked
          domainDetails: domainViewByDomain {
            name: domain
            expire_time
            taxonomies
            terms
            last_price
            likes
            last_sale_asset
          }
        }
      }
    }
  }
`

export const GET_USER_LIKES_REACTIVE = gql`
  query GetUsersLikesReactive($user: String!) {
    likes(where: { user: { _eq: $user } }) {
      liked
      user
      domain
      domainViewByDomain {
        name: domain
        expire_time
        taxonomies
        terms
        last_price
      }
    }
  }
`

export const IS_FOLLOWING = gql`
  query isFollowing($user: String!, $followerId: String!) {
    followers(
      where: { user: { _eq: $user }, follower_id: { _eq: $followerId } }
    ) {
      follower_id
    }
  }
`
