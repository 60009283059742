import Label from 'components/Label'
import View from 'components/View'

const SearchResultTitle = ({ text, button }) => {
  return (
    <View
      width="100%"
      justify="space-between"
      alignItems="center"
      padding="18px 0"
    >
      <Label size={13} color="#BCBCCC">
        {text}
      </Label>
      {button}
    </View>
  )
}

export default SearchResultTitle
