import { useCallback, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  GET_FEED_EVENT_QUERY,
  kodexApiContext
} from 'service/graphql/kodex-api'

export default timestampRange => {
  const [vars, setVars] = useState({})
  const [feedItemsLoading, setFeedItemsLoading] = useState(false)

  const [
    fetchFeedsData,
    { data: feedEvents, error: feedItemsError, fetchMore: feedEventsFetchMore }
  ] = useLazyQuery(GET_FEED_EVENT_QUERY, {
    variables: {
      ...vars,
      min_timestamp: timestampRange.min_timestampISO,
      max_timestamp: timestampRange.max_timestampISO
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    pollInterval: 10000,
    ...kodexApiContext
  })

  const getFeedItems = useCallback(
    async filter => {
      const {
        event_type,
        limit = 50,
        offset,
        min_domain_length,
        max_domain_length,
        min_price,
        max_price,
        search_terms,
        asset,
        event_types
      } = filter

      const variables = {
        limit: limit,
        offset: offset,
        min_domain_length,
        max_domain_length,
        min_price,
        max_price,
        event_type,
        min_timestamp: timestampRange.min_timestampISO,
        max_timestamp: timestampRange.max_timestampISO,
        terms: search_terms,
        event_types,
        search_asset: asset
      }

      setFeedItemsLoading(true)

      setVars(variables)

      await fetchFeedsData(variables)

      setFeedItemsLoading(false)
    },
    [fetchFeedsData, timestampRange]
  )

  const fetchMoreItems = useCallback(
    async filter => {
      const {
        event_type,
        limit = 100,
        offset,
        min_domain_length,
        max_domain_length,
        min_price,
        max_price,
        search_terms,
        asset,
        event_types
      } = filter

      const variables = {
        limit: limit,
        offset: offset,
        min_domain_length,
        max_domain_length,
        min_price,
        max_price,
        event_type,
        min_timestamp: timestampRange.min_timestampISO,
        max_timestamp: timestampRange.max_timestampISO,
        terms: search_terms,
        event_types,
        search_asset: asset
      }
      setFeedItemsLoading(true)

      await feedEventsFetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newResults = fetchMoreResult.get_feed
          if (newResults.length > 0) setFeedItemsLoading(false)
          return {
            get_feed: [...(previousResult?.get_feed ?? []), ...newResults]
          }
        }
      })
    },
    [feedEventsFetchMore]
  )

  const feedItems = useMemo(() => feedEvents?.get_feed ?? [], [feedEvents])

  return {
    getFeedItems,
    feedItems,
    feedItemsLoading,
    feedItemsError,
    fetchMoreItems
  }
}
