import styled from '@emotion/styled/macro'
import { ReactComponent as DollarGrey } from 'assets/dollar-grey.svg'
import { ReactComponent as EthPriceDark } from 'assets/eth-price-dark.svg'
import { ReactComponent as EthPriceGrey } from 'assets/eth-price-grey-2.svg'

const PriceRangeSelect = ({
  darkMode,
  minVal,
  maxVal,
  setMinVal,
  setMaxVal,
  selectedCurrency,
  setSelectedCurrency
}) => {
  const setUSD = () => setSelectedCurrency('USDC')
  const setETH = () => setSelectedCurrency('ETH')

  return (
    <Container>
      <CurrencySelect darkMode={darkMode}>
        <SelectOption
          darkMode={darkMode}
          active={selectedCurrency === 'ETH'}
          onClick={setETH}
        >
          {darkMode ? <EthPriceDark /> : <EthPriceGrey />}
        </SelectOption>
        <SelectOption
          darkMode={darkMode}
          active={selectedCurrency === 'USDC'}
          onClick={setUSD}
        >
          <DollarGrey />
        </SelectOption>
      </CurrencySelect>
      <InputsContainer>
        <Label left="14px">Min</Label>
        <CurrencyLabel left="20px">
          {selectedCurrency === 'ETH' ? (
            darkMode ? (
              <EthPriceDark />
            ) : (
              <EthPriceGrey />
            )
          ) : (
            <DollarGrey />
          )}
        </CurrencyLabel>
        <Input
          darkMode={darkMode}
          type="number"
          placeholder="0.0"
          value={minVal}
          onChange={e => setMinVal(e.target.value)}
        />
        <Label left="calc(50% + 23px)" color="#919ca6">
          Max
        </Label>
        <CurrencyLabel left="calc(50% + 29px)">
          {selectedCurrency === 'ETH' ? (
            darkMode ? (
              <EthPriceDark />
            ) : (
              <EthPriceGrey />
            )
          ) : (
            <DollarGrey />
          )}
        </CurrencyLabel>
        <Input
          darkMode={darkMode}
          type="number"
          placeholder="0.0"
          value={maxVal}
          onChange={e => setMaxVal(e.target.value)}
        />
      </InputsContainer>
    </Container>
  )
}

export default PriceRangeSelect

const Container = styled.div`
  height: 112px;
  width: 100%;
`

const CurrencySelect = styled.div`
  background: ${({ darkMode }) => (darkMode ? '#020202' : '#EAEAF1')};
  border-radius: 6px;
  display: flex;
  height: 48px;
  margin: 2px 0;
  padding: 4px;
  width: 100%;
`
const SelectOption = styled.div`
  align-items: center;
  background: ${({ active, darkMode }) =>
    active ? (darkMode ? '#1b1b1b' : 'white') : 'transparent'};
  border-radius: 6px;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: all 0.2 ease-in-out;
  width: 50%;

  rect {
    fill: transparent;
  }
`

const Input = styled.input`
  border: 1px solid ${({ darkMode }) => (darkMode ? '#333' : '#DADAE7')};
  border-radius: 8px;
  background: ${({ darkMode }) => (darkMode ? '#1b1b1b' : '#F6F6F9')};
  color: #a4a4a4;
  flex: 1;
  font-size: 18px;
  padding: 20px 5px 5px 30px;
  outline: none;
  width: 50%;
`

const InputsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 18px;
  position: relative;
`

const Label = styled.p`
  color: #919ca6;
  font-size: 13px;
  left: ${({ left }) => left};
  position: absolute;
  top: -7.5px;
`

const CurrencyLabel = styled.div`
  left: ${({ left }) => left};
  position: absolute;
  top: 33px;
  transform: translate(-50%, -50%);
  color: #919ca6;

  rect {
    fill: transparent;
  }
`
