import styled from '@emotion/styled/macro'
import BottomModal from 'components/BottomModal'
import Container from 'components/Layout/Container'
import { useAccount } from 'components/QueryAccount'
import Space from 'components/Space'
import View from 'components/View'
import React, { useCallback, useEffect, useState } from 'react'
import { getOffers } from 'service/rest/getOffers'
import { cancelAll } from 'utils/seaport'
import { ENS_CONTRACT_ADDRESS } from 'utils/utils'
import RequestModal from '../RequestModal'
import AccountSupportSettingTab from './AccountSupportSettingTab'
import CancelListingsModal from './modals/CancelListingsModal'
import NotificationSettingTab from './NotificationSettingTab'
import ProfileSettingTab from './ProfileSettingTab'
import { ethers } from 'ethers'

const SettingDialog = ({ onClose, open }) => {
  const [currentTab, setCurrentTab] = useState('PROFILE')

  const [cancelListingsModalOpen, setCancelListingsModalOpen] = useState(false)
  const [requestModalOpen, setRequestModalOpen] = useState(false)
  const [isCanceling, setIsCanceling] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const [listingsAmount, setListingsAmount] = useState(0)
  const [offersAmount, setOffersAmount] = useState(0)
  const [primaryName, setPrimaryName] = useState('')
  const [supportSeed, setSupportedSeed] = useState(1)

  const account = useAccount()

  const provider = new ethers.providers.StaticJsonRpcProvider(
    process.env.REACT_APP_LLAMA_NODES_URL,
    'homestead'
  )

  const resolveENSName = async ensName => {
    const resolvedAttempt = await provider
      .resolveName(ensName || '')
      .catch(() => null)

    const lookup = await provider
      .lookupAddress(resolvedAttempt)
      .catch(() => null)

    if (!lookup) return

    setPrimaryName(lookup)
  }

  useEffect(() => {
    resolveENSName(account)
  }, [account])

  const openCancelListingsModal = useCallback(() => {
    setCancelListingsModalOpen(true)
  }, [])

  const closeCancelListingsModal = useCallback(() => {
    setCancelListingsModalOpen(false)
  }, [])

  const handleCancelListings = useCallback(async () => {
    try {
      setIsCanceling(true)
      const seaportOrder = await cancelAll()
      const { data: res, wait } = await seaportOrder.transact()
      await wait().then(() => {
        setCancelled(true)
        setIsCanceling(false)
        setSupportedSeed(Math.random())
      })
    } catch (err) {
      setIsCanceling(false)
      throw new Error(err)
    }
  }, [])

  const openRequestModal = useCallback(() => {
    setRequestModalOpen(true)
  }, [])

  const closeRequestModal = useCallback(() => {
    setRequestModalOpen(false)
  }, [])

  useEffect(() => {
    const getOffersSentTokenIds = async () => {
      try {
        const fetchedOrders = await getOffers({
          address: ENS_CONTRACT_ADDRESS,
          limit: 1000,
          offerers: account
        })

        if (fetchedOrders?.length === 0) {
          setListingsAmount(0)
          setOffersAmount(0)
          return
        }

        const listings = fetchedOrders.length

        setListingsAmount(listings)
        setOffersAmount(fetchedOrders.length)
      } catch (err) {
        throw new Error(err)
      }
    }

    getOffersSentTokenIds()
  }, [supportSeed, account])

  return (
    <>
      <BottomModal open={open} onClose={onClose} contentBackground="#fff">
        <Container maxWidth="940px">
          <View>
            <VerticalTabs>
              <Tab
                selected={currentTab === 'Profile'}
                onClick={() => setCurrentTab('Profile')}
              >
                Profile
              </Tab>
              <Tab
                selected={currentTab === 'Notifcations'}
                onClick={() => setCurrentTab('Notifications')}
              >
                Notifications
              </Tab>
              <Tab
                selected={currentTab === 'Support'}
                onClick={() => setCurrentTab('Support')}
              >
                Support
              </Tab>
            </VerticalTabs>
            <View
              width="calc(100% - 300px)"
              height="calc(100vh - 140px)"
              key={supportSeed}
              scrollbar={`
              &::-webkit-scrollbar {
              display: none; /* for Chrome, Safari, and Opera */
              }
              -ms-overflow-style: none; /* for Internet Explorer, Edge */
              scrollbar-width: none; /* for Firefox */
            `}
            >
              <ProfileSettingTab
                selected={currentTab === 'PROFILE'}
                primaryName={primaryName}
              />
              <NotificationSettingTab
                selected={currentTab === 'Notifications'}
                successfulPurchase={false}
                bidActivity={false}
                itemSold={false}
                outbid={false}
                priceChange={false}
              />
              <AccountSupportSettingTab
                selected={currentTab === 'Support'}
                listingsCount={listingsAmount}
                offersCount={offersAmount}
                onCancelAllListingsClick={openCancelListingsModal}
                onSubmitRequestClick={openRequestModal}
              />
              <Space size={15} />
            </View>
          </View>
        </Container>
      </BottomModal>
      {cancelListingsModalOpen && (
        <CancelListingsModal
          open={cancelListingsModalOpen}
          onClose={closeCancelListingsModal}
          cancelListingsModal={handleCancelListings}
          canceling={isCanceling}
          cancelled={cancelled}
        />
      )}
      {requestModalOpen && (
        <RequestModal
          open={requestModalOpen}
          onClose={closeRequestModal}
          cancelListingsModal={closeRequestModal}
          successExitButtonLabel="Back to settings"
        />
      )}
    </>
  )
}

const VerticalTabs = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: -5px 100px -5px 0;
`

const Tab = styled.button`
  width: 100%;
  border-radius: 6px;
  background: ${props => (props.selected ? '#F3F3FA' : '#fff')};
  outline: none;
  border: none;
  padding: 20px;
  color: ${props => (props.selected ? '#020202' : '#BCBCCC')};
  font-size: 16px;
  font-family: 'Satoshi', sans-serif;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  margin: 5px 0;
`

export default SettingDialog
