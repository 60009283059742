import { useEffect, useMemo, useState } from 'react'
// import { useParams } from 'react-router-dom'

import TableIcon from 'components/Icon/TableIcon'
import Space from 'components/Space'
import View from 'components/View'

import { ReactComponent as Arrow } from 'assets/arrow-right.svg'
import { ReactComponent as ArrowTop } from 'assets/arrow-top.svg'
import { ReactComponent as EthPriceLight } from 'assets/eth-icon-dark.svg'
import Label from 'components/Label'
import moment from 'moment'
import NoItems from 'views/Checkout/components/NoItems'
import Offerer from './Offerer'

const MobileOffers = ({
  parsedOffers,
  cancelOffer,
  navItem,
  setHighestOffer,
  canceling,
  acceptOffer,
  accepting,
  ownedDomain,
  isRegistered
}) => {
  const [offers, setOffers] = useState([])
  const [page, setPage] = useState(1)

  useEffect(() => {
    // order offers by price
    const sortedOffers = parsedOffers.sort(
      (a, b) => Number(b.price.amount.decimal) - Number(a.price.amount.decimal)
    )

    // offers sorted by expiry date - latest
    const sortedByExpiryDate = parsedOffers.sort(
      (a, b) => b.expiration - a.expiration
    )

    const highestOfferAmount = parsedOffers?.reduce((acc, curr) => {
      const price = curr.price.amount.decimal

      if (price > acc) {
        return price
      } else {
        return acc
      }
    }, 0)

    setHighestOffer(highestOfferAmount)
    setOffers(sortedOffers)
  }, [parsedOffers, navItem])

  const pages = useMemo(() => Math.ceil(offers?.length / 3), [offers])

  console.log(parsedOffers)

  return parsedOffers?.length > 0 ? (
    <View
      width="100%"
      direction="column"
      borderRadius="8px"
      background="transparent"
    >
      <View
        width="100%"
        background={isRegistered ? 'white' : '#1B1B1B'}
        direction="column"
        gap="32px"
        padding="16px"
      >
        {offers?.slice((page - 1) * 3, page * 3).map((offer, i) => (
          <View width="100%" direction="column" gap="16px" key={i}>
            <View
              direction="row"
              width="100%"
              justify="space-between"
              alignItems="center"
            >
              <View direction="row" gap="8px" alignItems="center">
                <TableIcon
                  icon={<ArrowTop />}
                  stroke="#0698A8"
                  background="#E8F5F6"
                />
                <Space size={20} />
                <Label>Offer</Label>
              </View>
              <View alignItems="center" gap="8px">
                {isRegistered ? <EthPriceLight /> : <EthPriceLight />}
                <Label
                  size={16}
                  number
                  // style={{ textTransform: 'capitalize' }}
                  color={isRegistered ? '#47596B' : '#fff'}
                >
                  {offer.price.amount.decimal
                    .toLocaleString('default')
                    .replaceAll(',', ' ')}
                </Label>
              </View>
            </View>
            <View
              direction="row"
              width="100%"
              justify="space-between"
              alignItems="center"
            >
              <Label color="#47596B88">From</Label>
              <Offerer
                data={offer}
                showPfp={false}
                link={false}
                color="#47596B"
              />
            </View>
            <View
              direction="row"
              width="100%"
              justify="space-between"
              alignItems="center"
            >
              <Label color="#47596B88">Date</Label>
              <Label color="#47596B">
                {moment(offer?.validFrom * 1000).format('DD MMMM YYYY')}
              </Label>
            </View>
            <View
              direction="row"
              width="100%"
              justify="space-between"
              alignItems="center"
            >
              <Label color="#47596B88">Expires</Label>
              <Label color="#47596B">
                {moment(offer?.expiration * 1000).format('DD MMMM YYYY')}
              </Label>
            </View>
          </View>
        ))}
      </View>
      <View width="100%" justify="space-between" padding="16px">
        <View
          gap="6px"
          alignItems="center"
          onClick={() => {
            if (page === 1) return
            setPage(page - 1)
          }}
        >
          <Arrow
            stroke={page === 1 ? '#919CA655' : '#919CA6'}
            height={11}
            style={{
              transform: 'rotate(180deg)'
            }}
          />
          <Label color={page === 1 ? '#919CA655' : '#919CA6'}>Prev</Label>
        </View>
        <View gap="8px">
          <Label color={isRegistered ? '#47596B' : 'white'}>{page}</Label>
          <Label color={isRegistered ? '#919CA6' : 'white'}>OF</Label>
          <Label color={isRegistered ? '#47596B' : 'white'}>{pages}</Label>
        </View>
        <View
          gap="6px"
          alignItems="center"
          onClick={() => {
            if (page === pages) return
            setPage(page + 1)
          }}
        >
          <Label color={page === pages ? '#919CA655' : '#919CA6'}>Next</Label>
          <Arrow
            stroke={page === pages ? '#919CA655' : '#919CA6'}
            height={11}
          />
        </View>
      </View>
    </View>
  ) : (
    <NoItems label="No offers" />
  )
}

export default MobileOffers
