import { useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  GET_DOMAIN_PRICE_HISTORY,
  kodexApiContext
} from 'service/graphql/kodex-api'

export default (domainName, duration) => {
  const timestampRange = useMemo(() => {
    const minDate = new Date()
    const maxDate = new Date()
    minDate.setDate(maxDate.getDate() - duration).toString()

    const dateRange = {
      min_date: duration ? minDate.toISOString() : null,
      max_date: maxDate.toISOString()
    }

    if (!duration) {
      dateRange.min_date = undefined
    }

    return dateRange
  }, [duration])

  const fetchAllTimeHistory = !duration

  const [fetchPriceHistory, { data, loading, error }] = useLazyQuery(
    GET_DOMAIN_PRICE_HISTORY(fetchAllTimeHistory),
    {
      variables: {
        name: domainName,
        ...timestampRange
      },
      pollInterval: 10000,
      ...kodexApiContext
    }
  )

  useEffect(() => {
    fetchPriceHistory({
      name: domainName,
      ...timestampRange
    })
  }, [timestampRange, domainName])

  const priceHistory = useMemo(() => {
    if (data?.ens_sales == null) {
      return []
    }
    return [...data.ens_sales].sort((a, b) => {
      return new Date(a.timestamp) - new Date(b.timestamp)
    })
  }, [data])

  return {
    priceHistory,
    loading,
    error
  }
}
