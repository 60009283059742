import { JETTY_URL } from './constants'
import { siweAuthedFetch } from './siweFetch'

export const checkAuthenticationStatus = async () => {
  const nonceRes = await siweAuthedFetch(`${JETTY_URL}/authenticate`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include'
  }).catch(() => ({ status: 400 }))

  return nonceRes.status === 200
}
