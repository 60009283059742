import styled from '@emotion/styled/macro'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItems};
  width: 100%;
  min-height: ${props => props.minHeight};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '1180px')};
  background: ${props => props.background};
  position: ${props => (props.fixed ? 'fixed' : 'relative')};
  left: 50%;
  transform: translateX(-50%);
  top: 0;
`

export default Container
