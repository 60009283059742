import { useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'

export const useDebouncedItemCount = ({ itemCount }) => {
  const [debouncedItemCount, setDebouncedItemCount] = useState(itemCount)

  const debounceItemCount = useRef(
    debounce(itemCount => {
      setDebouncedItemCount(itemCount)
    }, 200)
  )

  useEffect(() => {
    debounceItemCount.current(itemCount)
  }, [itemCount])

  return { debouncedItemCount }
}
