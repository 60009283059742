export const CATEGORIES = {
  Dictionary: ['Adjectives', 'Nouns', 'Verbs'],
  // Art: ['Architecture', 'Cinema', 'Music', 'Fine Art', 'Theater'],
  Names: ['Female', 'Male'],
  Characters: [
    'Archetype',
    'Professions',
    'Comic',
    'Film',
    'Game',
    'Literature',
    'Mythology'
  ],
  Crypto: [
    'Defi',
    'Exchanges',
    'Metaverse',
    'Scaling',
    'Tickers',
    'General',
    // 'Web3',
    'Research',
    'Protocol'
  ],
  Clubs: ['1K Club', '10K Club', 'Triple Emoji', '3L Words', '4L Words'],
  Creatures: ['Fantasy', 'Pokemon', 'Sci-Fi'],
  Animals: ['Bird', 'Fish', 'Mammal', 'Insect', 'Reptile', 'Dinosaur'],
  Dictionary: ['Adjectives', 'Nouns', 'Verbs'],
  Clubs: ['1K Club', '10K Club', 'Emojis', '3 Letter Words', '4 Letter Words'],
  Misc: ['Materials', 'Collective', 'Objects', 'Food'],
  // People: ['History'],
  Web: ['Meme', 'Slang', 'Subreddit', 'Website'],
  Company: [
    'Utilities',
    'Manufacturing',
    'Consumer',
    'Healthcare',
    'Finance',
    'Technology',
    'Media',
    'Real Estate',
    'Top 2000'
  ],
  Places: ['Country', 'Cities', 'POI']
}

export const TAGS = [
  {
    key: 'Crypto',
    name: '#CRYPTO',
    color: '#E3888F',
    subcategories: [
      {
        key: 'General',
        name: '#GENERAL'
      },
      {
        key: 'Defi',
        name: '#DEFI'
      },
      {
        key: 'Exchanges',
        name: '#EXCHANGES'
      },
      {
        key: 'Metaverse',
        name: '#METAVERSE'
      },
      {
        key: 'Scaling',
        name: '#SCALING'
      },
      {
        key: 'Tickers',
        name: '#TICKERS'
      },
      {
        key: 'Research',
        name: '#RESEARCH'
      },
      {
        key: 'Protocols',
        name: '#PROTOCOLS'
      },
      {
        key: 'Web3',
        name: '#WEB3'
      }
    ]
  },
  {
    name: '#CLUBS',
    key: 'clubs',
    color: '#9398B2',
    subcategories: [
      {
        key: '1k-club',
        name: '#1K-CLUB'
      },
      {
        key: '10k-club',
        name: '#10K-CLUB'
      },
      {
        key: 'Emojis',
        name: '#TRIPLE EMOJI'
      },
      {
        key: '3-Letter-Words',
        name: '#3L WORDS'
      },
      {
        key: '4-Letter-Words',
        name: '#4L WORDS'
      }
    ]
  },
  {
    key: 'Dictionary',
    name: '#DICTIONARY',
    color: '#E3BA88',
    subcategories: [
      {
        key: 'Adjectives',
        name: '#ADJECTIVES'
      },
      {
        key: 'Nouns',
        name: '#NOUNS'
      },
      {
        key: 'Verbs',
        name: '#VERBS'
      }
    ]
  },
  {
    key: 'Places',
    name: '#PLACES',
    color: '#E3CF88',
    subcategories: [
      {
        key: 'Country',
        name: '#COUNTRY'
      },
      {
        key: 'Cities',
        name: '#CITIES'
      },
      {
        key: 'POI',
        name: '#POI'
      }
    ]
  },
  {
    key: 'Names',
    name: '#NAMES',
    color: '#0698A8',
    subcategories: [
      {
        key: 'Male',
        name: '#MALE'
      },
      {
        key: 'Female',
        name: '#FEMALE'
      }
    ]
  },
  {
    key: 'Creatures',
    name: '#CREATURES',
    color: '#BD8FCC',
    subcategories: [
      {
        key: 'Fantasy',
        name: '#FANTASY'
      },
      {
        key: 'Pokemon',
        name: '#POKEMON'
      },
      {
        key: 'Sci-fi',
        name: '#SCI-Fi'
      }
    ]
  },
  {
    key: 'Animals',
    name: '#ANIMALS',
    color: '#82BC84',
    subcategories: [
      {
        key: 'Mammal',
        name: '#MAMMAL'
      },
      {
        key: 'Reptile',
        name: '#REPTILE'
      },
      {
        key: 'Insect',
        name: '#INSECT'
      },
      {
        key: 'Fish',
        name: '#FISH'
      },
      {
        key: 'Bird',
        name: '#BIRD'
      },
      {
        key: 'Amphibian',
        name: '#AMPHIBIAN'
      },
      {
        key: 'Dinosaur',
        name: '#DINOSAUR'
      }
    ]
  },
  {
    key: 'Art',
    name: '#ART',
    color: '#E59F8A',
    subcategories: [
      {
        key: 'Architecture',
        name: '#ARCHITECTURE'
      },
      {
        key: 'Cinema',
        name: '#CINEMA'
      },
      {
        key: 'Theater',
        name: '#THEATER'
      },
      {
        key: 'Music',
        name: '#MUSIC'
      },
      {
        key: 'FineArt',
        name: '#FINE-ART'
      }
    ]
  },
  {
    key: 'People',
    name: '#PEOPLE',
    color: '#80A2D6',
    subcategories: [
      {
        key: 'History',
        name: '#HISTORY'
      }
    ]
  },
  {
    key: 'Characters',
    name: '#CHARACTERS',
    color: '#AF61AB',
    subcategories: [
      {
        key: 'Anime',
        name: '#ANIME'
      },
      {
        key: 'Archetype',
        name: '#ARCHETYPE'
      },
      {
        key: 'Professions',
        name: '#PROFESSIONS'
      },
      {
        key: 'Comic',
        name: '#COMIC'
      },
      {
        key: 'Film',
        name: '#FILM'
      },
      {
        key: 'Game',
        name: '#GAME'
      },
      {
        key: 'Literature',
        name: '#LITERATURE'
      },
      {
        key: 'Mythology',
        name: '#MYTHOLOGY'
      }
    ]
  },
  {
    key: 'Web',
    name: '#WEB',
    color: '#ABA3E3',
    subcategories: [
      {
        key: 'Meme',
        name: '#MEME'
      },
      {
        key: 'Slang',
        name: '#SLANG'
      },
      {
        key: 'Subreddit',
        name: '#SUBREDDIT'
      },
      {
        key: 'Website',
        name: '#WEBSITE'
      },
      {
        key: 'Social-Media',
        name: '#SOCIAL-MEDIA'
      }
    ]
  },
  {
    key: 'Misc',
    color: '#82CED9',
    subcategories: [
      {
        key: 'Finance',
        name: '#FINANCE'
      },
      {
        key: 'Abstract',
        name: '#ABSTRACT'
      },
      {
        key: 'Food',
        name: '#FOOD'
      },
      {
        key: 'Material',
        name: '#MATERIAL'
      },
      {
        key: 'Collective',
        name: '#COLLECTIVE'
      },
      {
        key: 'Objects',
        name: '#OBJECTS'
      }
    ],
    name: '#MISCELLANEOUS'
  },
  {
    key: 'Company',
    color: '#7ACCBD',
    subcategories: [
      {
        key: 'Utilities',
        name: '#UTILITIES'
      },
      {
        key: 'Manufacturing',
        name: '#MANUFACTURING'
      },
      {
        key: 'Consumer',
        name: '#CONSUMER'
      },
      {
        key: 'Healthcare',
        name: '#HEALTHCARE'
      },
      {
        key: 'Finance',
        name: '#FINANCE'
      },
      {
        key: 'Technology',
        name: '#TECHNOLOGY'
      },
      {
        key: 'Media',
        name: '#MEDIA'
      },
      {
        key: 'Real-Estate',
        name: '#REAL-ESTATE'
      },
      {
        key: 'Top-2000',
        name: '#TOP 2000'
      }
    ],
    name: '#COMPANY'
  }
]

export const CATEGORY_PRIORITY_LIST = [
  '1K Club',
  '10K Club',
  'Emojis',
  'Defi',
  'Exchanges',
  'Metaverse',
  'Scaling',
  'Tickers',
  'General',
  // 'Web3',
  'Research',
  'Protocol',
  'Fantasy',
  'Pokemon',
  'Sci-Fi',
  'Utilities',
  'Manufacturing',
  'Consumer',
  'Healthcare',
  'Finance',
  'Technology',
  'Media',
  'Real Estate',
  'Top 2000',
  'Meme',
  'Slang',
  'Subreddit',
  'Website',
  'Archetype',
  'Professions',
  'Comic',
  'Film',
  'Game',
  'Literature',
  'Mythology',
  'Female',
  'Male',
  'Country',
  'Cities',
  'POI',
  'Materials',
  'Collective',
  'Objects',
  'Food',
  '3 letter words',
  '4 letter words',
  'Adjectives',
  'Nouns',
  'Verbs'
]
