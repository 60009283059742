import React from 'react'
import flatten from 'lodash/flatten'

function getTableColumns(children) {
  const childrenArray = Array.isArray(children) ? children : [children]

  const flattenColumns = flatten(childrenArray).map(column => {
    if (column === React.Fragment || column.type === React.Fragment) {
      return getTableColumns(column.props?.children)
    } else if (Array.isArray(column)) {
      return getTableColumns(column)
    }

    return column
  })

  return flatten(flattenColumns).filter(Boolean)
}

export default getTableColumns
