import styled from '@emotion/styled/macro'
import { ReactComponent as ArrowBottom } from 'assets/arrow-down-white.svg'
import AvatarLogo from 'assets/kodex-logo-avatar-light.png'
import Time from 'components/DateTime/Time'
import KodexIcon from 'components/Icon/KodexIcon'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { BigNumber, ethers, utils } from 'ethers'
import useGetDomainCategories from 'hooks/useGetDomainCategories'
import useUserProfile from 'hooks/useUserProfile'
import moment from 'moment'
import 'moment-timezone'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import EventType from './EventType'
import SummaryFirstColumn from './SummaryFirstColumn'
import SummaryFourthColumn from './SummaryFourthColumn'
import SummarySecondColumn from './SummarySecondColumn'
import SummaryThirdColumn from './SummaryThirdColumn'

const FeedAccordion = props => {
  const {
    data: dataProp = {},
    expanded: expandedProp,
    showLoading,
    ...rest
  } = props

  const [expanded, setExpandedState] = useState(expandedProp === true)

  const handleChange = useCallback(() => {
    setExpandedState(!expanded)
  }, [expanded, setExpandedState])

  if (showLoading) {
    return (
      <View data-in="fade-up">
        <AccordionLoading expanded={expanded} handleChange={handleChange} />
      </View>
    )
  }

  const data = {
    domain: dataProp.domain,
    price: dataProp.price,
    event: dataProp.event_type,
    time: dataProp.start_time,
    endTime: dataProp.end_time,
    from:
      dataProp.event_type === 'listing' || dataProp.event_type === 'offer'
        ? dataProp.fromaddr.replace(/(0{24})/g, '') // TODO: trimming leading 0 because of Seaport, remove when backend will handle it
        : dataProp.fromaddr || '',
    to:
      dataProp.event_type === 'offer'
        ? dataProp.toaddr?.replace(/(0{24})/g, '') ?? '' // TODO: trimming leading 0 because of Seaport, remove when backend will handle it
        : dataProp.toaddr || '',
    categories: useGetDomainCategories(dataProp.domain),
    asset: dataProp.sale_asset || dataProp.asset || 'ETH'
  }

  return (
    <View data-in="fade-up" {...rest}>
      <View
        width="750px"
        direction="column"
        background="#1B1B1B"
        borderRadius="10px"
        margin="0 0 10px"
      >
        <AccordionSummary
          data={data}
          expanded={expanded}
          handleChange={handleChange}
        />
        {expanded && (
          <TransitionComponent>
            <AccordionDetails data={data} />
          </TransitionComponent>
        )}
      </View>
    </View>
  )
}

export default FeedAccordion

const AccordionSummary = ({ data, expanded, handleChange }) => {
  const history = useHistory()

  const { domain, price, event, time, asset, categories } = data

  const handleGoDomainSummary = e => {
    e.stopPropagation()
    history.push(`/domain/${domain}`)
  }

  const getPriceLabel = () => {
    if (asset === 'ETH' || asset === 'WETH') {
      return Number(
        Number(utils.formatEther(BigInt(price))).toFixed(3) || 1
      ).toString()
      // .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    }
    if (asset === 'DAI' || asset === 'USDC') {
      return (
        BigNumber.from(BigInt(price))
          .div(BigNumber.from(10).pow(4))
          .toNumber() /
        10 ** 2
      )
    }

    return price
  }

  return (
    <View
      width="100%"
      padding="20px 40px"
      justify="space-between"
      background="#262628"
      borderRadius="10px"
      cursor="pointer"
      onClick={handleChange}
    >
      <View width="25%" direction="column" padding="0 8px 0 0">
        <View
          onClick={
            categories?.[0]?.name
              ? () => {
                  history.push(
                    `/marketplace/${categories?.[0]?.name.toLowerCase()}`
                  )
                }
              : null
          }
        >
          <Label size={13} opacity="0.5" whiteSpace="nowrap">
            {categories?.[0]?.name ? `#${categories[0].name}` : 'Domain'}
          </Label>
        </View>

        <Space size={16} />
        <Label
          width="fit"
          maxWidth="90%"
          style={{ cursor: 'pointer' }}
          onClick={handleGoDomainSummary}
          size={16}
          lineHeight="40px"
          whiteSpace="nowrap"
        >
          {domain.length > 15 ? domain.slice(0, 15) + '...' : domain}
        </Label>
      </View>
      <View width="25%" direction="column" padding="0 8px 0 0">
        <Label size={13} opacity="0.5">
          Price
        </Label>
        <Space size={16} />
        <View alignItems="center">
          {/* {(asset === 'USDC' || asset === 'DAI') && (
            <View padding="9px 14px" background="#F3F3FA" borderRadius="6px">
              <Label color="#555" size={22}>
                $
              </Label>
            </View>
          )}
          {asset === 'WETH' && (
            <Avatar
              borderRadius="5px"
              width={27}
              height={30}
              background={'#fff0'}
              src={RedSmallEth}
              padding={0}
            />
          )}
          {asset === 'ETH' && (
            <KodexIcon icon={<EthPriceWhite />} padding={0} size={40} />
          )} */}
          {/* <Space size={20} /> */}
          <View maxWidth="100px">
            <Label number size={16}>
              {`${getPriceLabel()} ${asset}`}
            </Label>
          </View>
        </View>
      </View>
      <View width="25%" direction="column" padding="0 8px 0 0">
        <Label size={13} opacity="0.5">
          Event
        </Label>
        <Space size={16} />
        {event && <EventType event={event} />}
      </View>
      <View width="25%" direction="column" padding="0 8px 0 0">
        <Label size={13} opacity="0.5">
          Time
        </Label>
        <Space size={16} />
        <Label width="100%" size={16} lineHeight="40px">
          <Time
            timeString={moment.tz(time * 1000, 'UTC').format()}
            fromFeed={true}
          />
        </Label>
      </View>

      <IconWrapper rotate={expanded ? 'rotate(180deg)' : ''}>
        <KodexIcon icon={<ArrowBottom />} size={10} padding={0} />
      </IconWrapper>
    </View>
  )
}

const AccordionDetails = ({ data }) => {
  const history = useHistory()
  const { from, to, price, event, categories, endTime, time } = data

  const handleGoProfile = useCallback((e, address) => {
    e.stopPropagation()
    if (address !== ethers.constants.AddressZero) {
      history.push(`/profile/${address.replace('0x', '')}`)
    }
  }, [])

  const { profile: fromProfile } = useUserProfile(from)
  const { profile: toProfile } = useUserProfile(to)
  const { picture: fromPicture } = fromProfile.userInfo || {}
  const { picture: toPicture } = toProfile.userInfo || {}
  const { username: fromUsername } = fromProfile.userInfo || {}
  const { username: toUsername } = toProfile.userInfo || {}

  return (
    <View
      width="100%"
      padding="20px 50px 20px 40px"
      justify="space-between"
      alignItems="center"
    >
      <SummaryFirstColumn
        avatar={fromPicture}
        onClick={handleGoProfile}
        address={fromUsername || from}
        eventType={event}
      />
      {event !== 'registration' && (
        <SummarySecondColumn
          eventType={event}
          label={
            event === 'offer' || event === 'sale' ? toUsername || to : 'Kodex'
          }
          avatar={
            event === 'offer' || event === 'sale' ? toPicture : AvatarLogo
          }
          onClick={handleGoProfile}
        />
      )}
      {event !== 'sale' && (
        <SummaryThirdColumn
          eventType={event}
          category={categories?.[0]?.name}
          data={
            event === 'registration'
              ? price
              : event === 'listing'
              ? 'Seaport'
              : price
          }
        />
      )}
      {event !== 'sale' && (
        <SummaryFourthColumn eventType={event} time={time} endTime={endTime} />
      )}
      {event === 'registration' && <View flex={1} />}{' '}
      {/*TODO: temporary removing second column*/}
    </View>
  )
}

const AccordionLoading = ({ expanded, eventType, handleChange }) => (
  <View
    width="750px"
    direction="column"
    background="#1B1B1B"
    borderRadius="10px"
    margin="0 0 10px"
  >
    <View
      width="100%"
      padding="20px 40px"
      justify="space-between"
      background="#262628"
      borderRadius="10px"
      cursor="pointer"
      onClick={handleChange}
    >
      <View width="25%" direction="column" padding="0 8px 0 0">
        <View
          width="75px"
          height="13px"
          background="linear-gradient(to right, #404040, #545454, #404040, #404040, #404040)"
          animation="loading 1s infinite"
          borderRadius="6px"
        />
        <Space size={16} />
        <View height="40px" alignItems="center" width="100px">
          <View
            width="100%"
            height="16px"
            background="linear-gradient(to right, #404040, #545454, #404040, #404040, #404040)"
            animation="loading 1s infinite"
            borderRadius="6px"
          />
        </View>
      </View>
      <View width="25%" direction="column" padding="0 8px 0 0">
        <Label size={13} opacity="0.5">
          Price
        </Label>
        <Space size={16} />
        <View height="40px" alignItems="center" width="100px">
          <View
            width="100%"
            height="16px"
            background="linear-gradient(to right, #404040, #545454, #404040, #404040, #404040)"
            animation="loading 1s infinite"
            borderRadius="6px"
          />
        </View>
      </View>
      <View width="25%" direction="column" padding="0 8px 0 0">
        <Label size={13} opacity="0.5">
          Event
        </Label>
        <Space size={16} />
        <View
          width="67px"
          height="40px"
          background="linear-gradient(to right, #404040, #545454, #404040, #404040, #404040)"
          animation="loading 1s infinite"
          borderRadius="6px"
        />
      </View>
      <View width="25%" direction="column" padding="0 8px 0 0">
        <Label size={13} opacity="0.5">
          Time
        </Label>
        <Space size={16} />
        <View height="40px" alignItems="center" width="120px">
          <View
            width="100%"
            height="16px"
            background="linear-gradient(to right, #404040, #545454, #404040, #404040, #404040)"
            animation="loading 1s infinite"
            borderRadius="6px"
          />
        </View>
      </View>

      <IconWrapper rotate={expanded ? 'rotate(180deg)' : ''}>
        <KodexIcon icon={<ArrowBottom />} size={10} padding={0} />
      </IconWrapper>
    </View>

    {expanded && (
      <TransitionComponent>
        <View
          width="100%"
          padding="20px 50px 20px 40px"
          justify="space-between"
          alignItems="center"
        >
          <SummaryFirstColumn eventType={eventType} loading />
          <SummarySecondColumn eventType={eventType} loading />
          {eventType !== 'sale' && (
            <SummaryThirdColumn eventType={eventType} loading />
          )}
          {eventType !== 'sale' && (
            <SummaryFourthColumn eventType={eventType} loading />
          )}
        </View>
      </TransitionComponent>
    )}
  </View>
)

const TransitionComponent = styled.div`
  width: 100%;
`

const IconWrapper = styled.div`
  transform: ${props => props.rotate};
  transition: transform 0.3s ease;
  align-self: center;
`
