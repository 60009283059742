export const MAIN_CATEGORY_COLORS = [
  '#E3BA88',
  '#0698A8',
  '#AE61AB',
  '#E3888F',
  '#9398B2',
  '#BD8FCC',
  '#82BC84',
  '#E59F8A',
  '#ABA3E3',
  '#55BEAB',
  '#E3CF88'
]

export const COLORS_BY_CATEGORY = [
  {
    category: 'Dictionary',
    color: '#E3BA88'
  },
  {
    category: 'Names',
    color: '#0698A8'
  },
  {
    category: 'Characters',
    color: '#AE61AB'
  },
  {
    category: 'Crypto',
    color: '#E3888F'
  },
  {
    category: 'Clubs',
    color: '#9398B2'
  },
  {
    category: 'Creatures',
    color: '#BD8FCC'
  },
  {
    category: 'Animals',
    color: '#82BC84'
  },
  {
    category: 'Misc',
    color: '#E59F8A'
  },
  {
    category: 'Web',
    color: '#ABA3E3'
  },
  {
    category: 'Company',
    color: '#7ACCBD'
  },
  {
    category: 'Places',
    color: '#E3CF88'
  }
]
