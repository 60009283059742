import React from 'react'
import View from 'components/View'
import Space from 'components/Space'
import Label from 'components/Label'

const AccountSupportCard = ({ title, content, button }) => {
  return (
    <View
      borderRadius="10px"
      border="1px solid #E1E1E8"
      padding="40px 25px 20px 30px"
      direction="column"
      width="100%"
    >
      <Space size={17} />
      <Label weight={600} size={16} color="#020202">
        {title}
      </Label>
      <Space size={34} />
      <Label size={16} color="#BCBCCC" lineHeight={1.6}>
        {content}
      </Label>
      <Space size={54} />
      {button}
      <Space size={17} />
    </View>
  )
}

export default AccountSupportCard
