import { ensBaseRegistrarAddress, ensContractAbi } from 'constants/ens'

const { ethers } = require('ethers')

const getExpiryDate = async domain => {
  const registrar = new ethers.Contract(
    ensBaseRegistrarAddress,
    ensContractAbi,
    new ethers.providers.StaticJsonRpcProvider(
      process.env.REACT_APP_LLAMA_NODES_URL,
      'homestead'
    )
  )

  const tokenId = ethers.BigNumber.from(
    ethers.utils.keccak256(
      ethers.utils.toUtf8Bytes(domain?.replace('.eth', ''))
    )
  ).toString()

  const expiration = await registrar.nameExpires(tokenId)

  return await expiration
}

export default getExpiryDate
