import Trending1 from 'assets/trending/1.jpeg'
import Trending2 from 'assets/trending/2.png'
import Trending3 from 'assets/trending/3.jpeg'
import Trending4 from 'assets/trending/4.jpeg'
import Trending5 from 'assets/trending/5.jpeg'
import Trending6 from 'assets/trending/6.jpeg'
import Trending7 from 'assets/trending/7.jpg'
import Trending8 from 'assets/trending/8.jpeg'
import TrendingTokenCard from 'components/Card/TrendingTokenCard'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import View from 'components/View'
import {
  topBarMarketplaceStyle,
  topBarRegistryStyle,
  useTopBarStyle
} from 'contexts/TopBarStyleProvider'
import gsap, { Expo, Power1 } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useMediaMax } from 'mediaQuery'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

const NotableListings = () => {
  const history = useHistory()

  const smallBP = useMediaMax('medium')

  const [labelSize, setLabelSize] = useState(110)
  const [buttonWidth, setButtonWidth] = useState('195px')
  const [buttonLabelSize, setButtonLabelSize] = useState(18)

  const [contentDirection, setContentDirection] = useState('row')
  const [contentMargin, setContentMargin] = useState('0')
  const [contentPadding, setContentPadding] = useState('0 0 25vh 0')
  const [contentCardMargin, setContentCardMargin] = useState('20px')

  const [evenContentSectionPadding, setEvenContentSectionPadding] = useState(
    '70px 0px'
  )

  const { setStyle } = useTopBarStyle()

  const sectionRef = useRef(null)

  useEffect(() => {
    const navBarChangeColorTrigger = ScrollTrigger.create({
      trigger: sectionRef.current,
      start: 'top top+=93',
      scrub: true,
      onEnter: () => {
        setStyle({ ...topBarRegistryStyle, topBarBgColor: '#020202' })
      },
      onLeaveBack: () => {
        setStyle({ ...topBarMarketplaceStyle, topBarBgColor: '#fff' })
      }
    })
    // pin the canvas with sphere
    gsap.set('.js-notable-sequence__content', { yPerent: 0 })
    gsap.to('.js-notable-sequence__content', {
      scrollTrigger: {
        //scroller: scroll.el,
        scrub: true,
        trigger: '.js-notable-sequence__content',
        start: 'top center',
        end: '+=140%',
        invalidateOnRefresh: true
      },

      yPercent: () => 140,
      ease: 'none'
    })

    gsap.set('.js-notable-sequence__content canvas', {
      scale: 1.1,
      y: '0'
    })
    // scale down canvas + transform up
    ScrollTrigger.create({
      trigger: '.js-notable-sequence__container',
      start: 'top top',
      onEnter: () => {
        gsap.to('.js-notable-sequence__content canvas', {
          scale: 0.55,
          y: () => '-60%', //????
          ease: Expo.easeOut,
          duration: 1
        })
      },
      onLeaveBack: () => {
        gsap.to('.js-notable-sequence__content canvas', {
          scale: 1.1,
          y: () => '0',
          ease: Expo.easeOut,
          duration: 1
        })
      }
    })

    // show notable listing title + scale down canvas + half dim
    // gsap.set('.js-notable-sequence__caption', { scale: 1.5, alpha: 0 })
    ScrollTrigger.create({
      trigger: '.js-notable-sequence__container',
      start: 'top top-=30%',
      onEnter: () => {
        // show title
        gsap.to('.js-notable-sequence__caption', {
          scale: 1,
          alpha: 1,
          ease: Expo.easeOut,
          duration: 0.5
        })

        // scale down canvas
        gsap.to('.js-notable-sequence__content canvas', {
          scale: 0.4,
          ease: Expo.easeOut,
          duration: 1
        })

        // dim
        gsap.to('.js-notable-sequence__dim', {
          alpha: 0.4,
          ease: Power1.ease,
          duration: 0.5
        })
      },
      onLeaveBack: () => {
        // hide title
        gsap.to('.js-notable-sequence__caption', {
          scale: 1.5,
          alpha: 0,
          ease: Expo.easeOut,
          duration: 0.5
        })

        // incrase canvas back
        gsap.to('.js-notable-sequence__content canvas', {
          scale: 0.55,
          ease: Expo.easeOut,
          duration: 1
        })
        // dim
        gsap.to('.js-notable-sequence__dim', {
          alpha: 0,
          ease: Power1.ease,
          duration: 0.5
        })
      }
    })

    // show button and dim content after caption shown
    gsap.set('.js-notable-sequence__button', { alpha: 0 })
    gsap.set('.js-notable-sequence__dim', { alpha: 0 })
    ScrollTrigger.create({
      trigger: '.js-notable-sequence__container',
      start: 'top top-=70%',
      onEnter: () => {
        gsap.to('.js-notable-sequence__button', {
          alpha: 1,
          ease: Power1.ease,
          duration: 0.2
        })
        gsap.to('.js-notable-sequence__dim', {
          alpha: 0.8,
          ease: Power1.ease,
          duration: 0.5
        })
      },
      onLeaveBack: () => {
        gsap.to('.js-notable-sequence__button', {
          alpha: 0,
          ease: Power1.ease,
          duration: 0.2
        })
        gsap.to('.js-notable-sequence__dim', {
          alpha: 0.4,
          ease: Power1.ease,
          duration: 0.5
        })
      }
    })

    // columns container going down
    gsap.set('.js-notable-sequence__columns', { yPercent: 0 })
    gsap.to('.js-notable-sequence__columns', {
      scrollTrigger: {
        scrub: true,
        trigger: '.js-notable-sequence__columns',
        start: 'top center',
        end: 'bottom -=50%top',
        invalidateOnRefresh: true
      },
      yPercent: 80,
      ease: 'none'
    })

    // columns container going down
    document
      .querySelectorAll('.js-notable-sequence__column')
      .forEach(element => {
        gsap.set(element, { yPercent: element.dataset.from })
        gsap.to(element, {
          scrollTrigger: {
            scrub: true,
            trigger: '.js-notable-sequence__columns',
            start: 'top bottom',
            end: 'bottom -=50%top',
            invalidateOnRefresh: true
          },
          yPercent: element.dataset.to,
          ease: 'none'
        })
      })

    // pink separator going down faster than columns
    gsap.set('.js-notable-sequence__separator', { yPercent: 0 })
    gsap.to('.js-notable-sequence__separator', {
      scrollTrigger: {
        scrub: true,
        trigger: '.js-notable-sequence__separator',
        start: 'top bottom',
        end: 'bottom top',
        invalidateOnRefresh: true
      },
      yPercent: 100,
      ease: 'none'
    })
    return () => navBarChangeColorTrigger.kill()
  }, [])

  useEffect(() => {
    if (smallBP) {
      setButtonLabelSize(16)
      setButtonWidth('168px')
      setContentDirection('column')
      setContentMargin('auto')
      setContentPadding('100px 0 0')
      setContentCardMargin('8px')
      setEvenContentSectionPadding('0px')
      setLabelSize(40)
    } else {
      setButtonLabelSize(18)
      setButtonWidth('195px')
      setContentDirection('row')
      setContentMargin('0')
      setContentPadding('0 0 25vh 0')
      setContentCardMargin('20px')
      setEvenContentSectionPadding('70px 0px')
      setLabelSize(110)
    }
  }, [smallBP])
  return (
    <View background="#020202" width="100%" overflow="hidden" ref={sectionRef}>
      <Container maxWidth="100%" alignItems="center">
        <View
          direction="column"
          justify="flex-start"
          alignItems="center"
          width="100%"
          position="relative"
        >
          <View height="200vh" className="js-notable-sequence__container">
            <View
              height="100vh"
              padding="20vh 0 0 0"
              className="js-notable-sequence__content"
            >
              <View
                position="absolute"
                top="-4%"
                left="50%"
                transform="translate(-50%, 0)"
                opacity="0"
                zIndex="20"
                direction="column"
                alignItems="center"
                justify="center"
                className="js-notable-sequence__caption"
              >
                <Label
                  className="js-notable-sequence__title"
                  size={labelSize}
                  color="#fff"
                  weight="400"
                  style={{ margin: '0 0 60px 0' }}
                >
                  Trending
                </Label>

                <RoundedButton
                  background="transparent"
                  hoverBackground="#0698A8"
                  color="#0698A8"
                  hoverColor="#fff !important"
                  transition="all 0.2s ease"
                  borderRadius="6px"
                  border="1px solid #0698A8"
                  width={buttonWidth}
                  height="60px"
                  className="js-notable-sequence__button"
                  onClick={() => history.push('/marketplace')}
                >
                  <Label size={buttonLabelSize} color="inherit" weight="400">
                    See more
                  </Label>
                </RoundedButton>
              </View>
              {/* <NotableSequence /> */}

              {/* dim */}
              <View
                position="absolute"
                top="-50%"
                left="0"
                right="0"
                bottom="0"
                width="100%"
                height="200vh"
                background="#000"
                opacity="0"
                className="js-notable-sequence__dim"
              />
            </View>
          </View>
        </View>
        <View
          width="100%"
          justify="center"
          margin={contentPadding}
          direction={contentDirection}
          overflow="hidden"
          height="800px"
          className="js-notable-sequence__columns"
          style={{ pointerEvents: 'none' }}
        >
          <View transform="rotate(10deg) translateX(-8%)">
            <View
              flex={1}
              direction="column"
              padding={evenContentSectionPadding}
              margin={contentMargin}
              className="js-notable-sequence__column"
              data-from="-50"
              data-to="15"
            >
              <TrendingTokenCard
                name="mail.eth"
                category="web"
                eth={300}
                likes={144}
                avatar={Trending1}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
              <TrendingTokenCard
                name="emma.eth"
                category="name"
                eth={25}
                likes={43}
                avatar={Trending2}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
              <TrendingTokenCard
                name="paradigm.eth"
                category="crypto"
                eth={1000}
                likes={211}
                avatar={Trending3}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
            </View>
            <View
              flex={1}
              direction="column"
              margin={contentMargin}
              className="js-notable-sequence__column"
              data-from="0"
              data-to="-50"
            >
              <TrendingTokenCard
                name="wizard.eth"
                category="character"
                eth={75}
                likes={98}
                avatar={Trending4}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
              <TrendingTokenCard
                name="sushi.eth"
                category="food"
                eth={48}
                likes={94}
                avatar={Trending5}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
              <TrendingTokenCard
                name="emma.eth"
                category="name"
                eth={25}
                likes={43}
                avatar={Trending2}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
            </View>
            <View
              flex={1}
              direction="column"
              padding={evenContentSectionPadding}
              margin={contentMargin}
              className="js-notable-sequence__column"
              data-from="-50"
              data-to="20"
            >
              <TrendingTokenCard
                name="paradigm.eth"
                category="crypto"
                eth={1000}
                likes={211}
                avatar={Trending3}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
              <TrendingTokenCard
                name="love.eth"
                category="abstract"
                eth={100}
                likes={110}
                avatar={Trending8}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
              <TrendingTokenCard
                name="mail.eth"
                category="web"
                eth={300}
                likes={144}
                avatar={Trending1}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
            </View>
            <View
              flex={1}
              direction="column"
              margin={contentMargin}
              className="js-notable-sequence__column"
              data-from="0"
              data-to="-70"
            >
              <TrendingTokenCard
                name="deepmind.eth"
                category="tech"
                eth={190}
                likes={36}
                avatar={Trending6}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
              <TrendingTokenCard
                name="888.eth"
                category="1K"
                eth={888}
                likes={232}
                avatar={Trending7}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
              <TrendingTokenCard
                name="wizard.eth"
                category="character"
                eth={75}
                likes={98}
                avatar={Trending4}
                margin={contentCardMargin}
                starStrokeColor="#fff"
                cardBorder="none"
                bodyBg="#212121"
                headerBg="#212121"
              />
            </View>
          </View>
        </View>
        <View
          width="100vw"
          height="25vh"
          position="absolute"
          bottom="0"
          background="#AF61AB"
          className="js-notable-sequence__separator"
        />
      </Container>
    </View>
  )
}

export default NotableListings
