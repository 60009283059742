import moment from 'moment'

const DATE_FORMAT = 'DD MMMM YYYY'

const Date = ({ dateString, format = DATE_FORMAT }) => {
  const date = moment(dateString)
  return <time dateTime={dateString}>{date.format(format)}</time>
}

export default Date
