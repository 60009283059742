export function formatETHPrice(input) {
  return Number(input)?.toLocaleString('default', {
    maximumFractionDigits:
      Number(input) < 1 ? 4 : Number(input) > 100000 ? 0 : 2
  })
}

export function formatUSDPrice(input) {
  return Number(input)?.toLocaleString('default', {
    maximumFractionDigits: 0
  })
}

export function formatNumber(input) {
  if (isNaN(input)) return null

  return Number(input).toLocaleString('default', {
    maximumFractionDigits: 0
  })
}

export const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
