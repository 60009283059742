import React, { useMemo } from 'react'
import styled from '@emotion/styled/macro'
import { ReactComponent as EthPriceIcon } from 'assets/eth-icon-dark.svg'
import Label from 'components/Label'
import View from 'components/View'
import RoundedButton from 'components/Forms/RoundedButton'
import Space from '../../Space'

export default function PriceCard({
  price,
  editable,
  ethPrice,
  desiredPriceModal = () => {}
}) {
  const priceInEth = useMemo(
    () =>
      (ethPrice * Number(price)).toLocaleString('default', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }) || 0,
    [ethPrice, price]
  )

  return (
    <PriceContainer>
      <View flex={1} direction="row" alignItems="flex-end" gap="1rem">
        <View
          flex={1}
          direction="row"
          alignItems="center"
          justify="start"
          gap="1rem"
        >
          <Label
            size={16}
            letterSpacing="1px"
            color="#020202"
            lineHeight={2.3125}
          >
            Price
          </Label>
        </View>
        {editable && (
          // currently disabled
          <RoundedButton
            border="1px solid #e1e1e8"
            padding="0.5rem 0"
            borderRadius="7px"
            // hoverBackground="#e1e1e8"
            onClick={() => desiredPriceModal(true)}
            color="#BCBCCC"
          >
            Select
          </RoundedButton>
        )}
      </View>
      <View direction="column">
        {editable && (
          <Label size={13} color="#BCBCCC">
            Current
          </Label>
        )}
        {editable && <Space size={23} />}
        <View gap="10px">
          <View padding={'9px 14px'} background="#F3F3FA" borderRadius="6px">
            <Label color="black" size={22}>
              $
            </Label>
          </View>
          <View
            direction="row-reverse"
            height="40px"
            justify="start"
            alignItems="center"
          >
            <View height="12px" gap="3px" alignItems="center">
              <Label
                opacity={parseFloat(price) === 0 ? '0' : '1'}
                style={{ transition: 'opacity 1000ms 100ms ease' }}
                size={11}
                letterSpacing="1px"
                color="#BCBCCC"
              >
                {Math.round(parseFloat(price) * 1000) / 1000}
              </Label>
              {parseFloat(price) > 0 && <EthPriceIcon height={13} width={13} />}
            </View>
            <Space size={12} />
            <Label size={16} letterSpacing="1px" color="#000">
              {priceInEth}
            </Label>
          </View>
        </View>
      </View>
    </PriceContainer>
  )
}

const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  align-items: center;
  padding: 0.75rem;
`
