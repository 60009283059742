import styled from '@emotion/styled/macro'
import BgImg from 'assets/profile/trophy-badge.svg'

const Badge = styled.div`
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  width: 60px;
  height: 60px;
  padding-top: 30px;
  background: url(${BgImg});
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
`

export default Badge
