import { JETTY_URL } from 'service/siwe/constants'

export const getTrendingDomains = async ({
  duration,
  offset = 0,
  limit = 20
}) => {
  try {
    const req = fetch(
      `${JETTY_URL}/trending/domains?time_span=${duration}&limit=${limit}&offset=${offset}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )

    const res = await req.then(res => res.json()).then(data => data)
    return res
  } catch (e) {
    throw new Error(e)
  }
}

export const getTrendingUsers = async ({
  duration,
  offset = 0,
  limit = 20
}) => {
  try {
    const req = fetch(
      `${JETTY_URL}/trending/users?time_span=${duration}&limit=${limit}&offset=${offset}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )

    const res = await req.then(res => res.json()).then(data => data)
    return res
  } catch (e) {
    throw new Error(e)
  }
}

export const getTrendingCategories = async () => {
  try {
    const req = fetch(`${JETTY_URL}/trending/categories`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    const res = await req.then(res => res.json()).then(data => data)
    return res
  } catch (e) {
    throw new Error(e)
  }
}
