import styled from '@emotion/styled/macro'
import { ReactComponent as EthPrice } from 'assets/eth-price-grey.svg'
import { ReactComponent as EthPriceLight } from 'assets/eth-price-light.svg'
import Date from 'components/DateTime/Date'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { useCallback, useEffect, useState } from 'react'
import IconWrapper from '../../components/IconWrapper'

const MobileDetails = ({
  tokenId,
  registrationDate,
  expires,
  domainName,
  isRegistered
}) => {
  const [isCopied, setCopied] = useState(false)

  const handleCopy = useCallback(() => {
    if (!isCopied) {
      setCopied(true)

      const el = document.createElement('textarea')
      el.value = tokenId
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    }
  }, [isCopied, tokenId])

  useEffect(() => {
    if (!isCopied) {
      return
    }

    const timeout = setTimeout(() => setCopied(false), 5000)

    return () => clearTimeout(timeout)
  }, [isCopied])

  const priceEstimate = 0.09

  return (
    <View
      flex={1}
      borderRadius="10px"
      width="100%"
      border={`1px solid ${isRegistered ? '#DADAE7' : '#4A4A4D'}`}
    >
      <View padding="16px" direction="column" width="100%">
        <Label color={isRegistered ? '#47596B' : '#fff'} size={16} weight={600}>
          Details
        </Label>
        <Space size={32} />
        <View direction="column" width="100%" gap="24px">
          <View
            width="100%"
            alignItems="center"
            justify="space-between"
            direction={'row'}
          >
            <Label size={16} color={isRegistered ? '#47596B88' : '#fff9'}>
              Registered
            </Label>
            <Label size={16} color={isRegistered ? '#47596B' : '#fff'}>
              {registrationDate ? (
                <Date dateString={registrationDate * 1000} />
              ) : (
                '-'
              )}
            </Label>
          </View>
          <View
            width="100%"
            alignItems="center"
            justify="space-between"
            direction={'row'}
          >
            <Label size={16} color={isRegistered ? '#47596B88' : '#fff9'}>
              {isRegistered ? 'Expiry date' : 'Expired'}
            </Label>
            <Label size={16} color={isRegistered ? '#47596B' : '#fff'}>
              {expires ? <Date dateString={expires * 1000} /> : '-'}
            </Label>
          </View>

          <View
            width="100%"
            alignItems="center"
            justify="space-between"
            direction={'row'}
          >
            <Label size={16} color={isRegistered ? '#47596B88' : '#fff9'}>
              Token ID
            </Label>
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  backgroundColor: 'rgb(0 0 0 / 0.75)',
                  borderRadius: '6px',
                  transition: 'opacity 100ms',
                  color: 'rgb(255 255 255)',
                  padding: '6px 12px',
                  opacity: isCopied ? 1 : 0,
                  position: 'absolute',
                  top: '-100%',
                  right: '0',
                  transform: 'translate(0, -50%)'
                }}
              >
                Token ID copied!
              </div>
              <button
                type="button"
                style={{ cursor: 'pointer' }}
                onClick={handleCopy}
              >
                <Label
                  number
                  size={16}
                  color={isRegistered ? '#47596B' : '#fff'}
                >
                  {tokenId.slice(0, 16)}...
                </Label>
              </button>
            </div>
          </View>
          <View
            width="100%"
            alignItems="center"
            justify="space-between"
            direction={'row'}
          >
            <View alignItems="center">
              <Label size={16} color={isRegistered ? '#47596B88' : '#fff9'}>
                Estimated value
              </Label>
              {/* <Space size={6} />
              <RedirectWrapper
                href={`https://ensappraiser.com/appraise/${domainName}`}
                target="_blank"
              >
                <Redirect stroke="#47596B88" />
              </RedirectWrapper> */}
            </View>
            {priceEstimate ? (
              <View justify="flex-end" alignItems="center">
                <IconWrapper size={24}>
                  {isRegistered ? <EthPrice /> : <EthPriceLight />}
                </IconWrapper>
                <Space size={10} />
                <Label size={16} color={isRegistered ? '#47596B' : '#fff'}>
                  -
                </Label>
              </View>
            ) : (
              <Space />
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

const StyledTable = styled.table`
  text-align: left;
  width: 100%;

  th {
    font-size: 12px;
    font-weight: 500;
    color: #bcbccc;
    height: 40px;
  }

  tbody {
    td {
      height: 40px;
      width: 20%;
    }
  }
`

const RedirectWrapper = styled.a`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export default MobileDetails
