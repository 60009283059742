import React from 'react'
import classNames from 'classnames'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

const Cell = React.forwardRef((props, ref) => {
  const {
    className,
    children,
    rowData,
    rowKey,
    dataKey,
    rowIndex,
    isHeaderCell,
    headerHeight,
    hasChildren,
    width,
    style,
    ...rest
  } = props

  const classes = classNames('table-cell', className)

  const styles = {
    ...style,
    ...(width
      ? {
          flex: `0 0 ${width}`
        }
      : {})
  }

  let cellContent =
    isNil(children) && rowData && dataKey ? get(rowData, dataKey) : children

  if (typeof children === 'function') {
    cellContent = children(rowData, rowIndex)
  }

  return (
    <div
      ref={ref}
      {...rest}
      className={classes}
      style={{ ...styles, border: 'none', outline: 'none', overflow: 'hidden' }}
    >
      <div className="table-cell-content">{cellContent}</div>
    </div>
  )
})

export default Cell

export const HoverActiveCell = ({ children, ...props }) => (
  <Cell className="active-on-hover" {...props}>
    {children}
  </Cell>
)
