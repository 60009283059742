import { createContext, useState } from 'react'

export const ModalContext = createContext({
  closable: true,
  setClosable: () => {}
})

const ModalProvider = ({ children }) => {
  const [closable, setClosable] = useState(false)

  return (
    <ModalContext.Provider
      value={{
        closable,
        setClosable
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
