import { ens_normalize } from '@adraffy/ens-normalize'
import { ReactComponent as ArrowDownWhite } from 'assets/arrow-down-white.svg'
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg'
import { ReactComponent as Clock } from 'assets/clock.svg'
import { ReactComponent as EthIconDark } from 'assets/eth-icon-dark.svg'
import { ReactComponent as EthIcon } from 'assets/eth-icon.svg'
import { ReactComponent as EthRedDark } from 'assets/eth-red-dark-mobile.svg'
import { ReactComponent as NameWarning } from 'assets/warning-name.svg'
import Avatar from 'components/Avatar'
import FeaturedNetTokenCard from 'components/Card/NetTokenCard/FeaturedNetTokenCard'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import { useAccount } from 'components/QueryAccount'
import View from 'components/View'
import { CATEGORIES, CATEGORY_PRIORITY_LIST, TAGS } from 'constants/category'
import useDomainResolver from 'hooks/useDomainResolver'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import { formatETHPrice, formatUSDPrice } from 'utils/format'
import LoadingMobileNetTokenCard from './LoadingMobileNetTokenCard'

const REGISTRATION_STATUS = {
  GRACE_PERIOD: 'grace period',
  RECENTLY_EXPIRED: 'premium',
  NEW: 'new'
}

/**
 *
 * An unregistered domain card will have the "grace period", "recently expired"
 * or "new" tag or no tag at all, depending on its expiration date.
 *
 * If a domain  is 0-90 days past its expiration date, it should have the "grace period" tag.
 * If a domain is 90-119 days past its expiration date, it should have the "recently expired" tag.
 * If a domain is 119-149 days past its expiration date, it should have the "new" tag.
 * If a domain is 149+ days past its expiration date, it should have no tag at all.
 * @param {string} expiration - UTC timestamp / 1000
 * @returns {REGISTRATION_STATUS}
 */
const getRegistrationStatus = expiration => {
  // check for undefined on 0 expirations
  if (!expiration) {
    return undefined
  }

  const expirationDate = new Date(parseInt(expiration))
  const now = new Date()

  const getFutureDate = days => {
    const futureDate = moment(expiration).add(days, 'days')
    return futureDate
  }

  if (moment(now).isAfter(getFutureDate(149))) {
    return undefined
  }

  if (moment(now).isAfter(getFutureDate(111))) {
    return REGISTRATION_STATUS.NEW
  }

  if (moment(now).isAfter(getFutureDate(90))) {
    return REGISTRATION_STATUS.RECENTLY_EXPIRED
  }

  if (moment(now).isAfter(expirationDate)) {
    return REGISTRATION_STATUS.GRACE_PERIOD
  }

  return undefined
}

let isVisible = false

const MobileTokenCard = ({
  width,
  windowWidth,
  margin,
  headerBg,
  headerColor,
  bodyBg,
  registeredBg,
  bodyColor,
  footerBg,
  cardBorder,
  domain,
  fieldGetters = {},
  addedToCart,
  from = 'Marketplace',
  onClick,
  onSelect,
  hasAnimation,
  index,
  priceInETH = true,
  ethPrice = 1,
  featured,
  isDarkMode,
  // onSelectDomain,
  // shouldFetch = false,
  profileTab,
  fromMyProfile = false
  // openOfferDialog = () => {}
}) => {
  // Early return if this card is a featured card
  if (featured) {
    return (
      <FeaturedNetTokenCard
        margin={margin}
        headerBg={headerBg}
        headerColor={headerColor}
        footerBg={footerBg}
        registeredBg={registeredBg}
        cardBorder={cardBorder}
        bodyBg={bodyBg}
        bodyColor={bodyColor}
        searchTerm={featured.searchTerm + '.eth'}
        onClick={onClick}
      />
    )
  }

  if (!domain) {
    return (
      <LoadingMobileNetTokenCard
        width={width}
        margin={margin}
        isDarkMode={isDarkMode}
        hasAnimation={true}
        index={index}
        windowWidth={windowWidth}
      />
    )
  }

  const history = useHistory()

  const userAddress = useAccount()

  // const cardRef = useRef(null)

  // const handleChangeVisibilit = entries => {
  //   const [entry] = entries
  //   isVisible = entry.intersectionRatio === 1
  // }

  // const observerOptions = {
  //   root: null,
  //   rootMargin: '0px',
  //   threshold: 1.0
  // }

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     handleChangeVisibilit,
  //     observerOptions
  //   )

  //   if (isVisible) {
  //     observer.unobserve(cardRef.current)
  //     return
  //   }

  //   if (cardRef.current) observer.observe(cardRef.current)

  //   return () => {
  //     if (cardRef.current) observer.unobserve(cardRef.current)
  //   }
  // }, [observerOptions, handleChangeVisibilit, cardRef, isVisible])

  const [tokenCardSeed, setTokenCardSeed] = useState(1)
  const resetCardSeed = () => setTokenCardSeed(Math.random())

  // const [headerHovered, setHeaderHovered] = useState(false)
  // const [bodyHovered, setBodyHovered] = useState(false)
  // const [footerHovered, setFooterHovered] = useState(false)
  // const [buying, setBuying] = useState(false)
  // const [refetchData, setRefetchData] = useState(false)
  const footerHovered = false
  const buying = false
  const refetchData = false
  const [usesSpecialCharacters, setUsesSpecialCharacters] = useState(false)
  // const [alreadyAnimated, setAlreadyAnimated] = useState(false)
  const [cardExpanded, setCardExpanded] = useState(false)
  const [categoryColor, setCategoryColor] = useState(undefined)

  const {
    name: domainName,
    tokenIdHex,
    price,
    expires,
    categories,
    ownerPfp,
    ownerAddress,
    ownerUsername,
    isRegistered,
    // likesCount,
    // isLikedByUser,
    isListed,
    lastPrice,
    lastPriceAsset,
    listingPrice
    // toggleLike
  } = useDomainResolver(domain, fieldGetters, refetchData)

  const expirationStatus = getRegistrationStatus(expires)

  const sortedCategories = useMemo(() => {
    if (!categories || !categories?.terms) return []
    const categoriesArrCopy = categories?.terms
    const sortedCategoriesIndexes = categoriesArrCopy
      .map(c => ({
        index: CATEGORY_PRIORITY_LIST.indexOf(c),
        category: c
      }))
      .sort((a, b) => a.index - b.index)

    return sortedCategoriesIndexes.map(obj => obj.category)
  }, [categories])

  useEffect(() => {
    try {
      const normalizedName = ens_normalize(domainName)
      if (normalizedName) setUsesSpecialCharacters(false)
    } catch (e) {
      setUsesSpecialCharacters(true)
    }
  }, [domainName])

  useEffect(() => {
    resetCardSeed()
  }, [isRegistered])

  const handleClickCard = () => {
    if (isRegistered && from.toLowerCase() === 'registry') {
      history.push(`/domain/${domain.name}`)
      return
    }
    onClick(domain)
  }

  // const handleToggleLike = e => {
  //   e.stopPropagation()
  //   toggleLike()
  // }

  // const handleGoSubCategory = useCallback(
  //   e => {
  //     e.stopPropagation()

  //     const category = sortedCategories?.[0]
  //     if (category) {
  //       const categoryKey = category
  //         .toLowerCase()
  //         .split(' ')
  //         .join('-')

  //       TAGS.forEach(tag => {
  //         if (tag.key.toLowerCase() === categoryKey.toLowerCase()) {
  //           history.push(`/marketplace?category=${tag.key.toLowerCase()}`)
  //         } else if (tag.subcategories.length > 0) {
  //           tag.subcategories.forEach(subCategory => {
  //             if (subCategory.key.toLowerCase() === categoryKey.toLowerCase()) {
  //               history.push(`/marketplace?category=${tag.key.toLowerCase()}`)
  //             }
  //           })
  //         }
  //       })
  //     }
  //   },
  //   [categories]
  // )

  const getCategoryColor = () => {
    const category = sortedCategories?.[0]
    if (category) {
      const categoryKey = category
        .toLowerCase()
        .split(' ')
        .join('-')

      TAGS.forEach(tag => {
        if (tag.key.toLowerCase() === categoryKey.toLowerCase()) {
          setCategoryColor(
            MAIN_CATEGORY_COLORS[Object.keys(CATEGORIES).indexOf(tag.key)]
          )
          return
        } else if (tag.subcategories.length > 0) {
          tag.subcategories.forEach(subCategory => {
            if (subCategory.key.toLowerCase() === categoryKey.toLowerCase()) {
              setCategoryColor(
                MAIN_CATEGORY_COLORS[Object.keys(CATEGORIES).indexOf(tag.key)]
              )
              return
            }
          })
        }
      })
    }
  }

  useEffect(() => {
    getCategoryColor()
  }, [sortedCategories])

  // const cardVisibility =
  //   !profileTab ||
  //   profileTab === 'unlisted' ||
  //   profileTab === 'listed' ||
  //   domain.name.length >= 7

  const priceUSD = Number(price) * Number(ethPrice)

  const listingPriceUSD = Number(listingPrice) * Number(ethPrice)

  // const fetchOrder = useCallback(async name => {
  //   if (name?.length === 0) return

  //   try {
  //     const tokenId = ethers.BigNumber.from(
  //       ethers.utils.keccak256(
  //         ethers.utils.toUtf8Bytes(name.replace('.eth', ''))
  //       )
  //     ).toString()

  //     const fetchedOrders = await getOrders({
  //       assetContractAddress: ENS_CONTRACT_ADDRESS,
  //       token_ids: tokenId,
  //       limit: 100
  //     })

  //     if (!fetchedOrders.orders) {
  //       return
  //     }

  //     const ordersTransformed = await fetchedOrders.orders.map(order => {
  //       const transformedProtocolData = reTransformOrderData(
  //         order.protocol_data
  //       )
  //       return {
  //         parameters: {
  //           ...transformedProtocolData.parameters,
  //           counter: order.counter
  //         },
  //         signature: transformedProtocolData.signature
  //       }
  //     })

  //     return ordersTransformed
  //   } catch (err) {
  //     throw new Error(err)
  //   }
  // }, [])

  // const handlePurchaseDomain = async () => {
  //   try {
  //     const domainOrder = await fetchOrder(domainName)
  //     const fulfillOrders = await buy(domainOrder)
  //     setBuying(true)
  //     const ordersFulfilled = fulfillOrders.executeAllActions()
  //     ;(await ordersFulfilled).wait().then(() => {
  //       setTimeout(() => {
  //         setBuying(false)
  //         setRefetchData(true)
  //         resetCardSeed()
  //       }, 1000)
  //     })
  //   } catch (err) {
  //     setBuying(false)
  //     throw new Error(err)
  //   }
  // }

  // const handleFooterClick = e => {
  //   e.stopPropagation()
  //   if (expirationStatus === REGISTRATION_STATUS.GRACE_PERIOD) return
  //   if (
  //     isRegistered &&
  //     userAddress?.toLowerCase() === ownerAddress?.toLowerCase() &&
  //     !fromMyProfile
  //   ) {
  //     history.push(`/listing/${tokenIdHex}`)
  //     return
  //   }

  // if (!isRegistered && from === 'MARKETPLACE') {
  //   onSelect(isRegistered)
  //   return
  // }

  // if (isListed && isRegistered) {
  //   handlePurchaseDomain()
  //   return
  // }

  // if (isRegistered && !isListed) {
  //   openOfferDialog(domainName, lastPrice)
  //   return
  // }

  //   onSelect()
  // }

  return (
    <View
      // ref={cardRef}
      width="calc(100vw - 32px)"
      height="fit"
      // maxHeight={
      //   cardExpanded
      //     ? isRegistered
      //       ? '290px'
      //       : '256px'
      //     : sortedCategories?.length === 0 && from.toLowerCase() !== 'registry'
      //     ? '127px'
      //     : '152px'
      // }
      border={from.toLowerCase() === 'registry' && `1px solid #4A4A4D`}
      borderRadius="10px"
      transition="0.15s ease-out"
      // style={{
      //   animation: isVisible ? 'fadeIn 0.4s ease-out' : '',
      //   display: cardVisibility ? 'flex' : 'none'
      // }}
      // opacity={!hasAnimation || isVisible ? 1 : 0}
      zIndex={1000}
    >
      <View
        width="100%"
        height="fit"
        // maxHeight={
        //   cardExpanded
        //     ? isRegistered
        //       ? '100%'
        //       : '254px'
        //     : isRegistered
        //     ? '160px'
        //     : '150px'
        // }
        direction="column"
        background="transparent"
        overflow="hidden"
        borderRadius="10px"
        key={tokenCardSeed}
      >
        <View
          flex={1}
          // cursor="pointer"
          direction="column"
          width="100%"
          justify="space-between"
          height="fit"
          maxHeight={'80px'}
          background={addedToCart ? '#0698A8' : bodyBg}
          borderRadius="6px"
          padding="16px"
          style={{
            borderBottom:
              from.toLowerCase() === 'registry' &&
              `1px solid ${
                from.toLowerCase() !== 'registry' ? '#E1E1E8' : '#4A4A4D'
              }`
          }}
          gap="10px"
          zIndex={1000}
        >
          <View width="100%" justify="space-between" alignItems="center">
            <View justify="start" alignItems="center" gap="5px" maxWidth="75%">
              <Label
                cursor="pointer"
                size={17}
                weight="700"
                color={
                  addedToCart && from.toLowerCase() === 'marketplace'
                    ? bodyBg
                    : bodyColor
                }
                whiteSpace="nowrap"
                hoverColor={
                  !isRegistered ? '#ccc' : addedToCart ? '#ccc' : '#777'
                }
                onClick={e => {
                  e.stopPropagation()
                  handleClickCard()
                }}
              >
                {domainName}
              </Label>
              {usesSpecialCharacters && <NameWarning height={16} width={16} />}
            </View>
            <View justify="end" gap="5px" alignItems="center">
              <Label
                transition="0.2s ease"
                size={16}
                color={
                  addedToCart && from.toLowerCase() === 'marketplace'
                    ? bodyBg
                    : bodyColor
                }
                // color={
                //   !isRegistered ||
                //   listingPrice ||
                //   isListed ||
                //   ((footerHovered || (addedToCart && fromMyProfile)) &&
                //     userAddress?.toLowerCase() === ownerAddress?.toLowerCase())
                //     ? (isRegistered && !isListed) ||
                //       (fromMyProfile && addedToCart)
                //       ? '#A4A4A4'
                //       : from.toLowerCase() === 'registry'
                //       ? '#fff'
                //       : '#47596B'
                //     : from.toLowerCase() === 'registry'
                //     ? '#A4A4A4'
                //     : '#47596B'
                // }
                number
                align="right"
              >
                {/* {buying
                    ? 'BUYING...'
                    : footerHovered
                    ? fromMyProfile ||
                      userAddress?.toLowerCase() === ownerAddress?.toLowerCase()
                      ? isListed
                        ? 'EDIT'
                        : 'LIST'
                      : isListed
                      ? 'BUY NOW'
                      : isRegistered
                      ? 'OFFER'
                      : 'REGISTER'
                    : isListed
                    ? formatETHPrice(
                        priceInETH ? listingPrice : listingPriceUSD,
                        navigator.language
                      )
                    : !isRegistered
                    ? formatUSDPrice(priceInETH ? price : priceUSD)
                    : 'Unlisted'} */}
                {(footerHovered || (addedToCart && fromMyProfile)) &&
                userAddress?.toLowerCase() === ownerAddress?.toLowerCase()
                  ? isListed
                    ? 'Edit'
                    : 'List'
                  : isListed
                  ? priceInETH
                    ? formatETHPrice(listingPrice)
                    : formatUSDPrice(listingPriceUSD)
                  : !isRegistered
                  ? priceInETH
                    ? formatETHPrice(price)
                    : formatUSDPrice(priceUSD)
                  : 'Unlisted'}
              </Label>
              {(!isRegistered || listingPrice) &&
                !buying &&
                !(
                  footerHovered &&
                  userAddress?.toLowerCase() === ownerAddress?.toLowerCase()
                ) && (
                  <>
                    {priceInETH || Number(ethPrice) === 1 ? (
                      from.toLowerCase() === 'registry' ||
                      (addedToCart && isRegistered) ? (
                        <EthIcon height={18} width={18} />
                      ) : (
                        <EthIconDark height={18} width={18} />
                      )
                    ) : (
                      <Label color={isDarkMode ? '#fff' : '#47596B'} size={16}>
                        $
                      </Label>
                    )}
                  </>
                )}
            </View>
          </View>
          {!(isRegistered && sortedCategories?.length === 0) && (
            <View
              width="100%"
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <View gap="5px">
                <Label
                  size={16}
                  style={{ cursor: 'pointer' }}
                  color={
                    categoryColor ||
                    (from.toLowerCase() === 'registry' ? '#A4A4A4' : '#919CA6')
                  }
                >
                  {`${sortedCategories?.[0] || ''}`}
                </Label>
                <Label
                  size={16}
                  style={{ cursor: 'pointer' }}
                  color={
                    from.toLowerCase() === 'registry' ? '#A4A4A4' : '#919CA6'
                  }
                >
                  {sortedCategories?.length > 1
                    ? `+${sortedCategories?.length - 1}`
                    : ''}
                </Label>
              </View>
              {from.toLowerCase() === 'registry' && (
                <View alignItems="center" gap="5px">
                  <Label
                    size={16}
                    color={
                      from.toLowerCase() === 'registry' ? '#A4A4A4' : '#919CA6'
                    }
                    number
                  >
                    {isRegistered ? (
                      `${moment(expires).calendar()}`
                    ) : expirationStatus ? (
                      <Label
                        size={16}
                        color={
                          expirationStatus === 'premium' && !addedToCart
                            ? '#6E71C1'
                            : (expirationStatus === 'new' ||
                                expirationStatus === 'grace period') &&
                              !addedToCart
                            ? '#0698A8'
                            : '#fff'
                        }
                        whiteSpace="nowrap"
                      >
                        {expirationStatus}
                      </Label>
                    ) : (
                      'Unregistered'
                    )}
                  </Label>
                  {isRegistered && <Clock height={18} width={18} />}
                </View>
              )}
            </View>
          )}
        </View>
        <View
          width="100%"
          height={'fit'}
          // height={
          //   isRegistered
          //     ? cardExpanded
          //       ? '250px'
          //       : from.toLowerCase() === 'registry'
          //       ? '72px'
          //       : '82px'
          //     : cardExpanded
          //     ? '175px'
          //     : '72px'
          // }
          alignItems="center"
          justify="space-between"
          padding={
            from.toLowerCase() === 'registry'
              ? '16px 18px'
              : '26px 18px 16px 18px'
          }
          top={from.toLowerCase() !== 'registry' && '-10px'}
          borderRadius="10px"
          cursor="pointer"
          direction="column"
          background={from.toLowerCase() === 'registry' ? '#000' : '#F6F6F9'}
          zIndex={10}
        >
          <RoundedButton
            background={
              from.toLowerCase() === 'registry'
                ? cardExpanded
                  ? '#333333'
                  : '#1B1B1B'
                : cardExpanded
                ? '#47596B'
                : '#fff'
            }
            width="100%"
            height="40px"
            padding="12px 16px"
            borderRadius="4px"
            onClick={() => setCardExpanded(!cardExpanded)}
          >
            <View width="100%" justify="space-between" alignItems="center">
              <Label
                color={
                  from.toLowerCase() === 'registry'
                    ? '#FFF'
                    : cardExpanded
                    ? '#FFF'
                    : '#47596B'
                }
              >
                More info
              </Label>
              <div
                style={{
                  transition: '0.15s linear',
                  transform: cardExpanded ? 'rotate(180deg)' : 'rotate(0)'
                }}
              >
                {from.toLowerCase() === 'registry' || cardExpanded ? (
                  <ArrowDownWhite />
                ) : (
                  <ArrowDown />
                )}
              </div>
            </View>
          </RoundedButton>
          {cardExpanded && (
            <View
              direction="column"
              width="100%"
              gap="1rem"
              padding="16px 0 5px 0"
            >
              {lastPrice ? (
                <View width="100%" justify="space-between" alignItems="center">
                  <Label
                    cursor="pointer"
                    size={16}
                    color={
                      from.toLowerCase() === 'registry' ? '#A4A4A4' : '#919CA6'
                    }
                    whiteSpace="nowrap"
                    hoverColor={
                      !isRegistered ? '#ccc' : addedToCart ? '#ccc' : '#777'
                    }
                  >
                    Last sale
                  </Label>
                  <View alignItems="center" gap="5px">
                    <Label
                      size={16}
                      number
                      color={
                        from.toLowerCase() === 'registry'
                          ? '#E5E5E5'
                          : '#47596B'
                      }
                    >
                      {Number(lastPrice).toLocaleString('default', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                      })}
                    </Label>
                    {lastPriceAsset === 'ETH' ? (
                      from.toLowerCase() === 'registry' ? (
                        <EthIcon height={18} width={18} />
                      ) : (
                        <EthIconDark height={18} width={18} />
                      )
                    ) : lastPriceAsset === 'USDC' ||
                      lastPriceAsset === 'DAI' ? (
                      <Label size={16} color={!isRegistered ? '#fff' : '#000'}>
                        $
                      </Label>
                    ) : (
                      <EthRedDark height={20} />
                    )}
                  </View>
                </View>
              ) : (
                ''
              )}
              {isRegistered && (
                <View width="100%" justify="space-between" alignItems="center">
                  <Label
                    cursor="pointer"
                    size={16}
                    color={
                      from.toLowerCase() === 'registry' ? '#A4A4A4' : '#919CA6'
                    }
                  >
                    Owned by
                  </Label>
                  <View justify="end" gap="5px" alignItems="center">
                    <Label
                      size={16}
                      color={
                        from.toLowerCase() === 'registry'
                          ? '#E5E5E5'
                          : '#47596B'
                      }
                      align="right"
                    >
                      @{ownerUsername || ownerAddress?.slice(2, 7)}
                    </Label>
                    <View
                    // onClick={() => {
                    //   history.push(`/profile/${ownerAddress.slice(2)}`)
                    // }}
                    >
                      <Avatar
                        width={18}
                        src={ownerPfp}
                        borderRadius="6px"
                        background={'#fff0'}
                      />
                    </View>
                  </View>
                </View>
              )}
              <View width="100%" justify="space-between" alignItems="center">
                <Label
                  cursor="pointer"
                  size={16}
                  color={
                    from.toLowerCase() === 'registry' ? '#A4A4A4' : '#919CA6'
                  }
                >
                  {!isRegistered ? 'Expired' : 'Expire'}
                </Label>
                <View justify="end" gap="5px" alignItems="center">
                  <Label
                    size={16}
                    color={
                      from.toLowerCase() === 'registry' ? '#E5E5E5' : '#47596B'
                    }
                    align="right"
                  >
                    {`${moment(expires).calendar()}`}
                  </Label>
                  <Clock
                    height={18}
                    width={18}
                    stroke={
                      from.toLowerCase() === 'registry' ? '#E5E5E5' : '#47596B'
                    }
                  />
                </View>
              </View>
              {/* <View width="100%" justify="space-between" alignItems="center">
                <Label
                  size={16}
                  color={
                    from.toLowerCase() === 'registry' ? '#A4A4A4' : '#919CA6'
                  }
                  whiteSpace="nowrap"
                >
                  LIKES
                </Label>
                <View gap="6px" alignItems="center" padding="0 1px 0 0 ">
                  <Label
                    size={16}
                    color={
                      from.toLowerCase() === 'registry' ? '#E5E5E5' : '#47596B'
                    }
                    whiteSpace="nowrap"
                  >
                    {likesCount}
                  </Label>
                  {from.toLowerCase() === 'registry' ? (
                    <StarWhite width={16} height={16} stroke="#E5E5E5" />
                  ) : (
                    <StarBlack width={16} height={16} stroke="#47596B" />
                  )}
                </View>
              </View> */}
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

export default MobileTokenCard
