import React, { useMemo } from 'react'
import FooterLink from './FooterLink'
import Link from 'components/Forms/Link'
import { useLocation, useHistory } from 'react-router'

const FirstPageLink = () => {
  const location = useLocation()
  const history = useHistory()

  const currentRoute = useMemo(() => location.pathname.split('/')[1], [
    location.pathname
  ])

  const handleMarketplaceClick = () => {
    history.push('/marketplace', 60)
  }

  const handleRegistryClick = () => {
    history.push('/registry', 60)
  }

  if (currentRoute === 'marketplace') {
    return (
      <Link hoverOpacity={0.6} onClick={handleRegistryClick}>
        <FooterLink text="Registry" color="#fff" />
      </Link>
    )
  }

  return (
    <Link hoverOpacity={0.6} onClick={handleMarketplaceClick}>
      <FooterLink text="Marketplace" color="#fff" />
    </Link>
  )
}

export default FirstPageLink
