export default function(domainName, category, count = 5) {
  const url = `https://kodex-vectorization-model-v2.herokuapp.com/recommend`

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      input_word: domainName.split('.')[0],
      num_required: count,
      category: category
    }),
    redirect: 'follow'
  }

  return fetch(url, requestOptions).then(res => res.json())
}
