import React from 'react'
import styled from '@emotion/styled/macro'

const CardContainer = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  background: ${props => props.background};
  opacity: ${props => props.opacity};
  z-index: ${props => props.zIndex || 1};
`

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.background};
  border-radius: ${props => props.borderRadius};
  border: ${props => props.border};
  outline: ${props => props.outline};
  opacity: ${props => props.opacity};
  z-index: ${props => props.zIndex || 1};
  cursor: ${props => props.cursor};

  transform: none;
  transition: z-index 0.2s step-end, transform 0.2s ease;
  position: relative;

  > div {
    position: relative;
    z-index: 10;
  }

  &:hover {
    transform: translateY(-5px);
    transition: 0.2s step-start, transform 0.2s ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(8px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

const Card = React.forwardRef(
  (
    {
      width = '280px',
      height = '300px',
      background,
      hoverBackground,
      borderRadius,
      outline,
      border,
      margin,
      onClick,
      style,
      opacity,
      children,
      zIndex,
      cursor,
      hasShadowOnHover
    },
    ref
  ) => {
    return (
      <CardContainer
        ref={ref}
        style={{ ...style }}
        width={width}
        height={height}
        margin={margin}
        opacity={opacity}
        zIndex={zIndex}
      >
        <InnerContainer
          style={{ ...style }}
          onClick={onClick}
          width={width}
          height={'100%'}
          outline={outline}
          background={background}
          hoverBackground={hoverBackground}
          borderRadius={borderRadius}
          hasShadowOnHover={hasShadowOnHover}
          border={border}
          opacity={opacity}
          zIndex={zIndex}
          cursor={cursor}
        >
          {children}
        </InnerContainer>
      </CardContainer>
    )
  }
)

export default Card
