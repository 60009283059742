import { JETTY_URL } from './constants'

export const fetchNonce = async () => {
  const nonceRes = await fetch(`${JETTY_URL}/nonce`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include'
  })

  return nonceRes.text()
}
