import styled from '@emotion/styled'
import Post2 from 'assets/posts/ens-monthly-roundup-february-2023.md'
import Post1 from 'assets/posts/ens-monthly-roundup-january-2023.md'
import Post3 from 'assets/posts/ens-monthly-roundup-march-2023.md'
import NewsCard from 'components/Card/NewsCard'
import Container from 'components/Layout/Container'
import SimilarSection from 'components/SimilarSection'
import Space from 'components/Space'
import View from 'components/View'
import { useEffect, useMemo, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useLocation, useParams } from 'react-router'
import { POSTS } from '../News/posts'
import Header from './Header'

const NewsDetail = () => {
  const markdownPosts = [Post1, Post2, Post3]
  const contentRef = useRef(null)
  const { id } = useParams()
  const location = useLocation()
  const [content, setContent] = useState('')

  const post = useMemo(
    () => ({
      current: POSTS.find(post => post.url === id),
      morePosts: POSTS.filter(post => post.url !== id)
    }),
    [id, location]
  )

  // const MarkdownFile = useMemo(async () => {
  //   const File = lazy(() => import(`assets/posts/${post?.current?.url}.md`))
  //   console.log(await File, `assets/posts/${post?.current?.url}.md`)
  //   return await File
  // }, [post])

  useEffect(() => {
    const fetchMarkdownContent = () => {
      fetch(markdownPosts[POSTS.indexOf(post?.current)])
        .then(res => res.text())
        .then(text => {
          setContent(text)
        })
    }

    fetchMarkdownContent()
  }, [File, location, id])

  return (
    <View ref={contentRef} background="#fff" direction="column" width="100%">
      <Space size={170} />
      <Container maxWidth="750px">
        <Header
          date={post.current?.date}
          image={post.current?.previewImage}
          title={post.current?.title}
        />
        <Space size={40} />
        {/* {post.current?.content.map(contentItem => (
          <ReactMarkdown children={content} />
          ))} */}
        <MarkdownView width="750px" maxWidth="750px" direction="column">
          <ReactMarkdown
            children={content}
            components={{
              img: ({ node, ...props }) => <MarkdownImage {...props} />,
              a: ({ node, ...props }) => (
                <MarkdownLinks {...props} target="blank" />
              )
            }}
          />
        </MarkdownView>
        <Space size={180} />
      </Container>
      {post.morePosts.length > 0 && (
        <SimilarSection
          ref={contentRef}
          data={post.morePosts}
          gap="20px"
          title="MORE"
          firstItemPositionCoefficient={3}
          itemsRenderer={(post, _) => (
            <NewsCard
              key={post.url}
              title={post.title}
              date={post.date}
              avatar_url={post.previewImage}
              articleUrl={post.url}
            />
          )}
        />
      )}
    </View>
  )
}

const MarkdownView = styled(View)`
  font-family: 'Sora', sans-serif;

  * {
    font-family: 'Sora', sans-serif;
  }

  p,
  span,
  li {
    line-height: 2;
  }
`
const MarkdownImage = styled.img`
  border-radius: 10px;
  width: 750px;
`
const MarkdownLinks = styled.a`
  text-decoration: none;
  color: #0698a8 !important;
  transition: 0.15s linear;

  font-family: 'Sora', sans-serif !important;

  &:hover {
    color: #0698a899 !important;
  }
`

export default NewsDetail
