import styled from '@emotion/styled/macro'
import Label from 'components/Label'
import View from 'components/View'

const RegistrationLoadingAnimation = ({ step, registering }) => {
  return (
    <View
      width="100%"
      height="100%"
      justify="center"
      alignItems="center"
      position="absolute"
      top="0"
      left="0"
      zIndex="1000"
      background="#fffa"
      borderRadius="10px"
      direction="column"
      gap="1.25rem"
    >
      <div
        style={{
          display: 'flex',
          gap: '1px',
          flexDirection: 'column'
        }}
      >
        <UpperBlock />
        <MiddleBlock />
        <LowerBlock />
      </div>
      <Label color="#000" size={16}>
        {step === 1 && 'Approve commit in wallet'}
        {step === 2 && 'Wait for 1 minute'}
        {registering && 'Approve registration in wallet'}
      </Label>
      {(step === 2 || step === 1) && (
        <View
          background="#fff1ea"
          position="absolute"
          width="97%"
          padding="20px"
          left="1.5%"
          bottom="9px"
          borderRadius="6px"
        >
          Waiting period is required to verify all domains are still available
          to register
        </View>
      )}
    </View>
  )
}

export default RegistrationLoadingAnimation

const UpperBlock = styled.div`
  height: 6px;
  width: 6px;
  background-color: #0698a8;
  animation: loadingTop 2s infinite;

  @keyframes loadingTop {
    0% {
      transform: translateX(0) translateY(0);
    }
    25% {
      transform: translateX(220%) translateY(0);
    }
    50% {
      transform: translateX(220%) translateY(230%);
    }
    75% {
      transform: translateX(0) translateY(230%);
    }
    100% {
      transform: translateX(0) translateY(0);
    }
  }
`

const MiddleBlock = styled.div`
  height: 6px;
  width: 6px;
  background-color: #0698a8;
  transform: translateX(110%);
`

const LowerBlock = styled.div`
  height: 6px;
  width: 6px;
  background-color: #0698a8;
  transform: translateX(220%);

  animation: loadingBottom 2s infinite;

  @keyframes loadingBottom {
    0% {
      transform: translateX(220%) translateY(0);
    }
    25% {
      transform: translateX(0) translateY(0);
    }
    50% {
      transform: translateX(0) translateY(-230%);
    }
    75% {
      transform: translateX(220%) translateY(-230%);
    }
    100% {
      transform: translateX(220%) translateY(0);
    }
  }
`
