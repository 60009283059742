import { ReactComponent as Trash } from 'assets/trash.svg'
import Label from 'components/Label'
import LoadingSpinner from 'components/LoadingSpinner'
import Space from 'components/Space'
import View from 'components/View'
import useDomainResolver from 'hooks/useDomainResolver'
import { useEthPrice } from 'hooks/useEthPrice'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { formatETHPrice, formatUSDPrice } from 'utils/format'

const CATEGORIES = ['purchase', 'registration']
let priceAdded = false

const CartItem = ({
  domain,
  removeDomain,
  category,
  addToTotal,
  index,
  closeCart,
  priceInETH,
  itemsLoading,
  setItemsLoading
}) => {
  const domainName = domain.name

  const { isRegistered, isListed, listingPrice, price } = useDomainResolver(
    domain
  )

  const ethPrice = useEthPrice()

  const history = useHistory()

  const [isDataLoading, setIsDataLoading] = useState(true)

  useEffect(() => {
    if (category === CATEGORIES[0]) {
      addToTotal(Number(listingPrice) || 0, index)
    }
    if (category === CATEGORIES[1] && price) {
      addToTotal(Number(price) || 0, index)
    }
    if (
      (isListed && !listingPrice) ||
      isListed === undefined ||
      listingPrice === undefined
    ) {
      setIsDataLoading(true)
      return
    }
    if (isRegistered === false && price) {
      setIsDataLoading(false)
      return
    }

    setIsDataLoading(false)
  }, [isRegistered, isListed, listingPrice, price, category])

  useEffect(() => {
    if (category === CATEGORIES[0]) {
      let itemsLoadingCopy = itemsLoading
      itemsLoadingCopy.marketplace[index] = isDataLoading
      setItemsLoading(itemsLoadingCopy)
      return
    }
    if (category === CATEGORIES[1]) {
      let itemsLoadingCopy = itemsLoading
      itemsLoadingCopy.registry[index] = isDataLoading
      setItemsLoading(itemsLoadingCopy)
      return
    }
  }, [isDataLoading])

  const handleRemoveDomainClick = () => {
    removeDomain(domainName, index, Number(listingPrice) || 0)
  }

  const hasPrice = listingPrice || (isRegistered === false && price)

  const domainPrice = hasPrice
    ? priceInETH
      ? formatETHPrice(
          listingPrice ? listingPrice : isRegistered === false && price && price
        ) + ' ETH'
      : formatUSDPrice(
          listingPrice
            ? Number(listingPrice) * Number(ethPrice)
            : isRegistered === false &&
                price &&
                Number(price) * Number(ethPrice)
        ) + ' USD'
    : 'Unlisted'

  return (
    <View
      direction="row"
      width="100%"
      alignItems="center"
      justify="space-between"
      padding="10px 0"
    >
      <View
        direction="row"
        width="100%"
        justify="space-between"
        alignItems="center"
      >
        <View
          direction="column"
          alignItems="flex-start"
          width="50%"
          gap="0.4rem"
        >
          <Label
            color="black"
            cursor="pointer"
            hoverColor="#262628"
            size={16}
            width="11.5vw"
            whiteSpace="nowrap"
            overflow="hidden"
            onClick={() => {
              history.push(`/domain/${domain.name}`)
              closeCart()
            }}
          >
            {domain.name}
          </Label>
        </View>
        <View direction="row" alignItems="center" width="47.5%">
          <View direction="column" alignItems="flex-start">
            {isDataLoading ? (
              <LoadingSpinner margin="0 0 0 30px" />
            ) : (
              <Label
                number
                color={
                  (category === CATEGORIES[0] && listingPrice > 0) ||
                  (category === CATEGORIES[1] && price)
                    ? 'black'
                    : '#BCBCCC'
                }
                size={16}
                letterSpacing="1px"
              >
                {domainPrice}
              </Label>
            )}
          </View>
        </View>
      </View>
      <Space size={20} />
      <View
        justify="center"
        alignItems="center"
        cursor="pointer"
        onClick={handleRemoveDomainClick}
        minWidth="40px"
        minHeight="40px"
      >
        <Trash />
      </View>
    </View>
  )
}

export default CartItem
