import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from '@emotion/styled'
import Label from 'components/Label'
import Space from 'components/Space'
import Fractal from 'assets/fractal-request-modal.png'
import View from 'components/View'
import { ReactComponent as Cross } from 'assets/cross.svg'

const FileDropzone = ({ images, onDrop, removeImage, removeAllImages }) => {
  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.webp', '.jpeg', '.jpg']
    },
    multiple: true,
    onDrop: onDrop
  })

  return (
    <ImagePicker {...getRootProps()}>
      <input {...getInputProps()} />
      <Label size={16} color="#020202">
        ADD FILE OR DROP IT HERE
      </Label>
      <Space size={40} />
      {images.length === 0 ? (
        <PlaceholderImage
          src={Fractal}
          alt="fractal"
          width={200}
          height={200}
        />
      ) : (
        <View
          direction="column"
          width="100%"
          justify="space-between"
          height="100%"
        >
          <View direction="column" width="100%">
            {images.map(image => (
              <View
                padding="20px"
                alignItems="center"
                justify="space-between"
                width="100%"
                margin="0 0 10px 0"
                background="#fff"
                borderRadius="10px"
              >
                <Label size={16} color="#020202">
                  {image.name}
                </Label>
                <IconWrapper
                  onClick={e => {
                    e.stopPropagation()
                    removeImage(image)
                  }}
                >
                  <Cross />
                </IconWrapper>
              </View>
            ))}
          </View>
          <View
            padding="0 0 0 20px"
            justify="space-between"
            width="100%"
            alignItems="center"
          >
            <Label size={16} color="#020202">
              {images.length} / 3
            </Label>
            <ClearButton
              onClick={e => {
                e.stopPropagation()
                removeAllImages()
              }}
            >
              CLEAR
            </ClearButton>
          </View>
        </View>
      )}
    </ImagePicker>
  )
}

const ClearButton = styled.button`
  padding: 22px 20px;
  background: #fff;
  color: #020202;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  line-height: 1;
  transition: all 0.2s ease;
  opacity: 1;
  border-radius: 10px;

  &:hover {
    opacity: 0.6;
  }
`

const PlaceholderImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const IconWrapper = styled.div`
  background-color: #e1e1e8;
  border-radius: 4px;
  padding: 5px;
  transition: all 0.2s ease;
  opacity: 1;
  line-height: 0;

  &:hover {
    opacity: 0.6;
  }
`

const ImagePicker = styled.div`
  position: relative;
  display: flex;
  border-radius: 10px;
  padding: 40px 10px 10px;
  align-items: center;
  height: 440px;
  width: 100%;
  flex-direction: column;
  background-color: #f3f3fa;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23BCBCCCFF' stroke-width='1' stroke-dasharray='9%2c7' stroke-dashoffset='7' stroke-linecap='round'/%3e%3c/svg%3e");
`

export default FileDropzone
