import { useWindowListener } from 'common/useWindowListener.js'
import BottomModal from 'components/BottomModal'
import MobileTokenCard from 'components/Card/MobileNetTokenCard'
import NetTokenCard from 'components/Card/NetTokenCard'
import { HasLoadedCard } from 'components/Card/NetTokenCard/contexts/HasLoadedCardContext'
import PremiumRegistry from 'components/PremiumRegistry/index.jsx'
import { useAccount } from 'components/QueryAccount.js'
import { useColumnCount } from 'components/ResultList/hooks/useColumnCount'
import NetTokenRow from 'components/Row/NetTokenRow'
import SingleRegistration from 'components/SingleRegistration/index.jsx'
import Space from 'components/Space/index.js'
import { VirtualizedList } from 'components/VirtualizedList'
import { DOMAIN_TABLE_COLUMNS_BREAKPOINTS } from 'constants/breakpoints'
import { CartStoreContext } from 'contexts/CartStoreProvider'
import { FilterContext } from 'contexts/FilterProvider'
import { useEthPrice } from 'hooks/useEthPrice.js'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import getExpiryDate from 'service/rest/getExpiryDate'
import NoItems from 'views/Checkout/components/NoItems.jsx'

const DomainTable = ({
  viewType,
  searchTxt,
  parsedDomains,
  fetchMore,
  filtersOpened,
  loading,
  setIsLoading,
  activeCurrency,
  openOfferDialog,
  openRegistrationDialog,
  committedDomains
}) => {
  const {
    state: { domains: selectedDomains },
    addNewDomain,
    removeDomain
  } = useContext(CartStoreContext)
  const cartCtx = useContext(CartStoreContext)
  const [domainIsPremium, setDomainIsPremium] = useState(false)
  const [selectedDomain, setSelectedDomain] = useState({})
  const [registrationModalOpen, setRegistrationModalOpen] = useState(false)
  const [domainsIsInCartArr, setDomainsIsInCartArr] = useState([])
  // const [isLoading, setIsLoading] = useState(true)
  const [cardWidth, setCardWidth] = useState('310px')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [domains, setDomains] = useState([])

  // useEffect(() => {
  //   isLoading = true
  //   setDomains([])
  // }, [searchTxt])

  useEffect(() => {
    if (searchTxt || searchTxt?.length > 0) {
      setIsLoading(true)
      setDomains(parsedDomains)
      setIsLoading(false)
      return
    }

    const checkDomainsRegistrationStatus = async () => {
      if (parsedDomains) {
        setIsLoading(true)
        const domainsCopy = parsedDomains.slice(
          parsedDomains.length > 0 ? parsedDomains.length - 40 : 0,
          parsedDomains.length
        )

        if (!domainsCopy || domainsCopy?.length === 0) return

        const domainsReduce = domainsCopy?.reduce(async (acc, d) => {
          let result

          try {
            result = await getExpiryDate(d.name)
          } catch (e) {
            return acc
          }

          const isRegistered = moment().isBefore(result.toNumber() * 1000)

          if (isRegistered) {
            return acc
          }

          return (await acc).concat(d)
        }, [])

        const newDomains = await domainsReduce

        if (newDomains.length === 0) {
          setIsLoading(false)
          return
        }

        if (parsedDomains.length <= 40) {
          setDomains([...newDomains])
          setIsLoading(false)
          return
        }

        const newDomainsArr = [...domains, ...newDomains]

        setDomains(newDomainsArr)
        setIsLoading(false)
      }
    }

    checkDomainsRegistrationStatus()
  }, [parsedDomains])

  const [tableSeed, setTableSeed] = useState(1)
  const resetTableSeed = () => setTableSeed(Math.random())

  const history = useHistory()

  const ethPrice = useEthPrice()

  const user = useAccount()

  const { columnCount } = useColumnCount({
    sizeMap: DOMAIN_TABLE_COLUMNS_BREAKPOINTS
  })

  // const openReistrationModal = domain => {
  //   const getIfDomainPremium = calculateIfPremium(domain.expire_time)

  //   setSelectedDomain(domain)

  //   if (getIfDomainPremium) {
  //     setDomainIsPremium(true)
  //   } else {
  //     setDomainIsPremium(false)
  //   }

  //   setRegistrationModalOpen(true)
  // }

  const { savedDomainPosition, setSavedDomainPosition } = useContext(
    FilterContext
  )

  const handleGoSummary = (domainName, domainRegistered) => {
    if (domainRegistered) {
      setSavedDomainPosition(
        Object.assign(savedDomainPosition, {
          marketplace: {
            ...savedDomainPosition.marketplace,
            clickedDomain: domainName
          }
        })
      )
    } else {
      setSavedDomainPosition(
        Object.assign(savedDomainPosition, {
          registry: {
            ...savedDomainPosition.registry,
            clickedDomain: domainName
          }
        })
      )
    }
    history.push(`/domain/${domainName}`)
  }

  useEffect(() => {
    const selectedDomainsNames = selectedDomains.map(domain => domain?.name)
    const areInCartArr = domains.map(domain =>
      selectedDomainsNames.includes(domain?.name)
    )
    setDomainsIsInCartArr([...areInCartArr])
  }, [cartCtx, domains, selectedDomains])

  useEffect(() => {
    let calculatedCardWith

    switch (
      filtersOpened
        ? windowWidth < 1300
          ? columnCount - 2
          : columnCount - 1
        : columnCount
    ) {
      case 3:
        calculatedCardWith = '32.6%'
        break
      case 4:
        calculatedCardWith = '24.25%'
        break
      case 5:
        calculatedCardWith = '19.2%'
        break
      case 6:
        calculatedCardWith = '15.833%'
        break
      case 7:
        calculatedCardWith = '13.425%'
        break
      case 8:
        calculatedCardWith = '11.625%'
        break
      case 9:
        calculatedCardWith = '9%'
        break
      case 10:
        calculatedCardWith = '3%'
        break
      default:
        calculatedCardWith = '310px'
        break
    }

    setCardWidth(calculatedCardWith)
  }, [columnCount, filtersOpened, windowWidth])

  useWindowListener(
    'resize',
    () => {
      setWindowWidth(window.innerWidth)
    },
    []
  )

  return (
    <>
      <BottomModal
        open={registrationModalOpen}
        onClose={() => setRegistrationModalOpen(false)}
        closeButtonLabel="Back to registry"
        contentPadding="0"
        contentBackground="#fff"
      >
        {domainIsPremium
          ? registrationModalOpen && (
              <PremiumRegistry
                domain={selectedDomain}
                closeLabel="Back to registry"
                closeRegistration={() => setRegistrationModalOpen(false)}
                refetchState={resetTableSeed}
              />
            )
          : registrationModalOpen && (
              <SingleRegistration
                domain={selectedDomain}
                closeLabel="Back to registry"
                closeRegistration={() => setRegistrationModalOpen(false)}
                refetchState={resetTableSeed}
              />
            )}
      </BottomModal>
      {domains?.length === 0 && !loading && (
        <>
          <Space size={100} />
          <NoItems isRegistered={false} />
        </>
      )}
      <HasLoadedCard>
        {/* {window.innerWidth > 640 && (
          <Space size={viewType.toLowerCase() === 'list' ? 150 : 100} />
        )} */}
        <VirtualizedList
          key={tableSeed}
          Component={
            windowWidth < 640
              ? MobileTokenCard
              : viewType === 'LIST'
              ? NetTokenRow
              : NetTokenCard
          }
          genericProps={{
            margin: '10px 10px 0px 10px',
            footerBg: '#020202',
            registeredBg: '#ffffff',
            starStrokeColor: '#020202',
            hasAnimation: true,
            isDarkMode: true
          }}
          height={350}
          viewType={viewType}
          columnCount={
            filtersOpened
              ? windowWidth < 1300
                ? columnCount - 2
                : columnCount - 1
              : columnCount
          }
          individualComponentPropsMap={domains?.reduce((acc, domain, index) => {
            const registered =
              domain &&
              moment()
                .subtract(90, 'days')
                .isBefore(Number(domain.expire_time) * 1000)

            return {
              ...acc,
              [index]: {
                width: cardWidth,
                windowWidth,
                index,
                margin: 0,
                headerBg: registered ? '#EBEBF2' : '#4A4A4D',
                headerColor: registered ? '#000' : '#fff',
                bodyBg:
                  windowWidth < 640
                    ? registered
                      ? '#000'
                      : '#1B1B1B'
                    : registered
                    ? '#fff'
                    : '#1B1B1B',
                bodyColor:
                  windowWidth < 640 ? '#fff' : registered ? '#020202' : '#fff',
                addedToCart:
                  domainsIsInCartArr[index] || selectedDomains.includes(domain),
                domain,
                priceInETH: activeCurrency === 'ETH',
                ethPrice,
                from: 'REGISTRY',
                committedDomains,
                filtersOpened: filtersOpened,
                key: `${domain?.name}--${index}`,
                onClick: () => handleGoSummary(domain.name, registered),
                onSelect: () =>
                  user &&
                  (selectedDomains.includes(domain) ||
                    domainsIsInCartArr[index])
                    ? removeDomain(
                        domain?.name,
                        registered ? 'PURCHASE' : 'REGISTER'
                      )
                    : user &&
                      addNewDomain(
                        domain,
                        registered ? 'PURCHASE' : 'REGISTER'
                      ),
                openOfferDialog: openOfferDialog,
                openRegistrationDialog: openRegistrationDialog
              }
            }
          }, {})}
          itemCount={
            domains?.length +
            (domains?.length === 1 ||
            (parsedDomains?.length % 40 > 0 && !loading)
              ? 0
              : columnCount -
                  (filtersOpened ? 1 : 0) -
                  (domains?.length % (columnCount - (filtersOpened ? 1 : 0))) +
                  columnCount -
                  (filtersOpened ? 1 : 0) || 0)
          }
          loadMore={fetchMore}
          loading={loading}
          rowsToRender={40}
          isFetchMore={false}
        />
      </HasLoadedCard>
    </>
  )
}

export default DomainTable
