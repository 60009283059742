import styled from '@emotion/styled/macro'
import View from 'components/View'
import React from 'react'
import mq from 'mediaQuery'
import ContactListItem from 'views/Chats/ContactList/ContactListItem'

const ContactListView = styled(View)`
  flex: 1;
  flex-direction: column;
  padding: 20px 0 0 0;
  max-width: 329px;
  margin-right: 20px;

  ${mq.medium`
    margin-right: 100px;
  `}

  ${mq.large`
    max-width: 329px;
    margin-right: 80px;
  `}

  ${mq.xLarge`
    max-width: 529px;
    margin-right: 100px;
  `}
`

const ContactList = ({ contacts, selectedChat, selectChat }) => {
  return (
    <ContactListView>
      {contacts?.length &&
        contacts.map(contact => (
          <ContactListItem
            key={contact.id}
            contact={contact}
            selected={contact.id === selectedChat}
            onClick={() => selectChat(contact.id)}
          />
        ))}
    </ContactListView>
  )
}

export default ContactList
