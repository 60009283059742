import styled from '@emotion/styled/macro'
import Avatar from 'components/Avatar'
import Card from 'components/Card'
import Label from 'components/Label'
import View from 'components/View'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useMediaMax } from '../../mediaQuery'

const NewsCard = ({ title, date, type, avatar_url, articleUrl }) => {
  const smallBP = useMediaMax('small')
  const [cardWidth, setCardWidth] = useState('420px')
  const [cardHeight, setCardHeight] = useState('320px')
  const [cardHeaderDirection, setCardHeaderDirection] = useState('row')
  const [labelPadding, setLabelPadding] = useState('0')

  const history = useHistory()

  const style = {
    date: getDateColor(type),
    title: getTitleColor(type),
    background: getBackgroundColor(type),
    border: getBorder(type)
  }

  useEffect(() => {
    if (smallBP) {
      setCardWidth('317px')
      setCardHeight('389px')
      setCardHeaderDirection('column')
      setLabelPadding('0 0 70px')
    } else {
      setCardWidth('420px')
      setCardHeight('320px')
      setCardHeaderDirection('row')
      setLabelPadding('0')
    }
  }, [smallBP])

  const handleCardClick = () => {
    if (articleUrl == null) {
      return
    }
    history.push(`/news/${articleUrl}`)
  }

  return (
    <Card
      width={cardWidth}
      height={cardHeight}
      onClick={handleCardClick}
      style={{ cursor: articleUrl ? 'pointer' : 'auto' }}
    >
      <CardContent
        id="news-card"
        width={cardWidth}
        height={cardHeight}
        background={style.background}
        border={style.border}
        borderRadius="10px"
        overflow="hidden"
        hasShadowOnHover
      >
        <View
          justify="space-between"
          direction={cardHeaderDirection}
          padding={labelPadding}
          height="auto"
        >
          <Label id="news-card-date" size={14} color={style.date}>
            {date}
          </Label>
          <Avatar
            src={avatar_url || ''}
            width="100"
            borderRadius="6px"
            background="#0698A8"
            hoverOpacity={1}
            cursor={articleUrl ? 'pointer' : 'auto'}
          />
        </View>
        <Label id="news-card-label" size={24} color={style.title}>
          {title}
        </Label>
      </CardContent>
    </Card>
  )
}

const CardContent = styled.div`
  height: 100%;
  padding: 40px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${props => props.background};
  border: ${props => props.border};
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s ease;
`

function getDateColor(type) {
  if (type === 'pink') {
    return '#fff'
  } else {
    return '#C6C6CC'
  }
}

function getTitleColor(type) {
  if (type === 'pink' || type === 'black') {
    return '#fff'
  } else {
    return '#020202'
  }
}

function getBackgroundColor(type) {
  if (type === 'pink') {
    return '#AF61AB'
  } else if (type === 'black') {
    return '#020202'
  } else {
    return '#FFFFFF'
  }
}

function getBorder(type) {
  if (type === 'pink') {
    return 'none'
  } else {
    return '1px solid #EBEBF2'
  }
}

export default NewsCard
