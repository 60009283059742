import styled from '@emotion/styled/macro'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
// import { BigNumber, ethers } from 'ethers'

import ENSIconLogoWhite from 'assets/ensIconLogoWhite.svg'
import { ReactComponent as Minus } from 'assets/minus.svg'
import { ReactComponent as Plus } from 'assets/plus.svg'
import Card from 'components/Card'
import FeaturedNetTokenCard from 'components/Card/NetTokenCard/FeaturedNetTokenCard'
import LoadingNetTokenCard from 'components/Card/NetTokenCard/LoadingNetTokenCard'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { CATEGORY_PRIORITY_LIST } from 'constants/category'
import { ethers } from 'ethers'
import moment from 'moment'
// import { ReactComponent as EthPriceWhite } from 'assets/eth-price-white.svg'
// import { ReactComponent as EthPriceDark } from 'assets/eth-price-dark.svg'
import { ens_normalize } from '@adraffy/ens-normalize'
import { ReactComponent as NameWarning } from 'assets/warning-name.svg'
import LoadingSpinner from 'components/LoadingSpinner'
import { useAccount } from 'components/QueryAccount'
import useDomainResolver from 'hooks/useDomainResolver'
import { formatUSDPrice } from 'utils/format'
import { ENS_CONTRACT_ADDRESS } from 'utils/utils'
// import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import { getSigner } from '@ensdomains/ui'
import CategoriesBox from 'components/CategoriesDisplayBox'
import { FilterContext } from 'contexts/FilterProvider'
import { useHistory } from 'react-router'
import { actions } from 'utils/reservoir'
import { REGISTRATION_STATUS } from 'utils/domain'

/**
 *
 * An unregistered domain card will have the "grace period", "recently expired"
 * or "new" tag or no tag at all, depending on its expiration date.
 *
 * If a domain  is 0-90 days past its expiration date, it should have the "grace period" tag.
 * If a domain is 90-119 days past its expiration date, it should have the "recently expired" tag.
 * If a domain is 119-149 days past its expiration date, it should have the "new" tag.
 * If a domain is 149+ days past its expiration date, it should have no tag at all.

 */

const NetTokenCard = ({
  height,
  width,
  windowWidth,
  margin,
  headerBg,
  headerColor,
  bodyBg,
  registeredBg,
  bodyColor,
  footerBg,
  filtersOpened,
  cardBorder,
  domain,
  fieldGetters = {},
  addedToCart,
  from = 'MARKETPLACE',
  onClick,
  onSelect,
  hasAnimation,
  index,
  priceInETH = true,
  ethPrice = 1,
  featured,
  committedDomains,
  isDarkMode,
  // onSelectDomain,
  // shouldFetch = false,
  profileTab,
  fromMyProfile = false,
  openOfferDialog = () => {},
  openRegistrationDialog = () => {},
  inSimilarSection,
  trending = false
}) => {
  // Early return if this card is a featured card
  if (featured) {
    return (
      <FeaturedNetTokenCard
        margin={margin}
        headerBg={headerBg}
        headerColor={headerColor}
        footerBg={footerBg}
        registeredBg={registeredBg}
        cardBorder={cardBorder}
        bodyBg={bodyBg}
        bodyColor={bodyColor}
        searchTerm={featured.searchTerm + '.eth'}
        onClick={onClick}
      />
    )
  }

  if (!domain) {
    return (
      <LoadingNetTokenCard
        isDarkMode={isDarkMode}
        hasAnimation={true}
        index={index}
        windowWidth={windowWidth}
        trending={trending}
        profile={from.toLowerCase() === 'profile'}
      />
    )
  }

  // const cardRef = useRef(null)

  // const { enterCount, inViewport } = useInViewport(
  //   cardRef,
  //   {
  //     root: null,
  //     rootMargin: '300px 0px 0px 0px',
  //     threshold: [0, 0]
  //   },
  //   {
  //     disconnectOnLeave: true
  //   }
  // )

  // const [isVisible, setIsVisible] = useState(false)

  // const handleChangeVisibilit = entries => {
  //   const [entry] = entries
  //   isVisible = entry.intersectionRatio === 1
  // }

  // const observerOptions = {
  //   root: null,
  //   rootMargin: '0px 100px 0px 0px',
  //   threshold: 1.0
  // }

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     handleChangeVisibilit,
  //     observerOptions
  //   )

  //   if (isVisible) {
  //     observer.unobserve(cardRef.current)
  //     return
  //   }

  //   if (cardRef.current) observer.observe(cardRef.current)

  //   return () => {
  //     if (cardRef.current) observer.unobserve(cardRef.current)
  //   }
  // }, [observerOptions, handleChangeVisibilit, cardRef, isVisible])

  const history = useHistory()
  const userAddress = useAccount()
  const filterCtx = useContext(FilterContext)

  const [tokenCardSeed, setTokenCardSeed] = useState(1)
  const resetCardSeed = () => setTokenCardSeed(Math.random())

  // const [headerHovered, setHeaderHovered] = useState(false)
  // const [bodyHovered, setBodyHovered] = useState(false)
  const [footerHovered, setFooterHovered] = useState(false)
  const [buying, setBuying] = useState(false)
  const [refetchData, setRefetchData] = useState(false)
  const [usesSpecialCharacters, setUsesSpecialCharacters] = useState(false)
  const [displayWarningMessage, setDisplayWarningMessage] = useState(false)
  // const [alreadyAnimated, setAlreadyAnimated] = useState(false)
  // const [listingIsLoading, setListingIsLoading] = useState(false)
  const [addedToCartHovered, setAddedToCartHovered] = useState(false)
  const [inCommittingState, setInCommittingState] = useState(false)

  const {
    name: domainName,
    tokenIdHex,
    price,
    expires,
    categories,
    ownerPfp,
    ownerAddress,
    isRegistered,
    likesCount,
    isLikedByUser,
    isListed,
    lastPrice,
    lastPriceAsset,
    listingPrice,
    toggleLike,
    listedOn,
    expirationStatus
  } = useDomainResolver(domain, fieldGetters, refetchData)

  // useEffect(() => {
  //   if (isListed === undefined || listingPrice === undefined) return
  //   setListingIsLoading(false)
  // }, [isListed, listingPrice])

  // const sortedCategories = useMemo(() => {
  //   if (!categories || !categories?.terms) return []
  //   const categoriesArrCopy = categories?.terms
  //   const sortedCategoriesIndexes = categoriesArrCopy
  //     .map(c => ({
  //       index: CATEGORY_PRIORITY_LIST.indexOf(c),
  //       category: c
  //     }))
  //     .sort((a, b) => a.index - b.index)

  //   return sortedCategoriesIndexes.map(obj => obj.category)
  // }, [categories])

  // useEffect(() => {
  //   try {
  //     const normalizedName = ens_normalize(domainName)
  //     if (normalizedName) setUsesSpecialCharacters(false)
  //   } catch (e) {
  //     setUsesSpecialCharacters(true)
  //   }
  // }, [domainName])

  const isWhiteStyle =
    (isRegistered && expirationStatus === undefined) ||
    (from.toLowerCase() === 'marketplace' && isRegistered === undefined) ||
    from.toLowerCase() === 'profile'

  headerBg = isWhiteStyle ? '#F3F3FA' : '#262628'
  bodyBg = isWhiteStyle ? '#F3F3FA' : '#262628'

  const bodyBorder = isWhiteStyle ? '#CACADB' : '#4A4A4D'

  headerColor = isWhiteStyle ? '#000' : '#fff'
  bodyColor = isWhiteStyle ? '#020202' : '#fff'

  useEffect(() => {
    if (!committedDomains) return
    if (
      committedDomains.some(
        domain => domain.name === domainName.replace('.eth', '')
      )
    )
      setInCommittingState(true)
    else setInCommittingState(false)
  }, [committedDomains])

  useEffect(() => {
    resetCardSeed()
  }, [isRegistered])

  const handleClickCard = () => {
    if (addedToCartHovered) return
    // if (isRegistered && from === 'REGISTRY') {
    //   history.push(`/domain/${domain.name}`)
    //   return
    // }
    onClick()
  }

  // const handleToggleLike = e => {
  //   e.stopPropagation()
  //   toggleLike()
  // }

  // const handleGoSubCategory = useCallback(
  //   e => {
  //     e.stopPropagation()

  //     const category = sortedCategories?.[0]
  //     if (category) {
  //       const categoryKey = category
  //         .toLowerCase()
  //         .split(' ')
  //         .join('-')

  //       TAGS.forEach(tag => {
  //         if (tag.key.toLowerCase() === categoryKey.toLowerCase()) {
  //           history.push(`/marketplace?category=${tag.key.toLowerCase()}`)
  //         } else if (tag.subcategories.length > 0) {
  //           tag.subcategories.forEach(subCategory => {
  //             if (subCategory.key.toLowerCase() === categoryKey.toLowerCase()) {
  //               history.push(`/marketplace?category=${tag.key.toLowerCase()}`)
  //             }
  //           })
  //         }
  //       })
  //     }
  //   },
  //   [categories]
  // )

  const isPremium = expirationStatus
    ? expirationStatus === 'premium'
    : filterCtx?.filter?.status === 'premium'

  // const cardVisibility =
  //   !profileTab ||
  //   profileTab === 'unlisted' ||
  //   profileTab === 'listed' ||
  //   domain.name.length >= 7

  const priceUSD = Number(price) * Number(ethPrice)

  const listingPriceUSD = Number(listingPrice) * Number(ethPrice)

  const handlePurchaseDomain = async () => {
    if (!isListed || !listingPrice || !domainName) return

    try {
      setBuying(true)

      const signer = await getSigner()

      const tokenId = ethers.BigNumber.from(
        ethers.utils.keccak256(
          ethers.utils.toUtf8Bytes(domainName.replace('.eth', ''))
        )
      ).toString()

      await actions.buyToken({
        signer,
        items: [
          {
            token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
            quantity: 1,
            preferredOrderSource: 'kodex.io'
          }
        ],
        onProgress: step => {
          if (step[0].items[0].status === 'complete') {
            setTimeout(() => {
              setBuying(false)
              setRefetchData(true)
              resetCardSeed()
            }, 1000)
          }
        }
      })
    } catch (err) {
      setBuying(false)
      throw new Error(err)
    }
  }

  // const USDPriceIcon = () => {
  //   return (
  //     <View
  //       style={{
  //         width: 'clamp(20px,2vw,38px)',
  //         height: 'clamp(20px,2vw,38px)'
  //       }}
  //       background={isWhiteStyle ? '#fff' : '#000'}
  //       borderRadius="5px"
  //       justify="center"
  //       alignItems="center"
  //     >
  //       <Label
  //         style={{ fontSize: 'clamp(12px,1vw,20px)' }}
  //         color={isWhiteStyle ? '#000' : '#CACADB'}
  //       >
  //         $
  //       </Label>
  //     </View>
  //   )
  // }

  const handleRegister = e => {
    e.stopPropagation()
    // if (!isRegistered && from === 'MARKETPLACE') {
    //   onSelect(isRegistered)
    //   return
    // }

    if (isListed && isRegistered) {
      handlePurchaseDomain()
      return
    }

    if (isRegistered === false) {
      openRegistrationDialog(domainName, isPremium, expires)
    }

    if (isRegistered && !isListed) {
      openOfferDialog(domainName, lastPrice)
      return
    }
  }

  const handleFooterClick = e => {
    // e.stopPropagation()
    if (expirationStatus === REGISTRATION_STATUS.GRACE_PERIOD) return
    if (
      isRegistered &&
      userAddress?.toLowerCase() === ownerAddress?.toLowerCase() &&
      !fromMyProfile
    ) {
      history.push(`/listing/${tokenIdHex}`)
      return
    }

    // if (!isRegistered && from === 'MARKETPLACE') {
    //   onSelect(isRegistered)
    //   return
    // }

    if (isListed && isRegistered && !addedToCartHovered) {
      handlePurchaseDomain()
      return
    }

    // if (isRegistered && !isListed) {
    //   openOfferDialog(domainName, lastPrice)
    //   return
    // }

    onSelect()
  }

  const darkerGradient =
    'linear-gradient(135deg, rgba(160, 153, 255, 0.3) 0%, rgba(114, 152, 248,0.3) 51.56%, rgba(68, 188, 240, 0.3) 100%)'

  const registeredGradient =
    'linear-gradient(135deg, #A099FF 0%, #7298F8 51.56%, #44BCF0 100%)'

  const gradient = isWhiteStyle ? registeredGradient : darkerGradient

  // const hasNoCategory = sortedCategories.length === 0

  const isPriceLoading =
    isRegistered === undefined ||
    (!listingPrice && isRegistered === true && isListed) ||
    (isRegistered === false && !price)

  function formatETHPrice(input) {
    return Number(input)?.toLocaleString('default', {
      maximumFractionDigits:
        Number(input) < 1 ? 4 : Number(input) > 100000 ? 0 : 3
    })
  }

  const calculatedFontSize =
    width && filtersOpened !== undefined
      ? Math.floor(
          (1 / domainName.length) *
            parseFloat(width.replace('%', '')) *
            windowWidth *
            Math.pow(10, -2) *
            1.3 *
            (filtersOpened ? (370 / windowWidth) * 2.05 : 1)
        )
      : 24

  const domainNameFontSize =
    calculatedFontSize > 24
      ? 24
      : calculatedFontSize < 13
      ? 13
      : calculatedFontSize

  return (
    <Card
      onClick={handleClickCard}
      cursor="pointer"
      // ref={cardRef}
      background={bodyBg}
      width={width || '310px'}
      maxWidth={width || '310px'}
      // height={`${Number(width.slice(0, -1)) * 1.4}%`}
      height={height || `25vw`}
      margin={margin}
      border={`1px solid ${isWhiteStyle ? '#CACADB' : '#626266'}`}
      outline={addedToCart ? '3px solid #0698A8' : ''}
      borderRadius="10px"
      style={{
        // transition: '0.3s ease-out',
        // transitionProperty: 'transform, opacity',
        // display: cardVisibility ? 'flex' : 'none',
        // transform:
        //   enterCount > 0 || inViewport ? 'translateY(0)' : 'translateY(10%)',
        // opacity: enterCount > 0 || inViewport ? '1' : '0',
        // transform: isVisible ? 'translateY(0)' : 'translateY(10%)',
        // opacity: isVisible ? '1' : '0',
        opacity: 1,
        maxHeight: '430px'
        // transitionProperty: 'transform, opacity'
      }}
      zIndex={1000}
    >
      <View
        width="100%"
        height="100%"
        direction="column"
        background={bodyBg}
        justify="space-between"
        overflow="hidden"
        borderRadius="10px"
        key={tokenCardSeed}
        onMouseEnter={() => setFooterHovered(true)}
        onMouseLeave={() => setFooterHovered(false)}
      >
        <View flex="55%" width="100%" direction="column">
          {/* {!hasNoCategory && (
            <View
              height="30%"
              width="100%"
              padding="0px 10%"
              alignItems="center"
              justify="space-between"
              cursor="pointer"
              onClick={handleGoSubCategory}
              gap="6px"
              transition="0.2s linear"
              hoverOpacity="0.5"
            >
              <View>
                <Label
                  size={windowWidth < 1300 ? 12 : 14}
                  style={{ cursor: 'pointer' }}
                  color={headerColor}
                >
                  {`${sortedCategories?.[0] || ''}`}
                </Label>
                <Label
                  size={windowWidth < 1300 ? 12 : 14}
                  style={{ cursor: 'pointer' }}
                  color={'#BCBCCC'}
                >
                  {sortedCategories?.length > 1
                    ? `+${sortedCategories?.length - 1}`
                    : ''}
                </Label>
              </View>
              <BlueCheckIconStyle
                src={BlueCheckIcon}
                show={addedToCart}
                style={{ userSelect: 'none', pointerEvents: 'none' }}
              />
            </View>
          )} */}
          <View
            width="100%"
            height="100%"
            borderRadius="10px"
            background={
              isPremium ? 'rgb(49, 9, 38)' : isWhiteStyle ? bodyBg : '#020202'
            }
            // style={{
            //   borderBottom: `1px solid ${isWhiteStyle ? '#CACADB' : '#626266'}`,
            // }}
          >
            <DomainGradientStyle border={bodyBorder} gradient={gradient}>
              <View width="100%" justify="space-between" alignItems="center">
                <img
                  style={{ userSelect: 'none', pointerEvents: 'none' }}
                  src={ENSIconLogoWhite}
                  width="35vw"
                  alt="ens-icon-logo"
                />
                <View
                  opacity={categories?.terms ? 1 : 0}
                  transition="opacity 500ms ease"
                >
                  <CategoriesBox
                    isWhiteStyle={isWhiteStyle}
                    categories={categories?.terms}
                    boxBg={
                      isPremium
                        ? '#695D83'
                        : !isWhiteStyle
                        ? '#4E596F'
                        : '#8DAEF9'
                    }
                  />
                </View>
              </View>

              {/* {hasNoCategory && (
                <WhiteCheckIconStyle src={WhiteCheckIcon} show={addedToCart} />
              )} */}

              <div
                style={{
                  display: 'block',
                  color: '#fff',
                  fontFamily: 'Satoshi',
                  fontSize: domainNameFontSize,
                  fontWeight: 500,
                  overflowY: 'visible',
                  overflowX: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {domainName}
              </div>
            </DomainGradientStyle>
          </View>
        </View>

        <View
          flex="45%"
          width="100%"
          height="100%"
          direction="column"
          justify="space-between"
          background={inSimilarSection && isWhiteStyle ? '#fff' : bodyBg}
          borderRadius="8px"
          transition="0.2s ease-out"
          // onMouseEnter={() => setFooterHovered(true)}
          // onMouseLeave={() => setFooterHovered(false)}
        >
          <View
            width="100%"
            flex="33%"
            alignItems="flex-end"
            padding="0 10% 2% 10%"
          >
            <Label
              cursor="pointer"
              style={{ fontSize: 'clamp(14px,1vw,20px)' }}
              weight={500}
              color={bodyColor}
              whiteSpace="nowrap"
              width="80%"
              inlineSize="80%"
              overflow="hidden"
              overflowY="visible"
              hoverColor={
                !isWhiteStyle ? '#ccc' : addedToCart ? '#ccc' : '#777'
              }
              onClick={e => {
                e.stopPropagation()
                handleClickCard()
              }}
            >
              {domainName?.length > 20
                ? domainName.slice(0, 20) + '...'
                : domainName}
            </Label>
            {usesSpecialCharacters && (
              <View
                justify="center"
                position="relative"
                alignItems="center"
                margin="0 0 0 10px"
                cursor="pointer"
                padding="0 0 2px 0"
                onMouseEnter={() => setDisplayWarningMessage(true)}
                onMouseLeave={() => setDisplayWarningMessage(false)}
              >
                <NameWarning
                  height={windowWidth < 1400 ? '15px' : '20px'}
                  width={windowWidth < 1400 ? '15px' : '20px'}
                />
              </View>
            )}
          </View>
          {displayWarningMessage && (
            <>
              <div
                style={{
                  width: '100%',
                  position: 'absolute',
                  top: windowWidth < 1400 ? '-30px' : '-30px',
                  border: `1px solid ${isDarkMode ? '#4A4A4D' : '#E1E1E8'}`,
                  padding: '10px',
                  background: isDarkMode ? '#262628' : 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '8px',
                  zIndex: 100
                }}
              >
                <Label
                  color={isDarkMode ? 'white' : '#222'}
                  size={windowWidth < 1400 ? 14 : 15}
                  align="center"
                >
                  This domain uses non ASCII special characters
                </Label>
              </div>
            </>
          )}

          <View alignItems="center" width="100%" flex="33%" padding="0 10%">
            {/* {isPriceLoading ||
            (isListed === false && isRegistered) ? null : priceInETH ? (
              isWhiteStyle ? (
                <>
                  <EthPriceWhite
                    style={{
                      width: 'clamp(20px,2vw,38px)',
                      height: 'clamp(20px,2vw,38px)'
                    }}
                  />
                  <Space size={12} />
                </>
              ) : (
                <>
                  <EthPriceDark
                    style={{
                      width: 'clamp(20px,2vw,38px)',
                      height: 'clamp(20px,2vw,38px)'
                    }}
                  />
                  <Space size={12} />
                </>
              )
            ) : (
              <>
                <USDPriceIcon />
                <Space size={12} />
              </>
            )} */}

            {isPriceLoading ? (
              <LoadingSpinner
                margin="0 0 0 1em"
                scale={0.8}
                color={!isWhiteStyle ? 'white' : 'black'}
              />
            ) : expirationStatus === 'grace period' ? (
              <Label
                weight={500}
                style={{ fontSize: 'clamp(12px,1vw,18px)' }}
                color="#BCBCCC"
              >
                Grace period
              </Label>
            ) : listingPrice ? (
              <Label
                weight={500}
                number
                style={{ fontSize: 'clamp(12px,1vw,18px)' }}
                color={bodyColor}
              >
                {priceInETH
                  ? formatETHPrice(listingPrice) + ' ETH'
                  : formatUSDPrice(listingPriceUSD) + ' USD'}
              </Label>
            ) : price && !isListed && !isRegistered ? (
              <Label
                number
                weight={500}
                color={bodyColor}
                style={{ fontSize: 'clamp(12px,1vw,18px)' }}
              >
                {priceInETH
                  ? formatETHPrice(price) + ' ETH'
                  : formatUSDPrice(priceUSD) + ' USD'}
              </Label>
            ) : null}
          </View>

          {(expirationStatus !== REGISTRATION_STATUS.GRACE_PERIOD &&
            !inCommittingState &&
            footerHovered) ||
          buying ? (
            <View
              display={footerHovered ? 'flex' : 'none'}
              width="100%"
              flex="33%"
              padding="0 3.5% 3.5% 3.5%"
              animation="fadeInBottom 400ms ease"
              alignItems="center"
              gap="5px"
            >
              <RoundedButton
                hoverBackground="#0698A8"
                hoverColor="#fff"
                background="#fff"
                flex="70%"
                style={{ height: 'clamp(30px,2.75vw,50px)' }}
                // opacity={footerHovered ? 1 : 0}
                // transform={footerHovered ? 'translateY(0)' : 'translateY(5%)'}
                borderRadius="6px"
                border="1px solid #CACADB"
                onClick={
                  fromMyProfile ||
                  userAddress?.toLowerCase() === ownerAddress?.toLowerCase()
                    ? e => {
                        e.stopPropagation()
                        history.push(`/listing/${tokenIdHex}`)
                      }
                    : handleRegister
                }
              >
                <Label
                  color="black"
                  style={{ fontSize: 'clamp(12px,1vw,18px)' }}
                >
                  {fromMyProfile ||
                  userAddress?.toLowerCase() === ownerAddress?.toLowerCase()
                    ? isListed
                      ? 'Edit'
                      : 'List'
                    : isWhiteStyle
                    ? isListed
                      ? 'Buy now'
                      : 'Make offer'
                    : 'Register'}
                </Label>
              </RoundedButton>
              <RoundedButton
                onMouseEnter={() => setAddedToCartHovered(true)}
                onMouseLeave={() => setAddedToCartHovered(false)}
                onClick={() => handleFooterClick()}
                borderRadius="6px"
                border={!addedToCart && '1px solid #CACADB'}
                background={addedToCart ? '#0698A8' : '#fff'}
                flex="30%"
                style={{ height: 'clamp(30px,2.75vw,50px)' }}
              >
                {addedToCart ? (
                  <Minus
                    stroke={addedToCart ? 'white' : 'black'}
                    style={{ width: 'clamp(16px,1.15vw,20px)' }}
                  />
                ) : (
                  <Plus
                    stroke={addedToCart ? 'white' : 'black'}
                    style={{ width: 'clamp(16px,1.15vw,20px)' }}
                  />
                )}
              </RoundedButton>
            </View>
          ) : inCommittingState ? (
            <View
              padding="0 10% 2% 10%"
              width="100%"
              style={{ height: 'clamp(30px,2.75vw,50px)' }}
            >
              <Label
                color="#0698A8"
                weight={700}
                style={{
                  background:
                    'linear-gradient(330.4deg, rgb(68, 188, 240) 4.54%, rgb(114, 152, 248) 59.2%, rgb(160, 153, 255) 148.85%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                Committed
              </Label>
            </View>
          ) : lastPrice > 0 ? (
            <View
              padding="0 10% 2% 10%"
              flex="33%"
              width="100%"
              style={{ height: 'clamp(30px,2.75vw,50px)' }}
              alignItems="center"
            >
              <Label
                color={isRegistered ? '#BCBCCC' : '#A6A6A6'}
                style={{ fontSize: 'clamp(12px,1vw,18px)' }}
              >
                Last sale:
              </Label>
              <Space size={10} />
              <Label
                style={{ fontSize: 'clamp(12px,1vw,18px)' }}
                number
                color={bodyColor}
              >
                {`${formatETHPrice(lastPrice)} ${lastPriceAsset}`}
              </Label>
            </View>
          ) : isPremium ? (
            <View
              padding="0 10%"
              style={{ height: 'clamp(30px,2.75vw,50px)' }}
              flex="33%"
              width="100%"
              alignItems="center"
            >
              <Label
                color="#AF61AB"
                style={{ fontSize: 'clamp(12px,1vw,18px)' }}
              >
                Premium
              </Label>
            </View>
          ) : (
            <View
              padding="0 10% 2% 10%"
              width="100%"
              style={{ height: 'clamp(30px,2.75vw,50px)' }}
            />
          )}
        </View>
      </View>
    </Card>
  )
}

export const DomainGradientStyle = styled.div`
  display: flex;
  background-size: 120%;
  background: ${props => props.gradient};
  border-bottom: 1px solid ${props => props.border};
  width: 100%;
  border-radius: 10px;
  padding: 1.5em 10%;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
`

export default NetTokenCard
