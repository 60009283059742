import FractalBg from 'assets/fractal-completed-modal.png'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { AccountContext } from 'contexts/AccountProvider'
import { ethers } from 'ethers'
import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { addListing } from 'service/rest/addListing'
import {
  ENS_CONTRACT_ADDRESS,
  EXCHANGE_CONTRACT_ADDRESS,
  getENSContract
} from 'utils/utils'
import { DurationAccordion } from 'views/TokenProfile/DurationAccordion'
import ListingRow from './components/ListingRow'
import { actions } from 'utils/reservoir'
import { getSigner } from '@ensdomains/ui'
import LoadingSpinner from 'components/LoadingSpinner'

const DURATION_OPTIONS = [
  { label: '1 Day', value: 1 },
  { label: '1 Week', value: 7 },
  { label: '1 Month', value: 30 },
  { label: '3 Months', value: 90 },
  { label: '6 Months', value: 180 }
]

const ListingDialog = ({
  domains,
  setDomains,
  onClose,
  backLabel,
  resetState,
  setNavItem
}) => {
  const { account, handleSIWE } = useContext(AccountContext)

  const history = useHistory()

  const [allowance, setAllowance] = useState(0)
  const [listingAmounts, setListingAmounts] = useState(
    new Array(domains.length).fill(0)
  )
  const [totalListingAmount, setTotalListingAmount] = useState(0)
  const [offerDuration, setOfferDuration] = useState()
  const [isApproving, setApproving] = useState(false)
  const [isListing, setListing] = useState(false)
  const [isListed, setIsListed] = useState(false)
  const [duration, setDuration] = useState(DURATION_OPTIONS[0])
  const [published, setPublished] = useState(DURATION_OPTIONS[0])
  const [successfullListings, setSuccessfullListings] = useState([])
  const [durationAccordionExpanded, setDurationAccordionExpanded] = useState(
    false
  )

  const handleRemoveDomain = name => {
    if (domains.length === 1) {
      onClose()
    }
    const domainsArrCopy = domains
    const filteredDomainsArrCopy = domainsArrCopy.filter(d => d.name !== name)
    setDomains([...filteredDomainsArrCopy])
  }

  useEffect(() => {
    const totalAmount = listingAmounts.reduce(
      (acc, cur) => Number(acc) + Number(cur)
    )
    setTotalListingAmount(totalAmount)
  }, [listingAmounts])

  const handleAddListing = async data => {
    await addListing(data)
  }

  const fetchAllowance = useCallback(async () => {
    if (account) {
      try {
        const ensContract = await getENSContract()
        const allowance = await ensContract.isApprovedForAll(
          account,
          EXCHANGE_CONTRACT_ADDRESS
        )
        setAllowance(allowance)
      } catch (err) {
        console.log('ens fetch error for isApprovedForAll: ', err)
      }
    }
  }, [account])

  useEffect(() => {
    fetchAllowance()
  }, [fetchAllowance])

  const authedList = async args => {
    try {
      await handleSIWE()
    } catch (e) {
      throw e
    }

    return actions.listToken(args)
  }

  const handleApproveAllowance = async () => {
    if (isApproving) return

    try {
      setApproving(true)
      const ensContract = await getENSContract()
      const tx = await ensContract.setApprovalForAll(
        EXCHANGE_CONTRACT_ADDRESS,
        true
      )
      await tx.wait()
      await fetchAllowance()
      setApproving(false)
    } catch (err) {
      setApproving(false)
      console.log('Approve allowace error: ', err)
    }
  }

  const handleReset = () => {
    setListingAmounts(new Array(domains.length).fill(0))
    setOfferDuration(DURATION_OPTIONS[0])
  }

  const handleConfirm = async () => {
    if (domains.length === 0) return

    setListing(true)

    const expirationTime = moment()
      .add('days', duration.value)
      .format('X')

    const listings = domains.map((domain, i) => {
      const weiListedPrice = ethers.utils
        .parseEther(listingAmounts[i])
        .toString()

      const tokenId = ethers.BigNumber.from(
        ethers.utils.keccak256(
          ethers.utils.toUtf8Bytes(domain.name.replace('.eth', ''))
        )
      ).toString()

      return {
        // token: `0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85:${tokenId}`,
        token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
        weiPrice: weiListedPrice,
        expirationTime
      }

      // try {
      //   const order = await executeAllActions()

      //   await handleAddListing(order)

      //   const successfullListingsCopy = successfullListings
      //   successfullListingsCopy.push(domain.name)

      //   setSuccessfullListings([...successfullListingsCopy])

      //   if (i + 1 === domains.length) {
      //     setIsListed(true)
      //     const domainsFiltered = domains.filter(
      //       d => !successfullListings.includes(d.name)
      //     )
      //     setDomains([...domainsFiltered])
      //   }
      // } catch (err) {
      //   if (i + 1 === domains.length) {
      //     setIsListed(true)
      //     const domainsFiltered = domains.filter(
      //       d => !successfullListings.includes(d.name)
      //     )
      //     setDomains([...domainsFiltered])
      //   }
      //   if (i + 1 === domains.length && successfullListings.length === 0)
      //     onClose()
      //   throw new Error(err)
      // }
    })

    const signer = await getSigner()

    await authedList({
      signer,
      listings,
      onProgress: step => {
        if (step[1].items[0].status === 'complete') {
          domains.map((d, i) => {
            localStorage.removeItem(`${d.name.replace('.eth', '')}-listing`)
            localStorage.setItem(
              `${d.name.replace('.eth', '')}-listing`,
              JSON.stringify({
                name: d?.name.replace('.eth', ''),
                price: listingAmounts[i],
                expires: expirationTime
              })
            )
          })
          resetState()
          setSuccessfullListings(domains.map(d => d.name))
          setDomains([])
          setIsListed(true)
          setPublished(true)
          setNavItem('listed')
        }
      }
    })
  }

  if (isListed) {
    return (
      <View
        width="33vw"
        minWidth="720px"
        height="100%"
        margin="0 0 1rem 0"
        direction="column"
        borderRadius="10px"
        background="#0698A8"
        justify="space-between"
        alignItems="center"
        padding="40px"
        gap="2rem"
        overflow="scroll"
        scrollBar={`&::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
            }
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */`}
      >
        <View
          direction="column"
          alignItems="center"
          gap="2rem"
          margin="0.5rem 0 0 0"
          width="100%"
        >
          <Label size={35} color="#fff8">
            Listed
          </Label>
          <View
            direction="column"
            alignItems="center"
            justify="center"
            gap="0.5rem"
            width="100%"
          >
            {successfullListings
              .sort((a, b) => a.length - b.length)
              .map((name, i) => (
                <View height="35px" key={name}>
                  <Label size={30}>{name}</Label>
                </View>
              ))}
          </View>
        </View>
        <img src={FractalBg} height="250px" />
        <View width="350px" direction="column">
          <RoundedButton
            width="100%"
            height="60px"
            border="1px solid #fff"
            onClick={() => {
              history.push('/profile/my-profile?listed')
            }}
          >
            <Label size={16}>View In Listings</Label>
          </RoundedButton>
          <Space size={10} />
          <RoundedButton
            width="100%"
            height="60px"
            background="#fff"
            border="1px solid #fff"
            onClick={() => {
              onClose()
              resetState()
            }}
          >
            <Label size={16} color="#020202">
              back to {backLabel || ''}
            </Label>
          </RoundedButton>
        </View>
      </View>
    )
  }

  return (
    <View
      width="33vw"
      minWidth="720px"
      height="100%"
      direction="column"
      margin="0 0 1rem 0"
    >
      <View
        flex={1}
        width="100%"
        direction="column"
        style={{ overflow: 'hidden' }}
        borderRadius="10px"
      >
        <View
          width="100%"
          background="#fff"
          padding=" 5rem 0 0 2.5rem"
          direction="column"
          gap="0.5rem"
        >
          <Label size={30} color="#000">
            Bulk list
          </Label>
          <Label size={30} color="#BCBCCC">
            {domains.length} domains
          </Label>
        </View>
        <View
          flex={1}
          direction="column"
          background="#fff"
          padding=" 5rem 2.5rem 3rem 2.5rem"
          width="100%"
          gap="2rem"
        >
          <View
            direction="column"
            width="100%"
            gap="2rem"
            height="calc(100vh - 365px)"
            padding="0 0 12rem 0"
            overflow="scroll"
            scrollBar={`&::-webkit-scrollbar {
              display: none; /* for Chrome, Safari, and Opera */
            }
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */`}
          >
            <View direction="column" width="100%" gap="0.75rem">
              <View width="90%" direction="row">
                <View width="70%">
                  <Label size={14} color="#BCBCCC">
                    Domain
                  </Label>
                </View>
                <View
                  width="30%"
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Label size={14} color="#BCBCCC">
                    Amount
                  </Label>
                  <Label
                    size={14}
                    cursor="pointer"
                    color="#000"
                    hoverColor="#999"
                    onClick={() => {
                      const firstSetPrice = listingAmounts.filter(
                        amount => Number(amount) > 0
                      )
                      setListingAmounts(
                        new Array(domains.length).fill(firstSetPrice[0])
                      )
                    }}
                  >
                    For each
                  </Label>
                </View>
              </View>
              <Space size={10} />
              {domains.map((domain, i) => (
                <ListingRow
                  key={domain.name}
                  domain={domain}
                  removeDomain={handleRemoveDomain}
                  index={i}
                  setListingAmounts={setListingAmounts}
                  listingAmounts={listingAmounts}
                />
              ))}
            </View>
            <hr
              style={{ width: '100%', background: '#E1E1E8', color: '#E1E1E8' }}
            />
            <View justify="space-between" width="100%" alignItems="center">
              <View flex={1} direction="column">
                <Label size={13} color="#BCBCCC">
                  Listing duration
                </Label>
                <Space size={30} />
                <DurationAccordion
                  selectedItem={duration}
                  onChange={setDuration}
                  content={DURATION_OPTIONS}
                  width="100%"
                  contentExpanded={durationAccordionExpanded}
                  setContentExpanded={setDurationAccordionExpanded}
                />
              </View>
              <Space size={40} />
              <View flex={1} direction="column">
                <Label size={13} color="#BCBCCC">
                  Ends
                </Label>
                <Space size={36} />
                <Label size={30} color="#BCBCCC">
                  {moment()
                    .add(duration.value, 'days')
                    .format('DD MMM, hh:mm a')}
                </Label>
              </View>
            </View>
          </View>
          <View
            position="absolute"
            width="100%"
            padding="40px"
            bottom="0px"
            left="0px"
          >
            <View flex={1}>
              <RoundedButton
                width="100%"
                height="60px"
                border="1px solid #E1E1E8"
                disabled={!listingAmounts && !offerDuration}
                onClick={handleReset}
              >
                <Label
                  size={16}
                  color={
                    !listingAmounts && !offerDuration ? '#ffffff' : '#020202'
                  }
                >
                  Reset
                </Label>
              </RoundedButton>
            </View>
            <Space size={10} />
            <View flex={1}>
              {!allowance ? (
                <RoundedButton
                  width="100%"
                  height="60px"
                  background="#0698A8"
                  disabled={isApproving}
                  border="1px solid #EBEBF2"
                  onClick={handleApproveAllowance}
                  hoverBackground="#26a8c8"
                >
                  <Label color="#fff" size={16} letterSpacing="1px">
                    {isApproving ? 'Pending' : 'APPROVE ALL'}
                  </Label>
                  {isApproving && (
                    <LoadingSpinner color="white" margin="0 0 0 20px" />
                  )}
                </RoundedButton>
              ) : (
                <RoundedButton
                  width="100%"
                  height="60px"
                  border="1px solid #E1E1E8"
                  disabled={
                    isApproving ||
                    isListing ||
                    listingAmounts.some(am => am === 0) ||
                    !duration
                  }
                  background="#0698A8"
                  hoverBackground="#16a8b8"
                  onClick={handleConfirm}
                >
                  <Label
                    size={16}
                    color={
                      !listingAmounts ||
                      !listingAmounts.some(am => am === 0) ||
                      !duration
                        ? '#ffffff'
                        : isListing
                        ? '#000'
                        : '#ffffff'
                    }
                  >
                    {isListing ? 'Pending' : 'List'}
                  </Label>
                  {isListing && <LoadingSpinner margin="0 0 0 20px" />}
                </RoundedButton>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default ListingDialog
