import React, { useState, useEffect } from 'react'
import { Range } from 'react-range'

const getTrackBackground = ({ values, colors, min, max }) => {
  // sort values ascending
  const progress = values
    .slice(0)
    .sort((a, b) => a - b)
    .map(value => ((value - min) / (max - min)) * 100)
  const middle = progress.reduce(
    (acc, point, index) =>
      `${acc}, ${colors[index]} ${point}%, ${colors[index + 1]} ${point}%`,
    ''
  )
  return `linear-gradient(to right, ${colors[0]} 0%${middle}, ${
    colors[colors.length - 1]
  } 100%)`
}

const Ranger = ({ step, min, max, values, onChange, thumbWidth }) => {
  const [interValues, setInterValues] = useState(values)

  const handleChanged = () => {
    onChange(interValues)
  }

  useEffect(() => {
    setInterValues(values)
    return () => {}
  }, [values])

  return (
    <Range
      draggableTrack
      values={interValues}
      step={step}
      min={min}
      max={max}
      onChange={setInterValues}
      onFinalChange={handleChanged}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: '36px',
            display: 'flex',
            width: '100%'
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '10px',
              width: '100%',
              borderRadius: '4px',
              background: getTrackBackground({
                values: interValues,
                colors: ['#F3F3FA', '#0698A8', '#F3F3FA'],
                min,
                max
              }),
              alignSelf: 'center'
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '30px',
            width: thumbWidth,
            borderRadius: '4px',
            border: '1px solid #EBEBF2',
            backgroundColor: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: 'none'
          }}
        />
      )}
    />
  )
}

export default Ranger
