import styled from '@emotion/styled'
import { ReactComponent as ChartExpand } from 'assets/chart-expand.svg'
import { ReactComponent as ChartNarrow } from 'assets/chart-narrow.svg'
import { ReactComponent as CategoryIcon } from 'assets/feed-category.svg'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip
} from 'chart.js'
import RoundedButton from 'components/Forms/RoundedButton'
import KodexIcon from 'components/Icon/KodexIcon'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { VirtualizedList } from 'components/Virtualized'
import useGetFeedEvents from 'hooks/useGetFeedEvents/useGetFeedEvents'
import queryString from 'query-string'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import DurationSelector from './DurationSelector'
import FeedAccordion from './FeedAccordion/FeedAccordion'
import FilterDialog from './FilterDialog'
import getPriceChart from './getPriceChart'
// import { ReactComponent as ETHIcon } from 'assets/eth-price-grey.svg'
// import { ReactComponent as ETHIconGrey } from 'assets/eth-icon.svg'
import { ReactComponent as ETHIconDark } from 'assets/eth-icon-darker.svg'
import ScrollUpButton from 'components/ScrollUpButton'
import { CATEGORIES } from 'constants/category'
import { DURATION_ITEMS } from 'constants/feed-chart-duration'
import { FilterContext } from 'contexts/FilterProvider'
import { useLocation } from 'react-router'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import getDateLabel from './getDateLabel'
import { getFeedChartData, getFeedEventsData } from 'service/rest/getFeedData'

Chart.register(
  LinearScale,
  LineElement,
  BarController,
  BarElement,
  LineController,
  CategoryScale,
  PointElement,
  Tooltip,
  Filler
)

const filterAnimationDurationMilli = 500

export const initialFilter = {
  subcategories: [],
  status: 'sale',
  charLengths: [3, 10],
  priceRange: [undefined, undefined],
  results: [],
  asset: null
}

let isLoading = true

const Feed = () => {
  const location = useLocation()
  const parsed = queryString.parse(location?.search)

  const chartRef = useRef()
  const [chart, setChart] = useState(null)
  const [chartExpanded, setChartExpanded] = useState(false)

  const {
    feedFilter: filterOptions,
    setFeedFilter: setFilterOptions
  } = useContext(FilterContext)

  const [initChartAnimationFinished, seInitChartAnimationFinished] = useState(
    false
  )
  const [isFetchMore, setFetchMore] = useState(false)
  const [duration, setDuration] = useState(DURATION_ITEMS[3])
  const [isShowFilter, setShowFilter] = useState(false)

  const [filtersCount, setFiltersCount] = useState(0)
  const [filtersOffset, setFiltersOffset] = useState(0)

  const [feedItems, setFeedItems] = useState([])
  const [chartData, setChartData] = useState([])

  const contentRef = useRef(null)
  const pageRef = useRef(null)
  const listRef = useRef(null)
  const eventRef = useRef(filterOptions.status)

  // useEffect(() => {
  //   if (Object.keys(filterOptions).length === 0) {
  //     setFilterOptions(
  //       parsed?.category
  //         ? {
  //             ...initialFilter,
  //             subcategories: Object.values(CATEGORIES)[
  //               Object.keys(CATEGORIES).indexOf(parsed.category)
  //             ]
  //           }
  //         : initialFilter
  //     )
  //     return
  //   }
  // }, [])

  useEffect(() => {
    const parsed = queryString.parse(location.search)

    if (!parsed.category) return

    setFilterOptions(filter => ({
      ...filter,
      subcategories: Object.values(CATEGORIES)[
        Object.keys(CATEGORIES).indexOf(parsed.category)
      ]
    }))
  }, [location])

  useEffect(() => {
    if (chartExpanded) {
      setDuration(DURATION_ITEMS[DURATION_ITEMS.indexOf(duration) + 4])
      return
    }

    if (DURATION_ITEMS.indexOf(duration) >= 4)
      setDuration(DURATION_ITEMS[DURATION_ITEMS.indexOf(duration) - 4])
  }, [chartExpanded])

  const timestampRange = useMemo(() => {
    const minDate = new Date()
    const maxDate = new Date()

    if (duration?.label !== 'D') {
      maxDate.setHours(23)
      maxDate.setMinutes(59)
      maxDate.setSeconds(59)
      minDate.setHours(23)
      minDate.setMinutes(59)
      minDate.setSeconds(59)
    }

    minDate.setDate(maxDate.getDate() - duration?.value)

    if (duration?.label !== 'D') {
      minDate.setSeconds(minDate.getSeconds() + 1)
    }

    return {
      min_timestamp: Math.floor(minDate.getTime() / 1000),
      max_timestamp: Math.floor(maxDate.getTime() / 1000),
      min_timestampISO: minDate.toISOString(),
      max_timestampISO: maxDate.toISOString()
    }
  }, [duration])

  // const createTopBarColorScrollTrigger = () => {
  //   scrollbar.scrollTo(0, 0, 0)
  //   return ScrollTrigger.create({
  //     trigger: listRef.current,
  //     start: 'top top+=143',
  //     scrub: true,
  //     immediateRender: true,
  //     onEnter: () => {
  //       setStyle({
  //         ...topBarRegistryStyle,
  //         topBarBgColor: '#020202',
  //         topBarBoxShadow: '2px 2px 9px 0 rgba(0,0,0, 0.9)'
  //       })
  //     },
  //     onLeaveBack: () => {
  //       setStyle({
  //         ...topBarMarketplaceStyle,
  //         topBarBgColor: '#fff',
  //         topBarBoxShadow: '2px 2px 9px 0 rgba(0,0,0, 0.2)'
  //       })
  //     }
  //   })
  // }

  const getFeedItems = async params => {
    const items = await getFeedEventsData(params)
    setFeedItems(items || [])
    isLoading = false
  }

  const fetchChartData = async params => {
    const data = await getFeedChartData({
      ...variables,
      time_range: `days` + duration.value
    })
    setChartData(data || [])
  }

  const fetchNextPage = async () => {
    if (isFetchMore || isLoading || feedItems?.length % 50 !== 0) return
    isLoading = true

    const variables = {
      offset: filtersOffset,
      min_domain_length: filterOptions.charLengths[0],
      max_domain_length:
        filterOptions.charLengths[1] === 10
          ? null
          : filterOptions.charLengths[1],
      min_price: filterOptions.priceRange[0] ?? null,
      max_price: filterOptions.priceRange[1] ?? null,
      min_timestamp: timestampRange.min_timestamp,
      max_timestamp: timestampRange.max_timestamp,
      time_range: duration.labelRaw,
      event_types: filterOptions.status,
      asset:
        filterOptions.priceRange[0] == null &&
        filterOptions.priceRange[1] == null
          ? null
          : filterOptions.asset,
      search_terms:
        filterOptions.subcategories.length === 0
          ? undefined
          : filterOptions.subcategories
    }

    setFetchMore(true)
    const items = await getFeedEventsData(variables)
    setFeedItems(curr => [...curr, ...items])
    setFetchMore(false)
    isLoading = false
  }

  useEffect(() => {
    const variables = {
      offset: 0,
      min_domain_length: filterOptions.charLengths[0] + 4,
      max_domain_length:
        filterOptions.charLengths[1] === 10
          ? null
          : filterOptions.charLengths[1] + 4,
      min_price: filterOptions.priceRange[0] ?? null,
      max_price: filterOptions.priceRange[1] ?? null,
      min_timestamp: timestampRange.min_timestamp,
      max_timestamp: timestampRange.max_timestamp,
      time_range: duration.labelRaw,
      event_types: filterOptions.status,
      asset:
        filterOptions.priceRange[0] == null &&
        filterOptions.priceRange[1] == null
          ? null
          : filterOptions.asset,
      search_terms:
        filterOptions.subcategories.length === 0
          ? undefined
          : `{${filterOptions.subcategories.join(',')}}`
    }
    getFeedItems(variables)
    fetchChartData(variables)
    eventRef.current = filterOptions.status
  }, [filterOptions, duration, timestampRange, initChartAnimationFinished])

  useEffect(() => {
    const callback = entries => {
      const [entry] = entries
      if (entry.isIntersecting) {
        seInitChartAnimationFinished(true)

        chart.update('show')
      }
    }
    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: '0px 0px -170px 0px',
      threshold: [0, 0]
    })

    return () => {
      if (chartRef.current != null) {
        observer.unobserve(chartRef.current)
      }
    }
  }, [chartData, chart])

  const volumePrice = useMemo(() => {
    if (chartData.length === 0) {
      return 0
    }
    return chartData.reduce(
      (partialSum, event) => partialSum + Number(event.volumePrice),
      0
    )
  }, [chartData])

  const numSales = useMemo(() => {
    if (chartData.length === 0) {
      return 0
    }
    return chartData.reduce(
      (partialSum, event) => partialSum + Number(event.count),
      0
    )
  }, [chartData])

  const averagePrice = useMemo(() => {
    if (chartData.length === 0) {
      return 0
    }
    return volumePrice / numSales
  }, [volumePrice, numSales])

  useEffect(() => {
    if (!chartData) {
      return
    }

    const mappedLineEvents = chartData.map(event => event.avgPrice)
    const mappedBarEvents = chartData.map(event => event.volumePrice)
    const invisibleEvents = chartData.map(event => event.count)
    const labels = chartData.map(event => getDateLabel(event.timestamp))

    const getBgColor = context => {
      const i = context.index
      if (i === 0 || context.raw > mappedBarEvents[i - 1]) {
        return 'rgba(255,255,255, 0.5)'
      }
      return '#AF61AB'
    }

    if (chart != null) {
      chart.data.labels = labels

      chart.data.datasets[0].data = mappedBarEvents
      chart.data.datasets[1].data = mappedLineEvents
      chart.data.datasets[2].data = invisibleEvents

      chart.data.datasets[0].backgroundColor = getBgColor

      chart.update()
    } else {
      const chart = getPriceChart(
        labels,
        mappedBarEvents,
        mappedLineEvents,
        invisibleEvents,
        getBgColor,
        eventRef
      )
      setChart(chart)
    }
  }, [chartData, chart])

  const closeFilters = useCallback(filtersCount => {
    return setTimeout(() => {
      setShowFilter(false)
      setFiltersCount(filtersCount)
    }, filterAnimationDurationMilli)
  }, [])

  const getNumberLabel = number => {
    if (number === 0) {
      return '-'
    }
    if (number >= 1000) {
      return `${Number((number / 1000).toFixed(3))}K`
    }
    return Number(number.toFixed(3))
  }

  useEffect(() => {
    setFiltersOffset(feedItems.length)
  }, [feedItems])

  useEffect(() => {
    setFiltersOffset(0)
  }, [filterOptions, duration])

  const categoriesToDisplay = useMemo(() => {
    const categoriesSet = new Set()

    for (const [key, value] of Object.entries(CATEGORIES)) {
      if (value.every(c => filterOptions.subcategories?.includes(c))) {
        categoriesSet.add(key)
      } else {
        filterOptions.subcategories?.forEach(c => {
          if (value.includes(c)) {
            categoriesSet.add(c)
          }
        })
      }
    }

    return Array.from(categoriesSet)
  }, [filterOptions])

  return (
    <View
      ref={pageRef}
      width="100%"
      direction="column"
      alignItems="center"
      justify="center"
      position="relative"
    >
      <Space size={160} />
      <View
        direction="column"
        ref={contentRef}
        width="100%"
        alignItems="center"
        zIndex={1}
      >
        <View width="750px" alignItems="center" justify="space-between">
          <View width="100%" direction="row" alignItems="center">
            <View direction="column" width="150px">
              <Label color="rgba(255, 255, 255, 0.5)" size={13}>
                Avg. price
              </Label>
              <Space size={10} />
              <View>
                <Label number size={30}>
                  {getNumberLabel(
                    averagePrice > 1000000
                      ? Number(averagePrice) / 1000000
                      : averagePrice > 1000
                      ? Number(averagePrice) / 1000
                      : averagePrice
                  )}
                </Label>
                <Label color={'#aaa'}>
                  {averagePrice > 1000000
                    ? 'M'
                    : averagePrice > 1000
                    ? 'k'
                    : ''}
                </Label>
                <ETHIconDark width={28} height={28} />
              </View>
            </View>
            <View direction="column" width="150px">
              <Label color="rgba(255, 255, 255, 0.5)" size={13}>
                Volume
              </Label>
              <Space size={10} />
              <View gap="8px" alignItems="end">
                <View height="30px">
                  <Label number size={30}>
                    {getNumberLabel(
                      volumePrice > 1000000
                        ? Number(volumePrice) / 1000000
                        : volumePrice > 1000
                        ? Number(volumePrice) / 1000
                        : volumePrice
                    )}
                  </Label>
                  <Space size={2} />
                  <Label size={30} color={'#ccc'}>
                    {volumePrice > 1000000
                      ? 'M'
                      : volumePrice > 1000
                      ? 'k'
                      : ''}
                  </Label>
                  <ETHIconDark width={28} height={28} />
                </View>
              </View>
            </View>
            <View direction="column" width="150px">
              <Label color="rgba(255, 255, 255, 0.5)" size={13}>
                # {eventRef.current + 's'}
              </Label>
              <Space size={10} />
              <Label number size={30}>
                {numSales === 0
                  ? '-'
                  : numSales.toLocaleString('default', {
                      maximumFractionDigits: 0
                    })}
              </Label>
            </View>
          </View>
        </View>
        <Space size={40} />
        <View
          key="topBar"
          width="750px"
          alignItems="center"
          justify="space-between"
          id="topBar"
        >
          <View alignItems="center">
            <RoundedButton
              background={filtersCount === 0 ? '#2BA8B5' : '#fff'}
              hoverBackground={filtersCount === 0 ? '#51B7C2' : '#CDEAEE'}
              borderRadius="6px"
              onClick={() => setShowFilter(true)}
            >
              <KodexIcon
                icon={<CategoryIcon />}
                size={14}
                padding="13px"
                fill={filtersCount === 0 ? '#fff' : '#0698A8'}
              />
            </RoundedButton>
            <Space size={16} />
            <View direction="row" gap="8px" padding="0 20px 0 0" wrappable>
              <Label size={13}>
                {filtersCount === 0
                  ? 'Filter'
                  : `Selected: ${
                      categoriesToDisplay.length === 0 ? filtersCount : ''
                    }`}
              </Label>
              {categoriesToDisplay.map(category => (
                <Label
                  key={category}
                  size={13}
                  color={
                    MAIN_CATEGORY_COLORS[
                      Object.keys(CATEGORIES).indexOf(category)
                    ]
                  }
                >
                  {category}
                </Label>
              ))}
            </View>
          </View>
          <View alignItems="center">
            <DurationSelector
              currentRage={
                DURATION_ITEMS.indexOf(duration) >= 4
                  ? DURATION_ITEMS[DURATION_ITEMS.indexOf(duration) - 4]
                  : duration
              }
              onSelect={setDuration}
              chartExpanded={chartExpanded}
            />
            <Divider />
            <RoundedButton
              background="#2BA8B5"
              hoverBackground="#51B7C2"
              borderRadius="6px"
              onClick={() => setChartExpanded(!chartExpanded)}
            >
              <KodexIcon
                icon={chartExpanded ? <ChartNarrow /> : <ChartExpand />}
                size={14}
                padding="13px"
              />
            </RoundedButton>
          </View>
        </View>
        {/* <Space size={30} />
        <StickyFilters
          ref={pageRef}
          animationAlreadyShown={animationAlreadyShown}
        >
          {filterOptions.status !== 'sale' && (
            <View
              width="100%"
              justify="center"
              background={isScrolledToList ? '#020202' : '#0698A8'}
              transition="all 0.3s ease"
            >
              <View
                width="750px"
                alignItems="center"
                justify="space-between"
                padding="5px 0"
              >
                <View alignItems="center" onClick={() => setShowFilter(true)}>
                  <RoundedButton
                    background={
                      filtersCount === 0
                        ? isScrolledToList
                          ? '#262628'
                          : '#2BA8B5'
                        : '#fff'
                    }
                    hoverBackground={
                      filtersCount === 0
                        ? isScrolledToList
                          ? '#353535'
                          : '#51B7C2'
                        : '#CDEAEE'
                    }
                    borderRadius="6px"
                  >
                    <KodexIcon
                      icon={<CategoryIcon />}
                      size={14}
                      padding="13px"
                      fill={filtersCount === 0 ? '#fff' : '#0698A8'}
                    />
                  </RoundedButton>
                  <Space size={16} />
                  <Label size={13}>
                    {filtersCount === 0
                      ? 'FILTER'
                      : `SELECTED: ${filtersCount}`}
                  </Label>
                </View>
                {isScrolledToList ? (
                  <View>
                    <View
                      direction="column"
                      width="125px"
                      alignItems="flex-end"
                    >
                      <Label color="rgba(255,255,255, 0.5)" size={13}>
                        AVG.PRICE (ETH)
                      </Label>
                      <Space size={10} />
                      <Label color="#fff" size={16}>
                        {getNumberLabel(averagePrice)}
                      </Label>
                    </View>
                    <View
                      direction="column"
                      width="125px"
                      alignItems="flex-end"
                    >
                      <Label color="rgba(255,255,255, 0.5)" size={13}>
                        VOLUME (ETH)
                      </Label>
                      <Space size={10} />
                      <Label color="#fff" size={16}>
                        {getNumberLabel(volumePrice)}
                      </Label>
                    </View>
                  </View>
                ) : (
                  <View alignItems="center">
                    <DurationSelector
                      currentRage={duration}
                      onSelect={setDuration}
                    />
                    <Divider />
                    <RoundedButton
                      background="#2BA8B5"
                      hoverBackground="#51B7C2"
                      borderRadius="6px"
                      onClick={() => setChartExpanded(!chartExpanded)}
                    >
                      <KodexIcon
                        icon={chartExpanded ? <ChartNarrow /> : <ChartExpand />}
                        size={14}
                        padding="13px"
                      />
                    </RoundedButton>
                  </View>
                )}
              </View>
            </View>
          )}
        </StickyFilters> */}
        <Space size={30} />
        <View
          height="400px"
          padding="0 60px"
          width={chartExpanded ? '100%' : '870px'}
          ref={chartRef}
          transition="width 0.65s ease"
        >
          <canvas id="myChart" />
        </View>
        <Space size={30} />
      </View>

      {feedItems?.length > 10 && (
        <ScrollUpButton scrollToPosition={360} dark feed />
      )}
      <View
        width="100%"
        direction="column"
        alignItems="center"
        background="#020202"
        padding="60px"
        ref={listRef}
      >
        <Label color="#BCBCCC" size={16} width="750px">
          {numSales} Results
        </Label>
        <Space size={40} />

        <VirtualizedList
          data={feedItems}
          loading={isLoading}
          rowsRenderer={(e, idx) => <FeedAccordion key={idx} data={e} />}
          loadingRenderer={() => (
            <>
              <FeedAccordion eventType={filterOptions.status} showLoading />
              <FeedAccordion eventType={filterOptions.status} showLoading />
              <FeedAccordion eventType={filterOptions.status} showLoading />
              {/* <FeedAccordion eventType={filterOptions.status} showLoading />
              <FeedAccordion eventType={filterOptions.status} showLoading />
              <FeedAccordion eventType={filterOptions.status} showLoading />
              <FeedAccordion eventType={filterOptions.status} showLoading />
              <FeedAccordion eventType={filterOptions.status} showLoading />
              <FeedAccordion eventType={filterOptions.status} showLoading />
              <FeedAccordion eventType={filterOptions.status} showLoading />
              <FeedAccordion eventType={filterOptions.status} showLoading />
              <FeedAccordion eventType={filterOptions.status} showLoading /> */}
            </>
          )}
          onLoadMore={fetchNextPage}
          animationOptions={{
            limit: 5,
            duration: 2000
          }}
        />
      </View>
      <FilterDialog
        open={isShowFilter}
        filterOptions={filterOptions}
        feedCount={numSales}
        setFilterCount={setFiltersCount}
        onClose={closeFilters}
        onChangeFilters={setFilterOptions}
      />
    </View>
  )
}

const Divider = styled.div`
  width: 1px;
  height: 20px;
  background-color: #fff;
  opacity: 0.2;
  margin: 0 20px;
`

export default Feed
