import React from 'react'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { ethers } from 'ethers'
import Avatar from 'components/Avatar'
import DefaultAvatarImg from 'assets/icon-pfp-default.png'
import WalletAddress from 'components/Label/WalletAddress'
import { isAddress } from 'utils/address'

const SummaryFirstColumn = ({
  eventType,
  loading,
  address,
  avatar,
  onClick
}) => {
  const getColumnTitle = () => {
    if (eventType === 'registration') {
      return 'registrant'
    }
    if (eventType === 'listing') {
      return 'seller'
    }
    return 'From'
  }

  return (
    <View flex={1} direction="column" padding="0 8px 0 0">
      <Label size={13} opacity="0.5">
        {getColumnTitle()}
      </Label>
      <Space size={16} />
      {loading ? (
        <View
          width="100px"
          height="16px"
          background="#2C2C2C"
          borderRadius="6px"
        />
      ) : (
        <View
          alignItems="center"
          cursor={address !== ethers.constants.AddressZero ? 'pointer' : ''}
          onClick={e => onClick(e, address)}
        >
          {address !== ethers.constants.AddressZero && (
            <>
              <Avatar
                src={avatar ? avatar : DefaultAvatarImg}
                borderRadius="6px"
              />
              <Space size={16} />
            </>
          )}
          <Label size={16}>
            {isAddress(address) ? (
              <WalletAddress>{address}</WalletAddress>
            ) : (
              address
            )}
          </Label>
        </View>
      )}
    </View>
  )
}

export default SummaryFirstColumn
