import { useWindowListener } from 'common/useWindowListener'
import NetTokenCard from 'components/Card/NetTokenCard'
import { DomainView, useDomainViewContext } from 'components/Domain'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import { useColumnCount } from 'components/ResultList/hooks/useColumnCount'
import LoadingNetTokenRow from 'components/Row/LoadingNetTokenRow'
import Space from 'components/Space'
import View from 'components/View'
import { DOMAIN_TABLE_COLUMNS_BREAKPOINTS } from 'constants/breakpoints'
import { CartStoreContext } from 'contexts/CartStoreProvider'
import {
  TopBarStyleContext,
  topBarMarketplaceStyle,
  topBarRegistryStyle
} from 'contexts/TopBarStyleProvider'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  getTrendingCategories,
  getTrendingDomains,
  getTrendingUsers
} from 'service/rest/trending'
import NoResult from 'views/Profile/NoResult'
import Dropdown from './components/Dropdown'
import TrendingCategoriesTable from './components/TrendingCategoriesTable'
import UserCardsList from './components/UserCardsList'

const SECTIONS = ['domains', 'categories']
// const SECTIONS = ['domains', 'categories', 'users']

const DROPDOWN_ITEMS = [
  {
    label: '24 hours',
    value: 'DAY'
  },
  {
    label: '7 days',
    value: 'WEEK'
  },
  {
    label: '30 days',
    value: 'MONTH'
  }
]

let isLoadingData = true

const Trending = () => {
  const location = useLocation()
  const searchUrlParam = location.search.replace('?', '')
  const history = useHistory()

  const topBarCtx = useContext(TopBarStyleContext)

  const [selectedSection, setSelectedSection] = useState(
    SECTIONS.indexOf(searchUrlParam) > -1 ? searchUrlParam : SECTIONS[0]
  )
  const [selectedDuration, setSelectedDuration] = useState(DROPDOWN_ITEMS[0])
  const [durationDropdownOpen, setDurationDropdownOpen] = useState(false)
  const [cardWidth, setCardWidth] = useState('310px')

  const [trendingDomains, setTrendingDomains] = useState([])
  const [trendingCategories, setTrendingCategories] = useState([])

  const [trendingUsers, setTrendingUsers] = useState([])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  console.log(trendingCategories)

  useEffect(() => {
    if (window.innerWidth <= 740) {
      history.push('/marketplace')
      return
    }
  }, [])

  useEffect(() => {
    const fetchTrending = async () => {
      isLoadingData = true
      const trendingDomainsData = await getTrendingDomains({
        duration: selectedDuration.value,
        limit: 20,
        offset: 0
      })
      const trendingUsersData = await getTrendingUsers({
        duration: selectedDuration.value,
        limit: 20,
        offset: 0
      })

      const trendingCategoriesDataWeek = await getTrendingCategories()

      setTrendingCategories(trendingCategoriesDataWeek)
      isLoadingData = false
      setTrendingDomains(trendingDomainsData.domains)
      setTrendingUsers(trendingUsersData.users)
    }

    fetchTrending()
  }, [selectedDuration, selectedSection])

  useEffect(() => {
    if (selectedSection === SECTIONS[0]) {
      isLoadingData = true
      const refetchTrendingDomains = async () => {
        const trendingDomainsData = await getTrendingDomains({
          duration: selectedDuration.value,
          limit: 20,
          offset: 0
        })

        isLoadingData = false
        setTrendingDomains([...trendingDomainsData.domains])
      }

      refetchTrendingDomains()
    }

    if (selectedSection === SECTIONS[1]) {
      isLoadingData = true

      const refetchTrendingUsers = async () => {
        const trendingUsersData = await getTrendingUsers({
          duration: selectedDuration.value,
          limit: 20,
          offset: 0
        })

        isLoadingData = false
        setTrendingUsers([...trendingUsersData.users])
      }

      refetchTrendingUsers()
    }
  }, [selectedSection])

  const loadMoreTrendingDomains = async () => {
    if (!trendingDomains || trendingDomains?.length % 20 > 0) return

    const fetchedTrendingDomains = await getTrendingDomains({
      duration: selectedDuration.value,
      offset: trendingDomains.length,
      limit: 20
    })

    setTrendingDomains([...trendingDomains, ...fetchedTrendingDomains.domains])
  }

  const loadMoreTrendingUsers = async () => {
    if (!trendingUsers || trendingUsers?.length % 20 > 0) return

    const fetchedTrendingUsers = await getTrendingUsers({
      duration: selectedDuration.value,
      offset: trendingUsers.length,
      limit: 20
    })

    setTrendingUsers([...trendingUsers, ...fetchedTrendingUsers.users])
  }

  const domainViewContext = useDomainViewContext()
  const cartCtx = useContext(CartStoreContext)
  const domainsInCartNames = useMemo(
    () => cartCtx.state.domains.map(d => d.name),
    [cartCtx]
  )

  const { columnCount } = useColumnCount({
    sizeMap: DOMAIN_TABLE_COLUMNS_BREAKPOINTS
  })

  useEffect(() => {
    let calculatedCardWith
    switch (columnCount) {
      case 3:
        calculatedCardWith = '32.6%'
        break
      case 4:
        calculatedCardWith = '24.25%'
        break
      case 5:
        calculatedCardWith = '19.2%'
        break
      case 6:
        calculatedCardWith = '15.833%'
        break
      case 7:
        calculatedCardWith = '13.425%'
        break
      case 8:
        calculatedCardWith = '11.625%'
        break
      case 9:
        calculatedCardWith = '9%'
        break
      case 10:
        calculatedCardWith = '3%'
        break
      default:
        calculatedCardWith = '310px'
        break
    }

    setCardWidth(calculatedCardWith)
  }, [columnCount])

  useWindowListener(
    'resize',
    () => {
      setWindowWidth(window.innerWidth)
    },
    []
  )

  useEffect(() => {
    if (selectedSection === 'categories' && !topBarCtx.scrolled) {
      topBarCtx.setStyle(topBarRegistryStyle)
      return
    }
    if (!topBarCtx.scrolled && selectedSection !== 'categories') {
      topBarCtx.setStyle(topBarMarketplaceStyle)
    }
  }, [selectedSection, topBarCtx])

  var bodyBg = selectedSection === 'categories' ? '#020202' : '#fff'
  var bodyColor = selectedSection === 'categories' ? '#fff' : '#000'

  return (
    <View direction="column" width="100%" background={bodyBg} minHeight="100vh">
      <Container
        maxWidth="calc(100% - 140px)"
        background={bodyBg}
        height="100%"
      >
        {/* <Space size={160} />
    <Label size={110} color={bodyColor} data-in="stagger-chars">
      Trending {selectedSection}
    </Label>
    <Space size={80} /> */}
        <Space size={160} />
        <View
          width="100%"
          alignItems="center"
          justify="space-between"
          direction="row"
        >
          <View alignItems="center" direction="row">
            {SECTIONS.map(item => (
              <RoundedButton
                key={item}
                background={
                  selectedSection === item
                    ? selectedSection === 'categories'
                      ? '#262628'
                      : '#F3F3FA'
                    : bodyBg
                }
                color="black"
                padding="1.25rem"
                onClick={() => setSelectedSection(item)}
              >
                <Label color={bodyColor}>{item}</Label>
              </RoundedButton>
            ))}
          </View>
          {selectedSection !== 'categories' && (
            <Dropdown
              darkMode={selectedSection === 'categories'}
              open={durationDropdownOpen}
              setOpen={setDurationDropdownOpen}
              selectedItem={selectedDuration}
              setItem={setSelectedDuration}
            />
          )}
        </View>
        <Space size={40} />
        <View
          margin={selectedSection === 'categories' ? '0' : '0 -10px'}
          width="100%"
          padding=" 0 0 30px 0"
        >
          {selectedSection === 'domains' && (
            <DomainView
              domains={trendingDomains}
              context={domainViewContext}
              loadMore={() => loadMoreTrendingDomains()}
              loading={isLoadingData}
              windowWidth={windowWidth}
              renderers={{
                card: (data, index) => {
                  return (
                    <NetTokenCard
                      key={index}
                      domain={data}
                      width={cardWidth}
                      windowWidth={windowWidth}
                      margin="0"
                      headerBg="#EBEBF2"
                      headerColor="#000"
                      footerBg="#020202"
                      bodyBg="#fff"
                      registeredBg="#ffffff"
                      bodyColor="#020202"
                      starStrokeColor="#020202"
                      hasAnimation={true}
                      from={'marketplace'}
                      trending={true}
                      addedToCart={domainsInCartNames.includes(
                        data?.name || ''
                      )}
                      fieldGetters={{
                        name: prop => prop?.name || '',
                        expires: prop =>
                          prop?.expires ? parseInt(prop.expires) * 1000 : 0,
                        terms: prop => prop?.domains_terms || [],
                        taxonomies: prop =>
                          prop?.domains_taxonomies?.map(tax => tax.taxonomy) ||
                          [],
                        lastPrice: prop =>
                          prop?.ens_sales
                            ? prop?.ens_sales[0]?.saleassetamount || 0
                            : 0,
                        likesCount: prop =>
                          prop?.likes_aggregate?.aggregate?.count || 0
                      }}
                      onClick={() => {
                        history.push(`/domain/${data?.name || ''}`)
                      }}
                      onSelect={() => {
                        if (domainsInCartNames.includes(data?.name || '')) {
                          cartCtx.removeDomain(data?.name || '', 'Purchase')
                        } else {
                          cartCtx.addNewDomain(data, 'Purchase')
                        }
                      }}
                    />
                  )
                },
                noItems: () => <NoResult message="No Items" />
              }}
            />
          )}
          {selectedSection === 'categories' &&
            (trendingCategories.length > 0 ? (
              <TrendingCategoriesTable
                trendingCategories={trendingCategories}
              />
            ) : (
              <View width="100%" direction="column" gap="20px">
                <LoadingNetTokenRow isDarkMode trending />
                <LoadingNetTokenRow isDarkMode trending />
                <LoadingNetTokenRow isDarkMode trending />
                <LoadingNetTokenRow isDarkMode trending />
                <LoadingNetTokenRow isDarkMode trending />
                <LoadingNetTokenRow isDarkMode trending />
                <LoadingNetTokenRow isDarkMode trending />
                <LoadingNetTokenRow isDarkMode trending />
                <LoadingNetTokenRow isDarkMode trending />
                <LoadingNetTokenRow isDarkMode trending />
                <LoadingNetTokenRow isDarkMode trending />
              </View>
            ))}
          {selectedSection === 'users' && (
            <UserCardsList
              users={trendingUsers}
              cardWidth={cardWidth}
              windowWidth={windowWidth}
              loadMore={loadMoreTrendingUsers}
              loading={isLoadingData}
              noItems={() => <NoResult message="No Items" />}
            />
          )}
        </View>
      </Container>
    </View>
  )
}

export default Trending
