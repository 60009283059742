import Label from 'components/Label'
import View from 'components/View'
import { useEffect, useRef } from 'react'

let isVisible = false

const LoadingMobileNetTokenCard = ({
  hasAnimation = true,
  index,
  isDarkMode,
  windowWidth
}) => {
  // const cardRef = useRef(null)

  // const handleChangeVisibilit = entries => {
  //   const [entry] = entries
  //   isVisible = entry.intersectionRatio === 1
  // }

  // const observerOptions = {
  //   root: null,
  //   rootMargin: '0px',
  //   threshold: 1.0
  // }

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     handleChangeVisibilit,
  //     observerOptions
  //   )

  //   if (isVisible) {
  //     observer.unobserve(cardRef.current)
  //     return
  //   }

  //   if (cardRef.current) observer.observe(cardRef.current)

  //   return () => {
  //     if (cardRef.current) observer.unobserve(cardRef.current)
  //   }
  // }, [observerOptions, handleChangeVisibilit, cardRef, isVisible])

  return (
    <View
      // ref={cardRef}
      width="calc(100vw - 32px)"
      height="auto"
      maxHeight={'152px'}
      border={isDarkMode && `1px solid #4A4A4D`}
      borderRadius="10px"
      transition="0.2s ease-out"
      // opacity={!hasAnimation || isVisible ? 1 : 0}
      zIndex={1000}
    >
      <View
        width="100%"
        height="100%"
        direction="column"
        background={isDarkMode ? '#4A4A4D' : '#F3F3FA'}
        border={isDarkMode ? '1px solid #4A4A4D' : '1px solid #E1E1E8'}
        overflow="hidden"
        borderRadius="10px"
      >
        <View
          width="100%"
          height="150px"
          flex={1}
          direction="column"
          background={'#020202'}
          borderRadius="10px"
          style={{
            borderBottom: isDarkMode ? `1px solid ${'#4A4A4D'}` : 'none'
          }}
        >
          <View
            height="80px"
            width="100%"
            flex={1}
            position="relative"
            zIndex="10"
            direction="column"
            justify="space-between"
            background={isDarkMode ? '#212121' : '#FFF'}
            borderRadius="6px"
            padding="16px"
            gap="10px"
          >
            <View width="100%" justify="space-between" alignItems="center">
              <Label size={30} color={'#fff'} width="100%" whiteSpace="nowrap">
                <View
                  width="33%"
                  maxWidth="70px"
                  height="20px"
                  background={
                    isDarkMode
                      ? 'linear-gradient(to right, #262628, #555555, #262628, #262628, #262628)'
                      : 'linear-gradient(to right, #e3e3ea, #F3F3FA, #e3e3ea, #e3e3ea, #e3e3ea)'
                  }
                  borderRadius="6px"
                  animation="loading 1s infinite"
                />
              </Label>
              <View
                width="33%"
                maxWidth="70px"
                height="20px"
                background={
                  isDarkMode
                    ? 'linear-gradient(to right, #262628, #555555, #262628, #262628, #262628)'
                    : 'linear-gradient(to right, #e3e3ea, #F3F3FA, #e3e3ea, #e3e3ea, #e3e3ea)'
                }
                borderRadius="6px"
                animation="loading 1s infinite"
              />
            </View>
            <View width="100%" justify="space-between" alignItems="center">
              <Label size={30} color={'#fff'} width="100%" whiteSpace="nowrap">
                <View
                  width="33%"
                  maxWidth="70px"
                  height="20px"
                  background={
                    isDarkMode
                      ? 'linear-gradient(to right, #262628, #555555, #262628, #262628, #262628)'
                      : 'linear-gradient(to right, #e3e3ea, #F3F3FA, #e3e3ea, #e3e3ea, #e3e3ea)'
                  }
                  borderRadius="6px"
                  animation="loading 1s infinite"
                />
              </Label>
              <View
                width="33%"
                maxWidth="70px"
                height="20px"
                background={
                  isDarkMode
                    ? 'linear-gradient(to right, #262628, #555555, #262628, #262628, #262628)'
                    : 'linear-gradient(to right, #e3e3ea, #F3F3FA, #e3e3ea, #e3e3ea, #e3e3ea)'
                }
                borderRadius="6px"
                animation="loading 1s infinite"
              />
            </View>
          </View>
          <View
            position="relative"
            width="100%"
            height={isDarkMode ? '72px' : '85px'}
            alignItems="center"
            justify="space-between"
            padding={isDarkMode ? '16px 18px' : '26px 18px 16px 18px'}
            borderRadius="10px"
            top={!isDarkMode && '-10px'}
            background={isDarkMode ? '#000' : '#F6F6F9'}
          >
            <View
              width="100%"
              height="40px"
              background={
                isDarkMode
                  ? 'linear-gradient(to right, #2c2c30, #555555, #2c2c30, #2c2c30, #2c2c30)'
                  : 'linear-gradient(to right, #e3e3ea, #F3F3FA, #e3e3ea, #e3e3ea, #e3e3ea)'
              }
              borderRadius="6px"
              animation="loading 1s infinite"
            />
          </View>
        </View>
      </View>
    </View>
  )
}

export default LoadingMobileNetTokenCard
