import React, { useCallback, useMemo, useState } from 'react'
import View from 'components/View'
import Label from 'components/Label'
import Space from 'components/Space'
import NotificationSettingItem from './components/NotificationSettingItem'
import SaveButton from './components/SaveButton'

const NotificationSettingTab = ({
  itemSold,
  bidActivity,
  priceChange,
  outbid,
  successfulPurchase,
  selected
}) => {
  const [itemSoldNotification, setItemSoldNotification] = useState(itemSold)
  const [bidActivityNotification, setBidActivityNotification] = useState(
    bidActivity
  )
  const [priceChangeNotification, setPriceChangeNotification] = useState(
    priceChange
  )
  const [outbidNotification, setOutbidNotification] = useState(outbid)

  const [changesSaved, setChangesSaved] = useState(false)

  const [
    successfulPurchaseNotification,
    setSuccessfulPurchaseNotification
  ] = useState(successfulPurchase)

  const onItemSoldNotificationChange = useCallback(() => {
    setItemSoldNotification(!itemSoldNotification)
    setChangesSaved(false)
  }, [itemSoldNotification])

  const onBidActivityNotificationChange = useCallback(() => {
    setBidActivityNotification(!bidActivityNotification)
    setChangesSaved(false)
  }, [bidActivityNotification])

  const onPriceNotificationChange = useCallback(() => {
    setPriceChangeNotification(!priceChangeNotification)
    setChangesSaved(false)
  }, [priceChangeNotification])

  const onOutbidNotificationChange = useCallback(() => {
    setOutbidNotification(!outbidNotification)
    setChangesSaved(false)
  }, [outbidNotification])

  const onSuccessfulPurchaseNotificationChange = useCallback(() => {
    setSuccessfulPurchaseNotification(!successfulPurchaseNotification)
    setChangesSaved(false)
  }, [successfulPurchaseNotification])

  const dataDidChange = useMemo(
    () =>
      itemSold !== itemSoldNotification ||
      bidActivity !== bidActivityNotification ||
      priceChange !== priceChangeNotification ||
      outbid !== outbidNotification ||
      successfulPurchase !== successfulPurchaseNotification,
    [
      itemSold,
      itemSoldNotification,
      bidActivity,
      bidActivityNotification,
      priceChange,
      priceChangeNotification,
      outbid,
      outbidNotification,
      successfulPurchase,
      successfulPurchaseNotification
    ]
  )

  const saveChanges = () => {
    // console.log('Save')
    setChangesSaved(true)
  }

  if (!selected) {
    return <></>
  }

  return (
    <View direction="column" width="100%">
      <Space size={17} />
      <Label size={16} weight={600} color="#BCBCCC">
        NOTIFY ME WHEN
      </Label>
      <Space size={17} />
      <NotificationSettingItem
        title="Item sold"
        subtitle="when someone purchased one of your items"
        selected={itemSoldNotification}
        onClick={onItemSoldNotificationChange}
      />
      <NotificationSettingItem
        title="Bid activity"
        subtitle="when someone bids on one of your items"
        selected={bidActivityNotification}
        onClick={onBidActivityNotificationChange}
      />
      <NotificationSettingItem
        title="Price change"
        subtitle="When an item you made an offer on changes in price"
        selected={priceChangeNotification}
        onClick={onPriceNotificationChange}
      />
      <NotificationSettingItem
        title="Outbid"
        subtitle="When an offer you placed is exceeded by another user"
        selected={outbidNotification}
        onClick={onOutbidNotificationChange}
      />
      <NotificationSettingItem
        title="Successful Purchase"
        subtitle="When you successfully buy an item"
        selected={successfulPurchaseNotification}
        onClick={onSuccessfulPurchaseNotificationChange}
      />
      <Space size={60} />
      <SaveButton
        disabled={!dataDidChange || changesSaved}
        onClick={saveChanges}
      >
        {changesSaved ? 'CHANGES SAVED' : 'SAVE CHANGES'}
      </SaveButton>
      <Space size={70} />
    </View>
  )
}

export default NotificationSettingTab
