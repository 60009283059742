import { useQuery } from '@apollo/client'
import NetworkInvalidIcon from 'assets/network/invalid-network.png'
import BottomModal from 'components/BottomModal'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import { APP_DATA } from 'components/Layout/ApolloContainer'
import Space from 'components/Space'
import View from 'components/View'
import useReactiveVarListeners from 'hooks/useReactiveVarListeners'
import React, { useEffect } from 'react'

// const mainnetEthChainId = 1
const mainnetEthChainIdHex = '0x1'
// const mainnetEthNativeCurrency = 'ETH'
// const mainnetEthPublicRPCUrl = 'https://eth-mainnet.public.blastapi.io'
// const mainnetEthBlockExplorerUrl = 'https://etherscan.io'

const InvalidNetwork = () => {
  useReactiveVarListeners()

  const provider = window.ethereum // getProvider()
  const {
    data: { globalError }
  } = useQuery(APP_DATA)

  const [invalidNetworkModalOpen, setInvalidNetworkModalOpen] = React.useState(
    !globalError ? false : true
  )

  useEffect(() => {
    setInvalidNetworkModalOpen(!globalError ? false : true)
  }, [globalError])

  const handleNetworkSwitchToEth = async () => {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: mainnetEthChainIdHex }]
      })
      setInvalidNetworkModalOpen(false)
    } catch {}
  }

  return (
    <BottomModal
      open={invalidNetworkModalOpen}
      onClose={handleNetworkSwitchToEth}
      closeButtonLabel="Switch Network"
      contentPadding="0"
      contentBackground="#fff"
    >
      <View
        alignItems="center"
        justify="center"
        direction="column"
        width="100%"
        padding="7.5rem 0 9.625rem 0"
        background="#fff"
        overflow="auto"
      >
        <img src={NetworkInvalidIcon} height={200} width={200} />
        <Space size={60} />
        <Label size={60} color="black">
          Unsupported Network
        </Label>
        <Space size={20} />
        <Label size={16} lineHeight="25.6px" color="#BCBCCC">
          Kodex is currently only available on Ethereum Mainnet.
        </Label>
        <Space size={80} />
        <RoundedButton
          background="#AF61AB"
          borderRadius="6px"
          border="none"
          width="500px"
          height="60px"
          onClick={handleNetworkSwitchToEth}
          hoverOpacity="0.6"
        >
          <Label>Switch Network</Label>
        </RoundedButton>
      </View>
    </BottomModal>
  )
}

export default InvalidNetwork
