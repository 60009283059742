import { useQuery } from '@apollo/client'
import { useAccount } from 'components/QueryAccount'
import { defaultPfps } from 'constants/defaultPfps'
import { AccountContext } from 'contexts/AccountProvider'
import { BigNumber, ethers } from 'ethers'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import {
  GET_DOMAIN_SUBCATEGORIES,
  GET_DOMAIN_VIEWS,
  GET_ENS_DOMAIN_OWNER,
  GET_LIKE_AMOUNT_BY_DOMAIN,
  kodexApiContext
} from 'service/graphql/kodex-api'
import { GET_RENT_PRICE } from 'service/graphql/queries'
import getExpiryDate from 'service/rest/getExpiryDate'
import { getListing } from 'service/rest/getListing'
import { like, unlike } from 'service/rest/likeMutation'
import { ENS_CONTRACT_ADDRESS } from 'utils/utils'
import useUserProfile from './useUserProfile'

const YEAR_IN_SECONDS = 31556952

export const REGISTRATION_STATUS = {
  GRACE_PERIOD: 'grace period',
  RECENTLY_EXPIRED: 'premium',
  NEW: 'new'
}

export default (
  domainProp,
  gettersProp = {},
  refetchData = false,
  from = ''
) => {
  const getters = {
    ...defaultGetters,
    ...(gettersProp || {})
  }

  const { isConnected, handleConnectProvider } = useContext(AccountContext)
  const [listingPrice, setListingPrice] = useState(undefined)
  const [price, setPrice] = useState(undefined)
  const [listingDate, setListingDate] = useState(null)
  const [expires, setExpires] = useState(0)
  const account = useAccount()

  const domainName = getters.name(domainProp)
  const domainLabel = domainName.split('.')[0]

  const { data: domainViews } = useQuery(GET_DOMAIN_VIEWS, {
    variables: { name: domainName },
    ...kodexApiContext
  })

  const views = domainViews?.domains[0]?.views

  const { data: likeAddresses, refetch: refetchLikes } = useQuery(
    GET_LIKE_AMOUNT_BY_DOMAIN,
    {
      variables: {
        domain: domainName,
        offset: 0,
        limit: 1000
      },
      ...kodexApiContext
    }
  )

  useEffect(() => {
    refetchLikes()
  }, [isConnected])

  const likesAmount = useMemo(
    () => likeAddresses?.likes?.filter(like => like.liked),
    [likeAddresses]
  )

  const defaultPfpNumber = useMemo(() => {
    return Math.floor(Math.random() * 6)
  }, [])

  const tokenId = BigNumber.from(
    ethers.utils.keccak256(ethers.utils.toUtf8Bytes(domainLabel))
  ).toString()

  const tokenIdHex = BigNumber.from(
    ethers.utils.keccak256(ethers.utils.toUtf8Bytes(domainLabel))
  ).toHexString()

  const terms = getters.terms(domainProp)
  // const taxonomies = getters.taxonomies(domainProp)
  const lastPrice = getters.lastPrice(domainProp) || 0
  const lastPriceAsset = getters.lastPriceAsset(domainProp) || 0
  const likesCount = useMemo(
    () => likesAmount?.length || getters.likesCount(domainProp),
    [likesAmount]
  )

  const { data: taxonomies } = useQuery(GET_DOMAIN_SUBCATEGORIES, {
    variables: { domain: domainName },
    ...kodexApiContext
  })

  const isLikedByUser =
    likesAmount?.findIndex(
      l => l.user.toLowerCase() === account.toLowerCase()
    ) > -1

  const { data: rentPriceData, refetch: refetchRentPrice } = useQuery(
    GET_RENT_PRICE,
    {
      variables: {
        duration: 1 * YEAR_IN_SECONDS,
        label: domainLabel.replace('.eth', ''),
        commitmentTimerRunning: false
      }
      // onError: e => {
      //   console.log(e)
      // },
      // onCompleted: data => {
      //   console.log(data)
      // }
    }
  )

  useEffect(() => {
    if (!!rentPriceData) {
      setPrice(Number(ethers.utils.formatEther(rentPriceData.getRentPrice)))
      return
    }
    refetchRentPrice()
  }, [domainLabel, account, rentPriceData])

  const { data: ensDomainOwnerData, refetch: refetchOwner } = useQuery(
    GET_ENS_DOMAIN_OWNER,
    {
      variables: { label: domainLabel },
      ...kodexApiContext,
      skip: !domainLabel
    }
  )

  const ownerAddress =
    ensDomainOwnerData?.ens_?.registrations[0]?.registrant?.id

  const { profile, refetchAll } = useUserProfile(ownerAddress)
  const { picture: ownerPfp, username: ownerUsername } = profile.userInfo || {}

  const refetchDataFn = () => {
    void refetchOwner({ label: domainLabel })
    void refetchAll()
  }

  useEffect(() => {
    refetchDataFn
  }, [refetchData])

  const fetchExpiryDate = async () => {
    const expiryDate = await getExpiryDate(domainName)
    setExpires((await expiryDate.toNumber()) * 1000)
  }

  useEffect(() => {
    fetchExpiryDate()
  }, [domainName])

  // const expires = registration?.registrations[0]?.expiryDate * 1000

  const isRegistered = useMemo(
    () =>
      expires
        ? moment()
            .subtract(90, 'days')
            .isBefore(expires)
        : undefined,
    [expires]
  )

  const [isListed, setIsListed] = useState(undefined)
  const [listingExpireTime, setListingExpireTime] = useState('')
  const [allListingSources, setAllListingSources] = useState([])
  const [expirationStatus, setExpirationStatus] = useState('')

  /**
   * @param {string} expiration - UTC timestamp / 1000
   * @returns {REGISTRATION_STATUS}
   */

  const getRegistrationStatus = expiration => {
    // check for undefined on 0 expirations
    if (!expiration) {
      return undefined
    }

    const expirationDate = new Date(parseInt(expiration))
    const now = new Date()

    const getFutureDate = days => {
      const futureDate = moment(expiration).add(days, 'days')
      return futureDate
    }

    if (moment(now).isAfter(getFutureDate(149))) {
      return undefined
    }

    if (moment(now).isAfter(getFutureDate(111))) {
      return REGISTRATION_STATUS.NEW
    }

    if (moment(now).isAfter(getFutureDate(90))) {
      return REGISTRATION_STATUS.RECENTLY_EXPIRED
    }

    if (moment(now).isAfter(expirationDate)) {
      return REGISTRATION_STATUS.GRACE_PERIOD
    }

    return undefined
  }
  // const [toggleLikeMutation] = useMutation(
  //   isLikedByUser ? REMOVE_DOMAIN_LIKE : ADD_DOMAIN_LIKE,
  //   {
  //     variables: {
  //       user: account.toLowerCase(),
  //       domain: domainName
  //     },
  //     onCompleted: () => {
  //       refetchLikes({
  //         domain: domainName,
  //         offset: 0,
  //         limit: 100000
  //       })
  //     },
  //     update(cache, { data }) {
  //       if (isLikedByUser) {
  //         cache.modify({
  //           id: cache.identify({
  //             __typename: 'profile_view',
  //             user: account.toLowerCase()
  //           }),
  //           fields: {
  //             likes: value => (value > 0 ? value - 1 : 0),
  //             likesList: (list = [], { readField }) =>
  //               list.filter(item => readField('domain', item) !== domainName)
  //           }
  //         })
  //         cache.modify({
  //           id: cache.identify({
  //             __typename: 'ranked_domains_view',
  //             name: domainName
  //           }),
  //           fields: {
  //             likes: value => (value > 0 ? value - 1 : 0)
  //           }
  //         })
  //       } else {
  //         const newItemData = data?.['set_like']?.[0] || {}

  //         cache.modify({
  //           id: cache.identify({
  //             __typename: 'profile_view',
  //             user: account.toLowerCase()
  //           }),
  //           fields: {
  //             likes: value => value + 1,
  //             likesList: (list = [], { readField }) => {
  //               const cachedDomain = cache.readFragment({
  //                 id: cache.identify({
  //                   __typename: 'ranked_domains_view',
  //                   name: domainName
  //                 }),
  //                 fragment: gql`
  //                   fragment Domain on ranked_domains_view {
  //                     name
  //                     likes
  //                   }
  //                 `
  //               })
  //               const domainRef = cache.writeFragment({
  //                 data: {
  //                   ...cachedDomain,
  //                   likes: cachedDomain.likes + 1
  //                 },
  //                 fragment: gql`
  //                   fragment Domain on ranked_domains_view {
  //                     likes
  //                   }
  //                 `
  //               })

  //               const newItem = {
  //                 __typename: newItemData.__typename,
  //                 domain: newItemData.domain,
  //                 liked: true,
  //                 domainViewByDomain: domainRef
  //               }
  //               return [newItem, ...list]
  //             }
  //           }
  //         })
  //       }
  //     },
  //     refetchQueries: [{ query: GET_LIKES_BY_DOMAIN }, 'GetLikesByDomain'],

  //     ...kodexApiContext
  //   }
  // )

  // const toggleLike = async (...args) => {
  //   if (!isConnected) {
  //     return () => {}
  //   }

  //   try {
  //     await handleSIWE()
  //   } catch (e) {
  //     throw e
  //   }

  //   return toggleLikeMutation(...args)
  // }

  const toggleLike = async () => {
    if (!isConnected) {
      try {
        console.log('yoo hooo')
        await handleConnectProvider()
      } catch (e) {
        throw new Error(e)
      }

      return () => {}
    }

    if (isLikedByUser) {
      const unlikeDomain = await unlike({
        domainId: tokenIdHex
      })

      if (unlikeDomain.ok) {
        refetchLikes()
      }

      return
    }

    const likeDomain = await like({
      domainId: tokenIdHex
    })

    if (likeDomain.ok) {
      refetchLikes()
    }
  }

  useEffect(() => {
    setExpirationStatus(getRegistrationStatus(expires))
  }, [expires])

  useEffect(() => {
    const fetchListing = async () => {
      if (!isRegistered && (from.toLowerCase() === 'registry' || expires)) {
        setListingPrice(false)
        setIsListed(false)
        return
      }

      if (expires && moment().isAfter(expires)) {
        setListingPrice(false)
        setIsListed(false)
        return
      }

      if (localStorage.getItem(`${domainName.replace('.eth', '')}-listing`)) {
        const loadItem = JSON.parse(
          localStorage.getItem(`${domainName.replace('.eth', '')}-listing`)
        )
        setListingPrice(loadItem?.price)
        setIsListed(true)
        setListingExpireTime(loadItem?.expires)
        return
      }

      try {
        const rawListings = await getListing({
          address: ENS_CONTRACT_ADDRESS,
          token_id: tokenId
        })

        const listings = rawListings.sort(
          (a, b) => a.price.amount.decimal - b.price.amount.decimal
        )

        if (listings.length === 0) {
          setIsListed(false)
          setListingPrice(false)
          return
        }

        const kodexListing = listings.filter(
          listing => listing.source.domain === 'kodex.io'
        )

        if (!moment().isBefore(listings[0]?.expiration * 1000)) {
          setListingPrice(false)
          setIsListed(false)
          return
        }

        const filteredListings = listings.filter(listing => {
          if (!kodexListing || kodexListing.length === 0) return true

          return (
            listing.price.amount.decimal >=
            kodexListing?.[0].price.amount.decimal
          )
        })

        if (!moment().isBefore(Number(filteredListings[0]?.expiration) * 1000))
          return
        if (filteredListings[0].price.decimal === null) return

        const price = filteredListings[0].price.amount.decimal
        const endTime = filteredListings[0]?.expiration
        const listTime = listings[0].createdAt

        const listingSources = filteredListings.map(listing => ({
          name: listing.source.domain,
          icon: listing.source.icon,
          ethPrice: listing.price.amount.decimal,
          usdPrice: listing.price.amount.usd,
          url: listing.source.url
        }))

        setListingExpireTime(endTime || '')
        setIsListed(true)
        setListingPrice(price)
        setAllListingSources(listingSources)
        setListingDate(listTime)
      } catch (err) {
        setIsListed(false)
        throw new Error(err)
      }
    }

    fetchListing()
  }, [domainName, isRegistered, refetchData])

  return {
    name: domainName,
    label: domainLabel,
    tokenId,
    tokenIdHex,
    price,
    expires,
    terms,
    categories: taxonomies?.ranked_domains_view?.[0],
    ownerPfp: ownerPfp ? ownerPfp : defaultPfps[defaultPfpNumber],
    ownerAddress,
    ownerUsername,
    likesCount,
    lastPrice,
    expirationStatus,
    lastPriceAsset,
    isRegistered,
    isLikedByUser,
    likesAmount,
    registrations: [],
    isListed,
    listingPrice,
    listingExpireTime,
    views,
    listingDate,
    refetchDataFn,
    listedOn: allListingSources,
    toggleLike: () => {
      toggleLike()
    }
  }
}

const defaultGetters = {
  name: prop => prop?.name || prop?.domain || '',
  expires: prop => (prop?.expire_time ? parseInt(prop.expire_time) * 1000 : 0),
  terms: prop => prop?.terms || [],
  taxonomies: prop => prop?.taxonomies || [],
  lastPrice: prop => prop?.last_price || undefined,
  lastPriceAsset: prop => prop?.last_sale_asset || 'ETH',
  likesCount: prop => prop?.likes || 0
}
