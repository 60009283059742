import styled from '@emotion/styled/macro'

import NoResults from './NoResults'
import SearchLoader from './SearchLoader'
import SearchResult from './SearchResult'

const ModalBody = ({
  data,
  searchTerm,
  categoryList,
  closeModal,
  isLoading
}) => {
  const { loading, marketDomains, registryDomains, users, categories } = data

  if (loading || isLoading) {
    return <SearchLoader />
  }

  if (!marketDomains.length && !registryDomains.length && !users.length) {
    return <NoResults categories={categoryList} />
  }

  return (
    <SearchResult
      data={{ marketDomains, registryDomains, users, categories }}
      searchTerm={searchTerm}
      onClose={closeModal}
    />
  )
}

export default ({ data, closeModal, searchTerm, categoryList, isLoading }) => (
  <Modal>
    <ModalContent>
      <ModalBody
        data={data}
        searchTerm={searchTerm}
        categoryList={categoryList}
        closeModal={closeModal}
        isLoading={isLoading}
      />
    </ModalContent>
  </Modal>
)

const Modal = styled.div`
  position: absolute;
  z-index: 1;
  top: 70px;
  left: -140px;
  width: 550px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  max-height: calc(100vh - 100px);
  padding: 20px 30px;
  overflow-y: scroll;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const ModalContent = styled.div`
  transition: all 0.4s ease;
  display: flex;
`
