import React, { useContext, createContext, useEffect, useState } from 'react'

import Logo from 'assets/logo.svg'
import DarkLogo from 'assets/logo-dark.svg'
import { useLocation } from 'react-router'

export const topBarMarketplaceStyle = {
  logo: DarkLogo,
  pageLinkColor: '#020202',
  quickSearchButtonColor: '#020202',
  dividerColor: '#E1E1E8',
  chatButtonColor: '#020202',
  accountButtonColor: '#F3F3FA',
  myCartButtonColor: '#F3F3FA',
  myCartButtonIconColor: '#020202',
  connectWalletButtonColor: '#020202',
  topBarBgColor: 'transparent',
  topBarBoxShadow: '',
  background: '#fff'
}

export const topBarRegistryStyle = {
  logo: Logo,
  pageLinkColor: '#fff',
  quickSearchButtonColor: '#fff',
  dividerColor: '#fff',
  chatButtonColor: '#fff',
  accountButtonColor: '#262628',
  myCartButtonColor: '#262628',
  myCartButtonIconColor: '#fff',
  connectWalletButtonColor: '#fff',
  topBarBgColor: 'transparent',
  topBarBoxShadow: '',
  background: '#020202'
}

export const topBarHomeStyle = {
  logo: Logo,
  pageLinkColor: '#FFFFFF',
  quickSearchButtonColor: '#FFFFFF',
  dividerColor: '#FFFFFF',
  chatButtonColor: '#FFFFFF',
  accountButtonColor: '#FFFFFF',
  myCartButtonColor: '#FFFFFF',
  myCartButtonIconColor: '#020202',
  connectWalletButtonColor: '#FFFFFF',
  topBarBgColor: 'transparent' // на скролле черный
}

export const TopBarStyleContext = createContext({
  style: topBarRegistryStyle,
  scrolled: false,
  scrolledMarketplace: false,
  setStyle: style => {},
  setScrolled: scrolled => {},
  setScrolledMarketplace: scrolledMarketplace => {}
})

const TopBarStyleProvider = ({ children }) => {
  const [style, setStyle] = useState(topBarMarketplaceStyle)
  const location = useLocation()
  const [scrolled, setScrolled] = useState(false)
  const [scrolledMarketplace, setScrolledMarketplace] = useState(false)

  const isRegistryStyle =
    (style === topBarRegistryStyle && location.pathname.includes('/domain')) ||
    location.pathname.includes('/registry') ||
    style === topBarRegistryStyle
  const isHomeStyle =
    location.pathname === '/' ||
    location.pathname.includes('/feed') ||
    location.pathname.includes('/profile')

  useEffect(() => {
    if (location.pathname === '/about') {
      setStyle({ ...topBarRegistryStyle, topBarBgColor: '#020202' })
      return
    }

    let styleToSet
    let topBarBgColor = 'transparent'
    let topBarBoxShadow = ''
    if (isRegistryStyle) {
      styleToSet = topBarRegistryStyle
    } else if (isHomeStyle) {
      styleToSet = topBarHomeStyle
    } else {
      styleToSet = topBarMarketplaceStyle
    }

    if (scrolled) {
      if (isRegistryStyle || style === topBarRegistryStyle) {
        topBarBgColor = '#020202'
      } else {
        topBarBgColor = '#fff'
        topBarBoxShadow = '2px 2px 9px 0 rgba(0,0,0, 0.2)'
        styleToSet = topBarMarketplaceStyle
      }
    }

    if (scrolledMarketplace) {
      if (isRegistryStyle) topBarBoxShadow = 'none'
      else {
        topBarBoxShadow = '0'
        topBarBgColor = '#fff'
        styleToSet = topBarMarketplaceStyle
      }
    }

    setStyle({ ...styleToSet, topBarBgColor, topBarBoxShadow })
  }, [location.pathname, scrolled, scrolledMarketplace])

  return (
    <TopBarStyleContext.Provider
      value={{
        style,
        scrolled,
        scrolledMarketplace,
        setStyle,
        setScrolled,
        setScrolledMarketplace
      }}
    >
      {children}
    </TopBarStyleContext.Provider>
  )
}

export function useTopBarStyle() {
  const context = useContext(TopBarStyleContext)
  if (context === undefined) {
    throw new Error('useTopBarStyle must be used within a TopBarStyleProvider')
  }
  return context
}

export default TopBarStyleProvider
