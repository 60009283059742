import styled from '@emotion/styled/macro'
import { device } from 'constants/display'

const TopbarContainer = styled.div`
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${window.innerWidth < 740 ? '0 16px' : '0px 70px 0 70px'};
  box-shadow: ${props => props.boxShadow};
  background: ${props => props.background};
  transition: all 0.3s ease;

  @media ${device.mobile} {
    padding: 0px 10px 0 10px;
  }
`

export default TopbarContainer
