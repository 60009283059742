import styled from '@emotion/styled/macro'
import { ReactComponent as EthPrice } from 'assets/eth-price-grey.svg'
import { ReactComponent as EthPriceLight } from 'assets/eth-price-light.svg'
import { ReactComponent as Redirect } from 'assets/redirect.svg'
import Date from 'components/DateTime/Date'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { useCallback, useEffect, useState } from 'react'
import IconWrapper from '../../components/IconWrapper'

const Details = ({
  tokenId,
  registrationDate,
  expires,
  domainName,
  isRegistered,
  whiteMode,
  bodyBg,
  bodyColor,
  bodyBorder
}) => {
  const [isCopied, setCopied] = useState(false)

  const handleCopy = useCallback(() => {
    if (!isCopied) {
      setCopied(true)

      const el = document.createElement('textarea')
      el.value = tokenId
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    }
  }, [isCopied, tokenId])

  useEffect(() => {
    if (!isCopied) {
      return
    }

    const timeout = setTimeout(() => setCopied(false), 5000)

    return () => clearTimeout(timeout)
  }, [isCopied])

  const priceEstimate = 0.09

  return (
    <View
      width="100%"
      borderRadius="10px"
      height="100%"
      direction="column"
      background={bodyBg}
      padding="40px 20px 30px 20px"
    >
      <View alignItems="center" padding="17px 20px">
        <Label weight={600} color={bodyColor}>
          Details
        </Label>
      </View>
      <DetailsStyle>
        <View
          padding="17px 20px"
          alignItems="center"
          width="100%"
          justify="space-between"
        >
          <Label color={whiteMode ? '#BCBCCC' : '#fff9'}>Last registered</Label>
          <Label color={whiteMode ? '#212121' : '#fff'}>
            {registrationDate ? (
              <Date dateString={registrationDate * 1000} />
            ) : (
              '-'
            )}
          </Label>
        </View>
        <View
          padding="17px 20px"
          alignItems="center"
          width="100%"
          justify="space-between"
        >
          <Label color={whiteMode ? '#BCBCCC' : '#fff9'}>Domain expires</Label>
          <Label color={whiteMode ? '#212121' : '#fff'}>
            <Date dateString={expires * 1000} />
          </Label>
        </View>
        <View
          padding="17px 20px"
          alignItems="center"
          width="100%"
          justify="space-between"
        >
          <Label color={whiteMode ? '#BCBCCC' : '#fff9'}>Token ID</Label>
          <div
            style={{
              backgroundColor: 'rgb(0 0 0 / 0.75)',
              borderRadius: '6px',
              transition: 'opacity 100ms',
              color: 'rgb(255 255 255)',
              padding: '6px 12px',
              opacity: isCopied ? 1 : 0,
              position: 'absolute',
              top: '-100%',
              right: '0',
              transform: 'translate(0, -50%)'
            }}
          >
            Token ID copied
          </div>
          <button style={{ cursor: 'pointer' }} onClick={handleCopy}>
            <Label number color={whiteMode ? '#212121' : '#fff'}>
              {tokenId.slice(0, 16)}...
            </Label>
          </button>
        </View>
        <View
          padding="17px 20px"
          width="100%"
          justify="space-between"
          alignItems="center"
        >
          <View alignItems="center">
            <Label color={whiteMode ? '#BCBCCC' : '#fff9'}>
              Estimated value
            </Label>
            <Space size={10} />
            <RedirectWrapper
              href={`https://ensappraiser.com/appraise/${domainName}`}
              target="_blank"
            >
              <Redirect />
            </RedirectWrapper>
          </View>

          <Label color={whiteMode ? '#212121' : '#fff'}>
            {priceEstimate ? (
              <View justify="flex-end" alignItems="center">
                <IconWrapper size={24}>
                  {whiteMode ? <EthPrice /> : <EthPriceLight />}
                </IconWrapper>
                <Space size={10} />
                <Label color={whiteMode ? '#212121' : '#fff'}>
                  {priceEstimate}
                </Label>
              </View>
            ) : (
              <Space />
            )}
          </Label>
        </View>
      </DetailsStyle>
    </View>
  )
}

const DetailsStyle = styled.div`
  display: flex;
  border-radius: 10px;
  height: 100%;
  margin-top: 20px;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
`

const RedirectWrapper = styled.a`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export default Details
