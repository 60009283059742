import styled from '@emotion/styled/macro'
import { ReactComponent as ArrowBackGray } from 'assets/arrow-back-gray.svg'
import { ReactComponent as ArrowBack } from 'assets/arrow-back.svg'
import { FilterContext } from 'contexts/FilterProvider'
import { toWei } from 'ethjs-unit'
import { useEthPrice } from 'hooks/useEthPrice'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import BottomBar from './components/BottomBar'
import CategoryFilter from './components/CategoryFilter'
import FilterList from './components/FilterList'
import FilterType from './components/FilterType'
import MinMaxSlider from './components/MinMaxSlider'
import PriceRangeSelect from './components/PriceRangeSelect'

const STATUS_TYPE_REGISTRY = {
  title: 'Status',
  content: [
    { value: 'new', label: 'New' },
    { value: 'premium', label: 'PREMIUM' },
    { value: 'previously_owned', label: 'PREVIOUSLY OWNED' }
  ]
}

const STATUS_TYPE_MARKETPLACE = {
  title: 'Status',
  content: [
    { label: 'Buy Now', value: 'buy_now' },
    { label: 'Has Offers', value: 'has_offers' }
    // { label: 'Grace Period', value: '' }
  ]
}

const NAME_TYPE = {
  title: 'Type',
  content: [
    { value: 'letters', label: 'Letters' },
    { value: 'numbers', label: 'Numbers' }
    // { value: 'emojis', label: 'Emojis' }
  ]
}

const NAME_RESULT = {
  title: 'Result',
  content: [
    { value: 'starts_with', label: 'Starts with' },
    { value: 'constains', label: 'Contains' }
  ]
}

const DATE = {
  title: 'Date',
  content: [
    { value: 'recently_listed', label: 'Recently Listed' },
    { value: 'oldest_listed', label: 'Oldest Listed' },
    { value: 'recently_registered', label: 'Recently Registered' },
    { value: 'oldest_registered', label: 'Oldest Registered' },
    { value: 'expiring_soonest', label: 'Expiring soonest' }
  ]
}

const MobileFilters = ({}) => {
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [filtersSlideIn, setFiltersSlideIn] = useState(false)
  const [page, setPage] = useState(0)
  const transitioning = useRef(false)

  // Price range
  const [selectedCurrency, setSelectedCurrency] = useState('ETH')
  const [minPriceVal, setMinPriceVal] = useState('')
  const [maxPriceVal, setMaxPriceVal] = useState('')

  const {
    opened,
    openedRegistry,
    toggleOpen,
    toggleOpenRegistry,
    filter,
    setFilter,
    activeCurrency,
    setActiveCurrency,
    darkMode
  } = useContext(FilterContext)

  // Categories
  const [innerSubCategories, setInnerSubcategories] = useState(
    filter.subCategories ?? []
  )

  const statusValues = (darkMode
    ? STATUS_TYPE_REGISTRY
    : STATUS_TYPE_MARKETPLACE
  ).content

  const statusItems = statusValues.map(el => ({
    ...el,
    selected: darkMode
      ? filter.status === el.value
      : filter.status?.includes(el.value)
  }))

  const nameTypeItems = NAME_TYPE.content.map(el => ({
    ...el,
    selected: filter?.type?.includes(el.value)
  }))

  const nameResultItems = NAME_RESULT.content.map(el => ({
    ...el,
    selected: filter.result === el.value
  }))

  const dateItems = DATE.content.map(el => ({
    ...el,
    selected: filter.date === el.value
  }))

  useEffect(() => {
    if (!transitioning.current) {
      if ((!darkMode && opened) || (darkMode && openedRegistry)) {
        setPage(0)
        setFiltersVisible(true)
        setFiltersSlideIn(true)
      } else {
        transitioning.current = true
        setFiltersSlideIn(false)
        setTimeout(() => {
          setFiltersVisible(false)
          setPage(-1) // Hacky way to close all expanded filters
          transitioning.current = false
        }, 200)
      }
    }
  }, [opened, openedRegistry])

  const handleStatusChange = status => {
    if (
      darkMode ? filter.status === status : filter?.status?.includes(status)
    ) {
      if (darkMode) {
        const newFilter = { ...filter }
        delete newFilter.status
        setFilter(newFilter)
      } else {
        const filteredStatusArr = filter.status.filter(t => t !== status)
        setFilter(filter => ({
          ...filter,
          status: filteredStatusArr
        }))
      }
    } else {
      if (darkMode) {
        setFilter(filter => ({
          ...filter,
          status
        }))
      } else {
        setFilter(filter => ({
          ...filter,
          status: [status]
        }))
      }
    }
  }

  const handleLengthChange = params => {
    setFilter(filter => ({
      ...filter,
      minLength: params[0],
      maxLength: params[1]
    }))
  }

  const handleChangeNameResult = result => {
    if (result === filter.result) {
      const newFilter = { ...filter }
      delete newFilter.result
      setFilter(newFilter)
    } else {
      setFilter(filter => ({
        ...filter,
        result
      }))
    }
  }

  const handleChangeNameType = type => {
    let newTypes
    if (filter?.type?.includes(type)) {
      newTypes = filter.type.filter(t => t !== type)
    } else {
      newTypes = filter.type ? [...filter.type, type] : [type]
    }

    setFilter(filter => ({
      ...filter,
      type: newTypes.length > 0 ? newTypes : undefined
    }))
  }

  const ethPrice = useEthPrice()

  const handlePriceFilterChange = () => {
    if (Number(minPriceVal) > Number(maxPriceVal) && Number(maxPriceVal) > 0) {
      alert("Minimum price mustn't be higher than the maximum price")
      return
    }

    const transformedFromPrice =
      selectedCurrency === 'USDC'
        ? Number((Number(minPriceVal) / Number(ethPrice)).toFixed(5)) || 0
        : Number(minPriceVal) || 0
    const transformedToPrice =
      selectedCurrency === 'USDC'
        ? Number((Number(maxPriceVal) / Number(ethPrice)).toFixed(5)) || 0
        : Number(maxPriceVal) || 0

    const fromInWei = toWei(transformedFromPrice, 'ether')
    const toInWei = toWei(transformedToPrice, 'ether')

    setFilter(filter => ({
      ...filter,
      minPrice: fromInWei > 0 ? Number(fromInWei) : null,
      maxPrice: toInWei > 0 ? Number(toInWei) : null
    }))
  }

  const handleChangeDate = option => {
    if (option === filter.date) {
      setFilter(filter => ({
        ...filter,
        date: undefined
      }))
    } else {
      setFilter(filter => ({
        ...filter,
        date: option,
        sort: 'default'
      }))
    }
  }

  const onApply = () => {
    toggleOpen()
    setActiveCurrency(selectedCurrency)
    handlePriceFilterChange()
    setFilter(filter => ({
      ...filter,
      subCategories: innerSubCategories
    }))
  }

  const selectedCounter = useMemo(() => {
    let counter = 0

    Object.values(filter).forEach((key, i) => {
      if (i > 0 && key) counter++
    })

    return counter
  }, [filter])

  const handleResetFilter = () => {
    setFilter({ limit: 20 })
  }

  return (
    <>
      {((!darkMode && opened) || (darkMode && openedRegistry)) && (
        <Transparent onClick={darkMode ? toggleOpenRegistry : toggleOpen} />
      )}
      <Container
        visible={filtersVisible}
        slideIn={filtersSlideIn}
        darkMode={darkMode}
      >
        <Pages page={page}>
          <Page>
            <TopRow>
              {darkMode ? (
                <ArrowBack onClick={toggleOpenRegistry} />
              ) : (
                <ArrowBackGray onClick={toggleOpen} />
              )}
              Filters
            </TopRow>
            <InnerContainer>
              <FilterType
                label="Categories"
                otherText="All"
                movement="right"
                onClick={() => setPage(1)}
                darkMode={darkMode}
              />
              <FilterType
                darkMode={darkMode}
                label="Status"
                movement="down"
                page={page}
              >
                <FilterList
                  darkMode={darkMode}
                  items={statusItems}
                  onSelect={handleStatusChange}
                  expansionCount={statusItems.length}
                />
              </FilterType>
              <FilterType
                darkMode={darkMode}
                label="Length"
                otherText={
                  (filter.minLength ?? '3') +
                  ' - ' +
                  (filter.maxLength ?? '10+')
                }
                movement="down"
                page={page}
              >
                <MinMaxSlider
                  onChange={handleLengthChange}
                  darkMode={darkMode}
                />
              </FilterType>
              {
                // Marketplace Price Select here only
              }
              {!darkMode && (
                <FilterType
                  darkMode={darkMode}
                  label="Price Range"
                  movement="down"
                  page={page}
                >
                  <PriceRangeSelect
                    darkMode={darkMode}
                    minVal={minPriceVal}
                    setMinVal={setMinPriceVal}
                    maxVal={maxPriceVal}
                    setMaxVal={setMaxPriceVal}
                    selectedCurrency={activeCurrency}
                    setSelectedCurrency={setActiveCurrency}
                  />
                </FilterType>
              )}

              <FilterType
                darkMode={darkMode}
                label="Type"
                movement="down"
                page={page}
              >
                <FilterList
                  darkMode={darkMode}
                  items={nameTypeItems}
                  onSelect={handleChangeNameType}
                  expansionCount={nameTypeItems.length}
                  useTick
                />
              </FilterType>

              {
                // Registry only
              }
              {darkMode && (
                <FilterType
                  darkMode={darkMode}
                  label="Result"
                  movement="down"
                  page={page}
                >
                  <FilterList
                    darkMode={darkMode}
                    items={nameResultItems}
                    onSelect={handleChangeNameResult}
                    expansionCount={nameResultItems.length}
                  />
                </FilterType>
              )}

              {
                // Registry Price Select here only
              }
              {darkMode && (
                <FilterType
                  darkMode={darkMode}
                  label="Price"
                  movement="down"
                  page={page}
                >
                  <PriceRangeSelect
                    darkMode={darkMode}
                    minVal={minPriceVal}
                    setMinVal={setMinPriceVal}
                    maxVal={maxPriceVal}
                    setMaxVal={setMaxPriceVal}
                    selectedCurrency={activeCurrency}
                    setSelectedCurrency={setActiveCurrency}
                  />
                </FilterType>
              )}

              {
                // Marketplace only
              }
              {!darkMode && (
                <FilterType
                  darkMode={darkMode}
                  label="Date"
                  movement="down"
                  page={page}
                >
                  <FilterList
                    darkMode={darkMode}
                    items={dateItems}
                    onSelect={handleChangeDate}
                    expansionCount={dateItems.length}
                  />
                </FilterType>
              )}
            </InnerContainer>
          </Page>
          <Page>
            <TopRow>
              {darkMode ? (
                <ArrowBack onClick={() => setPage(0)} />
              ) : (
                <ArrowBackGray onClick={() => setPage(0)} />
              )}
              Categories
            </TopRow>
            <InnerContainer>
              <CategoryFilter
                darkMode={darkMode}
                page={page}
                subCategories={filter.subCategories}
                innerSubCategories={innerSubCategories}
                setInnerSubcategories={setInnerSubcategories}
              />
            </InnerContainer>
          </Page>
        </Pages>

        <BottomBar
          onApply={onApply}
          darkMode={darkMode}
          filtersCounter={selectedCounter}
          resetFilters={handleResetFilter}
        />
      </Container>
    </>
  )
}

const Container = styled.div`
  background: ${({ darkMode }) => (darkMode ? '#1b1b1b' : '#F6F6F9')};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: ${({ darkMode }) => (darkMode ? 'white' : '#47596B')};
  display: flex;
  flex-direction: column;
  font-weight: 700;
  height: calc(100vh - 32px);
  overflow: hidden;
  position: fixed;
  top: ${({ slideIn }) => (slideIn ? '32px' : '100vh')};
  transition: all 0.2s ease-in-out;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: 100vw;
  z-index: 1000;
`

const InnerContainer = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
`

const TopRow = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  width: 100%;

  svg {
    left: 20px;
    stroke: white;
    position: absolute;
  }
`

const Transparent = styled.div`
  height: 32px;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
`

const Pages = styled.div`
  display: flex;
  flex: 1;
  transform: translateX(-${({ page }) => page * 100}vw);
  transition: all 0.2s ease-in-out;
`

const Page = styled.div`
  max-height: calc(100vh - 124px);
  min-width: 100%;
  overflow-y: scroll;
`

export default MobileFilters
