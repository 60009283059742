import { useQuery } from '@apollo/client'
import Label from 'components/Label'
import View from 'components/View'
import { useHistory } from 'react-router'
import { BigNumber } from 'ethers'
import React from 'react'
import { GET_LABEL_NAME_FROM_HASH } from 'service/graphql/kodex-api'

const OfferDomain = ({ domain_id }) => {
  const history = useHistory()

  const hexId = BigNumber.from(domain_id[0]).toHexString()

  const { data: domains } = useQuery(GET_LABEL_NAME_FROM_HASH, {
    variables: { hashes: [hexId] }
  })

  return (
    <View alignItems="center" background="white">
      <Label
        color="black"
        hoverColor="#888"
        cursor="pointer"
        onClick={() =>
          history.push(`/domain/${domains?.domains[0]?.labelName || ''}.eth`)
        }
      >{`${domains?.domains[0]?.labelName || ''}.eth`}</Label>
    </View>
  )
}

export default OfferDomain
