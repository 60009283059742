import React from 'react'
import Label from 'components/Label'
import View from 'components/View'
import { BigNumber } from 'ethers'
import useGetDomainOwner from 'hooks/useGetDomainOwner'
import useUserProfile from 'hooks/useUserProfile'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router'
import { GET_LABEL_NAME_FROM_HASH } from 'service/graphql/kodex-api'
import { OFFERS_DROPDOWN_ITEMS } from 'views/Profile'

const Offerer = ({
  data,
  showPfp = true,
  link = true,
  color = '#BCBCCC',
  isSentOffers
}) => {
  const history = useHistory()

  const offererAddress = data?.maker

  const tokenId = data?.tokenSetId.split(':')[2]

  const hexId = BigNumber.from(tokenId).toHexString()

  const { data: domains } = useQuery(GET_LABEL_NAME_FROM_HASH, {
    variables: { hashes: [hexId] }
  })

  const domainName = domains ? domains?.domains[0].labelName : ''

  const { ownerAddress } = useGetDomainOwner(domainName)

  const { profile: offererData } = useUserProfile(offererAddress)
  const { profile: ownerData } = useUserProfile(ownerAddress)

  const addressToUse = isSentOffers ? ownerAddress : offererAddress
  const username = isSentOffers
    ? ownerData?.userInfo?.username?.length > 0
      ? ownerData?.userInfo?.username
      : ownerAddress.slice(2, 7)
    : offererData?.userInfo?.username?.length > 0
    ? offererData?.userInfo?.username
    : offererAddress.slice(2, 7)

  return (
    <View
      direction="row"
      gap="0.75rem"
      alignItems="center"
      cursor="pointer"
      onClick={() => {
        if (!link) return
        history.push(`/profile/${addressToUse?.slice(2)}`)
      }}
    >
      {/* <Avatar
        src={ownerData?.userInfo?.picture || DeafultAvatarImg}
        borderRadius="5px"
        background="#fff0"
      /> */}
      <Label color="#BCBCCC">{username}</Label>
    </View>
  )
}

export default Offerer
