import React from 'react'
import View from '../View'
import Label from '../Label'
import styled from '@emotion/styled'
import { ReactComponent as ArrowBack } from 'assets/arrow-back.svg'

const HideButton = ({ onHide, darkMode, scrollbarOffset = 0 }) => {
  return (
    <View
      justify="space-between"
      width={darkMode && scrollbarOffset < 140 ? '100%' : 'fit'}
      alignItems="center"
    >
      {darkMode && scrollbarOffset < 140 && (
        <Label color={darkMode ? '#ffffff' : '#020202'} size={16} weight={600}>
          Hide filters
        </Label>
      )}
      <HideButtonComponent
        background={darkMode ? '#262628' : '#f3f3fa'}
        onClick={onHide}
      >
        <ArrowBack stroke={darkMode ? '#fff' : '#000'} />
      </HideButtonComponent>
    </View>
  )
}

const HideButtonComponent = styled.button`
  padding: 20px;
  border-radius: 10px;
  background-color: ${props => props.background};
  cursor: pointer;
  line-height: 1;
`

export default HideButton
