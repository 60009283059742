import { JETTY_URL } from 'service/siwe/constants'
import { siweAuthedFetch } from 'service/siwe/siweFetch'

export const getActivitiyData = async vars => {
  const { limit = 50, offset = 0, user, domain_name } = vars

  const req = siweAuthedFetch(
    `${JETTY_URL}/feed/activity/${user ? 'user' : 'domain'}?${
      user ? 'user=' + user : 'domain_name=' + domain_name
    }&limit=${limit}&offset=${offset}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )

  const res = await req.then(data => data).then(data => data.json())

  return res.events
}
