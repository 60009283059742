import styled from '@emotion/styled/macro'
import { ReactComponent as ArrowBottom } from 'assets/arrow-down.svg'
import KodexIcon from 'components/Icon/KodexIcon'
import Label from 'components/Label'
import View from 'components/View'
import { useEffect, useMemo, useRef, useState } from 'react'

export const DurationAccordion = ({
  content,
  onChange,
  selectedItem,
  width,
  whiteMode,
  isRegistered = true,
  itemPadding = '15px 10px 15px 20px',
  itemHeight = '50px',
  transparent = false,
  borderColor,
  fontWeight = 700
  // contentExpanded,
  // setContentExpanded
}) => {
  if (whiteMode === undefined) whiteMode = isRegistered

  const [contentExpanded, setContentExpanded] = useState(false)

  const contentContainerRef = useRef()
  const contentWrapperRef = useRef()

  const sortedContent = useMemo(() => {
    const contentCopy = [...content]
    return contentCopy.sort((a, _) => (a.value === selectedItem.value ? -1 : 1))
  }, [selectedItem])

  const onItemPressed = item => {
    if (contentExpanded) {
      onChange(item)
    }
    setContentExpanded(!contentExpanded)
  }

  useEffect(() => {
    if (contentExpanded) {
      contentContainerRef.current.style.maxHeight = `${
        contentWrapperRef.current.clientHeight
      }px`
    } else {
      contentContainerRef.current.style.maxHeight = itemHeight
    }
  }, [contentExpanded])

  return (
    <AccordionComponent
      height={itemHeight}
      width={width}
      isRegistered={isRegistered}
    >
      <ContentContainer
        borderColor={borderColor}
        ref={contentContainerRef}
        isRegistered={whiteMode}
        height={itemHeight}
        contentExpanded={contentExpanded}
        transparent={transparent}
      >
        <ContentWrapper
          ref={contentWrapperRef}
          isRegistered={whiteMode}
          transparent={transparent}
        >
          {sortedContent.map((item, idx) => (
            <ContentItem
              fontWeight={fontWeight}
              key={idx}
              onClick={() => onItemPressed(item)}
              label={item.label}
              selected={item.value === selectedItem.value}
              contentExpanded={contentExpanded}
              isRegistered={isRegistered}
              whiteMode={whiteMode}
              itemPadding={itemPadding}
              transparent={transparent}
            />
          ))}
        </ContentWrapper>
      </ContentContainer>
    </AccordionComponent>
  )
}

const ContentItem = ({
  label,
  selected,
  contentExpanded,
  onClick,
  whiteMode,
  fontWeight,
  isRegistered,
  itemPadding,
  transparent
}) => {
  return (
    <ContentItemComponent
      onClick={onClick}
      isRegistered={whiteMode}
      transparent={transparent}
    >
      <View
        alignItems="center"
        justify="space-between"
        width="100%"
        padding={itemPadding}
      >
        <Label weight={fontWeight} color={whiteMode ? '#020202' : '#fff'}>
          {label}
        </Label>
        <IconWrapper opacity={!contentExpanded && selected ? 1 : 0}>
          <KodexIcon
            icon={<ArrowBottom />}
            size={20}
            padding={0}
            stroke={whiteMode ? '#020202' : '#fff'}
          />
        </IconWrapper>
      </View>
    </ContentItemComponent>
  )
}

const AccordionComponent = styled.div`
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${props => (props.width ? props.width : '145px')};
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  line-height: 1;
  position: relative;
  min-height: ${props => props.height};
`

const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  max-height: ${props => props.height};
  border: 1px solid
    ${props =>
      props.transparent
        ? 'transparent'
        : props.isRegistered
        ? '#ebebf2'
        : '#4A4A4D'};
  border-radius: 10px;
  transition: all 0.3s ease;
  width: 100%;
  overflow: hidden;
  ${props =>
    props.contentExpanded &&
    `box-shadow: 3px 3px 8px ${props.isRegistered ? '#0003' : '#0007'}`}
`

const ContentWrapper = styled.div`
  width: 100%;
  background: ${props =>
    props.transparent ? 'transparent' : props.isRegistered ? '#fff' : '#000'};
`

const IconWrapper = styled.div`
  opacity: ${props => props.opacity};
  transition: all 0.3s ease;
`

const ContentItemComponent = styled.div`
  cursor: pointer;
  background-color: ${props =>
    props.transparent ? 'transparent' : props.isRegistered ? '#fff' : '#000'};
  color: ${props => (props.isRegistered ? '#020202' : '#fff')};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props =>
      props.transparent
        ? 'transparent'
        : props.isRegistered
        ? '#f3f3fa'
        : '#121212'};
  }
`
