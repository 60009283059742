import React from 'react'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import moment from 'moment'

const SummaryFourthColumn = ({ eventType, loading, time, endTime }) => {
  const getColumnTitle = () => {
    if (eventType === 'registration') {
      return 'Period'
    }
    if (eventType === 'listing') {
      return 'Listing duration'
    }
    return 'Expiration'
  }

  const getTimestampLabel = () => {
    const newTime = moment.duration(moment(endTime).diff(moment(time)))
    let formattedDuration = newTime.humanize()
    if (formattedDuration[0].toLowerCase() === 'a') {
      formattedDuration = '1' + formattedDuration.substring(1)
    }
    return formattedDuration
  }

  return (
    <View flex={1} direction="column" padding="0 8px 0 0">
      <Label size={13} opacity="0.5">
        {getColumnTitle()}
      </Label>
      <Space size={16} />
      <View alignItems="center" height="40px">
        {loading ? (
          <View
            width="100px"
            height="16px"
            background="#2C2C2C"
            borderRadius="6px"
          />
        ) : (
          <Label size={16}>{getTimestampLabel()}</Label>
        )}
      </View>
    </View>
  )
}

export default SummaryFourthColumn
