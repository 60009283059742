import { useQuery } from '@apollo/client'
import styled from '@emotion/styled/macro'
import { ReactComponent as ArrowBottom } from 'assets/arrow-down-white.svg'
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg'
import AccountAvatar from 'assets/icon-pfp-default.png'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import View from 'components/View'
import { AccountContext } from 'contexts/AccountProvider'
import { CartStoreContext } from 'contexts/CartStoreProvider'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GET_REGISTRATIONS_SUBGRAPH } from 'service/graphql/queries'
import KodexIcon from '../../components/Icon/KodexIcon'
import SettingDialog from './SettingDialog/index'

const AccountButton = ({ color, background, onAccountStatusChange }) => {
  const history = useHistory()
  const [showMenu, setShowMenu] = useState(false)
  const [openSetting, setOpenSetting] = useState(false)
  const [opacity, setOpacity] = useState(0)

  const cartCtx = useContext(CartStoreContext)

  const {
    handeDisconnectProvider,
    handleConnectProvider,
    userData,
    account
  } = useContext(AccountContext)

  const { data: { account: ensData } = {} } = useQuery(
    GET_REGISTRATIONS_SUBGRAPH,
    {
      variables: {
        id: account.toLowerCase(),
        orderBy: 'registrationDate'
      },
      skip: !account,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  )

  useEffect(() => {
    if (account) {
      onAccountStatusChange(true)
    } else {
      onAccountStatusChange(false)
    }
  }, [account])

  const handleConnect = useCallback(() => {
    handleConnectProvider()
  }, [])

  const handleDisConnect = useCallback(() => {
    setShowMenu(false)
    handeDisconnectProvider()
    cartCtx.removeAll()
  }, [setShowMenu])

  const handleOpenProfile = useCallback(() => {
    setShowMenu(false)
    history.push(`/profile/my-profile`)
  }, [setShowMenu, history])

  const handleOpenSetting = useCallback(() => {
    setShowMenu(false)
    setOpenSetting(true)
  }, [setShowMenu, setOpenSetting])

  const handleCloseSetting = useCallback(() => {
    setOpenSetting(false)
  }, [setOpenSetting])

  const animationDuration = 500

  const animateProfileMenu = useCallback(() => {
    if (showMenu) {
      setOpacity(0)
      setTimeout(() => {
        setShowMenu(false)
      }, animationDuration)
    } else {
      setShowMenu(true)
    }
  }, [showMenu])

  useEffect(() => {
    if (showMenu) {
      setOpacity(1)
    }
  }, [showMenu, setOpacity])

  if (!account) {
    return (
      <RoundedButton
        background={background}
        borderRadius="6px"
        border={background === 'transparent' ? `1px solid ${color}` : 'none'}
        width="166px"
        height="60px"
        onClick={handleConnect}
        hoverOpacity="0.6"
      >
        <Label color={color} size={14}>
          Connect Wallet
        </Label>
      </RoundedButton>
    )
  }

  return (
    <View>
      <RoundedButton
        background={color}
        borderRadius="6px"
        padding="2px"
        onClick={animateProfileMenu}
        hoverOpacity="0.6"
        transition="opacity 0.2s ease"
      >
        {/* <Label color={color} size={14}>
          {`${accounts[0].slice(0, 6)}...${accounts[0].slice(-4)}`}
        </Label> */}
        <View alignItems="center">
          <View
            width="56px"
            height="56px"
            borderRadius="6px"
            justify="center"
            alignItems="center"
            background={color}
          >
            <img
              src={userData?.picture || AccountAvatar}
              width={'100%'}
              height={'100%'}
              style={{ borderRadius: 4, objectFit: 'cover' }}
            />
          </View>
          <View padding="10px">
            {color === '#262628' ? (
              <KodexIcon icon={<ArrowBottom />} size={10} padding="5px" />
            ) : (
              <ArrowDown />
            )}
          </View>
        </View>
      </RoundedButton>
      {showMenu && (
        <>
          <Overlay onClick={() => animateProfileMenu()} />
          <Menu
            opacity={opacity}
            transitionDuration={animationDuration / 1000}
            backgroundColor={background === 'transparent' ? '#fff' : background}
            border={background === 'transparent' ? `1px solid #ebebf2` : 'none'}
          >
            <MenuItem
              onClick={handleOpenProfile}
              color={background === 'transparent' ? `#020202` : '#fff'}
              hoverBackground={
                background === 'transparent' ? `#f3f3fa` : '#363638'
              }
            >
              Profile
            </MenuItem>
            <MenuItem
              onClick={handleOpenSetting}
              color={background === 'transparent' ? `#020202` : '#fff'}
              hoverBackground={
                background === 'transparent' ? `#f3f3fa` : '#363638'
              }
            >
              Settings
            </MenuItem>
            <MenuItem
              onClick={handleDisConnect}
              color={background === 'transparent' ? `#020202` : '#fff'}
              hoverBackground={
                background === 'transparent' ? `#f3f3fa` : '#363638'
              }
            >
              Disconnect
            </MenuItem>
          </Menu>
        </>
      )}
      {openSetting && (
        <SettingDialog open={openSetting} onClose={handleCloseSetting} />
      )}
    </View>
  )
}

const Overlay = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: transparent;
`

const Menu = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  width: 200px;
  border-radius: 10px;
  border: ${props => (props.border ? props.border : '1px solid #ebebf2')};
  display: flex;
  flex-direction: column;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
  overflow: hidden;
  z-index: 101;
  opacity: ${props => (props.opacity ? props.opacity : 0)};
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
`

const MenuItem = styled.div`
  width: 100%;
  padding: 22px 20px;
  cursor: pointer;
  color: ${props => (props.color ? props.color : '#020202')};
  background-color: transparent;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    background-color: ${props =>
      props.hoverBackground ? props.hoverBackground : '#f3f3fa'};
  }
`

export default AccountButton
