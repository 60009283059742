import React from 'react'
import View from '../../components/View'
import styled from '@emotion/styled'
import Space from '../../components/Space'
import Label from '../../components/Label'

const Header = ({ image, date, title }) => {
  return (
    <View height="auto">
      <HeaderImage src={image} alt={title} />
      <Space size={40} />
      <View direction="column">
        <Label size={16} color="#C6C6CC" weight={300}>
          {date}
        </Label>
        <Space size={20} />
        <Label size={40} color="#212121">
          {title}
        </Label>
      </View>
    </View>
  )
}

const HeaderImage = styled.img`
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  max-width: 180px;
  max-height: 180px;
`

export default Header
