import React, { useRef, useState, useEffect } from 'react'
import _ from 'lodash'
import View from 'components/View'
import { useVirtualizedListLoadMore } from 'components/VirtualizedList/hooks/useVirtualizedListLoadMore'
import FadeInItemsLeft from './FadeInItemsLeft'

const VirtualizedList = ({
  width,
  height,
  autoHeight,
  data,
  loading,
  rowsRenderer,
  onLoadMore,
  loadingRenderer,
  animationOptions
}) => {
  const { bottomPaddingRef } = useVirtualizedListLoadMore({
    loadMore: onLoadMore
  })

  const firstItem = useRef(null)
  const [staticData, setStaticData] = useState([])
  const [animationData, setAnimationData] = useState([])

  useEffect(() => {
    const newFirstIndex =
      firstItem.current &&
      data.findIndex(el => _.isEqual(el, firstItem.current))
    if (
      animationOptions &&
      typeof newFirstIndex === 'number' &&
      newFirstIndex > 0 &&
      newFirstIndex <= animationOptions.limit
    ) {
      setAnimationData(data.slice(0, newFirstIndex))
      setStaticData(data.slice(newFirstIndex, data.length))

      setTimeout(() => {
        setAnimationData([])
        setStaticData(data)
      }, animationOptions.duration)
    } else {
      setStaticData(data)
    }
    firstItem.current = data[0]
  }, [data])

  return (
    <View direction="column" width={width}>
      <FadeInItemsLeft
        data={animationData}
        renderer={rowsRenderer}
        duration={animationOptions?.duration || 150}
      />
      {staticData.map((row, index) => rowsRenderer(row, index))}
      {loading && loadingRenderer && loadingRenderer()}

      {data.length > 0 && <div ref={bottomPaddingRef} style={{ height: 1 }} />}
    </View>
  )
}

export default VirtualizedList
