import moment from 'moment'

export const POSTS = [
  {
    date: formatPostDate(new Date(2023, 2, 8)),
    previewImage:
      'https://res.cloudinary.com/kodex-solutions/image/upload/v1679178654/blog/ens-monthly-roundup-january-2023/Monthly_Roundup_-_Jan_qvl3vo.png',
    title: 'ENS Monthly roundup: January 2023',
    url: 'ens-monthly-roundup-january-2023',
    content: []
  },
  {
    date: formatPostDate(new Date(2023, 3, 10)),
    previewImage:
      'https://res.cloudinary.com/kodex-solutions/image/upload/v1678520635/blog/ens-monthly-roundup-february-2023/Monthly_Roundup_ddutpk.png',
    title: 'ENS Monthly roundup: February 2023',
    url: 'ens-monthly-roundup-february-2023',
    content: []
  },
  {
    date: formatPostDate(new Date(2023, 4, 4)),
    previewImage:
      'https://res.cloudinary.com/kodex-solutions/image/upload/v1680637837/blog/ens-monthly-roundup-march-2023/Monthly_Roundup_-_Mar_uysyov.png',
    title: 'ENS Monthly roundup: March 2023',
    url: 'ens-monthly-roundup-march-2023',
    content: []
  }
]

function formatPostDate(date) {
  return moment(date).format('DD MMMM YYYY')
}
