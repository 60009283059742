import React from 'react'
import { useDebouncedItemCount } from 'components/VirtualizedList/hooks/useDebouncedItemCount'
import { useVirtualizedListLoadMore } from 'components/VirtualizedList/hooks/useVirtualizedListLoadMore'

export const VirtualizedList = ({
  Component,
  genericProps,
  individualComponentPropsMap,
  itemCount,
  loadMore,
  viewType,
  from = ''
}) => {
  // const itemsToRender = columnCount * rowsToRender

  const { debouncedItemCount } = useDebouncedItemCount({ itemCount })
  const { bottomPaddingRef } = useVirtualizedListLoadMore({
    loadMore
  })

  // const { showRange, virtualizedListRef } = useVirtualizedListScroll({
  //   columnCount,
  //   height,
  //   rowsToRender
  // })

  // const topPaddingHeight = showRange * height

  // const itemsToMap = useMemo(() => {
  //   return [...Array(debouncedItemCount)]
  //     .map((_x, index) => index)
  //     .slice(
  //       showRange * itemsToRender,
  //       showRange * itemsToRender + itemsToRender * 2
  //     )
  // }, [debouncedItemCount, showRange, itemsToRender])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'fit'
      }}
    >
      {/* <div style={{ paddingTop: topPaddingHeight }} /> */}
      <div
        style={
          viewType === 'LIST'
            ? { display: 'block', paddingBottom: '40px' }
            : {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: from === 'marketplace' ? '10px 1%' : '15px 1%',
                padding: '10px 0 40px 0'
              }
        }
      >
        {Array(debouncedItemCount)
          .fill(1, 0, debouncedItemCount)
          .map((_, index) => (
            <Component
              index={index}
              key={index}
              {...genericProps}
              {...individualComponentPropsMap[index]}
            />
          ))}
        {
          <div
            ref={bottomPaddingRef}
            style={{
              height: '1px',
              width: '100%',
              bottom:
                viewType === 'LIST'
                  ? '600px'
                  : window.innerWidth < 740
                  ? '300px'
                  : '650px',
              left: 0,
              position: viewType === 'LIST' ? 'relative' : 'absolute'
            }}
          />
        }
      </div>
    </div>
  )
}
