import getEtherPrice from 'api/price'
import getENS from 'apollo/mutations/ens'
import { useEffect, useState } from 'react'

async function getContract() {
  let contractAddress
  try {
    const ens = getENS()
    contractAddress = await ens.getAddress('eth-usd.data.eth')
  } catch {
    //return mainnet if it does not exist
    contractAddress = '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419'
  }
  return contractAddress
}

export function useEthPrice() {
  const [price, setPrice] = useState('0.00')

  const handlePrice = async () => {
    try {
      const ethPrice = await getEtherPrice()
      setPrice(ethPrice)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handlePrice()
  }, [])

  return price
}
