import moment from 'moment'

const Time = ({
  style,
  timeString,
  isRegistered = true,
  fromProfile = false,
  fromFeed = false
}) => {
  const time = moment(timeString)
  return (
    <time
      style={{
        ...style,
        color: isRegistered
          ? fromProfile
            ? '#BCBCCC'
            : fromFeed
            ? '#fff'
            : '#000'
          : '#fff'
      }}
      dateTime={timeString}
    >
      {time.fromNow()}
    </time>
  )
}

export default Time
