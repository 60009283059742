import React, { useCallback, useState, useEffect, useMemo } from 'react'
import BottomModal from 'components/BottomModal'
import Container from 'components/Layout/Container'
import View from 'components/View'
import PriceRangeSelector from './PriceRange'
import CategoriesSelector from './Categories'
import EventsSelector from './Events'
import Length from './Length'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import CategoryFilterModal from 'components/CategoryFilterModal'
import { initialFilter } from '../index'
import { useHistory } from 'react-router'

const FilterDialog = ({
  open,
  filterOptions,
  setFilterCount,
  feedCount,
  onClose,
  onChangeFilters
}) => {
  const [subcategories, setSubcategories] = useState(
    filterOptions.subcategories ?? []
  )
  const [amountChars, setAmountChars] = useState(filterOptions.charLengths)
  const [priceFrom, setPriceFrom] = useState(filterOptions.priceRange[0] ?? '')
  const [priceTo, setPriceTo] = useState(filterOptions.priceRange[1] ?? '')
  const [currency, setCurrency] = useState(filterOptions.asset ?? 'ETH')
  const [eventType, setEventType] = useState(filterOptions.status)
  const [results, setResults] = useState(filterOptions.results)

  const [isCategoriesOpened, setIsCategoriesOpened] = useState(false)

  const history = useHistory()

  const handleExpandCategoryFilter = useCallback(() => {
    setIsCategoriesOpened(true)
  }, [])

  const closeCategoriesModal = useCallback(() => {
    setIsCategoriesOpened(false)
  }, [])

  useEffect(() => {
    if (window.innerWidth <= 740) {
      history.push('/marketplace')
      return
    }
  }, [])

  useEffect(() => {
    if (open) {
      setIsCategoriesOpened(false)
    }
    return () => {}
  }, [open])

  const handleChangeCharLengths = useCallback(
    value => {
      setAmountChars(value)
      onChangeFilters({ ...filterOptions, charLengths: value })
    },
    [filterOptions]
  )

  const handleApplyEventType = useCallback(
    value => {
      onChangeFilters({
        ...filterOptions,
        status: value
      })
      setEventType(value)
    },
    [filterOptions]
  )

  const handleApplyCurrency = useCallback(
    value => {
      setCurrency(value)
      onChangeFilters({
        ...filterOptions,
        asset: value
      })
    },
    [filterOptions]
  )

  const handleApplyPriceRange = useCallback(value => {}, [filterOptions])

  const onCategoriesApply = useCallback(subcategories => {
    setSubcategories(subcategories)
    onChangeFilters({ ...filterOptions, subcategories })
  }, [])

  const filtersCount = useMemo(() => {
    let filtersCounter = 0

    if (subcategories.length !== 0) {
      filtersCounter++
    }

    if (amountChars[0] !== 3 || amountChars[1] !== 10) {
      filtersCounter++
    }

    if (priceFrom !== '' || priceTo !== '') {
      filtersCounter++
    }

    return filtersCounter
  }, [
    priceFrom,
    priceTo,
    amountChars,
    results,
    filterOptions,
    currency,
    subcategories
  ])

  const handleReset = () => {
    setSubcategories([])
    setAmountChars(initialFilter.charLengths)
    setPriceFrom('')
    setPriceTo('')
    setCurrency(initialFilter.asset)
    setEventType(initialFilter.status)
    setResults(initialFilter.results)
  }

  const handleApply = () => {
    onChangeFilters({
      priceRange: [
        priceFrom ? priceFrom : undefined,
        priceTo ? priceTo : undefined
      ],
      charLengths: amountChars,
      asset: currency,
      status: eventType,
      subcategories,
      results
    })
    onClose(filtersCount)
  }

  const handleClose = useCallback(() => {
    setIsCategoriesOpened(state => {
      if (!state) {
        onClose(filtersCount)
        return state
      }
      return !state
    })
  }, [onClose, filtersCount])

  useEffect(() => {
    setFilterCount(filtersCount)
  }, [])

  return (
    <>
      <BottomModal
        open={open}
        onClose={handleClose}
        closeButtonLabel={isCategoriesOpened ? 'Back to filters' : 'Close'}
        contentBackground="#fff"
      >
        <Container maxWidth="940px">
          <View direction="column" height="calc(100vh - 140px)" overflow="auto">
            <View direction="column" width="100%">
              <View width="100%" height="fit">
                <CategoriesSelector
                  onCategoryExpand={handleExpandCategoryFilter}
                  selectedSubcategories={subcategories}
                />
                <Length
                  values={amountChars}
                  onChange={handleChangeCharLengths}
                />
              </View>
              <View width="100%">
                <PriceRangeSelector
                  currency={currency}
                  onCurrencyChange={handleApplyCurrency}
                  from={priceFrom}
                  onFromChanged={setPriceFrom}
                  to={priceTo}
                  onToChanged={setPriceTo}
                  onSave={handleApplyPriceRange}
                />
                <EventsSelector
                  value={eventType}
                  onChange={handleApplyEventType}
                />
              </View>
              {/*<ResultsSelector values={results} onChange={setResults} />*/}
            </View>

            <View
              margin="auto 0 40px 0"
              width="100%"
              padding="40px 35px 0 15px"
              alignItems="center"
            >
              <View width="50%">
                <RoundedButton
                  background="#f3f3f3"
                  color="#020202"
                  className="mr-1"
                  onClick={handleReset}
                  padding="22px 30px"
                  hoverOpacity={0.7}
                >
                  Reset
                </RoundedButton>
                <View margin="0 0 0 30px">
                  <RoundedButton
                    background="#0698A8"
                    color="#fff"
                    onClick={handleApply}
                    padding="22px 30px"
                    hoverOpacity={0.7}
                  >
                    Apply
                  </RoundedButton>
                </View>
              </View>
              <View width="50%" justify="space-between" padding="0 0 0 20px">
                <Label color="#bcbccc">
                  Selected: <Label color="#020202">{filtersCount}</Label>
                </Label>
                <Label color="#020202">{feedCount} Events</Label>
              </View>
            </View>
          </View>
        </Container>
      </BottomModal>
      <CategoryFilterModal
        open={isCategoriesOpened}
        onClose={closeCategoriesModal}
        subCategories={subcategories}
        onApply={onCategoriesApply}
        isInner
      />
    </>
  )
}

export default FilterDialog
