import React from 'react'
import styled from '@emotion/styled/macro'

const SettingTextArea = styled.textarea`
  position: ${props => props.position};
  top: ${props => props.top};
  left: ${props => props.left};
  border: 1px solid #e1e1e8;
  border-radius: 10px;
  padding: 22px 30px 22px 30px;
  caret-color: #a24f9d;
  font-size: 16px;
  font-family: 'Satoshi', sans-serif;
  height: ${props => props.height};
  width: ${props => props.width};
  vertical-align: top;
  resize: none;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }

  ::placeholder {
    color: #bcbccc;
  }

  :-ms-input-placeholder {
    color: #bcbccc;
  }

  ::-ms-input-placeholder {
    color: #bcbccc;
  }
`

export default SettingTextArea
