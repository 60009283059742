import styled from '@emotion/styled/macro'
import View from 'components/View'
import React from 'react'
import { DURATION_ITEMS } from 'constants/feed-chart-duration'

const DurationSelector = ({ currentRage, onSelect, chartExpanded }) => {
  return (
    <View alignItems="center">
      {DURATION_ITEMS.slice(0, 4).map(duration => (
        <Cell
          key={duration.value}
          selected={currentRage.value === duration.value}
          onClick={() => {
            if (chartExpanded) {
              onSelect(DURATION_ITEMS[DURATION_ITEMS.indexOf(duration) + 4])
              return
            }
            onSelect(duration)
          }}
        >
          {duration.label}
        </Cell>
      ))}
    </View>
  )
}

const Cell = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: ${props => (props.selected ? '#2BA8B5' : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  color: ${props => (props.selected ? '#fff' : 'rgba(255, 255, 255, 0.5)')};
  font-size: 13px;
  font-family: 'Satoshi', sans-serif;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`

export default DurationSelector
