import styled from '@emotion/styled/macro'
import Card from 'components/Card'
import Label from 'components/Label'
import View from 'components/View'
import { useLottie } from 'lottie-react'
import { useMediaMax } from 'mediaQuery'
import { useCallback } from 'react'
import { useHistory } from 'react-router'

const TrendingCategoryCard = ({ subject, icon, hoverBackground, category }) => {
  const smallBP = useMediaMax('medium')
  const history = useHistory()

  const options = {
    animationData: icon,
    loop: false,
    autoplay: false
  }

  const Lottie = useLottie(options)
  const onMouseEnter = useCallback(() => {
    Lottie.setDirection(1)
    Lottie.setSpeed(1.5)
    Lottie.play()
  }, [Lottie])
  const onMouseLeave = useCallback(() => {
    Lottie.setDirection(-1)
    Lottie.setSpeed(2)
    Lottie.play()
  }, [Lottie])

  const handleCardCLick = () => {
    history.push(`/marketplace?category=${category.toLowerCase()}`)
  }

  return (
    <Card
      width="100%"
      height="360px"
      margin={smallBP ? '8px 0' : '10px'}
      hasShadowOnHover
      onClick={handleCardCLick}
      cursor="pointer"
    >
      <CardContent
        hoverBackground={hoverBackground}
        onMouseEnter={() => onMouseEnter()}
        onMouseLeave={() => onMouseLeave()}
      >
        <View
          width="100%"
          height={295}
          flex={1}
          alignItems="center"
          justify="center"
        >
          {Lottie.View}
        </View>
        <View width="100%" padding="0px 40px">
          <Label size={18} color="#CAD0D2">
            {subject}
          </Label>
        </View>
      </CardContent>
    </Card>
  )
}

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  background: ${props => (props.background ? props.background : '#fff')};
  border: 1px solid #ebebf2;
  border-radius: 6px;
  transition: background 0.3s ease-in;
  transition-delay: 0.1s;

  :hover {
    transition: background 0.2s ease-out;
    transition-delay: 0;
    background: ${props =>
      props.hoverBackground ? props.hoverBackground : '#AF61AB'};

    svg {
      transition: stroke 0.5s ease-out;
      stroke: #fff;
    }
  }
`

export default TrendingCategoryCard
