import { useState } from 'react'

const defaultFilters = {
  amountChars: [3, 10],
  selectedSort: 'asc',
  saleType: 'sale',
  date: 'recently_listed',
  order: 'alphabetical'
}

export default (theme = 'light', layout = 'CARD', filters = defaultFilters) => {
  const [contextData, setContextData] = useState({
    theme,
    layout,
    filters
  })

  return {
    contextData,
    setLayout: value =>
      setContextData({
        ...contextData,
        layout: value
      }),
    setFilters: value =>
      setContextData({
        ...contextData,
        filters: {
          ...contextData.filters,
          ...value
        }
      }),
    reset: () =>
      setContextData({
        theme: 'light',
        layout: 'CARD',
        filters: defaultFilters
      })
  }
}
