import FractalBg from 'assets/fractal-completed-modal.png'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { WETH_ABI } from 'constants/WETH'
import { AccountContext } from 'contexts/AccountProvider'
import { CartStoreContext } from 'contexts/CartStoreProvider'
import { BigNumber, ethers } from 'ethers'
import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { addOffer } from 'service/rest/addOffer'
import { offer } from 'utils/seaport'
import {
  EXCHANGE_CONTRACT_ADDRESS,
  WETH_TOKEN_ADDRESS,
  getENSContract
} from 'utils/utils'
import { DurationAccordion } from 'views/TokenProfile/DurationAccordion'
import BulkOfferDomainRow from './components/BulkOfferDomainRow'
import * as Sentry from '@sentry/browser'
import { actions } from 'utils/reservoir'
import { getSigner } from '@ensdomains/ui'
import LoadingSpinner from 'components/LoadingSpinner'

const DURATION_OPTIONS = [
  { label: '12 Hours', value: 0.5 },
  { label: '3 Days', value: 3 },
  { label: '7 Days', value: 7 },
  { label: '1 Month', value: 30 }
]

const BulkOfferDialog = ({ domains, onClose, backLabel, resetState }) => {
  const { account } = useContext(AccountContext)

  const [allowance, setAllowance] = useState(0)
  const [offerAmounts, setOfferAmounts] = useState(
    new Array(domains.length).fill(0)
  )
  const [totalOfferAmount, setTotalOfferAmount] = useState(0)
  const [offerDuration, setOfferDuration] = useState()
  const [isApproving, setApproving] = useState(false)
  const [isOffering, setOffering] = useState(false)
  const [offerCreated, setOfferCreated] = useState(false)
  const [duration, setDuration] = useState(DURATION_OPTIONS[0])
  const [WETHBalance, setWETHBalance] = useState(0)
  const [successfulOffers, setSucessfullOffers] = useState([])
  const [durationAccordionExpanded, setDurationAccordionExpanded] = useState(
    false
  )

  const cartCtx = useContext(CartStoreContext)

  const WETH = new ethers.Contract(
    WETH_TOKEN_ADDRESS,
    WETH_ABI,
    new ethers.providers.StaticJsonRpcProvider(
      process.env.REACT_APP_LLAMA_NODES_URL,
      'homestead'
    )
  )

  useEffect(() => {
    const totalAmount = offerAmounts.reduce(
      (acc, cur) => Number(acc) + Number(cur)
    )
    setTotalOfferAmount(totalAmount)
  }, [offerAmounts])

  useEffect(() => {
    const fetchWETHBalance = async () => {
      const wethBalance = await WETH.balanceOf(account)

      const bigNumETHConversion = BigNumber.from(10).pow(18)

      const bigNumWETHBalance = BigNumber.from(wethBalance).mul(
        BigNumber.from(10).pow(6)
      )
      const WETHBalanceConverted =
        bigNumWETHBalance.div(bigNumETHConversion).toNumber() / 10 ** 6

      setWETHBalance(WETHBalanceConverted)
    }
    fetchWETHBalance()
  }, [WETH])

  const fetchAllowance = useCallback(async () => {
    if (account) {
      try {
        const ensContract = await getENSContract()
        const allowance = await ensContract.isApprovedForAll(
          account,
          EXCHANGE_CONTRACT_ADDRESS
        )
        setAllowance(allowance)
      } catch (err) {
        console.log('ens fetch error for isApprovedForAll: ', err)
      }
    }
  }, [account])

  useEffect(() => {
    fetchAllowance()
  }, [fetchAllowance])

  const handleApproveAllowance = async () => {
    if (isApproving) return

    try {
      setApproving(true)
      const ensContract = await getENSContract()
      const tx = await ensContract.setApprovalForAll(
        EXCHANGE_CONTRACT_ADDRESS,
        true
      )
      await tx.wait()
      await fetchAllowance()
      setApproving(false)
    } catch (err) {
      setApproving(false)
      console.log('Approve allowace error: ', err)
    }
  }

  const handleReset = () => {
    setOfferAmounts(new Array(domains.length).fill(0))
    setOfferDuration(DURATION_OPTIONS[0])
  }

  const handleAddOffer = async data => {
    const response = await addOffer(data)
    return response
  }

  const handleMakeOffer = async () => {
    if (offerAmounts.some(amount => Number(amount) === 0) || !duration) {
      return alert('set all offer amounts and duration first.')
    }

    const durationTransformed = moment()
      .add(
        duration.value < 1 ? duration.value * 24 : duration.value,
        duration.value < 1 ? 'hours' : 'days'
      )
      .toISOString()

    const durationInSeconds = Math.floor(
      new Date(durationTransformed).getTime() / 1000
    )

    const weiListedPrices = offerAmounts.map(amount =>
      ethers.utils.parseEther(amount)
    )

    try {
      setOffering(true)

      const allBids = domains.map(async (domain, i) => {
        const signer = await getSigner()

        const tokenId = ethers.BigNumber.from(
          ethers.utils.keccak256(ethers.utils.toUtf8Bytes(domain.name))
        ).toHexString()

        return {
          signer,
          bids: [
            {
              weiPrice: weiListedPrices[i],
              token: tokenId,
              expirationTime: durationInSeconds,
              orderbook: 'reservoir',
              orderKind: 'seaport'
            }
          ]
        }

        try {
          const order = await createdOffer.executeAllActions()

          const setOfferOnJetty = await handleAddOffer(order)

          if (i === domains.length - 1) {
            cartCtx.removeMultipleDomains(successfulOffers)
            setOfferCreated(true)
          }

          const successfullOffersCopy = successfulOffers
          successfullOffersCopy.push(domain.name)
          setSucessfullOffers(successfullOffersCopy)

          return setOfferOnJetty
        } catch (e) {
          if (i === domains.length - 1 && successfulOffers.length === 0)
            onClose()

          if (i === domains.length - 1 && successfulOffers.length > 0) {
            cartCtx.removeMultipleDomains(successfulOffers)
            setOfferCreated(true)
          }

          Sentry.captureException(e)
          throw new Error(e)
        }
      })

      const executedOffer = actions.placeBid({
        signer,
        bids: allBids
      })

      executedOffer.then(() => {
        const offeredDomains = domains.map(domain => domain.name)
        cartCtx.removeMultipleDomains(offeredDomains, 'PURCHASE')
        setOfferCreated(true)

        setSucessfullOffers(offeredDomains)
      })
    } catch (err) {
      onClose()
      Sentry.captureException(err)
      setOffering(false)
    }
  }

  if (offerCreated) {
    return (
      <View
        width="33vw"
        minWidth="720px"
        height="100%"
        margin="0 0 1rem 0"
        direction="column"
        borderRadius="10px"
        background="#0698A8"
        justify="space-between"
        alignItems="center"
        padding="40px"
      >
        <View
          direction="column"
          alignItems="center"
          gap="1rem"
          margin="1rem 0 0 0"
        >
          <Label size={40}>BULK OFFER SENT</Label>
          <Label size={40} color="#fff8">
            FOR
          </Label>
          <Label size={40}>
            {successfulOffers.map(
              (name, i) =>
                name + (i + 1 === successfulOffers.length ? '' : ', ')
            )}
          </Label>
        </View>
        <img src={FractalBg} />
        <View width="350px" direction="column">
          <RoundedButton
            width="100%"
            height="60px"
            border="1px solid #fff"
            onClick={() => {
              onClose()
            }}
          >
            <Label size={16}>View In Offers</Label>
          </RoundedButton>
          <Space size={10} />
          <RoundedButton
            width="100%"
            height="60px"
            background="#fff"
            border="1px solid #fff"
            onClick={() => onClose()}
          >
            <Label size={16} color="#020202">
              back to {backLabel || ''}
            </Label>
          </RoundedButton>
        </View>
      </View>
    )
  }

  return (
    <View
      width="33vw"
      minWidth="720px"
      height="100%"
      direction="column"
      margin="0 0 1rem 0"
    >
      <View
        flex={1}
        width="100%"
        direction="column"
        style={{ overflow: 'hidden' }}
        borderRadius="10px"
      >
        <View
          width="100%"
          background="#fff"
          padding=" 5rem 0 0 2.5rem"
          direction="column"
          gap="0.5rem"
        >
          <Label size={30} color="#000">
            Bulk offer
          </Label>
          <Label size={30} color="#BCBCCC">
            {domains.length} domains
          </Label>
        </View>
        <View
          flex={1}
          direction="column"
          background="#fff"
          padding=" 5rem 2.5rem 3rem 2.5rem"
          width="100%"
          gap="2rem"
        >
          <View
            direction="column"
            width="100%"
            gap="2rem"
            height="calc(100vh - 365px)"
            padding={`0 0 ${durationAccordionExpanded ? '15' : '2'}rem 0`}
            overflow="scroll"
            scrollBar={`&::-webkit-scrollbar {
              display: none; /* for Chrome, Safari, and Opera */
            }
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */`}
          >
            <View direction="column" width="100%" gap="0.75rem">
              <View width="100%" direction="row">
                <View width="25%">
                  <Label size={14} color="#BCBCCC">
                    Domain
                  </Label>
                </View>
                <View width="25%">
                  <Label size={14} color="#BCBCCC">
                    Price
                  </Label>
                </View>
                <View width="25%">
                  <Label size={14} color="#BCBCCC">
                    Highest offer
                  </Label>
                </View>
                <View
                  width="25%"
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Label size={14} color="#BCBCCC">
                    Your offer
                  </Label>
                  <Label
                    size={14}
                    cursor="pointer"
                    color="#000"
                    hoverColor="#999"
                    onClick={() => {
                      const firstSetPrice = offerAmounts.filter(
                        amount => Number(amount) > 0
                      )
                      setOfferAmounts(
                        new Array(domains.length).fill(firstSetPrice[0])
                      )
                    }}
                  >
                    For each
                  </Label>
                </View>
              </View>
              <Space size={10} />
              {domains?.map((domain, i) => (
                <BulkOfferDomainRow
                  key={domain.name}
                  domain={domain}
                  index={i}
                  setOfferAmount={setOfferAmounts}
                  offerAmounts={offerAmounts}
                />
              ))}
            </View>
            <hr
              style={{ width: '100%', background: '#E1E1E8', color: '#E1E1E8' }}
            />
            <View justify="space-between" width="100%" alignItems="center">
              <View flex={1} direction="column">
                <Label size={13} color="#BCBCCC">
                  Duration
                </Label>
                <Space size={30} />
                <DurationAccordion
                  selectedItem={duration}
                  onChange={setDuration}
                  content={DURATION_OPTIONS}
                  width="100%"
                  contentExpanded={durationAccordionExpanded}
                  setContentExpanded={setDurationAccordionExpanded}
                />
              </View>
              <Space size={40} />
              <View flex={1} direction="column">
                <Label size={13} color="#BCBCCC">
                  Ends
                </Label>
                <Space size={36} />
                <Label size={30} color="#BCBCCC">
                  {moment()
                    .add(
                      duration.value < 1 ? duration.value * 24 : duration.value,
                      duration.value < 1 ? 'hours' : 'days'
                    )
                    .format('DD MMM, hh:mm a')}
                </Label>
              </View>
            </View>
          </View>
          <View
            background="#ffffff"
            position="absolute"
            width="100%"
            padding="10px 40px 40px 40px"
            bottom="0px"
            left="0px"
          >
            <View flex={1}>
              <RoundedButton
                width="100%"
                height="60px"
                border="1px solid #E1E1E8"
                disabled={!offerAmounts && !offerDuration}
                onClick={handleReset}
              >
                <Label
                  size={16}
                  color={
                    !offerAmounts && !offerDuration ? '#ffffff' : '#020202'
                  }
                >
                  Reset
                </Label>
              </RoundedButton>
            </View>
            <Space size={10} />
            <View flex={1}>
              {!allowance ? (
                <RoundedButton
                  width="100%"
                  height="60px"
                  background="#0698A8"
                  disabled={isApproving}
                  border="1px solid #EBEBF2"
                  onClick={handleApproveAllowance}
                  hoverBackground="#26a8c8"
                >
                  <Label color="#fff" size={16} letterSpacing="1px">
                    {isApproving ? 'Pending' : 'Approve WETH'}
                  </Label>
                  {isApproving && (
                    <LoadingSpinner margin="0 0 0 20px" color="white" />
                  )}
                </RoundedButton>
              ) : (
                <RoundedButton
                  width="100%"
                  height="60px"
                  border="1px solid #E1E1E8"
                  disabled={
                    isApproving ||
                    isOffering ||
                    !offerAmounts ||
                    !duration ||
                    totalOfferAmount > Number(WETHBalance) ||
                    Number(offerAmounts) === 0
                  }
                  background="#0698A8"
                  hoverBackground="#16a8b8"
                  onClick={handleMakeOffer}
                >
                  <Label
                    size={16}
                    color={
                      !offerAmounts ||
                      Number(offerAmounts) === 0 ||
                      !duration ||
                      totalOfferAmount > Number(WETHBalance)
                        ? '#ffffff'
                        : isOffering
                        ? '#000'
                        : '#ffffff'
                    }
                  >
                    {totalOfferAmount > WETHBalance
                      ? 'Insufficient WETH'
                      : isOffering
                      ? 'Pending'
                      : 'Make offer'}
                  </Label>
                  {isOffering && (
                    <LoadingSpinner margin="0 0 0 20px" color="white" />
                  )}
                </RoundedButton>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default BulkOfferDialog
