const SECONDS_IN_DAY = 86400

export const calculateIfPremium = expiryDate => {
  const startOfPremium = expiryDate / SECONDS_IN_DAY + 90
  const endOfPremium = expiryDate / SECONDS_IN_DAY + 111
  const now = new Date().getTime() / 1000 / SECONDS_IN_DAY

  const domainStarted = startOfPremium <= now
  const domainFinished = endOfPremium < now

  if (domainStarted && !domainFinished) {
    return true
  }
  return false
}
