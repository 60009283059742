import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react'
import styled from '@emotion/styled/macro'
import { createPortal } from 'react-dom'
import { ModalContext } from 'contexts/ModalProvider'

const KodexSidebar = ({
  children,
  opened,
  onClose,
  animationDuration,
  isCart,
  positionDialogCenter = false
}) => {
  const [overlayBackground, setOverlayBackground] = useState(
    'rgba(0, 0, 0, 0.0)'
  )
  const [dialogTranslate, setDialogTransform] = useState(
    positionDialogCenter ? 'translateY(200%)' : 'translateX(105%)'
  )
  const [topPosition, setTopPosition] = useState('0')
  const [dialogOpen, setDialogOpen] = useState(opened)

  const modalCtx = useContext(ModalContext)

  const wrapperRef = useRef(null)

  useEffect(() => {
    if (opened) {
      setOverlayBackground('#35353577')
      setDialogTransform('translateX(0)')
      setDialogOpen(true)
    }
    if (!opened) {
      setOverlayBackground('rgba(0, 0, 0, 0.0)')
      setDialogTransform(
        positionDialogCenter ? 'translateY(200%)' : 'translateX(105%)'
      )
      setTimeout(() => {
        setDialogOpen(false)
      }, 300)
    }
  }, [opened])

  const closeCartDialog = useCallback(() => {
    if (onClose) {
      setDialogTransform(
        positionDialogCenter ? 'translateY(200%)' : 'translateX(105%)'
      )
      setTimeout(onClose, 500)
    }
  }, [onClose])

  const handleClickOutside = useCallback(event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      closeCartDialog()
    }
  }, [])

  const handleCloseCartDialog = e => {
    if (e.target !== e.currentTarget) {
      return
    }
    closeCartDialog()
    setOverlayBackground('rgba(0, 0, 0, 0.0)')
  }

  function stopPropagation(e) {
    e.stopPropagation()
  }

  useEffect(() => {
    if (!modalCtx.closable) {
      document.removeEventListener('mousedown', handleClickOutside)
      return
    }

    document.addEventListener('mousedown', handleClickOutside)
  }, [modalCtx])

  // useOutsideAlerter(wrapperRef, closeCartDialog)

  return (
    (opened || dialogOpen) &&
    (isCart
      ? createPortal(
          <NoOverlayDialog
            ref={wrapperRef}
            onClick={stopPropagation}
            transform={dialogTranslate}
            transitionDuration={animationDuration}
          >
            {children}
          </NoOverlayDialog>,
          document.getElementById('inner-modal-root')
        )
      : createPortal(
          <Overlay
            center={positionDialogCenter}
            onClick={modalCtx.closable ? handleCloseCartDialog : null}
            top={topPosition}
            background={overlayBackground}
            transitionDuration={0.5}
          >
            <Dialog
              center={positionDialogCenter}
              onClick={stopPropagation}
              transform={dialogTranslate}
              transitionDuration={animationDuration}
            >
              {children}
            </Dialog>
          </Overlay>,
          document.getElementById('inner-modal-root')
        ))
  )
}

const Overlay = styled.div`
  position: fixed;
  z-index: 10;
  top: ${props => props.top};
  left: 0;
  width: 100%;
  height: 100vh;
  background: ${props =>
    props.background ? props.background : 'rgba(0, 0, 0, 0.3)'};
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'flex-end')};
  transition: ${props =>
    props.transitionDuration
      ? `background ${props.transitionDuration}s ease`
      : 'background 0.2s ease'};
`

const Dialog = styled.div`
  ${props =>
    props.center ? 'padding: 1.75rem 0 1rem 0;' : 'padding-top:1rem;'}
  position: ${props => (props.center ? 'relative' : 'fixed')};
  ${props => !props.center && 'right: 1rem;'}
  display: flex;
  height: 100%;
  max-height: 100vh;
  z-index: 10;
  flex-direction: column;
  transform: ${props =>
    props.transform ? props.transform : 'translate3d(0, 0, 0)'};
  transition: ${props =>
    props.transitionDuration
      ? `all ${props.transitionDuration}s cubic-bezier(0.19, 1, 0.22, 1)`
      : 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)'};
`

const NoOverlayDialog = styled.div`
  border-radius: 8px;
  background: transparent;
  position: fixed;
  top: 1rem;
  right: 1rem;
  display: flex;
  height: 100%;
  max-height: calc(100vh - 2rem);
  z-index: 10;
  flex-direction: column;
  transform: ${props =>
    props.transform ? props.transform : 'translate3d(0, 0, 0)'};
  transition: ${props =>
    props.transitionDuration
      ? `all ${props.transitionDuration}s cubic-bezier(0.19, 1, 0.22, 1)`
      : 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)'};
`
export default KodexSidebar
