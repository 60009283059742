export * from './domainResolvers'
export * from './userResolvers'

export const kodexApiContext = {
  context: {
    source: 'kodex-api'
  },
  fetchPolicy: 'network-only', // Used for first execution
  nextFetchPolicy: 'cache-first' // Used for subsequent executions
}
