import React from 'react'
import View from 'components/View'
import Label from 'components/Label'
import Space from 'components/Space'
import NoItemsImage from 'assets/no-result-transparent.png'

export default ({ message = 'No Items' }) => {
  return (
    <View
      width="100%"
      padding="40px 0"
      direction="column"
      justify="center"
      alignItems="center"
      background="#F3F3FA"
      borderRadius="10px"
    >
      <img src={NoItemsImage} />
      <Space size={20} />
      <Label size={13} color="#BCBCCC">
        {message}
      </Label>
    </View>
  )
}
