import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import Label from 'components/Label'
import View from 'components/View'
import Space from 'components/Space'
import getPremiumRegistrationChart from './getPremiumRegistrationChart'
import { getPremiumPrice } from 'service/rest/getPremiumPrice'
import moment from 'moment'

const SECONDS_IN_DAY = 86400

const PremiumChart = ({ domain, expiryDate, darkMode, fromModal, id }) => {
  const [chart, setChart] = useState(null)
  const chartContainerRef = useRef(null)
  const startOfPremium = expiryDate / SECONDS_IN_DAY + 90
  const startOfPremiumMilliseconds = startOfPremium * SECONDS_IN_DAY * 1000
  const endOfPremium = expiryDate / SECONDS_IN_DAY + 111
  const now = new Date().getTime() / 1000 / SECONDS_IN_DAY

  const premiumDay = Math.ceil(now - startOfPremium)
  const isPremium = premiumDay >= 0 && premiumDay <= 21

  const premiumEnd = moment(startOfPremiumMilliseconds)
    .add(21, 'days')
    .unix()
  const hoursTillEnd = Math.round((premiumEnd - moment().unix()) / 3600)

  const labels = []
  for (let i = 0; i < hoursTillEnd; ++i) {
    labels.push(
      moment()
        .add(i, 'hours')
        .format('D MMMM YYYY HH:00')
    )
  }

  const datapoints = labels.map((label, i) => {
    const premiumPrice = getPremiumPrice(
      expiryDate,
      moment(label).unix() * 1000
    )

    return premiumPrice
  })

  useEffect(() => {
    const chart = getPremiumRegistrationChart(
      id,
      darkMode,
      fromModal,
      datapoints,
      labels
    )
    setChart(chart)
    return () => {
      chart.destroy()
    }
  }, [])

  useEffect(() => {
    if (chart == null) {
      return
    }
    const observer = new IntersectionObserver(
      entries => {
        const [entry] = entries
        if (entry.isIntersecting) {
          chart.update('show')
          observer.unobserve(entry.target)
        }
      },
      {
        root: null,
        rootMargin: '0px 0px -230px 0px',
        threshold: [0, 0]
      }
    )

    if (chartContainerRef.current != null) {
      observer.observe(chartContainerRef.current)
    }
    return () => {
      if (chartContainerRef.current != null) {
        observer.disconnect()
      }
    }
  }, [chart])

  return (
    <StyledChartContainer darkMode={darkMode}>
      <View
        justify="space-between"
        width="100%"
        padding={darkMode ? '15px 40px 14px' : '15px 20px 14px'}
      >
        <Label color={darkMode ? '#fff' : '#020202'} weight={600} size={13}>
          Temporary premium
        </Label>
        <Label size={13} color={darkMode ? '#ffffff99' : '#BCBCCC'}>
          <Label color={darkMode ? '#fff' : '#020202'} size={13}>
            {`${premiumDay} `}
          </Label>
          / 21 days
        </Label>
      </View>
      <Space size={20} />
      {isPremium && (
        <View
          ref={chartContainerRef}
          margin={fromModal ? '0 -46px' : '0 -48px 0 -28px'}
          padding={fromModal ? '0 0 0 48px' : '0 20px 0 48px'}
          height={fromModal ? '330px' : '360px'}
        >
          <canvas id={id} />
        </View>
      )}
      {!isPremium && (
        <>
          <Space size={20} />
          <View justify="center">
            <Label color="black" size={30}>
              Domain not in a temporary premium registration
            </Label>
          </View>
        </>
      )}
    </StyledChartContainer>
  )
}

export default PremiumChart

const StyledChartContainer = styled.div`
  padding: 35px 0 0;
  border: ${props => `1px solid ${props.darkMode ? '#4A4A4D' : '#e1e1e8'}`};
  border-radius: 10px;
  height: ${props => (props.darkMode ? '450px' : '410px')};
  width: 100%;
`
