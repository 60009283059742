import { useLazyQuery } from '@apollo/client'
import { createContext, useContext, useEffect, useState } from 'react'
import { accountsReactive } from 'apollo/reactiveVars'
import { useAccount } from 'components/QueryAccount'
import {
  GET_USER,
  GET_USER_FOLLOWERS,
  GET_USER_FOLLOWINGS,
  GET_USER_LIKES,
  GET_USER_PROFILE,
  kodexApiContext
} from 'service/graphql/kodex-api'
import { siwe } from 'service/siwe'
import { connectProvider, disconnectProvider } from '../utils/providerUtils'
import { checkAuthenticationStatus } from 'service/siwe/checkAuthenticationStatus'
import { setup } from 'apollo/mutations/ens'
import { UserActivityContext } from './UserActivityProvider'

export const AccountContext = createContext({
  isConnected: false,
  isConnecting: false,
  isSiweAuthenticated: false,
  account: undefined,
  userData: null,
  likes: [],
  handleSIWE: async () => {},
  handleConnectProvider: async () => {},
  handeDisconnectProvider: () => {},
  setUserData: () => {},
  refetchUserData: () => {}
})

const AccountProvider = ({ children }) => {
  const { handleUserSeen } = useContext(UserActivityContext)

  const account = useAccount()
  const [userData, setUserData] = useState()
  const [isConnecting, setConnecting] = useState(false)
  const [isConnected, setConnected] = useState(false)
  const [isSiweAuthenticated, setSiweAuthenticated] = useState(false)

  const [getUser] = useLazyQuery(GET_USER, {
    ...kodexApiContext
  })

  const [getProfile] = useLazyQuery(GET_USER_PROFILE, {
    ...kodexApiContext
  })
  const [getFollowers] = useLazyQuery(GET_USER_FOLLOWERS, {
    ...kodexApiContext
  })
  const [getFollowings] = useLazyQuery(GET_USER_FOLLOWINGS, {
    ...kodexApiContext
  })
  const [getLikes] = useLazyQuery(GET_USER_LIKES, {
    ...kodexApiContext
  })

  // const [createUserMutation] = useMutation(CREATE_USER, {
  //   ...kodexApiContext
  // })

  const createUser = async (...args) => {
    try {
      await handleSIWE()
    } catch (e) {
      throw e
    }
  }

  const syncUser = async _address => {
    const { data: { users } = {} } = await getUser({
      variables: {
        user: _address.toLowerCase()
      }
    })

    if (users?.[0]) {
      const { __typename, ...rest } = users[0]
      setUserData({ ...rest, email: '' })

      // console.log('user synced', rest)

      getProfile({
        variables: {
          user: _address.toLowerCase()
        }
      })
      getFollowers({
        variables: {
          user: _address.toLowerCase()
        }
      })
      getFollowings({
        variables: {
          user: _address.toLowerCase()
        }
      })
      getLikes({
        variables: {
          user: _address.toLowerCase()
        }
      })
    } else {
      try {
        const {
          data: { insert_users_one: createdRes } = {}
        } = await createUser({
          variables: {
            fields: {
              user: _address.toLowerCase()
            }
          }
        })

        if (!!createdRes) {
          const { __typename, ...rest } = createdRes
          setUserData(rest)
        }
      } catch (error) {}
    }
  }

  const refetchUserData = () => {
    /* getUser({
      variables: {
        user: account.toLowerCase()
      }
    }) */
    syncUser(account)
  }

  const handleSIWE = async () => {
    const authenticationStatus = await checkAuthenticationStatus()
    if (authenticationStatus) {
      setSiweAuthenticated(authenticationStatus)
      return
    }

    try {
      await siwe('Hi from Kodex!')
      setSiweAuthenticated(await checkAuthenticationStatus())
    } catch (e) {
      setSiweAuthenticated(false)
      throw e
    }
  }

  const handleConnectProvider = async () => {
    try {
      await setup({
        reloadOnAccountsChange: false,
        enforceReadOnly: true,
        enforceReload: false
      })
    } catch {
      /* noop */
    }

    try {
      setConnecting(true)
      setConnected(false)

      await connectProvider()
      await handleSIWE()
      await handleUserSeen()

      setConnected(true)
    } catch (error) {
      console.log('connect provider error: ', error)
      setConnected(false)
    }

    setConnecting(false)

    if (accountsReactive()?.[0]) {
      syncUser(accountsReactive()[0])
    }
  }

  const handeDisconnectProvider = () => {
    disconnectProvider()
    setConnected(false)
    setSiweAuthenticated(false)
  }

  useEffect(() => {
    handleConnectProvider()
  }, [])

  useEffect(() => {
    syncUser(account)
  }, [account])

  // useEffect(() => {
  // window.addEventListener('load', handleConnectProvider)
  // return () => {
  //   window.removeEventListener('load', handleConnectProvider)
  // }
  // }, [])

  return (
    <AccountContext.Provider
      value={{
        isConnected,
        isConnecting,
        isSiweAuthenticated,
        userData,
        account,
        handleSIWE,
        handleConnectProvider,
        handeDisconnectProvider,
        setUserData,
        refetchUserData
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export default AccountProvider
