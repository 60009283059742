import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  PointElement,
  Tooltip
} from 'chart.js'
import { externalTooltipHandler } from './tooltip'
import moment from 'moment'

Chart.register(
  BarController,
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip
)

export default (data, labels, bgColor, firstInit, isRegistered) => {
  const ctx = document.getElementById('myChart')
  Chart.defaults.font.family = 'Satoshi'
  Chart.defaults.barThickness = 4
  const chart = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [
        {
          data: data,
          borderColor: bgColor,
          backgroundColor: bgColor,
          borderWidth: 1,
          borderRadius: 10,
          borderSkipped: false
        }
      ]
    },
    options: {
      // layout: {
      //   padding: {
      //     left: -20
      //   }
      // },
      maintainAspectRatio: false,

      interaction: {
        intersect: false
      },
      plugins: {
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler
        },
        legend: {
          display: false
        }
      },
      transitions: {
        show: {
          animations: {
            y: {
              duration: 1200,
              from: 500,
              easing: 'easeOutExpo'
            }
          }
        },
        hide: {
          animations: {
            y: {
              to: 500,
              duration: 0
            },
            visible: {
              type: 'boolean',
              duration: 0
            }
          }
        }
      },
      scales: {
        y: {
          ticks: {
            padding: 20,
            color: isRegistered
              ? 'rgba(188,188,204, 0.6)'
              : 'rgba(255, 255, 255, 0.6)',
            maxTicksLimit: 3
          },
          grid: {
            drawTicks: false,
            drawBorder: false,
            color: isRegistered ? '#E1E1E8' : 'rgba(255, 255, 255, 0.2)',
            borderDash: [4, 10]
          }
        },
        x: {
          beginAtZero: true,
          ticks: {
            padding: 20,
            color: isRegistered
              ? 'rgba(188,188,204, 0.6)'
              : 'rgba(255, 255, 255, 0.6)',
            callback: function(value, index, data) {
              return index === 0 || index === data.length - 1
                ? moment(this.getLabelForValue(value)).format('D MMM')
                : null
            }
          },
          grid: {
            display: false,
            drawBorder: false,
            color: 'rgba(255, 255, 255, 0)'
          }
        }
      }
    }
  })
  chart.options.transitions.show.colors = false
  if (firstInit) {
    chart.update('hide')
  }

  return chart
}
