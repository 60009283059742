import { useQuery } from '@apollo/client'
import View from 'components/View'
import { VirtualizedList } from 'components/Virtualized'
import { GET_USER_FOLLOWERS, kodexApiContext } from 'service/graphql/kodex-api'
import SingleItem from './SingleItem'

const FollowersTab = ({ user, close, refetchFollowings, refetchFollowers }) => {
  const { data } = useQuery(GET_USER_FOLLOWERS, {
    variables: {
      user: user.toLowerCase()
    },
    ...kodexApiContext
  })

  return (
    <View
      width="100%"
      direction="column"
      style={{
        overflowY: 'scroll'
      }}
      height="calc(100vh - 200px)"
      padding="0 0 60px 0"
      scrollBar={`&::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */`}
    >
      <VirtualizedList
        data={data?.followers?.[0]?.list ?? []}
        rowsRenderer={(item, idx) => (
          <SingleItem
            key={idx}
            item={item.info}
            close={close}
            refetchFollowers={refetchFollowers}
            refetchFollowings={refetchFollowings}
          />
        )}
        width="100%"
      />
    </View>
  )
}

export default FollowersTab
