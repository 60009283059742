import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import { GET_CATEGORIES, kodexApiContext } from 'service/graphql/kodex-api'

const useGetDomainCategories = domain => {
  const { data: { domains_taxonomies: taxonomies } = {} } = useQuery(
    GET_CATEGORIES,
    {
      variables: {
        where: {
          domain: { _eq: domain }
        }
      },
      ...kodexApiContext,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  )

  const categories = useMemo(() => {
    return (taxonomies || []).map((tx, id) => ({
      key: tx.taxonomy,
      name: tx.taxonomy,
      color: MAIN_CATEGORY_COLORS[id % 11]
    }))
  }, [taxonomies])

  return categories
}

export default useGetDomainCategories
