import React, { useMemo } from 'react'
import View from 'components/View'
import Space from 'components/Space'
import Card from './Card'
import styled from '@emotion/styled/macro'
import { ReactComponent as CaretRight } from 'assets/caret-right.svg'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import KodexIcon from 'components/Icon/KodexIcon'
import { CATEGORIES } from 'constants/category'

const CategorySelector = ({
  onCategoryExpand = () => {},
  selectedSubcategories
}) => {
  const categoriesToDisplay = useMemo(() => {
    const categoriesSet = new Set()
    selectedSubcategories.forEach(subcategory => {
      for (const [key, value] of Object.entries(CATEGORIES)) {
        if (value.includes(subcategory)) {
          categoriesSet.add(key)
        }
      }
    })

    return Array.from(categoriesSet)
  }, [selectedSubcategories])

  return (
    <Card
      title="Categories"
      HeaderTrailingElement={
        <Arrow onClick={onCategoryExpand}>
          <KodexIcon padding="5px" size={10} icon={<CaretRight />} />
        </Arrow>
      }
    >
      <Space size={20} />
      <View
        padding="15px 0"
        height="auto"
        minHeight="60px"
        wrappable
        gap="0.5rem"
      >
        {categoriesToDisplay.length === Object.keys(CATEGORIES).length
          ? ''
          : categoriesToDisplay.map(category => (
              <Category
                color={
                  MAIN_CATEGORY_COLORS[
                    Object.keys(CATEGORIES).indexOf(category)
                  ]
                }
              >
                {category}
              </Category>
            ))}
        {(categoriesToDisplay.length === 0 ||
          categoriesToDisplay.length === Object.keys(CATEGORIES).length) && (
          <Category color="#aaa">ALL</Category>
        )}
      </View>
    </Card>
  )
}

export default CategorySelector

const Arrow = styled.div`
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`
const Category = styled.div`
  color: ${props => props.color};
  font-size: 30px;
  margin-right: 20px;
  line-height: 1;
`
