import { BigNumber } from 'ethers'

export const transformOrderData = data => {
  const transformedConsideration = data.parameters.consideration.map(
    consideration => {
      const endAmount = BigNumber.from(consideration.endAmount).toHexString()
      const startAmount = BigNumber.from(
        consideration.startAmount
      ).toHexString()
      const identifierOrCriteria = BigNumber.from(
        consideration.identifierOrCriteria
      ).toHexString()

      return {
        ...consideration,
        endAmount,
        startAmount,
        identifierOrCriteria
      }
    }
  )

  const transformedOffers = data.parameters.offer.map(offer => {
    const endAmount = BigNumber.from(offer.endAmount).toHexString()
    const startAmount = BigNumber.from(offer.startAmount).toHexString()
    const identifierOrCriteria = BigNumber.from(
      offer.identifierOrCriteria
    ).toHexString()

    return {
      ...offer,
      endAmount,
      startAmount,
      identifierOrCriteria
    }
  })

  const dataTransformed = {
    signature: data.signature,
    parameters: {
      ...data.parameters,
      startTime: BigNumber.from(data.parameters.startTime).toHexString(),
      endTime: BigNumber.from(data.parameters.endTime).toHexString(),
      consideration: transformedConsideration,
      offer: transformedOffers,
      nonce: data.parameters.counter
    }
  }

  return dataTransformed
}

export const reTransformOrderData = data => {
  const transformedConsideration = data.parameters.consideration.map(
    consideration => {
      const endAmount = BigNumber.from(consideration.endAmount).toString()
      const startAmount = BigNumber.from(consideration.startAmount).toString()
      const identifierOrCriteria = BigNumber.from(
        consideration.identifierOrCriteria
      ).toString()

      return {
        ...consideration,
        endAmount,
        startAmount,
        identifierOrCriteria
      }
    }
  )

  const transformedOffers = data.parameters.offer.map(offer => {
    const endAmount = BigNumber.from(offer.endAmount).toString()
    const startAmount = BigNumber.from(offer.startAmount).toString()
    const identifierOrCriteria = BigNumber.from(
      offer.identifierOrCriteria
    ).toString()

    return {
      ...offer,
      endAmount,
      startAmount,
      identifierOrCriteria
    }
  })

  const dataTransformed = {
    signature: data.signature,
    parameters: {
      ...data.parameters,
      startTime: BigNumber.from(data.parameters.startTime),
      endTime: BigNumber.from(data.parameters.endTime),
      consideration: transformedConsideration,
      offer: transformedOffers
    }
  }

  return dataTransformed
}
