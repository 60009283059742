import styled from '@emotion/styled/macro'
import { ReactComponent as Cross } from 'assets/cross-white.svg'
import { ReactComponent as CrossBlack } from 'assets/cross.svg'
import View from 'components/View'
import useCaretPosition from 'hooks/useCaretPosition'
import { useState } from 'react'
import Caret from '../Caret'

const SearchInput = ({
  placeholder,
  onChange,
  searchOnEnter,
  searchDomains,
  value,
  onClear,
  background,
  placeholderColor = '#262628',
  color = 'white',
  onKeyDown = () => {},
  onKeyUp = () => {}
}) => {
  const [isFocused, setIsFocused] = useState(true)
  const {
    inputRef,
    inputStringLengthRef,
    caretLeftPosition
  } = useCaretPosition(
    value,
    window.innerWidth < 740 ? 0 : 40,
    window.innerWidth < 740 ? 5 : 9
  )

  let searchTimeout

  const onInputChange = evt => {
    onChange(evt.target.value)
    clearTimeout(searchTimeout)
  }

  return (
    <View
      background={background || 'black'}
      display="flex"
      width="100%"
      maxWidth={`${window.innerWidth - 40}px`}
      justify="space-between"
    >
      <View
        position="relative"
        padding={window.innerWidth < 740 ? '0' : '5px 5px 5px 5px'}
        flex="1"
        background-color="#e4e4e4"
      >
        <StyledInput
          ref={inputRef}
          // type="search"
          placeholder={placeholder}
          placeholderColor={placeholderColor}
          onChange={onInputChange}
          color={color}
          value={value}
          fullWidth={onClear}
          autoFocus
          spellCheck="false"
          fontSize={window.innerWidth < 640 ? '40px' : '60px'}
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={() => {
            setIsFocused(false)
          }}
          onKeyDown={e => {
            if (e.which == 37 || e.which == 39) {
              e.preventDefault()
              return
            }
            clearTimeout(searchTimeout)
            if (e.key === 'Enter') {
              searchDomains()
              return
            }
          }}
          onKeyUp={() => {
            clearTimeout(searchTimeout)
            searchTimeout = setTimeout(() => {
              searchDomains()
            }, 1200)
          }}
          onClick={e => {
            e.target.setSelectionRange(value.length, value.length)
          }}
        />
        <Caret
          focused={isFocused}
          left={caretLeftPosition}
          width="4px"
          height={window.innerWidth < 640 ? '33px' : '44px'}
          top={'26px'}
        />
        <StringWidth
          ref={inputStringLengthRef}
          fontSize={window.innerWidth < 640 ? '40px' : '60px'}
          fullWidth={onClear}
        >
          {value}
        </StringWidth>
      </View>
      {value && onClear && (
        <ClearButton onClick={onClear} background={placeholderColor}>
          {window.innerWidth < 740 ? (
            placeholderColor === '#262628' ? (
              <Cross height="25px" width="25px" />
            ) : (
              <CrossBlack height="25px" width="25px" />
            )
          ) : (
            'Clear'
          )}
        </ClearButton>
      )}
    </View>
  )
}

const StyledInput = styled.input`
  flex: 1;
  max-width: ${props =>
    props.fullWidth
      ? window.innerWidth < 740
        ? window.innerWidth - 110
        : window.innerWidth - 250
      : window.innerWidth - 32}px;
  background: transparent;
  font-family: 'Satoshi', sans-serif;
  height: 80px;
  border: none;
  outline: none;
  font-size: ${props => props.fontSize};
  padding-right: ${window.innerWidth > 740 ? '40px' : '0'};
  padding-left: 0;
  margin: 0;
  color: ${props => props.color || '#fff'};
  caret-color: transparent;
  resize: both;

  ::placeholder {
    color: ${props => props.placeholderColor};
    opacity: 1;
    font-size: ${props => props.fontSize};
  }

  ::selection {
    background: #a14f9d;
  }
`

const StringWidth = styled.span`
  font-size: ${props => props.fontSize};
  width: auto;
  max-width: ${props =>
    props.fullWidth ? window.innerWidth - 100 : window.innerWidth - 35}px;
  position: absolute;
  z-index: -1;
  color: transparent;
`

const ClearButton = styled.button`
  border-radius: 6px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.background};
  margin: auto 0;
  width: ${window.innerWidth < 740 ? '40px' : '110px'};
  height: ${window.innerWidth < 740 ? '40px' : '60px'};
  color: #fff;
  font-size: 16px;
  font-family: 'Satoshi', sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    opacity: 0.6;
  }
`

export default SearchInput
