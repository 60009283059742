import { ens_normalize } from '@adraffy/ens-normalize'
import styled from '@emotion/styled/macro'
import { getSigner } from '@ensdomains/ui'
import { ReactComponent as Minus } from 'assets/minus.svg'
import { ReactComponent as Plus } from 'assets/plus.svg'
import { ReactComponent as NameWarning } from 'assets/warning-name.svg'
import { ReactComponent as CheckIcon } from 'assets/check-icon-blue.svg'
import { ReactComponent as CheckIconWhite } from 'assets/check-icon-white.svg'
import CategoriesBox from 'components/CategoriesDisplayBox'
import Chip from 'components/Chip'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { CATEGORIES, CATEGORY_PRIORITY_LIST, TAGS } from 'constants/category'
import { ethers } from 'ethers'
import useDomainResolver from 'hooks/useDomainResolver'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { getOffers } from 'service/rest/getOffers'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import { formatETHPrice, formatUSDPrice } from 'utils/format'
import { actions } from 'utils/reservoir'
import { REGISTRATION_STATUS } from 'hooks/useDomainResolver'
import { ENS_CONTRACT_ADDRESS } from 'utils/utils'
import ActivityAddress from 'views/Profile/components/ActivityAddress'
import LoadingNetTokenRow from './LoadingNetTokenRow'

const Container = styled.div`
  align-items: center;
  border-radius: 10px;
  display: flex;
  height: 60px;
  margin: 5px 0;
  padding: 20px;
  position: relative;
  width: 100%;
  cursor: pointer;
  opacity: 1;

  &:hover {
    background: ${props => props.hoverColor};
  }
`

const CategoryCountLabel = styled.span`
  color: #9a9a9a;
`

let isVisible = false

const NetTokenRow = ({
  domain,
  fieldGetters = {},
  onSelect,
  addedToCart,
  isDarkMode,
  hasAnimation,
  zIndex = 0,
  index,
  onClick,
  priceInETH = true,
  ethPrice = 1,
  from = '',
  openRegistrationDialog = () => {}
}) => {
  const rowRef = useRef(null)

  const handleChangeVisibility = entries => {
    const [entry] = entries
    isVisible = entry.intersectionRatio === 1
  }

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      handleChangeVisibility,
      observerOptions
    )
    if (!rowRef.current) return
    if (isVisible) {
      observer.unobserve(rowRef.current)
      return
    }

    if (rowRef.current) observer.observe(rowRef.current)

    return () => {
      if (rowRef.current) observer.unobserve(rowRef.current)
    }
  }, [observerOptions, handleChangeVisibility, rowRef, isVisible])

  const [isHovered, setIsHovered] = useState(false)

  const [refetchData, setRefetchData] = useState(false)
  const [purchaseError, setPurchaseError] = useState(false)
  const [buying, setBuying] = useState(false)
  const [usesSpecialCharacters, setUsesSpecialCharacters] = useState(false)
  const [displayWarningMessage, setDisplayWarningMessage] = useState(false)
  const [buyNowHovered, setBuyNowHovered] = useState(false)
  const [categoryColor, setCategoryColor] = useState(
    isDarkMode ? '#aaa' : '#444'
  )

  const [highestOffer, setHighestOffer] = useState(null)

  const history = useHistory()

  const {
    name: domainName,
    price,
    categories,
    isRegistered,
    isListed,
    listingPrice,
    isLikedByUser,
    lastPrice,
    lastPriceAsset,
    toggleLike,
    listingDate,
    expires,
    tokenId,
    listingExpireTime,
    ownerAddress,
    expirationStatus,
    registrations
  } = useDomainResolver(domain, fieldGetters, refetchData)

  const sortedCategories = useMemo(() => {
    if (!categories || !categories?.terms) return []
    const categoriesArrCopy = categories?.terms
    const sortedCategoriesIndexes = categoriesArrCopy
      .map(c => ({
        index: CATEGORY_PRIORITY_LIST.indexOf(c),
        category: c
      }))
      .sort((a, b) => a.index - b.index)

    return sortedCategoriesIndexes.map(obj => obj.category)
  }, [categories])

  const listingPriceUSD = Number(listingPrice) * Number(ethPrice)
  const highestOfferUSD = Number(highestOffer) * Number(ethPrice)

  const isRegistryStyle = from.toLowerCase() === 'registry'

  useEffect(() => {
    try {
      const normalizedName = ens_normalize(domainName)
      if (normalizedName) setUsesSpecialCharacters(false)
    } catch (e) {
      setUsesSpecialCharacters(true)
    }
  }, [domainName])

  const handleGoSubCategory = useCallback(
    e => {
      e.stopPropagation()

      const category = sortedCategories?.[0]
      if (category) {
        const categoryKey = category
          .toLowerCase()
          .split(' ')
          .join('-')

        TAGS.forEach(tag => {
          if (tag.key.toLowerCase() === categoryKey.toLowerCase()) {
            history.push(`/marketplace?category=${tag.key.toLowerCase()}`)
          } else if (tag.subcategories.length > 0) {
            tag.subcategories.forEach(subCategory => {
              if (subCategory.key.toLowerCase() === categoryKey.toLowerCase()) {
                history.push(`/marketplace?category=${tag.key.toLowerCase()}`)
              }
            })
          }
        })
      }
    },
    [categories]
  )

  const getCategoryColor = () => {
    const category = sortedCategories?.[0]
    if (category) {
      const categoryKey = category
        .toLowerCase()
        .split(' ')
        .join('-')

      TAGS.forEach(tag => {
        if (tag.key.toLowerCase() === categoryKey.toLowerCase()) {
          setCategoryColor(
            MAIN_CATEGORY_COLORS[Object.keys(CATEGORIES).indexOf(tag.key)]
          )
        } else if (tag.subcategories.length > 0) {
          tag.subcategories.forEach(subCategory => {
            if (subCategory.key.toLowerCase() === categoryKey.toLowerCase()) {
              setCategoryColor(
                MAIN_CATEGORY_COLORS[Object.keys(CATEGORIES).indexOf(tag.key)]
              )
            }
          })
        }
      })
    }
  }

  const fetchOffers = async () => {
    try {
      const fetchedOffers = await getOffers({
        address: ENS_CONTRACT_ADDRESS,
        token_id: tokenId
      })

      if (!fetchedOffers) {
        return
      }

      const highestOfferAmount = fetchedOffers?.reduce((acc, curr) => {
        const price = curr.price.amount.decimal

        if (price > acc) {
          return price
        } else {
          return acc
        }
      }, 0)

      setHighestOffer(highestOfferAmount)
    } catch (err) {
      throw new Error(err)
    }
  }

  useEffect(() => {
    fetchOffers()
  }, [])

  useEffect(() => {
    getCategoryColor()
  }, [sortedCategories])

  // const expDate = useMemo(() => {
  //   const now = new Date().getTime() / 1000
  //   if (domain && Number(domain.expires) > now) {
  //     return `${moment(Number(domain.expires) * 1000)
  //       .add(90, 'days')
  //       .format('ll')}`
  //   }
  //   return "ISN'T REGISTERED"
  // }, [domain])

  // const getLastPriceIcon = () => {
  //   const priceUnit = 'ETH' // TODO: hardcoded priceUnit
  //   if (priceUnit === 'WETH') {
  //     if (isHovered) {
  //       return <WethIconDark />
  //     }
  //     return <WethIcon />
  //   } else {
  //     if (!isDarkMode) {
  //       if (isHovered) {
  //         return <EtherIconWhite />
  //       }
  //       return <EtherIconGrey />
  //     } else {
  //       if (isHovered) {
  //         return <EtherIconDark />
  //       }
  //       return <EtherIcon />
  //     }
  //   }
  // }

  const handlePurchaseDomain = async e => {
    e.stopPropagation()
    if (!isListed || !listingPrice || !domainName) return
    try {
      setBuying(true)

      const signer = await getSigner()

      const tokenId = ethers.BigNumber.from(
        ethers.utils.keccak256(
          ethers.utils.toUtf8Bytes(domainName.replace('.eth', ''))
        )
      ).toString()

      await actions.buyToken({
        signer,
        items: [
          {
            token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
            quantity: 1,
            preferredOrderSource: 'kodex.io'
          }
        ],
        onProgress: step => {
          if (step[0].items[0].status === 'complete') {
            setTimeout(() => {
              setBuying(false)
              setRefetchData(true)
              // resetCardSeed()
            }, 1000)
          }
        }
      })
    } catch (err) {
      setPurchaseError(true)
      setBuying(false)
      setTimeout(() => setPurchaseError(false), 4000)
      throw new Error(err)
    }
  }

  const isRegistryPrice =
    isRegistered === false &&
    price &&
    expirationStatus !== REGISTRATION_STATUS.GRACE_PERIOD

  const textColor = from.toLowerCase() === 'registry' ? '#fff' : '#000'

  if (!domain || (!ownerAddress && !isRegistryStyle)) {
    return (
      <LoadingNetTokenRow
        isDarkMode={isDarkMode}
        hasAnimation={hasAnimation}
        index={index}
        marketplace={from.toLowerCase() === 'marketplace'}
        from={from}
      />
    )
  }

  return (
    <Container
      ref={rowRef}
      registered={isRegistered}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      hoverColor={!isRegistryStyle ? '#f3f3fa' : '#262628'}
      onClick={e => {
        e.stopPropagation()
        onSelect()
      }}
      style={{
        opacity: isVisible ? '1' : '0',
        transition: 'transform 0.4s ease-out',
        transform: isVisible ? 'translateY(0px)' : 'translateY(30px)'
      }}
    >
      <View flex="20%" alignItems="center">
        <Label
          size={16}
          color={textColor}
          onClick={e => {
            e.stopPropagation()
            onClick()
          }}
          hoverColor={!isRegistryStyle ? '#444' : '#fffa'}
          weight={700}
        >
          {domainName
            ? domainName.length > 28
              ? domainName.slice(0, 25) + '...'
              : domainName
            : '--'}
        </Label>{' '}
        {usesSpecialCharacters && (
          <View
            justify="center"
            position="relative"
            alignItems="center"
            margin="0 0 0 5px"
            cursor="pointer"
            onMouseEnter={() => setDisplayWarningMessage(true)}
            onMouseLeave={() => setDisplayWarningMessage(false)}
          >
            {displayWarningMessage && (
              <>
                <div
                  style={{
                    width: '250px',
                    position: 'absolute',
                    top: '-15px',
                    left: '30px',
                    border: `1px solid ${
                      isRegistryStyle ? '#4A4A4D' : '#E1E1E8'
                    }`,
                    padding: '10px',
                    background: isRegistryStyle ? '#262628' : 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px',
                    zIndex: 100
                  }}
                >
                  <Label
                    color={isRegistryStyle ? 'white' : '#222'}
                    size={13}
                    align="center"
                  >
                    This domain uses non ASCII special characters
                  </Label>
                </div>
              </>
            )}
            <NameWarning height="15px" />
          </View>
        )}
        <View
          zIndex={zIndex}
          opacity={sortedCategories.length > 0 ? '1' : '0'}
          transition="opacity 500ms ease"
        >
          <Space size={20} />
          <View
            opacity={sortedCategories ? 1 : 0}
            transition="opacity 500ms ease"
          >
            <CategoriesBox
              positionRight
              width="30px"
              hashtagFontSize={13}
              bodyColor={isRegistryStyle ? '#fff' : '#020202'}
              tooltipBorderColor="#CACADB"
              fontSize={13}
              isVisible={isVisible}
              boxBorderColor="transparent"
              isWhiteStyle={!isRegistryStyle}
              categories={sortedCategories.length > 0 ? sortedCategories : []}
              boxBg={
                isRegistryStyle
                  ? isHovered
                    ? '#000'
                    : '#333333'
                  : isHovered
                  ? '#FFF'
                  : '#F3F3FA'
              }
            />
          </View>
        </View>
      </View>
      <View alignItems="center" flex="20%">
        {!isListed && !isRegistryPrice ? (
          <View>
            <Space size={10} />
            <Label size={16} color={textColor} whiteSpace="nowrap" weight={500}>
              --
            </Label>
          </View>
        ) : (
          <RoundedButton
            onMouseEnter={() => setBuyNowHovered(true)}
            onMouseLeave={() => setBuyNowHovered(false)}
            onClick={e =>
              isRegistryPrice
                ? openRegistrationDialog()
                : isListed && handlePurchaseDomain(e)
            }
            background={
              purchaseError ? '#f54e4e' : isRegistryStyle ? '#000' : '#fff'
            }
            hoverBackground={
              isListed ||
              (!isRegistered &&
                expirationStatus !== REGISTRATION_STATUS.GRACE_PERIOD)
                ? '#0698A8'
                : 'transparent'
            }
            style={{
              display: 'flex',
              border:
                (listingPrice || isRegistryPrice) &&
                !buying &&
                '1px solid #cacadb',
              width: '60%',
              minWidth: '123px',
              padding: '17px 20px',
              transition: 'width 2000ms ease',
              justifyContent:
                isRegistryPrice || isListed ? 'center' : 'flex-start',
              borderRadius: '6px'
            }}
          >
            {purchaseError ? (
              <Label size={16} color="#fff" whiteSpace="nowrap" weight={700}>
                Error
              </Label>
            ) : (
              (isListed || isRegistryPrice) && (
                <Label
                  size={16}
                  color={buyNowHovered ? '#fff' : textColor}
                  whiteSpace="nowrap"
                  weight={700}
                  style={{
                    transition: 'color 0.05s'
                  }}
                >
                  {buyNowHovered ? (
                    isListed ? (
                      'Buy now'
                    ) : isRegistryPrice ? (
                      'Register'
                    ) : null
                  ) : (
                    <>
                      {!isRegistryPrice
                        ? priceInETH
                          ? formatETHPrice(listingPrice) + ' ETH'
                          : formatUSDPrice(listingPriceUSD) + ' USD'
                        : priceInETH
                        ? formatETHPrice(price) + ' ETH'
                        : formatUSDPrice(Number(price) * Number(ethPrice)) +
                          ' USD'}
                    </>
                  )}
                </Label>
              )
            )}
          </RoundedButton>
        )}
      </View>

      <View alignItems="center" flex="20%">
        {!!lastPrice && (
          <>
            {lastPrice ? (
              <Label number size={16} color={textColor}>
                {lastPriceAsset === 'ETH' || lastPriceAsset === 'WETH'
                  ? formatETHPrice(lastPrice)
                  : lastPriceAsset === 'USD' || lastPriceAsset === 'USDC'
                  ? formatUSDPrice(Number(lastPrice))
                  : formatUSDPrice(Number(lastPrice) * Number(ethPrice || 1))}
                {' ' + lastPriceAsset}
              </Label>
            ) : (
              <Label number size={16} color={textColor}>
                --
              </Label>
            )}
          </>
        )}
      </View>

      {isRegistryStyle ? (
        <View flex="20%">
          {/* <Label color="#BCBCCC">{registrationTime || '--'}</Label> */}
          <Label color="#BCBCCC">{'--'}</Label>
        </View>
      ) : (
        <View flex="20%">
          <ActivityAddress
            color="#BCBCCC"
            hoverColor="#d3d3de"
            address={ownerAddress || ''}
            showPfp={false}
          />
        </View>
      )}
      <View flex="18%">
        {listingDate || expires ? (
          <Label
            color={
              listingDate || (isRegistryStyle && expires) ? '#BCBCCC' : '#000'
            }
          >
            {listingDate !== null
              ? isRegistryStyle
                ? moment(listingDate).fromNow()
                : null
              : (expires && isRegistryStyle && moment(expires).fromNow()) ||
                '--'}
          </Label>
        ) : (
          <Label color="#000" style={{ marginLeft: '5px' }}>
            --
          </Label>
        )}
      </View>
      <View
        flex="2%"
        justify="flex-end"
        alignItems="center"
        opacity={isHovered || addedToCart ? '1' : '0'}
      >
        <View
          justify="center"
          alignItems="center"
          width="20px"
          height="20px"
          borderRadius="4px"
          border={`1px solid ${isRegistryStyle ? '#4a4a4d' : '#CACADB'}`}
          background={isRegistryStyle ? '#020202' : '#fff'}
        >
          {addedToCart ? (
            isRegistryStyle ? (
              <CheckIconWhite />
            ) : (
              <CheckIcon />
            )
          ) : null}
        </View>
      </View>
    </Container>
  )
}

export default NetTokenRow
