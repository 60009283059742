import { ReactComponent as EtherIcon } from 'assets/ether-grey.svg'
import Time from 'components/DateTime/Time'
import RoundedButton from 'components/Forms/RoundedButton'
import TableIcon from 'components/Icon/TableIcon'
import LoadingSpinner from 'components/LoadingSpinner'
import { useAccount } from 'components/QueryAccount'
import Space from 'components/Space'
import { Cell, Column, HeaderCell, Table } from 'components/Table'
import View from 'components/View'
import { BigNumber, ethers } from 'ethers'
import moment from 'moment'
import 'moment-timezone'
import { useEffect, useState } from 'react'
import { getOffers } from 'service/rest/getOffers'
import { ENS_CONTRACT_ADDRESS } from 'utils/utils'
import Offerer from 'views/TokenProfile/components/Offerer'
import NoResult from '../NoResult'
import OfferDomain from '../components/OfferDomain'
import { OFFERS_DROPDOWN_ITEMS } from '..'

const ProfileOffers = ({
  domains,
  account,
  cancelOffer,
  navItem,
  canceling,
  acceptOffer,
  accepting,
  ownedProfile,
  refetchDomainsOffers,
  setRefetchDomainsOffers
}) => {
  const [offers, setOffers] = useState([])
  const [offerDomainsIds, setOfferDomainIds] = useState([])
  const userAccount = useAccount()
  const [currentRowHovered, setCurrentRowHovered] = useState(null)

  useEffect(() => {
    const fetchOffers = async () => {
      if (
        navItem === OFFERS_DROPDOWN_ITEMS.RECEIVED &&
        (!domains || domains?.length === 0)
      )
        return

      setRefetchDomainsOffers(false)

      try {
        // console.log(account)
        const fetchedOffers =
          navItem === OFFERS_DROPDOWN_ITEMS.RECEIVED
            ? await domains.map(async domain => {
                const tokenIdString = ethers.BigNumber.from(
                  ethers.utils.keccak256(
                    ethers.utils.toUtf8Bytes(domain.name.replace('.eth', ''))
                  )
                ).toString()

                const offer = await getOffers({
                  address: ENS_CONTRACT_ADDRESS,
                  token_id: tokenIdString
                })

                return await offer?.[0]
              })
            : await getOffers({
                maker: userAccount
              })

        if (fetchedOffers?.length === 0) {
          setOffers([])
          return
        }

        const offersFiltered = await fetchedOffers.filter(offer =>
          navItem === OFFERS_DROPDOWN_ITEMS.SENT
            ? offer.maker.toLowerCase() === account.toLowerCase()
            : offer.maker.toLowerCase() !== account.toLowerCase()
        )

        // const sortedByAmount = await offersFiltered.sort(
        //   (a, b) =>
        //     BigNumber.from(b.parameters.consideration[2].startAmount)
        //       .div(BigNumber.from(10).pow(8))
        //       .toNumber() -
        //     BigNumber.from(a.parameters.consideration[2].startAmount)
        //       .div(BigNumber.from(10).pow(8))
        //       .toNumber()
        // )

        const sortedByExpiryDate = offersFiltered.sort(
          (a, b) => Number(b.expiration) - Number(a.expiration)
        )

        const offerDomainsIdsExtracted = sortedByExpiryDate.map(
          order => order.criteria.data.token.tokenId
        )

        setOfferDomainIds(offerDomainsIdsExtracted)

        setOffers([...sortedByExpiryDate])
      } catch (err) {
        throw new Error(err)
      }
    }

    fetchOffers()
  }, [account, navItem, domains, refetchDomainsOffers])

  const noDomains = !domains || (domains && domains.length === 0)

  return (
    <>
      {(navItem === OFFERS_DROPDOWN_ITEMS.SENT && offers.length === 0) ||
      (navItem === OFFERS_DROPDOWN_ITEMS.RECEIVED && noDomains) ? (
        <NoResult message="No offers" />
      ) : (
        offers.length > 0 && (
          <Table
            data={offers}
            height={350}
            background="transparent"
            style={{ margin: '-5px 0' }}
          >
            <Column>
              <HeaderCell>Domain</HeaderCell>
              <Cell>
                {(_, i) => {
                  return (
                    <View
                      onMouseEnter={() => setCurrentRowHovered(i)}
                      onMouseLeave={() => setCurrentRowHovered(null)}
                    >
                      <OfferDomain domain_id={[offerDomainsIds[i]]} />
                    </View>
                  )
                }}
              </Cell>
            </Column>
            <Column>
              <HeaderCell>Price</HeaderCell>
              <Cell>
                {(rowData, i) => {
                  const price = rowData?.price?.amount?.decimal

                  return (
                    <View
                      alignItems="center"
                      onMouseEnter={() => setCurrentRowHovered(i)}
                      onMouseLeave={() => setCurrentRowHovered(null)}
                    >
                      <TableIcon icon={<EtherIcon />} background="#F3F3FA" />
                      <Space size={20} />
                      <span>{price}</span>
                    </View>
                  )
                }}
              </Cell>
            </Column>
            <Column>
              <HeaderCell>
                {navItem === OFFERS_DROPDOWN_ITEMS.SENT ? 'To' : 'From'}
              </HeaderCell>
              <Cell dataKey="from">
                {(rowData, i) => (
                  <View
                    onMouseEnter={() => setCurrentRowHovered(i)}
                    onMouseLeave={() => setCurrentRowHovered(null)}
                  >
                    <Offerer
                      isSentOffers={navItem === OFFERS_DROPDOWN_ITEMS.SENT}
                      data={rowData}
                    />
                  </View>
                )}
              </Cell>
            </Column>
            {/* <Column>
            <HeaderCell>Floor Difference</HeaderCell>
            <Cell dataKey="offerDate" />
          </Column> */}
            <Column>
              <HeaderCell>Expires</HeaderCell>
              <Cell dataKey="expirationDate">
                {(rowData, i) => (
                  <View
                    onMouseEnter={() => setCurrentRowHovered(i)}
                    onMouseLeave={() => setCurrentRowHovered(null)}
                  >
                    <Time
                      timeString={moment
                        .tz(
                          BigNumber.from(rowData?.expiration).toNumber() * 1000,
                          'UTC'
                        )
                        .format()}
                    />
                  </View>
                )}
              </Cell>
            </Column>
            <Column width="100px">
              <HeaderCell />
              <Cell>
                {(rowData, i) => {
                  if (
                    rowData.maker.toLowerCase() !== userAccount.toLowerCase() &&
                    !ownedProfile
                  )
                    return
                  return (
                    <RoundedButton
                      onMouseEnter={() => setCurrentRowHovered(i)}
                      onMouseLeave={() => setCurrentRowHovered(null)}
                      width="100%"
                      opacity={currentRowHovered === i ? '1' : '0'}
                      transition="opacity 150ms ease"
                      height="50px"
                      border="1px solid #eee"
                      borderRadius="6px"
                      background="#fff"
                      hoverBackground="#f3f3fa"
                      disabled={canceling === i || accepting === i}
                      onClick={() => {
                        if (currentRowHovered !== i) return
                        if (
                          ownedProfile &&
                          navItem === OFFERS_DROPDOWN_ITEMS.SENT
                        ) {
                          cancelOffer(rowData, i)
                          return
                        }
                        if (
                          ownedProfile &&
                          navItem === OFFERS_DROPDOWN_ITEMS.RECEIVED
                        ) {
                          acceptOffer(rowData, i)
                          return
                        }
                        cancelOffer(rowData, i)
                      }}
                    >
                      {canceling === i || accepting === i ? (
                        <>
                          <>Pending</>
                          <LoadingSpinner margin="0 0 0 20px" />
                        </>
                      ) : ownedProfile &&
                        navItem === OFFERS_DROPDOWN_ITEMS.RECEIVED ? (
                        'Accept'
                      ) : (
                        'Cancel'
                      )}
                    </RoundedButton>
                  )
                }}
              </Cell>
            </Column>
          </Table>
        )
      )}
    </>
  )
}

export default ProfileOffers
