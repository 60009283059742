import styled from '@emotion/styled/macro'
import Space from 'components/Space'
import View from 'components/View'
import { useState } from 'react'
import Activities from './components/Activities'
import Offers from './components/Offers'

const TABS = ['Offers', 'Activity']

const Section = ({
  activityData,
  offers,
  cancelOffer,
  setHighestOffer,
  canceling,
  acceptOffer,
  accepting,
  ownedDomain,
  isRegistered,
  bodyColor,
  bodyBg,
  bodyBorder
}) => {
  const [tab, setTab] = useState(TABS[1])

  return (
    <div
      style={{
        gridColumnStart: 'span 2',
        width: '100%',
        borderRadius: '10px',
        border: `1px solid ${bodyBorder}`,
        padding: '20px 15px',
        direction: 'column',
        overscrollBehavior: 'contain'
      }}
    >
      <TabsContainer>
        {isRegistered
          ? TABS.map(t => (
              <Tab
                active={tab === t}
                onClick={() => setTab(t)}
                key={t}
                background="#fff"
                color="#020202"
              >
                {t}
              </Tab>
            ))
          : [TABS[1]].map(t => (
              <Tab
                active={tab === t}
                onClick={() => setTab(t)}
                key={t}
                background="#020202"
                color="#fff"
              >
                {t}
              </Tab>
            ))}
      </TabsContainer>
      <Space size={20} />

      <View width="100%" padding="0 20px">
        {tab === TABS[0] && isRegistered && (
          <Offers
            parsedOffers={offers}
            cancelOffer={cancelOffer}
            navItem={tab}
            setHighestOffer={setHighestOffer}
            canceling={canceling}
            acceptOffer={acceptOffer}
            accepting={accepting}
            ownedDomain={ownedDomain}
          />
        )}
        {tab === TABS[1] && (
          <Activities activityData={activityData} isRegistered={isRegistered} />
        )}
      </View>
    </div>
  )
}

export default Section

export const Tab = styled('div')`
  font-size: 16px;
  background: ${props => props.background};
  color: ${({ active, color }) => (active ? color : '#BCBCCC')};
  transition: all 0.1s ease-out;
  padding: 17px 20px;
  border-radius: ${({ active }) => (active ? '6px' : '0')};
  flex: 1;
  font-weight: 600;
  display: flex;
  font-family: 'Satoshi', sans-serif;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:hover,
  &:visited {
    cursor: pointer;
  }
`

export const TabsContainer = styled('div')`
  display: inline-flex;
  overflow: hidden;
`
