import styled from '@emotion/styled/macro'
import { ReactComponent as DoneWhite } from 'assets/done-white.svg'
import { ReactComponent as Done } from 'assets/done.svg'

export const Checkbox = ({ isChecked, onCheck, className, darkMode }) => {
  return (
    <Container onClick={onCheck} className={className} darkMode={darkMode}>
      {isChecked && (darkMode ? <DoneWhite /> : <Done />)}
    </Container>
  )
}

const Container = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 4px;
  border: ${props =>
    props.darkMode ? '1px solid #4A4A4D' : '1px solid #e1e1e8'};
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`
