import { isShortName } from '../../utils/utils'
import getENS, { getRegistrar } from 'apollo/mutations/ens'
import modeNames from '../modes'
import { sendHelper, sendHelperBlocking } from '../resolverUtils'
import { tagAddress } from 'utils/address'
import { getAccount, getSigner } from '@ensdomains/ui'

const resolvers = {
  Query: {
    async getRentPrice(_, { label, duration }) {
      const registrar = getRegistrar()
      return registrar.getRentPrice(label, duration)
    },
    async getPremium(_, { name, expires, duration }) {
      const registrar = getRegistrar()
      return registrar.getPremium(name, expires, duration)
    },
    async getTimeUntilPremium(_, { expires, amount }) {
      const registrar = getRegistrar()
      return registrar.getTimeUntilPremium(expires, amount)
    },

    async getMinimumCommitmentAge() {
      try {
        const registrar = getRegistrar()
        const minCommitmentAge = await registrar.getMinimumCommitmentAge()
        return parseInt(minCommitmentAge)
      } catch (e) {
        return 61
      }
    },
    async getMaximumCommitmentAge() {
      try {
        const registrar = getRegistrar()
        const maximumCommitmentAge = await registrar.getMaximumCommitmentAge()
        return parseInt(maximumCommitmentAge)
      } catch (e) {
        console.log(e)
      }
    },
    async checkCommitment(_, { label, secret }) {
      try {
        const registrar = getRegistrar()
        const commitment = await registrar.checkCommitment(label, secret)
        return parseInt(commitment)
      } catch (e) {
        console.log(e)
      }
    }
  },
  Mutation: {
    async commit(_, { label, secret }) {
      const registrar = getRegistrar()
      const tx = await registrar.commit(label, secret)
      return sendHelperBlocking(tx)
    },
    async register(_, { label, duration, secret }) {
      const signer = await getSigner()
      const account = await getAccount()
      const registrar = getRegistrar()
      const permanentRegistrarControllerWithoutSigner =
        registrar.permanentRegistrarController
      const permanentRegistrarController = permanentRegistrarControllerWithoutSigner.connect(
        signer
      )
      const resolverAddr = await registrar.getAddress('resolver.eth')
      const price = await registrar.getRentPrice(label, duration)
      const priceWithBuffer = price.mul(1150).div(1000)

      let tx
      if (parseInt(resolverAddr, 16) === 0) {
        const gasLimit = await registrar.estimateGasLimit(() => {
          return permanentRegistrarController.estimateGas.register(
            label,
            account,
            duration,
            secret,
            { value: priceWithBuffer }
          )
        })

        tx = await permanentRegistrarController.populateTransaction.register(
          label,
          account,
          duration,
          secret,
          { value: priceWithBuffer, gasLimit }
        )
      } else {
        const gasLimit = await registrar.estimateGasLimit(() => {
          return permanentRegistrarController.estimateGas.registerWithConfig(
            label,
            account,
            duration,
            secret,
            resolverAddr,
            account,
            { value: priceWithBuffer }
          )
        })

        tx = await permanentRegistrarController.populateTransaction.registerWithConfig(
          label,
          account,
          duration,
          secret,
          resolverAddr,
          account,
          { value: priceWithBuffer, gasLimit }
        )
      }

      tx.data = tagAddress(
        tx.data,
        '0x6b6f646578000000000000000000000000000000000000000000000000000000'
      )

      const signedTx = await signer.sendTransaction(tx)
      return sendHelperBlocking(signedTx)
    },
    async reclaim(_, { name, address }) {
      const registrar = getRegistrar()
      const tx = await registrar.reclaim(name, address)
      return sendHelper(tx)
    },
    async renew(_, { label, duration }) {
      const registrar = getRegistrar()
      const tx = await registrar.renew(label, duration)
      return sendHelper(tx)
    },
    async getDomainAvailability(_, { name }) {
      const registrar = getRegistrar()
      const ens = getENS()
      try {
        const {
          state,
          registrationDate,
          revealDate,
          value,
          highestBid
        } = await registrar.getEntry(name)
        let owner = null
        if (isShortName(name)) {
          cache.writeData({
            data: defaults
          })
          return null
        }

        if (modeNames[state] === 'Owned') {
          owner = await ens.getOwner(`${name}.eth`)
        }

        const data = {
          domainState: {
            name: `${name}.eth`,
            state: modeNames[state],
            registrationDate,
            revealDate,
            value,
            highestBid,
            owner,
            __typename: 'DomainState'
          }
        }

        cache.writeData({ data })

        return data.domainState
      } catch (e) {
        console.log('Error in getDomainAvailability', e)
      }
    },
    async setRegistrant(_, { name, address }) {
      const registrar = getRegistrar()
      const tx = await registrar.transferOwner(name, address)
      return sendHelperBlocking(tx)
    },
    async submitProof(_, { name, parentOwner }) {
      const registrar = getRegistrar()
      const tx = await registrar.submitProof(name, parentOwner)
      return sendHelper(tx)
    },
    async renewDomains(_, { labels, duration }) {
      const registrar = getRegistrar()
      const tx = await registrar.renewAll(labels, duration)
      return sendHelper(tx)
    }
  }
}

export default resolvers
