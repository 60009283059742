import React from 'react'
import RoundedButton from 'components/Forms/RoundedButton'
import View from 'components/View'
import Label from 'components/Label'
import { Checkbox } from './Checkbox'

const OptionSelect = ({ label, onClick, isChecked, isRadio, disabled }) => {
  return (
    <RoundedButton
      width="100%"
      height="60px"
      padding="30px 20px"
      onClick={disabled ? null : onClick}
    >
      <View width="100%" justify="space-between" alignItems="center">
        <Label size={16} color={disabled ? '#BCBCBC' : '#020202'}>
          {label}
        </Label>
        <Checkbox isChecked={isChecked} isRadio={isRadio} />
      </View>
    </RoundedButton>
  )
}

export default OptionSelect
