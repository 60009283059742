import Link from 'components/Forms/Link'
import Label from 'components/Label'
import View from 'components/View'
import { CATEGORY_PRIORITY_LIST, TAGS } from 'constants/category'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'

const CategoriesBox = ({
  categories,
  boxBg,
  isWhiteStyle,
  boxBorderColor = 'transparent',
  tooltipBorderColor = 'transparent',
  bodyColor,
  width,
  positionTop,
  positionRight,
  hashtagFontSize,
  fontSize
}) => {
  if (!categories) return <></>

  const [categoryHovered, setCategoryHovered] = useState(false)

  const history = useHistory()

  const sortedCategories = useMemo(() => {
    if (!categories) return []
    const categoriesArrCopy = categories
    const sortedCategoriesIndexes = categoriesArrCopy
      .map(c => ({
        index: CATEGORY_PRIORITY_LIST.indexOf(c),
        category: c
      }))
      .sort((a, b) => a.index - b.index)

    return sortedCategoriesIndexes.map(obj => obj.category)
  }, [categories])

  const handleGoSubCategory = useCallback(
    (e, category) => {
      e.stopPropagation()
      if (!category) return

      const categoryKey = category
        .toLowerCase()
        .split(' ')
        .join('-')

      TAGS.forEach(tag => {
        if (tag.key.toLowerCase() === categoryKey.toLowerCase()) {
          window.scrollTo({ top: 0, behavior: 'instant' })
          history.push(`/marketplace/${tag.key.toLowerCase()}`)
        } else if (tag.subcategories.length > 0) {
          tag.subcategories.forEach(subCategory => {
            if (subCategory.key.toLowerCase() === categoryKey.toLowerCase()) {
              window.scrollTo({ top: 0, behavior: 'instant' })
              history.push(`/marketplace/${tag.key.toLowerCase()}`)
            }
          })
        }
      })
    },
    [categories]
  )

  const defaultTooltipStyles = {
    display: categoryHovered ? 'flex' : 'none',
    position: 'absolute',
    zIndex: 100000
  }

  const tooltipPositionStyle = positionTop
    ? {
        left: '0',
        bottom: '100%',
        paddingBottom: '5px'
      }
    : positionRight
    ? {
        left: ' 90%',
        paddingLeft: '0.8em'
      }
    : {
        top: '0',
        right: '90%',
        paddingRight: '0.8em'
      }

  return (
    <View
      position="relative"
      zIndex={100000}
      onMouseEnter={() => setCategoryHovered(true)}
      onMouseLeave={() => setCategoryHovered(false)}
    >
      <div
        style={{
          ...defaultTooltipStyles,
          ...tooltipPositionStyle,
          zIndex: 1000000
        }}
      >
        <View
          zIndex={1000000}
          background={isWhiteStyle ? '#fff' : '#020202'}
          style={{ padding: 'clamp(10px,0.5vw,20px)' }}
          border={`1px solid ${tooltipBorderColor}`}
          borderRadius="6px"
          alignItems="flex-start"
          direction="column"
          gap="5px"
          justify="space-between"
          position="relative"
          whiteSpace="nowrap"
        >
          {categories.slice(0, 3).map((category, index) => {
            return (
              <Link
                style={{ height: '20px' }}
                key={index}
                hoverOpacity={0.6}
                onClick={e => {
                  handleGoSubCategory(e, category)
                }}
              >
                <Label
                  whiteSpace="nowrap"
                  color={MAIN_CATEGORY_COLORS[index % 11]}
                  style={{
                    fontSize: fontSize ? fontSize : 'clamp(12px,0.9vw,17px)'
                  }}
                >
                  {category}
                </Label>
              </Link>
            )
          })}
          {categories.length > 3 && (
            <Label color={bodyColor || '#000'}>...</Label>
          )}
        </View>
      </div>
      <View
        width={width || '3vw'}
        zIndex={-10000}
        style={!width ? { maxWidth: '40px', maxHeight: '40px' } : {}}
        alignItems="center"
        justify="center"
        height={width || '3vw'}
        borderRadius="6px"
        background={boxBg}
        position="relative"
        border={`1px solid ${boxBorderColor}`}
        cursor="default"
      >
        <Label size={hashtagFontSize || 16} color={bodyColor || '#fff'}>
          #
        </Label>
      </View>
    </View>
  )
}

export default CategoriesBox
