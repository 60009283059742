import styled from '@emotion/styled/macro'
import { ReactComponent as EthPriceLight } from 'assets/eth-price-white.svg'
import { ReactComponent as Trash } from 'assets/trash.svg'
import Label from 'components/Label'
import View from 'components/View'

const ListingRow = ({
  domain,
  removeDomain,
  index,
  listingAmounts,
  setListingAmounts
}) => {
  return (
    <View width="100%" direction="row" alignItems="center">
      <View
        width="90%"
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <View width="70%">
          <Label color="black" size={16} width="11.5vw" whiteSpace="nowrap">
            {domain.name}
          </Label>
        </View>
        <View
          width="30%"
          direction="row"
          alignItems="center"
          gap="1rem"
          background="#F3F3FA"
          borderRadius="6px"
          border="1px solid #E1E1E8"
          padding="0.7rem"
        >
          <EthPriceLight />
          <StyledInput
            value={listingAmounts[index] || ''}
            placeholder={'0.00'}
            type="number"
            onChange={e => {
              const listingAmountsCopy = listingAmounts
              listingAmountsCopy[index] = e.target.value
              setListingAmounts([...listingAmountsCopy])
            }}
            min={0}
          />
        </View>
      </View>
      <View
        justify="center"
        alignItems="center"
        width="10%"
        onClick={() => removeDomain(domain.name)}
      >
        <Trash style={{ cursor: 'pointer' }} />
      </View>
    </View>
  )
}

const StyledInput = styled.input`
  border: none;
  outline: none;
  font-size: 20px;
  line-height: 30px;
  color: #212121;
  background: transparent;
  width: 60%;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export default ListingRow
