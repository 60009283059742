import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import ReactDOM from 'react-dom'
import View from '../View'
import Label from '../Label'
import styled from '@emotion/styled/macro'
import Overlay from '../Overlay'
import { ModalContext } from 'contexts/ModalProvider'

const BottomModal = ({
  open,
  onClose,
  children,
  modalId = 'modal-root',
  contentPadding,
  contentBackground,
  closeButtonLabel = 'Close',
  transparent
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('transparent')
  const modalRoot = document.getElementById(modalId)

  const modalCtx = useContext(ModalContext)

  const isInner = useMemo(() => modalId !== 'modal-root', [])
  const contentRef = useRef(null)

  useEffect(() => {
    modalCtx.setClosable(true)
    if (!open) {
      setTimeout(() => {
        setModalOpen(open)
        setBackgroundColor('transparent')
      }, 450)
    } else {
      setModalOpen(open)
      setBackgroundColor(contentBackground)
    }
  }, [open])

  const handleClose = useCallback(() => {
    setModalOpen(false)
    setTimeout(onClose, 450)
  }, [])

  return ReactDOM.createPortal(
    <ModalWrapper open={open || modalOpen}>
      <Overlay
        transparent={transparent}
        open={modalOpen}
        show
        onClick={() => {
          if (modalCtx.closable) handleClose()
        }}
        isInner={isInner}
      >
        {!isInner && !transparent && (
          <View justify="center" width="100%" height="60px" alignItems="center">
            <Label color="rgba(255, 255, 255, 0.5)" size={16}>
              {closeButtonLabel}
            </Label>
          </View>
        )}
      </Overlay>
      <OverlayContent
        ref={contentRef}
        open={modalOpen}
        onClick={event => {
          event.stopPropagation()
          if (transparent) {
            handleClose()
          }
        }}
        padding={transparent ? '0' : contentPadding}
        background={transparent ? 'transparent' : backgroundColor}
        transparent={transparent}
      >
        {children}
      </OverlayContent>
    </ModalWrapper>,
    modalRoot
  )
}

const OverlayContent = styled.div`
  width: 100%;
  z-index: 10000;
  position: absolute;
  display: ${props => (props.transparent ? 'flex' : 'block')};
  height: ${props => (props.transparent ? '100vh' : 'calc(100vh - 60px)')};
  background-color: ${props => (props.background ? props.background : '#fff')};
  transform: ${props => (props.open ? 'translateY(-100vh)' : 'translateY(0)')};
  top: ${props => (props.transparent ? '100vh' : 'calc(100vh + 60px)')};
  left: 0;
  transition: transform 0.45s ease;
  border-radius: 10px 10px 0 0;
  justify-content: ${props => props.transparent && 'center'};
  align-items: ${props => props.transparent && 'center'};
  padding: ${props => (props.padding ? props.padding : '80px 0 0')};
`

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
  display: ${props => (props.open ? 'block' : 'none')};
`

export default BottomModal
