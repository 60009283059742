import gql from 'graphql-tag'
import { LikesFields } from './fragments'

export const GET_RANKED_DOMAINS = gql`
  query getDomains(
    $offset: Int
    $limit: Int
    $orderBy: [ranked_domains_view_order_by!]
    $where: ranked_domains_view_bool_exp
  ) {
    domains: ranked_domains_view(
      offset: $offset
      limit: $limit
      order_by: $orderBy
      where: $where
    ) {
      name: domain
      expire_time
      taxonomies
      terms
      last_price
      last_sale_asset
      likes
      listing_time
      listing_start_price
      has_offers
    }
  }
`

export const GET_SEARCH_DOMAINS = gql`
  query getDomainsAdvanced(
    $name_pattern_search: String
    $search_type: search_type_enum
    $order_type: order_type_enum
    $status_type: status_type_enum
    $date_status: date_status_enum
    $has_offers_selector: _has_offers_enum
    $taxonomies: _text
    $terms: _text
    $min_domain_length: Int
    $max_domain_length: Int
    $min_listing_price: numeric
    $max_listing_price: numeric
    $name_result: name_result_enum
    $name_symbols_type: _name_symbols_type_enum
    $offset: Int
    $limit: Int
  ) {
    domains: search_domains(
      args: {
        name: $name_pattern_search
        search_type: $search_type
        order_type: $order_type
        status_type: $status_type
        date_status: $date_status
        has_offers_selector: $has_offers_selector
        search_taxa: $taxonomies
        search_terms: $terms
        min_domain_length: $min_domain_length
        max_domain_length: $max_domain_length
        min_listing_price: $min_listing_price
        max_listing_price: $max_listing_price
        name_result: $name_result
        name_symbols_type: $name_symbols_type
        limit: $limit
        offset: $offset
      }
    ) {
      name: domain
      expire_time
      taxonomies
      terms
      last_price
      last_sale_asset
      likes
      listing_time
      listing_start_price
      has_offers
    }
  }
`

export const GET_SEARCH_DOMAINS_COUNT = gql`
  query getDomainsAdvanced(
    $name_pattern_search: String
    $search_type: search_type_enum
    $order_type: order_type_enum
    $status_type: status_type_enum
    $date_status: date_status_enum
    $has_offers_selector: _has_offers_enum
    $taxonomies: _text
    $terms: _text
    $min_domain_length: Int
    $max_domain_length: Int
    $min_listing_price: numeric
    $max_listing_price: numeric
    $name_result: name_result_enum
    $name_symbols_type: _name_symbols_type_enum
    $offset: Int
    $limit: Int
  ) {
    domains: search_domains_aggregate(
      args: {
        name: $name_pattern_search
        search_type: $search_type
        order_type: $order_type
        status_type: $status_type
        date_status: $date_status
        has_offers_selector: $has_offers_selector
        search_taxa: $taxonomies
        search_terms: $terms
        min_domain_length: $min_domain_length
        max_domain_length: $max_domain_length
        min_listing_price: $min_listing_price
        max_listing_price: $max_listing_price
        name_result: $name_result
        name_symbols_type: $name_symbols_type
        limit: $limit
        offset: $offset
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_DOMAIN = gql`
  query getDomain($name: String = "") {
    domains(where: { name: { _eq: $name } }) {
      name
      tokenid
      expires
      domains_taxonomies {
        taxonomy
      }
      domains_terms {
        term
      }
      likes_aggregate {
        aggregate {
          count
        }
      }
      ens_sales {
        saleasset
        saleassetamount
      }
    }
  }
`

export const GET_DOMAINS_BY_TOKENID = gql`
  query getDomains($id: [String] = [""]) {
    domains(where: { tokenid: { _in: $id } }) {
      name
      tokenid
      expires
      domains_taxonomies {
        taxonomy
      }
      domains_terms {
        term
      }
      likes_aggregate {
        aggregate {
          count
        }
      }
      ens_sales {
        saleasset
        saleassetamount
      }
    }
  }
`

export const GET_DOMAINS = gql`
  query getDomains($domains: [String] = [""]) {
    domains(where: { name: { _in: $domains } }) {
      name
      tokenid
      expires
      domains_taxonomies {
        taxonomy
      }
      domains_terms {
        term
      }
      likes_aggregate {
        aggregate {
          count
        }
      }
      ens_sales {
        saleasset
        saleassetamount
      }
    }
    likes: ranked_domains_view(where: { domain: { _in: $domains } }) {
      domain
      likes
    }
  }
`

export const GET_CART_DOMAINS_DATA = gql`
  query getDomains($domains: [String] = [""]) {
    domains(where: { name: { _in: $domains } }) {
      name
      tokenid
      expire_time: expires
      domains_taxonomies {
        taxonomy
      }
      ens_sales {
        saleasset
        saleassetamount
      }
    }
  }
`

export const GET_DOMAINS_LIKES = gql`
  query getDomainsLikes($domains: [String] = [""]) {
    likes(where: { domain: { _in: $domains } }) {
      ...LikesFields
    }
  }

  ${LikesFields}
`

export const GET_DOMAIN_LIST = gql`
  query getDomainList($names: [String!]) {
    domains(where: { name: { _in: $names } }) {
      name
      tokenid
      expires
      domains_taxonomies {
        taxonomy
      }
      domains_terms {
        term
      }
      likes_aggregate {
        aggregate {
          count
        }
      }
      ens_sales {
        saleasset
        saleassetamount
      }
    }
  }
`

export const GET_DOMAIN_VIEWS = gql`
  query getDomainViews($name: String!) {
    domains(where: { name: { _eq: $name } }) {
      name
      tokenid
      views
    }
  }
`

export const GET_TERMS_COUNT = gql`
  query getTermsCount($terms: String!) {
    domains_terms_aggregate(where: { term: { _eq: $terms } }) {
      aggregate {
        count(columns: term)
      }
    }
  }
`

export const GET_TAXONOMIES_COUNT = gql`
  query getTermsCount($taxonomy: String!) {
    domains_taxonomies_aggregate(where: { taxonomy: { _eq: $taxonomy } }) {
      aggregate {
        count
      }
    }
  }
`

export const GET_CATEGORIES = gql`
  query getCategories(
    $offset: Int
    $limit: Int
    $where: domains_taxonomies_bool_exp
  ) {
    domains_taxonomies(offset: $offset, limit: $limit, where: $where) {
      domain
      taxonomy
    }
  }
`

export const GET_TERMS_TAXONOMIES = gql`
  query getTermsTaxonomies($offset: Int, $limit: Int) {
    taxonomies(offset: $offset, limit: $limit) {
      terms_taxonomies {
        term
        taxonomy
      }
    }
  }
`

export const GET_DOMAIN_SUBCATEGORIES = gql`
  query getDomainTerms($domain: String!) {
    ranked_domains_view(where: { domain: { _eq: $domain } }) {
      name: domain
      taxonomies
      terms
    }
  }
`

export const GET_ENS_PAST_SALES = gql`
  query getENSPastSales($name: String) {
    ens_past_sales(
      where: { ensdomain: { _eq: $name } }
      order_by: { timestamp: desc }
    ) {
      price
      timestamp
    }
  }
`

export const GET_ENS_DOMAIN_OWNER = gql`
  query getENSDomainOwner($label: String) {
    ens_ {
      registrations(where: { labelName: $label }) {
        id
        registrant {
          id
        }
      }
    }
  }
`

export const GET_FEED_EVENT_QUERY = gql`
  query getFeedQuery(
    $taxonomies: _text
    $terms: _text
    $min_domain_length: Int
    $max_domain_length: Int
    $min_price: numeric
    $max_price: numeric
    $search_asset: String
    $min_timestamp: timestamp
    $max_timestamp: timestamp
    $event_types: _feed_event_enum
    $limit: Int
    $offset: Int
  ) {
    get_feed(
      args: {
        search_taxa: $taxonomies
        search_terms: $terms
        min_domain_length: $min_domain_length
        max_domain_length: $max_domain_length
        min_price: $min_price
        max_price: $max_price
        search_asset: $search_asset
        min_timestamp: $min_timestamp
        max_timestamp: $max_timestamp
        event_types: $event_types
        limit: $limit
        offset: $offset
      }
    ) {
      domain
      price
      asset
      timestamp
      end_time
      event_type
      fromaddr
      toaddr
    }
  }
`
export const GET_FEED_AGGREGATION = gql`
  query GetFeedAggregation(
    $time_range: feed_agg_time_range_enum
    $search_taxa: _text
    $search_terms: _text
    $max_domain_length: Int
    $min_domain_length: Int
    $min_price: numeric
    $max_price: numeric
    $search_asset: String
    $min_timestamp: timestamp
    $max_timestamp: timestamp
    $event_types: _feed_event_enum
  ) {
    get_feed_agg(
      args: {
        search_taxa: $search_taxa
        search_terms: $search_terms
        min_domain_length: $min_domain_length
        max_domain_length: $max_domain_length
        min_price: $min_price
        max_price: $max_price
        min_timestamp: $min_timestamp
        max_timestamp: $max_timestamp
        search_asset: $search_asset
        time_range: $time_range
        event_types: $event_types
      }
    ) {
      price_avg
      price_cnt
      price_sum
      event_type
      epoch_part
    }
  }
`

export const GET_DOMAIN_PRICE_HISTORY = allTime => {
  const args = allTime ? '' : ', $min_date: timestamp, $max_date: timestamp'
  const filterDates = allTime ? '' : '_gte: $min_date, _lte: $max_date'
  return gql`
     query DomainPriceHistory($name: String ${args}) {
      ens_sales(where: {
        domain: {_eq: $name}, 
        timestamp: { ${filterDates} }}) {
        domain
        price
        saleasset
        saleassetamount
        timestamp
      }
  }
  `
}

export const GET_DOMAIN_ACTIVITY_QUERY = gql`
  query getDomainActivity($domain: String!, $limit: Int, $offset: Int) {
    get_domain_activity(
      args: { domain_name: $domain, limit: $limit, offset: $offset }
    ) {
      timestamp
      event_type
      price
      asset
      fromaddr
      toaddr
    }
  }
`

export const GET_PROFILE_ACTIVITY_QUERY = gql`
  query getProfileActivity($user: String!, $limit: Int, $offset: Int) {
    get_profile_activity(
      args: { user: $user, limit: $limit, offset: $offset }
    ) {
      timestamp
      event_type
      domain
      price
      asset
      fromaddr
      toaddr
    }
  }
`

export const GET_LABEL_NAME_FROM_HASH = gql`
  query getLabelNames($hashes: [String!]) {
    domains(
      where: {
        labelhash_in: $hashes
        parent_: {
          id: "0x93cdeb708b7545dc668eb9280176169d1c33cfd8ed6f04690a0bcc88a93fc4ae"
        }
      }
    ) {
      name
      labelName
    }
  }
`

export const GET_DOMAINS_AMOUNT = gql`
  query getNumberOfDomains {
    domains_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const GET_CATEGORY_FLOOR_PRICE = gql`
  query getOffersFloorByTaxonomy(
    $search_taxa: _text = null
    $search_terms: _text = null
  ) {
    get_feed_aggregate(
      args: {
        event_types: "{offer}"
        search_taxa: $search_taxa
        search_terms: $search_terms
        min_domain_length: null
        max_domain_length: null
        min_price: null
        max_price: null
        min_timestamp: null
        max_timestamp: null
        search_asset: null
        limit: null
        offset: null
      }
    ) {
      aggregate {
        min {
          price
        }
      }
    }
  }
`
