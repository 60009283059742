const getOrCreateTooltip = (chart, darkMode) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.background = darkMode ? '#020202' : '#fff'
    tooltipEl.style.borderRadius = '10px'
    tooltipEl.style.border = `1px solid ${darkMode ? '#4A4A4D' : '#E1E1E8'}`
    tooltipEl.style.opacity = '1'
    tooltipEl.style.padding = '12px 20px'
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, -110%)'
    tooltipEl.style.transition =
      'all .8s cubic-bezier( 0.190,  1.000,  0.220,  1.000 ), opacity .2s ease'
    tooltipEl.style.fontFamily = 'Satoshi'
    tooltipEl.style.width = '250px'
    tooltipEl.style.zIndex = '5'
    tooltipEl.style.fontFamily = 'Satoshi'

    const table = document.createElement('div')
    table.className = 'table'
    table.style.margin = '0px'

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

const createBottomDash = (chart, todayLabel, darkMode, fromModal) => {
  const title = chart.tooltip.title[0]
  const previousDashes = chart.canvas.parentNode.querySelectorAll(
    `[data-title]`
  )

  previousDashes.forEach(dash => {
    if (dash.dataset.title !== todayLabel) {
      dash.style.opacity = 0
    }
    dash.style.bottom = '120px'
  })

  let bottomDash = chart.canvas.parentNode.querySelector(
    `[data-title="${title}"]`
  )

  if (!bottomDash) {
    bottomDash = createDashElement(
      title,
      title === todayLabel,
      darkMode,
      fromModal
    )

    chart.canvas.parentNode.appendChild(bottomDash)
  }

  return bottomDash
}

const createDashElement = (title, isToday, darkMode, fromModal) => {
  const bottomDash = document.createElement('div')
  let bg
  if (darkMode) {
    if (isToday) {
      bg = '#045C66'
    } else {
      bg = '#808080'
    }
  } else {
    if (isToday) {
      bg = '#82CBD3'
    } else {
      bg = '#D7D7E0'
    }
  }
  bottomDash.dataset.title = title
  bottomDash.style.width = fromModal ? '36px' : '51px'
  bottomDash.style.height = '5px'
  bottomDash.style.backgroundColor = bg
  bottomDash.style.zIndex = '10000'
  bottomDash.style.position = 'absolute'
  bottomDash.style.transform = 'translateX(-50%)'
  bottomDash.style.borderRadius = '4px'
  bottomDash.style.opacity = '0'
  bottomDash.style.bottom = '120px'
  bottomDash.style.transition = 'all .35s ease'
  return bottomDash
}

export const externalTooltipHandler = (
  context,
  todayPrice,
  todayLabel,
  darkMode,
  fromModal,
  currency = 'ETH'
) => {
  // Tooltip Element
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart, darkMode)
  const dash = createBottomDash(chart, todayLabel, darkMode, fromModal)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  // Set Text
  if (tooltip.body) {
    const header = createHeader(tooltip, todayPrice, darkMode, currency)
    const data = createData(tooltip, darkMode)

    const tableRoot = tooltipEl.querySelector('.table')

    // Remove old children
    while (tableRoot.firstChild ?? false) {
      tableRoot.firstChild.remove()
    }

    // Add new children
    tableRoot.appendChild(header)
    tableRoot.appendChild(data)
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1'
  tooltipEl.style.left = positionX + tooltip.caretX + 'px'
  tooltipEl.style.top = positionY + tooltip.caretY + 'px'
  tooltipEl.style.font = tooltip.options.bodyFont.string
  tooltipEl.style.fontFamily = 'Satoshi'

  dash.style.left = positionX + tooltip.caretX + 'px'
  dash.style.bottom = '125px'
  dash.style.opacity = 1
}

function createHeader(tooltip, todayPrice, darkMode, currency) {
  const selectedPrice = tooltip.dataPoints[0].raw
  const header = document.createElement('div')
  header.style.display = 'flex'
  header.style.alignItems = 'center'
  header.style.marginBottom = '8px'
  header.style.justifyContent = 'space-between'
  header.style.width = '100%'
  header.style.fontFamily = 'Satoshi'

  const value = document.createElement('span')
  value.style.fontWeight = '600'
  value.style.fontSize = '16px'
  value.style.color = darkMode ? '#fff' : '#020202'
  value.innerHTML = `${Number(selectedPrice).toLocaleString('default', {
    maximumFractionDigits: 2
  })} ${currency}`
  value.style.fontFamily = 'Satoshi'

  const deltaPercentageElem = document.createElement('span')

  let deltaPercentageLabel = ''
  if (todayPrice !== selectedPrice) {
    const deltaPercentage = selectedPrice / todayPrice
    if (deltaPercentage < 1) {
      deltaPercentageLabel = `-${Number(
        ((1 - deltaPercentage) * 100).toFixed(2)
      )}%`
      deltaPercentageElem.style.color = '#0698A8'
    } else {
      deltaPercentageLabel = `+${Number(
        ((deltaPercentage - 1) * 100).toFixed(2)
      )}%`
      deltaPercentageElem.style.color = '#AF61AB'
    }
  }

  deltaPercentageElem.style.fontSize = '16px'
  deltaPercentageElem.style.fontWeight = '600'
  deltaPercentageElem.innerHTML = deltaPercentageLabel
  deltaPercentageElem.style.fontFamily = 'Satoshi'

  header.appendChild(value)
  header.appendChild(deltaPercentageElem)

  return header
}

function createData(tooltip, darkMode) {
  const date = document.createElement('span')
  date.style.color = darkMode ? '#9A9A9A' : '#BCBCCC'
  date.style.fontSize = '13px'
  date.style.fontWeight = '300'
  date.innerHTML = tooltip.title[0]

  return date
}
