import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import View from 'components/View'
import React from 'react'
import RegistrationSuccessfullImage from 'assets/registration-successfull.png'

export default function AcceptSuccessModal({ onClose }) {
  return (
    <View
      direction="column"
      height="100%"
      width="100%"
      background="#0698A8"
      overflow="hidden"
      justify="center"
      alignItems="center"
      gap="5vh"
    >
      <View>
        <img src={RegistrationSuccessfullImage} />
      </View>
      <View direction="column" gap="3vh" justify="center" alignItems="center">
        <Label size={40} color="#fff">
          Offer Completed
        </Label>
        <Label size={20} color="#fff">
          Domain sold successfully.
        </Label>
      </View>
      <RoundedButton
        background="#fff"
        hoverBackground="#ddd"
        padding="1.5rem 1rem"
        width="33vw"
        onClick={() => onClose()}
      >
        <Label size={20} color="#000">
          Go back
        </Label>
      </RoundedButton>
    </View>
  )
}
