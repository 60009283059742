import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import View from 'components/View'
import React from 'react'
import RegistrationSuccessfullImage from 'assets/registration-successfull.png'

export default function RegistrationSuccessfull({
  closeLabel,
  closeRegistration,
  refetchState
}) {
  return (
    <View
      direction="column"
      height="95.5vh"
      width="100%"
      padding="8vh 0 2rem 0"
      background="#0698A8"
      overflow="auto"
      justify="center"
      alignItems="center"
      gap="5vh"
    >
      <img src={RegistrationSuccessfullImage} />
      <View direction="column" gap="3vh" justify="center" alignItems="center">
        <Label size={40} color="#fff">
          Complete
        </Label>
        <Label size={20} color="#fff8">
          Domain registered successfully.
        </Label>
      </View>
      <RoundedButton
        background="#fff"
        hoverBackground="#ddd"
        padding="1.5rem 1rem"
        width="33vw"
        onClick={() => {
          refetchState()
          closeRegistration()
        }}
      >
        <Label size={20} color="#000">
          {closeLabel || 'Go back'}
        </Label>
      </RoundedButton>
    </View>
  )
}
