import React from 'react'
import styled from '@emotion/styled'
import KodexIcon from 'components/Icon/KodexIcon'
import { ReactComponent as Done } from 'assets/done.svg'

const DoneIcon = ({ selected }) => {
  return (
    <DoneIconWrapper>
      <KodexIcon icon={selected ? <Done /> : <div />} size={10} padding={0} />
    </DoneIconWrapper>
  )
}

const DoneIconWrapper = styled.div`
  padding: 7px;
  border: 1px solid #f3f3fa;
  border-radius: 4px;
`

export default DoneIcon
