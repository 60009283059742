import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split
} from '@apollo/client'
import { siweAuthedFetch } from 'service/siwe/siweFetch'
import Observable from 'zen-observable'
import resolvers from '../api/rootResolver'
import { networkIdReactive } from './reactiveVars'
import typePolicies from './typePolicies'

let client

const cache = new InMemoryCache({
  typePolicies
})

const endpoints = {
  '1': 'https://api.thegraph.com/subgraphs/name/ensdomains/ens',
  '3': 'https://api.thegraph.com/subgraphs/name/ensdomains/ensropsten',
  '4': 'https://api.thegraph.com/subgraphs/name/ensdomains/ensrinkeby',
  '5': 'https://api.thegraph.com/subgraphs/name/ensdomains/ensgoerli'
}

function getGraphQLAPI() {
  const network = networkIdReactive()

  if (endpoints[network]) {
    return endpoints[network]
  }

  return endpoints['1']
}

function fromPromise(promise, operation) {
  return new Observable(observer => {
    promise
      .then(value => {
        operation.setContext({ response: value })
        observer.next({
          data: { [operation.operationName]: value },
          errors: []
        })
        observer.complete()
      })
      .catch(observer.error.bind(observer))
  })
}

const kodexApiLink = new HttpLink({
  uri: process.env.REACT_APP_HASURA_GRAPHQL_URL,
  credentials: 'include',
  fetchOptions: {
    mode: 'cors',
    credentials: 'include'
  },
  fetch: (uri, options) => {
    return siweAuthedFetch(uri, options)
  }
})

export function setupClient() {
  const httpLink = new HttpLink({
    uri: () => getGraphQLAPI()
  })

  const web3Link = new ApolloLink(operation => {
    const { variables, operationName } = operation

    if (resolvers.Query[operationName]) {
      return fromPromise(
        resolvers.Query[operationName]?.apply(null, [null, variables]),
        operation
      )
    }

    return fromPromise(
      resolvers.Mutation[operationName]?.apply(null, [null, variables]),
      operation
    )
  })

  const splitLink = split(
    operation => {
      return operation.getContext().source === 'kodex-api'
    },
    kodexApiLink,
    split(
      ({ operationName }) => {
        return (
          resolvers.Query[operationName] || resolvers.Mutation[operationName]
        )
      },
      web3Link,
      httpLink
    )
  )

  client = new ApolloClient({
    cache,
    link: splitLink
  })
  return client
}

export default function getClient() {
  return client
}
