import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg'
import { ReactComponent as CheckWhite } from 'assets/check-icon-white.svg'
import { ReactComponent as Check } from 'assets/check-icon.svg'
import Label from 'components/Label'
import View from 'components/View'

const DROPDOWN_ITEMS = [
  {
    label: '24 hours',
    value: 'DAY'
  },
  {
    label: '7 days',
    value: 'WEEK'
  },
  {
    label: '30 days',
    value: 'MONTH'
  }
]

const Dropdown = ({
  open,
  selectedItem,
  setItem,
  setOpen,
  darkMode = false
}) => {
  return (
    <View direction="column" zIndex={100000}>
      <View
        padding="0.5rem"
        cursor="pointer"
        alignItems="center"
        gap="0.5rem"
        onClick={() => setOpen(!open)}
        onBlur={() => setOpen(false)}
      >
        <Label color={darkMode ? '#fff' : '#000'}>{selectedItem.label}</Label>
        <ArrowDown stroke={darkMode ? '#fff' : '#000'} />
      </View>
      {open && (
        <View
          width={
            selectedItem.value === DROPDOWN_ITEMS[2].value
              ? '8.2rem'
              : selectedItem.value === DROPDOWN_ITEMS[0].value
              ? '8.75rem'
              : '8rem'
          }
          position="absolute"
          top="2.5rem"
          direction="column"
          left="-1rem"
          border={darkMode ? '1px solid #262628' : '1px solid #E1E1E8'}
          borderRadius="6px"
          background={darkMode ? '#020202' : '#fff'}
          style={{
            boxShadow: '2px 2px 4px #0003'
          }}
        >
          {DROPDOWN_ITEMS.map(item => (
            <View
              key={item.value}
              direction="row"
              wrappable={false}
              alignItems="center"
              justify="space-between"
              cursor="pointer"
              gap="1rem"
              width={'100%'}
              padding="1rem"
              hoverBackground={darkMode ? '#262628' : '#f3f3fa'}
              transition="0.1s ease-in-out"
              onClick={() => {
                setItem(item)
                setOpen(false)
              }}
            >
              <Label color={darkMode ? '#fff' : '#000'}>{item.label}</Label>

              {selectedItem.value === item.value && (
                <>{darkMode ? <CheckWhite /> : <Check />}</>
              )}
            </View>
          ))}
        </View>
      )}
    </View>
  )
}

export default Dropdown
