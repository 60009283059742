import PremiumPriceOracle from 'components/Prices/PremiumPriceOracle'
import { PREMIUM_PRICE_ALGORITHM } from 'constants/prices'

export const getPremiumPrice = (expiryDate, timestamp = new Date()) => {
  const oracle = new PremiumPriceOracle(expiryDate, PREMIUM_PRICE_ALGORITHM)

  const premiumPrice = oracle.getAmountByDateRange(
    Number(expiryDate) * 1000,
    timestamp
  )

  return premiumPrice
}
