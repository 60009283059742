export const DURATION_ITEMS = [
  {
    value: 1,
    label: 'D',
    labelRaw: '4_hours',
    labelRawHours: 4,
    max_epoch_part: 6
  },
  {
    value: 7,
    label: 'W',
    labelRaw: '1_days',
    labelRawHours: 24,
    max_epoch_part: 7
  },
  {
    value: 30,
    label: 'M',
    labelRaw: '2_days',
    labelRawHours: 48,
    max_epoch_part: 15
  },
  {
    value: 360,
    label: 'Y',
    labelRaw: '12_days',
    labelRawHours: 288,
    max_epoch_part: 30
  },
  {
    value: 2,
    label: 'D',
    labelRaw: '4_hours',
    labelRawHours: 4,
    max_epoch_part: 12
  },
  {
    value: 14,
    label: 'W',
    labelRaw: '1_days',
    labelRawHours: 24,
    max_epoch_part: 14
  },
  {
    value: 60,
    label: 'M',
    labelRaw: '2_days',
    labelRawHours: 48,
    max_epoch_part: 30
  },
  {
    value: 720,
    label: 'Y',
    labelRaw: '12_days',
    labelRawHours: 288,
    max_epoch_part: 60
  }
]
