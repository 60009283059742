import { getSigner } from '@ensdomains/ui'
import { createSiweMessage } from './createSiweMessage'
import { fetchNonce } from './fetchNonce'
import { verifySiwe } from './verifySiwe'

export async function siwe(statement) {
  const signer = await getSigner()
  const address = await signer.getAddress()

  const nonce = await fetchNonce()
  const message = await createSiweMessage(address, statement, nonce)
  const signature = await signer.signMessage(message)

  const verificationRes = await verifySiwe(message, signature)

  return verificationRes.text()
}
