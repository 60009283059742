import styled from '@emotion/styled/macro'
import { ReactComponent as EthPriceLight } from 'assets/eth-price-grey.svg'
import RedSmallEth from 'assets/eth-red-small.svg'
import Avatar from 'components/Avatar'
import Label from 'components/Label'
import View from 'components/View'
import { BigNumber, ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { getSingleOffer } from 'service/rest/getOffers'
import { reTransformOrderData } from 'service/rest/seaportJetty'
import { ENS_CONTRACT_ADDRESS } from 'utils/utils'

const BulkOfferDomainRow = ({
  domain,
  index,
  setOfferAmount,
  offerAmounts
}) => {
  const [highestOffer, setHighestOffer] = useState(0)

  useEffect(() => {
    const fetchOffers = async () => {
      const tokenId = BigNumber.from(
        ethers.utils.keccak256(
          ethers.utils.toUtf8Bytes(domain.name.replace('.eth', ''))
        )
      ).toString()

      try {
        const fetchedOffers = await getSingleOffer({
          assetContractAddress: ENS_CONTRACT_ADDRESS,
          token_ids: tokenId,
          limit: 100
        })

        if (fetchOffers.orders?.length === 0) return

        const offersTransformed = await fetchedOffers.orders.map(order => {
          const transformedProtocolData = reTransformOrderData(
            order.protocol_data
          )
          return {
            parameters: {
              ...transformedProtocolData.parameters,
              counter: order.counter
            },
            signature: transformedProtocolData.signature
          }
        })

        const bigNumETHConversion = BigNumber.from(10).pow(18)

        const highestOfferAmount = offersTransformed?.reduce((acc, curr) => {
          const bigNumPrice = BigNumber.from(
            curr?.parameters.offer[0].endAmount
          ).mul(BigNumber.from(10).pow(6))
          const price =
            bigNumPrice.div(bigNumETHConversion).toNumber() / 10 ** 6

          if (price > acc) {
            return price
          } else {
            return acc
          }
        }, 0)

        setHighestOffer(highestOfferAmount)
      } catch (err) {
        throw new Error(err)
      }
    }

    fetchOffers()
  }, [domain])

  const formattedETHPrice = domain.listing_start_price
    ? BigNumber.from(String(domain.listing_start_price))
        .div(BigNumber.from(10).pow(15))
        .toNumber() /
      10 ** 3
    : 0

  return (
    <View width="100%" direction="row" alignItems="center">
      <View width="25%">
        <Label color="black" size={16} width="11.5vw" whiteSpace="nowrap">
          {domain.name}
        </Label>
      </View>
      <View width="25%" alignItems="center" gap="1rem">
        {formattedETHPrice > 0 && (
          <>
            <EthPriceLight />
            <Label size={16} color="#000">
              {formattedETHPrice}
            </Label>
          </>
        )}
      </View>
      <View width="25%" direction="row" alignItems="center" gap="1rem">
        {Number(highestOffer) > 0 && (
          <>
            <Avatar
              borderRadius="5px"
              width={37}
              background={'#F3F3FA'}
              src={RedSmallEth}
              padding="9px"
            />
            <Label size={16} color="#000">
              {highestOffer}
            </Label>
          </>
        )}
      </View>
      <View
        width="25%"
        direction="row"
        alignItems="center"
        gap="1rem"
        background="#F3F3FA"
        borderRadius="6px"
        border="1px solid #E1E1E8"
        padding="0.7rem"
      >
        <Avatar
          borderRadius="5px"
          width={37}
          background={'#fff'}
          src={RedSmallEth}
          padding="9px"
        />
        <StyledInput
          value={offerAmounts[index] || ''}
          placeholder={'0.00'}
          type="number"
          onChange={e => {
            const offerAmountsCopy = offerAmounts
            offerAmountsCopy[index] = e.target.value
            setOfferAmount([...offerAmountsCopy])
          }}
          min={0}
        />
      </View>
    </View>
  )
}

const StyledInput = styled.input`
  border: none;
  outline: none;
  font-size: 20px;
  line-height: 40px;
  color: #212121;
  background: transparent;
  width: 60%;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export default BulkOfferDomainRow
