import ENSIconLogoWhite from 'assets/ensIconLogoWhite.svg'
import Card from 'components/Card'
import { useColumnCount } from 'components/ResultList/hooks/useColumnCount'
import View from 'components/View'
import { DOMAIN_TABLE_COLUMNS_BREAKPOINTS } from 'constants/breakpoints'
import { FilterContext } from 'contexts/FilterProvider'
import { useContext, useEffect, useRef, useState } from 'react'
import { DomainGradientStyle } from './index'
import { useLocation } from 'react-router'
import { isAddress } from 'utils/address'

let isVisible = false

const LoadingNetTokenCard = ({
  hasAnimation = true,
  index,
  height,
  isDarkMode,
  windowWidth,
  trending,
  profile
}) => {
  const [cardWidth, setCardWidth] = useState('310px')

  const location = useLocation()
  const isTrending = location.pathname.includes('trending')
  const isProfile =
    isAddress(`0x${location.pathname.slice(1, location.pathname.length)}`) ||
    location.pathname === '/my-profile'

  // const cardRef = useRef(null)

  // const handleChangeVisibilit = entries => {
  //   if (isVisible) return
  //   const [entry] = entries
  //   isVisible = entry.isIntersecting
  // }

  // const observerOptions = {
  //   root: null,
  //   rootMargin: '1px 1px 1px 1px',
  //   threshold: 0.05
  // }

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     handleChangeVisibilit,
  //     observerOptions
  //   )

  //   if (isVisible) {
  //     observer.unobserve(cardRef.current)
  //     return
  //   }

  //   if (cardRef.current) observer.observe(cardRef.current)

  //   return () => {
  //     if (cardRef.current) observer.unobserve(cardRef.current)
  //   }
  // }, [observerOptions, handleChangeVisibilit, cardRef, isVisible])

  const { columnCount } = useColumnCount({
    sizeMap: DOMAIN_TABLE_COLUMNS_BREAKPOINTS
  })

  const filterCtx = useContext(FilterContext)
  const filtersOpened =
    !profile &&
    !trending &&
    ((!isDarkMode && filterCtx.opened) ||
      (isDarkMode && filterCtx.openedRegistry))
  const isPremium = filterCtx?.filter?.status === 'premium'

  useEffect(() => {
    let calculatedCardWith
    switch (
      filtersOpened
        ? window.innerWidth < 1300
          ? columnCount - 2
          : columnCount - 1
        : columnCount
    ) {
      case 3:
        calculatedCardWith = '32.6%'
        break
      case 4:
        calculatedCardWith = '24.25%'
        break
      case 5:
        calculatedCardWith = '19.2%'
        break
      case 6:
        calculatedCardWith = '15.833%'
        break
      case 7:
        calculatedCardWith = '13.425%'
        break
      case 8:
        calculatedCardWith = '11.625%'
        break
      case 9:
        calculatedCardWith = '9%'
        break
      case 10:
        calculatedCardWith = '3%'
        break
      default:
        calculatedCardWith = '310px'
        break
    }

    setCardWidth(calculatedCardWith)
  }, [columnCount, filtersOpened, windowWidth])

  const darkerGradient =
    'linear-gradient(135deg, rgba(160, 153, 255, 0.3) 0%, rgba(114, 152, 248,0.3) 51.56%, rgba(68, 188, 240, 0.3) 100%)'

  const registeredGradient =
    'linear-gradient(135deg, #A099FF 0%, #7298F8 51.56%, #44BCF0 100%)'

  const gradient = !isDarkMode ? registeredGradient : darkerGradient

  const bodyBg = !isDarkMode ? '#F3F3FA' : '#262628'

  return (
    <Card
      key={index}
      background={bodyBg}
      // ref={cardRef}
      margin={'0'}
      width={cardWidth}
      height={height || '25vw'}
      border={`1px solid ${!isDarkMode ? '#CACADB' : '#626266'}`}
      zIndex={1000}
      opacity={1}
      borderRadius="12px"
      style={{
        maxHeight: '430px'
      }}
    >
      <View
        width="100%"
        height="100%"
        direction="column"
        background={bodyBg}
        justify="space-between"
        overflow="hidden"
        borderRadius="10px"
      >
        <View flex="55%" width="100%" direction="column">
          <View
            width="100%"
            height="100%"
            border={!isDarkMode ? '1px solid #CACADB' : '1px solid #626266'}
            borderRadius="10px"
            background={
              isPremium ? 'rgb(49, 9, 38)' : !isDarkMode ? bodyBg : '#020202'
            }
          >
            <DomainGradientStyle
              border={!isDarkMode ? '#CACADB' : '#4A4A4D'}
              gradient={gradient}
            >
              <View width="100%" justify="space-between" alignItems="center">
                <img
                  style={{ userSelect: 'none', pointerEvents: 'none' }}
                  src={ENSIconLogoWhite}
                  width="35vw"
                  alt="ens-icon-logo"
                />
                {/* <View
                  width="2.25vw"
                  height="2.25vw"
                  borderRadius="6px"
                  animation="loading 1s infinite"
                  background="linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2))"
                /> */}
              </View>
              <View
                width="100%"
                style={{ height: 'clamp(16px,1.5vw,25px)' }}
                animation="loading 1s infinite"
                background="linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2))"
                borderRadius="6px"
              />
            </DomainGradientStyle>
          </View>
        </View>

        <View
          flex="45%"
          width="100%"
          height="100%"
          padding="10%"
          direction="column"
          justify="space-between"
          background={bodyBg}
          borderRadius="8px"
          transition="0.2s ease-out"
        >
          <View
            width="100%"
            style={{ height: 'clamp(16px,1.15vw,23px)' }}
            animation="loading 1s infinite"
            background={
              isDarkMode
                ? 'linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2))'
                : 'linear-gradient(to right, rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05))'
            }
            borderRadius="6px"
          />
          <View
            style={{
              height: 'clamp(16px,1.8vw,30px)',
              width: 'clamp(16px,1.8vw,30px)'
            }}
            animation="loading 1s infinite"
            background={
              isDarkMode
                ? 'linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2))'
                : 'linear-gradient(to right, rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05))'
            }
            borderRadius="6px"
          />
          <View
            width="40%"
            style={{ height: 'clamp(16px,1.15vw,23px)' }}
            animation="loading 1s infinite"
            background={
              isDarkMode
                ? 'linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2))'
                : 'linear-gradient(to right, rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05))'
            }
            borderRadius="6px"
          />
        </View>
      </View>
    </Card>
  )
}

export default LoadingNetTokenCard
