const getOrCreateTooltip = (chart, darkMode) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.background = darkMode ? '#020202' : '#fff'
    tooltipEl.style.borderRadius = '10px'
    tooltipEl.style.border = `1px solid ${darkMode ? '#4A4A4D' : '#E1E1E8'}`
    tooltipEl.style.opacity = '1'
    tooltipEl.style.padding = '12px 20px'
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, -110%)'
    tooltipEl.style.transition =
      'all .8s cubic-bezier( 0.190,  1.000,  0.220,  1.000 ), opacity .2s ease'
    tooltipEl.style.fontFamily = 'Satoshi'
    tooltipEl.style.width = '250px'
    tooltipEl.style.zIndex = '5'
    tooltipEl.style.fontFamily = 'Satoshi'

    const table = document.createElement('div')
    table.className = 'table'
    table.style.margin = '0px'

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

export const externalTooltipHandler = context => {
  // Tooltip Element
  const darkMode = false
  const todayPrice = 0
  const currency = 'ETH'
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart, darkMode)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  // Set Text
  if (tooltip.body) {
    const header = createHeader(tooltip, todayPrice, darkMode, currency)
    const data = createData(tooltip, darkMode)

    const tableRoot = tooltipEl.querySelector('.table')

    // Remove old children
    while (tableRoot.firstChild ?? false) {
      tableRoot.firstChild.remove()
    }

    // Add new children
    tableRoot.appendChild(header)
    tableRoot.appendChild(data)
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1'
  tooltipEl.style.left = positionX + tooltip.caretX + 'px'
  tooltipEl.style.top = positionY + tooltip.caretY + 'px'
  tooltipEl.style.font = tooltip.options.bodyFont.string
  tooltipEl.style.fontFamily = 'Satoshi'
}

function createHeader(tooltip, todayPrice, darkMode, currency) {
  const selectedPrice = tooltip.dataPoints[0].raw
  const header = document.createElement('div')
  header.style.display = 'flex'
  header.style.alignItems = 'center'
  header.style.marginBottom = '8px'
  header.style.justifyContent = 'space-between'
  header.style.width = '100%'
  header.style.fontFamily = 'Satoshi'

  const value = document.createElement('span')
  value.style.fontWeight = '600'
  value.style.fontSize = '16px'
  value.style.color = darkMode ? '#fff' : '#020202'
  value.innerHTML = `${Number(selectedPrice).toLocaleString('default', {
    maximumFractionDigits: 2
  })} ${currency}`
  value.style.fontFamily = 'Satoshi'

  header.appendChild(value)

  return header
}

function createData(tooltip, darkMode) {
  const date = document.createElement('span')
  date.style.color = darkMode ? '#9A9A9A' : '#BCBCCC'
  date.style.fontSize = '13px'
  date.style.fontWeight = '300'
  date.innerHTML = tooltip.title[0]

  return date
}
