import gql from 'graphql-tag'

export const UserFields = gql`
  fragment UserFields on users {
    user
    username
    bio
    picture
    banner
    twitter
    instagram
    weblink
    last_seen
  }
`

export const LikesFields = gql`
  fragment LikesFields on likes {
    domain
    liked
    user
    userByUser {
      picture
    }
  }
`

export const KodexListingFields = gql`
  fragment KodexListingFields on kodex_listings {
    active
    askPrice
    buyer
    delistedat
    domain
    duration
    executed
    executedat
    executedprice
    executedtxhash
    listedat
    listingtxhash
    owner
  }
`
