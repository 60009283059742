import styled from '@emotion/styled/macro'
import { ReactComponent as ArrowBottom } from 'assets/arrow-down.svg'
import KodexIcon from 'components/Icon/KodexIcon'
import Label from 'components/Label'
import Ranger from 'components/Ranger'
import Space from 'components/Space'
import View from 'components/View'
import { useCallback, useEffect, useRef, useState } from 'react'

const CharacterRanger = ({
  values,
  onChange,
  label,
  thumbWidth,
  rangerExtremumLabelsColor,
  darkMode,
  defaultOpen
}) => {
  const [contentExpanded, setContentExpanded] = useState(defaultOpen)

  const contentContainerRef = useRef()
  const contentWrapperRef = useRef()
  const accordionRef = useRef()

  useEffect(() => {
    if (contentExpanded)
      accordionRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center'
      })
  }, [contentExpanded])

  const onTitlePressed = useCallback(() => {
    if (contentExpanded) {
      contentContainerRef.current.style.maxHeight = 0
      accordionRef.current.style.minHeight = '60px'
    } else {
      contentContainerRef.current.style.maxHeight = `${
        contentWrapperRef.current.clientHeight
      }px`
      accordionRef.current.style.minHeight = `${
        contentWrapperRef.current.clientHeight
      }px`
    }

    setContentExpanded(!contentExpanded)
  }, [setContentExpanded, contentExpanded])

  return (
    <AccordionComponent
      ref={accordionRef}
      borderColor={darkMode ? '#4A4A4D' : '#E1E1E8'}
      background={darkMode ? '#02020200' : '#fff'}
    >
      <View
        alignItems="center"
        justify="space-between"
        width="100%"
        padding="22px 20px 22px 30px"
        onClick={onTitlePressed}
        zIndex={2}
        background={darkMode ? '#02020200' : '#fff'}
        borderRadius="8px"
        cursor="pointer"
      >
        <View width="100%" justify="space-between">
          <Label
            color={darkMode ? '#ffffff' : '#020202'}
            size={16}
            weight="600"
            zIndex="0"
          >
            {label}
          </Label>
          <Label color={darkMode ? '#ffffff' : '#020202'} size={16} zIndex="0">
            {values[0] === values[1]
              ? values[0] === 10
                ? '10+'
                : `${values[0]}`
              : `${values[0]} - ${values[1]}${values[1] === 10 ? '+' : ''}`}
          </Label>
        </View>
        <Space size={10} />
        <IconWrapper rotate={contentExpanded ? 'rotate(180deg)' : ''}>
          <KodexIcon
            icon={<ArrowBottom />}
            stroke={darkMode ? '#fff' : '#020202'}
            size={20}
            padding={0}
          />
        </IconWrapper>
      </View>
      <ContentContainer ref={contentContainerRef}>
        <View
          width="100%"
          direction="column"
          padding="35px 30px 20px 30px"
          borderRadius="10px"
          ref={contentWrapperRef}
        >
          <Space size={30} />
          <Ranger
            step={1}
            min={3}
            max={10}
            values={values}
            onChange={onChange}
            thumbWidth={thumbWidth}
          />
          <Space size={6} />
          <View width="100%" justify="space-between">
            <Label
              number
              color={rangerExtremumLabelsColor}
              size={13}
              weight="600"
            >
              3
            </Label>
            <Label
              number
              color={rangerExtremumLabelsColor}
              size={13}
              weight="600"
            >
              10+
            </Label>
          </View>
        </View>
      </ContentContainer>
    </AccordionComponent>
  )
}

const AccordionComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.borderColor};
  border-radius: 10px;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  width: 100%;
  min-height: 60px;
  transition: all 0.3s ease;
  background: ${props => props.background};
`

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
`

const ContentContainer = styled.div`
  max-height: 0;
  transition: all 0.3s ease;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

const IconWrapper = styled.div`
  transform: ${props => props.rotate};
  transition: transform 0.3s ease;
`

export default CharacterRanger
