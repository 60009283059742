import moment from 'moment'

const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.background = 'rgba(255, 255, 255)'
    tooltipEl.style.borderRadius = '10px'
    tooltipEl.style.border = '1px solid #E1E1E8'
    tooltipEl.style.color = '#020202'
    tooltipEl.style.opacity = '1'
    tooltipEl.style.padding = '12px 20px'
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, -110%)'
    tooltipEl.style.transition =
      'all .8s cubic-bezier( 0.190,  1.000,  0.220,  1.000 ), opacity .2s ease'
    tooltipEl.style.fontFamily = 'Satoshi'
    tooltipEl.style.width = '200px'
    tooltipEl.style.zIndex = '5'

    const table = document.createElement('table')
    table.style.margin = '0px'

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

export const externalTooltipHandler = context => {
  // Tooltip Element
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  // Set Text
  if (tooltip.body) {
    const header = createHeader(tooltip)
    const data = createData(tooltip)

    const tableRoot = tooltipEl.querySelector('table')

    // Remove old children
    while (tableRoot.firstChild ?? false) {
      tableRoot.firstChild.remove()
    }

    // Add new children
    tableRoot.appendChild(header)
    tableRoot.appendChild(data)
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1'
  tooltipEl.style.left = positionX + tooltip.caretX + 'px'
  tooltipEl.style.top = positionY + tooltip.caretY + 'px'
  tooltipEl.style.font = tooltip.options.bodyFont.string
}

function createHeader(tooltip) {
  const currentPrice = tooltip.dataPoints[0].raw[1]
  const previousPrice = tooltip.dataPoints[0].raw[0]

  const header = document.createElement('div')
  header.style.display = 'flex'
  header.style.alignItems = 'center'
  header.style.marginBottom = '8px'
  header.style.justifyContent = 'space-between'
  header.style.fontFamily = 'Satoshi'
  header.style.width = '160px'

  const value = document.createElement('span')
  value.style.fontWeight = '600'
  value.style.fontSize = '16px'
  value.style.color = '#020202'
  value.style.fontFamily = 'Satoshi'
  value.innerHTML = `${Number(currentPrice.toFixed(3))} ETH`

  const deltaPercentageElem = document.createElement('span')

  let deltaPercentageLabel = ''
  if (previousPrice !== 0) {
    const deltaPercentage = currentPrice / previousPrice
    if (deltaPercentage < 1) {
      deltaPercentageLabel = `-${Number(
        ((1 - deltaPercentage) * 100).toFixed(2)
      )}%`
      deltaPercentageElem.style.color = '#AF61AB'
    } else {
      deltaPercentageLabel = `+${Number(
        ((deltaPercentage - 1) * 100).toFixed(2)
      )}%`
      deltaPercentageElem.style.color = '#0698A8'
    }
  }

  deltaPercentageElem.style.fontSize = '16px'
  deltaPercentageElem.style.fontWeight = '600'
  deltaPercentageElem.style.fontFamily = 'Satoshi'
  deltaPercentageElem.innerHTML = deltaPercentageLabel

  header.appendChild(value)
  header.appendChild(deltaPercentageElem)

  return header
}

function createData(tooltip) {
  const date = document.createElement('span')
  date.style.color = '#BCBCCC'
  date.style.fontSize = '13px'
  date.style.fontWeight = '300'
  date.innerHTML = moment(tooltip.title[0]).format('D MMM YYYY')

  return date
}
