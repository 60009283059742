import styled from '@emotion/styled/macro'
import { createContext, useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { useImagePreloader } from './ImagePreloaderProvider'

export const AnimatedHistoryContext = createContext({
  push: (url, progress, state) => {}
})

let hasInitLoaded = false
let previousSite = false

const AnimatedHistoryProvider = ({ children, needToPreloadImages }) => {
  // const [isLoaded, setIsLoaded] = useState(false)
  const {
    imageLoading,
    finishAnimation,
    onPreloaderHalfCompleted
  } = useImagePreloader()

  useEffect(() => {
    if (hasInitLoaded) return
    if (imageLoading === 0) hasInitLoaded = true
  }, [imageLoading])

  const loadBarRef = useRef(null)

  const location = useLocation()

  useEffect(() => {
    if (location.pathname.includes(previousSite)) return
    if (hasInitLoaded) {
      loadBarRef.current.style.display = 'block'
      loadBarRef.current.style.animation =
        'loadIn 2s cubic-bezier( 0.79, 0.33, 0.9, 0.1 )'
      setTimeout(() => {
        loadBarRef.current.style.display = 'none'
      }, 2060)
      previousSite = location.pathname
    }
  }, [location.pathname])

  return (
    <AnimatedHistoryContext.Provider
      value={{
        push: () => {}
      }}
    >
      {/* <PreloaderComponent
        ref={firstPreloaderRef}
        background={window.innerWidth < 640 ? '#eee' : '#AF61AB'}
      /> */}
      <LoadBar
        ref={loadBarRef}
        imageLoading={imageLoading}
        location={location.pathname}
        style={{
          zIndex: 10000,
          display: imageLoading === 0 && 'none'
        }}
      />
      {children}
    </AnimatedHistoryContext.Provider>
  )
}

const LoadBar = styled.div`
  width: 100vw;
  height: 3px;
  background: ${props =>
    props.location.includes('feed') || props.location === '/'
      ? '#AF61AB'
      : '#0698A8'};
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(
    -${props => (props.imageLoading % 25 || props.imageLoading > 75 ? props.imageLoading : Math.floor(props.imageLoading / 25) * 25)}vw
  );
  transition: transform 0.2s linear;
  border-radius: 20px;

  @keyframes loadIn {
    0% {
      transform: translateX(-100vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }
`

export function useAnimatedHistory() {
  const context = useContext(AnimatedHistoryContext)
  if (context === undefined) {
    throw new Error('useCount must be used within a PreloaderProvider')
  }
  return context
}

export default AnimatedHistoryProvider
