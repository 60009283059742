import React from 'react'
import styled from '@emotion/styled/macro'
import Label from '../../Label'

const TransactionsConfirmedCard = ({ step }) => {
  const getStepTitle = () => {
    if (step === 1) {
      return 'Request to register'
    } else if (step === 2) {
      return 'Wait for 1 minute'
    } else {
      return 'Complete registration'
    }
  }

  const getStepDescription = () => {
    if (step === 1) {
      return 'Your wallet will open and you will be asked to confirm the first of two transactions required for registration.'
    } else if (step === 2) {
      return "The waiting period is required to ensure another person hasn't tried to register the same name and protect you after your request."
    } else {
      return "Click 'register' and your wallet will re-open. Only after the 2nd transaction is confirmed you'll know if you got the domain name."
    }
  }

  return (
    <TransactionsConfirmedContainer>
      <TransactionsConfirmedCounterContainer>
        <TransactionsConfirmedCounter>
          <Label color="#020202" letterSpacing="1px">
            {step}
          </Label>
          <Label letterSpacing="1px" color="#BCBCCC">
            /3
          </Label>
        </TransactionsConfirmedCounter>
        <Label color="#020202" size={13} weight="600">
          {getStepTitle()}
        </Label>
      </TransactionsConfirmedCounterContainer>
      <Label color="#020202" opacity={0.5} size={13} lineHeight={1.6}>
        {getStepDescription()}
      </Label>
    </TransactionsConfirmedContainer>
  )
}

export default TransactionsConfirmedCard

const TransactionsConfirmedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff1ea;
  padding: 20px;
  border-radius: 15px;
  color: #888;
`

const TransactionsConfirmedCounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
`

const TransactionsConfirmedCounter = styled.div`
  width: 56px;
  background-color: #fff;
  padding: 12px 0;
  border-radius: 6px;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
`
