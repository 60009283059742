import React from 'react'
import Label from 'components/Label'
import RoundedButton from 'components/Forms/RoundedButton'

const EventType = ({ event }) => {
  const getLabelColor = () => {
    if (event === 'sale') {
      return '#6E71C1'
    }
    if (event === 'registration') {
      return '#B77DE4'
    }
    if (event === 'listing') {
      return '#59B0E2'
    }
    return '#0698A8'
  }

  const getBgColor = () => {
    if (event === 'sale') {
      return '#EFF0FF'
    }
    if (event === 'registration') {
      return '#F1E5FA'
    }
    if (event === 'listing') {
      return '#E5F6FF'
    }
    return '#E8F5F6'
  }

  return (
    <RoundedButton
      padding="12px 16px"
      borderRadius="6px"
      color={getLabelColor()}
      background={getBgColor()}
      cursor="auto"
    >
      <Label color="inherit" size="16">
        {event}
      </Label>
    </RoundedButton>
  )
}

export default EventType
