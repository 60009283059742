import { ReactComponent as Arrow } from 'assets/arrow-right.svg'
import { ReactComponent as EthPriceDark } from 'assets/eth-icon-dark.svg'
import { ReactComponent as EthPriceLight } from 'assets/eth-icon.svg'
import RedSmallEth from 'assets/eth-red-small.svg'
import { ReactComponent as ExpiredIcon } from 'assets/expiration.svg'
import { ReactComponent as PurchaseIcon } from 'assets/purchase-icon.svg'
import { ReactComponent as RegisteredIcon } from 'assets/registered.svg'
import { ReactComponent as SaleIcon } from 'assets/sale.svg'
import { ReactComponent as TransferIcon } from 'assets/transfer.svg'
import TableIcon from 'components/Icon/TableIcon'
import Label from 'components/Label'
import View from 'components/View'
import { BigNumber } from 'ethers'
import moment from 'moment'
import { useMemo, useState } from 'react'
import NoItems from 'views/Checkout/components/NoItems'

import Avatar from 'components/Avatar'
import 'moment-timezone'
import { EMPTY_ADDRESS } from 'utils/records'
import ActivityAddress from 'views/Profile/components/ActivityAddress'

const ICONS = {
  transfer: <TransferIcon />,
  purchase: <PurchaseIcon />,
  sale: <SaleIcon />,
  registration: <RegisteredIcon />,
  expiration: <ExpiredIcon />
}

const MobileActivities = ({
  activityData,
  isRegistered,
  filter = 'All events'
}) => {
  const [page, setPage] = useState(1)

  const filteredActivityData = useMemo(() => {
    const filteredData = activityData
      ?.filter(
        activity =>
          activity.fromaddr !== EMPTY_ADDRESS &&
          activity.fromaddr !== '0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5'
      )
      .map(activity => {
        if (activity.toaddr === EMPTY_ADDRESS) {
          return {
            ...activity,
            event_type: 'expiration'
          }
        }

        if (activity.toaddr === null) {
          return {
            ...activity,
            event_type: 'registration'
          }
        }

        return activity
      })

    setPage(1)

    if (filter !== 'All events') {
      const appliedFilter = filteredData.filter(
        act => act.event_type === filter.toLowerCase()
      )
      return appliedFilter
    }

    return filteredData
  }, [activityData, filter])

  const getIconBg = event => {
    let iconBg = '#f3f3f3'
    switch (event) {
      case 'sale':
        iconBg = isRegistered ? '#EFF0FF' : '#6E71C133'
        break
      case 'purchase':
        iconBg = isRegistered ? '#E6F6FF' : '#59B0E233'
        break
      case 'transfer':
        iconBg = isRegistered ? '#E8F5F7' : '#0698A833'
        break
      case 'registration':
        iconBg = isRegistered ? '#F1E5FA' : '#B77DE433'
        break
      default:
        iconBg = isRegistered ? '#f3f3fa' : '#f3f3f377'
        break
    }
    return iconBg
  }

  const pages = useMemo(() => Math.ceil(filteredActivityData?.length / 3), [
    filteredActivityData
  ])

  return filteredActivityData?.length > 0 ? (
    <View
      width="100%"
      direction="column"
      borderRadius="8px"
      background="transparent"
    >
      <View
        width="100%"
        background={isRegistered ? 'white' : '#1B1B1B'}
        direction="column"
        gap="32px"
        padding="16px"
      >
        {filteredActivityData
          .slice((page - 1) * 3, page * 3)
          .map((activity, i) => (
            <View width="100%" direction="column" gap="16px" key={i}>
              <View
                direction="row"
                width="100%"
                justify="space-between"
                alignItems="center"
              >
                <View direction="row" gap="8px" alignItems="center">
                  <TableIcon
                    padding="5px"
                    icon={
                      ICONS[
                        activity?.event_type ||
                          activity?.__typename.toLowerCase()
                      ]
                    }
                    background={getIconBg(
                      activity?.event_type || activity?.__typename.toLowerCase()
                    )}
                  />
                  <Label size={16} color={isRegistered ? '#47596B' : '#fff'}>
                    {`${activity?.event_type[0].toUpperCase()}${activity?.event_type.slice(
                      1,
                      activity?.event_type.length
                    )}`}
                  </Label>
                </View>
                <View alignItems="center" gap="8px">
                  {activity.asset === 'USDC' || activity.asset === 'DAI' ? (
                    <Label size={16} color={!isRegistered ? '#fff' : '#47596B'}>
                      $
                    </Label>
                  ) : activity.asset === 'WETH' ? (
                    <Avatar
                      width={20}
                      background={'transparent'}
                      src={RedSmallEth}
                      padding="0px"
                    />
                  ) : isRegistered ? (
                    <EthPriceDark />
                  ) : (
                    <EthPriceLight />
                  )}
                  <Label
                    size={16}
                    number
                    // style={{ textTransform: 'capitalize' }}
                    color={isRegistered ? '#47596B' : '#fff'}
                  >
                    {activity.asset === 'USDC' || activity.asset === 'DAI'
                      ? Number(
                          BigNumber.from(
                            activity?.cost ||
                              String(Number(activity?.price)) ||
                              1
                          )
                            .div(BigNumber.from(10).pow(6))
                            .toNumber()
                        )
                      : Number(
                          BigNumber.from(
                            activity?.cost ||
                              String(Number(activity?.price)) ||
                              1
                          )
                            .div(BigNumber.from(10).pow(15))
                            .toNumber() /
                            10 ** 3 || 0
                        )
                          .toLocaleString('default', {
                            maximumFractionDigits:
                              activity.asset === 'USDC' ||
                              activity.asset === 'DAI'
                                ? 0
                                : Number(
                                    BigNumber.from(
                                      activity?.cost ||
                                        String(Number(activity?.price)) ||
                                        1
                                    )
                                      .div(BigNumber.from(10).pow(15))
                                      .toNumber() /
                                      10 ** 3 || 0
                                  ) >= 1000
                                ? 0
                                : 3
                          })
                          .replaceAll(',', ' ') || '0.00'}
                  </Label>
                </View>
              </View>
              <View
                direction="row"
                width="100%"
                justify="space-between"
                alignItems="center"
              >
                <Label color={isRegistered ? '#47596B88' : '#fff9'}>From</Label>
                <ActivityAddress
                  address={
                    (activity?.event_type === 'registration' &&
                      '0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5') ||
                    activity.fromaddr
                  }
                  isRegistered={isRegistered}
                  showPfp={false}
                  link={false}
                  color={isRegistered ? '#47596B' : '#FFFFFF'}
                  hoverColor={isRegistered ? '#47596Bbb' : '#fffb'}
                />
              </View>
              <View
                direction="row"
                width="100%"
                justify="space-between"
                alignItems="center"
              >
                <Label color={isRegistered ? '#47596B88' : '#fff9'}>To</Label>
                <ActivityAddress
                  address={
                    activity.event_type === 'registration'
                      ? activity.fromaddr
                      : activity.toaddr || activity?.registrant?.id
                  }
                  isRegistered={isRegistered}
                  showPfp={false}
                  link={false}
                  color={isRegistered ? '#47596B' : '#FFFFFF'}
                  hoverColor={isRegistered ? '#47596Bbb' : '#fffb'}
                />
              </View>
              <View
                direction="row"
                width="100%"
                justify="space-between"
                alignItems="center"
              >
                <Label color={isRegistered ? '#47596B88' : '#fff9'}>Date</Label>
                <Label color={isRegistered ? '#47596B' : '#FFFFFF'}>
                  {moment
                    .tz(
                      activity.timestamp ||
                        Number(activity.registrationDate) * 1000,
                      'UTC'
                    )
                    .format('DD MMMM YYYY')}
                </Label>
              </View>
            </View>
          ))}
      </View>
      <View width="100%" justify="space-between" padding="16px">
        <View
          gap="6px"
          alignItems="center"
          onClick={() => {
            if (page === 1) return
            setPage(page - 1)
          }}
        >
          <Arrow
            stroke={
              page === 1
                ? isRegistered
                  ? '#919CA655'
                  : '#99999999'
                : isRegistered
                ? '#919CA6'
                : '#999999'
            }
            height={11}
            style={{
              transform: 'rotate(180deg)'
            }}
          />
          <Label
            color={
              page === 1
                ? isRegistered
                  ? '#919CA655'
                  : '#99999999'
                : isRegistered
                ? '#919CA6'
                : '#999999'
            }
          >
            Prev
          </Label>
        </View>
        <View gap="8px">
          <Label color={isRegistered ? '#47596B' : 'white'}>{page}</Label>
          <Label color={isRegistered ? '#919CA6' : '#999999'}>OF</Label>
          <Label color={isRegistered ? '#47596B' : 'white'}>{pages}</Label>
        </View>
        <View
          gap="6px"
          alignItems="center"
          onClick={() => {
            if (page === pages) return
            setPage(page + 1)
          }}
        >
          <Label
            color={
              page === pages
                ? isRegistered
                  ? '#919CA655'
                  : '#99999999'
                : isRegistered
                ? '#919CA6'
                : '#999999'
            }
          >
            Next
          </Label>
          <Arrow
            stroke={
              page === pages
                ? isRegistered
                  ? '#919CA655'
                  : '#99999999'
                : isRegistered
                ? '#919CA6'
                : '#999999'
            }
            height={11}
          />
        </View>
      </View>
    </View>
  ) : (
    <NoItems isRegistered={isRegistered} label="No activity yet" />
  )
}

export default MobileActivities
