import { JETTY_URL } from 'service/siwe/constants'
import { siweAuthedFetch } from 'service/siwe/siweFetch'

export const follow = async reqBody => {
  try {
    const req = siweAuthedFetch(`${JETTY_URL}/user/follow`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqBody)
    })
    const res = await req.then(res => res)
    return res
  } catch (err) {
    throw new Error(err)
  }
}

export const unfollow = async reqBody => {
  try {
    const req = siweAuthedFetch(`${JETTY_URL}/user/follow`, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqBody)
    })
    const res = await req.then(res => res)
    return res
  } catch (err) {
    throw new Error(err)
  }
}
