import styled from '@emotion/styled/macro'
import Space from 'components/Space'
import View from 'components/View'
import React, { useState } from 'react'
import ContactList from 'views/Chats/ContactList'
import MessageView from 'views/Chats/MessageView'
import { MOCK_CHATS } from 'views/Chats/mockData'
import mq from 'mediaQuery'

export const ChatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 110px 10px 10px 10px;

  ${mq.large`
    padding: 110px 10px 10px 50px;
  `}

  ${mq.xLarge`
    padding: 110px 10px 10px 101px;
  `}
`

const Chats = () => {
  const [selectedChat, setSelectedChat] = useState('1')

  const contacts = MOCK_CHATS.map(({ id, summary }) => ({ id, summary }))
  const chatMap = MOCK_CHATS.reduce(
    (acc, next) => ({
      ...acc,
      [next.id]: next
    }),
    {}
  )

  return (
    <ChatsContainer maxWidth="calc(100% - 140px)" background="#fff">
      <Space size={20} />
      <View width="100%" flex={1} position="relative">
        <ContactList
          contacts={contacts}
          selectedChat={selectedChat}
          selectChat={chat => setSelectedChat(chat)}
        />
        <MessageView chat={chatMap[selectedChat]} />
      </View>
    </ChatsContainer>
  )
}

export default Chats
