import { useEffect, useRef, useState } from 'react'

export default (inputValue, inputPaddingRight, paddingFromText) => {
  const [caretLeftPosition, setCaretLeftPosition] = useState(0)
  const inputRef = useRef(null)
  const inputStringLengthRef = useRef(null)

  useEffect(() => {
    if (inputValue.length === 0) {
      setCaretLeftPosition(0)
      return
    }

    const inputStringLengthPX = inputStringLengthRef.current.clientWidth
    const inputElementLength = inputRef.current.clientWidth - inputPaddingRight

    if (inputStringLengthPX > inputElementLength) {
      setCaretLeftPosition(inputElementLength + paddingFromText)
    } else {
      setCaretLeftPosition(inputStringLengthPX + paddingFromText)
    }
  }, [inputValue, window.innerWidth])

  return {
    inputRef,
    inputStringLengthRef,
    caretLeftPosition
  }
}
