import { useEffect, useState } from 'react'
import { useWindowListener } from 'common/useWindowListener'

const getColumnCountPerBreakpoint = ({ windowSize, sizeMap }) => {
  const sizes = Object.entries(sizeMap)
    .sort(([breakpointA], [breakpointB]) => breakpointB - breakpointA)
    .map(([breakpoint, columnCount]) => [parseInt(breakpoint), columnCount])

  const columCount = sizes.find(([breakpoint]) => windowSize >= breakpoint)?.[1]

  if (!columCount) {
    throw new Error('Column breakpoint map error')
  }

  return columCount
}

export const useColumnCount = ({ sizeMap }) => {
  const [columnCount, setColumnCount] = useState(undefined)

  useEffect(() => {
    setColumnCount(
      getColumnCountPerBreakpoint({
        windowSize: window.visualViewport.width,
        sizeMap
      })
    )
  }, [sizeMap])

  useWindowListener(
    'resize',
    () => {
      setColumnCount(
        getColumnCountPerBreakpoint({
          windowSize: window.visualViewport.width,
          sizeMap
        })
      )
    },
    [sizeMap]
  )

  return { columnCount }
}
