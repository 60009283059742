import styled from '@emotion/styled/macro'
import { ReactComponent as CardView } from 'assets/card-view.svg'
import { ReactComponent as ListView } from 'assets/list-view.svg'
import RoundedButton from 'components/Forms/RoundedButton'
import IconWrapper from 'components/IconWrapper'
import View from 'components/View'

const ViewTypeSelector = ({ value, onChange, darkMode }) => {
  return (
    <View
      width="100%"
      alignItems="center"
      background={darkMode ? '#262628' : '#F3F3FA'}
      borderRadius="10px"
      padding="5px"
    >
      <ViewTypeSelectorButton
        icon={<CardView />}
        iconColor={
          value === 'CARD' ? (darkMode ? '#ffffff' : '#020202') : '#BCBCCC'
        }
        onClick={() => onChange('CARD')}
        background={
          value === 'CARD' ? (darkMode ? '#020202' : '#fff') : 'transparent'
        }
      />
      <ViewTypeSelectorButton
        icon={<ListView />}
        iconColor={
          value === 'LIST' ? (darkMode ? '#ffffff' : '#020202') : '#BCBCCC'
        }
        onClick={() => onChange('LIST')}
        background={
          value === 'LIST' ? (darkMode ? '#020202' : '#fff') : 'transparent'
        }
      />
      <SelectionIndicator
        translateX={value === 'LIST' ? '100%' : '0%'}
        darkMode={darkMode}
      />
    </View>
  )
}

const ViewTypeSelectorButton = ({ icon, onClick, background, iconColor }) => {
  return (
    <View flex={1} zIndex={100}>
      <RoundedButton
        width="100%"
        height="51px"
        padding="0px 0px"
        background="transparent"
        onClick={onClick}
        borderRadius="7px"
      >
        <View width="100%" alignItems="center" justify="center">
          <IconWrapper stroke={iconColor}>{icon}</IconWrapper>
        </View>
      </RoundedButton>
    </View>
  )
}

export default ViewTypeSelector

const SelectionIndicator = styled.div`
  height: 85%;
  width: calc(50% - 5px);
  position: absolute;
  background-color: ${props => (props.darkMode ? 'black' : 'white')};
  border-radius: 7px;
  transition: 0.2s linear;
  transform: ${props => `translateX(${props.translateX})` || 'tranlateX(0)'};
`
