import React, { useState } from 'react'
import styled from '@emotion/styled'
import { ReactComponent as EthPriceLight } from 'assets/eth-price-light.svg'
// import { ReactComponent as EthPriceDark } from 'assets/eth-price-dark.svg'
import View from 'components/View'
import Space from 'components/Space'
import PriceInfoCard from '../Cards/PriceInfoCard'
import RoundedButton from 'components/Forms/RoundedButton'

const DesiredPriceModal = ({
  currentPrice,
  desiredPrice,
  setDesiredPrice,
  setDesiredPriceModal
}) => {
  const [desiredPriceInput, setDesiredPriceInput] = useState(desiredPrice)
  const [suggestedPrice, setSuggestedPrice] = useState(0)

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: '100'
      }}
    >
      <StyledBackdrop onClick={() => setDesiredPriceModal(false)}>
        Backdrop
      </StyledBackdrop>
      <StyledSetPriceContainer>
        <div style={{ fontSize: '2rem' }}>Set price</div>
        <View
          direction="row"
          justify="space-between"
          margin="5vh 0 2vh 0"
          style={{ color: '#BCBCCC', fontSize: '0.9rem' }}
        >
          <div>Desired price</div>
          <div style={{ width: '25vw' }}>Current price</div>
        </View>
        <StyledInputsContainer>
          <StyledInput
            type="number"
            value={desiredPriceInput ? desiredPriceInput : undefined}
            min={0}
            step={0.001}
            placeholder={'0.00'}
            onChange={e => setDesiredPriceInput(e.target.value)}
          />
          <div
            style={{
              fontSize: '1.5rem',
              color: '#BCBCCC',
              width: '15vw',
              paddingTop: '0.4rem'
            }}
          >
            {currentPrice}
          </div>
          <EthPriceLight />
        </StyledInputsContainer>
        <Space size={30} />
        <View direction="row" justify="space-between">
          <div style={{ color: '#BCBCCC' }}>Registration price</div>
          <div>now</div>
        </View>
        <Space size={30} />
        <PriceInfoCard />
        <ButtonsContainer>
          <RoundedButton
            background="#fff"
            width="23vw"
            color={suggestedPrice > 0 ? 'black' : '#777'}
            disabled={suggestedPrice === 0}
            padding="1.5rem"
            letterSpacing="0.1rem"
            border="1px solid #e1e1e8"
            hoverBackground="#e1e1e8"
          >
            Set to suggested
          </RoundedButton>
          <RoundedButton
            background="#0698A8"
            width="23vw"
            color="white"
            padding="1.5rem"
            letterSpacing="0.1rem"
            hoverBackground="#0698A8cc"
            onClick={() => {
              setDesiredPrice(desiredPriceInput)
              setDesiredPriceModal(false)
            }}
          >
            Apply
          </RoundedButton>
        </ButtonsContainer>
      </StyledSetPriceContainer>
    </div>
  )
}

export default DesiredPriceModal

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  z-index: 20;
`

const StyledSetPriceContainer = styled.div`
  position: fixed;
  margin: 1vh 1vh 0 0;
  top: 0;
  right: 0;
  width: 50vw;
  height: 91vh;
  padding: 4rem 2rem;
  border-radius: 15px;
  background-color: #fff;
  z-index: 30;
`
const StyledInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #e1e1e8;
  background-color: #f3f3fa;
  border-radius: 10px;
  padding: 1rem;
`

const StyledInput = styled.input`
  font-size: 1.5rem;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`

const ButtonsContainer = styled.div`
  margin-top: 27.5vh;
  display: flex;
  flex-diection: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
`
