import View from 'components/View'
import { ethers } from 'ethers'
import { useEffect, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'
import LoadingTrendingUserCard from './LoadingTrendingUserCard'
import TrendingUserCard from './TrendingUserCard'

const UserCardsList = ({
  users,
  cardWidth = '310px',
  windowWidth,
  loadMore = () => {},
  noItems,
  loading
}) => {
  if (users?.length === 0 && !loading) {
    return noItems()
  }

  const bottomPaddingRef = useRef(null)

  const { inViewport } = useInViewport(bottomPaddingRef)

  useEffect(() => {
    if (!inViewport) return
    loadMore()
  }, [inViewport])

  const provider = new ethers.providers.StaticJsonRpcProvider(
    process.env.REACT_APP_LLAMA_NODES_URL,
    'homestead'
  )

  const emptyArray = new Array(20).fill('h', 0, 20)

  return (
    <View flex={1} wrappable="wrap" direction="row" gap="15px 1%">
      {!loading
        ? users?.map(user => (
            <TrendingUserCard
              key={user.user}
              name={user.username}
              address={user.user}
              favCategory={user.favourite_category}
              // domains="330"
              // followers="22"
              likes={user.likes}
              offers={user.offers}
              avatar={user.picture}
              width={cardWidth}
              windowWidth={windowWidth}
              headerBg="#212121"
              headerColor="#EBEBF2"
              contentBorder="1px solid #E1E1E8"
              provider={provider}
            />
          ))
        : emptyArray.map((_, i) => (
            <LoadingTrendingUserCard
              key={i}
              width={cardWidth}
              windowWidth={windowWidth}
              headerBg="#212121"
              contentBorder="1px solid #E1E1E8"
            />
          ))}
      <div ref={bottomPaddingRef} style={{ height: 1 }} />
    </View>
  )
}

export default UserCardsList
