import { Chart } from 'chart.js'
import { externalTooltipHandler } from './tooltip'
export default function(
  labels,
  barData,
  lineData,
  invisibleData,
  bgColor,
  eventType
) {
  const ctx = document.getElementById('myChart').getContext('2d')
  Chart.defaults.font.family = 'Satoshi'
  Chart.defaults.font.weight = '300'

  const gradient = ctx.createLinearGradient(0, 320, 0, 0)
  gradient.addColorStop(0, 'rgba(255, 255, 255, 0)')
  gradient.addColorStop(0.1, 'rgba(255, 255, 255, 0)')
  gradient.addColorStop(1, 'rgba(255, 255, 255, 0.3)')

  const chart = new Chart(ctx, {
    data: {
      labels: labels,
      datasets: [
        {
          data: barData,
          backgroundColor: bgColor,
          borderRadius: { topLeft: 2, topRight: 2 },
          pointRadius: 0,
          pointHitRadius: 0,
          barThickness: 3,
          type: 'bar',
          borderSkip: false,
          hitRadius: 0,
          yAxisID: 'volume',
          order: 2
        },

        {
          data: lineData,
          borderColor: '#fff',
          backgroundColor: gradient,
          pointBackgroundColor: '#0698A8',
          pointBorderColor: 'white',
          pointBorderWidth: 0,
          hoverBorderWidth: 2,
          hoverBorderColor: 'white',
          pointRadius: 0,
          hoverRadius: 5,
          tension: 0.1,
          type: 'line',
          fill: true,
          order: 0,
          yAxisID: 'price'
        },
        {
          data: invisibleData,
          backgroundColor: 'transparent',
          pointRadius: 0,
          pointHitRadius: 0,
          barThickness: 0,
          type: 'bar',
          borderSkip: true,
          hitRadius: 0,
          yAxisID: 'volume',
          order: 2
        }
      ]
    },
    options: {
      legend: {
        display: false
      },
      interaction: {
        mode: 'index',
        intersect: false
      },
      transitions: {
        show: {
          animations: {
            y: {
              duration: 1200,
              from: 500,
              easing: 'easeOutExpo'
            }
          }
        },
        hide: {
          animations: {
            y: {
              duration: 0
            },
            visible: {
              type: 'boolean',
              duration: 0
            }
          }
        }
      },
      plugins: {
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: ctx => externalTooltipHandler(ctx, eventType)
        },
        filler: {
          propagate: true
        }
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        volume: {
          type: 'linear',
          display: true,
          grid: {
            display: false,
            drawTicks: false,
            drawBorder: false
          },
          ticks: {
            display: false,
            stepSize: 500
          }
        },
        price: {
          type: 'linear',
          position: 'left',
          display: true,
          beginAtZero: true,

          ticks: {
            padding: 20,
            color: 'rgba(255, 255, 255, 0.5)',
            maxTicksLimit: 3
          },
          grid: {
            drawTicks: false,
            drawBorder: false,
            color: 'rgba(255, 255, 255, 0.5)',
            borderDash: [4, 8]
          }
        },
        x: {
          offset: false,
          beginAtZero: true,
          align: 'start',
          ticks: {
            align: 'inner',
            padding: 20,
            color: 'rgba(255, 255, 255, 0.5)',
            callback: function(value, index, labels) {
              return index === 0 || index === labels.length - 1
                ? this.getLabelForValue(value)
                : null
            }
          },
          grid: {
            display: false,
            drawBorder: false,
            color: 'rgba(255, 255, 255, 1)'
          }
        }
      }
    }
  })
  chart.options.transitions.show.colors = false

  return chart
}
