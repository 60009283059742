import styled from '@emotion/styled/macro'
import Link from 'components/Forms/Link'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import { useTopBarStyle } from 'contexts/TopBarStyleProvider'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link as RouteLink, useLocation } from 'react-router-dom'
import AccountButton from './AccountButton'
import Divider from './Divider'
import Logo from './Logo'
import MobileNavContent from './MobileNavContent'
import MyCartButton from './MyCart/MyCart'
import QuickSearch from './QuickSearch'
import TopbarContainer from './TopbarContainer'

const DefaultTopBar = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [navBarExpanded, setNavBarExpanded] = useState(false)
  const { style } = useTopBarStyle()
  const location = useLocation()
  const history = useHistory()

  if (location.pathname === '/' && window.innerWidth < 740) {
    history.push('/marketplace')
  }

  const handleGoChat = () => {
    if (!location.pathname.includes('chats')) {
      history.push('/chats', 10)
    }
  }

  const onAccountStatusChange = useCallback(
    status => {
      setLoggedIn(status)
    },
    [setLoggedIn]
  )

  useEffect(() => {
    setNavBarExpanded(false)
  }, [location.pathname])

  return (
    <View>
      <TopbarContainer
        background={style.topBarBgColor}
        boxShadow={navBarExpanded ? 'none' : style.topBarBoxShadow}
      >
        <View alignItems="center" flex={1}>
          <Logo src={style.logo} />
          {window.innerWidth > 740 && (
            <>
              <RouteLink to="/marketplace">
                <Link hoverOpacity={0.6}>
                  <Label size={18} weight="800" color={style.pageLinkColor}>
                    Marketplace
                  </Label>
                </Link>
              </RouteLink>
              <Space size={30} />
              <RouteLink to="/registry">
                <Link hoverOpacity={0.6}>
                  <Label size={18} weight="800" color={style.pageLinkColor}>
                    Registry
                  </Label>
                </Link>
              </RouteLink>
              <Space size={30} />
              <RouteLink to="/feed">
                <Link hoverOpacity={0.6}>
                  <Label size={18} weight="800" color={style.pageLinkColor}>
                    Activity
                  </Label>
                </Link>
              </RouteLink>
            </>
          )}
        </View>
        {window.innerWidth > 740 ? (
          loggedIn ? (
            <View alignItems="center">
              <QuickSearch color={style.quickSearchButtonColor} />
              <Divider color={style.dividerColor} />
              <Link
                style={{ zIndex: 2 }}
                padding="20px"
                onClick={handleGoChat}
                hoverOpacity="0.6"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 7C1 3.68629 3.68629 1 7 1H13C16.3137 1 19 3.68629 19 7V13C19 16.3137 16.3137 19 13 19H1V7Z"
                    stroke={style.chatButtonColor}
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              <Space size={40} />
              <AccountButton
                color={style.accountButtonColor}
                background={'transparent'}
                onAccountStatusChange={onAccountStatusChange}
              />
              <Space size={10} />
              <MyCartButton
                iconBackground={style.myCartButtonColor}
                iconColor={style.myCartButtonIconColor}
              />
            </View>
          ) : (
            <View alignItems="center" gap="20px">
              <QuickSearch color={style.quickSearchButtonColor} />
              <AccountButton
                color={style.connectWalletButtonColor}
                background="transparent"
                onAccountStatusChange={onAccountStatusChange}
              />
            </View>
          )
        ) : (
          <View
            direction="column"
            margin="0 10px 0 0"
            gap="5px"
            onClick={() => setNavBarExpanded(!navBarExpanded)}
          >
            <NavTopLine
              expanded={navBarExpanded}
              background={style.pageLinkColor}
            />
            <NavMiddleLine
              expanded={navBarExpanded}
              background={style.pageLinkColor}
            />
            <NavBottomLine
              expanded={navBarExpanded}
              background={style.pageLinkColor}
            />
          </View>
        )}
      </TopbarContainer>
      <ExpandedNavContent
        expanded={navBarExpanded}
        background={style.background}
      >
        <MobileNavContent
          primaryColor={style.pageLinkColor}
          secondaryColor={style.myCartButtonColor}
          background={style.background}
          closeNavbar={() => setNavBarExpanded(false)}
        />
      </ExpandedNavContent>
    </View>
  )
}

export default DefaultTopBar

const NavTopLine = styled.div`
  height: 3px;
  width: ${props => (props.expanded ? '20px' : '8px')};
  background: ${props => props.background};
  border-radius: 50px;
  transition: all 0.15s ease-out;
  ${props =>
    props.expanded
      ? 'transform: rotate(45deg) translateY(6px) translateX(5px);'
      : ''}
`
const NavMiddleLine = styled.div`
  height: 3px;
  width: 16px;
  background: ${props => props.background};
  border-radius: 50px;
  transition: all 0.15s ease-out;
  ${props => (props.expanded ? 'transform: translateX(200px);' : '')}
`
const NavBottomLine = styled.div`
  height: 3px;
  width: ${props => (props.expanded ? '20px' : '8px')};
  background: ${props => props.background};
  border-radius: 50px;
  transition: all 0.15s ease-out;
  margin-left: ${props => (props.expanded ? '0' : '8px')};
  ${props =>
    props.expanded
      ? 'transform: rotate(-45deg) translateY(-6px) translateX(5px);'
      : ''}
`

const ExpandedNavContent = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 1;
  background: ${props => props.background};
  transition: all 0.25s ease-out;
  ${props => (props.expanded ? '' : 'transform: translateY(-100vh);')}
`
