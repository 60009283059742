import React from 'react'
import styled from '@emotion/styled/macro'

const Spinner = styled.div`
  margin: ${props => (props.margin ? props.margin : '0')};
  ${props => props.scale && `transform: scale(${props.scale});`}

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;

    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: ${props => (props.color ? props.color : '#000')};
    animation: spinner 0.8s linear infinite;
  }
`

const LoadingSpinner = ({ margin, scale, color }) => {
  return <Spinner margin={margin} scale={scale} color={color} />
}

export default LoadingSpinner
