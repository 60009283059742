import styled from '@emotion/styled/macro'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'

const NotableAccountCard = ({
  name,
  title,
  domains,
  followers,
  avatar,
  headerBg,
  headerColor,
  contentBorder,
  onClick,
  isActive
}) => {
  return (
    <StyledCard onClick={onClick} background={headerBg} isActive={isActive}>
      <View width="100%" height="100%" direction="column">
        <View height="60px" padding="0px 20px" alignItems="center">
          <Label
            size={16}
            color={isActive ? 'transparent' : headerColor || '#fff'}
          >
            {title}
          </Label>
        </View>
        <StyledCardContent isActive={isActive} border={contentBorder}>
          <ContentContainer isActive={isActive}>
            <MyAvatar
              width="100px"
              height="100px"
              src={avatar}
              borderRadius="6px"
              background="#7c5151"
              isActive={isActive}
            />
            <Space size={20} />
            <Name isActive={isActive}>{name}</Name>
          </ContentContainer>

          <SubtitleContainer isActive={isActive}>
            <Label size={16} color="#CAD0D2">
              <Label color="#020202">{domains} </Label>
              DOMAINS
            </Label>
            <Label size={16} color="#CAD0D2">
              <Label color="#020202">{followers} </Label>
              followers
            </Label>
          </SubtitleContainer>
        </StyledCardContent>
      </View>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  width: ${props => (props.isActive ? '100%' : '310px')};
  height: ${props => (props.isActive ? '500px' : '430px')};
  overflow: hidden;
  border-radius: 10px;
  background-color: ${props =>
    props.background ? props.background : '#212121'};
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  flex-direction: column;
  overflow: visible;
  height: 80%;
`

const StyledCardContent = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  flex: 1;
  border-radius: 10px;
  border: ${props => props.border};
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transform: ${props =>
    props.isActive ? 'translateY(-60px)' : 'translateY(0)'};
`

const MyAvatar = styled.img`
  width: ${props => props.width};
  border-radius: ${props => props.borderRadius};
  background: ${props => props.background};
  min-width: ${props => (props.isActive ? '100%' : '90px')};
  min-height: ${props => (props.isActive ? '100%' : '90px')};
  position: absolute;
  top: ${props => (props.isActive ? '50%' : '40%')};
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  object-fit: cover;
`

const Name = styled.span`
  position: absolute;
  bottom: ${props => (props.isActive ? '-10%' : '33%')};
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 30px;
  color: ${props => (props.isActive ? '#fff' : '#020202')};
`

const SubtitleContainer = styled.div`
  display: flex;
  padding: ${props => (props.isActive ? '0' : '44px 44px')};
  justify-content: space-between;
  width: 100%;
  opacity: ${props => (props.isActive ? '0' : '1')};
  height: ${props => (props.isActive ? '0%' : '100%')}
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
`

export default NotableAccountCard
