'use strict'

import gsap, { Expo } from 'gsap/all'

class InViewCard {
  constructor(el) {
    this.el = el || document.body
    this.elems = [...this.el.querySelectorAll('[data-card]')]
    this.cache = null
    this.options = null
    this.observer = null

    this.init()
  }

  createObserver() {
    this.options = {
      root: null,
      rootMargin: '0px 0px -10% 0px',
      threshold: [0, 0]
    }
    this.observer = new IntersectionObserver(
      this.handler.bind(this),
      this.options
    )
  }

  getCache() {
    this.cache = []
    this.elems.forEach(el => {
      this.cache.push({
        el: el,
        animation: el.dataset.in,
        isIntersected: false,
        elems: null,
        split: null,
        tl: null
      })
    })
  }

  setAnimation(elem) {
    elem.tl = gsap.timeline({ paused: true })

    elem.tl.fromTo(
      elem.el,
      0.75,
      {
        y: 30,
        alpha: 0
      },
      {
        y: 0,
        alpha: 1,
        ease: Expo.easeOut
      },
      0.025,
      0
    )

    elem.tl.progress(1).progress(0)
  }

  handler(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        let i = this.elems.indexOf(entry.target)
        let elem = this.cache[i]

        elem.isIntersected = true

        elem.tl.play()

        this.stillObserving()
          ? this.observer.unobserve(entry.target)
          : this.observer.disconnect()
      } else {
        return
      }
    })
  }

  stillObserving() {
    return this.cache.some(e => !e.isIntersected)
  }

  destroy() {
    this.observer.disconnect()
    this.observer = null
    this.elems = null
    this.cache = null
  }

  run() {
    this.cache.forEach(elem => {
      this.setAnimation(elem)

      this.observer.observe(elem.el)
    })
  }

  init() {
    this.getCache()

    this.createObserver()

    this.run()
  }
}

export default InViewCard
