import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

const frameCount = 80
const feedFrameCount = 160

const createImages = frameCount => {
  const images = []

  for (let i = 0; i < frameCount; i++) {
    const img = new Image()
    images.push(img)
  }

  return images
}

const loadImages = (sequenceTypeIndex, images) => {
  images.forEach((image, i) => {
    image.src =
      sequenceTypeIndex === 1
        ? currentFrameHero(i * 2)
        : sequenceTypeIndex === 2
        ? currentFrameNotable(i * 2)
        : currentFrameFeed(i)
  })
}

const currentFrameHero = index =>
  require(`assets/sequence/hero/1_${index.toString().padStart(4, '0')}.webp`)
    .default

const currentFrameNotable = index =>
  require(`assets/sequence/notable/2_${index.toString().padStart(4, '0')}.webp`)
    .default

const currentFrameFeed = index =>
  require(`assets/sequence/feed/KOD${index
    .toString()
    .padStart(4, '0')}-min.webp`).default

export const ImagePreloaderContext = createContext({
  imagesSequence: {},
  notableSequence: {},
  feedSequence: {},
  imageLoading: 100,
  loading: true,
  finishAnimation: () => {},
  frameCount,
  canStartAnimatePage: false,
  onPreloaderHalfCompleted: value => {}
})

const ImagePreloaderProvider = ({ children }) => {
  const [imagesSequence, setImagesSequence] = useState({})
  const [notableSequence, setNotableSequence] = useState({})
  const [feedSequence, setFeedSequence] = useState({})
  const [imageLoading, setImageLoading] = useState(100)
  const [allImageCount, setAllImageCount] = useState(160 + 80 + 80)
  const [animationFinished, setAnimationFinished] = useState(false)
  const [canStartAnimatePage, setCanStartAnimatePage] = useState(false)

  useEffect(() => {
    const images = createImages(frameCount)
    const notableImages = createImages(frameCount)
    const feedImages = createImages(feedFrameCount)

    Promise.all(
      [...images, ...notableImages, ...feedImages].map(image =>
        preloadImage(image)
      )
    ).then(() => {
      setImageLoading(0)
    })
    loadImages(1, images)
    setImagesSequence({ images, frameCount })

    loadImages(2, notableImages)
    setNotableSequence({ images: notableImages, frameCount })

    loadImages(3, feedImages)
    setFeedSequence({ images: feedImages, frameCount: feedFrameCount })
  }, [setImagesSequence, setNotableSequence, setFeedSequence])

  useEffect(() => {
    setImageLoading(Math.floor((allImageCount * 100) / 320))
  }, [allImageCount])

  const preloadImage = image => {
    return new Promise(resolve => {
      image.onload = () => {
        setAllImageCount(c => c - 1)
        resolve()
      }
    })
  }

  const finishAnimation = useCallback(() => {
    setAnimationFinished(true)
  }, [setAnimationFinished])

  const onPreloaderHalfCompleted = useCallback(
    value => {
      setCanStartAnimatePage(value)
    },
    [setCanStartAnimatePage]
  )

  return (
    <ImagePreloaderContext.Provider
      value={{
        imagesSequence,
        notableSequence,
        feedSequence,
        imageLoading: imageLoading,
        loading: !animationFinished,
        finishAnimation,
        canStartAnimatePage,
        onPreloaderHalfCompleted
      }}
    >
      {children}
    </ImagePreloaderContext.Provider>
  )
}

export function useImagePreloader() {
  const context = useContext(ImagePreloaderContext)
  if (context === undefined) {
    throw new Error('useCount must be used within a PreloaderProvider')
  }
  return context
}

export default ImagePreloaderProvider
