import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import View from 'components/View'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as EthPriceWhite } from 'assets/eth-price-white.svg'

const ListedModal = ({ open, onClose, domain }) => {
  const history = useHistory()

  const [listedDomain, setListedDomain] = useState()

  useEffect(() => {
    if (!open || !domain) return

    const loadItem = JSON.parse(
      localStorage.getItem(`${domain.replace('.eth', '')}-listing`)
    )

    if (!loadItem) {
      history.push(`/domain/${domain}`)
      // onClose()
      return
    }

    if (loadItem?.listingModalShown) {
      history.push(`/domain/${domain}`)
      // onClose()
      return
    }

    setListedDomain(loadItem)
    localStorage.removeItem(`${loadItem.name}-listing`)
    localStorage.setItem(
      `${loadItem.name}-listing`,
      JSON.stringify({
        name: loadItem.name,
        price: loadItem.price,
        expires: loadItem.expires,
        listingModalShown: true
      })
    )
  }, [])

  return (
    <View
      height="320px"
      width="580px"
      background="#0698A8"
      //   right={`${window.innerWidth / 2 - 290}px`}
      padding="40px"
      direction="column"
      justify="space-between"
      alignItems="center"
      borderRadius="10px"
    >
      <View>
        <Label size={40}>Listing successfull</Label>
      </View>
      <View width="100%" justify="space-between" alignItems="center">
        <Label>{listedDomain?.name}.eth</Label>
        <View alignItems="center" gap="8px">
          <EthPriceWhite height={30} width={30} />
          <Label>{listedDomain?.price}</Label>
        </View>
      </View>
      <View justify="space-between" width="100%">
        <RoundedButton
          color="white"
          border="1px solid white"
          background="transparent"
          borderRadius="6px"
          height="60px"
          width="245px"
          onClick={() => {
            // history.push(`/domain/${listedDomain.name}.eth`)
            onClose()
          }}
        >
          Back to domain
        </RoundedButton>
        <RoundedButton
          color="black"
          border="1px solid white"
          background="white"
          borderRadius="6px"
          width="245px"
          height="60px"
          onClick={() => {
            history.push('/my-profile?listed')
          }}
        >
          View in listings
        </RoundedButton>
      </View>
    </View>
  )
}

export default ListedModal
