import { reservoirAPIURL } from 'utils/reservoir'

export const getListing = async params => {
  const { address, token_id, maker, limit = 7, offset = 0 } = params
  try {
    const req = fetch(
      `${reservoirAPIURL}/orders/asks/v4?${
        token_id ? `token=${address}:${token_id}&` : ''
      }${maker ? `maker=${maker}&` : ''}status=active`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )

    const res = await req.then(res => res).then(data => data.json())

    return res?.orders || []
  } catch (err) {
    throw new Error(err)
  }
}
