import { useCallback, useState } from 'react'
import styled from '@emotion/styled/macro'
import BottomModal from '../BottomModal'
import View from '../View'
import Space from '../Space'
import Container from '../Layout/Container'
import FollowingsTab from './FollowingsTab'
import FollowersTab from './FollowersTab'

const TABS = ['Following', 'Followers']

const FollowerModal = ({
  user,
  active,
  open,
  onClose,
  refetchFollowers,
  refetchFollowings
}) => {
  const [tab, setTab] = useState(!!active ? active : TABS[0])

  const close = useCallback((afterAnimateCallback, data) => {
    if (afterAnimateCallback != null) {
      afterAnimateCallback()
    } else {
      onClose(data)
    }
  }, [])

  return (
    <BottomModal
      onClose={() => close(null, null)}
      open={open}
      contentBackground="#fff"
    >
      <Container maxWidth="940px">
        <View direction="column" width="100%">
          <TabsContainer>
            {TABS.map(t => (
              <Tab active={tab === t} onClick={() => setTab(t)} key={t}>
                {t}
              </Tab>
            ))}
          </TabsContainer>
          <Space size={20} />

          {tab === TABS[0] && (
            <FollowingsTab
              close={close}
              user={user}
              refetchFollowers={refetchFollowers}
              refetchFollowings={refetchFollowings}
            />
          )}
          {tab === TABS[1] && (
            <FollowersTab
              close={close}
              user={user}
              refetchFollowers={refetchFollowers}
              refetchFollowings={refetchFollowings}
            />
          )}
        </View>
      </Container>
    </BottomModal>
  )
}

export default FollowerModal

// TODO: Replace with agreed component
export const Tab = styled('div')`
  font-size: 16px;
  background: white;
  color: ${({ active }) => (active ? '#020202' : '#BCBCCC')};
  transition: all 0.1s ease-out;
  padding: 17px 20px;
  border-radius: ${({ active }) => (active ? '6px' : '0')};
  flex: 1;
  display: flex;
  font-family: 'Satoshi', sans-serif;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:hover,
  &:visited {
    cursor: pointer;
  }
`

export const TabsContainer = styled('div')`
  display: inline-flex;
  overflow: hidden;
`
