import { ens_normalize } from '@adraffy/ens-normalize'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled/macro'
import { getSigner } from '@ensdomains/ui'
import ConfirmationImg from 'assets/abstract-orb.png'
import SeeMore from 'assets/domain-profile-see-more.png'
import ENSIconLogoWhite from 'assets/ensIconLogoWhite.svg'
import { ReactComponent as HeartIconBlackFill } from 'assets/heart-icon-black-fill.svg'
import { ReactComponent as HeartIconBlack } from 'assets/heart-icon-black.svg'
import { ReactComponent as HeartIconWhiteFill } from 'assets/heart-icon-white-fill.svg'
import { ReactComponent as HeartIconWhite } from 'assets/heart-icon-white.svg'
import DefaultAvatarImg from 'assets/icon-pfp-default.png'
import LinkIcon from 'assets/linkIcon.svg'
import LinkIconWhite from 'assets/linkIconWhite.svg'
import { ReactComponent as Minus } from 'assets/minus.svg'
import { ReactComponent as Plus } from 'assets/plus.svg'
import { ReactComponent as NameWarning } from 'assets/warning-name.svg'
import { useWindowListener } from 'common/useWindowListener'
import AcceptSuccessModal from 'components/AcceptModal/AcceptSuccessModal'
import Avatar from 'components/Avatar'
import BottomModal from 'components/BottomModal'
import NetTokenCard, {
  getRegistrationStatus
} from 'components/Card/NetTokenCard'
import CategoriesBox from 'components/CategoriesDisplayBox'
import { OfferDialog } from 'components/Dialogs'
import ListedModal from 'components/Dialogs/ListedModal'
import TransferDialog from 'components/Dialogs/TransferDialog'
import Link from 'components/Forms/Link'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import LoadingSpinner from 'components/LoadingSpinner'
import PremiumRegistry from 'components/PremiumRegistry'
import { useAccount } from 'components/QueryAccount'
import KodexSidebar from 'components/Sidebar'
import SimilarSection from 'components/SimilarSection'
import SingleRegistration from 'components/SingleRegistration'
import Space from 'components/Space'
import View from 'components/View'
import { TAGS } from 'constants/category'
import { CartStoreContext } from 'contexts/CartStoreProvider'
import {
  TopBarStyleContext,
  topBarMarketplaceStyle,
  topBarRegistryStyle
} from 'contexts/TopBarStyleProvider'
import { BigNumber, ethers } from 'ethers'
import useDomainResolver from 'hooks/useDomainResolver'
import { useEthPrice } from 'hooks/useEthPrice'
import useGetDomainOwner from 'hooks/useGetDomainOwner'
import useSimilarDomains from 'hooks/useSimilarDomains'
import useUserProfile from 'hooks/useUserProfile'
import moment from 'moment'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useLocation } from 'react-router'
import { useHistory, useParams } from 'react-router-dom'
import {
  GET_DOMAIN_ACTIVITY_QUERY,
  GET_LIKES_BY_DOMAIN,
  kodexApiContext
} from 'service/graphql/kodex-api'
import {
  GET_BALANCE,
  GET_LAST_SALE,
  GET_REGISTRATIONS_BY_LABELNAME
} from 'service/graphql/queries'
import { getListing } from 'service/rest/getListing'
import { getOffers } from 'service/rest/getOffers'
import { JETTY_URL } from 'service/siwe/constants'
import { siweAuthedFetch } from 'service/siwe/siweFetch'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import { formatETHPrice } from 'utils/format'
import { actions } from 'utils/reservoir'
import {
  ENS_CONTRACT_ADDRESS,
  EXCHANGE_CONTRACT_ADDRESS,
  WETH_TOKEN_ADDRESS,
  getERC20TokenContract
} from 'utils/utils'
import { calculateIfPremium } from 'views/Checkout/components/calculateIfPremium'
import PremiumChart from '../../components/PremiumRegistry/Chart'
import ActivityAndOffers from './ActivityAndOffers'
import Details from './Details'
import MobileRegisteredDomain from './MobileRegisteredDomain'
import PriceHistory from './PriceHistory'
import LikeAccountsList from './components/LikeAccountsList'
import { getActivitiyData } from 'service/rest/getActivityData'

const RegisteredDomain = () => {
  const history = useHistory()
  const noAnimateHistory = useHistory()

  if (window.innerWidth < 740) {
    return <MobileRegisteredDomain />
  }

  const { name } = useParams()
  const location = useLocation()
  // const [active, setActive] = useState(false)
  const [listed, setListed] = useState(false)
  const [listedModalOpen, setListedModalOpen] = useState(
    location.search?.replace('?', '')?.includes('listed')
  )
  const [isBuying, setBuying] = useState(false)
  const [domainInCart, setDomainInCart] = useState(false)
  const [offers, setOffers] = useState([])
  const [highestOffer, setHighestOffer] = useState(0)
  const [openOfferDialog, setOpenOfferDialog] = useState(false)
  const [openTransferDialog, setOpenTransferDialog] = useState(false)
  const [domainOrder, setDomainOrder] = useState([])
  const [domainsIsInCartArr, setDomainsIsInCartArr] = useState([])
  const [cancelingListing, setCancelingListing] = useState(undefined)
  const [registrationModalOpen, setRegistrationModalOpen] = useState(false)
  const [domainIsPremium, setDomainIsPremium] = useState(false)
  const [listingCanceled, setListingCanceled] = useState(false)
  const [listingPurchased, setListingPurchased] = useState(false)
  const [cancelingIndex, setCancelingIndex] = useState(undefined)
  const [acceptingIndex, setAcceptingIndex] = useState(undefined)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [registrationPrice, setRegistrationPrice] = useState(0)
  // const [premiumRegistrationPrice, setPremiumRegistrationPrice] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [addToCartHovered, setAddToCartHovered] = useState(false)

  const [usesSpecialCharacters, setUsesSpecialCharacters] = useState(false)
  const [displayWarningMessage, setDisplayWarningMessage] = useState(false)
  // const [openSimilarOfferDialog, setOpenSimilarOfferDialog] = useState(false)
  // const [similarOfferedDomainName, setSimilarOfferedDomainName] = useState('')
  // const [
  //   similarOfferedDomainlastPrice,
  //   setSimilarOfferedDomainLastPrice
  // ] = useState('')

  const [listingPurchasedPrice, setListingPurchasedPrice] = useState(0)
  const [listingPurchasedTxHash, setListingPurchasedTxHash] = useState('')
  const [isNotEnoughETH, setIsNotEnoughETH] = useState(false)
  const [registrations, setRegistrations] = useState([])
  const [activity, setActivity] = useState([])

  const [isOwned, setIsOwned] = useState(false)

  if (name.length <= 6) noAnimateHistory.push('/marketplace')

  useEffect(() => {
    if (windowWidth <= 640) {
      history.push('/marketplace')
      return
    }

    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant'
    })
  }, [])

  useEffect(() => {
    setListedModalOpen(
      location.search?.replace('?', '')?.includes('listed') &&
        !location.search?.replace('?', '')?.includes('unlisted')
    )
  }, [location.search])

  useEffect(() => {
    if (
      !name ||
      name.split('.')?.[1]?.length < 3 ||
      name.split('.')?.[0]?.length < 3 ||
      !name.includes('.')
    ) {
      // history.push(`/registry?search=${name.split('.')[0]}`)
      history.push(`/registry`)
      return
    }

    try {
      const normalizedName = ens_normalize(name)
      if (normalizedName) setUsesSpecialCharacters(false)
    } catch (e) {
      setUsesSpecialCharacters(true)
    }

    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
  }, [name])

  // Seeds for manual updating of certain components
  const [offersSeed, setOffersSeed] = useState(1)
  const [headerSeed, setHeaderSeed] = useState(2)
  const [pageSeed, setPageSeed] = useState(3)
  const resetHeader = () => {
    setHeaderSeed(Math.random())
  }
  const resetOffers = () => {
    setOffersSeed(Math.random())
    setHeaderSeed(Math.random())
  }

  const resetPage = () => {
    setPageSeed(Math.random())
  }

  const {
    state: { domains: selectedDomains },
    addNewDomain,
    removeDomain
  } = useContext(CartStoreContext)

  const contentRef = useRef(null)

  const cartCtx = useContext(CartStoreContext)

  const account = useAccount()

  const ethPrice = useEthPrice()

  const { data: { registrationsData } = {} } = useQuery(
    GET_REGISTRATIONS_BY_LABELNAME,
    {
      variables: { labelName: name.replace('.eth', '') }
    }
  )

  const {
    isLikedByUser,
    isListed,
    listingPrice,
    listingExpireTime,
    toggleLike,
    likesCount,
    isRegistered,
    refetchDataFn,
    views,
    price,
    likesAmount,
    expirationStatus,
    categories,
    expires,
    listedOn
  } = useDomainResolver(
    {
      name: name
    },
    undefined,
    listingPurchased
  )

  const { data: saleData, refetch: refetchLastSale } = useQuery(GET_LAST_SALE, {
    variables: { domain: name },
    ...kodexApiContext
  })

  const lastPrice = saleData?.ens_last_sales[0]?.price || undefined
  const lastPriceAsset = saleData?.ens_last_sales[0]?.saleasset || undefined

  const tokenId = useMemo(() => {
    if (name) {
      const nameLabel = name.replace('.eth', '')
      return ethers.BigNumber.from(
        ethers.utils.keccak256(ethers.utils.toUtf8Bytes(nameLabel))
      ).toString()
    }

    return ''
  }, [name])

  const tokenIdHex = useMemo(() => {
    if (name) {
      const nameLabel = name.replace('.eth', '')
      return ethers.BigNumber.from(
        ethers.utils.keccak256(ethers.utils.toUtf8Bytes(nameLabel))
      ).toHexString()
    }

    return ''
  }, [name])

  useEffect(() => {
    const fetchViews = async () => {
      const reqBody = { token_id: tokenIdHex }

      try {
        const req = siweAuthedFetch(`${JETTY_URL}/user/viewed`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(reqBody)
        })

        const res = await req.then(res => res)
      } catch (e) {
        throw new Error(e)
      }
    }

    fetchViews()
  }, [account])

  useEffect(() => {
    // Checking if the domain is in cart to update the state
    const [domainIsInCart] = cartCtx.state.domains.filter(domain =>
      domain.name.includes(name)
    )
    if (domainIsInCart) {
      setDomainInCart(true)
    } else {
      setDomainInCart(false)
    }
  }, [name])

  useEffect(() => {
    document.title = name ? `${name} | Kodex` : 'Domain | Kodex'
  }, [name])

  const fetchAllowance = useCallback(async () => {
    if (account) {
      const wethContract = await getERC20TokenContract(WETH_TOKEN_ADDRESS)
      const allowance = await wethContract.allowance(
        account,
        EXCHANGE_CONTRACT_ADDRESS
      )
    }
  }, [account])

  useEffect(() => {
    fetchAllowance()
  }, [fetchAllowance])

  // const handleApproveAllowance = async () => {
  //   if (isApproving) return

  //   try {
  //     setApproving(true)
  //     const wethContract = await getERC20TokenContract(WETH_TOKEN_ADDRESS)
  //     const tx = await wethContract.approve(
  //       EXCHANGE_CONTRACT_ADDRESS,
  //       ethers.constants.MaxUint256
  //     )
  //     await tx.wait()
  //     await fetchAllowance()
  //     setApproving(false)
  //   } catch (err) {
  //     setApproving(false)
  //     throw new Error(err)
  //   }
  // }

  const handleCancelListing = async () => {
    try {
      const [listingId] = domainOrder.map(listing => listing.id)
      const signer = await getSigner()

      // const tokenId = ethers.BigNumber.from(
      //   ethers.utils.keccak256(ethers.utils.toUtf8Bytes(domain.name))
      // ).toHexString()

      setCancelingListing(true)

      await actions.cancelOrder({
        id: listingId,
        signer,
        onProgress: step => {
          if (step[0].items[0].status === 'complete') {
            setListed(false)
            setCancelingListing(false)
            setListingCanceled(true)
            resetHeader()
            resetPage()
          }
        }
      })
    } catch (err) {
      setCancelingListing(false)
      throw new Error(err)
    }
  }

  useEffect(() => {
    if (!expires) return

    const [domainIsInCart] = cartCtx.state.domains.filter(domain =>
      domain.name.includes(name)
    )

    if (domainIsInCart) {
      setDomainInCart(true)
    } else {
      setDomainInCart(false)
    }
  }, [cartCtx.state.domains])

  const handleSeeMoreClick = () => {
    // since we changed the output to 100 im not sure if we should change from 60 to 100 here, it works without but could be affecting something else.
    history.push(`/search?query=${name}&searchType=similar`, 60)
  }

  const [likedProfiles, setLikedProfiles] = useState([])
  const [likedModalIsOpen, setLikedModalIsOpen] = useState(false)
  const [domainIsMine, setDomainIsMine] = useState(false)
  const [isCopied, setCopied] = useState(false)

  const labelName = name.replace('.eth', '')

  const { ownerAddress } = useGetDomainOwner(labelName)

  const { profile } = useUserProfile(ownerAddress)
  const { picture: profilePicture } = profile.userInfo || {}
  const { likes } = profile

  const { data: likeAddresses, refetch: refetchLikeAddresses } = useQuery(
    GET_LIKES_BY_DOMAIN,
    {
      variables: {
        domain: name,
        offset: 0,
        limit: likes || 1000
      },
      ...kodexApiContext,
      fetchPolicy: 'no-cache'
    }
  )

  const topBarCtx = useContext(TopBarStyleContext)

  useEffect(() => {
    if (registrationsData) {
      setRegistrations(registrationsData)
      return
    }
  }, [registrationsData])

  useEffect(() => {
    topBarCtx.setStyle(
      isRegistered ? topBarMarketplaceStyle : topBarRegistryStyle
    )
  }, [isRegistered])

  const handleCart = e => {
    e.stopPropagation()
    if (!account) return

    const [domainIsInCart] = cartCtx.state.domains.filter(domain =>
      domain.name.includes(name)
    )

    if (domainIsInCart) {
      setDomainInCart(false)
      cartCtx.removeDomain(name, isRegistered ? 'PURCHASE' : 'REGISTER')
      return
    }

    const domainObjectTransformed = {
      name: domainName,
      expire_time: expires || null,
      price: Number(registrations[0]?.cost) || null,
      __typename: registrations[0]?.__typename || null
    }

    setDomainInCart(true)
    cartCtx.addNewDomain(
      domainObjectTransformed,
      isRegistered ? 'PURCHASE' : 'REGISTER'
    )
  }

  const handleGoSubCategory = useCallback(
    (e, category) => {
      e.stopPropagation()

      if (category) {
        const categoryKey = category
          .toLowerCase()
          .split(' ')
          .join('-')

        TAGS.forEach(tag => {
          if (tag.key.toLowerCase() === categoryKey.toLowerCase()) {
            history.push(`/marketplace?category=${tag.key.toLowerCase()}`)
          } else if (tag.subcategories.length > 0) {
            tag.subcategories.forEach(subCategory => {
              if (subCategory.key.toLowerCase() === categoryKey.toLowerCase()) {
                history.push(`/marketplace?category=${tag.key.toLowerCase()}`)
              }
            })
          }
        })
      }
    },
    [categories]
  )

  useEffect(() => {
    if (!isRegistered) {
      setIsOwned(false)
      return
    }
    if (moment().isBefore(expires)) {
      setIsOwned(true)
      return
    }
    setIsOwned(false)
  }, [isRegistered, expires])

  useEffect(() => {
    setListed(isListed)
  }, [isListed])

  useEffect(() => {
    refetchLikeAddresses()
  }, [likesAmount, likesCount])

  useEffect(() => {
    const likedProfilePicturesArray = likeAddresses?.likes
      ?.filter(d => d.liked)
      ?.map(likedUser => ({
        user: likedUser.user,
        username: likedUser.userByUser.username,
        picture: likedUser.userByUser.picture,
        twitter: likedUser.userByUser.twitter,
        instagram: likedUser.userByUser.instagram,
        weblink: likedUser.userByUser.weblink
      }))

    setLikedProfiles(likedProfilePicturesArray)
  }, [likeAddresses, isLikedByUser])

  const myProfile = useAccount()

  useEffect(() => {
    if (myProfile?.toLowerCase() === ownerAddress?.toLowerCase()) {
      setDomainIsMine(true)
    } else {
      setDomainIsMine(false)
    }
  }, [myProfile, ownerAddress])

  const fetchOrder = useCallback(async domainName => {
    if (domainName?.length === 0) return

    try {
      const tokenId = ethers.BigNumber.from(
        ethers.utils.keccak256(
          ethers.utils.toUtf8Bytes(domainName.replace('.eth', ''))
        )
      ).toString()

      const fetchedOrders = await getListing({
        address: ENS_CONTRACT_ADDRESS,
        token_id: tokenId
      })

      if (!fetchedOrders) {
        setDomainOrder([])
        return
      }

      setDomainOrder([...fetchedOrders])
    } catch (err) {
      throw new Error(err)
    }
  }, [])

  useEffect(() => {
    fetchOrder(name)
  }, [name, isListed])

  const handlePurchaseDomain = async () => {
    if (domainOrder.length > 0) {
      try {
        const signer = await getSigner()

        setBuying(true)

        await actions.buyToken({
          signer,
          items: [
            {
              token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
              quantity: 1,
              preferredOrderSource: 'kodex.io'
            }
          ],
          onProgress: step => {
            if (step[0].items[0].status === 'complete') {
              removeDomain(name, 'PURCHASE')
              setListingPurchasedPrice(listingPrice)
              setBuying(false)
              setListed(false)
              setListingPurchasedTxHash(receipt.transactionHash)
              setListingPurchased(true)
              resetHeader()
              resetPage()
            }
          }
        })
      } catch (err) {
        setBuying(false)
        setListingPurchasedPrice(0)
        throw new Error(err)
      }
    }
  }

  const fetchOffers = async () => {
    if (!ownerAddress) return

    try {
      const fetchedOffers = await getOffers({
        address: ENS_CONTRACT_ADDRESS,
        token_id: tokenId
      })

      if (!fetchedOffers) {
        setOffers([])
        return
      }

      const filteredOffers = fetchedOffers.filter(
        off => off.maker.toLowerCase() !== ownerAddress.toLowerCase()
      )

      setOffers([...filteredOffers])
    } catch (err) {
      throw new Error(err)
    }
  }

  useEffect(() => {
    fetchOffers()
  }, [tokenId, ownerAddress])

  const handleCancelOffer = async (offer, i) => {
    try {
      setCancelingIndex(i)

      const signer = await getSigner()

      await actions.cancelOrder({
        id: offer.id,
        signer,
        onProgress: step => {
          if (
            step[0]?.items[0]?.status &&
            step[0]?.items[0]?.status === 'complete'
          ) {
            setCancelingIndex(undefined)
            resetHeader()
            setOffers(currOffers => currOffers.filter(o => o.id !== offer.id))
          }
        }
      })
    } catch (err) {
      setCancelingIndex(undefined)
      throw new Error(err)
    }
  }
  const handleAcceptOffer = async (offer, i) => {
    if (offer) {
      try {
        const signer = await getSigner()

        setAcceptingIndex(i)

        await actions.acceptOffer({
          items: [
            {
              token: `${ENS_CONTRACT_ADDRESS}:${tokenId}`,
              quantity: 1
            }
          ],
          signer,
          onProgress: step => {
            if (step[0].items[0].status === 'complete') {
              setSuccessModalOpen(true)
              setAcceptingIndex(undefined)
              setOffers([])
              resetPage()
            }
          }
        })
      } catch (err) {
        setAcceptingIndex(undefined)
        throw new Error(err)
      }
    }
  }

  const { data: domainActivity, refetch: refetchDomainActivity } = useQuery(
    GET_DOMAIN_ACTIVITY_QUERY,
    {
      variables: {
        domain: name,
        limit: 20,
        offset: 0
      },
      ...kodexApiContext
    }
  )

  const fetchActivity = async () => {
    if (!name) {
      setActivity([])
      return
    }

    const res = await getActivitiyData({
      limit: 50,
      offset: 0,
      domain_name: name
    })

    setActivity(res)
  }

  useEffect(() => {
    fetchActivity()
  }, [name])

  const similarDomains = useSimilarDomains(name)

  useEffect(() => {
    const selectedDomainsNames = selectedDomains.map(domain => domain.name)
    const areInCartArr = similarDomains.map(domain =>
      selectedDomainsNames.includes(domain.name)
    )
    setDomainsIsInCartArr([...areInCartArr])
  }, [cartCtx, similarDomains, selectedDomains])

  // const handleOpenSimilarOfferDialog = (name, lastPrice) => {
  //   setSimilarOfferedDomainName(name)
  //   setSimilarOfferedDomainLastPrice(lastPrice)
  //   setOpenSimilarOfferDialog(true)
  // }

  useEffect(() => {
    if (!expires) return
    const getIfDomainPremium = calculateIfPremium(expires)

    if (getIfDomainPremium) {
      setDomainIsPremium(true)
    } else {
      setDomainIsPremium(false)
    }
  }, [expires, name])

  useEffect(() => {
    const registrationPriceDollars = (price * ethPrice).toLocaleString(
      'default',
      {
        maximumFractionDigits: 0
      }
    )

    setRegistrationPrice(registrationPriceDollars || 0)
  }, [price, ethPrice])

  // useEffect(() => {
  //   if (!registrations) return
  //   const premiumPrice = getPremiumPrice(
  //     registrations[0]?.expiryDate,
  //     moment().unix() * 1000
  //   )
  //   setPremiumRegistrationPrice(premiumPrice)
  // }, [registrations])

  useEffect(() => {
    if (
      !isRegistered &&
      expirationStatus !== 'grace period' &&
      !topBarCtx.scrolled
    ) {
      topBarCtx.setStyle(topBarRegistryStyle)
      return
    }
    if (
      (isRegistered || expirationStatus === 'grace period') &&
      !topBarCtx.scrolled
    ) {
      topBarCtx.setStyle(topBarMarketplaceStyle)
    }
  }, [isRegistered, topBarCtx, expirationStatus])

  useEffect(() => {
    setTimeout(() => {
      refetchDataFn()
      refetchLastSale()
      fetchActivity()
    }, 7500)
  }, [listingPurchased])

  const { data: { getBalance } = {} } = useQuery(GET_BALANCE, {
    variables: { address: account },
    fetchPolicy: 'no-cache'
  })

  const ethBalance = getBalance
    ? Number(BigNumber.from(getBalance).toString()) / 10 ** 18
    : 0

  const handleCopy = useCallback(() => {
    if (!isCopied) {
      setCopied(true)

      const el = document.createElement('textarea')
      el.value = name
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    }
  }, [isCopied, name])

  useEffect(() => {
    if (!isCopied) {
      return
    }

    const timeout = setTimeout(() => setCopied(false), 5000)

    return () => clearTimeout(timeout)
  }, [isCopied])

  const formatAddress = address => {
    return address ? address.substr(2, 7) : ''
  }

  const formatDate = date => {
    return moment(date).fromNow()
  }

  const isWhiteStyle = isRegistered || expirationStatus === 'grace period'

  const bodyColor = isWhiteStyle ? '#020202' : '#fff'
  const bodyBg = isWhiteStyle ? '#fff' : '#020202'
  const bodyBorder = isWhiteStyle ? '#CACADB' : '#4A4A4D'

  const darkerGradient =
    'linear-gradient(135deg, rgba(160, 153, 255, 0.3) 0%, rgba(114, 152, 248,0.3) 51.56%, rgba(68, 188, 240, 0.3) 100%)'

  const registeredGradient =
    'linear-gradient(135deg, #A099FF 0%, #7298F8 51.56%, #44BCF0 100%)'

  const gradient = isRegistered ? registeredGradient : darkerGradient

  const componentHeight = '500px'

  const addedToCart = selectedDomains.some(domain => domain.name === name)
  useWindowListener(
    'resize',
    () => {
      setWindowWidth(window.innerWidth)
    },
    []
  )

  const hasPriceContent =
    listingPrice || domainIsPremium || lastPrice !== undefined

  const whiteMode = isRegistered || expirationStatus === 'grace period'

  return (
    <>
      <View
        ref={contentRef}
        direction="column"
        width="100%"
        overflow="hidden"
        background={bodyBg}
      >
        <KodexSidebar
          opened={listingCanceled}
          onClose={() => setListingCanceled(false)}
        >
          <View
            direction="column"
            alignItems="center"
            justify="space-between"
            padding="10vh 1rem"
            background="#0698A8"
            height="98vh"
            margin="0 0 1rem 0"
            width="50vw"
            borderRadius="10px"
          >
            <View direction="column" alignItems="center">
              <Label size={40}>{name}</Label>
              <Space size={15} />
              <Label size={40} color="rgb(255 255 255 / 0.5)" align="center">
                Unlisted successfully
              </Label>
            </View>
            <Space size={80} />
            <img src={ConfirmationImg} />
            <Space size={80} />
            <RoundedButton
              width="300px"
              background="#ffffff"
              color="#000000"
              borderRadius="10px"
              height="60px"
              border="1px solid #EBEBF2"
              fontSize="16px"
              onClick={() => {
                setListingCanceled(false)
                noAnimateHistory.push(`/domain/${name}?unlisted`)
              }}
            >
              Go back
            </RoundedButton>
          </View>
        </KodexSidebar>
        <KodexSidebar
          opened={listingPurchased}
          onClose={() => {
            setListingPurchased(false)
            noAnimateHistory.push(`/domain/${name}?unlisted`)
          }}
        >
          <View
            direction="column"
            alignItems="center"
            justify="space-between"
            padding="10vh 1rem"
            background="#0698A8"
            height="98vh"
            margin="0 0 1rem 0"
            width="50vw"
            borderRadius="10px"
          >
            <View direction="column" alignItems="center">
              <Label size={40}>{name}</Label>
              <Space size={15} />
              <Label size={40} color="rgb(255 255 255 / 0.5)" align="center">
                Purchase successful for
              </Label>
              <Space size={15} />
              <Label size={40}>
                {Number(listingPurchasedPrice).toLocaleString(
                  navigator.language,
                  {
                    maximumFractionDigits: 3
                  }
                )}{' '}
                ETH
              </Label>
            </View>
            <Space size={80} />
            <img src={ConfirmationImg} />
            <Space size={80} />
            <RoundedButton
              width="350px"
              height="60px"
              background="#2898A8"
              color="#ffffff"
              borderRadius="10px"
              border="1px solid #EBEBF2"
              fontSize="16px"
              onClick={() =>
                window.open(
                  `https://etherscan.io/tx/${listingPurchasedTxHash}`,
                  '_blank'
                )
              }
            >
              View transaction
            </RoundedButton>
            <Space size={10} />
            <RoundedButton
              width="350px"
              height="60px"
              background="#ffffff"
              color="#000000"
              borderRadius="10px"
              border="1px solid #EBEBF2"
              fontSize="16px"
              onClick={() => {
                setListingPurchased(false)
                noAnimateHistory.push(`/domain/${name}?unlisted`)
              }}
            >
              Go back
            </RoundedButton>
          </View>
        </KodexSidebar>
        <BottomModal
          open={successModalOpen}
          onClose={() => setSuccessModalOpen(false)}
        >
          <AcceptSuccessModal onClose={() => setSuccessModalOpen(false)} />
        </BottomModal>
        <BottomModal
          open={listedModalOpen}
          onClose={() => setListedModalOpen(false)}
          transparent
        >
          <ListedModal
            open={listedModalOpen}
            onClose={() => setListedModalOpen(false)}
            domain={name}
          />
        </BottomModal>
        <BottomModal
          open={registrationModalOpen}
          onClose={() => setRegistrationModalOpen(false)}
          closeButtonLabel="Back to registry"
          contentPadding="0"
        >
          {domainIsPremium
            ? registrationModalOpen && (
                <PremiumRegistry
                  domain={{
                    name,
                    expire_time: expires || null
                  }}
                  closeLabel="Back to domain page"
                  closeRegistration={() => setRegistrationModalOpen(false)}
                  refetchState={() => {
                    noAnimateHistory.push(`/domain/${name}?registered`)
                  }}
                />
              )
            : registrationModalOpen && (
                <SingleRegistration
                  domain={{
                    name
                  }}
                  closeLabel="Back to domain page"
                  closeRegistration={() => setRegistrationModalOpen(false)}
                  refetchState={() => {
                    noAnimateHistory.push(`/domain/${name}?registered`)
                  }}
                />
              )}
        </BottomModal>
        <KodexSidebar
          opened={likedModalIsOpen}
          onClose={() => setLikedModalIsOpen(false)}
        >
          <LikeAccountsList likeAccounts={likedProfiles} />
        </KodexSidebar>
        <KodexSidebar
          positionDialogCenter={true}
          opened={openOfferDialog}
          onClose={() => setOpenOfferDialog(false)}
        >
          <OfferDialog
            domain={{
              name,
              lastPrice,
              lastPriceAsset,
              highestOffer
            }}
            onClose={() => setOpenOfferDialog(false)}
            backLabel="domain page"
            minWidth="900px"
            resetState={() => {
              fetchOffers()
              resetOffers()
              noAnimateHistory.push(`/domain/${name}?unlisted`)
            }}
          />
        </KodexSidebar>
        {/* This code below was used for when you could open offer dialog when clicking on the card footer */}
        {/* <KodexSidebar
          opened={openSimilarOfferDialog}
          onClose={() => setOpenSimilarOfferDialog(false)}
        >
          <OfferDialog
            domain={{
              name: similarOfferedDomainName,
              lastPrice: similarOfferedDomainlastPrice
            }}
            backLabel="account"
            onClose={() => setOpenSimilarOfferDialog(false)}
          />
        </KodexSidebar> */}
        <KodexSidebar
          opened={openTransferDialog}
          onClose={() => setOpenTransferDialog(false)}
        >
          <TransferDialog
            domainName={name}
            onClose={() => setOpenTransferDialog(false)}
            onReset={() => {
              resetPage()
              resetHeader()
            }}
          />
        </KodexSidebar>
        <Container
          maxWidth="calc(100% - 140px)"
          background={bodyBg}
          key={pageSeed}
        >
          <Space size={80} />
          <Space size={80} />
          <DomainContainer>
            <View
              borderRadius="10px"
              background={
                domainIsPremium
                  ? 'rgb(49, 9, 38)'
                  : whiteMode
                  ? bodyBg
                  : '#020202'
              }
            >
              <DomainCard background={gradient}>
                <View width="100%" justify="space-between">
                  <img src={ENSIconLogoWhite} alt="ENS Icon Logo" width={40} />
                  <View
                    opacity={categories?.terms ? 1 : 0}
                    transition="opacity 500ms ease"
                  >
                    <CategoriesBox
                      leftDistance="-240%"
                      fontSize={18}
                      bodyColor={'#fff'}
                      bodyBorder="transparent"
                      isWhiteStyle={whiteMode}
                      width="44px"
                      categories={categories?.terms}
                      boxBg={
                        domainIsPremium
                          ? '#695D83'
                          : !whiteMode
                          ? '#4E596F'
                          : '#8DAEF9'
                      }
                    />
                  </View>
                </View>
                <View direction="column" gap="20px" width="100%">
                  {views > 0 && (
                    <View>
                      <Space size={5} />
                      <Label
                        number
                        size={
                          windowWidth < 1300 ? 16 : windowWidth < 1500 ? 18 : 20
                        }
                      >
                        {views >= 1_000_000
                          ? Math.floor(views / 1_000_000) + 'M'
                          : views >= 1_000
                          ? Math.floor(views / 1_000) + 'K'
                          : views}{' '}
                        {views === 1 ? 'view' : 'views'}
                      </Label>
                    </View>
                  )}

                  <div
                    style={{
                      width: '100%',
                      display: 'block',
                      color: '#fff',
                      fontFamily: 'Satoshi',
                      fontSize:
                        windowWidth < 1300 ? 48 : windowWidth < 1500 ? 52 : 64,
                      fontWeight: 500,
                      overflowY: 'visible',
                      overflowX: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {name}
                  </div>
                </View>
              </DomainCard>
            </View>

            <View
              border={`1px solid ${bodyBorder}`}
              borderRadius="10px"
              height={componentHeight}
              direction="column"
              justify="space-between"
              padding="0 5%"
            >
              <DomainTitleStyle whiteMode={whiteMode}>
                <View direction="row" gap="10px" alignItems="center">
                  <Label color={bodyColor} size={32}>
                    {name}
                  </Label>
                  {usesSpecialCharacters && (
                    <View
                      height="25px"
                      padding="1px 0 0 0"
                      justify="items-start"
                      position="relative"
                      alignItems="start"
                      cursor="pointer"
                      onMouseEnter={() => setDisplayWarningMessage(true)}
                      onMouseLeave={() => setDisplayWarningMessage(false)}
                    >
                      {displayWarningMessage && (
                        <>
                          <div
                            style={{
                              width: '360px',
                              position: 'absolute',
                              top: '-12px',
                              left: '35px',
                              border: `1px solid ${
                                isRegistered ? '#E1E1E8' : '#4A4A4D'
                              }`,
                              padding: '15px',
                              background: isRegistered ? 'white' : '#262628',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '8px',
                              zIndex: 100
                            }}
                          >
                            <Label
                              color={isRegistered ? '#222' : 'white'}
                              size={15}
                              align="center"
                            >
                              This domain uses non ASCII special characters
                            </Label>
                          </div>
                        </>
                      )}
                      <NameWarning height="25px" width="25px" />
                    </View>
                  )}
                  {expirationStatus === 'grace period' && (
                    <View padding="0 0 3px 0">
                      <Label color="#BCBCCC">Grace period</Label>
                    </View>
                  )}
                </View>
                <View margin="0 16px 0 0" gap="40px">
                  <RoundedButton onClick={handleCopy}>
                    <img
                      src={!isRegistered ? LinkIconWhite : LinkIcon}
                      alt="link-icon"
                      width={22}
                    />
                    <div
                      style={{
                        backgroundColor: bodyBg,
                        borderRadius: '6px',
                        transition: 'opacity 100ms',
                        color: bodyColor,
                        padding: '6px 12px',
                        opacity: isCopied ? 1 : 0,
                        position: 'absolute',
                        bottom: '100%',
                        right: '-110%'
                      }}
                    >
                      copied!
                    </div>
                  </RoundedButton>
                  <View gap="10px" alignItems="center">
                    <Label weight={700} color={bodyColor}>
                      {likesCount}
                    </Label>
                    {isLikedByUser ? (
                      <RoundedButton
                        onClick={e => {
                          e.stopPropagation()
                          toggleLike()
                        }}
                      >
                        {isWhiteStyle ? (
                          <HeartIconBlackFill />
                        ) : (
                          <HeartIconWhiteFill />
                        )}
                      </RoundedButton>
                    ) : (
                      <RoundedButton
                        onClick={e => {
                          e.stopPropagation()
                          toggleLike()
                        }}
                      >
                        {isWhiteStyle ? <HeartIconBlack /> : <HeartIconWhite />}
                      </RoundedButton>
                    )}
                  </View>
                </View>
              </DomainTitleStyle>
              <View alignItems="center" flex="27.5%">
                <Avatar
                  src={profilePicture || DefaultAvatarImg}
                  borderRadius="6px"
                  width={40}
                  background="transparent"
                />

                <Space size={20} />

                <View
                  onClick={() => {
                    history.push(
                      `/profile/${ownerAddress?.slice(2, ownerAddress?.length)}`
                    )
                  }}
                >
                  <Label
                    cursor="pointer"
                    hoverColor="#a6a6a6"
                    padding="6px"
                    transition="color 0.2s"
                    size={20}
                    color={bodyColor}
                  >
                    @
                    {profile.userInfo
                      ? profile.userInfo.username
                      : formatAddress(ownerAddress)}
                  </Label>
                </View>
              </View>
              <View width="100%" justify="space-evenly" height="25%" flex="33%">
                {hasPriceContent && (
                  <View
                    flex="50%"
                    direction="column"
                    justify="space-between"
                    height="100%"
                  >
                    <View padding="17px 0">
                      <Label color={whiteMode ? '#BCBCCC' : '#A8A8A9'}>
                        {isListed || domainIsPremium ? 'Price' : 'Last sale'}
                      </Label>
                    </View>

                    <View alignItems="center" gap="1.5em">
                      {/* {whiteMode ? (
                        <EthPriceGrey
                          width={windowWidth < 1300 ? 54 : 60}
                          height={windowWidth < 1300 ? 54 : 60}
                        />
                      ) : (
                        <EthPriceLight
                          width={windowWidth < 1300 ? 54 : 60}
                          height={windowWidth < 1300 ? 54 : 60}
                        />
                      )} */}
                      <View
                        alignItems="center"
                        height={
                          windowWidth < 1300
                            ? '54px'
                            : windowWidth < 1500
                            ? '60px'
                            : '64px'
                        }
                      >
                        <Label color={bodyColor} number size={30}>
                          {!!listingPrice || !!price || !!lastPrice ? (
                            <>
                              {isListed
                                ? formatETHPrice(listingPrice) + ' ETH'
                                : domainIsPremium
                                ? formatETHPrice(price) + ' ETH'
                                : `${formatETHPrice(
                                    lastPrice
                                  )} ${lastPriceAsset}`}
                            </>
                          ) : (
                            <LoadingSpinner
                              margin="0 0 0 10px"
                              color={whiteMode ? '#000' : '#fff'}
                            />
                          )}
                        </Label>
                      </View>
                    </View>
                  </View>
                )}
                <View
                  flex={hasPriceContent ? '50%' : '100%'}
                  direction="column"
                  padding="0 1%"
                  justify="space-between"
                  height="100%"
                >
                  <View padding="17px 0">
                    <Label color={whiteMode ? '#BCBCCC' : '#A8A8A9'}>
                      {listingExpireTime && listed
                        ? 'Listing expires'
                        : !listed && isRegistered
                        ? 'Domain expires'
                        : !isRegistered
                        ? 'Domain expired'
                        : null}
                    </Label>
                  </View>
                  <View
                    height={
                      windowWidth < 1300
                        ? '54px'
                        : windowWidth < 1500
                        ? '60px'
                        : '64px'
                    }
                    alignItems="center"
                  >
                    <Label color={whiteMode ? '#BCBCCC' : '#A8A8A9'} size={30}>
                      {listed && (listingExpireTime || expires)
                        ? formatDate(listingExpireTime * 1000)
                        : !!expires
                        ? formatDate(expires)
                        : '-'}
                    </Label>
                  </View>
                </View>
              </View>
              <View width="100%" alignItems="center" flex="33%" gap="10px">
                {domainIsMine ? (
                  <>
                    {listed ? (
                      <>
                        <RoundedButton
                          borderRadius="6px"
                          height="60px"
                          width={
                            isListed || isRegistered === false ? '50%' : '100%'
                          }
                          background="#0698A8"
                          onClick={() => history.push(`/listing/${tokenIdHex}`)}
                        >
                          <Label color="#fff" size={16}>
                            Edit
                          </Label>
                        </RoundedButton>
                        <RoundedButton
                          borderRadius="6px"
                          height="60px"
                          width={
                            isListed || isRegistered === false ? '50%' : '100%'
                          }
                          background={
                            isListed || !domainIsPremium ? '#F3F3FA' : '#262628'
                          }
                          onClick={handleCancelListing}
                        >
                          <Label
                            color={
                              isListed || !domainIsPremium ? '#000' : '#fff'
                            }
                            size={16}
                          >
                            {cancelingListing
                              ? 'Canceling...'
                              : 'Cancel listing'}
                          </Label>
                        </RoundedButton>
                      </>
                    ) : (
                      <>
                        <RoundedButton
                          borderRadius="6px"
                          height="60px"
                          width={
                            isListed || isRegistered === false ? '50%' : '100%'
                          }
                          background={
                            isListed || !domainIsPremium ? '#F3F3FA' : '#262628'
                          }
                          onClick={() => history.push(`/listing/${tokenIdHex}`)}
                        >
                          <Label
                            color={
                              isListed || !domainIsPremium ? '#000' : '#fff'
                            }
                            size={16}
                          >
                            Sell
                          </Label>
                        </RoundedButton>
                        <RoundedButton
                          borderRadius="6px"
                          height="60px"
                          width={
                            isListed || isRegistered === false ? '50%' : '100%'
                          }
                          background="#0698A8"
                          onClick={() => setOpenTransferDialog(true)}
                        >
                          <Label color="#fff" size={16}>
                            Transfer
                          </Label>
                        </RoundedButton>
                      </>
                    )}
                  </>
                ) : (
                  isListed &&
                  !domainIsPremium && (
                    <RoundedButton
                      onClick={() => {
                        if (Number(ethBalance) <= Number(listingPrice))
                          return setIsNotEnoughETH(true)
                        handlePurchaseDomain()
                      }}
                      disabled={isBuying || isNotEnoughETH}
                      borderRadius="6px"
                      height="60px"
                      background="rgba(6, 152, 168, 0.9)"
                      hoverBackground="rgba(6, 152, 168, 1)"
                      width="50%"
                    >
                      <View width="100%" height="100%">
                        <ButtonMouseEventSensor
                          inFront={isNotEnoughETH}
                          onMouseLeave={() => setIsNotEnoughETH(false)}
                        />
                        <View
                          flex={addToCartHovered ? '0' : '78%'}
                          style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
                          transition="flex 0.2s ease, opacity 0.15s"
                          opacity={addToCartHovered ? '0' : '1'}
                          alignItems="center"
                          justify="center"
                          height="100%"
                          width="100%"
                        >
                          <Label size={16}>
                            {isNotEnoughETH
                              ? 'Not enough ETH'
                              : isBuying
                              ? 'Pending'
                              : 'Buy now'}
                          </Label>
                          {isBuying && (
                            <LoadingSpinner color="white" margin="0 0 0 20px" />
                          )}
                        </View>
                        <View
                          onMouseEnter={() => setAddToCartHovered(true)}
                          onMouseLeave={() => setAddToCartHovered(false)}
                          onClick={handleCart}
                          transition="all 0.2s ease"
                          hoverBackground="rgba(0,0,0,0.05)"
                          style={{
                            borderLeft: '1px solid #38ADB9'
                          }}
                          borderRadius={addToCartHovered ? '6px' : '0'}
                          flex={addToCartHovered ? '100%' : '22%'}
                          alignItems="center"
                          justify="center"
                          height="100%"
                          width="100%"
                        >
                          {addedToCart ? <Minus /> : <Plus />}

                          {addToCartHovered && <Space size={14} />}

                          <Label
                            weight={700}
                            style={{
                              whiteSpace: 'nowrap',
                              width: addToCartHovered ? 'max-content' : '0'
                            }}
                            opacity={addToCartHovered ? '1' : '0'}
                            transition="opacity 0.15s, width 0.2s"
                          >
                            {addedToCart ? 'Remove from cart' : 'Add to cart'}
                          </Label>
                        </View>
                      </View>
                    </RoundedButton>
                  )
                )}
                {!domainIsMine && (
                  <>
                    {!isRegistered ? (
                      <RoundedButton
                        onClick={() => {
                          isRegistered
                            ? setOpenOfferDialog(true)
                            : setRegistrationModalOpen(true)
                        }}
                        borderRadius="6px"
                        height="60px"
                        width="100%"
                        background={
                          (isRegistered && !isListed) || !isRegistered
                            ? '#0698A8'
                            : '#F3F3FA'
                        }
                      >
                        <View
                          flex={addToCartHovered ? '0' : '88%'}
                          style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
                          transition="flex 0.2s ease, opacity 0.15s"
                          opacity={addToCartHovered ? '0' : '1'}
                          alignItems="center"
                          justify="center"
                          height="100%"
                          width="100%"
                        >
                          <Label
                            weight={700}
                            color={
                              (isRegistered && !isListed) || !isRegistered
                                ? '#fff'
                                : '#000'
                            }
                            size={16}
                          >
                            Register
                          </Label>
                        </View>
                        <View
                          onMouseEnter={() => setAddToCartHovered(true)}
                          onMouseLeave={() => setAddToCartHovered(false)}
                          onClick={handleCart}
                          transition="all 0.2s ease"
                          hoverBackground="rgba(0,0,0,0.05)"
                          style={{
                            borderLeft: '1px solid #38ADB9'
                          }}
                          borderRadius={addToCartHovered ? '6px' : '0'}
                          flex={addToCartHovered ? '100%' : '12%'}
                          alignItems="center"
                          justify="center"
                          height="100%"
                          width="100%"
                        >
                          {addedToCart ? <Minus /> : <Plus />}

                          {addToCartHovered && <Space size={14} />}

                          <Label
                            weight={700}
                            style={{
                              whiteSpace: 'nowrap',
                              width: addToCartHovered ? 'max-content' : '0'
                            }}
                            opacity={addToCartHovered ? '1' : '0'}
                            transition="opacity 0.15s"
                          >
                            {addedToCart ? 'Remove from cart' : 'Add to cart'}
                          </Label>
                        </View>
                      </RoundedButton>
                    ) : (
                      <RoundedButton
                        onClick={() => {
                          isRegistered
                            ? setOpenOfferDialog(true)
                            : setRegistrationModalOpen(true)
                        }}
                        borderRadius="6px"
                        height="60px"
                        width={
                          isListed || isRegistered === false ? '50%' : '100%'
                        }
                        background={
                          (isRegistered && !isListed) || !isRegistered
                            ? '#0698A8'
                            : '#F3F3FA'
                        }
                      >
                        <Label
                          weight={700}
                          color={
                            (isRegistered && !isListed) || !isRegistered
                              ? '#fff'
                              : '#000'
                          }
                          size={16}
                        >
                          Make offer
                        </Label>
                      </RoundedButton>
                    )}
                  </>
                )}
              </View>
            </View>
            {domainIsPremium && (
              <div style={{ gridColumnStart: 'span 2' }}>
                <PremiumChart
                  domain={name}
                  expiryDate={expires || null}
                  darkMode
                  id="premiumDomainModalChart"
                />
              </div>
            )}
            <View
              background={whiteMode ? '#F3F3FA' : bodyBg}
              border={`1px solid ${bodyBorder}`}
              borderRadius="10px"
              height="430px"
              justify="space-between"
              direction="column"
            >
              <Details
                bodyColor={bodyColor}
                bodyBg={bodyBg}
                bodyBorder={bodyBorder}
                tokenId={tokenId}
                registrationDate={
                  registrations && registrations[0]?.registrationDate
                }
                expires={expires || null}
                domainName={name}
                isRegistered={isOwned}
                whiteMode={whiteMode}
              />
              {/* {isRegistered && (
                <View
                  width="100%"
                  padding="0 2em"
                  justify="space-between"
                  alignItems="center"
                  height="4.5em"
                >
                  <Label color={bodyColor} weight={600}>
                    ISSUE A SUBDOMAIN
                  </Label>
                  <View alignItems="center">
                    <EthPriceWhite width={24} />
                    <Space size={12} />
                    <Label size={12} weight={600} color={bodyColor}>
                      price
                    </Label>
                  </View>
                </View>
              )} */}
            </View>
            <View borderRadius="10px" height="430px">
              <PriceHistory
                height="430px"
                domainName={name}
                whiteMode={whiteMode}
                isRegistered={isOwned}
              />
            </View>
            <View
              width="calc(100vw - 120px)"
              minHeight="200px"
              height="fit"
              gap="1.1rem"
            >
              <View
                height="100%"
                direction="column"
                justify="space-between"
                borderRadius="10px"
                border={`1px solid ${bodyBorder}`}
                padding="3em 2em"
                width="50%"
              >
                <Label weight={600} size={18} color={bodyColor}>
                  Description
                </Label>
                <Space size={20} />
                <Label size={16} color={bodyColor} opacity={0.7}>
                  A truly awesome domain.
                </Label>
              </View>

              <View
                height="100%"
                borderRadius="10px"
                border={`1px solid ${bodyBorder}`}
                direction="column"
                justify="space-between"
                width="50%"
                padding="3em 2em"
                gap="20px"
              >
                <Label weight={600} size={18} color={bodyColor}>
                  Categories
                </Label>
                <View width="100%" gap="2em" wrappable>
                  {categories?.terms?.map((category, index) => {
                    return (
                      <Link
                        key={index}
                        hoverOpacity={0.6}
                        onClick={e => {
                          handleGoSubCategory(e, category)
                        }}
                      >
                        <Label
                          color={MAIN_CATEGORY_COLORS[index % 11]}
                          size={30}
                        >
                          {category}
                        </Label>
                      </Link>
                    )
                  })}
                  {!categories?.terms && <Label>Uncategorized.</Label>}
                </View>
              </View>
            </View>

            <ActivityAndOffers
              bodyColor={bodyColor}
              bodyBg={bodyBg}
              bodyBorder={bodyBorder}
              key={offersSeed}
              activityData={activity}
              offers={offers}
              cancelOffer={handleCancelOffer}
              setHighestOffer={setHighestOffer}
              canceling={cancelingIndex}
              acceptOffer={handleAcceptOffer}
              accepting={acceptingIndex}
              ownedDomain={domainIsMine}
              isRegistered={isOwned || expirationStatus === 'grace period'}
            />
          </DomainContainer>
          <Space size={80} />
        </Container>
        <SimilarSection
          ref={contentRef}
          isRegistered={whiteMode}
          data={similarDomains}
          firstItemPositionCoefficient={2}
          gap="20px"
          title="More like this"
          LastItemComponent={
            <SeeMoreCard onClick={handleSeeMoreClick}>
              <Label size={30} zIndex={1}>
                See more
              </Label>
              <Image src={SeeMore} width={200} height={230} alt="see more" />
            </SeeMoreCard>
          }
          itemsRenderer={(dataItem, index) => {
            const registered =
              dataItem &&
              moment()
                .subtract(90, 'days')
                .isBefore(Number(dataItem.expire_time) * 1000)
            return (
              <NetTokenCard
                height="370px"
                // margin="10px"
                footerBg="#020202"
                committedDomains={[]}
                registeredBg="#ffffff"
                hasAnimation={true}
                domain={dataItem}
                onClick={() => {
                  window.scrollTo({
                    left: 0,
                    top: 0,
                    behavior: 'smooth'
                  })
                  history.push(`/domain/${dataItem.name}`)
                }}
                onSelect={() => {
                  selectedDomains.includes(domain) || domainsIsInCartArr[index]
                    ? userAddress &&
                      removeDomain(
                        domain.name,
                        registered ? 'Purchase' : 'Register'
                      )
                    : userAddress &&
                      addNewDomain(domain, registered ? 'Purchase' : 'Register')
                }}
                headerBg={registered ? '#EBEBF2' : '#262628'}
                headerColor={registered ? '#000' : '#fff'}
                bodyBg={registered ? '#fff' : '#212121'}
                bodyColor={registered ? '#020202' : '#fff'}
                addedToCart={
                  selectedDomains.includes(dataItem) ||
                  domainsIsInCartArr[index]
                }
                from={registered ? 'Marketplace' : 'Registry'}
                inSimilarSection={true}
                key={`${dataItem.name}--${index}`}
                // openOfferDialog={handleOpenSimilarOfferDialog}
              />
            )
          }}
        />
      </View>
    </>
  )
}

const DomainTitleStyle = styled.div`
  display: flex;
  padding: 3em 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => (props.whiteMode ? '#cacadb' : '#4A4A4D')};
`

const DomainCard = styled.div`
  background: ${props => props.background};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  border-radius: 10px;
  padding: 2em 2em 2.5em 2em;
`

const DomainContainer = styled.div`
  background: ${props => props.background};
  display: grid;
  gap: 20px;
  grid-template-rows: repeat(1/4);
  grid-template-columns: 50% 50%;
`

const StyledHeader = styled.div`
  width: 100%;
  height: 230px;
  display: grid;
  background: ${props => (props.active ? '#0698A8' : props.background)};
  padding: 10px;
  border-radius: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-gap: 8px;
  justify-items: stretch;
  position: relative;
  transition: 0.2s ease-out;
`

const StyledHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 30px;
  justify-self: start;
`

const StyledHeaderCenter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 30px 30px;
  justify-self: center;
`

const StyledHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 9px;
  justify-self: right;
`

const SeeMoreCard = styled.div`
  position: relative;
  width: 310px;
  height: 370px;
  cursor: pointer;
  background-color: #0698a8;
  border-radius: 10px;
  margin: 0 10px;
  margin-right: 100px;
  display: flex;
  align-items: flex-end;
  padding: 30px;
`

const Image = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
`

const ButtonMouseEventSensor = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: ${({ inFront }) => (inFront ? 1 : -1)};
`

export default RegisteredDomain
