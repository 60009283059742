import styled from '@emotion/styled/macro'
import { ReactComponent as BagIcon } from 'assets/bag.svg'
import PhotoPlaceholder from 'assets/search-no-results.png'
import { useWindowListener } from 'common/useWindowListener'
import BottomModal from 'components/BottomModal'
import { OfferDialog } from 'components/Dialogs'
import BulkOfferDialog from 'components/Dialogs/BulkOfferDialog'
import RoundedButton from 'components/Forms/RoundedButton'
import KodexIcon from 'components/Icon/KodexIcon'
import Label from 'components/Label'
import PremiumRegistry from 'components/PremiumRegistry'
import { useAccount } from 'components/QueryAccount'
import KodexSidebar from 'components/Sidebar'
import SingleRegistration from 'components/SingleRegistration'
import View from 'components/View'
import { CartStoreContext } from 'contexts/CartStoreProvider'
import { ModalContext } from 'contexts/ModalProvider'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { calculateIfPremium } from 'views/Checkout/components/calculateIfPremium'
import CartItems from './CartItems'

let hasConnected = false

const EmptyCart = ({ closeCart }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useWindowListener(
    'resize',
    () => {
      setWindowWidth(window.innerWidth)
    },
    []
  )

  return (
    <View
      background="#fff"
      borderRadius="6px"
      width={windowWidth < 1500 ? '45vw' : '35vw'}
      height="100%"
      margin="0 0 1rem 0"
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.4) 2px -1px 10px'
      }}
    >
      <View
        width="100%"
        height="100%"
        padding="50px"
        direction="column"
        alignItems="center"
        justify="space-between"
        animation="1s ease fadeInBottom"
      >
        <Label size={40} color="#BCBCCC">
          Cart is empty
        </Label>
        <img src={PhotoPlaceholder} width="40%" />
        <View direction="column" width="100%" alignItems="center" gap="1rem">
          <Link to="/marketplace">
            <RoundedButton
              background="#fff"
              width="350px"
              height="70px"
              border="1px solid #E1E1E8"
              hoverBackground="#E1E1E8"
              onClick={closeCart}
            >
              <Label color="#020202">Find domains</Label>
            </RoundedButton>
          </Link>
          <Link to="/registry">
            <RoundedButton
              background="#fff"
              width="350px"
              height="70px"
              border="1px solid #E1E1E8"
              hoverBackground="#E1E1E8"
              onClick={closeCart}
            >
              <Label color="#020202">Go to registry</Label>
            </RoundedButton>
          </Link>
        </View>
      </View>
    </View>
  )
}

const MyCartButton = ({ iconColor, iconBackground }) => {
  const [openCart, setOpenCart] = useState(false)
  const [domainIsPremium, setDomainIsPremium] = useState(false)
  const [selectedDomain, setSelectedDomain] = useState({})
  const [registrationModalOpen, setRegistrationModalOpen] = useState(false)
  const [openOfferDialog, setOpenOfferDialog] = useState(false)
  const [openBulkOfferDialog, setOpenBulkOfferDialog] = useState(false)
  const [purchased, setPurchased] = useState(false)
  const [registered, setRegistered] = useState(false)

  const {
    state: { domains }
  } = useContext(CartStoreContext)

  const modalCtx = useContext(ModalContext)

  const location = useLocation()
  const history = useHistory()
  const account = useAccount()

  useEffect(() => {
    if (!hasConnected && account) {
      hasConnected = true
      if (location.search.includes('cart')) {
        setOpenCart(true)
      }
    }
  }, [account])

  const handleOpenCartDialog = useCallback(() => {
    setOpenCart(true)
  }, [setOpenCart])

  const handleCloseCartDialog = () => {
    if (modalCtx.closable) {
      const closeTimeout = setTimeout(() => {
        setOpenCart(false)
        setRegistered(false)
        setPurchased(false)
      }, 300)
      return () => {
        clearTimeout(closeTimeout)
      }
    }
  }

  const openRegistrationModal = domain => {
    const getIfDomainPremium = calculateIfPremium(domain.expire_time)

    setSelectedDomain(domain)

    if (getIfDomainPremium) {
      setDomainIsPremium(true)
    } else {
      setDomainIsPremium(false)
    }

    handleCloseCartDialog()
    setRegistrationModalOpen(true)
  }

  return (
    <>
      <RoundedButton onClick={handleOpenCartDialog} hoverOpacity="0.6">
        <KodexIcon
          background={iconBackground}
          size={20}
          padding="20px"
          borderRadius="6px"
          stroke={iconColor}
          icon={<BagIcon />}
        />
        {domains.length > 0 && <Badge>{domains.length}</Badge>}
      </RoundedButton>
      <BottomModal
        open={registrationModalOpen}
        onClose={() => {
          handleOpenCartDialog()
          setRegistrationModalOpen(false)
        }}
        closeButtonLabel="Back to cart"
        contentPadding="0"
        contentBackground="#0698A8"
      >
        {domainIsPremium
          ? registrationModalOpen && (
              <PremiumRegistry
                domain={selectedDomain}
                closeLabel="Back to cart"
                closeRegistration={() => {
                  handleOpenCartDialog()
                  setRegistrationModalOpen(false)
                }}
                refetchState={() => {
                  history.push('/profile/my-profile?unlisted')
                }}
              />
            )
          : registrationModalOpen && (
              <SingleRegistration
                domain={selectedDomain}
                closeLabel="Back to cart"
                closeRegistration={() => {
                  handleOpenCartDialog()
                  setRegistrationModalOpen(false)
                }}
                refetchState={() => {
                  history.push('/profile/my-profile?unlisted')
                }}
              />
            )}
      </BottomModal>
      <KodexSidebar
        opened={openCart}
        onClose={modalCtx.closable ? handleCloseCartDialog : () => {}}
        isCart={true}
      >
        {domains.length === 0 && !purchased && !registered ? (
          <EmptyCart closeCart={handleCloseCartDialog} />
        ) : (
          <>
            <CartItems
              closeCart={handleCloseCartDialog}
              openRegistration={openRegistrationModal}
              cartOpen={openCart}
              purchased={purchased}
              setPurchased={setPurchased}
              registered={registered}
              setRegistered={setRegistered}
              setOpenBulkOfferDialog={setOpenBulkOfferDialog}
              setOpenOfferDialog={setOpenOfferDialog}
              setSelectedDomain={setSelectedDomain}
            />
            {/* <RegisterDialog domains={domains} /> */}
          </>
        )}
      </KodexSidebar>
      <KodexSidebar
        opened={openOfferDialog}
        positionDialogCenter={true}
        onClose={() => setOpenOfferDialog(false)}
        isCart={true}
      >
        <OfferDialog
          domain={selectedDomain}
          onClose={() => setOpenOfferDialog(false)}
          backLabel="cart"
        />
      </KodexSidebar>
      <KodexSidebar
        opened={openBulkOfferDialog}
        onClose={() => setOpenBulkOfferDialog(false)}
        isCart={true}
      >
        <BulkOfferDialog
          domains={selectedDomain}
          onClose={() => setOpenBulkOfferDialog(false)}
          backLabel="cart"
        />
      </KodexSidebar>
    </>
  )
}

const Badge = styled.div`
  align-items: center;
  background: #af61ab;
  color: #ffffff;
  font-size: 10px;
  display: flex;
  height: 16px;
  justify-content: center;
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 100px;
  width: 16px;
`

export default MyCartButton
