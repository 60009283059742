import React from 'react'
import IconWrapper from '../../IconWrapper'
import styled from '@emotion/styled/macro'

const KodexIcon = ({
  icon,
  background,
  stroke,
  fill,
  size,
  borderRadius,
  padding = '10px'
}) => {
  return (
    <IconContainer
      background={background}
      borderRadius={borderRadius}
      padding={padding}
    >
      <IconWrapper stroke={stroke} fill={fill} size={size}>
        {icon}
      </IconWrapper>
    </IconContainer>
  )
}

const IconContainer = styled.div`
  padding: ${props => props.padding};
  background: ${props => props.background};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '4px')};
`

export default KodexIcon
