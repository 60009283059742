import React from 'react'
import View from 'components/View'
import Label from 'components/Label'
import NoItemsImage from 'assets/no-result-transparent.png'

const NoItems = ({ isRegistered = true, label }) => {
  return (
    <View
      width="100%"
      padding="2rem 0"
      background={isRegistered ? '#F3F3FA' : '#222'}
      borderRadius="10px"
      justify="center"
      alignItems="center"
      direction="column"
      gap="1rem"
      margin="0 0 1rem 0"
    >
      <img src={NoItemsImage} />
      <Label size={14} color="#BCBCCC">
        {label ? label : 'No items'}
      </Label>
    </View>
  )
}

export default NoItems
