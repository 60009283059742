import React from 'react'
import { ethers } from 'ethers'

const WalletAddress = ({
  children,
  format = 'optimized',
  lowercase = false
}) => {
  let address = typeof children === 'string' ? String(children) : ''

  if (format === 'optimized') {
    address = address.trim()
    if (ethers.constants.AddressZero === address) {
      return 'Null'
    }

    if (address.startsWith('0x')) {
      address = address.slice(2)
    }
    address = address.substring(0, 6)
  } else {
    address = address.substring(0, 5) + '...' + address.substring(38)
  }

  return (
    <span style={{ textTransform: lowercase ? 'lowercase' : 'uppercase' }}>
      {address}
    </span>
  )
}

export default WalletAddress
