import { BigNumber } from 'ethers'
import { useMemo } from 'react'

import Time from 'components/DateTime/Time'
import TableIcon from 'components/Icon/TableIcon'
import Label from 'components/Label'
import { Cell, Column, HeaderCell, Table } from 'components/Table'
import View from 'components/View'

import { ReactComponent as ExpiredIcon } from 'assets/expiration.svg'
import { ReactComponent as PurchaseIcon } from 'assets/purchase-icon.svg'
import { ReactComponent as RegisteredIcon } from 'assets/registered.svg'
import { ReactComponent as SaleIcon } from 'assets/sale.svg'
import { ReactComponent as TransferIcon } from 'assets/transfer.svg'
import moment from 'moment'
import NoItems from 'views/Checkout/components/NoItems'

import { useEthPrice } from 'hooks/useEthPrice'
import 'moment-timezone'
import { formatUSDPrice } from 'utils/format'
import { EMPTY_ADDRESS } from 'utils/records'
import ActivityAddress from 'views/Profile/components/ActivityAddress'

const ICONS = {
  transfer: <TransferIcon />,
  purchase: <PurchaseIcon />,
  sale: <SaleIcon />,
  registration: <RegisteredIcon />,
  expiration: <ExpiredIcon />
}

const Activities = ({ activityData, isRegistered }) => {
  const ethPrice = useEthPrice()

  const filteredActivityData = useMemo(
    () =>
      activityData
        ?.filter(
          activity =>
            activity.fromaddr !== EMPTY_ADDRESS &&
            activity.fromaddr !== '0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5'
        )
        .map(activity => {
          if (activity.toaddr === EMPTY_ADDRESS) {
            return {
              ...activity,
              event_type: 'expiration'
            }
          }

          if (activity.toaddr === null) {
            const registrationPrice = formatUSDPrice(
              (activity.price / Math.pow(10, 18)) * ethPrice
            )
            return {
              ...activity,
              registrationPrice,
              event_type: 'registration'
            }
          }

          return activity
        }),
    [activityData]
  )

  const getIconBg = event => {
    let iconBg = '#f3f3f3'
    switch (event) {
      case 'sale':
        iconBg = isRegistered ? '#EFF0FF' : '#6E71C133'
        break
      case 'purchase':
        iconBg = isRegistered ? '#E6F6FF' : '#59B0E233'
        break
      case 'transfer':
        iconBg = isRegistered ? '#E8F5F7' : '#0698A833'
        break
      case 'registration':
        iconBg = isRegistered ? '#F1E5FA' : '#B77DE433'
        break
      default:
        iconBg = isRegistered ? '#f3f3fa' : '#f3f3f377'
        break
    }
    return iconBg
  }

  return filteredActivityData?.length > 0 ? (
    <Table
      data={filteredActivityData}
      height={250}
      style={{
        background: isRegistered ? '#fff' : '#020202',
        margin: '-5px 0'
      }}
    >
      <Column
        style={{
          background: isRegistered ? '#fff' : '#020202'
        }}
      >
        <HeaderCell
          style={{
            background: isRegistered ? '#fff' : '#020202'
          }}
        >
          <Label size={13} color={isRegistered ? '#BCBCCC' : '#fff9'}>
            Event
          </Label>
        </HeaderCell>
        <Cell
          style={{
            background: isRegistered ? '#fff' : '#020202'
          }}
        >
          {data => (
            <View alignItems="center" gap="20px">
              <TableIcon
                icon={ICONS[data?.event_type || data?.__typename.toLowerCase()]}
                background={getIconBg(
                  data?.event_type || data?.__typename.toLowerCase()
                )}
              />
              <Label
                size={16}
                style={{ textTransform: 'capitalize' }}
                color={isRegistered ? '#212121' : '#fff'}
              >
                {data?.event_type}
              </Label>
            </View>
          )}
        </Cell>
      </Column>
      <Column
        style={{
          background: isRegistered ? '#fff' : '#020202',
          border: 'none'
        }}
      >
        <HeaderCell
          style={{
            background: isRegistered ? '#fff' : '#020202',
            border: 'none'
          }}
        >
          <Label size={13} color={isRegistered ? '#BCBCCC' : '#fff9'}>
            Price
          </Label>
        </HeaderCell>
        <Cell
          style={{
            background: isRegistered ? '#fff' : '#020202',
            border: 'none'
          }}
        >
          {data => (
            <View alignItems="center" gap="20px">
              {data?.event_type === 'sale' ||
              data?.event_type === 'registration' ? (
                <>
                  <Label
                    size={16}
                    number
                    // style={{ textTransform: 'capitalize' }}
                    color={isRegistered ? '#212121' : '#fff'}
                  >
                    {data.event_type === 'registration'
                      ? data.registrationPrice
                      : data.asset === 'USDC' || data.asset === 'DAI'
                      ? Number(
                          BigNumber.from(
                            data?.cost || String(Number(data?.price)) || 1
                          )
                            .div(BigNumber.from(10).pow(6))
                            .toNumber()
                        )
                      : Number(
                          BigNumber.from(
                            data?.cost || String(Number(data?.price)) || 1
                          )
                            .div(BigNumber.from(10).pow(15))
                            .toNumber() /
                            10 ** 3 || 0
                        )
                          .toLocaleString('default', {
                            maximumFractionDigits:
                              data.asset === 'USDC' || data.asset === 'DAI'
                                ? 0
                                : Number(
                                    BigNumber.from(
                                      data?.cost ||
                                        String(Number(data?.price)) ||
                                        1
                                    )
                                      .div(BigNumber.from(10).pow(15))
                                      .toNumber() /
                                      10 ** 3 || 0
                                  ) >= 1000
                                ? 0
                                : 3
                          })
                          .replaceAll(',', ' ') || '0.00'}{' '}
                    {data.event_type === 'registration' ? 'USD' : data.asset}
                  </Label>
                </>
              ) : (
                <></>
              )}
            </View>
          )}
        </Cell>
      </Column>
      <Column style={{ background: isRegistered ? '#fff' : '#020202' }}>
        <HeaderCell
          style={{
            background: isRegistered ? '#fff' : '#020202'
          }}
        >
          <Label size={13} color={isRegistered ? '#BCBCCC' : '#fff9'}>
            From
          </Label>
        </HeaderCell>
        <Cell style={{ background: isRegistered ? '#fff' : '#020202' }}>
          {data => (
            <ActivityAddress
              address={
                (data?.event_type === 'registration' &&
                  '0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5') ||
                data.fromaddr
              }
              isRegistered={isRegistered}
            />
          )}
        </Cell>
      </Column>
      <Column style={{ background: isRegistered ? '#fff' : '#020202' }}>
        <HeaderCell
          style={{
            background: isRegistered ? '#fff' : '#020202'
          }}
        >
          <Label size={13} color={isRegistered ? '#BCBCCC' : '#fff9'}>
            To
          </Label>
        </HeaderCell>
        <Cell
          style={{
            background: isRegistered ? '#fff' : '#020202',
            border: 'none'
          }}
        >
          {data => (
            <ActivityAddress
              address={
                data.event_type === 'registration'
                  ? data.fromaddr
                  : data.toaddr || data?.registrant?.id
              }
              isRegistered={isRegistered}
            />
          )}
        </Cell>
      </Column>
      <Column style={{ background: isRegistered ? '#fff' : '#020202' }}>
        <HeaderCell
          style={{
            background: isRegistered ? '#fff' : '#020202'
          }}
        >
          <Label size={13} color={isRegistered ? '#BCBCCC' : '#fff9'}>
            Date
          </Label>
        </HeaderCell>
        <Cell
          style={{
            background: isRegistered ? '#fff' : '#020202'
          }}
        >
          {data => (
            <Time
              timeString={moment
                .tz(
                  data.start_time * 1000 ||
                    Number(data.registrationDate) * 1000,
                  'UTC'
                )
                .format()}
              isRegistered={isRegistered}
            />
          )}
        </Cell>
      </Column>
    </Table>
  ) : (
    <NoItems isRegistered={isRegistered} label="No activity yet" />
  )
}

export default Activities
