import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import View from 'components/View'
import Label from 'components/Label'
import Space from 'components/Space'

const MobileSimilarSection = forwardRef((props, pageRef) => {
  const { isRegistered = true, data, itemsRenderer, gap } = props

  return (
    <SimilarSectionComponent isRegistered={isRegistered}>
      <View gap={gap} direction="column" justify="flex-start" transition="none">
        <Label
          size={30}
          weight={500}
          color={isRegistered ? '#47596B' : '#E5E5E5'}
        >
          Similar
        </Label>
        <Space size={10} />
        {data.map((dataItem, index) => itemsRenderer(dataItem, index))}
      </View>
    </SimilarSectionComponent>
  )
})

const SimilarSectionComponent = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 32px 16px;
  background-color: ${props => (props.isRegistered ? '#EAEAF1' : '#020202')};
`

export default MobileSimilarSection
