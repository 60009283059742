import { hasValidReverseRecord } from '../utils/utils'
import {
  accountsReactive,
  favouritesReactive,
  globalErrorReactive,
  isENSReadyReactive,
  isReadOnlyReactive,
  isRunningAsSafeAppReactive,
  namesReactive,
  networkIdReactive,
  networkReactive,
  reverseRecordReactive,
  transactionHistoryReactive
} from './reactiveVars'

export default {
  Query: {
    fields: {
      domains: {
        keyArgs: ['where', 'order_by'],
        merge(existing = [], incoming) {
          return [...existing, ...(incoming || [])]
        }
      },
      ranked_domains_view: {
        keyArgs: [
          'where',
          'order_by',
          'name_pattern_search',
          'min_domain_length',
          'max_domain_length',
          'search_type',
          'order_type'
        ],
        merge(existing = [], incoming) {
          return [...existing, ...(incoming || [])]
        }
      },
      names: {
        read() {
          return namesReactive()
        }
      },
      transactionHistory: {
        read() {
          return transactionHistoryReactive()?.transactionHistory
        }
      },
      networkId: {
        read() {
          return networkIdReactive()
        }
      },
      network: {
        read() {
          const network = networkReactive()
          const networkName = network?.name
          if (!networkName) return 'Loading'
          return networkName === 'homestead' ? 'Main' : networkName
        }
      },
      accounts: {
        read() {
          return accountsReactive()
        }
      },
      displayName: {
        read() {
          const addresss = accountsReactive()?.[0]
          if (!addresss) return ''
          return hasValidReverseRecord(reverseRecordReactive())
            ? reverseRecordReactive().name
            : `${addresss?.slice(0, 10)}...`
        }
      },
      avatar: {
        read() {
          return reverseRecordReactive()?.avatar || ''
        }
      },
      isReadOnly: {
        read() {
          return isReadOnlyReactive()
        }
      },
      isSafeApp: {
        read() {
          return isRunningAsSafeAppReactive()
        }
      },
      isENSReady: {
        read() {
          return isENSReadyReactive()
        }
      },
      favourites: {
        read() {
          return favouritesReactive()
        }
      },
      globalError: {
        read() {
          return globalErrorReactive() || false
        }
      }
    }
  },
  Domain: {
    keyFields: ['name']
  },
  domains: {
    keyFields: ['name']
  },
  ranked_domains_view: {
    keyFields: ['name']
  },
  users: {
    keyFields: ['user']
  },
  profile_view: {
    keyFields: ['user']
  }
}
