import React from 'react'
import View from 'components/View'
import Label from 'components/Label'
import Space from 'components/Space'
import DoneIcon from './DoneIcon'

const NotificationSettingItem = ({ title, subtitle, selected, onClick }) => {
  return (
    <View
      justify="space-between"
      alignItems="center"
      padding="25px 0"
      width="100%"
      onClick={onClick}
    >
      <View direction="column">
        <Label size={16} color="#020202">
          {title}
        </Label>
        <Space size={10} />
        <Label size={13} color="#BCBCCC">
          {subtitle}
        </Label>
      </View>
      <DoneIcon selected={selected} />
    </View>
  )
}

export default NotificationSettingItem
