import styled from '@emotion/styled/macro'

const RoundedButton = styled.button`
  background: ${props => (props.disabled ? '#d8d8d8' : props.background)};
  color: ${props => props.color};
  transform: ${props => props.transform};
  opacity: ${props => props.opacity};
  width: ${props => props.width};
  height: ${props => props.height};
  padding: ${props => props.padding};
  flex: ${props => props.flex};
  line-height: ${props => props.lineHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Satoshi', sans-serif;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '10px')};
  border: ${props => (props.border ? props.border : 'none')};
  outline: none;
  margin: ${props => props.margin};
  font-size: ${props => props.fontSize};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  transition: ${props =>
    props.transition ? props.transition : 'all 0.2s ease'};
  ${props => props.letterSpacing && `letter-spacing: ${props.letterSpacing};`}

  :hover {
    opacity: ${props =>
      props.hoverOpacity && !props.disabled ? props.hoverOpacity : 1};
    background: ${props => !props.disabled && props.hoverBackground};
    border-color: ${props => !props.disabled && props.hoverBackground};
    color: ${props => !props.disabled && props.hoverColor};

    span {
      color: ${props => !props.disabled && props.hoverColor};
    }
  }

  :active {
    background: ${props => !props.disabled && props.activeBackground};
    color: ${props => !props.disabled && props.activeColor};
  }
`

export default RoundedButton
