import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import { externalTooltipHandler } from './tooltip'
import moment from 'moment'

Chart.register(
  LinearScale,
  LineElement,
  BarController,
  BarElement,
  LineController,
  CategoryScale,
  PointElement,
  Tooltip
)

export default function(id, darkMode, fromModal, datapoints, labels) {
  const ctx = document.getElementById(id).getContext('2d')
  Chart.defaults.font.family = 'Satoshi'
  Chart.defaults.font.weight = '300'

  const todayLabel = moment().format('D MMMM YYYY HH:00')
  const todayIndex = labels.indexOf(todayLabel)
  const todayPrice = datapoints[todayIndex]

  const chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: labels,
      datasets: [
        {
          label: 'Price',
          data: datapoints,
          borderColor: darkMode ? '#045C66' : '#82CBD3',
          backgroundColor: '#0698A8',
          fill: false,
          tension: 0.4,
          pointBackgroundColor: '#0698A8',
          pointBorderColor: 'white',
          pointBorderWidth: 0,
          hoverBorderWidth: 2,
          hoverBorderColor: '#0698A8',
          pointHoverBackgroundColor: 'white',
          pointRadius: 0,
          hoverRadius: 5
        }
      ]
    },
    options: {
      clip: {
        bottom: 7,
        right: 7,
        left: 7,
        top: 7
      },
      responsive: true,
      maintainAspectRatio: false,
      transitions: {
        show: {
          animations: {
            y: {
              from: 190,
              duration: 1200,
              easing: 'easeOutExpo'
            }
          }
        },
        hide: {
          animations: {
            y: {
              duration: 0
            },
            visible: {
              type: 'boolean',
              duration: 0
            }
          }
        }
      },
      plugins: {
        title: {
          display: false
        },
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: tooltip =>
            externalTooltipHandler(
              tooltip,
              todayPrice,
              todayLabel,
              darkMode,
              fromModal,
              'USD'
            )
        }
      },
      interaction: {
        intersect: false
      },
      scales: {
        x: {
          title: {
            display: false
          },
          grid: {
            color: '#ffffff',
            display: false,
            drawBorder: false,
            offset: false,
            drawOnChartArea: false
          },
          ticks: {
            padding: 64,
            align: 'inner',
            color: darkMode ? '#9A9A9A' : '#D7D7E0',
            crossAlign: 'near',
            font: {
              size: 13,
              weight: 300
            },
            callback: function(value, index, labels) {
              return index === 0 || index === labels.length - 1
                ? this.getLabelForValue(value)
                : null
            }
          }
        },
        y: {
          beginAtZero: true,
          ticks: {
            padding: 20,
            color: darkMode ? '#9A9A9A' : '#D7D7E0',
            maxTicksLimit: 3,
            font: {
              size: 13,
              weight: 300
            }
          },
          grid: {
            drawTicks: false,
            drawBorder: false,
            color: darkMode ? '#353535' : '#E1E1E8',
            borderDash: [4, 8],
            offset: false
          }
        }
      }
    }
  })
  chart.options.transitions.show.colors = false

  chart.tooltip.setActiveElements(
    [
      {
        datasetIndex: 0,
        index: todayIndex
      }
    ],
    { x: 0, y: 0 }
  )
  chart.tooltip.setActiveElements([], { x: 0, y: 0 })
  chart.update('hide')

  return chart
}
