import LoadingNetTokenCard from 'components/Card/NetTokenCard/LoadingNetTokenCard'
import { useColumnCount } from 'components/ResultList/hooks/useColumnCount'
import LoadingNetTokenRow from 'components/Row/LoadingNetTokenRow'
import View from 'components/View'
import { VirtualizedList } from 'components/Virtualized'
import { DOMAIN_TABLE_COLUMNS_BREAKPOINTS } from 'constants/breakpoints'
import { useEffect, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'

export default ({
  domains,
  context,
  renderers,
  loadMore,
  loading,
  windowWidth,
  ...rest
}) => {
  const {
    contextData: { layout }
  } = context

  const { card, listHeader, listItem, noItems } = renderers

  if (domains?.length === 0 && !loading) {
    return noItems()
  }

  const { columnCount } = useColumnCount({
    sizeMap: DOMAIN_TABLE_COLUMNS_BREAKPOINTS
  })

  const bottomPaddingRef = useRef(null)

  const { inViewport } = useInViewport(bottomPaddingRef)

  useEffect(() => {
    if (!inViewport) return
    loadMore()
  }, [inViewport])

  const emptyArray = new Array(20).fill('h', 0, 20)

  if (layout === 'CARD' && card) {
    return (
      <View
        flex={1}
        width="100%"
        wrappable="wrap"
        direction="row"
        gap="15px 1%"
        {...rest}
      >
        {!loading
          ? domains
              ?.concat(
                Array(
                  domains?.length % 20 === 0
                    ? columnCount - (domains?.length % 20) + columnCount || 5
                    : 0
                ).fill(
                  null,
                  0,
                  columnCount - (domains?.length % 20) + columnCount || 5
                )
              )
              .map((domain, index) => card(domain, index))
          : emptyArray.map((_, i) => (
              <LoadingNetTokenCard
                key={i}
                isDarkMode={false}
                hasAnimation={true}
                index={i}
                windowWidth={windowWidth}
              />
            ))}
        <div ref={bottomPaddingRef} style={{ height: 1 }} />
      </View>
    )
  }

  if (layout === 'LIST') {
    return (
      <View flex={1} width="100%" direction="column" {...rest}>
        {!loading
          ? domains
              ?.concat(
                Array(domains?.length % 20 === 0 ? columnCount || 5 : 0).fill(
                  null,
                  0,
                  columnCount || 5
                )
              )
              .map((domain, index) => listItem(domain, index))
          : emptyArray.map((_, i) => (
              <LoadingNetTokenRow
                key={i}
                isDarkMode={false}
                hasAnimation={true}
                index={i}
                windowWidth={windowWidth}
              />
            ))}
        <div ref={bottomPaddingRef} style={{ height: 1 }} />
      </View>
    )
  }
}
