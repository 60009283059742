import React, { useMemo } from 'react'
import FooterLink from './FooterLink'
import Link from 'components/Forms/Link'
import { useLocation, useHistory } from 'react-router'

const SecondPageLink = () => {
  const location = useLocation()
  const history = useHistory()

  const currentRoute = useMemo(() => location.pathname.split('/')[1], [
    location.pathname
  ])

  const handleRegistryClick = () => {
    history.push('/registry', 60)
  }

  const handleFeedClick = () => {
    history.push('/feed', 60)
  }

  if (currentRoute === 'marketplace' || currentRoute === 'registry') {
    return (
      <Link hoverOpacity={0.6} onClick={handleFeedClick}>
        <FooterLink text="Feed" color="#fff" />
      </Link>
    )
  }

  return (
    <Link hoverOpacity={0.6} onClick={handleRegistryClick}>
      <FooterLink text="Registry" color="#fff" />
    </Link>
  )
}

export default SecondPageLink
