import View from 'components/View'
import React, { useState } from 'react'
import { ReactComponent as ContractSecurityIcon } from 'assets/contract-security-shield.svg'
import Label from 'components/Label'
import RoundedButton from 'components/Forms/RoundedButton'

const FirstTimePopup = ({ showPopup, setShowPopup }) => {
  const [isModalAnimated, setIsModalAnimated] = useState(false)

  const handleCloseModal = () => {
    setIsModalAnimated(true)
    setTimeout(() => {
      setShowPopup(false)
    }, 500)
  }
  window.onload = () => {
    setIsModalAnimated(false)
  }

  return (
    <View
      position="fixed"
      opacity={isModalAnimated ? '0' : '1'}
      display={showPopup ? 'flex' : 'none'}
      transition="opacity 0.5s ease"
      width="100vw"
      height="100vh"
      background="rgba(0,0,0,0.5)"
      zIndex={100000}
      justify="center"
      alignItems="center"
    >
      <View
        direction="column"
        background="#fff"
        width={window.innerWidth > 640 ? '580px' : '90%'}
        height={window.innerWidth > 640 ? '533px' : 'fit'}
        borderRadius="10px"
        padding="5px"
      >
        <View
          background="#F3F3FA"
          width="100%"
          flex={window.innerWidth > 640 ? '40%' : ''}
          borderRadius="10px"
          justify="center"
          alignItems="center"
        >
          <ContractSecurityIcon
            style={{
              filter: 'drop-shadow(0 0 75px #0698A8)'
            }}
          />
        </View>
        <View
          flex={window.innerWidth > 640 ? '60%' : '100%'}
          direction="column"
          justify="space-between"
          alignItems="center"
          padding={window.innerWidth > 640 ? '40px' : '20px 10px 10px 10px'}
          gap="30px"
        >
          <Label color="#000" size={30} weight={700} align="center">
            Contract Security Update
          </Label>
          <Label
            color="#BCBCCC"
            style={{ padding: '0 30px', lineHeight: '160%' }}
            align="center"
          >
            Kodex now uses audited Seaport contracts for all on-chain execution,
            as well as Reservoir for order routing. Fret not, your domains are
            safe with us.
          </Label>
          <View
            width="100%"
            justify="space-between"
            gap={window.innerWidth > 640 ? '10px' : '5px'}
          >
            <RoundedButton
              onClick={() =>
                (window.location = 'https://docs.kodex.io/docs/about/security')
              }
              width={window.innerWidth > 640 ? '245px' : '49%'}
              padding={window.innerWidth > 640 ? '22px 0' : '16px 0'}
              border="1px solid #CACADB"
              borderRadius="10px"
            >
              <Label color="#000" style={{ whiteSpace: 'nowrap' }}>
                Learn more
              </Label>
            </RoundedButton>
            <RoundedButton
              onClick={() => handleCloseModal()}
              width={window.innerWidth > 640 ? '245px' : '49%'}
              padding={window.innerWidth > 640 ? '22px 0' : '16px 0'}
              background="#0698A8"
              borderRadius="10px"
            >
              <Label style={{ whiteSpace: 'nowrap' }}>Got it</Label>
            </RoundedButton>
          </View>
        </View>
      </View>
    </View>
  )
}

export default FirstTimePopup
