import { useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled/macro'
import elevator_ding from 'assets/elevator_ding.mp3'
import elevator_music from 'assets/elevator_music.mp3'
import { ReactComponent as SoundOff } from 'assets/sound-off.svg'
import { ReactComponent as SoundOn } from 'assets/sound-on.svg'
import RoundedButton from 'components/Forms/RoundedButton'
import { useGasPrice } from 'components/hooks'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import { useAccount } from 'components/QueryAccount'
import PriceCard from 'components/SingleRegistration/Cards/PriceCard'
import TransactionsConfirmedCard from 'components/SingleRegistration/Cards/TransactionsConfirmedCard'
import RegistrationSuccessfull from 'components/SingleRegistration/RegistrationSuccessfull'
import Space from 'components/Space'
import View from 'components/View'
import { AccountContext } from 'contexts/AccountProvider'
import { CartStoreContext } from 'contexts/CartStoreProvider'
import { ModalContext } from 'contexts/ModalProvider'
import { BigNumber, ethers } from 'ethers'
import EthVal from 'ethval'
import moment from 'moment'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { GET_SEARCH_DOMAINS } from 'service/graphql/kodex-api'
import { COMMIT, REGISTER } from 'service/graphql/mutations'
import {
  CHECK_COMMITMENT,
  GET_BALANCE,
  GET_MINIMUM_COMMITMENT_AGE,
  GET_RENT_PRICE
} from 'service/graphql/queries'
import { randomSecret } from 'utils/register'
import InfoCard from './Cards/InfoCard'
import PremiumChart from './Chart'
import DesiredPriceModal from './Modal'
import LoadingSpinner from 'components/LoadingSpinner'
import { useEthPrice } from 'hooks/useEthPrice'

const YEAR_IN_SECONDS = 31556952
const COMMIT_GAS_WEI = 42000
const REGISTER_GAS_WEI = 240000
const TOGAL_GAS_WEI = COMMIT_GAS_WEI + REGISTER_GAS_WEI

const PremiumRegistry = ({
  domain = '',
  closeLabel,
  closeRegistration,
  refetchState
}) => {
  const [period, setPeriod] = useState(1)
  // const [destinationAddress, setDestinationAddress] = useState('')
  // const [expiryDate, setExpiryDate] = useState(0)
  const [desiredPrice, setDesiredPrice] = useState(0)
  const [desiredPriceModalIsOpen, setDesiredPriceModalIsOpen] = useState(false)
  const [destination, setDestination] = useState('')
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [commiting, setCommiting] = useState(false)
  const [
    registrationCommitCountdown,
    setRegistrationCommitCountdown
  ] = useState(60)
  const [currentStep, setCurrentStep] = useState(1)
  const [isRegistering, setRegistering] = useState(false)
  const [txHash, setTxHash] = useState()
  const [secret, setSecret] = useState(randomSecret())
  const { isConnected } = useContext(AccountContext)

  const [elevatorMusicMuted, setElevatorMusicMuted] = useState(false)

  const elevatorMusic = useRef(null)
  const elevatorDing = new Audio(elevator_ding)

  const cartCtx = useContext(CartStoreContext)
  const modalCtx = useContext(ModalContext)

  const ethPrice = useEthPrice()

  const domainName = useMemo(() => {
    const name = domain?.name?.replace('.eth', '') || ''
    return name
  }, [domain])

  useEffect(() => {
    const transactionSecretStored = localStorage.getItem(domainName)
    if (transactionSecretStored) {
      refetchCheckCommitment({
        label: domainName,
        secret: transactionSecretStored,
        commitmentTimerRunning: false
      })
      setSecret(transactionSecretStored)
    }
  }, [domainName, domain])

  const { data: checkCommitment, refetch: refetchCheckCommitment } = useQuery(
    CHECK_COMMITMENT,
    {
      variables: {
        label: domainName,
        secret,
        commitmentTimerRunning: false
      }
    }
  )

  useEffect(() => {
    if (!checkCommitment) return
    if (checkCommitment?.checkCommitment === 0) return
    if (
      moment().isBefore(checkCommitment.checkCommitment * 1000 + 86400 * 1000)
    ) {
      setCurrentStep(3)
      setTxHash(undefined)
    }
  }, [checkCommitment])

  useEffect(() => {
    if (currentStep === 4) {
      setSuccessModalOpen(true)
      if (refetchState) refetchState()
    }
  }, [currentStep])

  const account = useAccount()
  const { data: { getBalance } = {} } = useQuery(GET_BALANCE, {
    variables: { address: account },
    fetchPolicy: 'no-cache'
  })

  // useEffect(() => {
  //   const premiumPrice = getPremiumPrice(domainName)
  //   console.log(premiumPrice)
  // }, [domainName, account])

  const { data: { getRentPrice } = {} } = useQuery(GET_RENT_PRICE, {
    variables: {
      duration: period * YEAR_IN_SECONDS,
      label: domainName,
      commitmentTimerRunning: false
    }
  })

  const { price: gasPrice } = useGasPrice(isConnected)
  const { data: { getMinimumCommitmentAge } = {} } = useQuery(
    GET_MINIMUM_COMMITMENT_AGE,
    {}
  )
  const [handleRequestRegister] = useMutation(COMMIT, {
    onCompleted: data => {
      setTxHash(Object.values(data)[0])
      localStorage.setItem(domainName, secret)
      setCurrentStep(2)
      elevatorMusic.current.play()
      setTimeout(() => {
        elevatorDing.play()
        elevatorMusic.current.pause()
        modalCtx.setClosable(true)
      }, 59500)
      if (getMinimumCommitmentAge) {
        const commitmentInterval = setInterval(() => {
          setRegistrationCommitCountdown(state => state - 1)
        }, 1000)

        setTimeout(() => {
          clearInterval(commitmentInterval)
          setTxHash(undefined)
          setCurrentStep(3)
        }, getMinimumCommitmentAge * 1000)
      }
    },
    onError: e => {
      setCommiting(false)
      modalCtx.setClosable(true)
    },
    variables: {
      label: domainName,
      secret: secret,
      commitmentTimerRunning: !!txHash
    }
  })

  const [handleRegister] = useMutation(REGISTER, {
    onCompleted: data => {
      modalCtx.setClosable(true)
      setRegistering(false)
      setCurrentStep(4)
      const cartDomainsNames = cartCtx.state.domains.map(d => d.name)
      if (cartDomainsNames.includes(domain.name)) {
        cartCtx.removeDomain(domain.name, 'REGISTER')
      }
    },
    onError: err => {
      setRegistering(false)
      modalCtx.setClosable(true)
    },
    refetchQueries: [{ query: GET_SEARCH_DOMAINS }, 'getDomainsAdvanced'],
    variables: {
      label: domainName,
      secret: secret,
      duration: period * YEAR_IN_SECONDS
    }
  })

  const priceNum =
    !!getRentPrice &&
    Number(ethers.utils.formatEther(getRentPrice)).toLocaleString(undefined, {
      maximumFractionDigits: 3
    })

  const registerGasFast =
    !!gasPrice.fast &&
    new EthVal(`${TOGAL_GAS_WEI * gasPrice.fast}`).toEth().toFixed(4)

  const totalAmount = (
    Number(priceNum) + Number(registerGasFast)
  ).toLocaleString(undefined, { maximumFractionDigits: 4 })

  const isInSufficient = useMemo(() => {
    return !!getBalance ? BigNumber.from(getBalance || 0) < totalAmount : true
  }, [getBalance, totalAmount])

  return (
    <>
      {successModalOpen && (
        <RegistrationSuccessfull
          closeLabel={closeLabel}
          closeRegistration={closeRegistration}
        />
      )}
      {!successModalOpen && (
        <View
          direction="column"
          height="94vh"
          width="100%"
          padding="9vh 0 2rem 0"
          background="#fff"
          overflow="auto"
        >
          {desiredPriceModalIsOpen && (
            <DesiredPriceModal
              currentPrice={priceNum}
              desiredPrice={desiredPrice}
              setDesiredPrice={setDesiredPrice}
              setDesiredPriceModal={setDesiredPriceModalIsOpen}
            />
          )}
          <Container maxWidth="1060px" width="80vw" background="#fff">
            <Label size={70} color="black">
              {domainName?.includes('.eth') ? domainName : domainName + '.eth'}
            </Label>
            <Space size={15} />
            <Label color="#e1e1e8" size={25}>
              recently expired
            </Label>
            <Space size={50} />
            <div
              style={{
                width: '100%',
                color: '#BCBCCC',
                justifyContent: 'end',
                fontSize: '.9rem',
                padding: '10px 0 20px 0',
                display: 'flex',
                borderBottom: '1px solid #e1e1e8'
              }}
            >
              <PriceCard
                type="Price"
                price={priceNum || '0.00'}
                ethPrice={ethPrice}
                editable={false}
              />
            </div>
            <Space size={35} />
            <InputDataContainer>
              <InputContainer>
                <label htmlFor="address">Destination</label>
                <AddressInput
                  type="string"
                  name="address"
                  value={destination}
                  onChange={e => setDestination(e.target.value)}
                  placeholder="my wallet"
                  disabled={true}
                />
              </InputContainer>
              <InputContainer>
                <label htmlFor="period">Period</label>
                <PeriodContainer>
                  <div>
                    {period} {period === 1 ? 'Year' : 'Years'}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.75rem'
                    }}
                  >
                    <PeriodInputControlsContainer>
                      <PeriodInputControls
                        onClick={() => setPeriod(period === 1 ? 1 : period - 1)}
                      >
                        -
                      </PeriodInputControls>
                      <PeriodInputControls
                        onClick={() => setPeriod(period + 1)}
                      >
                        +
                      </PeriodInputControls>
                    </PeriodInputControlsContainer>
                    {currentStep === 3 ? (
                      <Button
                        disabled={isRegistering || isInSufficient}
                        onClick={() => {
                          if (!isRegistering) {
                            setRegistering(true)
                            modalCtx.setClosable(false)
                            handleRegister()
                          }
                        }}
                      >
                        {isRegistering ? 'Registering...' : 'Register'}
                      </Button>
                    ) : currentStep === 2 ? (
                      <RoundedButton
                        background="#fff"
                        width="9rem"
                        color="black"
                        height="70px"
                        border="1px solid #e1e1e8"
                        borderRadius="9px"
                        letterSpacing="0.1rem"
                        disabled={false}
                        style={{
                          borderLeft: '2px solid #e1e1e8'
                        }}
                      >
                        <View
                          width="100%"
                          justify="space-between"
                          alignItems="center"
                          padding="0 15px 0 25px"
                        >
                          <Label size={16} color="#000">
                            {registrationCommitCountdown <= 0
                              ? '0:00'
                              : `${Math.floor(
                                  registrationCommitCountdown / 60
                                )}:${Math.floor(
                                  registrationCommitCountdown % 60
                                ).toLocaleString('default', {
                                  minimumIntegerDigits: 2
                                })}`}
                          </Label>
                          <RoundedButton
                            borderRadius="10px"
                            padding="15px"
                            background="white"
                            hoverBackground="#F3F3FA"
                            onClick={() => {
                              setElevatorMusicMuted(!elevatorMusicMuted)
                              elevatorMusic.current.volume = !elevatorMusicMuted
                                ? 0
                                : 1
                            }}
                          >
                            {elevatorMusicMuted ? (
                              <SoundOff height="20px" />
                            ) : (
                              <SoundOn height="20px" />
                            )}
                          </RoundedButton>
                        </View>
                      </RoundedButton>
                    ) : (
                      <Button
                        disabled={isInSufficient || commiting}
                        onClick={() => {
                          setCommiting(true)
                          modalCtx.setClosable(false)
                          handleRequestRegister()
                        }}
                      >
                        {commiting
                          ? 'Pending'
                          : isInSufficient
                          ? 'Missing ETH'
                          : 'Confirm'}
                        {commiting && <LoadingSpinner margin="0 0 0 20px" />}
                      </Button>
                    )}
                  </div>
                </PeriodContainer>
              </InputContainer>
            </InputDataContainer>
            <Space size={20} />
            <TransactionsConfirmedCard
              step={currentStep}
              commitmentAge={getMinimumCommitmentAge}
            />
            <Space size={20} />
            <PremiumChart
              domain={domainName}
              expiryDate={domain.expire_time || domain.expires / 1000}
              id="premiumDomainModalChartModal"
              fromModal
            />
            <Space size={20} />
            <InfoCard />
          </Container>
        </View>
      )}
      <audio
        ref={elevatorMusic}
        volume={elevatorMusicMuted ? 0 : 1}
        src={elevator_music}
        controls={false}
      />
    </>
  )
}

export default PremiumRegistry

const InputDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`

const InputContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #bcbccc;
`

const PeriodContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #e1e1e8;
  border-radius: 10px;
  color: #000;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding-left: 1.5rem;
`

const PeriodInputControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid #e1e1e8;
  border-radius: 10px;
`

const PeriodInputControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem;
  font-size: 2rem;
  transition: 0.1s linear;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f8;
  }
`

const AddressInput = styled.input`
  background-color: #f3f3fa;
  border-radius: 10px;
  border: 1px solid #e1e1e8;
  padding: 1.5rem 1rem;
  font-size: 1rem;
`

const Button = styled.button`
  padding: 27px 0;
  color: ${props => (props.disabled ? '#BCBCCC' : 'white')};
  border: ${props =>
    props.disabled ? '1px solid #E1E1E8' : '1px solid #0698A8'};
  background-color: ${props => (props.disabled ? '#fff' : '#0698A8')};
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 1;
  border-radius: 10px;
  width: 135px;
`
