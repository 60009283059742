import React from 'react'
import styled from '@emotion/styled/macro'

import DefaultBannerImg from 'assets/profile/banner-default.png'

const Banner = ({ img }) => (
  <BannerContainer>
    {img && (
      <>
        <Overlay />
        <Wrapper bgColor={'#a2a4ab'} background={img} />
      </>
    )}
    {!img && (
      <>
        <Overlay />
        <DefaultWrapper />
      </>
    )}
  </BannerContainer>
)

export default Banner

const BannerContainer = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100vw;
  height: 100%;
  left: calc((100% - 100vw) / 2);
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${props => props.bgColor};
  text-align: center;
`

const DefaultWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${DefaultBannerImg});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-color: #e6eaf4;
  text-align: center;
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #020202;
  opacity: 0.3;
`
