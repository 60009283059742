import React from 'react'
import View from 'components/View'
import Label from 'components/Label'
import Space from 'components/Space'
import PinkFractal from 'assets/subcategories/characters.png'
import GreenFractal from 'assets/marketplace-offers.png'
import Container from 'components/Layout/Container'

const HelpCenter = () => {
  const onGettingStartedClick = () => {
    console.log('onGettingStartedClick')
  }

  const onFaqClick = () => {
    console.log('onGettingStartedClick')
  }

  return (
    <Container
      maxWidth="calc(100% - 140px)"
      minHeight="calc(100vh - 450px)"
      background="#fff"
    >
      <Space size={170} />
      <Label size={110} lineHeight={0.9} color="#020202">
        HELP CENTER
      </Label>
      <Space size={80} />
      <View width="100%" transition="height 0.3s ease">
        <Card
          onClick={onGettingStartedClick}
          imgUrl={GreenFractal}
          label="GETTING STARTED"
          url="https://docs.kodex.io/docs/guides/welcome"
        />
        <Space size={20} />
        <Card
          onClick={onFaqClick}
          imgUrl={PinkFractal}
          label="FAQ"
          url="https://discord.gg/kodex"
        />
      </View>
      <Space size={120} />
    </Container>
  )
}

const Card = ({ label, imgUrl, onClick, url }) => {
  return (
    <a href={url} style={{ width: '50%' }} target="_blank">
      <View
        flex={1}
        borderRadius="6px"
        background="#EBEBF2"
        overflow="hidden"
        height="320px"
        onClick={onClick}
        alignItems="flex-end"
        padding="40px"
        transition="transform 0.15s ease"
        hoverTransform="translateY(-1rem)"
        cursor="pointer"
      >
        <img
          src={imgUrl}
          alt={label}
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            minWidth: '100%'
          }}
        />
        <Label color="#fff" size={24} lineHeight={1.3}>
          {label}
        </Label>
      </View>
    </a>
  )
}

export default HelpCenter
