import { parseCookie } from 'utils/utils'

export const siweAuthedFetch = (url, options) => {
  const cookies = document.cookie ? parseCookie(document.cookie) : {}

  return fetch(url, {
    ...options,
    credentials: 'include',
    headers: {
      ...options?.headers,
      id: cookies?.id || '',
      Cookie: document.cookie
    }
  })
}
