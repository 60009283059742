import styled from '@emotion/styled/macro'
import { ReactComponent as RefreshBlack } from 'assets/refresh-black.svg'
import { ReactComponent as RefreshWhite } from 'assets/refresh-white.svg'
import { AccordionMultipleSelect, AccordionRadio } from 'components/Accordion'
import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import LoadingSpinner from 'components/LoadingSpinner'
import Space from 'components/Space'
import View from 'components/View'
import { toWei } from 'ethjs-unit'
import { useEthPrice } from 'hooks/useEthPrice'
import { useCallback, useEffect, useMemo, useState } from 'react'
import CharacterRanger from 'views/Registry/FilterBar/CharacterRanger'
import ClearButton from './ClearButton'
import HideButton from './HideButton'
import PriceRangeSelector from './PriceRangeSelector'
import ToolBarCategoriesButton from './ToolBarCategoriesButton'

const SALE_TYPES = {
  title: 'Status',
  content: [
    { label: 'Buy Now', value: 'buy_now' },
    { label: 'Has Offers', value: 'has_offers' }
    // { label: 'Grace Period', value: '' }
  ]
}

const DATE = {
  title: 'Date',
  content: [
    { value: 'recently_listed', label: 'Recently Listed' },
    { value: 'oldest_listed', label: 'Oldest Listed' },
    { value: 'recently_registered', label: 'Recently Registered' },
    { value: 'oldest_registered', label: 'Oldest Registered' },
    { value: 'expiring_soonest', label: 'Expiring soonest' }
  ]
}

const IN_ORDER = {
  title: 'Sort By',
  content: [
    { value: 'price_low_to_high', label: 'Price low to high' },
    { value: 'price_high_to_low', label: 'Price high to low' },
    { value: 'alphabetical', label: 'Alphabetical' },
    { value: 'highest_last_sale', label: 'Last Sale' },
    { value: 'most_favorited', label: 'Likes' }
  ]
}

const DATE_TYPE = {
  title: 'Status',
  content: [
    { value: 'new', label: 'New' },
    { value: 'premium', label: 'Premium' },
    { value: 'previously_owned', label: 'Previously owned' }
  ]
}

const REGISTRY_DATE_TYPE = {
  title: 'Date',
  content: [
    { value: 'newest', label: 'Newest' },
    { value: 'oldest', label: 'Oldest' }
  ]
}

const NAME_TYPE = {
  title: 'Type',
  content: [
    { value: 'letters', label: 'Letters' },
    { value: 'numbers', label: 'Numbers' }
    // { value: 'emojis', label: 'Emojis' }
  ]
}

// const TYPE_RESULT = {
//   title: 'Type result',
//   content: [
//     { value: 'only', label: 'Only' },
//     { value: 'constains', label: 'Contains' }
//   ]
// }

const NAME_RESULT = {
  title: 'Result',
  content: [
    { value: 'starts_with', label: 'Starts with' },
    { value: 'contains', label: 'Contains' }
  ]
}

const ToolBar = ({
  page,
  categoriesCount,
  filtersOpened,
  onOpenCategoriesModal,
  modalButtonTitle,
  darkMode,
  onLengthChange,
  onPriceChange = () => {},
  statusType,
  onStatusChange,
  onSortByChange,
  onDateChange = () => {},
  onChangeType,
  onChangeResult,
  onResetFilter,
  activeCurrency,
  setActiveCurrency,
  domainCount,
  refreshData = () => {},
  scrollbarOffset
}) => {
  const [selectedSaleType, setSelectedSaleType] = useState(statusType)
  const [selectedHasOffersType, setSelectedHasOffersType] = useState([
    SALE_TYPES.content[0].value
  ])
  // const [selectedHasOffersType, setSelectedHasOffersType] = useState([])
  const [currency, setCurrency] = useState(activeCurrency || 'ETH')
  const [priceFrom, setPriceFrom] = useState('')
  const [priceTo, setPriceTo] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedOrder, setSelectedOrder] = useState()
  const [amountChars, setAmountChars] = useState([3, 10])
  const [nameType, setNameType] = useState([])
  const [nameResult, setNameResult] = useState('')
  const [domainCountLoading, setDomainCountLoading] = useState(false)

  const ethPrice = useEthPrice()

  const handleChangeSaleType = option => {
    if (selectedSaleType === option) {
      setSelectedSaleType(undefined)
      onStatusChange(undefined)
      return
    }

    setSelectedSaleType(option)
    onStatusChange(option)
  }

  useEffect(() => {
    if (statusType === selectedSaleType) return
    setSelectedSaleType(statusType)
  }, [statusType])

  const handleChangeHasOffersType = option => {
    if (selectedHasOffersType.includes(option)) {
      const hasOffersTypesArr = selectedHasOffersType.filter(t => t !== option)
      setSelectedHasOffersType(hasOffersTypesArr)
      onStatusChange(hasOffersTypesArr)
      return
    }

    setSelectedHasOffersType([...selectedHasOffersType, option])
    onStatusChange([...selectedHasOffersType, option])
  }

  const handleChangeDate = useCallback(
    option => {
      if (option === selectedDate) {
        setSelectedDate(undefined)
        onDateChange(undefined)
      } else {
        setSelectedDate(option)
        onDateChange(option)
        setSelectedOrder(undefined)
        onSortByChange('default')
      }
    },
    [selectedDate]
  )

  const handleChangeNameType = type => {
    if (nameType.includes(type)) {
      const nameTypeArr = nameType.filter(t => t !== type)
      setNameType(nameTypeArr)
      onChangeType(nameTypeArr)
      return
    }

    setNameType([...nameType, type])
    onChangeType([...nameType, type])
  }

  // const handleChangeNameTypeResult = typeResult => {
  //   if (typeResult === nameTypeResult) {
  //     setNameTypeResult(undefined)
  //     onChangeTypeResult(undefined)
  //     return
  //   }
  //   setNameTypeResult(typeResult)
  //   onChangeTypeResult(typeResult)
  // }

  const handleChangeNameResult = result => {
    if (result === nameResult) {
      setNameResult(undefined)
      onChangeResult(undefined)
      return
    }
    setNameResult(result)
    onChangeResult(result)
  }

  const applyPriceRange = () => {
    if (Number(priceFrom) > Number(priceTo) && Number(priceTo) > 0) {
      alert("Minimum price mustn't be higher than the maximum price")
      return
    }

    const transformedFromPrice =
      activeCurrency === 'USDC'
        ? Number((Number(priceFrom) / Number(ethPrice)).toFixed(5)) || 0
        : Number(priceFrom) || 0
    const transformedToPrice =
      activeCurrency === 'USDC'
        ? Number((Number(priceTo) / Number(ethPrice)).toFixed(5)) || 0
        : Number(priceTo) || 0

    const fromInWei = toWei(transformedFromPrice, 'ether')
    const toInWei = toWei(transformedToPrice, 'ether')

    onPriceChange([fromInWei, toInWei])
  }

  useEffect(() => {
    setActiveCurrency(currency)
  }, [currency])

  const filtersCount = useMemo(() => {
    let filtersCounter = 0

    const selectedSalesValues = darkMode
      ? DATE_TYPE.content.map(item => item.value)
      : SALE_TYPES.content.map(item => item.value)

    if (
      selectedSalesValues.includes(
        darkMode
          ? statusType
          : selectedHasOffersType?.[0] || selectedHasOffersType?.[1]
      )
    ) {
      filtersCounter++
    }

    if (priceFrom || priceTo) {
      filtersCounter++
    }

    const selectedDatesValues = DATE.content.map(item => item.value)

    if (!darkMode && selectedDatesValues.includes(selectedDate)) {
      filtersCounter++
    }

    // const selectedOrderValues = IN_ORDER.content.map(item => item.value)

    // if (selectedOrderValues.includes(selectedOrder)) {
    //   filtersCounter++
    // }

    if (amountChars[0] !== 3 || amountChars[1] !== 10) {
      filtersCounter++
    }

    if (categoriesCount !== '') {
      filtersCounter++
    }

    const nameTypeValues = NAME_TYPE.content.map(item => item.value)

    if (nameTypeValues.includes(nameType[0])) {
      filtersCounter++
    }

    if (nameResult) {
      filtersCounter++
    }

    return filtersCounter
  }, [
    selectedSaleType,
    priceFrom,
    priceTo,
    amountChars[0],
    amountChars[1],
    selectedOrder,
    selectedDate,
    categoriesCount,
    nameType,
    nameResult,
    statusType,
    selectedHasOffersType
  ])

  const clearFilters = useCallback(() => {
    if (filtersCount === 0) {
      return
    }
    setSelectedSaleType([])
    setPriceFrom(undefined)
    setPriceTo(undefined)
    setSelectedDate(null)
    setSelectedOrder(null)
    setAmountChars([3, 10])
    onResetFilter()
    setNameResult(undefined)
    onChangeResult(undefined)
    setNameType([])
    onChangeType([])
    setSelectedHasOffersType([])
  }, [filtersCount])

  const handleLengthChange = useCallback(params => {
    const length = [...params]
    if (length[1] === 10) length[1] = undefined
    setAmountChars(params)
    onLengthChange(length)
  }, [])

  const nameTypeProps = {
    title: NAME_TYPE.title,
    content: NAME_TYPE.content,
    secondSectionValue: nameType,
    onSecondSectionChanged: handleChangeNameType,
    onMultiSelectChange: handleChangeNameType
  }

  const finishDomainCountLoading = () => {
    setDomainCountLoading(false)
  }
  return (
    <View width="100%" direction="column">
      <View direction="column" width="100%">
        <ToolbarBody darkMode={darkMode}>
          <View direction="column" width="100%" overflow="hidden">
            <ToolBarCategoriesButton
              title={modalButtonTitle}
              onClick={onOpenCategoriesModal}
              categoriesCount={categoriesCount}
              darkMode={darkMode}
            />
            <Space size={10} />
            {darkMode ? (
              <AccordionRadio
                title={DATE_TYPE.title}
                content={DATE_TYPE.content}
                value={statusType}
                onChange={handleChangeSaleType}
                darkMode={darkMode}
              />
            ) : (
              <AccordionMultipleSelect
                title={SALE_TYPES.title}
                secondSection={SALE_TYPES.content}
                secondSectionValue={selectedHasOffersType}
                onSecondSectionChanged={handleChangeHasOffersType}
                darkMode={darkMode}
              />
            )}
            {/* {!darkMode && (
              <>
                <Space size={20} />
                <AccordionRadio
                  darkMode={darkMode}
                  title={IN_ORDER.title}
                  content={IN_ORDER.content}
                  value={selectedOrder}
                  onChange={handleChangeOrder}
                />
              </>
            )} */}
            <Space size={10} />
            <CharacterRanger
              values={amountChars}
              onChange={handleLengthChange}
              thumbWidth="30px"
              label="Length"
              rangerExtremumLabelsColor="#BCBCCC"
              darkMode={darkMode}
            />
            <Space size={10} />
            <AccordionRadio
              darkMode={darkMode}
              title={darkMode ? NAME_RESULT.title : NAME_RESULT.title}
              content={darkMode ? NAME_RESULT.content : NAME_RESULT.content}
              value={nameResult}
              nameTypeProps={nameTypeProps}
              onChange={handleChangeNameResult}
            />
            <Space size={10} />
            <PriceRangeSelector
              currency={currency}
              onCurrencyChange={setCurrency}
              from={priceFrom}
              onFromChanged={setPriceFrom}
              to={priceTo}
              onToChanged={setPriceTo}
              onSave={applyPriceRange}
              darkMode={darkMode}
              setActiveCurrency={setActiveCurrency}
            />
            {page === 'marketplace' && (
              <>
                <Space size={10} />
                <AccordionRadio
                  darkMode={darkMode}
                  title={darkMode ? REGISTRY_DATE_TYPE.title : DATE.title}
                  content={darkMode ? REGISTRY_DATE_TYPE.content : DATE.content}
                  value={selectedDate}
                  onChange={handleChangeDate}
                />
              </>
            )}
            <Space size={140} />
          </View>
        </ToolbarBody>

        {filtersCount !== 0 && (
          <View
            position="absolute"
            bottom="7vh"
            width="100%"
            direction="column"
            background={'transparent'}
            zIndex={3}
          >
            <Space size={20} />
            <ClearButton filtersCount={filtersCount} onClick={clearFilters} />
            <Space size={20} />
          </View>
        )}
      </View>
    </View>
  )
}

const ToolbarBody = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 200px);
  width: 100%;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
`

export default ToolBar
