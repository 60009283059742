import Label from 'components/Label'
import Container from 'components/Layout/Container'
import Space from 'components/Space'
import View from 'components/View'
import React from 'react'
import { POSTS } from './posts'
import NewsCard from 'components/Card/NewsCard'

const News = () => {
  return (
    <View background="#fff" direction="column" minHeight="75vh" width="100%">
      <Space size={170} />
      <Container maxWidth="1300px">
        <View width="100%" alignItems="center">
          <Label size={110} color="#000">
            Blog
          </Label>
        </View>
        <Space size={100} />
        <View width="100%" gap="20px" wrappable>
          {/* <Label size={20} color="#262626">
            Sit tight! Latest news from the world of ENS are coming soon.
          </Label> */}
          {POSTS.map(post => (
            <NewsCard
              title={post.title}
              date={post.date}
              avatar_url={post.previewImage}
              articleUrl={post.url}
              key={post.url}
            />
          ))}
        </View>
      </Container>
      <Space size={140} />
    </View>
  )
}

export default News
