import { ApolloProvider } from '@apollo/client'
import { Web3ReactProvider } from '@web3-react/core'
import { useState } from 'react'
import {
  BrowserRouter,
  HashRouter,
  Redirect,
  Route as DefaultRoute,
  Switch
} from 'react-router-dom'
import ApolloContainer from 'components/Layout/ApolloContainer'
import View from 'components/View'
import { Footer } from 'views/Home/components'
import InvalidNetwork from 'views/Network/InvalidNetwork'
import Topbar from 'views/Topbar'
import AccountProvider from 'contexts/AccountProvider'
import CartStoreProvider from 'contexts/CartStoreProvider'
import ImagePreloaderProvider from 'contexts/ImagePreloaderProvider'
import UserActivityProvider from 'contexts/UserActivityProvider'
import { setupClient } from './apollo/apolloClient'
import { clientReactive, networkIdReactive } from './apollo/reactiveVars'
import './App.css'
import { FeedAnimationProvider } from './contexts/FeedAnimationProvider'
import TopBarStyleProvider from './contexts/TopBarStyleProvider'
import FilterProvider from 'contexts/FilterProvider'
import ModalProvider from 'contexts/ModalProvider'
import MobileFilters from 'components/MobileOnly/MobileFilters'
import FirstTimePopup from 'components/FirstTimePopup'
import AnimatedHistoryProvider from 'contexts/AnimatedHistoryProvider'
import RegisteredDomain from 'views/TokenProfile/RegisteredDomain'
import Chats from 'views/Chats'
import Sale from 'views/Sale'
import Feed from 'views/Home/Feed'
import Categories from 'views/Categories'
import News from 'views/News'
import NewsDetail from 'views/NewsDetail'
import SearchResult from 'views/SearchResult'
import Trending from 'views/Trending'
import About from 'views/About'
import HelpCenter from 'views/HelpCenter'
import Profile from 'views/Profile'
import Registry from 'views/Registry'
import Marketplace from 'views/Marketplace'
import Subcategory from 'views/Subcategory'
import Home from 'views/Home'

// const Home = lazy(() =>
//   import(
//     /* webpackChunkName: "Home", webpackPrefetch:true */
//     './views/Home'
//   )
// )

// const SingleName = lazy(() =>
//   import(
//     /* webpackChunkName: "SingleName", webpackPrefetch:true */
//     './routes/SingleName'
//   )
// )

// const Checkout = lazy(() => import('./views/Checkout'))
// const Offer = lazy(() => import('./views/Offer'))
// const Notifications = lazy(() => import('./views/Notifications'))
// const Registry = lazy(() => import('./views/Registry'))
// const Marketplace = lazy(() => import('./views/Marketplace'))
// const Subcategory = lazy(() => import('./views/Subcategory'))
// const Profile = lazy(() => import('./views/Profile'))
// const Chats = lazy(() => import('./views/Chats'))
// const Sale = lazy(() => import('./views/Sale'))
// const DomainOffer = lazy(() => import('./views/DomainOffer'))
// const NotRegisteredDomain = lazy(() => import('./views/TokenProfile'))
// const RegisteredDomain = lazy(() =>
//   import('./views/TokenProfile/RegisteredDomain')
// )
// const Feed = lazy(() => import('./views/Home/Feed'))
// const Categories = lazy(() => import('./views/Categories'))
// const News = lazy(() => import('./views/News'))
// const NewsDetail = lazy(() => import('./views/NewsDetail'))
// const SearchResult = lazy(() => import('./views/SearchResult'))
// const About = lazy(() => import('./views/About'))
// const Trending = lazy(() => import('./views/Trending'))
// const HelpCenter = lazy(() => import('./views/HelpCenter'))

//If we are targeting an IPFS build we need to use HashRouter
const Router =
  process.env.REACT_APP_IPFS === 'True' ? HashRouter : BrowserRouter

const HomePageLayout = ({ children }) => {
  return (
    <View
      direction="column"
      background="#0698A8"
      overflow="hidden"
      width="100vw"
      minHeight="100vh"
    >
      {children}
      <Footer />
    </View>
  )
}

const FeedPageLayout = ({ children }) => {
  return (
    <View
      direction="column"
      background="#0698A8"
      overflow="hidden"
      width="100vw"
      minHeight="100vh"
    >
      {children}
      <Footer />
    </View>
  )
}

// const CheckoutPageLayout = ({ children }) => {
//   return (
//     <View direction="column" background="#000" height="100vh" minHeight="100vh">
//       {children}
//     </View>
//   )
// }

const MarketplacePageLayout = ({ children }) => {
  return (
    <View direction="column" background="#fff" minHeight="100vh" width="100%">
      {/* <Topbar
        textColor="#020202"
        textContrastColor="#020202"
        iconBackground="#F3F3FA"
      /> */}
      {children}
    </View>
  )
}

const AccountPageLayout = ({ children }) => {
  return (
    <View direction="column" background="#fff" minHeight="100vh">
      {/* <Topbar textColor="#020202" textContrastColor="#020202" /> */}
      {children}
      <Footer />
    </View>
  )
}

const Route = ({
  component: Component,
  layout: Layout = HomePageLayout,
  isHomePageRoute,
  ...rest
}) => {
  return (
    <AnimatedHistoryProvider>
      <DefaultRoute
        {...rest}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    </AnimatedHistoryProvider>
  )
}

export const getLibrary = provider => {
  return provider
}

const App = () => {
  const client = clientReactive(setupClient(networkIdReactive()))
  // const containerRef = useRef(null)

  const [showPopup, setShowPopup] = useState(false)

  window.onload = () => {
    const visited = localStorage.getItem('visited')
    if (!visited) {
      setShowPopup(true)
      localStorage.setItem('visited', 'true')
    }
  }

  // useEffect(() => {
  //   window.addEventListener('scroll', () => {
  //     localStorage.setItem(
  //       'SCROLL',
  //       JSON.stringify({
  //         x: window.scrollX,
  //         y: window.scrollY
  //       })
  //     )
  //   })
  // })

  if (window.innerWidth < 740) {
    return (
      <Router>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ApolloProvider {...{ client }}>
            <AccountProvider>
              <ImagePreloaderProvider>
                <FeedAnimationProvider>
                  <FilterProvider>
                    <ModalProvider>
                      <TopBarStyleProvider>
                        <div id="body-portal" />
                        {showPopup && (
                          <FirstTimePopup
                            showPopup={showPopup}
                            setShowPopup={setShowPopup}
                          />
                        )}
                        <Topbar />
                        {window.innerWidth < 640 && <MobileFilters />}
                        <Switch>
                          {/* <Route
                      exact
                      path="/"
                      component={Home}
                      layout={HomePageLayout}
                      isHomePageRoute
                    /> */}
                          {/* <Route
                      exact
                      path="/checkout"
                      component={Checkout}
                      layout={MarketplacePageLayout}
                    />
                    <Route
                      exact
                      path="/offer"
                      component={Offer}
                      layout={MarketplacePageLayout}
                    /> */}
                          {/* <Route
                      exact
                      path="/notification"
                      component={Notifications}
                      layout={CheckoutPageLayout}
                    /> */}
                          <Route
                            exact
                            path="/registry/:search"
                            component={Registry}
                            layout={MarketplacePageLayout}
                          />
                          <Route
                            exact
                            path="/registry"
                            component={Registry}
                            layout={MarketplacePageLayout}
                          />

                          <Route
                            exact
                            path="/marketplace"
                            component={Marketplace}
                            layout={MarketplacePageLayout}
                          />
                          <Route
                            exact
                            path="/marketplace/:subcategory"
                            component={Subcategory}
                            layout={MarketplacePageLayout}
                          />

                          {/* <Route
                      exact
                      path="/token/:name/buy"
                      component={NotRegisteredDomain}
                      layout={AccountPageLayout}
                    /> */}
                          <Route
                            exact
                            path="/domain/:name"
                            component={RegisteredDomain}
                            layout={AccountPageLayout}
                          />
                          {/* <Route
                      path="/name/:name"
                      component={SingleName}
                      layout={CheckoutPageLayout}
                    />
                    <Route
                      exact
                      path="/chats"
                      component={Chats}
                      layout={MarketplacePageLayout}
                    />
                    <Route
                      exact
                      path="/listing/:id"
                      component={Sale}
                      layout={MarketplacePageLayout}
                    />
                    <Route
                      exact
                      path="/domain-offer"
                      component={DomainOffer}
                      layout={MarketplacePageLayout}
                    />
                    <Route
                      exact
                      path="/feed"
                      component={Feed}
                      layout={FeedPageLayout}
                    />
                    <Route
                      exact
                      path="/categories"
                      component={Categories}
                      layout={AccountPageLayout}
                    />
                    <Route
                      exact
                      path="/news"
                      component={News}
                      layout={AccountPageLayout}
                    />
                    <Route
                      exact
                      path="/news/:id"
                      component={NewsDetail}
                      layout={AccountPageLayout}
                    />
                    <Route
                      exact
                      path="/search"
                      component={SearchResult}
                      layout={MarketplacePageLayout}
                    />
                    <Route
                      exact
                      path="/trending"
                      component={Trending}
                      layout={MarketplacePageLayout}
                    />
                    <Route
                      exact
                      path="/about"
                      component={About}
                      layout={AccountPageLayout}
                    />
                    <Route
                      exact
                      path="/help"
                      component={HelpCenter}
                      layout={AccountPageLayout}
                    /> */}
                          {/* <Route
                      exact
                      path="/:id"
                      component={Profile}
                      layout={MarketplacePageLayout}
                    />
                    <DefaultRoute
                      path="/profile/:id"
                      render={({ match }) => {
                        const { id } = match.params
                        return <Redirect to={`/${id}`} />
                      }}
                    />
                    <DefaultRoute
                      path="/:address"
                      render={({ match }) =>
                        match.url.includes('0x') ? (
                          <Redirect to={`/profile/${match.url.slice(1)}`} />
                        ) : (
                          <Redirect to="/" />
                        )
                      }
                    /> */}
                        </Switch>
                        <InvalidNetwork />
                      </TopBarStyleProvider>
                    </ModalProvider>
                  </FilterProvider>
                </FeedAnimationProvider>
              </ImagePreloaderProvider>
            </AccountProvider>
          </ApolloProvider>
        </Web3ReactProvider>
      </Router>
    )
  }
  return (
    <Router>
      <AnimatedHistoryProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ApolloProvider {...{ client }}>
            <AccountProvider>
              <UserActivityProvider>
                <ApolloContainer>
                  <ImagePreloaderProvider>
                    <FeedAnimationProvider>
                      <FilterProvider>
                        <ModalProvider>
                          <CartStoreProvider>
                            <TopBarStyleProvider>
                              <div id="body-portal" />
                              {showPopup && (
                                <FirstTimePopup
                                  showPopup={showPopup}
                                  setShowPopup={setShowPopup}
                                />
                              )}
                              <AnimatedHistoryProvider>
                                <Topbar />
                              </AnimatedHistoryProvider>
                              {window.innerWidth < 740 && <MobileFilters />}
                              <Switch>
                                <Route
                                  exact
                                  path="/"
                                  component={Home}
                                  layout={HomePageLayout}
                                  isHomePageRoute
                                />
                                {/* <Route
                                exact
                                path="/checkout"
                                component={Checkout}
                                layout={MarketplacePageLayout}
                              />
                              <Route
                                exact
                                path="/offer"
                                component={Offer}
                                layout={MarketplacePageLayout}
                              />
                              <Route
                                exact
                                path="/notification"
                                component={Notifications}
                                layout={CheckoutPageLayout}
                              /> */}
                                <Route
                                  exact
                                  path="/registry"
                                  component={Registry}
                                  layout={MarketplacePageLayout}
                                />
                                <Route
                                  exact
                                  path="/marketplace"
                                  component={Marketplace}
                                  layout={MarketplacePageLayout}
                                />
                                <Route
                                  exact
                                  path="/marketplace/:subcategory"
                                  component={Subcategory}
                                  layout={MarketplacePageLayout}
                                />

                                {/* <Route
                                exact
                                path="/token/:name/buy"
                                component={NotRegisteredDomain}
                                layout={AccountPageLayout}
                              /> */}
                                <Route
                                  exact
                                  path="/domain/:name"
                                  component={RegisteredDomain}
                                  layout={AccountPageLayout}
                                />
                                {/* <Route
                                path="/name/:name"
                                component={SingleName}
                                layout={CheckoutPageLayout}
                              /> */}
                                <Route
                                  exact
                                  path="/chats"
                                  component={Chats}
                                  layout={MarketplacePageLayout}
                                />
                                <Route
                                  exact
                                  path="/listing/:id"
                                  component={Sale}
                                  layout={MarketplacePageLayout}
                                />
                                {/* <Route
                                exact
                                path="/domain-offer"
                                component={DomainOffer}
                                layout={MarketplacePageLayout}
                              /> */}
                                <Route
                                  exact
                                  path="/feed"
                                  component={Feed}
                                  layout={FeedPageLayout}
                                />
                                <Route
                                  exact
                                  path="/categories"
                                  component={Categories}
                                  layout={AccountPageLayout}
                                />
                                <Route
                                  exact
                                  path="/news"
                                  component={News}
                                  layout={AccountPageLayout}
                                />
                                <Route
                                  exact
                                  path="/news/:id"
                                  component={NewsDetail}
                                  layout={AccountPageLayout}
                                />
                                <Route
                                  exact
                                  path="/search"
                                  component={SearchResult}
                                  layout={MarketplacePageLayout}
                                />
                                <Route
                                  exact
                                  path="/trending"
                                  component={Trending}
                                  layout={MarketplacePageLayout}
                                />
                                <Route
                                  exact
                                  path="/about"
                                  component={About}
                                  layout={AccountPageLayout}
                                />
                                <Route
                                  exact
                                  path="/help"
                                  component={HelpCenter}
                                  layout={AccountPageLayout}
                                />
                                <Route
                                  exact
                                  path="/:id"
                                  component={Profile}
                                  layout={MarketplacePageLayout}
                                />
                                <DefaultRoute
                                  path="/registry/:search"
                                  component={Registry}
                                  layout={MarketplacePageLayout}
                                />
                                <DefaultRoute
                                  path="/profile/:id"
                                  render={({ match }) => {
                                    const { id } = match.params
                                    return <Redirect to={`/${id}`} />
                                  }}
                                />
                                <DefaultRoute
                                  path="/:address"
                                  render={({ match }) =>
                                    match.url.includes('0x') ? (
                                      <Redirect
                                        to={`/profile/${match.url.slice(1)}`}
                                      />
                                    ) : (
                                      <Redirect to="/" />
                                    )
                                  }
                                />
                              </Switch>
                              <InvalidNetwork />
                            </TopBarStyleProvider>
                          </CartStoreProvider>
                        </ModalProvider>
                      </FilterProvider>
                    </FeedAnimationProvider>
                  </ImagePreloaderProvider>
                </ApolloContainer>
              </UserActivityProvider>
            </AccountProvider>
          </ApolloProvider>
        </Web3ReactProvider>
      </AnimatedHistoryProvider>
    </Router>
  )
  // } else {
  //   return <MobileScreen />
  // }
}
export default App
