import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_RANKED_DOMAINS, kodexApiContext } from 'service/graphql/kodex-api'
import fetchSimilarDomains from 'service/rest/fetchSimilarDomains'
import useGetDomainCategories from './useGetDomainCategories'
import { BigNumber } from 'ethers'

const useSimilarDomains = (domain, count, orderBy) => {
  const categories = useGetDomainCategories(domain)
  const [similarDomains, setSimilarDomains] = useState([])

  useEffect(() => {
    fetchSimilarDomains(
      domain,
      categories.length > 0 ? categories[0].key : null,
      count
    )
      .then(res => (res?.recommendations || []).map(d => `${d}.eth`))
      .then(domains => {
        setSimilarDomains(domains)
      })
  }, [categories])

  const { data: { domains: domainsRes } = {} } = useQuery(GET_RANKED_DOMAINS, {
    variables: {
      where: {
        domain: {
          _in: similarDomains
        }
      }
    },
    ...kodexApiContext,
    skip: !similarDomains.length
  })

  const results = useMemo(() => {
    const data = domainsRes || []
    if (data.length === 5) {
      return data
    }

    const sortedDomains = similarDomains
      .map(sd => {
        if (data.find(dd => dd.name === sd)) {
          return data.find(dd => dd.name === sd)
        }
        return {
          name: sd
        }
      })
      .sort((a, b) => {
        if (orderBy === 'alphabetical') {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        }

        if (orderBy === 'price_high_to_low') {
          return (
            (b.listing_start_price
              ? BigNumber.from(String(b.listing_start_price))
                  .div(BigNumber.from(10).pow(12))
                  .toNumber()
              : 0) -
            (a.listing_start_price
              ? BigNumber.from(String(a.listing_start_price))
                  .div(BigNumber.from(10).pow(12))
                  .toNumber()
              : 0)
          )
        }

        if (orderBy === 'price_low_to_high') {
          return (
            (a.listing_start_price
              ? BigNumber.from(String(a.listing_start_price))
                  .div(BigNumber.from(10).pow(12))
                  .toNumber()
              : 0) -
            (b.listing_start_price
              ? BigNumber.from(String(b.listing_start_price))
                  .div(BigNumber.from(10).pow(12))
                  .toNumber()
              : 0)
          )
        }

        if (orderBy === 'highest_last_sale') {
          return a.last_price - b.last_price
        }

        if (orderBy === 'most_favorited') {
          return a.likes - b.likes
        }

        return 0
      })

    return sortedDomains
  }, [domainsRes, similarDomains, orderBy])

  return results
}

export default useSimilarDomains
