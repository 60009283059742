import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import View from 'components/View'
import Section from './Section'
import Team from './Team'
import Partners from './Partners'
import AboutUs from './AboutUs'

import AboutTransparentFigure from 'assets/about-page/transparent-figure.webp'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export const About = () => {
  const secondSectionRef = useRef(null)

  useEffect(() => {
    ScrollTrigger.create({
      pin: true,
      trigger: secondSectionRef.current,
      start: 'top top',
      endTrigger: '.about-us',
      end: 'bottom bottom',
      scrub: true,
      pinSpacing: false
    })

    document.title = 'About | Kodex'
  }, [])

  return (
    <View direction="column">
      <SectionWrapper ref={secondSectionRef} height="100vh" zIndex={0}>
        <Section background="#fff">
          <View justify="center" alignItems="center" width="100%">
            <img
              src={AboutTransparentFigure}
              alt="transparent fractal"
              width={420}
              height={420}
            />
          </View>
        </Section>
      </SectionWrapper>
      <AboutUs />
      <Team />
      <Partners />
    </View>
  )
}

const HeaderSection = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
`

const SectionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: ${props => props.height};
  margin-top: ${props => props.marginTop};
  z-index: ${props => props.zIndex};
  overflow: hidden;
  background: ${props => props.background};
`

const HeaderLabelWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: calc(100vh - 600px);
  width: 100%;
`

const FractalWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

export default About
