import { useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled/macro'
import { ethers } from 'ethers'
import EthVal from 'ethval'
import { useContext, useState } from 'react'

import RoundedButton from 'components/Forms/RoundedButton'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'

import { useGasPrice } from 'components/hooks'
import { useAccount } from 'components/QueryAccount'
import { AccountContext } from 'contexts/AccountProvider'
import { COMMIT, REGISTER } from 'service/graphql/mutations'
import {
  GET_BALANCE,
  GET_MINIMUM_COMMITMENT_AGE,
  GET_RENT_PRICE
} from 'service/graphql/queries'

import { ReactComponent as EthPriceWhite } from 'assets/eth-price-white.svg'
import headerBg from 'assets/filter-header.png'
import { randomSecret } from 'utils/register'
import LoadingSpinner from 'components/LoadingSpinner'

const YEAR_IN_SECONDS = 31556952
const COMMIT_GAS_WEI = 42000
const REGISTER_GAS_WEI = 240000
const TOGAL_GAS_WEI = COMMIT_GAS_WEI + REGISTER_GAS_WEI

const RegisterDialog = ({ name, onClose }) => {
  const [duration, setDuration] = useState(1)
  const [currentStep, setCurrentStep] = useState(1)
  const [isRegistering, setRegistering] = useState(false)
  const [txHash, setTxHash] = useState()
  const [secret, setSecret] = useState(randomSecret())
  const { isConnected } = useContext(AccountContext)

  const account = useAccount()
  const { data: { getBalance } = {} } = useQuery(GET_BALANCE, {
    variables: { address: account },
    fetchPolicy: 'no-cache'
  })

  const { data: { getRentPrice } = {} } = useQuery(GET_RENT_PRICE, {
    variables: {
      duration: duration * YEAR_IN_SECONDS,
      label: name?.replace('.eth', ''),
      commitmentTimerRunning: false
    }
  })
  const { price: gasPrice } = useGasPrice(isConnected)
  const { data: { getMinimumCommitmentAge } = {} } = useQuery(
    GET_MINIMUM_COMMITMENT_AGE
  )
  const [handleRequestRegister] = useMutation(COMMIT, {
    onCompleted: data => {
      setTxHash(Object.values(data)[0])
      setCurrentStep(2)
      if (getMinimumCommitmentAge) {
        setTimeout(() => {
          setTxHash(undefined)
          setCurrentStep(3)
        }, getMinimumCommitmentAge * 1000)
      }
    },
    variables: {
      label: name?.replace('.eth', ''),
      secret: secret,
      commitmentTimerRunning: !!txHash
    }
  })
  const [handleRegister] = useMutation(REGISTER, {
    onCompleted: data => {
      setRegistering(false)
      setCurrentStep(4)
    },
    variables: {
      label: name?.replace('.eth', ''),
      secret: secret,
      duration: duration * YEAR_IN_SECONDS
    }
  })

  const priceNum =
    !!getRentPrice &&
    Number(ethers.utils.formatEther(getRentPrice)).toLocaleString(undefined, {
      maximumFractionDigits: 4
    })
  const registerGasFast =
    !!gasPrice.fast &&
    new EthVal(`${TOGAL_GAS_WEI * gasPrice.fast}`).toEth().toFixed(4)
  const totalAmount = (
    Number(priceNum) + Number(registerGasFast)
  ).toLocaleString(undefined, { maximumFractionDigits: 4 })

  console.log('getBalance: ', getBalance)
  const isInSufficient = !!getBalance
    ? ethers.utils.formatEther(getBalance) < totalAmount
    : true

  if (currentStep === 4) {
    return (
      <View
        width="720px"
        height="100%"
        direction="column"
        borderRadius="6px"
        style={{ overflow: 'hidden' }}
      >
        <View
          flex={1}
          direction="column"
          alignItems="center"
          justify="space-between"
          background="#3d98a8"
          padding="50px"
          width="100%"
        >
          <Label size={40} align="center" color="#fff">
            DOMAIN REGISTERED
            <br />
            SUCCESSFULLY!
          </Label>
          <RoundedButton
            width="350px"
            height="70px"
            background="#fff"
            onClick={onClose}
          >
            <Label size={16} color="#020202">
              BACK
            </Label>
          </RoundedButton>
        </View>
      </View>
    )
  }

  return (
    <View
      width="720px"
      height="100%"
      direction="column"
      borderRadius="6px"
      style={{ overflow: 'hidden' }}
    >
      <View
        height="140px"
        width="100%"
        background="#8b9bb9"
        padding="0px 40px"
        alignItems="center"
        justify="space-between"
      >
        <img
          src={headerBg}
          alt="headerBg"
          style={{ position: 'absolute', left: 95, top: 0 }}
        />
        <Label size={40} color="#fff">
          {name}
        </Label>
      </View>
      <View
        flex={1}
        direction="column"
        background="#fff"
        padding="60px 40px"
        width="100%"
      >
        <Label size={13} color="#BCBCCC">
          PERIOD
        </Label>
        <Space size={20} />
        <View
          height="70px"
          width="100%"
          background="#F3F3FA"
          border="1px solid #EBEBF2"
          borderRadius="10px"
          justify="space-between"
          alignItems="center"
        >
          <Label color="#020202" style={{ paddingLeft: 25 }} size={16}>
            {duration} YEAR
          </Label>
          <View height="100%" alignItems="center">
            <View
              width="120px"
              height="60px"
              borderRadius="6px"
              background="#fff"
              overflow="hidden"
            >
              <StyledButton
                onClick={() => setDuration(val => val - 1)}
                disabled={duration === 1}
              >
                -
              </StyledButton>
              <StyledButton onClick={() => setDuration(val => val + 1)}>
                +
              </StyledButton>
            </View>
            <Space size={10} />
            {currentStep === 3 ? (
              <RoundedButton
                width="135px"
                height="70px"
                background="#0698A8"
                color="#fff"
                disabled={isRegistering}
                onClick={() => {
                  if (!isRegistering) {
                    setRegistering(true)
                    handleRegister()
                  }
                }}
              >
                {isRegistering ? 'CONFIRMI...' : 'REGISTER'}
              </RoundedButton>
            ) : !!txHash ? (
              <RoundedButton
                disabled
                width="135px"
                height="70px"
                background="#0698A8"
                color="#fff"
              >
                <Label>Pending</Label>
                <LoadingSpinner color="white" margin="0 0 0 20px" />
              </RoundedButton>
            ) : (
              <RoundedButton
                disabled={isInSufficient}
                width="135px"
                height="70px"
                background="#0698A8"
                color="#fff"
                onClick={() => {
                  handleRequestRegister()
                }}
              >
                CONFIRM
              </RoundedButton>
            )}
          </View>
        </View>
        <Space size={20} />
        <View
          background="#FFF1EA"
          borderRadius="10px"
          direction="column"
          padding="20px"
          width="100%"
        >
          <View alignItems="center">
            <View background="#fff" padding="12px 18px" borderRadius="6px">
              <Label size={16} color="#020202">
                {currentStep}/3
              </Label>
            </View>
            <Space size={20} />
            <Label color="#020202">
              {currentStep === 1
                ? 'Request to register'
                : currentStep === 2
                ? 'Wait for 1 minute'
                : 'Complete registration'}
            </Label>
          </View>
          <Space size={20} />
          <Label color="#020202">
            {currentStep === 1
              ? 'Your wallet will open and you will be asked to confirm the first of two transactions required for registration.'
              : currentStep === 2
              ? `The waiting period is required to verify the domain can be validly registered.`
              : `Click 'register' and your wallet will re-open. Confirm the transaction and the domain will be yours!`}
          </Label>
        </View>
        <Space size={40} />
        <View
          height="60px"
          width="100%"
          justify="space-between"
          alignItems="center"
        >
          <Label size={16} color="#020202">
            REGISTRATION COST
          </Label>
          <View alignItems="center">
            <Label size={16} color="#020202">
              {priceNum}
            </Label>
            <Space size={20} />
            <EthPriceWhite />
          </View>
        </View>
        <View
          height="60px"
          width="100%"
          justify="space-between"
          alignItems="center"
        >
          <Label size={16} color="#020202">
            GAS FEE
          </Label>
          <View alignItems="center">
            <Label size={16} color="#020202">
              {registerGasFast}
            </Label>
            <Space size={20} />
            <EthPriceWhite />
          </View>
        </View>
        <Space size={20} />
        <View border="1px solid #EBEBF2" width="100%" />
        <Space size={20} />
        <View
          height="60px"
          width="100%"
          justify="space-between"
          alignItems="center"
        >
          <Label size={16} color="#020202">
            TOTAL AMOUNT
          </Label>
          <View alignItems="center">
            <Label size={16} color="#020202">
              {totalAmount}
            </Label>
            <Space size={20} />
            <EthPriceLight />
          </View>
        </View>
      </View>
    </View>
  )
}

const StyledButton = styled.button`
  border: none;
  flex: 1;
  height: 100%;
  color: ${props => (props.disabled ? '#C4C4C4' : '#020202')};
  background: #fff;
  font-size: 30px;
  cursor: pointer;
  font-family: 'Satoshi', sans-serif;
`

export default RegisterDialog
