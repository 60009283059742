import { createContext, useEffect } from 'react'
import { checkAuthenticationStatus } from 'service/siwe/checkAuthenticationStatus'
import { JETTY_URL } from 'service/siwe/constants'
import { siweAuthedFetch } from 'service/siwe/siweFetch'

export const UserActivityContext = createContext({
  handleUserSeen: async () => {}
})

const UserActivityProvider = ({ children }) => {
  const handleUserSeen = async () => {
    const authenticationStatus = await checkAuthenticationStatus()
    if (authenticationStatus) {
      siweAuthedFetch(`${JETTY_URL}/user/seen`, {
        method: 'POST'
      })
    }
  }

  useEffect(() => {
    window.addEventListener('load', handleUserSeen)

    return () => {
      window.removeEventListener('load', handleUserSeen)
    }
  }, [])

  return (
    <UserActivityContext.Provider
      value={{
        handleUserSeen
      }}
    >
      {children}
    </UserActivityContext.Provider>
  )
}

export default UserActivityProvider
