import styled from '@emotion/styled/macro'
import Space from 'components/Space'
import View from 'components/View'
import Label from 'components/Label'

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  margin: 5px 0;
  padding: 0 20px;
  position: relative;
  width: 100%;
`

const Cell = styled.div`
  align-items: center;
  display: flex;
  flex: ${({ constrained }) => (constrained ? '' : '1')};
  gap: 20px;
  justify-content: ${({ constrained }) => (constrained ? '' : 'flex-end')};
  width: ${({ constrained, category, isDarkMode }) =>
    constrained
      ? isDarkMode
        ? category
          ? '22%'
          : '24%'
        : category
        ? '17.75%'
        : '21%'
      : ''};
`

const Loading = styled.div`
  animation: shimmerBackground 1s infinite;
  background: linear-gradient(
    to right,
    ${props => (!props.isDarkMode ? '#e3e3ea' : '#363636')},
    ${props => (!props.isDarkMode ? '#F3F3FA' : '#262628')},
    ${props => (!props.isDarkMode ? '#e3e3ea' : '#363636')}
  );
  background-size: 200% 100%;
  border-radius: 6px;
  height: ${({ height }) => (isNaN(height) ? height : height + 'px')};
  width: ${({ width }) => (isNaN(width) ? width : width + 'px')};

  @keyframes shimmerBackground {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
`

const LoadingNetTokenRow = ({ isDarkMode, trending, marketplace }) => {
  return marketplace !== undefined ? (
    <View alignItems="center" height="60px" margin="5px 0" width="100%">
      <View flex="20%" padding="0 0 0 20px">
        <Loading height={16} width="50%" isDarkMode={isDarkMode} />
      </View>
      <View flex="20%">
        <Loading height={16} width="50%" isDarkMode={isDarkMode} />
      </View>
      <View flex="20%">
        <Loading height={16} width="50%" isDarkMode={isDarkMode} />
      </View>

      <View flex="20%">
        <Loading height={16} width="50%" isDarkMode={isDarkMode} />
      </View>
      <View flex="18%">
        <Loading height={16} width="50%" isDarkMode={isDarkMode} />
      </View>
      <View flex="2%" />
    </View>
  ) : (
    <Container>
      <Cell constrained isDarkMode={isDarkMode}>
        <Loading
          height={16}
          width={trending ? 100 : 150}
          isDarkMode={isDarkMode}
        />
      </Cell>
      <Cell constrained category isDarkMode={isDarkMode}>
        <Loading
          height={16}
          width={trending ? 100 : 120}
          isDarkMode={isDarkMode}
        />
      </Cell>
      {trending && (
        <>
          <Cell constrained category isDarkMode={isDarkMode}>
            <Loading height={16} width={100} isDarkMode={isDarkMode} />
          </Cell>
          <Cell constrained category isDarkMode={isDarkMode}>
            <Loading height={16} width={100} isDarkMode={isDarkMode} />
          </Cell>
          <Cell constrained category isDarkMode={isDarkMode}>
            <Loading height={16} width={100} isDarkMode={isDarkMode} />
          </Cell>
          <Cell constrained category isDarkMode={isDarkMode}>
            <Loading height={16} width={100} isDarkMode={isDarkMode} />
          </Cell>
          <Cell constrained category isDarkMode={isDarkMode}>
            <Loading height={16} width={60} isDarkMode={isDarkMode} />
          </Cell>
          <Cell constrained category isDarkMode={isDarkMode}>
            <Loading height={16} width={60} isDarkMode={isDarkMode} />
          </Cell>
        </>
      )}
      {!isDarkMode && (
        <Cell constrained isDarkMode={isDarkMode}>
          <Loading height={16} width={80} isDarkMode={isDarkMode} />
        </Cell>
      )}
      {!trending && (
        <>
          <Cell constrained isDarkMode={isDarkMode}>
            <Loading height={40} width={40} isDarkMode={isDarkMode} />
            <Loading height={16} width={80} isDarkMode={isDarkMode} />
          </Cell>
          <Cell constrained isDarkMode={isDarkMode}>
            <Loading height={40} width={40} isDarkMode={isDarkMode} />
            <Loading height={16} width={80} isDarkMode={isDarkMode} />
          </Cell>
        </>
      )}
      {/* <Cell isDarkMode={isDarkMode}>
        <Loading height={50} width={50} isDarkMode={isDarkMode} />
      </Cell> */}
    </Container>
  )
}

export default LoadingNetTokenRow
