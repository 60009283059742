import useGetFeedItems from './useGetFeedItems'
import useGetFeedChart from './useGetFeedChart'
import { useMemo } from 'react'

export default (duration, stepSize) => {
  const timestampRange = useMemo(() => {
    const minDate = new Date()
    const maxDate = new Date()

    if (duration?.label !== 'D') {
      maxDate.setHours(23)
      maxDate.setMinutes(59)
      maxDate.setSeconds(59)
      minDate.setHours(23)
      minDate.setMinutes(59)
      minDate.setSeconds(59)
    }

    minDate.setDate(maxDate.getDate() - duration?.value)

    if (duration?.label !== 'D') {
      minDate.setSeconds(minDate.getSeconds() + 1)
    }

    return {
      min_timestamp: minDate,
      max_timestamp: maxDate,
      min_timestampISO: minDate.toISOString(),
      max_timestampISO: maxDate.toISOString()
    }
  }, [duration])

  const feedItems = useGetFeedItems(timestampRange)
  const chart = useGetFeedChart(timestampRange, duration, stepSize)

  return {
    feedItems,
    chart
  }
}
