const { JETTY_URL } = require('service/siwe/constants')

export const getFeedEventsData = async filter => {
  const {
    limit = 50,
    offset = 0,
    min_domain_length,
    max_domain_length,
    min_price,
    max_price,
    min_timestamp,
    max_timestamp,
    search_terms,
    event_types,
    asset
  } = filter

  const params = [
    `domain_name=`,
    `&limit=${limit}`,
    `&offset=${offset}`,
    `&event_types=${event_types || ''}`,
    asset ? `&asset=${asset}` : null,
    max_domain_length ? `&max_domain_length=${max_domain_length}` : null,
    min_domain_length ? `&min_domain_length=${min_domain_length}` : null,
    max_price ? `&max_price=${max_price}` : null,
    min_price ? `&min_price=${min_price}` : null,
    max_timestamp ? `&max_timestamp=${max_timestamp}` : null,
    min_timestamp ? `&min_timestamp=${min_timestamp}` : null,
    `&search_terms=${search_terms ? search_terms.join(',') : ''}`
  ].join('')

  try {
    const req = fetch(`${JETTY_URL}/feed/events?${params}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    const res = await req.then(data => data).then(data => data.json())

    return res.events
  } catch (e) {
    console.error(e)
  }
}

export const getFeedChartData = async filter => {
  const {
    min_domain_length,
    max_domain_length,
    min_price,
    max_price,
    min_timestamp,
    max_timestamp,
    search_terms,
    event_types,
    asset,
    time_range
  } = filter

  const params = [
    `domain_name=`,
    `&time_range=${time_range}`,
    `&event_types=${event_types || ''}`,
    asset ? `&asset=${asset}` : null,
    max_domain_length ? `&max_domain_length=${max_domain_length}` : null,
    min_domain_length ? `&min_domain_length=${min_domain_length}` : null,
    max_price ? `&max_price=${max_price}` : null,
    min_price ? `&min_price=${min_price}` : null,
    max_timestamp ? `&max_timestamp=${max_timestamp}` : null,
    min_timestamp ? `&min_timestamp=${min_timestamp}` : null,
    `&search_terms=${search_terms ? search_terms.join(',') : ''}`
  ].join('')

  try {
    const req = fetch(`${JETTY_URL}/feed/aggregate?${params}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    const res = await req.then(data => data).then(data => data.json())
    console.log(res)

    return res.aggregations
  } catch (e) {
    console.error(e)
  }
}
