import { JETTY_URL } from 'service/siwe/constants'
import { reservoirAPIURL } from 'utils/reservoir'
import { ENS_CONTRACT_ADDRESS } from 'utils/utils'

export const getOffers = async ({
  address,
  token_id,
  limit = 10,
  offset = 0,
  maker,
  source
}) => {
  try {
    const req = fetch(
      `${reservoirAPIURL}/orders/bids/v5?${
        token_id && address ? `token=${address}:${token_id}&` : ''
      }${maker ? `maker=${maker}&` : ''}${
        source ? `source=${source}&` : ''
      }status=active`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )

    const res = await req.then(res => res).then(data => data.json())

    // console.log(res)

    return res?.orders || []
  } catch (err) {
    throw new Error(err)
  }
}

export const getSingleOffer = async ({
  assetContractAddress = ENS_CONTRACT_ADDRESS,
  token_ids = '',
  limit = 100
}) => {
  try {
    const req = fetch(
      `${JETTY_URL}/offers?asset_contract_address=${assetContractAddress}&token_ids=${token_ids}&limit=${limit}&active=true`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )

    const res = await req.then(res => res.json()).then(data => data)
    return res
  } catch (err) {
    throw new Error(err)
  }
}
