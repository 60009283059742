import { getSigner } from '@ensdomains/ui'
import { Seaport } from '@opensea/seaport-js'
import { OPENSEA_CONDUIT_KEY } from '@opensea/seaport-js/lib/constants'
import { ethers } from 'ethers'
import { formatEther } from 'ethers/lib/utils'

const ensBaseRegistrarAddress = '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85'
const feeBps = parseInt(process.env.REACT_APP_KODEX_SEAPORT_FEE_BPS, 10)

/**
 * @param {number} amount
 */
const stringifiedAmount = amount => {
  return ethers.utils.parseEther(amount).toString()
}

/**
 * @param {string} name
 * @param {import('ethers').BigNumber} price
 * @param {number} duration
 * @returns {Promise<import('@opensea/seaport-js/lib/types').OrderUseCase<import('@opensea/seaport-js/lib/types').CreateOrderAction>>}
 */
export const list = async (name, price, duration) => {
  const signer = await getSigner()
  const seaport = new Seaport(signer, {
    overrides: {
      defaultConduitKey: OPENSEA_CONDUIT_KEY
    }
  })
  const seller = await signer.getAddress()

  const nameLabel = name.replace('.eth', '')
  const tokenId = ethers.BigNumber.from(
    ethers.utils.keccak256(ethers.utils.toUtf8Bytes(nameLabel))
  ).toString()

  const ethValue = parseFloat(formatEther(price))
  const systemFeeAmount = feeBps !== 0 ? ethValue / feeBps : 0
  const sellerAmount = ethValue - systemFeeAmount

  const considerations = [
    {
      amount: stringifiedAmount(sellerAmount.toFixed(6)),
      recipient: seller
    }
  ]

  if (systemFeeAmount !== 0)
    considerations.push({
      amount: stringifiedAmount(systemFeeAmount.toFixed(6)),
      recipient: '0x171940bFcBB287744D644E07333D6738fCC53DbF'
    })

  return seaport.createOrder({
    startTime: `0`,
    endTime: `${duration}`,
    offer: [
      {
        itemType: 2, // ItemType.ERC721,
        token: ensBaseRegistrarAddress,
        identifier: tokenId
      }
    ],
    consideration: considerations,
    domain: 'kodex.io'
  })
}

/**
 * @param {string} name
 * @param {string} owner
 * @param {import('ethers').BigNumber} offerAmount
 * @param {number} duration
 * @returns {Promise<import('@opensea/seaport-js/lib/types').OrderUseCase<import('@opensea/seaport-js/lib/types').CreateOrderAction>>}
 */
export const offer = async (name, owner, offerAmount, duration) => {
  const signer = await getSigner()
  const seaport = new Seaport(signer, {
    overrides: {
      defaultConduitKey: OPENSEA_CONDUIT_KEY
    }
  })
  const offerer = await signer.getAddress()

  const nameLabel = name.replace('.eth', '')
  const tokenId = ethers.BigNumber.from(
    ethers.utils.keccak256(ethers.utils.toUtf8Bytes(nameLabel))
  ).toString()

  const ethValue = parseFloat(formatEther(offerAmount))
  const systemFeeAmount = feeBps !== 0 ? ethValue / feeBps : 0
  const sellerAmount = ethValue - systemFeeAmount

  const considerations = [
    {
      itemType: 2, // ItemType.ERC721,
      token: ensBaseRegistrarAddress,
      identifier: tokenId,
      recipient: offerer
    },
    {
      amount: stringifiedAmount(sellerAmount.toFixed(6)),
      token: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', // WETH
      recipient: owner
    }
  ]

  if (systemFeeAmount !== 0)
    considerations.push({
      amount: stringifiedAmount(systemFeeAmount.toFixed(6)),
      token: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', // WETH
      recipient: '0x171940bFcBB287744D644E07333D6738fCC53DbF'
    })

  return seaport.createOrder({
    startTime: `0`,
    endTime: `${duration}`,
    offer: [
      {
        amount: stringifiedAmount(ethValue.toFixed(6)),
        token: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' // WETH
      }
    ],
    consideration: considerations,
    domain: 'kodex.io'
  })
}

export const buy = async parsedOrders => {
  const signer = await getSigner()
  const seaport = new Seaport(signer, {
    overrides: {
      defaultConduitKey: OPENSEA_CONDUIT_KEY
    }
  })

  const orders = parsedOrders.map(order => ({ order }))

  return seaport.fulfillOrders({
    fulfillOrderDetails: orders,
    domain: 'kodex.io'
  })
}

export const cancel = async stringifiedOrders => {
  const signer = await getSigner()
  const seaport = new Seaport(signer, {
    overrides: {
      defaultConduitKey: OPENSEA_CONDUIT_KEY
    }
  })

  const orders = stringifiedOrders

  return seaport.cancelOrders(orders, undefined, 'kodex.io')
}

export const cancelAll = async () => {
  const signer = await getSigner()
  const seaport = new Seaport(signer, {
    overrides: {
      defaultConduitKey: OPENSEA_CONDUIT_KEY
    }
  })

  return seaport.bulkCancelOrders(undefined, 'kodex.io')
}
