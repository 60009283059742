import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import React, { useEffect, useMemo, useState } from 'react'
import { ReactComponent as TrendingFire } from 'assets/trending-fire.svg'
import { getTrendingCategories } from 'service/rest/trending'
import { useParams } from 'react-router'
import {
  GET_CATEGORY_FLOOR_PRICE,
  kodexApiContext
} from 'service/graphql/kodex-api'
import { useLazyQuery } from '@apollo/client'
import { utils } from 'ethers'
import { capitalize } from 'utils/format'
import categoryChart from './chart/categoryChart'
import RoundedButton from 'components/Forms/RoundedButton'
import moment from 'moment'

const totalLabels = [
  'Floor',
  'Highest offer',
  'Highest sale',
  'Unique owners',
  'Domains',
  '24H Volume',
  'Total volume'
]

export const formatBigNumber = number => {
  // 16704 => 16 704
  return number
    .toString()
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g)
    .join(' ')
    .split('')
    .reverse()
    .join('')
}

const chartDurationLabels = ['7D', '14D', '30D', '2M', '6M']

const CategoryHeader = () => {
  const { subcategory: categoryParam } = useParams()

  const [categoryData, setCategoryData] = useState({})
  const [categoryFloor, setCategoryFloor] = useState(null)
  const [chart, setChart] = useState(null)
  const [selectedDurationIndex, setSelectedDurationIndex] = useState(0)

  const [fetchFloorPrice, { data: floorPriceData }] = useLazyQuery(
    GET_CATEGORY_FLOOR_PRICE,
    {
      ...kodexApiContext
    }
  )

  const getCategoriesData = async () => {
    const categoriesData = await getTrendingCategories()

    if (!categoriesData) return

    const currentCategory = categoriesData.filter(
      category =>
        category.taxonomy.toLowerCase() === categoryParam.toLowerCase()
    )[0]

    setCategoryData(currentCategory)
  }

  useEffect(() => {
    const floorPriceRaw =
      floorPriceData?.get_feed_aggregate?.aggregate?.min.price
    if (!floorPriceRaw) return
    const floorPrice = Number(utils.formatEther(BigInt(floorPriceRaw)))
    setCategoryFloor(floorPrice)
  }, [floorPriceData])

  useEffect(() => {
    getCategoriesData()
  }, [])

  useEffect(() => {
    if (!categoryData) return

    fetchFloorPrice({
      variables: {
        search_taxa: `{${categoryData.taxonomy}}`
      }
    })
  }, [categoryData])

  const totalsData = useMemo(() => {
    if (!categoryData && !categoryFloor) return []
    return [
      {
        label: 'Floor',
        value: categoryFloor ?? 0 + ' ETH'
      },
      {
        label: 'Highest offer',
        value: '15.51 WETH'
      },
      {
        label: 'Highest sale',
        value: '50.00 ETH'
      },
      {
        label: 'Unique owners',
        value: formatBigNumber(categoryData.owners ?? 0)
      },
      {
        label: 'Domains',
        value: formatBigNumber(categoryData.supply ?? 0)
      },
      {
        label: '24H Volume',
        value: categoryData.sale_volume_day ?? 0 + ' ETH'
      },
      {
        label: 'Total volume',
        value: '1 755.98 ETH'
      }
    ]
  }, [categoryData, categoryFloor])

  useEffect(() => {
    if (chart) return
    const newChart = categoryChart({
      labels: new Array(10).fill('').map((item, i) =>
        moment()
          .add(i, 'days')
          .format('DD MMMM YYYY hh:mm a')
      ),
      data: [10, 20, 100, 15, 60, 40, 30, 30, 120, 90]
    })
    setChart(newChart)
  }, [])

  useEffect(() => {
    if (!chart) return
    chart.update()
  }, [categoryData, categoryFloor])

  return (
    <View direction="column" width="100%">
      <View width="100%">
        <View flex="50%" direction="column" alignItems="start">
          <View direction="column" height="160px">
            <Label color="#0698A8" size={80}>
              {capitalize(categoryParam)}
            </Label>
            <Space size={20} />
            <Label color="#BCBCCC" letterSpacing="0.03em">
              Far far away, behind the word mountains, far from.
            </Label>
          </View>
          <View height="100%" gap="10px">
            <View
              border="1px solid #CACADB"
              borderRadius="6px"
              padding="20px"
              alignItems="center"
              height="60px"
              gap="10px"
            >
              <TrendingFire width={20} height={20} />
              <Label color="#020202" letterSpacing="0.03em" weight={700}>
                Trending
              </Label>
            </View>
            <View
              border="1px solid #CACADB"
              borderRadius="6px"
              padding="22px 20px"
              height="60px"
            >
              <Label color="#020202" letterSpacing="0.03em" weight={700}>
                Top users
              </Label>
            </View>
          </View>
        </View>
        <View direction="column" flex="50%" alignItems="end">
          <View width="100%" height="160px">
            <canvas id="categoryChart" />
          </View>
          <View
            width="100%"
            gap="10px"
            justify="end"
            height="60px"
            margin="10px 0 0 0"
          >
            {chartDurationLabels.map((label, i) => {
              return (
                <RoundedButton
                  height="60px"
                  width="60px"
                  hoverBackground={
                    selectedDurationIndex === i
                      ? '#F3F3FA'
                      : 'rgba(243, 243, 250,0.5)'
                  }
                  background={
                    selectedDurationIndex === i ? '#F3F3FA' : 'transparent'
                  }
                  onClick={() => setSelectedDurationIndex(i)}
                  padding="20px"
                  borderRadius="6px"
                >
                  <Label
                    color={selectedDurationIndex === i ? '#020202' : '#BCBCCC'}
                    weight={700}
                    size={13}
                  >
                    {label}
                  </Label>
                </RoundedButton>
              )
            })}
          </View>
        </View>
      </View>
      <Space size={40} />
      <View
        width="100%"
        border="1px solid #CACADB"
        borderRadius="10px"
        padding="30px"
        justify="space-between"
      >
        {!totalsData || totalsData.length === 0
          ? totalLabels.map((label, i) => (
              <TotalsLoading key={i} label={label} />
            ))
          : totalsData.map((item, i) => {
              return (
                <View key={i} direction="column" gap="20px" width="100%">
                  <Label size={13} color="#BCBCCC">
                    {item.label.toUpperCase()}
                  </Label>
                  <Label weight={700} color="#020202">
                    {item.value}
                  </Label>
                </View>
              )
            })}
      </View>
    </View>
  )
}

const TotalsLoading = ({ label }) => {
  return (
    <View direction="column" gap="20px" width="100%">
      <Label size={13} color="#BCBCCC">
        {label.toUpperCase()}
      </Label>
      <View
        animation="loading 1s infinite"
        background="linear-gradient(to right, rgba(2,2,2,0.07), rgba(2,2,2,0.05), rgba(2,2,2,0.07), rgba(2,2,2,0.05), rgba(2,2,2,0.07))"
        width="50%"
        height="20px"
        borderRadius="6px"
      />
    </View>
  )
}

export default CategoryHeader
