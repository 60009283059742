import { useState, useEffect } from 'react'
import styled from '@emotion/styled/macro'
import { ReactComponent as ArrowDown } from 'assets/arrow-down-white.svg'
import { ReactComponent as ArrowDownGray } from 'assets/arrow-down-gray.svg'

const FilterType = ({
  label,
  otherText,
  movement,
  onClick: onClickProp,
  expansionCount,
  page,
  children,
  darkMode
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const onClick = onClickProp ?? (() => setIsExpanded(prev => !prev))

  useEffect(() => {
    setIsExpanded(false)
  }, [page])

  return (
    <Container
      isExpanded={isExpanded}
      expansionCount={expansionCount}
      darkMode={darkMode}
    >
      <Row
        invertSVG={isExpanded}
        rotateSVG={movement === 'right'}
        onClick={onClick}
      >
        <Label>{label}</Label>
        <Text darkMode={darkMode}>{otherText}</Text>
        {darkMode ? <ArrowDown /> : <ArrowDownGray />}
      </Row>
      {children}
    </Container>
  )
}

export default FilterType

const Container = styled.div`
  border: 1px solid ${({ darkMode }) => (darkMode ? '#333' : '#DADAE7')};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: ${({ isExpanded, expansionCount }) =>
    isExpanded ? (Number(expansionCount) + 1) * 52 + 'px' : '52px'};
  max-height: ${({ isExpanded, expansionCount }) =>
    isExpanded ? (Number(expansionCount) + 1) * 52 + 'px' : '52px'};
  overflow-y: hidden;
  padding: 18px 16px;
  transition: all 0.2s ease-in-out;
`

const Row = styled.div`
  align-items: center;
  display: flex;

  svg {
    height: 10px;
    margin-left: 10px;
    transform: ${({ rotateSVG, invertSVG }) =>
      rotateSVG ? 'rotate(-90deg)' : invertSVG ? 'rotate(180deg)' : ''};
    transition: all 0.1s ease-in;
    stroke: white;
  }
`

const Label = styled.p`
  margin: 0;
  flex: 1;
`

const Text = styled.p`
  color: ${({ darkMode }) => (darkMode ? '#999999' : '##919CA6')};
  font-weight: 400;
  margin: 0;
`
