import styled from '@emotion/styled'
import Link from 'components/Forms/Link'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import React, { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import AccountSupportCard from './components/AccountSupportCard'

const AccountSupportSettingTab = ({
  listingsCount,
  offersCount,
  onCancelAllListingsClick,
  onSubmitRequestClick,
  selected
}) => {
  if (!selected) {
    return <></>
  }

  const shouldDisplayCancelAllCard = useMemo(
    () => listingsCount > 0 || offersCount > 0,
    [listingsCount, offersCount]
  )

  return (
    <View direction="column" width="100%">
      <View width="100%">
        <AccountSupportCard
          title="General help"
          content="Visit our help center to learn how to get started with buying, selling, and creating."
          button={
            <RouterLink to="/help">
              <Link hoverOpacity={0.6}>
                <Label size={16} color="#020202">
                  Go to help center
                </Label>
              </Link>
            </RouterLink>
          }
        />
        <Spacer20 />
        <AccountSupportCard
          title="Contact support"
          content="can’t find the answers you’re looking for? You can submit a request here."
          button={
            <Link hoverOpacity={0.6} onClick={onSubmitRequestClick}>
              <Label size={16} color="#020202">
                Submit a request
              </Label>
            </Link>
          }
        />
      </View>
      <Space size={20} />
      {shouldDisplayCancelAllCard && (
        <View
          borderRadius="10px"
          border="1px solid #E1E1E8"
          padding="40px 25px 20px 30px"
          direction="column"
          width="100%"
        >
          <Space size={17} />
          <Label weight={600} size={16} color="#020202">
            Cancel all listings and offers
          </Label>
          <Space size={34} />
          <Label size={16} color="#BCBCCC" lineHeight={1.6}>
            We will cancel{' '}
            <Label size={16} color="#020202" lineHeight={1.6}>
              {listingsCount} listings
            </Label>{' '}
            and{' '}
            <Label size={16} color="#020202" lineHeight={1.6}>
              {offersCount} offers
            </Label>
            .
          </Label>
          <Label size={16} color="#BCBCCC" lineHeight={1.6}>
            Do not proceed if you intend to cancel a single listing or offer.
          </Label>
          <Space size={27} />
          <View
            background="#FFF1EA"
            borderRadius="10px"
            padding="19px 0 18px 20px"
            width="100%"
          >
            <Label size={13} color="rgba(2, 2, 2, 0.5)">
              This method will save gas compared to cancelling a single listing
              or offer.
            </Label>
          </View>
          <Space size={37} />
          <Link hoverOpacity={0.6} onClick={onCancelAllListingsClick}>
            <Label size={16} color="#020202">
              Cancel all
            </Label>
          </Link>
          <Space size={17} />
        </View>
      )}
    </View>
  )
}

const Spacer20 = styled.div`
  min-width: 20px;
`

export default AccountSupportSettingTab
