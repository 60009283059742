import styled from '@emotion/styled/macro'
import { keyframes } from 'emotion'

const Caret = ({ focused, left, width, height, transform, top }) => {
  return (
    <CaretComponent
      focused={focused}
      left={left}
      width={width}
      height={height}
      transform={transform}
      top={top}
    />
  )
}

const blink = keyframes`
  50% {
    border-color: transparent;
    background-color: transparent;
  }
`

const CaretComponent = styled.div`
  position: absolute;
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: ${props => (props.focused ? '#A14F9D' : 'transparent')};
  animation-name: ${blink};
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  top: ${props => props.top};
  transform: ${props => props.transform};
  left: ${props => props.left}px;
  z-index: 1;
`

export default Caret
