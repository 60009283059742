import SearchNoResults from 'assets/search-no-results.png'
import Link from 'components/Forms/Link'
import Label from 'components/Label'
import Space from 'components/Space'
import View from 'components/View'
import gsap from 'gsap'
import { useEffect, useRef } from 'react'
import SearchResultItem from './SearchResultItem'
import SearchResultTitle from './SearchResultTitle'

const NoResults = ({ categories }) => {
  const rootRef = useRef(null)

  useEffect(() => {
    const elems = rootRef.current.querySelectorAll('.inview')
    gsap.fromTo(
      elems,
      {
        y: 30,
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        stagger: {
          each: 0.065
        }
      }
    )
  }, [])

  return (
    <View ref={rootRef} direction="column" width="100%" alignItems="center">
      <SearchResultTitle text="Sorry, we couldn't find anything" />
      <Space size={40} />
      <img src={SearchNoResults} alt="No results image" />
      <Space size={40} />
      <SearchResultTitle text="You might like this instead" />
      <View wrappable width="100%">
        {categories.slice(0, 2).map(category => (
          <SearchResultItem key={category.key} className="inview">
            <Link
              hoverOpacity={0.6}
              onClick={() => {
                history.push(
                  `/marketplace?category=${category.key.toLowerCase()}`
                )
              }}
              overflow="hidden"
            >
              <Label size={16} color={category.color}>
                {category.name}
              </Label>
            </Link>
          </SearchResultItem>
        ))}
      </View>
      <Space size={4} />
    </View>
  )
}

export default NoResults
