import { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom'

import RoundedButton from 'components/Forms/RoundedButton'
import TableIcon from 'components/Icon/TableIcon'
import Space from 'components/Space'
import { Cell, Column, HeaderCell, Table } from 'components/Table'
import View from 'components/View'

import { ReactComponent as ArrowTop } from 'assets/arrow-top.svg'
import Label from 'components/Label'
import LoadingSpinner from 'components/LoadingSpinner'
import { useAccount } from 'components/QueryAccount'
import moment from 'moment'
import NoItems from 'views/Checkout/components/NoItems'
import Offerer from './Offerer'

const YEAR_IN_SECONDS = 365.25 * 24 * 60 * 60
const DAY_IN_SECONDS = 24 * 60 * 60

const Offers = ({
  parsedOffers,
  cancelOffer,
  navItem,
  setHighestOffer,
  canceling,
  acceptOffer,
  accepting,
  ownedDomain
}) => {
  const [offers, setOffers] = useState([])
  const account = useAccount()

  useEffect(() => {
    // order offers by price
    const sortedOffers = parsedOffers.sort(
      (a, b) => Number(b.validFrom) - Number(a.validFrom)
    )

    sortedOffers.map(offer => {
      const from = moment(offer.validFrom * 1000)
      const until = moment(offer.validUntil * 1000)
      const duration = until.from(from, true)
      offer.offerDuration = duration
    })

    // offers sorted by expiry date - latest
    const sortedByExpiryDate = parsedOffers.sort(
      (a, b) => b.expiration - a.expiration
    )

    const highestOfferAmount = parsedOffers?.reduce((acc, curr) => {
      const price = curr.price.amount.decimal

      if (price > acc) {
        return price
      } else {
        return acc
      }
    }, 0)

    setHighestOffer(highestOfferAmount)
    setOffers(sortedOffers)
  }, [parsedOffers, navItem])

  return parsedOffers?.length > 0 ? (
    <Table data={offers} height={250} background="transparent">
      <Column>
        <HeaderCell>Event</HeaderCell>
        <Cell>
          {rowData => {
            return (
              <View alignItems="center" background="white">
                <TableIcon
                  icon={<ArrowTop />}
                  stroke="#0698A8"
                  background="#E8F5F6"
                />
                <Space size={20} />
                <span>Offer</span>
              </View>
            )
          }}
        </Cell>
      </Column>
      <Column>
        <HeaderCell>Price</HeaderCell>
        <Cell>
          {rowData => {
            const price = rowData.price.amount.decimal
            return (
              <View alignItems="center">
                <Label number size={16} color="black">
                  {price.toLocaleString('default').replaceAll(',', ' ')}
                  {' ETH'}
                </Label>
              </View>
            )
          }}
        </Cell>
      </Column>
      <Column>
        <HeaderCell>From</HeaderCell>
        <Cell dataKey="from">{rowData => <Offerer data={rowData} />}</Cell>
      </Column>
      {/* <Column>
        <HeaderCell>Floor Difference</HeaderCell>
        <Cell dataKey="offerDate" />
      </Column> */}
      <Column>
        <HeaderCell>Offer date</HeaderCell>
        <Cell dataKey="expirationDate">
          {rowData => (
            <View>
              <Label color="black">
                {moment(rowData?.validFrom * 1000).fromNow()}
              </Label>
            </View>
          )}
        </Cell>
      </Column>
      <Column>
        <HeaderCell>Offer duration</HeaderCell>
        <Cell dataKey="expirationDate">
          {rowData => (
            <View>
              <Label color="black">{rowData?.offerDuration}</Label>
            </View>
          )}
        </Cell>
      </Column>
      <Column width="100px">
        <HeaderCell />
        <Cell>
          {(rowData, i) => {
            if (
              rowData?.maker?.toLowerCase() !== account.toLowerCase() &&
              !ownedDomain
            ) {
              return
            }
            return (
              <RoundedButton
                width="100%"
                height="50px"
                border="1px solid #eee"
                borderRadius="6px"
                background="#fff"
                hoverBackground="#f3f3fa"
                disabled={canceling === i || accepting === i}
                onClick={() => {
                  if (ownedDomain) {
                    acceptOffer(rowData, i)
                    return
                  }
                  cancelOffer(rowData, i)
                }}
              >
                {canceling === i || accepting === i ? (
                  <>
                    <>Pending</>
                    <LoadingSpinner margin="0 0 0 20px" />
                  </>
                ) : ownedDomain ? (
                  'Accept'
                ) : (
                  'Cancel'
                )}
              </RoundedButton>
            )
          }}
        </Cell>
      </Column>
    </Table>
  ) : (
    <NoItems label="No offers" />
  )
}

export default Offers
