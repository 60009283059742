import React from 'react'
import View from 'components/View'
import SocialLinkIcon from './SocialLinkIcon'
import SettingsInput from './SettingsInput'

const SocialLinkInput = ({ placeholder, value, onChange, icon }) => {
  return (
    <View
      border="1px solid #E1E1E8"
      borderRadius="10px"
      alignItems="center"
      width="100%"
    >
      <SocialLinkIcon top="0px" left="-1px" borderColor="#E1E1E8">
        {icon}
      </SocialLinkIcon>
      <SettingsInput
        border="none"
        padding="22px 22px 22px 80px"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </View>
  )
}

export default SocialLinkInput
