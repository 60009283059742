import styled from '@emotion/styled/macro'
import { forwardRef, useEffect, useState } from 'react'
import View from '../View'
import ToolBar from './Toolbar'

import { ReactComponent as FiltersIcon } from 'assets/filters.svg'

const StickyToolbar = forwardRef(
  (
    {
      page,
      filtersOpened,
      onOpenCategoriesModal,
      modalButtonTitle,
      darkMode,
      onLengthChange,
      onPriceChange,
      statusType,
      onStatusChange,
      onSortByChange,
      onDateChange,
      onChangeType,
      onChangeResult,
      onResetFilter,
      subCategories = [],
      viewType,
      activeCurrency,
      setActiveCurrency = () => {},
      onViewTypeChange,
      setScrollAmount,
      domainCount,
      refreshData,
      filtersClosing,
      filtersOpening
    },
    pageRef
  ) => {
    const [scrollBarOffset, setScrollBarOffset] = useState(0)

    useEffect(() => {
      const onScroll = () => {
        const scrollbarTop = window.scrollY

        if (scrollbarTop > 294) return

        setScrollBarOffset(scrollbarTop)
      }

      window.addEventListener('scroll', onScroll)
      return () => {
        window.addEventListener('scroll', onScroll)
      }
    }, [])

    const shouldOpen = filtersOpened || filtersOpening

    return (
      <View
        height={'100%'}
        width={shouldOpen ? '390px' : '0px'}
        justify="center"
        padding={shouldOpen ? '0 70px 0 0' : '0'}
        transition="all 400ms ease"
        style={{
          position: 'sticky',
          top: 170
        }}
      >
        {/* <FiltersSpacerPlaceholder
          opened={filtersOpened}
          filtersClosing={filtersClosing}
        /> */}
        {/* <FiltersIconWrapper
          opened={filtersOpened}
          onClick={openFiltersHandler}
          darkMode={darkMode}
          filtersClosing={filtersClosing}
          top={'10px'}
        >
          <FiltersIcon />
        </FiltersIconWrapper> */}
        <ToolbarContainer
          opened={filtersOpened}
          opening={filtersOpening}
          darkMode={darkMode}
          filtersClosing={filtersClosing}
        >
          <ToolBar
            filtersOpened={filtersOpened}
            filtersOpening={filtersOpening}
            filtersClosing={filtersClosing}
            categoriesCount={subCategories.length || ''}
            onOpenCategoriesModal={onOpenCategoriesModal}
            modalButtonTitle={modalButtonTitle}
            darkMode={darkMode}
            onLengthChange={onLengthChange}
            onPriceChange={onPriceChange}
            statusType={statusType}
            onStatusChange={onStatusChange}
            onSortByChange={onSortByChange}
            onDateChange={onDateChange}
            onChangeType={onChangeType}
            onChangeResult={onChangeResult}
            onResetFilter={onResetFilter}
            page={page}
            activeCurrency={activeCurrency}
            setActiveCurrency={setActiveCurrency}
            setScrollAmount={setScrollAmount}
            domainCount={domainCount}
            refreshData={refreshData}
            scrollbarOffset={scrollBarOffset}
          />
        </ToolbarContainer>
      </View>
    )
  }
)

const ToolbarContainer = styled.div`
  padding-top: ${props => props.paddingTop || '10px'};
  pointer-events: ${props => (props.opened ? 'all' : 'none')};
  width: 100%;
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.opened ? '1' : '0')};
  background: ${props =>
    props.opened && props.darkMode
      ? 'black'
      : props.opened && !props.darkMode
      ? 'white'
      : 'transparent'};
  will-change: transform;
  transition: all 0.4s ease;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  @keyframes slidein {
    0% {
      transform: translateX(-370px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(-370px);
      opacity: 0;
    }
  }
`

const FiltersIconWrapper = styled.button`
  position: absolute;
  top: ${props => props.top || '10px'};
  left: ${props => (props.opened ? '-700px' : '-380px')};
  background-color: ${props => (props.darkMode ? '#262628' : '#020202')};
  padding: 20px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: transform 0.4s ease;
  line-height: 1;
  cursor: pointer;
  transform: ${props =>
    props.opened ? 'translateX(-70px)' : 'translateX(310px)'};
  will-change: transform;
  z-index: 100000;
`

const FiltersSpacerPlaceholder = styled.div`
  width: ${props => (props.opened ? '310px' : '0')};
  transition: width 0.4s ease;
`

export default StickyToolbar
