import { useLazyQuery } from '@apollo/client'
import { useAccount } from 'components/QueryAccount'
import { createContext, useEffect, useState } from 'react'
import {
  GET_CART_DOMAINS_DATA,
  kodexApiContext
} from 'service/graphql/kodex-api'
import { JETTY_URL } from 'service/siwe/constants'
import { siweAuthedFetch } from 'service/siwe/siweFetch'

export const LIMIT = 20

const initialState = {
  domains: [],
  allDomains: [],
  checkoutType: 'purchase'
}

export const CartStoreContext = createContext({
  state: initialState,
  addNewDomain: (addedDomain, basket) => {},
  removeDomain: (name, basket) => {},
  removeMultipleDomains: (names, basket) => {},
  removeAll: () => {},
  changeCheckoutType: type => {}
})

const CartStoreProvider = ({ children }) => {
  const [domains, setDomains] = useState([])
  const [checkoutType, setCheckoutType] = useState('purchase')
  const account = useAccount()

  const [getCartDomainsData, { data: cartDomainsData }] = useLazyQuery(
    GET_CART_DOMAINS_DATA,
    {
      variables: {},
      ...kodexApiContext
    }
  )

  useEffect(() => {
    setDomains(cartDomainsData?.domains || [])
  }, [cartDomainsData])

  const jettyFetchStoredDomains = async () => {
    try {
      const req = siweAuthedFetch(`${JETTY_URL}/user/cart/list`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })

      const res = await req?.then(res => res.json()).then(data => data)

      if (!res) return

      const resDomainNames = res?.map(d => d.id)

      getCartDomainsData({
        variables: {
          domains: resDomainNames
        }
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  const jettyAddNewDomain = async (domain, basket) => {
    try {
      const req = siweAuthedFetch(`${JETTY_URL}/user/cart/modify`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: domain, basket: basket })
      })

      const res = await req.then(res => res)
    } catch (e) {
      throw new Error(e)
    }
  }

  const jettyRemoveDomain = async (name, basket) => {
    try {
      const req = siweAuthedFetch(`${JETTY_URL}/user/cart/modify`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: name, basket: basket })
      })

      const res = await req.then(res => res)
    } catch (e) {
      throw new Error(e)
    }
  }

  const jettyRemoveAllDomains = async () => {
    try {
      const req = siweAuthedFetch(`${JETTY_URL}/user/cart/clear`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })

      const res = await req.then(res => res)
    } catch (e) {
      throw new Error(e)
    }
  }

  // Do NOT remove that, saved ofr later due to some problems
  useEffect(() => {
    if (!account) return
    jettyFetchStoredDomains()
  }, [account])

  const addNewDomain = (addedDomain, basket) => {
    const domainExists = domains.findIndex(
      domain => domain.name === addedDomain.name
    )
    if (domainExists === -1) {
      setDomains(domains => [...domains, addedDomain])
      jettyAddNewDomain(addedDomain.name, basket)
    }
  }

  const removeDomain = (name, basket) => {
    const filteredDomains = domains.filter(domain => domain.name !== name)
    setDomains(filteredDomains)
    jettyRemoveDomain(name, basket)
  }

  const removeMultipleDomains = (names = [], basket) => {
    const filteredDomains = domains.filter(
      domain => !names.includes(domain.name)
    )
    setDomains(filteredDomains)
    names.forEach(async name => {
      jettyRemoveDomain(name, basket)
    })
  }

  const removeAll = () => {
    setDomains([])
    jettyRemoveAllDomains()
  }

  const changeCheckoutType = type => {
    setCheckoutType(type)
  }

  return (
    <CartStoreContext.Provider
      value={{
        state: { domains, checkoutType },
        addNewDomain,
        removeDomain,
        removeMultipleDomains,
        removeAll,
        changeCheckoutType
      }}
    >
      {children}
    </CartStoreContext.Provider>
  )
}

export default CartStoreProvider
