export const RESULT_COLUMNS_BREAKPOINTS = {
  3090: 8,
  2750: 7,
  2410: 6,
  2070: 5,
  1730: 4,
  1420: 3,
  1050: 2,
  0: 1
}

export const DOMAIN_TABLE_COLUMNS_BREAKPOINTS = {
  3400: 9,
  2900: 8,
  2500: 7,
  2050: 6,
  1650: 5,
  1350: 5,
  950: 5,
  800: 2,
  0: 1
}
