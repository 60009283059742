import moment from 'moment'

export default function(timestamp) {
  if (timestamp == null) {
    return ''
  }
  if (timestamp.durationLabel === 'D') {
    return `${moment(timestamp.from).format('D MMMM, hh:mm')} - ${moment(
      timestamp.to
    ).format('hh:mm a')}`
  } else if (timestamp.durationLabel === 'W') {
    return moment(timestamp.to).format('D MMMM, dddd')
  } else if (timestamp.durationLabel === 'M') {
    return `${moment(timestamp.from).format('D')} - ${moment(
      timestamp.to
    ).format('D MMMM')}`
  } else {
    return `${moment(timestamp.from).format('D MMM')} - ${moment(
      timestamp.to
    ).format('D MMM YYYY')}`
  }
}
