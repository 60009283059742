import React, { useEffect, useState } from 'react'
import { useMediaMax } from '../../../mediaQuery'
import Label from 'components/Label'

const FooterLink = ({ text, color }) => {
  const smallBP = useMediaMax('small')
  const [labelSize, setLabelSize] = useState(30)

  useEffect(() => {
    if (smallBP) {
      setLabelSize(16)
    } else {
      setLabelSize(30)
    }
  })
  return (
    <Label size={labelSize} color={color}>
      {text}
    </Label>
  )
}

export default FooterLink
