import styled from '@emotion/styled/macro'
import DeafultAvatarImg from 'assets/icon-pfp-default.png'
import Avatar from 'components/Avatar'
import Label from 'components/Label'
import LoadingSpinner from 'components/LoadingSpinner'
import Space from 'components/Space'
import View from 'components/View'
import { TAGS } from 'constants/category'
import useUserProfile from 'hooks/useUserProfile'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useInViewport } from 'react-in-viewport'
import { useHistory } from 'react-router'
import useDomainsData from 'views/Profile/useDomainsData'

const TrendingUserCard = ({
  name,
  favCategory,
  followers,
  avatar,
  headerBg,
  headerColor,
  contentBorder,
  onClick,
  isActive,
  address,
  width,
  windowWidth,
  likes,
  offers,
  provider
}) => {
  const [alreadyAnimated, setAlreadyAnimated] = useState(false)
  const [ENSName, setENSName] = useState(undefined)

  const domains = useDomainsData(address)

  const history = useHistory()
  const { profile: ownerData } = useUserProfile(address)

  const cardRef = useRef(null)

  const { inViewport } = useInViewport(cardRef)

  useEffect(() => {
    if (alreadyAnimated || !inViewport) return
    setTimeout(() => setAlreadyAnimated(true), 400)
  }, [inViewport])

  const handleGoSubCategory = useCallback(
    e => {
      e.stopPropagation()

      const category = favCategory
      if (category) {
        const categoryKey = category
          .toLowerCase()
          .split(' ')
          .join('-')

        TAGS.forEach(tag => {
          if (tag.key.toLowerCase() === categoryKey.toLowerCase()) {
            history.push(`/marketplace?category=${tag.key.toLowerCase()}`)
          } else if (tag.subcategories.length > 0) {
            tag.subcategories.forEach(subCategory => {
              if (subCategory.key.toLowerCase() === categoryKey.toLowerCase()) {
                history.push(`/marketplace?category=${tag.key.toLowerCase()}`)
              }
            })
          }
        })
      }
    },
    [favCategory]
  )

  const lookupENSName = async () => {
    const lookup = await provider.lookupAddress(address).catch(() => null)

    return lookup
  }

  useEffect(() => {
    const fetchENSName = async () => {
      const resolvedName = await lookupENSName()
      setENSName(resolvedName)
    }

    fetchENSName()
  }, [])

  return (
    <StyledCard
      width={width}
      height={'25vw'}
      maxHeight={'430px'}
      onClick={onClick}
      background={headerBg}
      ref={cardRef}
      style={{
        animation: !alreadyAnimated && inViewport ? 'fadeIn 0.2s ease-out' : ''
      }}
      opacity={inViewport || alreadyAnimated ? 1 : 0}
    >
      {ownerData ? (
        <View width="100%" height="100%" direction="column">
          <View
            height="13.5%"
            width="100%"
            padding="0px 20px"
            alignItems="center"
          >
            {favCategory && (
              <Label
                size={windowWidth < 1300 ? 14 : 16}
                color={headerColor || '#fff'}
                hoverColor={`${headerColor}99` || '#fff'}
                cursor="pointer"
                onClick={handleGoSubCategory}
              >
                {favCategory} lover
              </Label>
            )}
          </View>
          <StyledCardContent
            border={contentBorder}
            onClick={() => history.push(`/profile/${address?.slice(2)}`)}
          >
            <ContentContainer>
              <Avatar
                width={windowWidth < 1300 ? '80' : '100'}
                src={avatar || DeafultAvatarImg}
                borderRadius="6px"
                background="#F3F3FA"
              />
              <Space size={20} />
              <View
                width={windowWidth < 1350 ? '90%' : '85%'}
                margin="0 auto"
                alignItems="center"
                justify="center"
              >
                <Label
                  color="#000"
                  hoverColor="#0008"
                  cursor="pointer"
                  size={windowWidth < 1350 ? 20 : 24}
                  onClick={() => history.push(`/profile/${address?.slice(2)}`)}
                >
                  {name ||
                    ENSName ||
                    ownerData?.userInfo?.username ||
                    address?.slice(2, 7)}
                </Label>
              </View>
            </ContentContainer>
            <SubtitleContainer
              direction={windowWidth < 1400 ? 'column' : 'row'}
              justify={windowWidth < 1400 ? 'center' : 'space-between'}
              gap="0.3rem"
            >
              <Label size={16} color="#CAD0D2">
                <Label color="#020202">{domains?.length} </Label>
                Domains
              </Label>
              <Label size={16} color="#CAD0D2">
                <Label color="#020202">{ownerData.followers} </Label>
                Followers
              </Label>
            </SubtitleContainer>
          </StyledCardContent>
        </View>
      ) : (
        <LoadingSpinner />
      )}
    </StyledCard>
  )
}

const StyledCard = styled.div`
  width: ${props => props.width || '310px'};
  height: ${props => props.height || '430px'};
  max-height: ${props => props.maxHeight};
  // overflow: hidden;
  border-radius: 10px;
  background-color: ${props =>
    props.background ? props.background : '#212121'};
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  flex-direction: column;
  overflow: visible;
  border-radius: 10px;
  border-radius-bottom-left: 15px;
  border-radius-bottom-right: 15px;
  height: 80%;
`

const StyledCardContent = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 20% 0 0 0;
  width: 100%;
  height: 82.5%;
  background-color: #fff;
  flex: 1;
  border-radius: 10px;
  border: ${props => props.border};
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transform: ${props =>
    props.isActive ? 'translateY(-60px)' : 'translateY(0)'};
`

const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  gap: ${props => props.gap || '0'};
  padding: 14% 12.5%;
  justify-content: ${props => props.justify};
  align-items: center;
  width: 100%;
  opacity: ${props => (props.isActive ? '0' : '1')};
  height: ${props => (props.isActive ? '0%' : '100%')}
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
`

export default TrendingUserCard
