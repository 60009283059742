import { useLazyQuery } from '@apollo/client'
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg'
import { ReactComponent as FiltersIcon } from 'assets/filters-mobile-dark.svg'
import TrendingImage from 'assets/marketplace-trending.png'
import Avatar from 'components/Avatar'
import HeaderMobileCard from 'components/Card/MobileMarketplaceCards/HeaderMobileCard'
import CategoryFilterModal from 'components/CategoryFilterModal'
import { OfferDialog } from 'components/Dialogs'
import RoundedButton from 'components/Forms/RoundedButton'
import HeaderBar from 'components/HeaderBar'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import ResultList from 'components/ResultList'
import ScrollUpButton from 'components/ScrollUpButton'
import KodexSidebar from 'components/Sidebar'
import Space from 'components/Space'
import StickyToolbar from 'components/StickyToolBar'
import View from 'components/View'
import { defaultPfps } from 'constants/defaultPfps'
import { FilterContext } from 'contexts/FilterProvider'
import { useImagePreloader } from 'contexts/ImagePreloaderProvider'
import {
  TopBarStyleContext,
  topBarMarketplaceStyle
} from 'contexts/TopBarStyleProvider'
import useUserProfile from 'hooks/useUserProfile'
import queryString from 'query-string'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  GET_SEARCH_DOMAINS_COUNT,
  kodexApiContext
} from 'service/graphql/kodex-api'
import { getMarketplaceDefaultDomains } from 'service/rest/getMarketplaceDomains'
import { getTrendingCategories, getTrendingUsers } from 'service/rest/trending'
import InViewAnimation from 'utils/inViewAnimation'
import InViewCard from 'utils/inViewCard'
import { Footer } from '../Home/components'
import useGetFeedEvents from 'hooks/useGetFeedEvents/useGetFeedEvents'
import { DURATION_ITEMS } from 'constants/feed-chart-duration'
import LoadingSpinner from 'components/LoadingSpinner'

let isLoading = true
let hasStatusFilterChanged = false

const manualTrendingUsersAddresses = [
  '0x0C8bdC6A21A2b98F4f7C6958BE1b128Df1aa3c5B',
  '0xae3C9636DE4005CB1a0c3aA94e53152A60330388',
  '0x7B3431DB5a984C7a7a710175F6119a385070E9ec',
  '0x7fbe318294C86F79bE117dB0F82CD9fF570FB909',
  '0x9746F004a75b79f518271e94170bcb13A2c2a559'
]

const Marketplace = () => {
  const {
    filter,
    setFilter,
    activeCurrency,
    setActiveCurrency,
    opened: filtersOpened,
    toggleOpen,
    setDarkMode,
    setViewType,
    viewType,
    savedDomainPosition,
    setSavedDomainPosition
  } = useContext(FilterContext)

  const [categoriesModalOpen, setCategoriesModalOpen] = useState(false)
  // const [activeCurrency, setActiveCurrency] = useState('ETH')
  const [allDomainsFetched, setAllDomainsFetched] = useState(false)
  const [offerDialogOpen, setOfferDialogOpen] = useState(false)
  const [offeredDomainName, setOfferedDomainName] = useState('')
  const [offeredDomainlastPrice, setOfferedDomainLastPrice] = useState('')
  const [domains, setDomains] = useState([])
  const [initialDomainLoad, setInitialDomainLoad] = useState(true)
  const [trendingCategories, setTrendingCategories] = useState([])
  const [trendingUsers, setTrendingUsers] = useState([])
  const [filtersClosing, setFiltersClosing] = useState(false)
  const [filtersOpening, setFiltersOpening] = useState(false)

  const location = useLocation()

  const history = useHistory()

  const pageRef = useRef(null)

  const viewTypeValue = viewType.marketplace.view
  const TopBarCtx = useContext(TopBarStyleContext)

  const scrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: 450,
      behavior: 'smooth'
    })
  }

  const fetchDomains = async () => {
    const fetchedDomains = await getMarketplaceDefaultDomains({
      ...filter,
      offset: savedDomainPosition.marketplace.offset
    })

    setDomains(fetchedDomains.domains)

    if (initialDomainLoad) {
      setInitialDomainLoad(false)
    }
    setTimeout(() => (isLoading = false), 2000)
  }

  useEffect(() => {
    isLoading = true
    fetchDomains()
  }, [filter])

  const loadMoreDomains = async () => {
    if (!domains || domains?.length % 20 > 0) return

    isLoading = true

    if (filter?.search?.length > 0) {
      const fetchedDomains = await getMarketplaceDefaultDomains({
        ...filter,
        offset: domains.length
      })

      const newDomainsArr = [...domains, ...fetchedDomains.domains]

      setDomains(newDomainsArr)
      setTimeout(() => (isLoading = false), 2000)
      return
    }

    const fetchedDomains = await getMarketplaceDefaultDomains({
      ...filter,
      offset: domains.length
    })

    const newDomainsArr = [...domains, ...fetchedDomains.domains]

    setDomains(newDomainsArr)
    setTimeout(() => (isLoading = false), 2000)
  }

  const refetchDomains = async () => {
    isLoading = true
    scrollToTop()
    fetchDomains()
  }

  const { canStartAnimatePage, imageLoading } = useImagePreloader()

  const [fetchDomainsCount, { data: numOfDomains }] = useLazyQuery(
    GET_SEARCH_DOMAINS_COUNT,
    {
      variables: {},
      ...kodexApiContext
    }
  )

  const domainCount = useMemo(() => {
    return numOfDomains?.domains?.aggregate.count
  }, [numOfDomains])

  // TODO: add new filters here
  const getDomainsCount = useCallback(() => {
    const {
      limit = 50,
      sort,
      search,
      minLength,
      maxLength,
      minPrice,
      maxPrice,
      subCategories,
      status,
      date,
      result,
      type
    } = filter

    let variables = {
      limit,
      offset: 0,
      name_pattern_search: search,
      min_domain_length: minLength,
      max_domain_length: maxLength,
      min_listing_price: minPrice,
      max_listing_price: maxPrice,
      search_type: 'marketplace',
      has_offers_selector:
        status && status.length > 0 && typeof status === 'object'
          ? `{${status.join(',')}}`
          : undefined,
      order_type: sort || 'default',
      date_status: date,
      name_result: result,
      name_symbols_type:
        type && type.length > 0 ? `{${type.join(',')}}` : undefined,
      terms:
        subCategories && subCategories.length > 0
          ? `{${subCategories.join(',')}}`
          : undefined
    }

    fetchDomainsCount({
      variables: { ...variables, limit: undefined }
    })
  })

  useEffect(() => {
    getDomainsCount()
  }, [filter])

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant'
    })

    document.title = 'Marketplace | Kodex'
  }, [])

  useEffect(() => {
    if (domains.length >= domainCount) setAllDomainsFetched(true)
  }, [domains])

  const handleOpenModal = useCallback(() => {
    setCategoriesModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setCategoriesModalOpen(false)
  }, [])

  useEffect(() => {
    let animation
    if (canStartAnimatePage) {
      animation = new InViewAnimation()
    }
    if (animation != null) {
      return () => {
        animation.destroy()
      }
    }
  }, [canStartAnimatePage])

  useEffect(() => {
    if (domains == null) {
      return
    }
    const cards = new InViewCard()

    return () => cards.destroy()
  }, [domains])

  const handleOpenFilters = useCallback(() => {
    toggleOpen()
  }, [])

  const handleHideFilters = useCallback(() => {
    toggleOpen()
  }, [])

  const handleViewChange = type => {
    setDomains(domains.slice(0, 20))

    setViewType({
      ...viewType,
      marketplace: { view: type }
    })
    scrollToTop()
  }

  const handleLengthChange = params => {
    setFilter(filter => ({
      ...filter,
      minLength: params[0],
      maxLength: params[1] === 10 ? null : params[1]
    }))
    scrollToTop()
  }

  const handlePriceChange = prices => {
    setFilter(filter => ({
      ...filter,
      minPrice: prices[0] > 0 ? Number(prices[0]) : null,
      maxPrice: prices[1] > 0 ? Number(prices[1]) : null
    }))
    scrollToTop()
  }

  const handleStatusChange = status => {
    if (!hasStatusFilterChanged) hasStatusFilterChanged = true
    setFilter(filter => ({
      ...filter,
      status
    }))
    scrollToTop()
  }

  const handleSortByChange = sort => {
    setFilter(filter => ({
      ...filter,
      sort
    }))
    scrollToTop()
  }

  const handleDateChange = date => {
    setFilter(filter => ({
      ...filter,
      date
    }))
    scrollToTop()
  }

  const handleChangeCategories = subCategories => {
    setFilter(filter => ({
      ...filter,
      subCategories
    }))
    scrollToTop()
  }

  const handleChangeResult = result => {
    setFilter(filter => ({
      ...filter,
      result
    }))
    scrollToTop()
  }

  const handleChangeType = type => {
    setFilter(filter => ({
      ...filter,
      type: type.length > 0 ? type : undefined
    }))
    scrollToTop()
  }

  const handleChangeSearch = name => {
    setDomains([])
    setFilter(filter => ({
      ...filter,
      search: name || undefined,
      status: hasStatusFilterChanged
        ? filter.status
        : name.length === 0 && !hasStatusFilterChanged
        ? ['buy_now']
        : undefined
    }))
    scrollToTop()
  }

  useEffect(() => {
    let categoryQuery = undefined
    if (location.search) {
      const parsed = queryString.parse(location.search)
      const searchQuery = parsed?.search
      const categoryQuery = parsed?.category
      setFilter(filter => ({
        ...filter,
        sort: 'default',
        search: searchQuery?.replace('.eth', '') || undefined,
        subCategories: categoryQuery ? [categoryQuery] : undefined
      }))
    } else {
      if (!filter.search) return
      setFilter(filter => ({
        ...filter,
        sort: 'default',
        status: ['buy_now'],
        search: undefined,
        subCategories: undefined
      }))
    }
  }, [location.search])

  useEffect(() => {
    if (location.search && imageLoading === 0) {
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: window.innerWidth < 740 ? 250 : 450,
          behavior: 'smooth'
        })
      }, 1000)
    }
  }, [location.search, imageLoading])

  const handleResetFilter = () => {
    setFilter({ limit: 20, status: ['buy_now'], sort: 'default' })
    setSavedDomainPosition({
      ...savedDomainPosition,
      marketplace: { domains: [], clickedDomain: '', offset: 0 }
    })
    scrollToTop()
  }

  useEffect(() => {
    fetchDomains()
  }, [
    savedDomainPosition.marketplace.offset,
    savedDomainPosition.marketplace.clickedDomain
  ])

  const handleOpenOfferDialog = (name, lastPrice) => {
    setOfferedDomainName(name)
    setOfferedDomainLastPrice(lastPrice)
    setOfferDialogOpen(true)
  }

  const refreshData = () => {
    getDomainsCount()
    refetchDomains(filter)
  }

  const handleOrderByChange = value => {
    setFilter({
      ...filter,
      rawOrderType: value
    })
  }

  useEffect(() => {
    TopBarCtx.setStyle(topBarMarketplaceStyle)
    var filtersObj
    if (Object.keys(filter).length === 0) {
      filtersObj = {
        limit: 50,
        sort: 'default',
        status: ['buy_now'],
        search: undefined
      }
    } else {
      filtersObj = filter
    }
    let categoryQuery = undefined
    if (location.search) {
      const parsed = queryString.parse(location.search)
      categoryQuery = parsed?.category
      if (parsed.search) {
        filtersObj = Object.assign(filtersObj, {
          limit: 50,
          sort: 'default',
          search: parsed.search.replace('.eth', ''),
          subCategories: categoryQuery ? [categoryQuery] : undefined
        })
      } else if (categoryQuery) {
        filtersObj = {
          limit: 50,
          sort: 'default',
          status: ['buy_now'],
          search: undefined,
          subCategories: categoryQuery ? [categoryQuery] : undefined
        }
      }
    }
    setFilter(filtersObj)
    // setActiveCurrency('ETH')
    setDarkMode(false)
  }, [])

  const fetchTrendingCategories = async () => {
    const trendingData = await getTrendingCategories()

    setTrendingCategories(trendingData)
  }

  const fetchTrendingUsers = async () => {
    const trendingData = await getTrendingUsers({
      duration: 'WEEK'
    })

    setTrendingUsers(trendingData.users)
  }

  useEffect(() => {
    fetchTrendingCategories()
    // fetchTrendingUsers()
  }, [])

  useEffect(() => {
    const newSavedPositions = Object.assign(savedDomainPosition, {
      marketplace: { ...savedDomainPosition.marketplace, domains }
    })
    setSavedDomainPosition(newSavedPositions)
  }, [domains])

  const manualTrendingUsers = manualTrendingUsersAddresses.map(address => {
    const { profile } = useUserProfile(address)
    return profile?.userInfo || {}
  })

  const hideFiltersHandler = () => {
    if (filtersOpening || filtersClosing) return
    setFiltersClosing(true)
    handleOpenFilters()
    setTimeout(() => {
      setFiltersClosing(false)
    }, 400)
  }

  const openFiltersHandler = () => {
    if (filtersOpening || filtersClosing) return
    setFiltersOpening(true)
    handleOpenFilters()
    setTimeout(() => {
      setFiltersOpening(false)
    }, 400)
  }

  const {
    chart: { chartData, fetchChartData }
  } = useGetFeedEvents(DURATION_ITEMS[3])

  useEffect(() => {
    fetchChartData({
      offset: 0,
      min_domain_length: 7,
      max_domain_length: null,
      min_price: null,
      max_price: null,
      time_range: DURATION_ITEMS[3].labelRaw,
      event_types: null,
      asset: null,
      search_terms: null
    })
  }, [])

  const volumePrice = useMemo(() => {
    if (chartData.length === 0) {
      return
    }
    return chartData.reduce(
      (partialSum, event) => partialSum + Number(event.volumePrice),
      0
    )
  }, [chartData])

  const numSales = useMemo(() => {
    if (chartData.length === 0) {
      return
    }
    return chartData.reduce(
      (partialSum, event) => partialSum + Number(event.count),
      0
    )
  }, [chartData])

  const averagePrice = useMemo(() => {
    if (chartData.length === 0) {
      return
    }
    return volumePrice / numSales
  }, [volumePrice, numSales])

  const totalOwners = useMemo(() => {
    if (trendingCategories.length > 0) return 0
    return chartData.reduce(
      (ownersSum, cat) => ownersSum + Number(cat.owners),
      0
    )
  })

  return (
    <>
      <KodexSidebar
        positionDialogCenter={true}
        opened={offerDialogOpen}
        onClose={() => {
          setOfferDialogOpen(false)
        }}
      >
        <OfferDialog
          onClose={() => setOfferDialogOpen(false)}
          domain={{
            name: offeredDomainName,
            lastPrice: offeredDomainlastPrice
          }}
          backLabel="marketplace"
        />
      </KodexSidebar>
      <View direction="column" width="100%">
        <Container
          ref={pageRef}
          maxWidth={window.innerWidth < 740 ? '100%' : 'calc(100% - 140px)'}
          background="#fff"
        >
          {window.innerWidth < 740 ? (
            <>
              <Space size={104} />
              <View width="100%" padding="0 16px 24px 16px">
                <Label
                  size={40}
                  color="#020202"
                  data-in="stagger-chars"
                  // style={{ opacity: 0 }}
                >
                  Browse ENS like a Pro
                </Label>
              </View>
              <View width="100%" padding="0 16px" direction="column" gap="16px">
                <HeaderMobileCard
                  key={'domains'}
                  label="Trending domains"
                  endpoint="domains"
                />
                <HeaderMobileCard
                  key={'users'}
                  label="Top users"
                  endpoint="users"
                />
              </View>
            </>
          ) : (
            <>
              <Space size={160} />
              <View gap="10px">
                <View
                  width="72.5%"
                  height="170px"
                  background="#0698A8"
                  borderRadius="10px"
                  transition="height 0.3s ease"
                  overflow="hidden"
                  padding="20px 30px 30px 30px"
                  justify="space-between"
                  direction="column"
                >
                  <View
                    width="100%"
                    justify="space-between"
                    alignItems="center"
                    zIndex={10}
                  >
                    <Label weight="500">Trending</Label>
                    <RoundedButton
                      height="50px"
                      width="50px"
                      borderRadius="6px"
                      background="#fff6"
                      hoverBackground="#fff4"
                      onClick={() => {
                        history.push('/trending?categories')
                      }}
                    >
                      <ArrowRight height={10} width={10} />
                    </RoundedButton>
                  </View>
                  <View gap="10px" zIndex={10}>
                    {trendingCategories?.length > 0 ? (
                      trendingCategories?.slice(0, 3).map(c => (
                        <View
                          key={c.taxonomy}
                          background="#2BA8B5aa"
                          hoverBackground="#2BA8B5dd"
                          cursor="pointer"
                          transition="0.2s ease-out"
                          padding="17px 20px"
                          borderRadius="6px"
                          onClick={() => {
                            history.push(`/feed?category=${c.taxonomy}`)
                          }}
                        >
                          <Label>{c.taxonomy}</Label>
                        </View>
                      ))
                    ) : (
                      <>
                        <View
                          key={1}
                          background="linear-gradient(to right, rgba(43, 168, 181,0.8), rgba(43, 168, 181,0.6), rgba(43, 168, 181,0.8), rgba(43, 168, 181,0.6), rgba(43, 168, 181,0.8))"
                          padding="25px 40px"
                          animation="loading 1s infinite"
                          borderRadius="6px"
                        />
                        <View
                          key={2}
                          background="linear-gradient(to right, rgba(43, 168, 181,0.8), rgba(43, 168, 181,0.6), rgba(43, 168, 181,0.8), rgba(43, 168, 181,0.6), rgba(43, 168, 181,0.8))"
                          padding="25px 40px"
                          animation="loading 1s infinite"
                          borderRadius="6px"
                        />
                        <View
                          key={3}
                          background="linear-gradient(to right, rgba(43, 168, 181,0.8), rgba(43, 168, 181,0.6), rgba(43, 168, 181,0.8), rgba(43, 168, 181,0.6), rgba(43, 168, 181,0.8))"
                          padding="25px 40px"
                          borderRadius="6px"
                          animation="loading 1s infinite"
                        />
                      </>
                    )}
                  </View>
                  <img
                    src={TrendingImage}
                    style={{
                      position: 'absolute',
                      top: '0px',
                      left:
                        window.innerWidth < 1350
                          ? -(1350 - window.innerWidth)
                          : window.innerWidth > 1500
                          ? window.innerWidth -
                            (Math.floor((window.innerWidth / 100) * 72.5) + 280)
                          : 0
                    }}
                  />
                </View>
                <View
                  width="27.5%"
                  height="170px"
                  background="#000"
                  borderRadius="10px"
                  transition="height 0.3s ease"
                  overflow="hidden"
                  padding="20px 30px"
                  justify="space-between"
                  direction="column"
                >
                  <View
                    width="100%"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Label weight="500">Top users</Label>
                    <RoundedButton
                      height="50px"
                      width="50px"
                      borderRadius="6px"
                      hoverBackground="#fff5"
                      background="#fff7"
                      onClick={() => {
                        history.push('/trending?users')
                      }}
                    >
                      <ArrowRight height={10} width={10} />
                    </RoundedButton>
                  </View>
                  <View
                    gap="10px"
                    width="100%"
                    zIndex={10}
                    justify="space-between"
                  >
                    {manualTrendingUsers.length > 0 ? (
                      manualTrendingUsers
                        .slice(
                          0,
                          5 +
                            Math.floor(
                              (((window.innerWidth - 1350) / 110) * 27.5) / 55
                            )
                        )
                        .map((u, i) => (
                          <View
                            key={i}
                            onClick={() => {
                              history.push(`/${u.user}`)
                            }}
                          >
                            <Avatar
                              width={50}
                              background={'#fff5'}
                              borderRadius={'6px'}
                              src={
                                u.picture ||
                                defaultPfps[Math.floor(Math.random() * 6)]
                              }
                            />
                          </View>
                        ))
                    ) : (
                      <>
                        <View
                          width="50px"
                          height="50px"
                          animation="loading 1s infinite"
                          background="linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2))"
                          borderRadius="6px"
                        />
                        <View
                          width="50px"
                          height="50px"
                          animation="loading 1s infinite"
                          background="linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2))"
                          borderRadius="6px"
                        />
                        <View
                          width="50px"
                          height="50px"
                          animation="loading 1s infinite"
                          background="linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2))"
                          borderRadius="6px"
                        />
                        <View
                          width="50px"
                          height="50px"
                          animation="loading 1s infinite"
                          background="linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2))"
                          borderRadius="6px"
                        />
                        <View
                          width="50px"
                          height="50px"
                          animation="loading 1s infinite"
                          background="linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2), rgba(255,255,255,0.25), rgba(255,255,255,0.2))"
                          borderRadius="6px"
                        />
                      </>
                    )}
                  </View>
                </View>
              </View>
              <Space size={10} />
              <View
                width="100%"
                height="120px"
                background="white"
                borderRadius="10px"
                transition="height 0.3s ease"
                overflow="hidden"
                padding="30px"
                justify="space-between"
                border="1px solid #CACADB"
              >
                {/* <View
                  flex={2}
                  onClick={() => {
                    history.push('/trending?domains')
                  }}
                >
                  <SimpleCard
                    imgUrl={TopOffers}
                    title="Trending"
                    height="420px"
                  />
                </View>
                <Space size={20} />
                <View
                  flex={1}
                  onClick={() => {
                    history.push('/trending?users')
                  }}
                >
                  <SimpleCard
                    imgUrl={TopAccounts}
                    title="Top users"
                    height="420px"
                  />
                </View> */}
                <View direction="column" gap="20px" alignItems="start">
                  <Label color="#BCBCCC">Total volume</Label>
                  <Label color="#000" weight="600">
                    {volumePrice || volumePrice === 0 ? (
                      volumePrice?.toLocaleString('default', {
                        maximumFractionDigits: volumePrice > 100 ? 2 : 3
                      }) + ' ETH'
                    ) : (
                      <View
                        width="100px"
                        height="20px"
                        animation="loading 1s infinite"
                        background="linear-gradient(to right, rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05))"
                        borderRadius="6px"
                      />
                    )}
                  </Label>
                </View>
                <View direction="column" gap="20px" alignItems="start">
                  <Label color="#BCBCCC">Average price</Label>
                  <Label color="#000" weight="600">
                    {averagePrice || averagePrice === 0 ? (
                      averagePrice?.toLocaleString('default', {
                        maximumFractionDigits: averagePrice > 100 ? 2 : 3
                      }) + ' ETH'
                    ) : (
                      <View
                        width="100px"
                        height="20px"
                        animation="loading 1s infinite"
                        background="linear-gradient(to right, rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05))"
                        borderRadius="6px"
                      />
                    )}
                  </Label>
                </View>
                <View direction="column" gap="20px" alignItems="start">
                  <Label color="#BCBCCC">Total listed</Label>
                  <Label color="#000" weight="600">
                    13%
                  </Label>
                </View>
                <View direction="column" gap="20px" alignItems="start">
                  <Label color="#BCBCCC">Owners</Label>
                  <Label color="#000" weight="600">
                    {totalOwners || totalOwners === 0 ? (
                      totalOwners + 381249
                    ) : (
                      <View
                        width="100px"
                        height="20px"
                        animation="loading 1s infinite"
                        background="linear-gradient(to right, rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05), rgba(2,2,2,0.04), rgba(2,2,2,0.05))"
                        borderRadius="6px"
                      />
                    )}
                  </Label>
                </View>
                <RoundedButton
                  color="black"
                  width="26%"
                  height="60px"
                  background="#E7E7EE"
                  onClick={() => {
                    history.push('/feed')
                  }}
                  hoverBackground="#E7E7EEbb"
                >
                  See more
                </RoundedButton>
              </View>
            </>
          )}
          <Space size={window.innerWidth < 740 ? 32 : 20} />
          {window.innerWidth > 740 && (
            <ScrollUpButton
              scrollToPosition={window.innerWidth < 740 ? 50 : 560}
            />
          )}
          <View
            width="100%"
            minHeight={window.innerWidth < 740 ? 'calc(100vh - 300px)' : '0'}
            direction="column"
            background={window.innerWidth < 740 ? '#EAEAF1' : 'transparent'}
            padding={window.innerWidth < 740 ? '0 16px' : '0'}
            position="relative"
          >
            {window.innerWidth < 740 ? (
              <View
                height="30px"
                width="100%"
                justify="space-between"
                alignItems="center"
                margin="30px 0 10px 0"
              >
                <View
                  alignItems="center"
                  gap="8px"
                  cursor="pointer"
                  onClick={handleOpenFilters}
                >
                  <FiltersIcon />
                  <Label size={16} color="#47596B">
                    Filters
                  </Label>
                </View>
                <View alignItems="center" gap="8px">
                  <Label size={16} color="#919CA6">
                    {domainCount} domains
                  </Label>
                </View>
              </View>
            ) : (
              <HeaderBar
                onOrderByChange={handleOrderByChange}
                renderedDomainCount={domains.length}
                from="marketplace"
                filtersOpened={filtersOpened}
                darkMode={false}
                domainCount={domainCount}
                refreshData={() => refreshData()}
                viewType={viewTypeValue}
                onViewTypeChange={handleViewChange}
                onSortByChange={handleSortByChange}
                onSearchChange={handleChangeSearch}
                searchDomain={filter?.search}
                selectedCategories={filter?.subCategories}
                changeSelectedCategories={handleChangeCategories}
                onHideFilters={hideFiltersHandler}
                onOpenFilters={openFiltersHandler}
              />
            )}
            <View flex={1} width="100%">
              <StickyToolbar
                ref={pageRef}
                filtersClosing={filtersClosing}
                filtersOpening={filtersOpening}
                filtersOpened={filtersOpened}
                onHideFilters={handleHideFilters}
                onOpenFilters={handleOpenFilters}
                subCategories={filter.subCategories}
                onOpenCategoriesModal={handleOpenModal}
                onLengthChange={handleLengthChange}
                onPriceChange={handlePriceChange}
                onStatusChange={handleStatusChange}
                onSortByChange={handleSortByChange}
                onDateChange={handleDateChange}
                onResetFilter={handleResetFilter}
                setActiveCurrency={setActiveCurrency}
                activeCurrency={activeCurrency}
                onViewTypeChange={handleViewChange}
                onChangeType={handleChangeType}
                onChangeResult={handleChangeResult}
                domainCount={domainCount}
                refreshData={() => refreshData()}
                viewType={viewTypeValue}
                modalButtonTitle="Categories"
                page="marketplace"
              />
              <ResultList
                domains={domains}
                filtersOpened={filtersOpened}
                fetchMore={() => {
                  loadMoreDomains()
                }}
                activeCurrency={activeCurrency}
                openOfferDialog={handleOpenOfferDialog}
                viewType={viewTypeValue}
                initialLoad={initialDomainLoad}
                loading={isLoading}
              />
            </View>
          </View>
        </Container>
      </View>
      {allDomainsFetched && (
        <>
          {window.innerWidth > 740 && <Space size={40} />}
          <Footer />
        </>
      )}
      {categoriesModalOpen && window.innerWidth > 740 && (
        <CategoryFilterModal
          subCategories={filter.subCategories}
          open={categoriesModalOpen}
          onClose={handleCloseModal}
          onApply={handleChangeCategories}
        />
      )}
    </>
  )
}

export default Marketplace
