import styled from '@emotion/styled/macro'
import { ReactComponent as CopyImg } from 'assets/copy-white.svg'
import DeafultAvatarImg from 'assets/icon-pfp-default.png'
import { ReactComponent as InstagramImg } from 'assets/instagram-white.svg'
import { ReactComponent as SettingsImg } from 'assets/settings-white.svg'
import { ReactComponent as Twitter } from 'assets/twitter-white.svg'
import { ReactComponent as WebUrlImg } from 'assets/web-url-white.svg'
import Avatar from 'components/Avatar'
import { FollowerBtn } from 'components/FollowerBtn'
import FollowerModal from 'components/FollowerModal'
import Link from 'components/Forms/Link'
import Label from 'components/Label'
import WalletAddress from 'components/Label/WalletAddress'
import { useAccount } from 'components/QueryAccount'
import Space from 'components/Space'
import View from 'components/View'
import { AccountContext } from 'contexts/AccountProvider'
import { isAddress } from 'ethers/lib/utils'
import useUserProfile from 'hooks/useUserProfile'
import moment from 'moment'
import 'moment-timezone'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { makeHttpLink } from 'utils/utils'
import Banner from './Banner'
import TrophyBadge from './TrophyBadge'

const Header = ({ address, setOpenSettings, primaryName }) => {
  const isOwnProfile = address.toLowerCase() === useAccount()?.toLowerCase()

  const {
    profile,
    followers,
    followings,
    refetchFollowers,
    refetchFollowings
  } = useUserProfile(
    address,
    !isOwnProfile
      ? {
          fetchPolicy: 'no-cache',
          nextFetchPolicy: 'no-cache'
        }
      : {
          fetchPolicy: 'cache-only'
        }
  )

  const { userData } = useContext(AccountContext)

  const {
    username,
    banner,
    picture,
    bio,
    twitter,
    instagram,
    weblink
  } = useMemo(() => {
    if (isOwnProfile) {
      return userData || {}
    }

    return profile.userInfo || {}
  }, [userData, profile])

  const [copied, setCopied] = useState(false)
  const [copiedAddress, setCopiedAddress] = useState(false)

  const [isHover, setHover] = useState(false)

  const handleMouseEnter = useCallback(() => {
    if (!isHover && username) {
      setHover(true)
    }
  }, [isHover])

  const handleMouseLeave = useCallback(() => {
    if (isHover) {
      setHover(false)
    }
  }, [isHover])

  const clipboardAddressLink = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
    navigator.clipboard
      .writeText(`${window.location.host}/profile/${address.replace('0x', '')}`)
      .then(() => {})
  }

  const clipboardAddress = () => {
    if (!isAddress(address)) return
    setCopiedAddress(true)
    setTimeout(() => {
      setCopiedAddress(false)
    }, 3000)
    navigator.clipboard.writeText(address).then(() => {})
  }

  const [followerModalOpen, setFollowerModalOpen] = useState(false)
  const followerModalProps = useRef({})

  const openFollowerModal = tab => {
    followerModalProps.current.tab = tab
    setFollowerModalOpen(true)
  }
  const closeFollowerModal = () => setFollowerModalOpen(false)

  const lastSeenTimestamp = profile?.userInfo?.last_seen || ''

  useEffect(() => {
    document.title = username ? `${username} @ Kodex` : 'Profile | Kodex'
  }, [username])

  return (
    <>
      <TopSection>
        <Banner img={banner} />
        <HoverMessage opacity={isHover ? 1 : 0}>Beta Tester</HoverMessage>
        <TrophyBadge
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ cursor: 'help' }}
        />

        <View alignItems="center" width="100%" margin="auto 0 40px">
          <View alignItems="stretch">
            <Avatar
              src={picture ? picture : DeafultAvatarImg}
              borderRadius="6px"
              width={80}
              background={'#E6EAF4'}
            />
            <Space size={20} />
            <View
              flex={1}
              direction="column"
              baclground="transparent"
              justify="flex-end"
            >
              <Label
                size={16}
                color="rgba(255, 255, 255, 0.6)"
                style={{ marginBottom: '5px' }}
              >
                Last seen:{' '}
                {lastSeenTimestamp ? (
                  <Label>{moment.tz(lastSeenTimestamp, 'UTC').fromNow()}</Label>
                ) : (
                  '-'
                )}
              </Label>
              <Label
                size={60}
                maxWidth="50vw"
                color="#fff"
                hoverColor="#fff8"
                cursor="pointer"
                onClick={clipboardAddress}
              >
                {username ? (
                  `${username}`
                ) : primaryName ? (
                  primaryName
                ) : (
                  <>
                    <WalletAddress lowercase format="reduced">
                      {address}
                    </WalletAddress>
                  </>
                )}
              </Label>
              {copiedAddress && <AddressTooltip>Address copied</AddressTooltip>}
            </View>
          </View>

          <View margin="0 0 0 auto" alignItems="center">
            {twitter && (
              <HeaderLink
                href={`https://twitter.com/${twitter}`}
                target="_blank"
              >
                <Twitter color="#fff" size="20" />
              </HeaderLink>
            )}
            {instagram && (
              <HeaderLink
                href={`https://instagram.com/${instagram}`}
                target="_blank"
              >
                <InstagramImg />
              </HeaderLink>
            )}
            {weblink && (
              <HeaderLink href={makeHttpLink(weblink)} target="_blank">
                <WebUrlImg />
              </HeaderLink>
            )}

            <HeaderLink data-tip={'Link copied'} onClick={clipboardAddressLink}>
              <CopyImg />
              {copied && <Tooltip>Link copied</Tooltip>}
            </HeaderLink>

            {isOwnProfile && (
              <>
                <Splitter size={10} />
                <HeaderLink onClick={() => setOpenSettings(true)}>
                  <SettingsImg />
                </HeaderLink>
              </>
            )}
            {!isOwnProfile && (
              <>
                <Space size={40} />
                <FollowerBtn
                  id={address}
                  refetchFollowers={refetchFollowers}
                  refetchFollowings={refetchFollowings}
                />
              </>
            )}
          </View>
        </View>
      </TopSection>

      <View width="50%" margin="40px 0 0">
        <Link onClick={() => openFollowerModal('Following')}>
          <Label size={16} color="#BCBCCC">
            Following:
          </Label>
          &nbsp;
          <Label size={16} color="#020202">
            {followings || 0}
          </Label>
        </Link>
        <Link
          onClick={() => openFollowerModal('Followers')}
          style={{ marginLeft: '40px' }}
        >
          <Label size={16} color="#BCBCCC">
            Followers:
          </Label>
          &nbsp;
          <Label size={16} color="#020202">
            {followers || 0}
          </Label>
        </Link>
      </View>

      {bio && (
        <View width="50%" margin="40px 0 0">
          <Label size={16} color="#BCBCCC">
            {bio}
          </Label>
        </View>
      )}

      {followerModalOpen && (
        <FollowerModal
          user={address}
          active={followerModalProps.current.tab}
          open={followerModalOpen}
          onClose={closeFollowerModal}
          refetchFollowers={refetchFollowers}
          refetchFollowings={refetchFollowings}
        />
      )}
    </>
  )
}

export default Header

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 450px;
  top: 0;
`

const HeaderLink = styled.a`
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0 5px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`

const Tooltip = styled.div`
  position: absolute;
  padding: 5px 10px;
  width: 92px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 13px;
  top: -25px;
  left: -15px;
`

const AddressTooltip = styled.div`
  position: absolute;
  padding: 5px 10px;
  width: 120px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 13px;
  top: -5px;
  left: 240px;
`

const Splitter = styled.div`
  height: 40px;
  border: 1px solid #fff;
  opacity: 0.2;
  margin: 0 5px;
`

const HoverMessage = styled.div`
  background-color: rgb(0 0 0 / 0.5);
  border-radius: 6px;
  color: #ffffff;
  margin-top: -1rem;
  margin-right: -1.5rem;
  position: absolute;
  padding: 6px 12px;
  opacity: ${props => props.opacity ?? 1};
  transition: opacity 100ms;
  top: 100%;
  right: 0;
  transform: translate(0, -100%);
  z-index: 1;
`
