import styled from '@emotion/styled/macro'
import DeafultAvatarImg from 'assets/icon-pfp-default.png'
import { ReactComponent as Twitter } from 'assets/twitter.svg'
import { FollowerBtn } from 'components/FollowerBtn'
import WalletAddress from 'components/Label/WalletAddress'
import Avatar from '../Avatar'
import Label from '../Label'
import Space from '../Space'
import View from '../View'
import { useHistory } from 'react-router'
import { useEffect, useRef, useState } from 'react'

const ClickableLabel = styled(Label)`
  cursor: pointer;
`

export default ({ item, close, refetchFollowings, refetchFollowers }) => {
  const history = useHistory()
  const {
    user: followerId,
    username,
    picture,
    bio,
    twitter,
    address: { address }
  } = item || {}

  const handleLinkToProfile = () => {
    history.push(`/profile/${address.replace('0x', '')}`)
    close(null, null)
  }

  const rowRef = useRef(null)

  const [isVisible, setIsVisible] = useState(false)

  const handleChangeVisibilit = entries => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  const observerOptions = {
    root: null,
    rootMargin: '0px 10px 0px 0px',
    threshold: 0.5
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      handleChangeVisibilit,
      observerOptions
    )

    if (isVisible) {
      observer.unobserve(rowRef.current)
      return
    }

    if (rowRef.current) observer.observe(rowRef.current)

    return () => {
      if (rowRef.current) observer.unobserve(rowRef.current)
    }
  }, [observerOptions, handleChangeVisibilit, rowRef, isVisible])

  return (
    <View
      ref={rowRef}
      direction="column"
      width="100%"
      padding="10px 0"
      opacity={isVisible ? 1 : 0}
      transform={isVisible ? 'translateY(0)' : 'translateY(10px)'}
      transition="all 0.4s ease-out"
    >
      <View alignItems="center" justify="space-between" width="100%">
        <View alignItems="center" gap="30px">
          <a onClick={handleLinkToProfile}>
            <Avatar
              src={picture ? picture : DeafultAvatarImg}
              borderRadius="6px"
              width={60}
              background={'transparent'}
            />
          </a>
          <ClickableLabel
            size={18}
            weight="500"
            width="auto"
            color="#020202"
            onClick={handleLinkToProfile}
            hoverColor="#666"
          >
            {username ? username : <WalletAddress>{followerId}</WalletAddress>}
          </ClickableLabel>
        </View>
        {!!twitter && (
          <a href={`https://twitter.com/${twitter}`} target="_blank">
            <Twitter />
          </a>
        )}

        {/* <div style={{ marginLeft: 'auto' }}>
          <FollowerBtn
            id={followerId}
            refetchFollowers={refetchFollowers}
            refetchFollowings={refetchFollowings}
          />
        </div> */}
      </View>

      {/* {bio && (
        <View width="75%" margin="20px 0 0">
          <Label
            size={16}
            color="#BCBCCC"
            lineHeight="160%"
            style={{ maxHeight: '3.4em' }}
          >
            {bio}
          </Label>
        </View>
      )} */}
    </View>
  )
}
