import { useQuery } from '@apollo/client'
import {
  GET_TAXONOMIES_COUNT,
  GET_TERMS_COUNT,
  kodexApiContext
} from 'service/graphql/kodex-api'

const useGetCategoryCount = (category, isSubcategory) => {
  const { data: categoryCount } = useQuery(GET_TAXONOMIES_COUNT, {
    variables: {
      taxonomy: category
    },
    skip: isSubcategory && !category,
    ...kodexApiContext
  })
  const { data: subCategoryCount } = useQuery(GET_TERMS_COUNT, {
    variables: {
      terms: category
    },
    skip: !isSubcategory && !category,
    ...kodexApiContext
  })

  return isSubcategory
    ? subCategoryCount?.domains_terms_aggregate.aggregate.count || 0
    : categoryCount?.domains_taxonomies_aggregate.aggregate.count || 0
}

export default useGetCategoryCount
