import { useLazyQuery } from '@apollo/client'
import Names from 'assets/marketplace-offers.png'
import Accounts from 'assets/subcategories/accounts.png'
import Animals from 'assets/subcategories/animals.png'
import Art from 'assets/subcategories/art.png'
import Characters from 'assets/subcategories/characters.png'
import Company from 'assets/subcategories/company.png'
import Creatures from 'assets/subcategories/creatures.png'
import Crypto from 'assets/subcategories/crypto.png'
import Dictionary from 'assets/subcategories/dictionary.png'
import English from 'assets/subcategories/english.png'
import KClub from 'assets/subcategories/k-club.png'
import Misc from 'assets/subcategories/misc.png'
import People from 'assets/subcategories/people.png'
import Places from 'assets/subcategories/places.png'
import Web from 'assets/subcategories/web.png'
import SimpleCard from 'components/Card/SimpleCard'
import CategoryFilterModal from 'components/CategoryFilterModal'
import { OfferDialog } from 'components/Dialogs'
import HeaderBar from 'components/HeaderBar'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import ResultList from 'components/ResultList'
import KodexSidebar from 'components/Sidebar'
import Space from 'components/Space'
import StickyToolbar from 'components/StickyToolBar'
import View from 'components/View'
import { CATEGORIES, TAGS } from 'constants/category'
import { FilterContext } from 'contexts/FilterProvider'
import { useImagePreloader } from 'contexts/ImagePreloaderProvider'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useHistory, useParams } from 'react-router'
import {
  GET_SEARCH_DOMAINS_COUNT,
  kodexApiContext
} from 'service/graphql/kodex-api'
import { getMarketplaceDefaultDomains } from 'service/rest/getMarketplaceDomains'
import InViewAnimation from 'utils/inViewAnimation'
import InViewCard from 'utils/inViewCard'
import CategoryHeader from './CategoryHeader'

let firstSortByInit = false

const Subcategory = () => {
  const params = useParams()
  let history = useHistory()
  if (
    !Object.keys(CATEGORIES)
      .map(cat => cat.toLowerCase())
      .includes(params.subcategory)
  )
    history.push('/404')
  const currentCategory = useMemo(
    () => TAGS.find(tag => tag.key.toLowerCase() === params.subcategory),
    [params.subcategory]
  )
  const [filter, setFilter] = useState({
    limit: 20,
    CATEGORIES: []
  })

  useEffect(() => {
    if (window.innerWidth <= 740) {
      history.push('/marketplace')
      return
    }
  }, [])

  useEffect(() => {
    setFilter(filter => ({
      ...filter,
      subCategories:
        CATEGORIES[
          Object.keys(CATEGORIES).find(
            key => key.toLowerCase() === params.subcategory
          )
        ]
    }))

    return () => {}
  }, [params.subcategory])

  const [categoriesModalOpen, setCategoriesModalOpen] = useState(false)

  const filterCtx = useContext(FilterContext)
  const filtersOpened = filterCtx.opened

  const [activeCurrency, setActiveCurrency] = useState('ETH')
  const [isFetchMore, setFetchMore] = useState(false)
  const [viewType, setViewType] = useState('CARD')

  const [offerDialogOpen, setOfferDialogOpen] = useState(false)
  const [offeredDomainName, setOfferedDomainName] = useState('')
  const [offeredDomainlastPrice, setOfferedDomainLastPrice] = useState('')
  const [filtersClosing, setFiltersClosing] = useState(false)
  const [filtersOpening, setFiltersOpening] = useState(false)

  const pageRef = useRef(null)
  const cardsWrapperRef = useRef(null)

  const [domains, setDomains] = useState([])

  const getDomains = async () => {
    if (filter?.search?.length > 0) {
      const fetchedDomains = await getMarketplaceDefaultDomains(filter)
      setDomains(fetchedDomains.domains)
      return
    }
    const fetchedDomains = await getMarketplaceDefaultDomains(filter)
    setDomains(fetchedDomains?.domains)
  }

  const fetchMore = async () => {
    if (!domains || domains?.length % 20 > 0) return

    if (filter?.search?.length > 0) {
      const fetchedDomains = await getMarketplaceDefaultDomains({
        ...filter,
        offset: domains.length
      })

      setDomains([...domains, ...fetchedDomains.domains])
      return
    }

    const fetchedDomains = await getMarketplaceDefaultDomains({
      ...filter,
      offset: domains.length
    })

    setDomains([...domains, ...fetchedDomains?.domains])
  }

  useEffect(() => {
    getDomains()
  }, [filter])

  const [fetchDomainsCount, { data: numOfDomains }] = useLazyQuery(
    GET_SEARCH_DOMAINS_COUNT,
    {
      variables: {},
      ...kodexApiContext
    }
  )

  // TODO: add new filters here
  const getDomainsCount = useCallback(() => {
    const {
      limit = 20,
      sort,
      search,
      minLength,
      maxLength,
      minPrice,
      maxPrice,
      subCategories,
      status,
      date,
      result,
      type
    } = filter

    let variables = {
      limit,
      offset: 0,
      name_pattern_search: search,
      min_domain_length: minLength,
      max_domain_length: maxLength,
      min_listing_price: minPrice,
      max_listing_price: maxPrice,
      search_type: 'marketplace',
      has_offers_selector:
        status && status.length > 0 ? `{${status.join(',')}}` : undefined,
      order_type: sort || 'default',
      date_status: date,
      name_result: result,
      name_symbols_type:
        type && type.length > 0 ? `{${type.join(',')}}` : undefined,
      terms:
        subCategories && subCategories.length > 0
          ? `{${subCategories.join(',')}}`
          : undefined
    }

    fetchDomainsCount({
      variables: { ...variables, limit: undefined }
    })
  })

  useEffect(() => {
    getDomainsCount()
  }, [filter])

  // This can just be part of the mock object and will be part of
  // the api response moving forwards

  const getTopAccountsImage = () => {
    switch (currentCategory.key) {
      case 'clubs':
        return KClub
      case 'English':
        return English
      case 'Creatures':
        return Creatures
      case 'Dictionary':
        return Dictionary
      case 'Web':
        return Web
      case 'Places':
        return Places
      case 'Names':
        return Names
      case 'Characters':
        return Characters
      case 'Crypto':
        return Crypto
      case 'Company':
        return Company
      case 'Misc':
        return Misc
      case 'Art':
        return Art
      case 'Animals':
        return Animals
      case 'People':
        return People
      default:
        return Crypto
    }
  }

  const { canStartAnimatePage } = useImagePreloader()

  useEffect(() => {
    let animation
    if (canStartAnimatePage) {
      animation = new InViewAnimation()
    }
    if (animation != null) {
      return () => {
        animation.destroy()
      }
    }
  }, [canStartAnimatePage])

  useEffect(() => {
    if (domains == null) {
      return
    }
    const cards = new InViewCard()

    return () => cards.destroy()
  }, [domains])

  const handleOpenFilters = useCallback(() => {
    filterCtx.toggleOpen()
  }, [])

  const handleHideFilters = useCallback(() => {
    filterCtx.toggleOpen()
  }, [])

  const handleCloseModal = useCallback(() => {
    setCategoriesModalOpen(false)
  }, [])

  const handleOpenModal = useCallback(() => {
    setCategoriesModalOpen(true)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: 750,
      behavior: 'smooth'
    })
  }

  const handleChangeSearch = name => {
    setFilter(filter => ({
      ...filter,
      search: name || undefined
    }))
    scrollToTop()
  }

  const handleViewChange = type => {
    setViewType(type)
    scrollToTop()
  }

  const handleLengthChange = params => {
    setFilter(filter => ({
      ...filter,
      minLength: params[0],
      maxLength: params[1] === 10 ? null : params[1]
    }))
    scrollToTop()
  }

  const handlePriceChange = prices => {
    setFilter(filter => ({
      ...filter,
      minPrice: prices[0] > 0 ? Number(prices[0]) : null,
      maxPrice: prices[1] > 0 ? Number(prices[1]) : null
    }))
    scrollToTop()
  }

  const handleStatusChange = status => {
    setFilter(filter => ({
      ...filter,
      status
    }))
    scrollToTop()
  }

  const handleSortByChange = sort => {
    setFilter(filter => ({
      ...filter,
      sort
    }))
    if (!firstSortByInit) {
      firstSortByInit = true
      return
    }
    scrollToTop()
  }

  const handleDateChange = date => {
    setFilter(filter => ({
      ...filter,
      date
    }))
    scrollToTop()
  }

  const handleChangeCategories = subCategories => {
    setFilter(filter => ({
      ...filter,
      subCategories
    }))
    scrollToTop()
  }

  useEffect(() => {
    getDomains()
    return () => {}
  }, [filter])

  const handleResetFilter = () => {
    setFilter({})
    scrollToTop()
  }

  const handleOpenOfferDialog = (name, lastPrice) => {
    setOfferedDomainName(name)
    setOfferedDomainLastPrice(lastPrice)
    setOfferDialogOpen(true)
  }

  const refetchDomains = async () => {
    scrollToTop()
    getDomains()
  }

  const refreshData = () => {
    refetchDomains(filter)
  }

  const hideFiltersHandler = () => {
    if (filtersOpening || filtersClosing) return
    setFiltersClosing(true)
    handleOpenFilters()
    setTimeout(() => {
      setFiltersClosing(false)
    }, 400)
  }

  const openFiltersHandler = () => {
    if (filtersOpening || filtersClosing) return
    setFiltersOpening(true)
    handleOpenFilters()
    setTimeout(() => {
      setFiltersOpening(false)
    }, 400)
  }

  return (
    <>
      <KodexSidebar
        positionDialogCenter={true}
        opened={offerDialogOpen}
        onClose={() => setOfferDialogOpen(false)}
      >
        <OfferDialog
          onClose={() => setOfferDialogOpen(false)}
          domain={{
            name: offeredDomainName,
            lastPrice: offeredDomainlastPrice
          }}
          backLabel="marketplace"
        />
      </KodexSidebar>
      <Container ref={pageRef} maxWidth="calc(100% - 140px)" background="#fff">
        <Space size={80} />
        <Space size={60} />
        <CategoryHeader />
        <Space size={60} />
        {/* <Space size={80} />
        <Space size={80} />
        <Label size={110} color={'#0698A8'} data-in="stagger-chars">
          {`#${currentCategory.name[1].toUpperCase()}${currentCategory.name
            .slice(2, currentCategory.name.length)
            .toLowerCase()}`}
        </Label>
        <Space size={80} />
        <View width="100%" height="420px" transition="height 0.3s ease">
          <View
            flex={2}
            onClick={() => {
              history.push('/trending?domains')
            }}
          >
            <SimpleCard
              imgUrl={getTopAccountsImage()}
              title="Trending"
              height="420px"
            />
          </View>
          <Space size={20} />
          <View
            flex={1}
            onClick={() => {
              history.push('/trending?users')
            }}
          >
            <SimpleCard imgUrl={Accounts} title="TOP ACCOUNTS" height="420px" />
          </View>
        </View>
        <Space size={80} /> */}
        <View width="100%" direction="column">
          <HeaderBar
            darkMode={false}
            domainCount={numOfDomains?.domains?.aggregate.count || 0}
            refreshData={() => refreshData()}
            viewType={viewType}
            onViewTypeChange={handleViewChange}
            onSortByChange={handleSortByChange}
            onSearchChange={handleChangeSearch}
            onHideFilters={hideFiltersHandler}
            onOpenFilters={openFiltersHandler}
          />
          <View ref={cardsWrapperRef} flex={1} width="100%">
            <StickyToolbar
              ref={pageRef}
              filtersOpened={filtersOpened}
              onHideFilters={handleHideFilters}
              onOpenFilters={handleOpenFilters}
              subCategories={filter.subCategories}
              onOpenCategoriesModal={handleOpenModal}
              onLengthChange={handleLengthChange}
              onPriceChange={handlePriceChange}
              onStatusChange={handleStatusChange}
              onSortByChange={handleSortByChange}
              onDateChange={handleDateChange}
              onResetFilter={handleResetFilter}
              setActiveCurrency={setActiveCurrency}
              activeCurrency={activeCurrency}
              domainCount={numOfDomains?.domains?.aggregate.count || 0}
              refreshData={() => refreshData()}
              modalButtonTitle="Categories"
              page="marketplace"
            />
            <ResultList
              domains={domains}
              filtersOpened={filtersOpened}
              fetchMore={fetchMore}
              activeCurrency={activeCurrency}
              isFetchMore={isFetchMore}
              setFetchMore={setFetchMore}
              openOfferDialog={handleOpenOfferDialog}
              viewType={viewType}
            />
          </View>
        </View>
        <Space size={80} />
      </Container>

      {categoriesModalOpen && (
        <CategoryFilterModal
          subCategories={filter.subCategories}
          open={categoriesModalOpen}
          onClose={handleCloseModal}
          onApply={handleChangeCategories}
        />
      )}
    </>
  )
}

export default Subcategory
