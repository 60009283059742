import styled from '@emotion/styled/macro'
import { useWindowListener } from 'common/useWindowListener'
import MobileTokenCard from 'components/Card/MobileNetTokenCard'
import NetTokenCard from 'components/Card/NetTokenCard'
import { HasLoadedCard } from 'components/Card/NetTokenCard/contexts/HasLoadedCardContext'
import { useAccount } from 'components/QueryAccount'
import { useColumnCount } from 'components/ResultList/hooks/useColumnCount'
import NetTokenRow from 'components/Row/NetTokenRow'
import Space from 'components/Space'
import { VirtualizedList } from 'components/VirtualizedList'
import { DOMAIN_TABLE_COLUMNS_BREAKPOINTS } from 'constants/breakpoints'
import { CartStoreContext } from 'contexts/CartStoreProvider'
import { FilterContext } from 'contexts/FilterProvider'
import { useEthPrice } from 'hooks/useEthPrice'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import NoItems from 'views/Checkout/components/NoItems'

const ResultList = ({
  domains,
  fetchMore,
  filtersOpened,
  activeCurrency,
  openOfferDialog,
  viewType,
  initialLoad = false,
  isSimilar = false,
  loading
}) => {
  const history = useHistory()

  const {
    state: { domains: selectedDomains },
    removeDomain,
    addNewDomain
  } = useContext(CartStoreContext)
  const cartCtx = useContext(CartStoreContext)

  const [domainsIsInCartArr, setDomainsIsInCartArr] = useState([])
  const [cardWidth, setCardWidth] = useState('310px')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const userAddress = useAccount()

  const ethPrice = useEthPrice()

  const { columnCount } = useColumnCount({
    sizeMap: DOMAIN_TABLE_COLUMNS_BREAKPOINTS
  })

  const { savedDomainPosition, setSavedDomainPosition } = useContext(
    FilterContext
  )

  const handleGoSummary = (domainName, domainRegistered) => {
    if (domainRegistered) {
      setSavedDomainPosition(
        Object.assign(savedDomainPosition, {
          marketplace: {
            ...savedDomainPosition.marketplace,
            clickedDomain: domainName
          }
        })
      )
    } else {
      setSavedDomainPosition(
        Object.assign(savedDomainPosition, {
          registry: {
            ...savedDomainPosition.registry,
            clickedDomain: domainName
          }
        })
      )
    }
    history.push(`/domain/${domainName}`)
  }

  const genericProps = useMemo(
    () => ({
      margin: '10px 10px 0 10px',
      footerBg: '#F3F3FA',
      registeredBg: '#ffffff',
      hasAnimation: true
    }),
    []
  )

  useEffect(() => {
    const selectedDomainsNames = selectedDomains?.map(domain => domain.name)
    const areInCartArr = domains?.map(domain =>
      selectedDomainsNames.includes(domain.name)
    )
    setDomainsIsInCartArr([...areInCartArr])
  }, [cartCtx, domains, selectedDomains])

  useEffect(() => {
    let calculatedCardWith
    switch (
      filtersOpened
        ? windowWidth < 1300
          ? columnCount - 2
          : columnCount - 1
        : columnCount
    ) {
      case 3:
        calculatedCardWith = '32.6%'
        break
      case 4:
        calculatedCardWith = '24.25%'
        break
      case 5:
        calculatedCardWith = '19.2%'
        break
      case 6:
        calculatedCardWith = '15.833%'
        break
      case 7:
        calculatedCardWith = '13.425%'
        break
      case 8:
        calculatedCardWith = '11.625%'
        break
      case 9:
        calculatedCardWith = '9%'
        break
      case 10:
        calculatedCardWith = '3%'
        break
      default:
        calculatedCardWith = '310px'
        break
    }

    setCardWidth(calculatedCardWith)
  }, [columnCount, filtersOpened, windowWidth])

  useWindowListener(
    'resize',
    () => {
      setWindowWidth(window.innerWidth)
    },
    []
  )

  return (
    <>
      {/* {viewType === 'LIST' && (
        <>
          <Space size={150} />
          <View
            width="100%"
            margin="10px 0 25px"
            padding="0 30px 0 20px"
            alignItems="center"
          >
            <View flex="18%">
              <Label color="#BCBCCC">Domain</Label>
            </View>
            <View flex="18%" justify="flex-end">
              <Label color="#BCBCCC">Current price</Label>
            </View>
            <View flex="18%" justify="flex-end">
              <Label color="#BCBCCC">Category</Label>
            </View>
            <View flex="18%" justify="flex-end">
              <Label color="#BCBCCC">Owner</Label>
            </View>
            <View flex="18%" justify="flex-end">
              <Label color="#BCBCCC">Last price</Label>
            </View>
            <View flex="10%" />
          </View>
        </>
      )} */}
      {!initialLoad && !loading && domains?.length === 0 && (
        <>
          <Space size={100} />
          <NoItems />
        </>
      )}
      <HasLoadedCard>
        <VirtualizedList
          Component={
            windowWidth < 640
              ? MobileTokenCard
              : viewType === 'LIST'
              ? NetTokenRow
              : NetTokenCard
          }
          genericProps={genericProps}
          viewType={viewType}
          individualComponentPropsMap={domains?.reduce((acc, domain, index) => {
            const registered =
              domain &&
              moment()
                .subtract(90, 'days')
                .isBefore(Number(domain.expire_time) * 1000)
            return {
              ...acc,
              [index]: {
                margin: '0',
                width: cardWidth,
                windowWidth,
                zIndex: domains.length - index,
                headerBg: registered ? '#F3F3FA' : '#262628',
                headerColor: registered ? '#000' : '#fff',
                bodyBg: registered ? '#F3F3FA' : '#262628',
                bodyColor: registered ? '#020202' : '#F3F3FA',
                addedToCart:
                  selectedDomains.includes(domain) || domainsIsInCartArr[index],
                from: 'marketplace',
                domain,
                priceInETH: activeCurrency === 'ETH',
                ethPrice,
                key: `${domain.name}--${index}`,
                openOfferDialog: openOfferDialog,
                filtersOpened: filtersOpened,
                onClick: () => handleGoSummary(domain.name, registered),
                onSelect: () =>
                  selectedDomains.includes(domain) || domainsIsInCartArr[index]
                    ? userAddress &&
                      removeDomain(
                        domain.name,
                        registered ? 'Purchase' : 'Register'
                      )
                    : userAddress &&
                      addNewDomain(domain, registered ? 'Purchase' : 'Register')
              }
            }
          }, {})}
          itemCount={
            domains?.length +
            (viewType === 'LIST'
              ? loading
                ? 10
                : 0
              : domains?.length % 20 === 0 && loading
              ? columnCount -
                  (filtersOpened ? 1 : 0) -
                  (20 % (columnCount - (filtersOpened ? 1 : 0))) +
                  columnCount -
                  (filtersOpened ? 1 : 0) || 0
              : 0)
          }
          from={'marketplace'}
          loadMore={fetchMore}
        />
      </HasLoadedCard>
    </>
  )
}

const TableHeader = styled.div`
  align-items: center;
  color: #bcbccc;
  display: flex;
  font-size: 13px;
  margin: 4px 0 20px;
  height: 50px;
  padding: 0 30px 0 20px;
  width: 100%;

  div {
    width: 20%;
  }

  .shorter-header-el {
    width: 18%;
  }

  .first-header-el {
    width: 19%;
  }
`

export default ResultList
