import React from 'react'
import styled from '@emotion/styled'

const SaveButton = styled.button`
  background-color: ${props => (props.disabled ? '#F3F3FA' : '#0698A8')};
  color: ${props => (props.disabled ? '#BCBCCC' : '#fff')};
  border-radius: 6px;
  width: 100%;
  cursor: pointer;
  padding: 22px 0;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  line-height: 1;
  transition: all 0.25s ease;
`

export default SaveButton
