import Label from 'components/Label'
import View from 'components/View'
import React, { useEffect, useState } from 'react'
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg'
import { ReactComponent as Star } from 'assets/star-black.svg'
import { getTrendingDomains, getTrendingUsers } from 'service/rest/trending'
import Space from 'components/Space'
import Avatar from 'components/Avatar'
import { defaultPfps } from 'constants/defaultPfps'

const HeaderMobileCard = ({ label, endpoint }) => {
  const [expanded, setExpanded] = useState(false)
  const [fetchedData, setFetchedData] = useState([])

  useEffect(() => {
    const fetchTrending = async () => {
      if (endpoint === 'domains') {
        const trendingDomainsData = await getTrendingDomains({
          duration: 'DAY',
          limit: 4,
          offset: 0
        })

        setFetchedData(trendingDomainsData.domains)
      }
      if (endpoint === 'users') {
        const trendingUsersData = await getTrendingUsers({
          duration: 'DAY',
          limit: 4,
          offset: 0
        })

        setFetchedData(trendingUsersData.users)
      }
    }

    fetchTrending()
  }, [endpoint])

  return (
    <View width="100%" direction="column" transition="0.15s linear">
      <View
        width="100%"
        direction="row"
        background="#F6F6F9"
        padding="16px"
        borderRadius="8px"
        justify="space-between"
        onClick={() => setExpanded(!expanded)}
        zIndex={2}
      >
        <Label size={24} color="#47596B">
          {label}
        </Label>
        <div
          style={{
            transition: '0.15s linear',
            transform: expanded ? 'rotate(180deg)' : 'rotate(0)'
          }}
        >
          <ArrowDown stroke="#47596B" />
        </div>
      </View>
      <View
        width="100%"
        direction="column"
        height={expanded ? '170px' : '0px'}
        gap="10px"
        background="#EAEAF1"
        top="-10px"
        borderRadius="8px"
        padding={expanded ? '0 16px 0 16px' : 'none'}
        zIndex={1}
        transition="height 0.2s ease-out"
        overflow="hidden"
      >
        <Space size={35} />
        {expanded &&
          fetchedData.map((item, i) => (
            <View
              key={i}
              width="100%"
              justify="space-between"
              alignItems="center"
              margin="4px 0"
            >
              <View maxWidth="70%" alignItems="center">
                {item.user && (
                  <>
                    <Avatar
                      width={18}
                      src={
                        item.picture ||
                        defaultPfps[Math.floor(Math.random() * 6)]
                      }
                      borderRadius="6px"
                      background={'#fff0'}
                    />
                    <Space size={8} />
                  </>
                )}
                <Label
                  cursor="pointer"
                  size={16}
                  whiteSpace="nowrap"
                  color="#47596B"
                >
                  {item.name || item.username || item.user.slice(2, 7) || ''}
                </Label>
              </View>
              <View alignItems="center">
                <Label size={16} number color="#47596B">
                  {item.likes}
                </Label>
                <Space size={8} />
                <Star height={18} width={18} stroke="#47596B" />
              </View>
            </View>
          ))}
        <Space size={16} />
      </View>
    </View>
  )
}

export default HeaderMobileCard
