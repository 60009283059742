import React from 'react'
import styled from '@emotion/styled/macro'

const AvatarContainer = styled.div`
  width: ${p => p.width}px;
  max-width: ${p => p.maxWidth};
  height: ${p => (p.height ? p.height : p.width)}px;
  max-height: ${p => p.maxWidth};
  border-radius: ${p => (p.borderRadius ? p.borderRadius : '50%')};
  background-color: ${p => (p.background ? p.background : '#262628')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${p => (p.padding ? p.padding : '0px')};
  overflow: hidden;
  cursor: ${props => (props.cursor ? props.cursor : 'pointer')};
  transition: all 0.2s ease;

  &:hover {
    opacity: ${props => (props.hoverOpacity ? props.hoverOpacity : 0.6)};
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Avatar = ({
  className,
  width = 40,
  maxWidth,
  src,
  borderRadius,
  padding,
  background,
  hoverOpacity,
  cursor
}) => {
  return (
    <AvatarContainer
      className={className}
      width={width}
      borderRadius={borderRadius}
      padding={padding}
      background={background}
      hoverOpacity={hoverOpacity}
      cursor={cursor}
      maxWidth={maxWidth}
    >
      {src && <Image src={src} />}
    </AvatarContainer>
  )
}

export default Avatar
