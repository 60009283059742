import styled from '@emotion/styled/macro'
import BottomModal from 'components/BottomModal'
import RoundedButton from 'components/Forms/RoundedButton'
import Container from 'components/Layout/Container'
import View from 'components/View'
import { useEffect, useState } from 'react'
import { MAIN_CATEGORY_COLORS } from 'utils/constants'
import { Category } from './Category'
import { CATEGORIES } from 'constants/category'
import { countBy, every, uniq } from 'lodash'
import { SelectAllCategories } from './SelectAllCategories'

const CategoryFilterModal = ({
  onClose,
  open,
  onApply,
  subCategories = [],
  darkMode,
  isInner
}) => {
  const [activeCategory, setActiveCategory] = useState(
    Object.keys(CATEGORIES)[0]
  )
  const [categories, setCategories] = useState([])
  const [innerSubCategories, setInnerSubcategories] = useState(subCategories)
  const [domainCount, setDomainCount] = useState(0)
  const [categoryDomains, setCategoryDomains] = useState({})

  const handleChangeCategory = params => {
    let newCategories = [],
      newSubcategories = []
    if (categories.includes(params)) {
      newCategories = categories.filter(cat => cat !== params)
      newSubcategories = innerSubCategories.filter(
        cat => !CATEGORIES[params].includes(cat)
      )
    } else {
      newCategories = [...categories, params]
      newSubcategories = uniq([...innerSubCategories, ...CATEGORIES[params]])
    }
    setCategories(newCategories)
    setInnerSubcategories(newSubcategories)
    setActiveCategory(params)
  }

  const handleChangeSubcategory = params => {
    let newCategories = categories,
      newSubcategories = []

    if (innerSubCategories.includes(params)) {
      newSubcategories = innerSubCategories.filter(cat => cat !== params)
      newCategories = categories.filter(cat => cat !== activeCategory)
    } else {
      newSubcategories = uniq([...innerSubCategories, params])
      if (
        every(CATEGORIES[activeCategory], cat => newSubcategories.includes(cat))
      ) {
        newCategories = uniq([...newCategories, activeCategory])
      }
    }
    setCategories(newCategories)
    setInnerSubcategories(newSubcategories)
  }
  const getCategoryCount = param => {
    return (
      countBy(CATEGORIES[param], cat => innerSubCategories.includes(cat))[
        'true'
      ] || 0
    )
  }

  const handleClose = () => {
    onClose()
  }

  const handleReset = () => {
    setCategories([])
    setInnerSubcategories([])
  }

  const handleApply = () => {
    onClose()
    onApply(innerSubCategories)
  }

  const handleUpdateCategoryDomains = (name, count) => {
    setCategoryDomains({ ...categoryDomains, [name]: count })
  }

  useEffect(() => {
    setDomainCount(
      innerSubCategories.reduce((a, b) => a + (categoryDomains[b] || 0), 0)
    )
    return () => {}
  }, [innerSubCategories, categoryDomains])

  useEffect(() => {
    // setInnerSubcategories(subCategories)
    let newCategories = []
    Object.keys(CATEGORIES).forEach(activeCategory => {
      if (
        every(CATEGORIES[activeCategory], cat => subCategories.includes(cat))
      ) {
        newCategories = uniq([...newCategories, activeCategory])
      }
    })
    setCategories(newCategories)
    return () => {}
  }, [])

  const handleSelectAllCategories = () => {
    if (Object.keys(CATEGORIES).length === categories.length) {
      setCategories([])
      setInnerSubcategories([])
      return
    }
    setCategories(Object.keys(CATEGORIES))
    setInnerSubcategories(Object.values(CATEGORIES).flat())
  }

  const handleSelectAllSubCategories = () => {
    if (
      innerSubCategories.filter(subC =>
        CATEGORIES[activeCategory].includes(subC)
      ).length === CATEGORIES[activeCategory].length
    ) {
      setInnerSubcategories(
        innerSubCategories.filter(
          subC => !CATEGORIES[activeCategory].includes(subC)
        )
      )
      setCategories(categories.filter(cat => cat !== activeCategory))
      return
    }
    setCategories([...categories, activeCategory])
    setInnerSubcategories([
      ...innerSubCategories.filter(
        subC => !CATEGORIES[activeCategory].includes(subC)
      ),
      ...CATEGORIES[activeCategory]
    ])
  }

  return (
    <BottomModal
      open={open}
      onClose={handleClose}
      contentBackground={darkMode ? '#212121' : ''}
      modalId={isInner ? 'inner-modal-root' : 'modal-root'}
    >
      <Container maxWidth="940px">
        <View direction="column" height="calc(100vh - 140px)" overflow="hidden">
          <InnerContainer>
            <Section isScroll>
              <SelectAllCategories
                name="All categories"
                darkMode={darkMode}
                onSelect={handleSelectAllCategories}
                isSelected={
                  Object.keys(CATEGORIES).length === categories.length
                }
              />
              {Object.keys(CATEGORIES).map((cat, index) => (
                <Category
                  name={cat}
                  color={MAIN_CATEGORY_COLORS[index % 11]}
                  price="0.025"
                  count={getCategoryCount(cat)}
                  isSelected={categories.includes(cat)}
                  isActive={cat === activeCategory}
                  onClick={() => setActiveCategory(cat)}
                  onSelect={count => handleChangeCategory(cat, count)}
                  key={cat}
                  darkMode={darkMode}
                />
              ))}
            </Section>
            <Section>
              <SelectAllCategories
                name={`${activeCategory} subcategories`}
                darkMode={darkMode}
                onSelect={handleSelectAllSubCategories}
                isSelected={
                  innerSubCategories.filter(subC =>
                    CATEGORIES[activeCategory].includes(subC)
                  ).length === CATEGORIES[activeCategory].length
                }
              />
              {CATEGORIES[activeCategory].map(cat => (
                <Category
                  name={cat}
                  price="0.025"
                  count={7}
                  isSelected={innerSubCategories.includes(cat)}
                  key={cat}
                  isSubcategory
                  onSelect={() => handleChangeSubcategory(cat)}
                  onUpdateDomainCount={handleUpdateCategoryDomains}
                  darkMode={darkMode}
                />
              ))}
            </Section>
          </InnerContainer>
          <View margin="auto 0 20px 0" padding="20px 0 0 0" width="100%">
            <RoundedButton
              background="transparent"
              color={darkMode ? '#ffffff90' : '#4A4A4D'}
              className="mr-1"
              onClick={handleReset}
              padding="22px 30px"
              hoverOpacity={0.7}
              border="1px solid #4A4A4D"
            >
              Reset
            </RoundedButton>
            <View margin="0 0 0 30px">
              <RoundedButton
                background="#0698A8"
                border="1px solid #0698A8"
                color="#fff"
                onClick={handleApply}
                padding="22px 30px"
                hoverOpacity={0.7}
              >
                Apply
              </RoundedButton>
            </View>
            <View
              direction="row"
              width="100%"
              margin="auto 0 auto auto"
              padding="0 0 0 23%"
              justify="space-between"
            >
              <SELECTED darkMode={darkMode}>
                Selected: &nbsp;
                <TOTAL darkMode={darkMode} className="my-auto">
                  {innerSubCategories.length}&nbsp; Categories
                </TOTAL>
              </SELECTED>
              <TOTAL darkMode={darkMode} className="my-auto ml-auto">
                {Number(domainCount)
                  .toLocaleString('default', {
                    maximumFractionDigits: 0
                  })
                  .replaceAll(',', ' ')}{' '}
                Domains
              </TOTAL>
            </View>
          </View>
        </View>
      </Container>
    </BottomModal>
  )
}

export default CategoryFilterModal

const SELECTED = styled.div`
  color: ${props => (props.darkMode ? '#ffffff90' : '#bcbccc')};
  display: flex;

  span {
    margin: auto 8px;
    color: #020202;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
`
const TOTAL = styled.div`
  font-family: 'Satoshi', sans-serif;
  color: ${props => (props.darkMode ? '#ffffff90' : '#bcbccc')};
  @media screen and (max-width: 480px) {
    display: none;
  }
`

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  overflow: auto;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`

const Section = styled.div`
  width: calc(50% - 20px);
  overflow: ${props => (props.isScroll ? 'auto' : 'hidden')};

  @media screen and (max-width: 480px) {
    width: calc(100% - 20px);
    height: auto;
    &:last-child {
      margin-left: 40px;
      width: calc(100% - 60px);
    }
  }
`
