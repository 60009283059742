import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import gsap, { Expo } from 'gsap/all'
import styled from '@emotion/styled/macro'
import View from 'components/View'
import NewsCard from 'components/Card/NewsCard'
import SectionHeader from '../../components/SectionHeader'
import { useMediaMax } from 'mediaQuery'
import Link from 'components/Forms/Link'
import { useHistory } from 'react-router'
import { POSTS } from 'views/News/posts'

const News = () => {
  const settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '60px',
    speed: 500,
    variableWidth: true,
    accessibility: false,
    touchMove: false,
    swipeToSlide: false,
    arrows: false
  }

  const smallBP = useMediaMax('small')
  const [cardWrapperWidth, setCardWrapperWidth] = useState('460px')
  const [sliderWrapperPadding, setSliderWrapperPadding] = useState(
    '380px 0 280px'
  )
  const [headerPosition, setHeaderPosition] = useState({
    bottom: '5em',
    left: '70px'
  })

  const history = useHistory()

  useEffect(() => {
    // slider
    gsap.set('.js-news__slider', { xPercent: 50 })
    gsap.to('.js-news__slider', {
      scrollTrigger: {
        scrub: true,
        trigger: '.js-notable-accounts',
        start: () => 'top top-=600px',
        end: () => 'top top-=5000px',
        invalidateOnRefresh: true
      },
      xPercent: 0,
      ease: 'none'
    })

    gsap.set('.js-footer__logo', { y: 30, alpha: 0 })
    gsap.to('.js-footer__logo', {
      scrollTrigger: {
        trigger: '.js-notable-accounts',
        start: () => 'top top-=4800px',
        invalidateOnRefresh: true
      },
      y: 0,
      alpha: 1,
      ease: Expo.easeOut
    })
    gsap.set('.js-footer__top', { y: 30, alpha: 0 })
    gsap.to('.js-footer__top', {
      scrollTrigger: {
        trigger: '.js-notable-accounts',
        start: () => 'top top-=4800px',
        invalidateOnRefresh: true
      },
      y: 0,
      alpha: 1,
      ease: Expo.easeOut
    })
    gsap.set('.js-footer__bottom', { y: 30, alpha: 0 })
    gsap.to('.js-footer__bottom', {
      scrollTrigger: {
        trigger: '.js-notable-accounts',
        start: () => 'top top-=4800px',
        invalidateOnRefresh: true
      },
      y: 0,
      alpha: 1,
      ease: Expo.easeOut
    })
  }, [])

  useEffect(() => {
    if (smallBP) {
      setCardWrapperWidth('365px')
      setSliderWrapperPadding('20vh 0')
      setHeaderPosition({ top: '140px', left: '24px' })
    } else {
      setCardWrapperWidth('460px')
      setSliderWrapperPadding('30vh 0')
      setHeaderPosition({ bottom: '5em', left: '70px' })
    }
  }, [smallBP])

  const seeAllNews = () => {
    history.push('/news', 60)
  }

  return (
    <View
      minWidth="2500px"
      height="100vh"
      direction="column"
      background="#F3F3FA"
      data-in="stagger-slider"
      className="js-news pin-box"
      overflow="hidden"
    >
      <TitleWrapper
        className="js-news__title"
        {...headerPosition}
        bottom={window.innerHeight < 900 ? '1rem' : undefined}
      >
        <SectionHeader text="Blog" color="#000" />
        <Link hoverOpacity={0.7} onClick={seeAllNews}>
          <SectionHeader text="All" color="#BCBCCC" />
        </Link>
      </TitleWrapper>
      <SliderWrapper padding={sliderWrapperPadding} className="js-news__slider">
        <Slider {...settings}>
          {POSTS.map(post => (
            <CardWrapper width={cardWrapperWidth} key={post.url} hasLink>
              <NewsCard
                title={post.title}
                date={post.date}
                avatar_url={post.previewImage}
                articleUrl={post.url}
              />
            </CardWrapper>
          ))}
          <CardWrapper width={cardWrapperWidth}>
            <NewsCard title="" date="" />
          </CardWrapper>
          <CardWrapper width={cardWrapperWidth}>
            <NewsCard title="" date="" />
          </CardWrapper>
          <CardWrapper width={cardWrapperWidth}>
            <NewsCard title="" date="" />
          </CardWrapper>
          <CardWrapper width={cardWrapperWidth}>
            <NewsCard title="" date="" />
          </CardWrapper>
          <CardWrapper width={cardWrapperWidth}>
            <NewsCard title="" date="" />
          </CardWrapper>
          <CardWrapper width={cardWrapperWidth}>
            <NewsCard title="" date="" />
          </CardWrapper>
        </Slider>
      </SliderWrapper>
    </View>
  )
}

const TitleWrapper = styled.div`
  position: absolute;
  bottom: ${props => (props.bottom ? `${props.bottom}` : '3em')};
  padding-left: ${props => (props.left ? `${props.left}` : '70px')};
  padding-right: ${props => (props.left ? `${props.left}` : '70px')};
  top: ${props => props.top};
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 99vw;
`

const CardWrapper = styled.div`
  width: ${props =>
    props.width ? `${props.width} !important` : '460px !important'};
  display: flex !important;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => (props.hasLink ? 'auto' : 'none')};
`

const SliderWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: ${props => (props.padding ? `${props.padding}` : '380px 0 280px')};

  .slick-track {
    display: flex;

    #news-card-date {
      color: #c6c6cc;
    }

    #news-card-label {
      color: #020202;
    }
  }

  // .slick-active {
  //   #news-card {
  //     background: #af61ab !important;
  //   }

    #news-card-date {
      color: #fff;
    }

    #news-card-label {
      color: #fff;
    }
  }
`

export default News
