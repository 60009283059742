import React, { useMemo } from 'react'
import FooterLink from './FooterLink'
import Link from 'components/Forms/Link'
import { useLocation, useHistory } from 'react-router'

const ThirdPageLink = () => {
  const location = useLocation()
  const history = useHistory()

  const currentRoute = useMemo(() => location.pathname.split('/')[1], [
    location.pathname
  ])

  const handleAboutClick = () => {
    history.push('/about', 0)
  }

  const handleFeedClick = () => {
    history.push('/feed', 60)
  }

  if (currentRoute === 'about') {
    return (
      <Link hoverOpacity={0.6} onClick={handleFeedClick}>
        <FooterLink text="Feed" color="#fff" />
      </Link>
    )
  }

  return (
    <Link hoverOpacity={0.6} onClick={handleAboutClick}>
      <FooterLink text="About" color="#fff" />
    </Link>
  )
}

export default ThirdPageLink
