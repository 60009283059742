import React, { useEffect, useState } from 'react'
import TrendingCategoryCard from 'components/Card/TrendingCategoryCard'
import Label from 'components/Label'
import View from 'components/View'
import Container from 'components/Layout/Container'
import MOREICON from 'assets/More-category.png'
import MOREICONMobile from 'assets/More-category-mobile.png'
import CRYPTO from 'assets/lotties/crypto.json'
import WEB from 'assets/lotties/web.json'
import PLACES from 'assets/lotties/places.json'
import { useMediaMax } from 'mediaQuery'
import SeeMoreCard from './SeeMore'
import NAMES from 'assets/lotties/names.json'
import Characters from 'assets/lotties/characters.json'
import MISCELLANEOUS from 'assets/lotties/miscellaneous.json'

const DomainCategories = () => {
  const smallBP = useMediaMax('medium')
  const [seeMoreCardFlex, setSeeMoreCardFlex] = useState(2)
  const [itemsWrapperStyle, setItemsWrapperStyle] = useState({
    direction: 'row',
    alignItems: 'flex-start'
  })
  const [cardWidth, setCardWidth] = useState('100%')
  const [labelSize, setLabelSize] = useState({ size: 110, width: '600px' })
  const [labelPadding, setLabelPadding] = useState('300px 0 100px 70px')

  useEffect(() => {
    if (smallBP) {
      setSeeMoreCardFlex(1)
      setItemsWrapperStyle({
        direction: 'column',
        alignItems: 'center'
      })
      setCardWidth('88%')
      setLabelSize({ size: 40, width: '365px' })
      setLabelPadding('140px 0 60px 24px')
    } else {
      setSeeMoreCardFlex(2)
      setItemsWrapperStyle({
        direction: 'row',
        alignItems: 'flex-start'
      })
      setCardWidth('100%')
      setLabelSize({ size: 110, width: '600px' })
      setLabelPadding('300px 0 100px 70px')
    }
  }, [smallBP])

  return (
    <View background="#fff" direction="column" width="100%">
      <Container maxWidth="1300px">
        <View
          width="100%"
          justify="space-between"
          alignItems="center"
          padding={labelPadding}
        >
          <Label
            {...labelSize}
            color="#000"
            weight="00"
            data-in="stagger-chars"
          >
            Curated categories
          </Label>
        </View>
        <View width="100%" {...itemsWrapperStyle} data-in="stagger-item">
          <View width={cardWidth} flex={1}>
            <TrendingCategoryCard
              subject="Crypto"
              icon={CRYPTO}
              hoverBackground={'#E3888F'}
              category="crypto"
            />
          </View>
          <View width={cardWidth} flex={1}>
            <TrendingCategoryCard
              subject="Name"
              icon={NAMES}
              hoverBackground={'#0698A8'}
              category="name"
            />
          </View>
          <View flex={1}>
            <TrendingCategoryCard
              subject="Place"
              icon={PLACES}
              hoverBackground={'#E3CF88'}
              category="places"
            />
          </View>
          <View width={cardWidth} flex={1}>
            <TrendingCategoryCard
              subject="Character"
              icon={Characters}
              hoverBackground={'#AF61AB'}
              category="character"
            />
          </View>
        </View>
        <View
          width="100%"
          padding="0 0 100px"
          {...itemsWrapperStyle}
          data-in="stagger-item"
        >
          <View width={cardWidth} flex={1}>
            <TrendingCategoryCard
              subject="Web"
              icon={WEB}
              hoverBackground={'#ABA3E3'}
              category="web"
            />
          </View>
          <View width={cardWidth} flex={1}>
            <TrendingCategoryCard
              subject="Miscellaneaous"
              icon={MISCELLANEOUS}
              hoverBackground={'#82CED9'}
              category="misc"
            />
          </View>
          <View width={cardWidth} flex={seeMoreCardFlex}>
            <SeeMoreCard
              subject="See more"
              icon={smallBP ? MOREICONMobile : MOREICON}
            />
          </View>
        </View>
      </Container>
    </View>
  )
}

export default DomainCategories
