import styled from '@emotion/styled/macro'

const FadeInItemsLeft = ({ data, renderer, duration }) => {
  return data.map((row, index) => (
    <FadeInLeftContainer key={index} count={index} duration={duration}>
      {renderer(row, index)}
    </FadeInLeftContainer>
  ))
}

export default FadeInItemsLeft

const FadeInLeftContainer = styled.div`
  animation-duration: ${({ duration }) => duration / 1000 + 's'};
  animation-name: fadeInLeft;
  animation-delay: ${({ count }) => count * 400 + 'ms'};

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
