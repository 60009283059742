import styled from '@emotion/styled'
import { ReactComponent as AlumniVentures } from 'assets/about-page/logos/alumni-ventures.svg'
import { ReactComponent as ArcadeXyz } from 'assets/about-page/logos/arcade-xyz.svg'
import { ReactComponent as Cmt } from 'assets/about-page/logos/cmt.svg'
import { ReactComponent as OriginVentures } from 'assets/about-page/logos/origin-ventures.svg'
import { ReactComponent as Renft } from 'assets/about-page/logos/renft.svg'
import { ReactComponent as Sfermion } from 'assets/about-page/logos/sfermion.svg'
import Label from 'components/Label'
import Container from 'components/Layout/Container'
import Space from 'components/Space'
import View from 'components/View'
import { useRef } from 'react'

const PARTNERS = [
  {
    label: 'Backed by',
    partners: [
      { logo: OriginVentures, href: 'https://originventures.com/' },
      { logo: AlumniVentures, href: 'https://www.av.vc/' },
      { logo: Sfermion, href: 'https://www.sfermion.io/' },
      { logo: Cmt, href: 'https://cmt.digital/' }
    ]
  },
  {
    label: 'Partners',
    partners: [
      { logo: ArcadeXyz, href: 'https://www.arcade.xyz/' },
      { logo: Renft, href: 'https://www.renft.io/' }
    ]
  }
]

export const Partners = () => {
  const sectionRef = useRef(null)

  // useEffect(() => {
  //   if (sectionRef.current == null) {
  //     return
  //   }
  // }, [])

  return (
    <View
      width="100%"
      ref={sectionRef}
      background="#020202"
      borderRadius="20px 20px 0 0"
    >
      <Container maxWidth="1080px">
        <View direction="column" padding="250px 0 250px" justify="center">
          <PartnersSection
            partners={PARTNERS[0].partners}
            label={PARTNERS[0].label}
          />
          <Space size={100} />
          <PartnersSection
            partners={PARTNERS[1].partners}
            label={PARTNERS[1].label}
          />
        </View>
      </Container>
    </View>
  )
}

const PartnersSection = ({ label, partners }) => {
  return (
    <View justify="space-between" alignItems="center" width="100%">
      <Label transition="color 0.5s ease" size={60}>
        {label}
      </Label>
      <View transition="opacity 0.5s ease" alignItems="center">
        {partners.map(({ logo: Logo, href }, index) => (
          <LogoWrapper href={href} target="_blank" key={label + index}>
            <Logo />
          </LogoWrapper>
        ))}
      </View>
    </View>
  )
}

const LogoWrapper = styled.a`
  margin: 0 10px;
  width: 120px;
  height: 120px;
  border: 1px solid #4a4a4d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  transition: all 0.3s ease;
  opacity: 1;

  :hover {
    opacity: 0.6;
  }
`

export default Partners
