import React from 'react'
import styled from '@emotion/styled/macro'

const SettingsInput = styled.input`
  border: ${props => props.border};
  background-color: ${props => props.background};
  border-radius: 10px;
  padding: ${props => props.padding};
  caret-color: #a24f9d;
  font-size: 16px;
  font-family: 'Satoshi', sans-serif;
  width: 100%;
  line-height: 1;
  height: 60px;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }

  ::placeholder {
    line-height: 1;
    color: #bcbccc;
  }

  :-ms-input-placeholder {
    line-height: 1;
    color: #bcbccc;
  }

  ::-ms-input-placeholder {
    line-height: 1;
    color: #bcbccc;
  }
`

export default SettingsInput
